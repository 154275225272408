import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { edoc, api } = config;
const basePath = api.middlewareAPIURL + edoc.basePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */
export async function getEDocDetails(candidateId) {
  const result = await axios.get(`${basePath}/${candidateId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function eDocRequest(
  candidateId,
  parameters,
  docFormIds,
  subject,
  content,
  replyTo,
  fromName,
  attachments,
  ccAddresses,
  bccAddresses,
  delay,
) {
  const result = await axios.post(
    `${basePath}`,
    {
      candidateId,
      parameters,
      docFormIds,
      emailContext: {
        subject,
        htmlContent: content,
        replyTo,
        fromName,
        attachments,
        ccAddresses,
        bccAddresses,
        delay,
      },
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendEDocReminder(edocId) {
  const result = await axios.post(`${basePath}/reminder/${edocId}`, null, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function downloadEDoc(eDocId) {
  const url = `${api.middlewareAPIURL}${edoc.basePath}${edoc.downloadeDocPath}/${eDocId}`;

  const result = await axios.get(url, { headers: getCommonHeaders() });

  const blob = await (await fetch(`data:application/pdf;base64,${result.data.data}`)).blob();

  if (result.status === 200) return fileDownload(blob, result.data.fileName);
  return null;
}

export async function deleteEDocFromRequest(eDocRequestId, eDocId) {
  const result = await axios.delete(`${basePath}/${eDocRequestId}/${eDocId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
