import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionToggle } from '../../../Base/SectionDividers';
import { EmailForm } from '../../../Communication';
import InviteEmailContext from './InviteEmailContext';

function InvalidFeedBack({ errorMessage, invalid }) {
  return (
    <div className="invalid-feedback" style={{ display: invalid ? 'block' : 'none' }}>
      {errorMessage}
    </div>
  );
}

InvalidFeedBack.propTypes = {
  errorMessage: PropTypes.string,
  invalid: PropTypes.bool,
};

InvalidFeedBack.defaultProps = {
  errorMessage: null,
  invalid: false,
};

function InviteEmailForm({ value, onChange, invalid, errorMessage, noTemplates, defaultTemplateId }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Fragment>
      <hr />
      <SectionToggle
        sectionAs="fieldset"
        heading="Invitation Email"
        sectionClassName="invite-email"
        isCollapsed={isCollapsed}
        onToggle={() => setIsCollapsed(!isCollapsed)}
      >
        {!isCollapsed && <InvalidFeedBack errorMessage={errorMessage} invalid={invalid} />}
        <InviteEmailContext.Consumer>
          {({ applicants, removeInputs }) => (
            <EmailForm
              data={value}
              noTemplates={noTemplates}
              removeInputs={removeInputs}
              applicants={applicants}
              wrapperClassName="mt-3"
              actionType="EVENT_INVITE"
              isChildForm
              externalSend
              // externalSubmitted
              onChange={(formData) => {
                const { id, to, submitted, ...rest } = formData;
                onChange({ ...rest });
              }}
            />
          )}
        </InviteEmailContext.Consumer>
      </SectionToggle>
      {isCollapsed && <InvalidFeedBack errorMessage={errorMessage} invalid={invalid} />}
      <hr />
    </Fragment>
  );
}

InviteEmailForm.propTypes = {
  value: PropTypes.shape(),
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  noTemplates: PropTypes.bool,
};

InviteEmailForm.defaultProps = {
  value: {},
  onChange: () => {},
  invalid: false,
  errorMessage: null,
  noTemplates: false,
};

export default InviteEmailForm;
