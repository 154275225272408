/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { getOptions } from '../../utils/fetchUtils';
import { SelectInput } from '../../../Base/Forms/Custom/CommonComponents';
import { useMounted } from '../../../Base/hooks';
import VacancySelect from '../../../Base/Forms/Custom/VacancySelect/VacancySelect';

async function fetchOptions(id, options = {}) {
  const { callback, vacIds, activeAccountId } = options;
  const opts = await getOptions(id, [activeAccountId, vacIds]);
  if (callback) callback(opts);
  return opts;
}

function CandidateSelect({ value, onChange, disabled, activeAccountId }) {
  const isMounted = useMounted();
  const [vacancyIds, setVacancyIds] = useState([]);
  const [candidateOpts, setCandidateOpts] = useState([]);
  const [selectCandidates, setSelectCandidates] = useState([]);

  useEffect(() => {
    fetchOptions('candidateIds', {
      activeAccountId,
      callback: (opts) => {
        if (isMounted()) setCandidateOpts(opts);
      },
      ...(vacancyIds.length ? { vacIds: vacancyIds.map((vac) => vac.value) } : {}),
    });
  }, [activeAccountId, isMounted, vacancyIds]);

  useEffect(() => {
    const candidatesSelected = value
      .map((cand) => {
        return candidateOpts.find((candAvail) => cand === candAvail.value);
      })
      .filter(Boolean);

    setSelectCandidates(candidatesSelected);
  }, [candidateOpts, value]);

  return (
    <>
      <FormGroup className="disabled-margin-bottom pb-2">
        <SelectInput
          disabled={disabled}
          isClearable
          isMulti
          onChange={(val, choices) => {
            onChange(val, choices);
          }}
          options={candidateOpts}
          placeholder="Search Candidates"
          value={selectCandidates}
        />
      </FormGroup>
      <FormGroup className="disabled-margin-bottom">
        <VacancySelect
          activeAccountId={activeAccountId}
          disabled={disabled}
          isClearable
          isMulti
          onChange={(val, selectedIds) => {
            setVacancyIds(selectedIds);
            onChange([], []);
          }}
          placeholder="Filter candidates by Vacancy"
          value={vacancyIds}
        />
      </FormGroup>
    </>
  );
}

CandidateSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  activeAccountId: PropTypes.string,
};

CandidateSelect.defaultProps = {
  value: [],
  onChange: () => {},
  disabled: false,
  activeAccountId: null,
};

export default CandidateSelect;
