import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { CreateButton } from '../../../../Base/Buttons';
import Option from './Option';
import { Confirmation } from '../../../../Base/Modal';
import { DEFAULT_OPTION, hasUniqueOpts } from '../utils';
import ErrorMessage from './ErrorMessage';
import { Label, Input } from 'reactstrap';

function OptionsManager({
  type,
  name,
  options,
  screening,
  handleChange,
  isSubmitted,
  onDelete,
  onChange,
  onAdd,
  disabled,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteOptNum, setDeleteOptNum] = useState(-1);

  let isUnique = true;
  if (isSubmitted) isUnique = hasUniqueOpts(options);

  return (
    <FormGroup>
      {options.map((option, i) => (
        <Option
          // eslint-disable-next-line react/no-array-index-key
          key={`option-${i}`}
          disabled={disabled}
          type={type}
          index={i}
          name={name}
          className={`${type}-option`}
          screening={screening}
          isSubmitted={isSubmitted}
          invalid={!isUnique}
          option={option}
          onDelete={(hasLength) => {
            if (hasLength) {
              setDeleteOptNum(i);
              setShowConfirm(true);
            } else {
              onDelete(i);
            }
          }}
          onChange={(opt) => onChange(opt, i)}
          onScreeningChange={(id) => {
            let answers = screening?.answers ? [...screening.answers] : [];
            const isAlreadyIncluded = answers.includes(id);

            // we are handle the case of removing existing items as well as adding
            if (isAlreadyIncluded) {
              answers = answers.filter((a) => a !== id);
            } else {
              answers.push(id);
            }

            handleChange('screening', { ...screening, answers });
          }}
        />
      ))}
      {!disabled && (
        <CreateButton
          label="Add Option"
          className="btn-sm"
          action={(e) => {
            e.preventDefault();
            onAdd([...options, DEFAULT_OPTION]);
          }}
        />
      )}
      {screening?.isScreeningQuestion ? (
        <FormGroup className="mt-5">
          <Label>Screening rejection message</Label>
          <Input
            defaultValue={screening.field}
            onBlur={(e) => {
              handleChange('screening', { ...screening, field: e.target.value });
            }}
            type="textarea"
            disabled={disabled}
          />
        </FormGroup>
      ) : null}
      <ErrorMessage isInvalid={!isUnique} styles={{ clear: 'both' }}>
        Options must have unique values
      </ErrorMessage>
      <Confirmation
        show={showConfirm}
        title="Delete Option?"
        content="Are you sure you want to delete the option?"
        confirmCallback={() => {
          onDelete(deleteOptNum);
          setShowConfirm(false);
        }}
        cancelCallback={() => setShowConfirm(false)}
      />
    </FormGroup>
  );
}

OptionsManager.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  screening: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.string,
    isScreeningQuestion: PropTypes.bool,
  }),
  isSubmitted: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  handleChange: PropTypes.func,
  onAdd: PropTypes.func,
  disabled: PropTypes.bool,
};

OptionsManager.defaultProps = {
  type: 'checkboxes',
  name: null,
  options: [DEFAULT_OPTION],
  screening: {
    answers: [],
    field: '',
    isScreeningQuestion: false,
  },
  isSubmitted: false,
  onDelete: () => {},
  onChange: () => {},
  handleChange: () => {},
  onAdd: () => {},
  disabled: false,
};

export default OptionsManager;
