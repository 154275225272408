/* eslint-disable jsx-a11y/label-has-for */
import { useReducer, useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import cx from 'classnames';
import { DeleteButton } from '../../../../Base/Buttons';
import OptionsManager from './OptionsManager';
import { DEFAULT_OPTION, DEFAULT_QUESTION, DEFAULT_RULE, spliceItem, wrapperRefPropType } from '../utils';
import RulesInputs from './RulesInputs';
import FormManagerContext from './FormManagerContext';
import ErrorMessage from './ErrorMessage';
import WidthDropdown from './WidthDropdown';
import FileTypeDropdown from './FileTypeDropdown';
import AddressCountryDropdown from './AddressCountryDropdown';
import DateRadio from './DateRadio';
import { RestrictedMarker } from '../components';
import PresetDropdownSelect from './PresetDropdownSelect';
import BankDetailsOptions from './BankDetailsOptions';
import InformationalText from './InformationalText';
import { IconSVG } from '../../../../Base/SVG';
import { checkPermissions } from '../../../../../js/auth/AuthUtils';
import ChatBotCompabilityIcon from './ChatBotCompabilityIcon';

const countryList = [
  {
    value: 'AF',
    label: 'Afghanistan (+93)',
  },
  {
    value: 'AL',
    label: 'Albania (+355)',
  },
  {
    value: 'DZ',
    label: 'Algeria (+213)',
  },
  {
    value: 'AR',
    label: 'Argentina (+54)',
  },
  {
    value: 'AU',
    label: 'Australia (+61)',
  },
  {
    value: 'AT',
    label: 'Austria (+43)',
  },
  {
    value: 'BE',
    label: 'Belgium (+32)',
  },
  {
    value: 'BR',
    label: 'Brazil (+55)',
  },
  {
    value: 'CA',
    label: 'Canada (+1)',
  },
  {
    value: 'CN',
    label: 'China (+86)',
  },
  {
    value: 'DK',
    label: 'Denmark (+45)',
  },
  {
    value: 'FI',
    label: 'Finland (+358)',
  },
  {
    value: 'FR',
    label: 'France (+33)',
  },
  {
    value: 'DE',
    label: 'Germany (+49)',
  },
  {
    value: 'IN',
    label: 'India (+91)',
  },
  {
    value: 'IT',
    label: 'Italy (+39)',
  },
  {
    value: 'JP',
    label: 'Japan (+81)',
  },
  {
    value: 'MX',
    label: 'Mexico (+52)',
  },
  {
    value: 'NL',
    label: 'Netherlands (+31)',
  },
  {
    value: 'NZ',
    label: 'New Zealand (+64)',
  },
  {
    value: 'NO',
    label: 'Norway (+47)',
  },
  {
    value: 'PL',
    label: 'Poland (+48)',
  },
  {
    value: 'PT',
    label: 'Portugal (+351)',
  },
  {
    value: 'RU',
    label: 'Russian Federation (+7)',
  },
  {
    value: 'ES',
    label: 'Spain (+34)',
  },
  {
    value: 'SE',
    label: 'Sweden (+46)',
  },
  {
    value: 'CH',
    label: 'Switzerland (+41)',
  },
  {
    value: 'GB',
    label: 'United Kingdom (+44)',
  },
  {
    value: 'US',
    label: 'United States (+1)',
  },
];

export const questionPropType = PropTypes.shape({
  config: PropTypes.shape({
    classNames: PropTypes.string,
    rule: PropTypes.shape(),
    type: PropTypes.string,
  }),
  number: PropTypes.number,
  required: PropTypes.bool,
  screening: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.string),
    field: PropTypes.string,
    isScreeningQuestion: PropTypes.bool,
  }),
  text: PropTypes.string,
});

const questionTypeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Paragraph', value: 'textarea' },
  { label: 'Informational Text', value: 'informationalText' },
  { label: 'Email', value: 'email' },
  { label: 'Phone Number', value: 'phoneNumber' },
  { label: 'URL', value: 'url' },
  { label: 'Number', value: 'number' },
  { label: 'Multiple Choice', value: 'multipleChoice' },
  { label: 'Checkboxes', value: 'checkboxes' },
  { label: 'Drop-down', value: 'dropdown' },
  { label: 'Preset Drop-down', value: 'presetDropdown' },
  { label: 'Dynamic Drop-down', value: 'dynamicSelect' },
  { label: 'National Insurance Number', value: 'nin' },
  { label: 'HMRC Declaration', value: 'hmrcDeclaration' },
  { label: 'Visa Details', value: 'visaDetails' },
  { label: 'Date', value: 'date' },
  { label: 'Date of Birth', value: 'dateOfBirth' },
  { label: 'ISO Date', value: 'isoDate' },
  { label: 'Address Lookup', value: 'address' },
  { label: 'UK Postcode', value: 'ukPostcode' },
  { label: 'Bank Details', value: 'bankDetails' },
  { label: 'UK Validated Bank Details', value: 'bankDetailsUKValidated' },
  { label: 'Video', value: 'video' },
  { label: 'File Attachment', value: 'fileUpload' },
  { label: 'File Attachment - Large', value: 'largeFileUpload' },
];

const newOptions = [
  { label: 'UK Validated Bank Details', value: 'bankDetailsUKValidated' },
  { label: 'International Address Lookup', value: 'internationalAddressLookup' },
];

function findSelectedTypeOption(type, QOptions) {
  return QOptions?.sort((a, b) => (a.label > b.label ? 1 : -1)).find((opt) => opt.value === type);
}

const isOptionInList = (option, list) => {
  return list.some((listOption) => listOption.value === option.value);
};

function getWidthClass(classStr) {
  const classArr = classStr.split(' ').filter((cls) => cls.startsWith('w-'));
  return classArr.length ? classArr[0] : '';
}

const SET_INPUT = 'SET_INPUT';
const SET_INPUT_CONFIG = 'SET_INPUT_CONFIG';
const UPDATE_CONFIG = 'UPDATE_CONFIG';
const SET_RULE = 'SET_RULE';
const SET_VALUE = 'SET_VALUE';
const SET_OPTIONS = 'SET_OPTIONS';
const SET_QUESTION = 'SET_QUESTION';

function questionReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_INPUT:
      return {
        ...state,
        [payload.type]: payload.value,
      };
    case SET_INPUT_CONFIG:
      return {
        ...state,
        [payload.type]: payload.value,
        config: payload.config,
      };
    case UPDATE_CONFIG:
      return {
        ...state,
        config: payload,
      };
    case SET_VALUE:
      return {
        ...state,
        value: payload,
      };
    case SET_RULE:
      return {
        ...state,
        config: {
          ...state.config,
          rule: payload,
        },
      };
    case SET_OPTIONS:
      return {
        ...state,
        config: {
          ...state.config,
          options: payload,
        },
      };
    case SET_QUESTION:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

// some IDs need to be disabled from editing
const DISABLED_IDS = [
  {
    finder: (q) => q.config.dropdownType === 'minimumAgeRestriction',
    questionType: 'presetDropdown',
  },
  {
    finder: (q) => q.config.dropdownType === 'workPermitRestriction',
    questionType: 'presetDropdown',
  },
];

function Question({
  isApplicationForm,
  isLastItem,
  isModal,
  wrapperRef,
  sectionIndex,
  // index,
  questionConfig,
  logicQuestionsIds,
  isSubmitted,
  onDelete,
  onChange,
  onSetLogic,
  onMoveItem,
}) {
  const [question, dispatch] = useReducer(questionReducer, DEFAULT_QUESTION);
  const [rules, setRules] = useState({});
  const [showRules, setShowRules] = useState(false);
  const [showWidth, setShowWidth] = useState(false);
  const [logic, setLogic] = useState();
  const [widthClassName, setWidthClassName] = useState('');
  const [pattern, setPattern] = useState();

  if (checkPermissions(['talentfunnel:admin'])) {
    newOptions.forEach((option) => {
      if (!isOptionInList(option, questionTypeOptions)) {
        questionTypeOptions.push(option);
      }
    });
  }

  useEffect(() => {
    dispatch({ payload: questionConfig, type: SET_QUESTION });

    const configObj = questionConfig.config || {};

    const ruleObj = configObj.rule
      ? Object.entries(configObj.rule).reduce((acc, [key, val]) => ({ rule: key, value: val }), {})
      : {};
    setRules(ruleObj);
    setShowRules(!!Object.keys(ruleObj).length);

    if (configObj.logic && Object.keys(configObj.logic)) {
      setLogic(configObj.logic);
    } else {
      setLogic();
    }

    if (configObj.classNames) {
      const widthClass = getWidthClass(questionConfig.config.classNames);
      if (widthClass.length) setWidthClassName(widthClass);
      setShowWidth(true);
    }

    if (typeof configObj.pattern === 'string') {
      setPattern(configObj.pattern);
    }
  }, [question.config.rule, questionConfig]);

  function handleChange(inputName, inputValue, inputConfig) {
    const questionData = {
      ...question,
      [inputName]: inputValue,
    };

    if (inputName === 'type') {
      questionData.screening = {
        answers: [],
        field: '',
        isScreeningQuestion: false,
      };

      if (inputValue === 'fileUpload') {
        questionData.attachmentType = 'OTHER';
      }

      // reset rquired param if you change question type
      dispatch({
        payload: {
          type: 'required',
          value: false,
        },
        type: SET_INPUT,
      });
      questionData.required = false;
    }

    if (inputConfig) questionData.config = inputConfig;

    dispatch({
      payload: {
        config: inputConfig,
        type: inputName,
        value: inputValue,
      },
      type: inputConfig ? SET_INPUT_CONFIG : SET_INPUT,
    });

    onChange(questionData);
  }

  function handleConfigChange(config, newQuestion = {}) {
    const questionData = {
      ...question,
      ...newQuestion,
      config: {
        ...question.config,
        ...config,
      },
    };

    dispatch({ payload: config, type: UPDATE_CONFIG });
    onChange(questionData);
  }

  function handleValueChange(value) {
    const questionData = {
      ...question,
      config: {
        ...question.config,
      },
      value,
    };

    dispatch({ payload: value, type: SET_VALUE });
    onChange(questionData);
  }

  function handleOptionChange(options) {
    const questionData = {
      ...question,
      config: {
        ...question.config,
        options,
      },
    };

    dispatch({ payload: options, type: SET_OPTIONS });
    onChange(questionData);
  }

  function handleRulesChange({ rule, value }) {
    const ruleObj = rule && value ? { [rule]: value } : {};

    const questionData = {
      ...question,
      config: {
        ...question.config,
        rule: { ...ruleObj },
      },
    };

    dispatch({ payload: ruleObj, type: SET_RULE });
    onChange(questionData);
  }

  if (question.type === 'hidden') return null;

  const options = (question.config || {}).options || [];
  const isOptionInput = /multipleChoice|checkboxes|dropdown/.test(question.type);

  let allowRules = question.type === 'text' || question.type === 'textarea';
  let rulesOpts = [
    { label: 'Character limit', value: '' },
    { label: 'Min. characters', value: 'minLength' },
    { label: 'Max. characters', value: 'maxLength' },
  ];
  let maxSize = 2000;
  if (question.type === 'checkboxes' && options.length > 1) {
    allowRules = true;
    rulesOpts = [
      { label: 'Item limit', value: '' },
      { label: 'Min. items', value: 'minItems' },
      { label: 'Max. items', value: 'maxItems' },
    ];
    maxSize = 10;
  }

  const isIdDisabled = DISABLED_IDS.some(
    ({ questionType, finder }) => questionType === question.type && finder(question),
  );
  const hideAllOptions = /informationalText/.test(question.type);
  const hideQuestionLabel = /informationalText/.test(question.type);

  // eslint-disable-next-line max-len
  const allowWidth =
    !/checkboxes|multipleChoice|informationalText|video|signature|bankDetails|bankDetailsUKValidated|visaDetails|hmrcDeclaration|nin|dateOfBirth|isoDate|ukPostcode/.test(
      question.type,
    );

  // can't apply logic to questions that don't exist & application forms can't be changed
  const hideLogicBtn = (sectionIndex === 0 && question.number === 1) || isApplicationForm;
  // disables are question ui except question label
  const isRestricted = question.config.restricted;

  return (
    <FormManagerContext.Consumer>
      {({ newQuestions, onOptionAdd, onOptionEdit }) => {
        const isTypeInvalid = isSubmitted && !question.type && !newQuestions.includes(question.id);
        const questionId = `question-${sectionIndex}-${question.number}`;

        return (
          <div className="flexiform-question">
            <Row className="mb-1">
              <Col>
                <Label for={questionId} className="d-flex gap-2">
                  {`Question ${question.number}`}
                  <ChatBotCompabilityIcon questionType={question.type} />
                </Label>
              </Col>
              <Col>
                <div className="flexiform-toolbar">
                  <Tippy content="Move question up" disabled={question.number === 1 || isApplicationForm}>
                    <div>
                      <Button
                        color="link"
                        disabled={question.number === 1 || isApplicationForm}
                        onClick={() => {
                          // The index of the question is one higher than its array index
                          const questionIndex = question.number - 1;
                          onMoveItem(questionIndex, questionIndex - 1);
                        }}
                        size="sm"
                      >
                        <IconSVG name="Chevron Up" width="1.5em" />
                      </Button>
                    </div>
                  </Tippy>
                  <Tippy content="Move question down" disabled={isLastItem || isApplicationForm}>
                    <div>
                      <Button
                        color="link"
                        disabled={isLastItem || isApplicationForm}
                        onClick={() => {
                          // The index of the question is one higher than its array index
                          const questionIndex = question.number - 1;
                          onMoveItem(questionIndex, questionIndex + 1);
                        }}
                        size="sm"
                      >
                        <IconSVG name="Chevron" width="1.5em" />
                      </Button>
                    </div>
                  </Tippy>
                  {/* !logic && <MoveQuestion sectionIndex={sectionIndex} questionIndex={index} /> */}
                  {isRestricted ? (
                    <RestrictedMarker tooltipMessage="Restricted Questions cannot be deleted and allow limited editing.">
                      Question
                    </RestrictedMarker>
                  ) : (
                    <>
                      {!hideLogicBtn && (
                        <button
                          className={cx(
                            'btn',
                            'btn-sm',
                            'xs',
                            'me-2',
                            { 'btn-default': !logic },
                            { 'btn-success': logic },
                          )}
                          onClick={() => onSetLogic(question.id, logic)}
                          type="button"
                        >
                          <i className={cx('fa', 'fa-btn', { 'fa-plus': !logic }, { 'fa-pencil': logic })} />
                          {` ${logic ? 'Edit' : 'Add'} Logic`}
                        </button>
                      )}
                      <DeleteButton
                        action={() => onDelete(question.number)}
                        className="btn-sm xs"
                        disabled={isApplicationForm && question.id !== 'cvUpload'}
                        floatRight={false}
                        iconOnly
                        label="Delete Question"
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="align-items-center" form>
              <Col>
                <FormGroup>
                  <Input
                    id={questionId}
                    name={questionId}
                    onChange={(e) => handleChange('text', e.target.value)}
                    type="text"
                    // invalid={isSubmitted && !question.text.length && !newQuestions.includes(question.id)}
                    value={question.text}
                  />
                  <ErrorMessage isInvalid={isSubmitted && !question.text.length && !newQuestions.includes(question.id)}>
                    Question name required
                  </ErrorMessage>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check inline>
                  {checkPermissions(['talentfunnel:admin']) && !isApplicationForm && (
                    <Label check>
                      <Input
                        checked={question.config.restricted || false}
                        className="standard-checkbox small"
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          handleConfigChange({ restricted: checked });
                        }}
                        type="checkbox"
                      />
                      <span>Restricted</span>
                    </Label>
                  )}
                  {isOptionInput && !isApplicationForm && (
                    <Label check>
                      <Tippy
                        content="You can not use as a screening question as logic is applied using this question."
                        disabled={!logicQuestionsIds.includes(question?.id)}
                      >
                        <div>
                          <Input
                            checked={question?.screening?.isScreeningQuestion || false}
                            className="standard-checkbox small"
                            disabled={isRestricted || logicQuestionsIds.includes(question?.id) || isApplicationForm}
                            onChange={(e) => {
                              handleChange('screening', {
                                ...question.screening,
                                isScreeningQuestion: e.target.checked,
                              });
                            }}
                            type="checkbox"
                          />
                          <span>Screening</span>
                        </div>
                      </Tippy>
                    </Label>
                  )}
                  {!hideAllOptions && !(isApplicationForm && question.id !== 'cvUpload') && (
                    <Label check>
                      <Input
                        checked={question.required || question.isRequired}
                        className="standard-checkbox small"
                        disabled={isRestricted}
                        onChange={(e) => {
                          handleChange('required', e.target.checked);
                        }}
                        type="checkbox"
                      />
                      <span>Required</span>
                    </Label>
                  )}
                  {!hideAllOptions && !isApplicationForm && (
                    <Label check>
                      <Input
                        checked={question.config.sensitive || false}
                        className="standard-checkbox small"
                        disabled={
                          isRestricted ||
                          /^nin|bankDetails|bankDetailsUKValidated$/.test(question.type) ||
                          isApplicationForm
                        }
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          handleConfigChange({ sensitive: checked });
                        }}
                        type="checkbox"
                      />
                      <span>Sensitive</span>
                    </Label>
                  )}
                  {allowRules && !hideAllOptions && !isApplicationForm && (
                    <Label check>
                      <Input
                        checked={showRules}
                        className="standard-checkbox small"
                        disabled={isRestricted || isApplicationForm}
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          setShowRules(checked);
                          if (!checked) handleRulesChange({});
                        }}
                        type="checkbox"
                      />
                      <span>Rules</span>
                    </Label>
                  )}
                  {allowWidth && !hideAllOptions && !isApplicationForm && (
                    <Label check>
                      <Input
                        checked={showWidth}
                        className="standard-checkbox small"
                        disabled={isRestricted || isApplicationForm}
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          setShowWidth(checked);
                          handleConfigChange({ classNames: checked ? 'w-100' : '' });
                        }}
                        type="checkbox"
                      />
                      <span>Change width</span>
                    </Label>
                  )}
                  {question.type === 'video' && (
                    <Label check>
                      <Input
                        checked={question.config.download || false}
                        className="standard-checkbox small"
                        disabled={isRestricted || isApplicationForm}
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          handleConfigChange({ download: checked });
                        }}
                        type="checkbox"
                      />
                      <span>Video Download</span>
                    </Label>
                  )}
                  {question.type === 'text' && !hideAllOptions && !isApplicationForm && (
                    <Label check>
                      <Input
                        checked={typeof question?.config?.pattern === 'string'}
                        className="standard-checkbox small"
                        disabled={isRestricted || isApplicationForm}
                        onChange={(e) => {
                          const {
                            target: { checked },
                          } = e;
                          handleConfigChange({ pattern: checked ? '' : null });
                        }}
                        type="checkbox"
                      />
                      <span>Regex pattern</span>
                    </Label>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="align-items-center" form>
              <Col>
                <FormGroup>
                  <Select
                    classNamePrefix="react-select"
                    isDisabled={isRestricted || isApplicationForm}
                    // className={cx({ 'is-invalid': isTypeInvalid })}
                    menuPortalTarget={isModal && wrapperRef.current ? wrapperRef.current : null}
                    onChange={({ value }) => {
                      let config;
                      if (/checkbox|multipleChoice|dropdown/.test(value)) {
                        const isArray = Array.isArray(question.config.options);
                        let opts = [];

                        if (isArray) {
                          opts = [...question.config.options];
                        } else {
                          opts = [DEFAULT_OPTION];
                          onOptionAdd(`${question.id}-0`);
                        }

                        config = {
                          ...question.config,
                          options: opts,
                        };
                      } else if (value === 'date') {
                        config = {
                          ...question.config,
                          limitToFutureDates: false,
                          limitToPastDates: false,
                        };
                      }
                      handleChange('type', value, config);
                    }}
                    options={questionTypeOptions}
                    placeholder="Question type"
                    value={findSelectedTypeOption(question.type, questionTypeOptions)}
                  />
                  <ErrorMessage isInvalid={isTypeInvalid}>Question type required</ErrorMessage>
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex">
                  {showRules && allowRules && !isApplicationForm && (
                    <RulesInputs
                      disabled={isRestricted || isApplicationForm}
                      items={rulesOpts}
                      maxSize={maxSize}
                      onChange={handleRulesChange}
                      rule={Object.keys(rules).length ? rules : DEFAULT_RULE}
                    />
                  )}
                  {/* eslint-disable-next-line max-len */}
                  {showWidth &&
                    !/checkboxes|multipleChoice|video|bankDetails|bankDetailsUKValidated|visaDetails|dateOfBirth|isoDate|hmrcDeclaration/.test(
                      question.type,
                    ) && (
                      <WidthDropdown
                        disabled={isRestricted}
                        onChange={(val) => handleConfigChange({ classNames: val })}
                        value={widthClassName}
                      />
                    )}
                </div>
              </Col>
            </Row>
            {/text|video/.test(question.type) && (
              <Row form>
                <Col xs="6">
                  {question.type === 'text' && (
                    <>
                      <p className="text-muted">Limited to single line answers</p>
                      {typeof question?.config?.pattern === 'string' && (
                        <FormGroup>
                          <Label>Validation Regex</Label>
                          <Input
                            disabled={isRestricted}
                            onChange={(e) => {
                              handleConfigChange({ pattern: e.target.value });
                            }}
                            type="text"
                            value={pattern || ''}
                          />
                        </FormGroup>
                      )}
                    </>
                  )}
                  {question.type === 'video' && <p className="text-muted">Limited to 2 minutes</p>}
                </Col>
              </Row>
            )}
            {(question.type === 'date' || question.type === 'isoDate') && (
              <Row>
                <Col>
                  <DateRadio
                    disabled={isRestricted}
                    limitToFutureDates={question.config.limitToFutureDates}
                    limitToPastDates={question.config.limitToPastDates}
                    name={`${question.id}-date-radio`}
                    onChange={(val) => handleConfigChange(val)}
                  />
                </Col>
              </Row>
            )}
            {question.type === 'phoneNumber' && (
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label>Default Code</Label>
                    <Select
                      isDisabled={isRestricted}
                      classNamePrefix="react-select"
                      onChange={({ value }) => {
                        handleConfigChange({ countryCode: value });
                      }}
                      options={countryList}
                      placeholder="Country"
                      value={countryList.find((country) => country.value === (question?.config?.countryCode || 'GB'))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {checkPermissions(['talentfunnel:admin']) && question.type === 'fileUpload' && !isApplicationForm && (
              <Row>
                <Col>
                  <FileTypeDropdown
                    disabled={isRestricted}
                    onChange={(val) => handleChange('attachmentType', val)}
                    value={question.attachmentType}
                  />
                </Col>
              </Row>
            )}
            {question.type === 'internationalAddressLookup' && (
              <Row>
                <Col>
                  <AddressCountryDropdown
                    disabled={isRestricted}
                    onChange={(val) => handleConfigChange({ countryCode: val })}
                    value={question.config.countryCode || 'GB'}
                  />
                </Col>
              </Row>
            )}
            {question.type === 'presetDropdown' && (
              <Row form>
                <Col sm="6">
                  <FormGroup>
                    <Label>Preset:</Label>
                    <PresetDropdownSelect
                      disabled={isRestricted}
                      onChange={(val, quest) => handleConfigChange({ dropdownType: val }, quest)}
                      value={question.config.dropdownType}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {question.type === 'informationalText' && (
              <Row form>
                <Col sm="12">
                  <InformationalText onChange={(val) => handleValueChange(val)} value={question.value} />
                </Col>
              </Row>
            )}
            {(question.type === 'dynamicSelect' || question.type === 'visaDetails') && (
              <Row form>
                <Col xs="6">
                  <FormGroup>
                    <Label>Url path</Label>
                    <Input
                      disabled={isRestricted}
                      onChange={(e) => handleConfigChange({ url: e.target.value })}
                      type="text"
                      value={question.config.url || ''}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {(question.type === 'bankDetails' || question.type === 'bankDetailsUKValidated') && (
              <Row form>
                <Col sm="6">
                  <BankDetailsOptions
                    disabled={isRestricted}
                    onChange={(val) => handleConfigChange({ bankDetails: val })}
                    value={question.config.bankDetails}
                  />
                </Col>
              </Row>
            )}
            {isOptionInput && (
              <Row form>
                <Col xs="6">
                  <OptionsManager
                    disabled={isRestricted}
                    handleChange={handleChange}
                    isSubmitted={isSubmitted}
                    onAdd={(opts) => {
                      onOptionAdd(`${question.id}-${opts.length - 1}`);
                      handleOptionChange(opts);
                    }}
                    onChange={(opt, idx) => {
                      const opts = spliceItem(options, idx, opt);
                      onOptionEdit(opt.id);
                      handleOptionChange(opts);
                    }}
                    onDelete={(idx) => {
                      const opts = spliceItem(options, idx);
                      handleOptionChange(opts);
                    }}
                    options={options}
                    screening={question.screening}
                    type={question.type}
                  />
                </Col>
              </Row>
            )}
            {checkPermissions(['talentfunnel:admin']) && (
              <Row>
                <Col sm="6" />
                <Col sm="6">
                  <FormGroup>
                    <Label for={`${questionId}-id`}>ID</Label>
                    <Input
                      defaultValue={question.id}
                      disabled={isApplicationForm || isIdDisabled}
                      id={`${questionId}-id`}
                      name={questionId}
                      onBlur={(e) => handleChange('id', e.target.value)}
                      onInput={(e) => {
                        // we are not allowed spaces in the id as it will break the form when reading
                        e.target.value = e.target.value.replace(/\s+/g, '');
                      }}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          </div>
        );
      }}
    </FormManagerContext.Consumer>
  );
}

Question.propTypes = {
  isApplicationForm: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isModal: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  // index: PropTypes.number,
  logicQuestionsIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveItem: PropTypes.func,
  onSetLogic: PropTypes.func,
  questionConfig: questionPropType,
  sectionIndex: PropTypes.number,
  wrapperRef: wrapperRefPropType,
};

Question.defaultProps = {
  isApplicationForm: false,
  isLastItem: false,
  isModal: false,
  isSubmitted: false,
  logicQuestionsIds: [],
  // index: 0,
  onChange: () => {},
  onDelete: () => {},
  onMoveItem: () => {},
  onSetLogic: () => {},
  questionConfig: DEFAULT_QUESTION,
  sectionIndex: 0,
  wrapperRef: null,
};

export default Question;
