export function formatFilters(filters) {
  if (!filters || !Array.isArray(filters)) return [];

  return filters.reduce((acc, filter) => {
    const { field, operation, value } = filter;

    if (field === 'questionAnswers') return [...acc, { field, operation, value }];

    return [
      ...acc,
      {
        field,
        operation,
        value: !Array.isArray(value) ? [value] : value,
      },
    ];
  }, []);
}

export function validatePostcode(postcode) {
  // eslint-disable-next-line max-len
  const rx =
    /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i;
  return rx.test(postcode);
}

export function formatPostcode(postcode) {
  const match = /^([A-Z]{1,2}[\dA-Z]{1,2})[ ]?(\d[A-Z]{2})$/i.exec(postcode);
  match.shift();
  return match.join(' ');
}

export function findLabel(arr, val) {
  if (arr.length === 0 && val === 'EQ') return 'Is';
  const obj = arr.find(({ condition }) => condition === val);
  if (obj) return obj.label;
  return '';
}

export function addTempFilter(tempFilters, filterName, newFilter) {
  return {
    ...tempFilters,
    [filterName]: [newFilter, ...(tempFilters[filterName] || [])],
  };
}

export function removeTempFilter(tempFilters, filter) {
  const { filterName, instanceId: instId } = filter;

  if (tempFilters[filterName]) {
    const clone = [...tempFilters[filterName]];
    const idx = clone.findIndex(({ instanceId }) => instanceId === instId);
    if (~idx) {
      clone.splice(idx, 1);
      return { ...tempFilters, [filterName]: clone };
    }
  }

  return { ...tempFilters };
}

export function truncateNumber(num, threshold = 9999) {
  if (!num) return 0;

  if (num > threshold) {
    const rnd = Math.floor(num / 1000) * 1000;
    return `${rnd.toString().slice(0, 2)}k${num > rnd ? '+' : ''}`;
  }

  return num;
}

export function isDupeFilter(filters, filterObj) {
  const { id, displayValue, operation } = filterObj;
  const byId = filters.some((f) => f.id === id);
  const byOp = filters.some((f) => f.operation === operation);
  const byVal = filters.some((f) => f.displayValue === displayValue);
  return byId && byOp && byVal;
}

export function getFilteredFields(filters) {
  return filters.filter(({ field }) => field === 'questionAnswers').map(({ value }) => value?.question || '');
}
