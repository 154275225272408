import React from 'react';
import BaseSVG from '../BaseSVG';

function Tablet(props) {
  return (
    <BaseSVG
      {...props}
      name="Tablet"
      viewBox="0 0 512 512"
      // eslint-disable-next-line max-len
      d="M419.438,0H92.563C74.078,0,59.078,15,59.078,33.5v445c0,18.5,15,33.5,33.484,33.5h326.875 c18.5,0,33.484-15,33.484-33.5v-445C452.922,15,437.938,0,419.438,0z M259.938,490.984c-7.969,0-14.438-6.453-14.438-14.453 c0-7.969,6.469-14.438,14.438-14.438c7.984,0,14.438,6.469,14.438,14.438C274.375,484.531,267.922,490.984,259.938,490.984z M413.531,441.109H98.469V39.375h315.063V441.109z"
    />
  );
}

export default Tablet;
