import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { DropdownManager, SubMenuContext } from '../Dropdowns';
import IconSVG from '../SVG/IconSVG';
import { stopPolling, startPolling } from '../../../js/actions/eventNotificationActions';

function Button({ iconOnly, onClick, children, innerRef, isDisabled, tooltipContent, tooltipMaxWidth, className }) {
  return (
    <Tippy
      content={tooltipContent}
      disabled={!isDisabled || !tooltipContent.length}
      maxWidth={tooltipMaxWidth}
      theme="ats"
    >
      <button
        ref={innerRef}
        className={cx(
          'action-button',
          'btn-outline',
          { 'btn-action-cog no-text': iconOnly },
          { 'btn btn-action-cog': !iconOnly },
          { 'is-disabled': isDisabled },
          className,
        )}
        onClick={() => {
          if (!isDisabled) onClick();
        }}
        type="button"
      >
        {children}
      </button>
    </Tippy>
  );
}

Button.propTypes = {
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  isDisabled: PropTypes.bool,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tooltipMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Button.defaultProps = {
  iconOnly: true,
  onClick: () => {},
  children: null,
  isDisabled: false,
  tooltipContent: '',
  tooltipMaxWidth: 200,
  className: null,
};

function ActionButton({
  menuItems,
  isOpen,
  onToggle,
  isMenuButton,
  menuStyle,
  menuClassName,
  subMenuProps,
  title,
  position,
  pauseNotificationsOnOpen,
  pausePoll,
  startPoll,
  ...buttonProps
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenuId, setShowMenuId] = useState('');

  useEffect(() => {
    if (isOpen !== showMenu) {
      setShowMenu(isOpen);
      setShowMenuId('');
    }
  }, [isOpen, showMenu]);

  function handleToggle(openMenu) {
    setShowMenu(openMenu);
    onToggle(openMenu);

    if (pauseNotificationsOnOpen) {
      if (openMenu) {
        pausePoll();
      } else {
        startPoll();
      }
    }
  }

  if (!menuItems.length && isMenuButton) return null;

  const {
    buttonLabel,
    iconOnly,
    isDisabled,
    tooltipContent,
    tooltipMaxWidth,
    className,
    iconName = 'Gear',
  } = buttonProps;

  return (
    <SubMenuContext.Provider
      value={{
        ...subMenuProps,
        onClick: (itemId) => setShowMenuId(showSubMenuId !== itemId ? itemId : ''),
        showSubMenuId,
      }}
    >
      <DropdownManager
        menuClassName={`action-button-menu ${menuClassName || ''}`}
        menuItems={menuItems}
        menuStyle={menuStyle}
        onDocumentClick={() => handleToggle(false)}
        position={position}
        showMenu={showMenu}
        title={title}
        trigger={({ ref }) => (
          <Button
            className={className}
            iconOnly={iconOnly}
            innerRef={ref}
            isDisabled={isDisabled}
            onClick={() => handleToggle(!showMenu)}
            tooltipContent={tooltipContent}
            tooltipMaxWidth={tooltipMaxWidth}
          >
            <IconSVG name={iconName} />
            {buttonLabel}
          </Button>
        )}
      />
    </SubMenuContext.Provider>
  );
}

ActionButton.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  isMenuButton: PropTypes.bool,
  menuClassName: PropTypes.string,
  menuStyle: PropTypes.shape(),
  subMenuProps: PropTypes.shape(),
  title: PropTypes.string,
  position: PropTypes.string,
  pauseNotificationsOnOpen: PropTypes.bool,
  pausePoll: PropTypes.func,
  startPoll: PropTypes.func,
};

ActionButton.defaultProps = {
  menuItems: [],
  isOpen: false,
  onToggle: () => {},
  isMenuButton: true,
  menuClassName: null,
  menuStyle: {},
  subMenuProps: {},
  title: null,
  position: 'bottom',
  pauseNotificationsOnOpen: false,
  pausePoll: () => {},
  startPoll: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    pausePoll: () => {
      dispatch(stopPolling());
    },
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(null, mapDispatchToProps)(ActionButton);
