import React from 'react';
import { Container } from 'reactstrap';
// routes config
import SiteWrapper from '../SiteWrapper';

function DefaultLayout(props) {
  return (
    <SiteWrapper>
      <Container fluid className="h-100" style={{ minWidth: '1100px', display: 'flex', flexDirection: 'column' }}>
        {props.children}
      </Container>
    </SiteWrapper>
  );
}

export default DefaultLayout;
