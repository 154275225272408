import React from 'react';
import Import from './Import';
import { checkPermissions } from '../js/auth/AuthUtils';

function Homepage(props) {
  return (
    <Import
      touch={() => import('../touch/Dashboard/Dashboard')}
      desktop={() => {
        const allowDashboard = checkPermissions(['dashboard:view']);
        if (allowDashboard) return import('../views/Dashboard/Dashboard');
        return import('../views/Vacancy/View/VacancyList/VacancyList');
      }}
    >
      {(Comp) => <Comp {...props} />}
    </Import>
  );
}

export default Homepage;
