import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable'; // Stable language feature for targeted browsers
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import Provider from 'react-redux/es/components/Provider';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './js/store';
import { LanguageProvider } from './views/Base/contexts';

serviceWorker.unregister();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <LanguageProvider store={store}>
          <App />
        </LanguageProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
