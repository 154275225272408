import React from 'react';

const LoadingIcon = ({ size = 100, spinDuration = 3 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width={size} height={size}>
      <style>
        {`
          @keyframes pulse {
            0%, 100% { r: 12; opacity: 0.7; }
            50% { r: 15; opacity: 1; }
          }
          @keyframes spinCounterclockwise {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(-360deg); }
          }
          .dot {
            animation: pulse 2s infinite;
          }
          #dot1 { animation-delay: 0s; }
          #dot2 { animation-delay: 0.6s; }
          #dot3 { animation-delay: 1.2s; }
          #dotGroup {
            animation: spinCounterclockwise ${spinDuration}s linear infinite;
            transform-origin: center;
          }
        `}
      </style>

      <g id="dotGroup">
        <circle id="dot1" className="dot" cx="34" cy="66" r="12" fill="#7FFFD4" />
        <circle id="dot2" className="dot" cx="50" cy="34" r="12" fill="#90EE90" />
        <circle id="dot3" className="dot" cx="66" cy="66" r="12" fill="#9370DB" />
      </g>
    </svg>
  );
};

export default LoadingIcon;
