import {
  ADD_FOURTH_USER,
  REMOVE_FOURTH_USER,
  ADD_FOURTH_DATA,
  REMOVE_FOURTH_DATA,
  ADD_FOURTH_INTEGRATION,
  ADD_FOURTH_GLOBAL_CONFIG,
} from '../constants/actionTypes';

const defaultState = {
  connected: false,
  user: { username: '', organisationId: '' },
  data: {},
  globalConfig: {},
  globalConfigLoaded: false,
};

export default function fourthReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_FOURTH_INTEGRATION:
      return { ...payload, connected: true };
    case ADD_FOURTH_USER:
      return {
        ...state,
        connected: true,
        user: payload,
      };
    case REMOVE_FOURTH_USER:
      return { ...defaultState };
    case ADD_FOURTH_DATA:
      return {
        ...state,
        data: payload,
      };
    case REMOVE_FOURTH_DATA:
      return {
        ...state,
        data: {},
        globalConfig: {},
        globalConfigLoaded: false,
      };
    case ADD_FOURTH_GLOBAL_CONFIG:
      return {
        ...state,
        globalConfig: payload,
        globalConfigLoaded: true,
      };
    default:
      return state;
  }
}
