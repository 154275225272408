import {
  SET_USER_AUTH_ERROR,
  UPDATE_ACTIVE_ACCOUNT,
  USER_AUTH_UPDATE,
  USER_DETAILS_UPDATE,
  USER_SETTINGS_UPDATE,
  USER_RESET_STATE,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  INCREMENT_FAILED_AUTH_COUNT,
  CLEAR_FAILED_AUTH_COUNT,
} from '../constants/actionTypes';

export const updateUserDetails = (userDetails) => ({
  type: USER_DETAILS_UPDATE,
  payload: userDetails,
});

export const updateUserSettings = (userSettings) => ({
  type: USER_SETTINGS_UPDATE,
  payload: userSettings,
});

export const updateAuthDetails = (authDetails) => ({
  type: USER_AUTH_UPDATE,
  payload: authDetails,
});

export const setAuthError = (error) => ({
  type: SET_USER_AUTH_ERROR,
  payload: error,
});

export const incrementFailedAuthCount = () => ({
  type: INCREMENT_FAILED_AUTH_COUNT,
});

export const clearFailedAuthCount = () => ({
  type: CLEAR_FAILED_AUTH_COUNT,
});

export const updateActiveAccount = (selectedAccount) => ({
  type: UPDATE_ACTIVE_ACCOUNT,
  payload: selectedAccount,
});

export function addAccount(accObj) {
  return {
    type: ADD_ACCOUNT,
    payload: { ...accObj },
  };
}

export function updateAccount(accObj) {
  return {
    type: UPDATE_ACCOUNT,
    payload: { ...accObj },
  };
}

export function deleteAccount(accountId) {
  return {
    type: DELETE_ACCOUNT,
    payload: accountId,
  };
}

export const resetUserState = () => ({
  type: USER_RESET_STATE,
});
