import axios from 'axios';
import { getCommonHeaders, getCommonHeadersNoContentType } from '../common-api-utils';
import config from '../../config/config';

const { api, integrations: { fourth } } = config;

export async function linkAccount(name, password, organisationId) {
  if (name && password && organisationId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${fourth.userPath}`,
      { name, password, organisationId },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function unlinkAccount(name, password, organisationId) {
  if (name && password && organisationId) {
    const result = await axios.delete(
      `${api.middlewareAPIURL}${fourth.userPath}`,
      {
        headers: getCommonHeaders(),
        data: { name, password, organisationId },
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateLinkedAccount(name, password, organisationId) {
  if (name && password && organisationId) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${fourth.userPath}`,
      { name, password, organisationId },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getLinkedAccount() {
  const result = await axios.get(
    `${api.middlewareAPIURL}${fourth.userPath}`,
    { headers: { ...getCommonHeaders() } },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function uploadCsvFieldData(file) {
  if (file) {
    const formData = new FormData();
    formData.append('file', file);

    const result = await axios.put(
      `${api.middlewareAPIURL}${fourth.dataPath}`,
      formData,
      { headers: { ...getCommonHeadersNoContentType() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getCsvFieldData() {
  const result = await axios.get(
    `${api.middlewareAPIURL}${fourth.dataPath}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getFieldData(fieldName, query = '') {
  if (fieldName) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${fourth.dataPath}/${fieldName}${query.length ? `?${query}` : ''}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToFourth(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${fourth.submitPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function checkFourthSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${fourth.statusPath}/${candidateId}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getFourthSubmissionData(candidateId) {
  if (candidateId) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${fourth.submitPath}/${candidateId}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getGlobalConfig() {
  const result = await axios.get(
    `${api.middlewareAPIURL}${fourth.globalConfigPath}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createGlobalConfig(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${fourth.globalConfigPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
