import {
  RESET_APPLICATION_STATE,
  ADD_APPLICATION_FORM_DATA,
} from '../constants/actionTypes';

const initialState = {
  applicationForms: {},
  applicationBuilderData: undefined,
  applicationBuilderDataId: undefined,
  creationError: undefined,
};

const resetState = {
  applicationBuilderData: undefined,
  applicationUrl: undefined,
  creationError: undefined,
};

const applicationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APPLICATION_FORM_DATA:
      return {
        ...state,
        applicationForms: {
          ...state.applicationForms,
          [action.payload.applicationFormId]: action.payload,
        },
      };
    case RESET_APPLICATION_STATE:
      return resetState;
    default:
      return state;
  }
};

export default applicationFormReducer;
