import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';

const datePropType = PropTypes.arrayOf(PropTypes.number);

const positionHistoryPropType = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  startDate: datePropType,
  endDate: datePropType,
}));

function DateRange({ startDate, endDate, current }) {
  const sDate = moment(startDate);

  if (!sDate.isValid()) return null;

  let eDate = '';
  if (current) eDate = ' - Present';

  if (endDate) {
    const mDate = moment(endDate);
    if (mDate.isValid()) eDate = ` - ${mDate.format('LL')}`;
  }

  return <p className="mb-2">{`${sDate.format('LL')}${eDate}`}</p>;
}

DateRange.propTypes = {
  startDate: datePropType,
  endDate: datePropType,
  current: PropTypes.bool,
};

DateRange.defaultProps = {
  startDate: null,
  endDate: null,
  current: false,
};

function Positions({ data }) {
  if (!data || !data.length) return null;

  return data.map(({
    title,
    description,
    startDate,
    endDate,
  }, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`${title}-${i}`} className="employment-position mb-3 clearfix">
      <p className="font-weight-bold mb-1 text-capitalize">{title}</p>
      <DateRange startDate={startDate} endDate={endDate} />
      <p>{description}</p>
    </div>
  ));
}

Positions.propTypes = {
  data: positionHistoryPropType,
};

Positions.defaultProps = {
  data: [],
};

export default function EmploymentHistory({ data, showTitle, title }) {
  if (!data || !data.length) return null;

  return (
    <Row className="large-content">
      <Col>
        {showTitle && <p className="detail-title">{title}</p>}
        <div className="detail-value pl-2">
          {data.map(({
            organization,
            startDate,
            endDate,
            current,
            positionHistory,
          }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`emp-${i}`}>
              <div className="mb-4">
                {organization.name && <h5 className="mb-2">{organization.name}</h5>}
                <DateRange startDate={startDate} endDate={endDate} current={current} />
                <Positions data={positionHistory} />
              </div>
            </Fragment>
          ))}
        </div>
      </Col>
    </Row>
  );
}

EmploymentHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    current: PropTypes.bool,
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
    positionHistory: positionHistoryPropType,
    startDate: datePropType,
    endDate: datePropType,
  })),
  showTitle: PropTypes.bool,
  title: PropTypes.string,
};

EmploymentHistory.defaultProps = {
  data: [],
  showTitle: true,
  title: 'Employment',
};
