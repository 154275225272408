import { SET_RR_DATA } from '../constants/actionTypes';

const defaultState = {
  companyId: [],
  contractBasis: [],
  documentCategory: [],
  entityId: [],
  groupId: [],
  positionId: [],
};

export default function rotaReadyReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_RR_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
