import {
  ADD_ACTIVE_FILTER,
  DELETE_ACTIVE_FILTER,
  RESET_FILTER_STATE,
  UPDATE_ACTIVE_FILTER,
  BULK_ADD_FILTERS,
} from '../constants/actionTypes';

export const addActiveFilter = (filter) => ({
  type: ADD_ACTIVE_FILTER,
  payload: filter,
});

export const bulkAddFilters = (filters) => ({
  type: BULK_ADD_FILTERS,
  payload: filters,
});

export const updateActiveFilter = (filter) => ({
  type: UPDATE_ACTIVE_FILTER,
  payload: filter,
});

export const deleteFilter = (filter) => ({
  type: DELETE_ACTIVE_FILTER,
  payload: filter,
});

export const resetFilterState = () => ({
  type: RESET_FILTER_STATE,
});
