/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';
import InputRange from 'react-input-range';
import ColorInput from '../../../../Branding/ColorInput';
import { SelectInput } from '../../../../../../Base/Forms/Custom/CommonComponents';

require('rc-collapse/assets/index.css');

function Buttons({ currentMUITheme, changeStyle, customFonts }) {
  return (
    <>
      <FormGroup>
        <Label for="button-colour">Button Colour:</Label>
        <ColorInput
          className="w-50"
          id="button-colour"
          onChange={(val) => {
            changeStyle('components.MuiButton.styleOverrides.root.backgroundColor', val);
          }}
          swatchClassName="mt-0"
          value={currentMUITheme?.components?.MuiButton?.styleOverrides?.root?.backgroundColor}
        />
      </FormGroup>
      <FormGroup>
        <Label for="button-colour">Button Hover Colour:</Label>
        <ColorInput
          className="w-50"
          id="button-colour"
          onChange={(val) => {
            changeStyle('components.MuiButton.styleOverrides.root["&:hover"].backgroundColor', val);
          }}
          swatchClassName="mt-0"
          value={currentMUITheme?.components?.MuiButton?.styleOverrides?.root['&:hover']?.backgroundColor}
        />
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font</Label>
        <SelectInput
          onChange={(val) => {
            changeStyle('typography.button.fontFamily', val);
          }}
          options={customFonts.map((a) => {
            return { label: a.url, value: a.url };
          })}
          value={currentMUITheme?.typography?.button.fontFamily}
        />
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Size</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={48}
            minValue={16}
            onChange={(val) => {
              changeStyle('typography.button.fontSize', val);
            }}
            value={currentMUITheme?.typography.button.fontSize}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Weight</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={1000}
            minValue={100}
            onChange={(val) => {
              changeStyle('typography.button.fontWeight', val);
            }}
            step={100}
            value={currentMUITheme?.typography?.button.fontWeight}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Line Height</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={3}
            minValue={0.5}
            onChange={(val) => {
              changeStyle('typography.button.lineHeight', val);
            }}
            step={0.1}
            value={currentMUITheme?.typography?.button.lineHeight}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Letter Spacing</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={150}
            minValue={1}
            onChange={(val) => {
              changeStyle('typography.button.letterSpacing', `${val / 100}em`);
            }}
            value={Number.parseFloat(currentMUITheme?.typography?.button.letterSpacing.replace('em', '')) * 100}
          />
        </Col>
      </FormGroup>
    </>
  );
}

Buttons.propTypes = {
  currentMUITheme: PropTypes.shape(),
  changeStyle: PropTypes.func,
  customFonts: PropTypes.shape(),
};

Buttons.defaultProps = {
  currentMUITheme: {},
  changeStyle: () => {},
  customFonts: [],
};

export default Buttons;
