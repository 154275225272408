import React from 'react';
import PropTypes from 'prop-types';
import videoIcon from '../../../../assets/img/icons/video/black.svg';

function VideoIconLink({ videoRoomId, bookedInCandidates }) {
  if (!videoRoomId) return null;

  let inviteIdParam = '';
  const [candidateBooking] = bookedInCandidates;
  if (candidateBooking?.inviteId) inviteIdParam = `&iid=${candidateBooking.inviteId}`;

  return (
    <a
      href={`./video/interview?id=${videoRoomId}${inviteIdParam}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={videoIcon} alt="" width="20" />
    </a>
  );
}

VideoIconLink.propTypes = {
  videoRoomId: PropTypes.string,
  bookedInCandidates: PropTypes.arrayOf(PropTypes.shape()),
};

VideoIconLink.defaultProps = {
  videoRoomId: null,
  bookedInCandidates: [],
};

export default VideoIconLink;
