import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { api, scheduling } = config;

export async function getEventType(id) {
  const endPointPath = id ? `/${id}` : '';

  const result = await axios.get(`${api.middlewareAPIURL}${scheduling.eventTypePath}${endPointPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createEventType(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${scheduling.eventTypePath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateEventType(data = {}) {
  if (Object.keys(data).length && data.id && data.id.length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${scheduling.eventTypePath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function deleteEventType(id) {
  if (id) {
    const result = await axios.delete(`${api.middlewareAPIURL}${scheduling.eventTypePath}/${id}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
