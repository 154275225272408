import PropTypes from 'prop-types';
import VideoModalLink from './VideoModalLink';
import AddressObjectHandler from './AddressObjectHandler';

function ObjectHandler({ obj, candidateId }) {
  if (!Object.keys(obj).length) return null;

  const { type, fileId, transcription, formattedAddress } = obj;

  if (type && type === 'VIDEO') {
    return <VideoModalLink candidateId={candidateId} fileId={fileId} transcription={transcription} />;
  }

  if (formattedAddress) return <AddressObjectHandler obj={obj} />;

  return null;
}

ObjectHandler.propTypes = {
  candidateId: PropTypes.string,
  obj: PropTypes.shape(),
};

ObjectHandler.defaultProps = {
  candidateId: null,
  obj: {},
};

export default ObjectHandler;
