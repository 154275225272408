import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { ubeya },
} = config;

// eslint-disable-next-line import/prefer-default-export
export async function submitToUbeya(candidateId) {
  if (candidateId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${ubeya.basePath}/${candidateId}`,
      {},
      {
        headers: getCommonHeaders(),
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }
  return null;
}
