import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

function InformationalText({
  value,
  onChange,
}) {
  const quillRef = useRef();
  const typingTimer = useRef();
  const [content, setContent] = useState(value);

  const doneTypingInterval = 1000;

  useEffect(() => {
    clearTimeout(typingTimer.current);
    typingTimer.current = setTimeout(() => onChange(content), doneTypingInterval);
  }, [content]);

  return (
    <ReactQuill
      ref={quillRef}
      value={content || ''}
      className="message-body with-toolbar"
      onChange={(val) => setContent(val)}
    />
  );
}

InformationalText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

InformationalText.defaultProps = {
  value: '',
  onChange: () => { },
};

export default InformationalText;
