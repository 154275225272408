import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { setAuthError } from '../../../js/actions/userActions';
import { requestStatuses } from '../../../js/constants/requestStatuses';
import { ResolveLogo } from '../../Base/SVG';
import AccessCodeEntryView from '../../Applications/External/AccessCodeEntryView';
import { doTwoFactorLogin } from '../../../js/auth/AuthUtils';
import { useNavigate } from 'react-router-dom';

function TwoFAChallenge() {
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [trustBrowser, setTrustBrowser] = useState(false);
  const { search } = useLocation();
  const { id, rMe } = queryString.parse(search);
  const cookies = new Cookies();
  const navigate = useNavigate();

  async function handleCompletedCode(code) {
    const result = await doTwoFactorLogin(code, id, true, rMe || false, () => {}, navigate);

    if (typeof result === 'string') {
      if (result === requestStatuses.OFFLINE_ERROR) {
        setErrorMessage(
          <p style={{ color: '#db0909' }} className="text-center">
            The allotted time to enter your code has expired please <a href="/login">Login</a> again
          </p>,
        );
      } else if (result === requestStatuses.INVALID_CREDENTIALS) {
        setErrorMessage(
          <p style={{ color: '#db0909' }} className="text-center">
            The authentication code you have entered is invalid, please try again
          </p>,
        );
      } else {
        setErrorMessage(
          <p style={{ color: '#db0909' }} className="text-center">
            An error has occurred, please try again. If this continues please try to
            <a href="/login">login</a> again
          </p>,
        );
      }
    }
  }

  return (
    <div className="app flex-row align-items-center auth-page">
      <Container>
        <Row className="justify-content-center">
          <Col xl={5} lg={6} md={7} sm={10} xs={12}>
            <CardGroup>
              <Card className="p-4 rounded">
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <div className="text-center">
                        <ResolveLogo />
                        <h4 className="mt-2 mb-1 text-muted">Enter 2FA Code</h4>
                        <p className="mb-5">Please enter a valid code from your authentication app</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3 mt-2">
                    <Col>
                      <AccessCodeEntryView
                        onAccessCodeComplete={handleCompletedCode}
                        isLoading={false}
                        btnLabel="Login"
                        error={errorMessage}
                        numInputs={6}
                      />
                    </Col>
                  </Row>
                  {errorMessage && (
                    <Row style={{ marginTop: '70px' }}>
                      <Col>{errorMessage}</Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

TwoFAChallenge.propTypes = {
  authError: PropTypes.string,
  dispatchAuthError: PropTypes.func,
};

TwoFAChallenge.defaultProps = {
  authError: null,
  dispatchAuthError: () => {},
};

function mapStateToProps(state) {
  return { authError: state.userData.userAuth.authError, failedAuthCount: state.userData.failedAuthCount };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAuthError: (error) => {
      dispatch(setAuthError(error));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFAChallenge);
