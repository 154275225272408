import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonComp from './ButtonComp';

export default function IconButton({
  action,
  onFocus,
  label,
  size,
  type,
  disabled,
  isLoading,
  floatRight,
  className,
  iconClassName,
  iconOnly,
  SVGIcon,
}) {
  return (
    <ButtonComp
      className={cx('icon-button', className, { 'float-end': floatRight })}
      disabled={disabled}
      isLoading={isLoading}
      loadingIconColor="white"
      onClick={action}
      onFocus={onFocus}
      size={size}
      type={type}
    >
      {SVGIcon || (
        <span>
          <i className={`${!iconOnly ? 'fa-btn' : ''} ${iconClassName || ''}`} />
        </span>
      )}
      {!iconOnly && label}
    </ButtonComp>
  );
}

IconButton.propTypes = {
  action: PropTypes.func,
  onFocus: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  floatRight: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  iconOnly: PropTypes.bool,
  SVGIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

IconButton.defaultProps = {
  action: () => {},
  onFocus: () => {},
  label: null,
  size: null,
  type: 'button',
  disabled: false,
  isLoading: false,
  floatRight: false,
  className: null,
  iconClassName: null,
  iconOnly: false,
  SVGIcon: null,
};
