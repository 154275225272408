import { useReducer, useCallback } from 'react';

const SET_RESOLVED = 'SET_RESOLVED';
const SET_REJECTED = 'SET_REJECTED';

const defaultState = {
  isPending: true,
  isRejected: false,
  rejectionStatus: undefined,
};

function pageStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_RESOLVED:
      return {
        ...state,
        isPending: false,
      };
    case SET_REJECTED:
      return {
        ...state,
        isPending: false,
        isRejected: true,
        rejectionStatus: payload,
      };
    default:
      return state;
  }
}

export default function usePageLoading() {
  const [pageState, dispatch] = useReducer(pageStateReducer, defaultState);

  const setPageResolved = useCallback(() => dispatch({ type: SET_RESOLVED }), []);
  const setPageRejected = useCallback((payload) => dispatch({ type: SET_REJECTED, payload }), []);

  return {
    pageState,
    setPageResolved,
    setPageRejected,
  };
}
