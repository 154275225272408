import React from 'react';
import Import from './Import';

function Dashboard(props) {
  return (
    <Import
      touch={() => import('../touch/Dashboard/Dashboard')}
      desktop={() => import('../views/Dashboard/Dashboard')}
    >
      {(Comp) => <Comp {...props} />}
    </Import>
  );
}

export default Dashboard;
