import React from 'react';
import moment from 'moment';
import { Alert } from 'reactstrap';

function getMessage(status, statusUpdateDateTime) {
  if (status === 'JUST_APPROVED') {
    return 'You have successfully authorised this vacancy';
  } else if (status === 'JUST_REJECTED') {
    return 'You have successfully rejected this vacancy';
  } else {
    return `You have already responded to this request on ${statusUpdateDateTime} with: ${status}.`;
  }
}

const ResponseBanner = ({ status, statusUpdateDateTime }) => {
  let color = 'success';

  if (status === 'REJECTED' || status === 'JUST_REJECTED') {
    color = 'danger';
  }

  const date = moment.utc(statusUpdateDateTime).local().format('MMMM Do YYYY, h:mm:ss A');
  const message = getMessage(status, date);
  return <Alert color={color}>{message}</Alert>;
};

export default ResponseBanner;
