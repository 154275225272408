// eslint-disable-next-line import/prefer-default-export
export function moveArrayItem(arr = [], from, to) {
  if (from === to) return arr;

  const clone = [...arr];

  if (clone[from] && clone.length > to) {
    const elm = clone.splice(from, 1)[0];
    clone.splice(to, 0, elm);
  }

  return clone;
}
