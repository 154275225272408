export default {
  en: {
    application: 'Application',
    for: 'for',
    accessCodeTitle: 'Enter the access code',
    viewCandidateBtnLabel: 'View Candidate',
    invalidAccessCodeError: 'Your access code is invalid, please try again',
    notFoundError: 'We are unable to locate the candidate please check the email you received and try again',
    expiredError: 'Your access to this candidate has expired, please contact your administrator',
    revokedError: 'Your access to this candidate has been revoked, please contact your administrator',
    generalError: 'There has been an error accessing the candidate please try again later',
  },
  es: {},
};
