import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

function UTMRow({ isTouch, title, value }) {
  if (!value) return null;

  return (
    <Row>
      {isTouch ? (
        <Fragment>
          <Col xs="4">
            <p className="detail-title">{title}</p>
          </Col>
          <Col>
            <p className="detail-value">{value}</p>
          </Col>
        </Fragment>
      ) : (
        <Col className="d-flex">
          <p className="detail-title me-3">{title}</p>
          <p className="detail-value">{value}</p>
        </Col>
      )}
    </Row>
  );
}

UTMRow.propTypes = {
  isTouch: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
};

UTMRow.defaultProps = {
  isTouch: false,
  title: null,
  value: null,
};

function UTMDisplay({ utm, isTouch }) {
  if (!Object.keys(utm).length) return null;

  const { utmSource, utmMedium, utmCampaign } = utm;

  return (
    <Fragment>
      <UTMRow isTouch={isTouch} title="Referred" value={utmSource} />
      <UTMRow isTouch={isTouch} title="Medium" value={utmMedium} />
      <UTMRow isTouch={isTouch} title="Campaign" value={utmCampaign} />
    </Fragment>
  );
}

UTMDisplay.propTypes = {
  utm: PropTypes.shape(),
  isTouch: PropTypes.bool,
};

UTMDisplay.defaultProps = {
  utm: {},
  isTouch: false,
};

export default UTMDisplay;
