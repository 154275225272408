import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useMounted } from '../../hooks';
import { getVacancyListForAccounts } from '../../../../api/VacancyAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';

function VacancyFilter({ onSelect }) {
  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  async function getVacancyList() {
    const vacancies = await retryableAPICall(() => getVacancyListForAccounts());

    if (typeof vacancies !== 'string') {
      const valMap = vacancies.map(({ id, positionProfile }) => ({
        default: false,
        label: `${positionProfile.title} - ${positionProfile.location.city}`,
        value: id,
      }));
      setOptionData(valMap.sort((a, b) => a.label.localeCompare(b.label)));
    } else {
      toast.error('Could not load vacancies so unable to filter. Please try again later.');
    }
  }
  useEffect(() => {
    if (isMounted()) {
      getVacancyList();
    }
  }, [isMounted]);

  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      width: '500px',
    }),
  };
  return (
    <Select
      autoSize
      classNamePrefix="react-select"
      id="filter-question-select"
      isClearable
      isMulti
      menuPosition="fixed"
      onChange={(vals) => onSelect(vals, optionData)}
      options={optionData}
      placeholder="Search Vacancies"
      styles={customStyles}
      //   value={questionText ? { value: questionText, label: questionText } : null}
    />
  );

  //  children({ optionData });
}

VacancyFilter.propTypes = {
  onSelect: PropTypes.func,
};

VacancyFilter.defaultProps = {
  onSelect: () => {},
};

export default VacancyFilter;
