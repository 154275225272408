/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateButton, CancelButton } from '../../../Base/Buttons';
import { addGlobalConfig } from '../../../../js/actions/fourthActions';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getGlobalConfig, createGlobalConfig } from '../../../../api/Integrations/FourthAPI';
import { Confirmation } from '../../../Base/Modal';
import { useMounted } from '../../../Base/hooks';

async function globalConfigProxy(onSuccess = () => {}, data) {
  let resp;

  if (data) {
    resp = await retryableAPICall(() => createGlobalConfig(data));
  } else {
    resp = await retryableAPICall(() => getGlobalConfig());
  }

  if (typeof resp === 'string' && resp.length) {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching Fourth Config. Please try again later or contact support');
    }
  } else {
    onSuccess(resp);
  }
}

function FourthGlobalConfig({ globalConfig, setGlobalConfig, globalConfigLoaded }) {
  const isMounted = useMounted();
  const [configValue, setConfigValue] = useState('');
  const [benchmarkCfg, setBenchmarkCfg] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    const confStr = JSON.stringify(globalConfig);
    setConfigValue(confStr);
    setBenchmarkCfg(confStr);
  }, [globalConfig]);

  useEffect(() => {
    if (isMounted() && !globalConfigLoaded) {
      globalConfigProxy((resp) => setGlobalConfig(resp));
    }
  }, [globalConfigLoaded, isMounted, setGlobalConfig]);

  function handleSave() {
    try {
      const configObj = JSON.parse(configValue);

      globalConfigProxy(
        () => {
          setGlobalConfig(configObj);
          toast.success('Global Config saved successfully');
          // payload has to be wrapped in config prop
        },
        { config: { ...configObj } },
      );
    } catch (e) {
      toast.error('Invalid JSON format, please correct and try again');
    }
  }

  return (
    <Fragment>
      <hr />
      <FormGroup>
        <Label for="globalConfig">Global Config</Label>
        <Input
          id="globalConfig"
          type="textarea"
          rows="5"
          style={{ minHeight: '120px', backgroundColor: '#fff' }}
          value={configValue}
          onChange={(e) => {
            setConfigValue(e.target.value);
          }}
        />
      </FormGroup>
      <CreateButton
        className="mt-2"
        label="Update Global Config"
        floatRight={false}
        action={() => setShowConfirm(true)}
      />
      {benchmarkCfg !== configValue && (
        <CancelButton className="mt-2 ms-2" floatRight={false} action={() => setConfigValue(benchmarkCfg)} />
      )}
      <Confirmation
        show={showConfirm}
        content="Are you sure to want to update the Fourth Global Config"
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          handleSave();
        }}
      />
    </Fragment>
  );
}

FourthGlobalConfig.propTypes = {
  globalConfig: PropTypes.shape(),
  setGlobalConfig: PropTypes.func,
  globalConfigLoaded: PropTypes.bool,
};

FourthGlobalConfig.defaultProps = {
  globalConfig: {},
  setGlobalConfig: () => {},
  globalConfigLoaded: false,
};

function mapStateToProps(state) {
  const {
    fourth: { connected, globalConfig, globalConfigLoaded },
  } = state;

  return {
    connected,
    globalConfig,
    globalConfigLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGlobalConfig: (configObj) => {
      dispatch(addGlobalConfig(configObj));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthGlobalConfig);
