import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { IconSVG } from '../../../Base/SVG';
import { IconButton } from '../../../Base/Buttons';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { downloadFiles } from '../../../../api/FileAPI/FileAPI';

function ChatEntry({ entry, candidate, doReply, languagePack }) {
  const isRecieved = entry.direction === 'RECEIVED';
  let userName = isRecieved ? candidate.applicantName : entry.sentUserName;

  if (userName.length === 0) userName = languagePack.systemUserLabel;
  const userInitials = userName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .substr(0, 2)
    .toUpperCase();

  let entryStatus = languagePack.sent;

  if (isRecieved) {
    entryStatus = languagePack.received;
  } else if (!isRecieved && entry.status === 'DELIVERED') {
    entryStatus = languagePack.delivered;
  }

  function getAttachments() {
    const attachments = [];
    if (entry.attachments) {
      entry.attachments.forEach((attach) => {
        let attachmentName;
        let colour;

        switch (attach.contentType) {
          case 'application/pdf':
            attachmentName = 'File PDF';
            colour = '#ff0000';
            break;
          case 'image/png':
            attachmentName = 'File Image';
            colour = '#989898';
            break;
          case 'image/jpg':
            attachmentName = 'File Image';
            colour = '#989898';
            break;
          case 'image/jpeg':
            attachmentName = 'File Image';
            colour = '#989898';
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            attachmentName = 'File Word';
            colour = '#324993';
            break;
          default:
            attachmentName = 'File';
            colour = '#989898';
        }
        attachments.push(
          <button
            className="chat-attachments-button"
            onClick={(e) => {
              e.preventDefault();
              retryableAPICall(() => downloadFiles([attach.id]));
            }}
            type="button"
          >
            <div className="chat-attachments-container">
              <div className="chat-attachments-icon-container">
                <IconSVG fill={colour} name={attachmentName} width="1.5em" />
              </div>
              <p>{`${attach.fileName}`}</p>
            </div>
          </button>,
        );
      });
    }

    return attachments;
  }

  return (
    <div className={`chat-bubble-container ${isRecieved ? 'float-end' : ''}`}>
      <div className={`${isRecieved ? 'float-end' : ''}`}>
        <Tippy content={`${languagePack.sentBy} ${userName}`} delay={300}>
          <div className="chat-user-image">
            <div className="circle-initials">{userInitials}</div>
          </div>
        </Tippy>
        <div className={`chat-bubble ${isRecieved ? 'chat-is-sent' : ''}`}>
          <p className="chat-bubble-header">
            {`${entryStatus}: ${moment.utc(entry.createdDateTime).local().format('DD-MM-YYYY [at] HH:mm:ss')}`}
          </p>
          {entry.type?.includes('_EMAIL') && entry.title && (
            <p className="chat-bubble-title">{`${languagePack.subject}: ${entry.title}`}</p>
          )}
          <p dangerouslySetInnerHTML={{ __html: entry.content }} />
          <div className="chat-bubble-status-container">
            {(entry.latestStatus === 'SENT' || entry.latestStatus === 'IN_TRANSIT') && (
              <Tippy
                content={`${languagePack.sent} on ${moment
                  .utc(entry.createdDateTime)
                  .local()
                  .format('DD-MM-YYYY [at] HH:mm:ss')}`}
              >
                <span className="float-end">
                  <IconSVG name="Tick" width="0.75em" />
                </span>
              </Tippy>
            )}
            {entry.latestStatus === 'DELIVERED' && (
              <Tippy
                content={`${languagePack.tippyDelivered} on ${moment
                  .utc(entry.statusDateTime)
                  .local()
                  .format('DD-MM-YYYY [at] HH:mm:ss')}`}
              >
                <span className="float-end">
                  <IconSVG fill="#4DBD74FF" name="Tick" width="0.75em" />
                </span>
              </Tippy>
            )}
            {entry.latestStatus === 'FAILED' && (
              <Tippy
                content={`${languagePack.tippyFailed} on ${moment
                  .utc(entry.statusDateTime)
                  .local()
                  .format('DD-MM-YYYY [at] HH:mm:ss')}`}
              >
                <span className="float-end">
                  <IconSVG fill="#d71a1a" name="Cross" width="0.75em" />
                </span>
              </Tippy>
            )}
            {entry.latestStatus === 'DO_RETRY' && (
              <Tippy
                content={`${languagePack.tippyRetrying} on ${moment
                  .utc(entry.statusDateTime)
                  .local()
                  .format('DD-MM-YYYY [at] HH:mm:ss')}`}
              >
                <span className="float-end">
                  <IconSVG fill="#bfaa42" name="Redo" width="0.75em" />
                </span>
              </Tippy>
            )}
            {isRecieved && (
              <Tippy content={languagePack.tippyReplyToMessage}>
                <span className="float-end">
                  <IconButton
                    action={() => doReply(entry)}
                    className="chat-reply-btn"
                    floatRight={false}
                    iconClassName="fa fa-reply"
                    iconOnly
                    size="sm"
                  />
                </span>
              </Tippy>
            )}
            {entry.responseReason && isRecieved && (
              <Tippy content={languagePack.attachmentsBlocked}>
                <span className="float-end">
                  <IconSVG fill="#de5471" name="Exclamation Circle" />
                </span>
              </Tippy>
            )}
          </div>
          {getAttachments()}
        </div>
      </div>
    </div>
  );
}

ChatEntry.propTypes = {
  entry: PropTypes.shape(),
  candidate: PropTypes.shape(),
  doReply: PropTypes.func,
  languagePack: PropTypes.shape(),
};

ChatEntry.defaultProps = {
  entry: null,
  candidate: {},
  doReply: () => {},
  languagePack: {},
};

export default ChatEntry;
