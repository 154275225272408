export const schema = {
  type: 'object',
  required: ['descriptionName', 'descriptionContent'],
  properties: {
    descriptionName: {
      type: 'string',
      title: 'Name',
    },
    descriptionContent: {
      type: 'string',
      title: 'Description',
    },
  },
};

export const uiSchema = {
  'ui:order': Object.keys(schema.properties),
  'ui:rootFieldId': 'description',
  descriptionContent: {
    'ui:field': 'rte',
  },
};
