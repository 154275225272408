import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { sona },
} = config;

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${sona.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToSona(data = {}, applicantId) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${sona.submitPath}/${applicantId} `,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getSonaData(endpoint) {
  const result = await axios.get(`${api.middlewareAPIURL}${sona.dataPath}/${endpoint}`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
