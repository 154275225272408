import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback } from 'reactstrap';
import 'quill-emoji/dist/quill-emoji.css';
import ReactQuill, { Quill } from 'react-quill';
import QuillEmoji from 'quill-emoji';

Quill.register('emoji-toolbar', QuillEmoji);

function SMSBodyInput({
  value,
  invalid,
  errorMsg,
  onChange,
  limit,
  isLoading,
}) {
  const [inputClass, setInputClass] = useState('');
  const [quillRef, setQuillRef] = useState();

  function handleOnChange(val) {
    const strippedValue = val.replace(/<[^>]*>?/gm, '').replace(/\uFEFF/g, '');
    const remainingCharCount = limit - Array.from(strippedValue).length;
    if ((remainingCharCount < 5 && remainingCharCount > 0)) {
      setInputClass('text-warning');
    } else if (remainingCharCount <= 0) {
      setInputClass('text-danger');
    } else {
      setInputClass('');
    }
    if (quillRef) {
      const quill = quillRef.getEditor();
      quill.deleteText(limit, quill.getLength());
      onChange(val);
    }
  }
  return (
    <FormGroup>
      {!isLoading ? (
        <ReactQuill
          style={{ minHeight: '80px' }}
          ref={(el) => setQuillRef(el)}
          defaultValue={value}
          className={`message-body ${invalid ? 'is-invalid' : ''}`}
          onChange={(val) => handleOnChange(val)}
          modules={SMSBodyInput.modules}
        />
      ) : <div className="ql-container ql-snow"><div className="ql-editor" /></div>}
      <FormFeedback>{errorMsg}</FormFeedback>
      <p className={inputClass}>
        {`You currently have ${limit - Array.from(value.replace(/<[^>]*>?/gm, '').replace(/\uFEFF/g, '')).length}
        of ${limit} characters remaining`}
      </p>
    </FormGroup>
  );
}

/*
* Quill modules to attach to editor
* See http://quilljs.com/docs/modules/ for complete options
*/
SMSBodyInput.modules = {
  toolbar: false,
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true,
  keyboard: {
    bindings: {
      tab: false,
      handleEnter: {
        key: 13,
        handler() {
          // Do nothing
        },
      },
    },
  },
};

SMSBodyInput.propTypes = {
  value: PropTypes.string,
  invalid: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
};

SMSBodyInput.defaultProps = {
  value: null,
  invalid: false,
  errorMsg: null,
  onChange: () => { },
  isLoading: false,
  limit: 250,
};

export default SMSBodyInput;
