import React from 'react';
import PropTypes from 'prop-types';

function BaseSVG({
  d,
  viewBox,
  name,
  width,
  className,
  prefix,
  fill,
  fillRule,
  clipRule,
  stroke,
  strokeWidth,
  strokeLinejoin,
  strokeLinecap,
  styles,
  paths,
  transform,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-icon={name}
      data-prefix={prefix}
      viewBox={viewBox}
      width={width}
      style={styles}
    >
      {paths.length ? (
        <g>
          {paths.map(
            (
              {
                fill: pFill,
                d: pD,
                strokeLinecap: pStrokeLinecap,
                strokeLinejoin: pStrokeLinejoin,
                transform: pTransform,
                stroke: pStroke,
                strokeWidth: pStrokeWidth,
              },
              i,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <path
                key={`path_${i}`}
                fill={pFill || fill}
                d={pD}
                stroke-linecap={pStrokeLinecap}
                stroke-linejoin={pStrokeLinejoin}
                transform={pTransform}
                stroke={pStroke}
                stroke-width={pStrokeWidth}
              />
            ),
          )}
        </g>
      ) : (
        <path
          fill={fill}
          d={d}
          strokeLinecap={strokeLinecap}
          strokeLinejoin={strokeLinejoin}
          transform={transform}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      )}
    </svg>
  );
}

BaseSVG.propTypes = {
  d: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  prefix: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeLinecap: PropTypes.string,
  strokeLinejoin: PropTypes.string,
  fillRule: PropTypes.string,
  clipRule: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      fill: PropTypes.string,
      d: PropTypes.string,
    }),
  ),
  transform: PropTypes.string,
};

BaseSVG.defaultProps = {
  d: null,
  width: '1em',
  className: null,
  prefix: null,
  fill: 'currentColor',
  stroke: null,
  strokeWidth: null,
  strokeLinecap: null,
  strokeLinejoin: null,
  fillRule: null,
  clipRule: null,
  styles: {},
  paths: [],
  transform: null,
};

export default BaseSVG;
