/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Select from 'react-select';

import { useFormKeypress, useMounted } from '../../../Base/hooks';
import { ValueDisplay } from '../../../Base/ValueDisplay';
import { CreateButton, EditButton, CancelButton } from '../../../Base/Buttons';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { createUpdateIndeedSettings, getIndeedSettings } from '../../../../api/PublishingAPI/PublishingSettingsAPI';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';

function IndeedSourceSettings({ languagePack, tenant }) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const cachedFormData = useRef({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  useEffect(() => {
    const getApi = async () => {
      const resp = await retryableAPICall(() => getIndeedSettings(tenant));

      if (typeof resp !== 'string') {
        setFormData(resp);
        cachedFormData.current = resp;
      }
    };

    if (isMounted()) {
      getApi();
    }
  }, [isMounted]);

  async function saveProfileDetails(callback = () => {}) {
    let resp = await retryableAPICall(() => createUpdateIndeedSettings(tenant, formData));

    if (typeof resp === 'string') {
      toast.error(languagePack.saveErrorMessage);
      setIsSaving(false);
    } else {
      if (resp.status === 'FAILED' && resp.errorReason === 'INVALID_CREDENTIALS') {
        toast.error(languagePack.invalidCredsMessage);
      } else if (resp.status === 'FAILED') {
        toast.error(languagePack.saveErrorMessage);
      } else {
        toast.success(languagePack.saveUpdateSuccessMessage);
        setFormData(resp);
        callback();
      }
      setIsSaving(false);
    }
  }

  function handleSave() {
    setIsSaving(true);

    const errObj = validation(
      [
        { id: 'type', required: true },
        { id: 'source', required: true },
      ],
      formData,
    );
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      saveProfileDetails(() => {
        setIsEditing(false);
        setIsSaving(false);
      });
    }
  }

  return (
    <div className="mt-4">
      <h5>{languagePack.indeedSourceTitle}</h5>
      <Form innerRef={formRef}>
        <FormGroup
          row
          id="type"
          label={`${languagePack.employerType || 'Employer type'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.type}
        >
          {isEditing ? (
            <Select
              className="react-select"
              placeholder="Select Employer Type"
              options={[
                { label: 'Employer', value: 'EMPLOYER' },
                { label: 'Staffing Agency', value: 'STAFFING_AGENCY' },
              ]}
              value={[
                { label: 'Employer', value: 'EMPLOYER' },
                { label: 'Staffing Agency', value: 'STAFFING_AGENCY' },
              ].find((a) => a.value === formData?.type)}
              onChange={(val) => handleChange('type', val.value)}
            />
          ) : (
            <ValueDisplay value={formData.type} />
          )}
        </FormGroup>
        <FormGroup
          row
          id="source"
          label={`${languagePack.sourceName || 'Source Name'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.source}
        >
          {isEditing ? (
            <Input id="source" onChange={(e) => handleChange('source', e.target.value)} value={formData.source} />
          ) : (
            <ValueDisplay value={formData.source} />
          )}
        </FormGroup>
        {isEditing ? (
          <Fragment>
            <CreateButton
              className="mt-2"
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
            />
            <CancelButton
              className="mt-2 ms-2"
              floatRight={false}
              action={() => {
                setFormData(cachedFormData.current);
                setIsEditing(false);
              }}
            />
          </Fragment>
        ) : (
          <EditButton className="mt-2" action={() => setIsEditing(true)} floatRight={false} />
        )}
      </Form>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { tenant },
      },
    },
  } = state;
  return { tenant };
}

IndeedSourceSettings.propTypes = {
  languagePack: PropTypes.shape(),
  tenant: PropTypes.string,
};

IndeedSourceSettings.defaultProps = {
  languagePack: {},
  tenant: '',
};

export default connect(mapStateToProps, null)(IndeedSourceSettings);
