import browserStore from 'store2';

export function getLocalObject(key) {
  const cacheStr = browserStore.get(key);
  const cacheObj = JSON.parse(cacheStr);
  if (cacheObj) return cacheObj;
  return null;
}

export function getLocalObjectByNs(key, namespace) {
  const obj = getLocalObject(key);
  if (obj && obj[namespace]) return obj[namespace];
  return null;
}

export function getLocalObjectByProp(key, namespace, prop) {
  const obj = getLocalObjectByNs(key, namespace);
  if (obj && obj[prop]) return obj[prop];
  return null;
}

export function setLocalObject(key, namespace, prop, value, keepExisting) {
  const cacheObj = getLocalObject(key);

  const obj = {
    ...(cacheObj ? { ...cacheObj } : {}),
    [namespace]: {
      ...(cacheObj && cacheObj[namespace] ? { ...cacheObj[namespace] } : {}),
      [prop]:
        keepExisting && cacheObj && cacheObj[namespace] && cacheObj[namespace][prop]
          ? {
              ...cacheObj[namespace][prop],
              ...value,
            }
          : value,
    },
  };

  browserStore(key, JSON.stringify(obj));
}
