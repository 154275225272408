import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cx from 'classnames';

function PaginationButton({
  currentResult,
  totalResults,
  onClick,
  isNextButton,
  label,
  size,
}) {
  const classNames = ['pagination-btn', 'px-0'];

  // prev button props
  let buttonProps = {
    disabled: currentResult === 1,
    onClick: () => onClick((currentResult - 1), 'prev'),
    className: cx(
      ...classNames,
      'pagination-prev-btn',
      { 'font-weight-bold': (currentResult > 1) },
    ),
  };
  let btnLabel = label || 'Previous';

  if (isNextButton) {
    buttonProps = {
      disabled: currentResult === totalResults,
      onClick: () => onClick((currentResult + 1), 'next'),
      className: cx(
        ...classNames,
        'pagination-next-btn',
        { 'font-weight-bold': (currentResult < totalResults) },
      ),
    };
    btnLabel = label || 'Next';
  }

  return <Button color="link" size={size} {...buttonProps}>{btnLabel}</Button>;
}

PaginationButton.propTypes = {
  currentResult: PropTypes.number,
  totalResults: PropTypes.number,
  onClick: PropTypes.func,
  isNextButton: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
};

PaginationButton.defaultProps = {
  currentResult: 1,
  totalResults: 0,
  onClick: () => { },
  isNextButton: false,
  label: null,
  size: null,
};

export default PaginationButton;
