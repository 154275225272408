import {
  ADD_TAG,
  DELETE_TAGS,
  SET_ALL_TAGS,
  EDIT_TAG,
} from '../constants/actionTypes';

const defaultState = {
  tagsLoaded: false,
  tags: [],
};

export default function tagReducer(state = defaultState, action) {
  const { type, payload = {} } = action;

  const { tagId, tagName } = payload;

  switch (type) {
    case ADD_TAG:
      return {
        ...state,
        tags: [...state.tags, payload],
      };
    case DELETE_TAGS:
      return {
        ...state,
        tags: state.tags.filter((tag) => !payload.includes(tag.id)),
      };
    case SET_ALL_TAGS:
      return {
        tagsLoaded: true,
        tags: [...payload],
      };
    case EDIT_TAG:
      return {
        ...state,
        tags: state.tags.map((tag) => {
          if (tag.id === tagId) return { ...tag, name: tagName };
          return tag;
        }),
      };
    default:
      return state;
  }
}
