import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

const defaultInputState = {
  limitReached: false,
  limitWarn: false,
};

function reducer(state, action) {
  const { type, payload } = action;
  if (type === 'UPDATE') return { ...state, ...payload };
  return state;
}

export default function LimitedTextArea(props) {
  const {
    schema,
    required,
    rows,
    limit,
    formData,
    onChange,
  } = props;
  let { className } = props;

  const [value, setValue] = useState('');
  const [inputState, dispatch] = useReducer(reducer, defaultInputState);

  useEffect(() => {
    if (formData) setValue(formData);
  }, [formData]);

  function handleChange(newVal) {
    const remainingCharCount = limit - newVal.length;
    dispatch({
      type: 'UPDATE',
      payload: {
        limitWarn: (remainingCharCount < 5 && remainingCharCount > 0),
        limitReached: remainingCharCount === 0,
      },
    });
    setValue(newVal);
    onChange(newVal);
  }

  const { title } = schema;
  if (inputState.limitReached) className = 'text-danger';
  if (inputState.limitWarn) className = 'text-warning';

  return (
    <Fragment>
      {title && (
        <label className="control-label" htmlFor="root_referenceCode">
          {title}
          {required && (<span className="required">*</span>)}
        </label>
      )}
      <textarea
        rows={rows}
        className={`form-control ${className}`}
        onChange={(e) => handleChange(e.currentTarget.value.substring(0, limit))}
        value={value}
      />
      <p className={className}>
        {`You currently have ${(limit - value.length)} of ${limit} characters remaining`}
      </p>
    </Fragment>
  );
}

LimitedTextArea.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  limit: PropTypes.number,
  className: PropTypes.string,
  formData: PropTypes.string,
  onChange: PropTypes.func,
};

LimitedTextArea.defaultProps = {
  schema: { title: null },
  required: false,
  rows: 4,
  className: null,
  limit: 140,
  formData: null,
  onChange: () => { },
};
