import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IconSVG } from '../../SVG';

function ColumnOrderControl({ index, length, onClick }) {
  return (
    <div className="order-control">
      <Button
        color="link"
        size="sm"
        disabled={index === 0}
        onClick={() => onClick((index - 1))}
      >
        <IconSVG name="Chevron Up" width="2em" />
      </Button>
      <Button
        color="link"
        size="sm"
        disabled={length && index === (length - 1)}
        onClick={() => onClick((index + 1))}
      >
        <IconSVG name="Chevron" width="2em" />
      </Button>
    </div>
  );
}

ColumnOrderControl.propTypes = {
  index: PropTypes.number,
  length: PropTypes.number,
  onClick: PropTypes.func,
};

ColumnOrderControl.defaultProps = {
  index: 0,
  length: 0,
  onClick: () => { },
};

export default ColumnOrderControl;
