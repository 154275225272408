import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import root from '../sagas/eventNotificationSaga';
import rootReducer from '../reducers/index';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'ats',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const enhancers = [applyMiddleware(sagaMiddleware)];
export const store = createStore(persistedReducer, composeWithDevTools(...enhancers));
export const persistor = persistStore(store);
sagaMiddleware.run(root);
const action = (type) => store.dispatch({ type });
action('START_POLLING');
