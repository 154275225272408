import moment from 'moment';
import {
  ADD_VACANCY,
  DELETE_VACANCY,
  RESET_ACTIVE_VACANCY_CANDIDATES,
  RESET_VACANCY_STATE,
  SET_VACANCY_CANDIDATE_PAGE,
  SET_VACANCY_APPLICATION_URL, UPDATE_VACANCY,
  UPDATE_VACANCY_LAST_VIEWED_DT,
} from '../constants/actionTypes';

const initialState = {
  vacancies: {},
};

// https://blog.bitsrc.io/6-tricks-with-resting-and-spreading-javascript-objects-68d585bdc83#f43f
const removeProperty = (prop) => ({ [prop]: _, ...rest }) => rest;

const vacancyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { vacancies } = state;

  switch (type) {
    case ADD_VACANCY:
      return {
        ...state,
        vacancies: {
          ...vacancies,
          [payload.id]: payload,
        },
      };
    case UPDATE_VACANCY:
      return {
        ...state,
        vacancies: {
          ...vacancies,
          [payload.id]: {
            ...(vacancies[payload.id] || {}),
            ...payload,
          },
        },
      };
    case DELETE_VACANCY:
      return {
        ...state,
        vacancies: removeProperty(payload)(vacancies),
      };
    case SET_VACANCY_CANDIDATE_PAGE:
      return {
        ...state,
        vacancies: {
          ...vacancies,
          [payload.id]: {
            ...vacancies[payload.vacancyId],
            currentApplicantPage: payload.pageNo,
          },
        },
      };
    case SET_VACANCY_APPLICATION_URL:
      return {
        ...state,
        vacancies: {
          ...vacancies,
          [payload.id]: {
            ...vacancies[payload.vacancyId],
            applicationUrl: payload.url,
          },
        },
      };
    case UPDATE_VACANCY_LAST_VIEWED_DT:
      return {
        ...state,
        vacancies: {
          ...vacancies,
          [payload]: {
            ...vacancies[payload],
            lastViewedDT: moment.utc(),
          },
        },
      };
    case RESET_VACANCY_STATE:
      return {
        ...state,
        vacancies: {},
      };
    case RESET_ACTIVE_VACANCY_CANDIDATES:
      return {
        ...state,
        vacancyApplicants: [],
      };
    default:
      return state;
  }
};

export default vacancyReducer;
