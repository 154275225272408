import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function StaticField(props) {
  const {
    onChange,
    schema,
  } = props;

  // Just set whatever the value is
  useEffect(() => {
    setTimeout(() => {
      onChange(schema.value);
    }, 200);
  }, [onChange, schema]);

  return (
    <Fragment>
      {schema && schema.show
      && (
        <p>
          {schema.title}
          {' '}
          :
          {' '}
          {schema.value}
        </p>
      )}
    </Fragment>
  );
}

StaticField.propTypes = {
  schema: PropTypes.shape(),
  onChange: PropTypes.func,
};

StaticField.defaultProps = {
  schema: {},
  onChange: () => { },
};
