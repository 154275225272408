import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';

import config from '../../config/config';

const { admin, api } = config;

/**
 * Gets the email templates that a user can see
 * @param getCompanyProfileForAccount
 */
export async function emailTemplateSearch(keywords, filters) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${admin.emailTemplateSearchPath}`,
    { keywords, filters, limit: 5000 },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

/**
 * Gets the email templates that a user can see
 * @param getCompanyProfileForAccount
 */
export async function saveTemplate(templateData) {
  const {
    id,
    name,
    subject,
    replyTo,
    fromName,
    message,
    ccAddresses,
    bccAddresses,
    attachments,
    group,
    type,
    sharedWith,
  } = templateData;
  const result = await axios.post(
    `${api.middlewareAPIURL}${admin.emailTemplateAdminPath}`,
    {
      id,
      name,
      subject,
      content: message,
      replyTo,
      fromName,
      ccAddresses,
      attachments,
      bccAddresses,
      group,
      type,
      sharedWith,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

/**
 * Gets the email templates that a user can see
 * @param getCompanyProfileForAccount
 */
export async function updateTemplate(templateData) {
  const {
    id,
    attachments,
    name,
    subject,
    replyTo,
    fromName,
    message,
    ccAddresses,
    bccAddresses,
    group,
    type,
    sharedWith,
  } = templateData;
  const result = await axios.put(
    `${api.middlewareAPIURL}${admin.emailTemplateAdminPath}`,
    {
      id,
      name,
      subject,
      content: message,
      replyTo,
      fromName,
      ccAddresses,
      attachments,
      bccAddresses,
      group,
      type,
      sharedWith,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function deleteTemplate(id, group) {
  const result = await axios.delete(`${api.middlewareAPIURL}${admin.emailTemplateAdminPath}/${id}/${group}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}
