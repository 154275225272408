import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResolveLogo from '../SVG/ResolveLogo';

function LoadingInput({ loading, companyLogo }) {
  if (!loading) return null;

  return (
    <div className="loading-input">
      <ResolveLogo imgSrc={companyLogo} imgWidth="23px" svgWidth="1.5em" />
    </div>
  );
}

LoadingInput.propTypes = {
  loading: PropTypes.bool,
  companyLogo: PropTypes.string,
};

LoadingInput.defaultProps = {
  loading: false,
  companyLogo: null,
};

function mapStateToProps(state) {
  const { companyData: { companyDetails: { companyLogo } }, styles } = state;
  const { logoUrl } = styles;
  return { companyLogo: logoUrl || companyLogo };
}

export default connect(mapStateToProps)(LoadingInput);
