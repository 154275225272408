import React from 'react';
import PropTypes from 'prop-types';
import ButtonComp from './ButtonComp';

export default function PublishButton({ action, isLoading, iconOnly, size }) {
  return (
    <ButtonComp
      className="publish-button btn-outline float-end"
      onClick={action}
      isLoading={isLoading}
      loadingIconColor="white"
      iconName="fa-newspaper-o"
      iconOnly={iconOnly}
      size={size}
    >
      Add Boards / Edit
    </ButtonComp>
  );
}

PublishButton.propTypes = {
  action: PropTypes.func,
  isLoading: PropTypes.bool,
  iconOnly: PropTypes.bool,
  size: PropTypes.string,
};

PublishButton.defaultProps = {
  action: () => {},
  isLoading: false,
  iconOnly: false,
  size: null,
};
