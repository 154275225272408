import axios from 'axios';
import { getCommonHeaders, getCommonHeadersNoContentType } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
} = config;

export async function createUser(customerId) {
  if (customerId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}/dbs/user`,
      { tenantId: `${config.auth.tenantId}`, integrationType: "dbs", organisationId: customerId },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateUser(customerId) {
  if (customerId) {
    const result = await axios.put(
      `${api.middlewareAPIURL}/dbs/user`,
      { tenantId: `${config.auth.tenantId}`, integrationType: "dbs", organisationId: customerId },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
