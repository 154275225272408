// eslint-disable-next-line import/prefer-default-export
export const categories = [
  { value: 'Activity staff/Resort Rep', label: 'Activity staff/Resort Rep' },
  { value: 'Admin/Secretarial/Clerical', label: 'Admin/Secretarial/Clerical' },
  { value: 'Advertising/Marketing/PR', label: 'Advertising/Marketing/PR' },
  { value: 'Art/Design', label: 'Art/Design' },
  { value: 'Au Pair/Nanny', label: 'Au Pair/Nanny' },
  { value: 'Bar/Catering/Hospitality', label: 'Bar/Catering/Hospitality' },
  { value: 'Care work/Social Work', label: 'Care work/Social Work' },
  { value: 'Charity/Fundraising', label: 'Charity/Fundraising' },
  { value: 'Cleaning/Maintenance', label: 'Cleaning/Maintenance' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Customer Services', label: 'Customer Services' },
  { value: 'Driver/Courier', label: 'Driver/Courier' },
  { value: 'Entertainment/Leisure', label: 'Entertainment/Leisure' },
  { value: 'Entry Level', label: 'Entry Level' },
  { value: 'Graduate', label: 'Graduate' },
  { value: 'Internship', label: 'Internship' },
  { value: 'IT', label: 'IT' },
  { value: 'Journalism/Publishing', label: 'Journalism/Publishing' },
  { value: 'Manual', label: 'Manual' },
  { value: 'Market Research/Telephone Work', label: 'Market Research/Telephone Work' },
  { value: 'Media/Performing arts', label: 'Media/ Performing arts' },
  { value: 'Medical/Healthcare', label: 'Medical/Healthcare' },
  { value: 'Military', label: 'Military' },
  { value: 'Modelling', label: 'Modelling' },
  { value: 'Other/General', label: 'Other/General' },
  { value: 'Promotional/demonstrator work', label: 'Promotional/Demonstrator work' },
  { value: 'Recruitment Consultant', label: 'Recruitment Consultant' },
  { value: 'Research', label: 'Research' },
  { value: 'Sales/Retail', label: 'Sales/Retail' },
  { value: 'Security/Stewarding', label: 'Security/Stewarding' },
  { value: 'Sports Instructor/Coach', label: 'Sports Instructor/Coach' },
  { value: 'Teacher/Tutor', label: 'Teacher/Tutor' },
  { value: 'Translator', label: 'Translator' },
  { value: 'Voluntary', label: 'Voluntary' },
];
