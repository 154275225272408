import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { requestStatuses } from '../../../js/constants/requestStatuses';

function AccessCodeErrorView({
  errorCode, langPack,
}) {
  function getErrorMessage() {
    switch (errorCode) {
      case requestStatuses.PERMISSION_DENIED:
        return langPack.invalidAccessCodeError;
      case requestStatuses.INVALID_CREDENTIALS:
        return langPack.revokedError;
      case requestStatuses.NOT_FOUND_ERROR:
        return langPack.notFoundError;
      case requestStatuses.EXPIRED_ERROR:
        return langPack.expiredError;
      default:
        return langPack.generalError;
    }
  }
  return (
    <Fragment>
      <Row className="mb-3">
        <Col>
          <p style={{ color: 'red' }}>{getErrorMessage()}</p>
        </Col>
      </Row>
    </Fragment>
  );
}

AccessCodeErrorView.propTypes = {
  errorCode: PropTypes.string,
  langPack: PropTypes.shape(),
};

AccessCodeErrorView.defaultProps = {
  errorCode: undefined,
  langPack: {},
};

export default AccessCodeErrorView;
