/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import cx from 'classnames';

function FileTypeDropdown({ value, onChange, disabled }) {
  return (
    <div className="file-type-dropdown">
      <FormGroup>
        <Label for="fileType" className={cx('pe-2', { 'is-disabled': disabled })}>
          File Type:
        </Label>
        <select
          disabled={disabled}
          id="fileType"
          className="select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="CV">CV</option>
          <option value="OTHER">General</option>
        </select>
      </FormGroup>
    </div>
  );
}

FileTypeDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

FileTypeDropdown.defaultProps = {
  value: '',
  onChange: () => {},
  disabled: false,
};

export default FileTypeDropdown;
