import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const { api, forms } = config;

// eslint-disable-next-line import/prefer-default-export
export async function getCreateVacancyForm() {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.createVacancyForm}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function createFlexiForm(
  name,
  schemas,
  type = 'APPLICATION_FORM',
  readOnly = false,
  share = [],
  themeId = null,
) {
  const { schema: formSchema = {}, uiSchema = {}, rules = [] } = schemas;

  const body = {
    formSchema,
    name,
    readOnly,
    rules,
    share,
    type,
    uiSchema,
    themeId,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${forms.flexiForm}`, body, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function updateFlexiForm(
  id,
  name,
  schemas,
  type = 'APPLICATION_FORM',
  readOnly = false,
  share = [],
  themeId = null,
) {
  const { schema: formSchema = {}, uiSchema = {}, rules = [] } = schemas;

  const body = {
    formSchema,
    id,
    name,
    readOnly,
    rules,
    share,
    type,
    uiSchema,
    themeId,
  };

  const result = await axios.put(`${api.middlewareAPIURL}${forms.flexiForm}`, body, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getFlexiFormList(types = []) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiForm}?types=${types.join(',')}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getFlexiForm(id = '') {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiForm}/${id}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function deleteFlexiForm(id = '') {
  const result = await axios.delete(`${api.middlewareAPIURL}${forms.flexiForm}/${id}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getFlexiFormView(id) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiFormView}/${id}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getFlexiFormViewIdsByType(type) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiFormView}/list/type/${type}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getFormPlaceHolders(id) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiFormView}/parameters/${id}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getGlobalFlexiFormViewByType(type) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiFormView}/list/type/global/${type}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getEditFormToken(formId) {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.flexiFormGenerateToken}/${formId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function searchQuestions(keywords = '', filters = [], fields = []) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${forms.questionSearchPath}`,
    { fields, filters, keywords },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
