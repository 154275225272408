import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

// eslint-disable-next-line import/prefer-default-export
export async function getIDPConfig() {
  const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.idpManagementPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export async function createIDPConfig(idpConfig) {
  const result = await axios.post(`${config.api.middlewareAPIURL}${config.account.idpManagementPath}`, idpConfig, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export async function updateIDPConfig(idpConfig) {
  const result = await axios.put(`${config.api.middlewareAPIURL}${config.account.idpManagementPath}`, idpConfig, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export async function deleteIDPConfig(idpConfigId) {
  const result = await axios.delete(
    `${config.api.middlewareAPIURL}${config.account.idpManagementPath}/${idpConfigId}`,
    {
      headers: getCommonHeaders(),
    },
  );

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}
