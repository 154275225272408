import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, Input, Row, Table } from 'reactstrap';
import { retryableAPICall } from '../../../../api/common-api-utils';
import {
  addHollwegPositionMapping,
  deleteHollwegPositionMappings,
  getHollwegPositionMappings,
} from '../../../../api/Integrations/HollwegAPI';
import { IconButton } from '../../../Base/Buttons';
import { useMounted } from '../../../Base/hooks';

const initialState = {
  isLoading: false,
  role: null,
  hollwegPosition: '',
  name: '',
  mappings: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false };
    case 'SET_POSITIONS_DATA':
      return { ...state, positions: action.data };
    case 'SET_MAPPINGS_DATA':
      return { ...state, mappings: action.data };
    case 'ADD_MAPPING': {
      return {
        ...state,
        mappings: [
          ...state.mappings,
          { role: state.role.value, hollwegPositionId: state.hollwegPosition, name: state.name },
        ],
        role: null,
        hollwegPosition: '',
        name: '',
      };
    }
    case 'REMOVE_MAPPING': {
      const filteredData = state.mappings.filter(
        (item) => !(item.hollwegPositionId === action.data.hollwegPositionId && item.name === action.data.name),
      );
      return {
        ...state,
        mappings: filteredData,
      };
    }
    case 'SET_NAME':
      return { ...state, name: action.val };
    case 'SET_POSITION':
      return { ...state, hollwegPosition: action.val };
    case 'SET_ROLE':
      return { ...state, role: action.val };
    default:
      return state;
  }
};

function HollwegAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  const { categories } = useSelector((bigState) => bigState.categoryReducer);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const mappingsResponse = await retryableAPICall(() => getHollwegPositionMappings());
        if (isMounted) {
          if (mappingsResponse) {
            let mappedPositions = [];

            if (Object.keys(mappingsResponse).length > 0) {
              mappedPositions = Object.entries(mappingsResponse).map(([key, value]) => {
                return { role: key, hollwegPositionId: value.hollwegId, name: value.name };
              });
            }

            dispatch({ type: 'SET_MAPPINGS_DATA', data: mappedPositions });
          }
        }
      } catch (error) {
        toast.error('Failed to Load location data. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const handleMapRole = async () => {
    if (state.role && state.hollwegPosition && state.name) {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        await retryableAPICall(() => addHollwegPositionMapping(state.role.value, state.hollwegPosition, state.name));

        if (isMounted) {
          dispatch({
            type: 'ADD_MAPPING',
          });
        }
      } catch (error) {
        toast.error('Failed to add location. Please try again later or contact support');
      } finally {
        if (isMounted) {
          dispatch({ type: 'SET_LOADING_COMPLETE' });
        }
      }
    }
  };

  const handleDeleteMappedRole = async (name, id, role) => {
    dispatch({ type: 'SET_IS_LOADING' });
    try {
      await retryableAPICall(() => deleteHollwegPositionMappings(role));

      if (isMounted) {
        dispatch({
          type: 'REMOVE_MAPPING',
          data: { hollwegPositionId: id, name },
        });
      }
    } catch (error) {
      toast.error('Failed to add location. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  return (
    <>
      <div className="mb-1 mt-3">
        <h5>Roles</h5>
      </div>
      <Row className="gap-5">
        <Col>
          <div className="mb-1 mt-1">
            <h6>Add a Hollweg position</h6>
          </div>
          <FormGroup className="d-flex gap-3">
            <Input
              type="text"
              placeholder="Name"
              value={state.name}
              style={{ width: '15rem' }}
              onChange={(e) => dispatch({ type: 'SET_NAME', val: e.target.value })}
            />
            <Select
              className="react-select hollweg-select"
              placeholder="Select a Role"
              options={categories
                .filter((item) => !state.mappings.map((item) => item.role).includes(item.id))
                .map((category) => ({ value: category.id, label: category.label }))}
              value={state.role}
              onChange={(val) => dispatch({ type: 'SET_ROLE', val })}
            />
            <Input
              type="text"
              placeholder="Hollweg Position Id"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (state.isLoading || state.name.length === 0 || state.hollwegPosition.length === 0) {
                    return;
                  }
                  handleMapRole();
                }
              }}
              style={{ width: '15rem' }}
              value={state.hollwegPosition}
              onChange={(e) => dispatch({ type: 'SET_POSITION', val: e.target.value })}
            />
            <Button
              color="primary"
              disabled={state.isLoading || state.name.length === 0 || state.hollwegPosition.length === 0}
              onClick={() => handleMapRole()}
            >
              Add
            </Button>
          </FormGroup>
          <Table style={{ width: '51.5rem' }}>
            <thead>
              <tr>
                <th style={{ width: '31%' }}>Name</th>
                <th style={{ width: '31%' }}>Role</th>
                <th style={{ width: '31%' }}>Hollweg position ID</th>
                <th style={{ width: '7%' }}></th>
              </tr>
            </thead>
            <tbody>
              {state.mappings.length > 0 ? (
                state.mappings.map((role, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`${role.name}-${index}`}>
                    <td>{role.name}</td>
                    <td>{role.role}</td>
                    <td>{role.hollwegPositionId}</td>
                    <td class="text-center">
                      <IconButton
                        disabled={state.isLoading}
                        iconOnly
                        iconClassName="fa fa-times"
                        size="sm"
                        action={() => {
                          handleDeleteMappedRole(role.name, role.hollwegPositionId, role.role);
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No positions added
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

HollwegAdmin.propTypes = {};

HollwegAdmin.defaultProps = {};

export default HollwegAdmin;
