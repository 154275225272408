import React from 'react';
import PropTypes from 'prop-types';
import BuildDetails from './BuildDetails';

function NINDisplay({ title, value, vertical }) {
  if (!value) return null;

  const ninArr = value.match(/.{1,2}/g);

  return (
    <BuildDetails
      title={title}
      titleColumnSize="4"
      vertical={vertical}
    >
      {ninArr ? ninArr.join(' ') : value}
    </BuildDetails>
  );
}

NINDisplay.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  vertical: PropTypes.bool,
};

NINDisplay.defaultProps = {
  title: null,
  value: null,
  vertical: false,
};

export default NINDisplay;
