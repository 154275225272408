import React from 'react';
import PropTypes from 'prop-types';
import BuildDetails from './BuildDetails';

export default function ExecSummary({ data }) {
  if (!data || !data.length) return null;
  return <BuildDetails title="Summary" largeContent>{data}</BuildDetails>;
}

ExecSummary.propTypes = {
  data: PropTypes.string,
};

ExecSummary.defaultProps = {
  data: '',
};
