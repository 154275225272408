import React, { useEffect, Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTableState, DataTable } from '../../../Base/Tables';
import VacancyTemplateForm from './VacancyTemplateForm';
import { ActionButton } from '../../../Base/Buttons';
import { Confirmation } from '../../../Base/Modal';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Slider from '../../../Base/Slider/Slider';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import { getCreateVacancyTemplate, deleteVacancyTemplate } from '../../../../api/VacancyAPI';
import { usePageLoading, useMounted, useLanguage } from '../../../Base/hooks';
import { addObjToArray, updateObjInArray, deleteObjFromArray } from '../../../../js/utils/arrayOfObjects';
import { ClientAdminNoDataMessage, utils, PageColumn } from '../Dashboard';
import { TippyTruncate } from '../../../Base/Truncate';
import { saveVacancyTemplate } from '../../../../js/language/pages';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';
import styled from 'styled-components';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;
async function deleteTemplate(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteVacancyTemplate(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function VacancyTemplates({ isCreateMode, onClose, tablePrefs, activeAccount, totalAccounts }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const {
    tableState,
    setTableData,
    // setTableLoading,
    setTableRejected,
  } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const { langPack: languagePack } = useLanguage(saveVacancyTemplate);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [actionMenuId, setActionMenuId] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteObj, setDeleteObj] = useState();
  const [isCopyMode, setIsCopyMode] = useState(false);
  const requestData = useCallback(
    async (callback = () => {}) => {
      // eslint-disable-next-line max-len
      const resp = await retryableAPICall(() =>
        getCreateVacancyTemplate('name,id,description,readOnly,sharedWith,createdDateTime,accountId'),
      );

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: resp,
            totalResults: resp.length,
          });
          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    if (isMounted()) setSliderOpen(isCreateMode);
  }, [isCreateMode, isMounted]);

  function handleClose() {
    setIsCopyMode(false);
    setTemplateId();
    setSliderOpen(false);
    setTimeout(() => {
      onClose();
    }, 500);
  }

  const columns = [
    {
      Header: 'NAME',
      id: 'templateName',
      minWidth: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const {
          // eslint-disable-next-line react/prop-types
          id,
          // eslint-disable-next-line react/prop-types
          name,
          // eslint-disable-next-line react/prop-types
          readOnly,
          // eslint-disable-next-line react/prop-types
          accountId,
        } = original;

        // eslint-disable-next-line max-len
        if (
          checkPermissions(['admin:vacancytemplates:update']) &&
          utils.isEditable(accountId, activeAccount.id, readOnly)
        ) {
          return (
            <a
              href="#vacancyselection"
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line no-use-before-define
                setTemplateId(id);
                // eslint-disable-next-line no-use-before-define
                setSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'DESCRIPTION',
      id: 'description',
      minWidth: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { description } = original;
        return <TippyTruncate>{description}</TippyTruncate>;
      },
    },
    {
      Header: 'CREATED',
      id: 'createdDateTime',
      accessor: (r) => moment(r.createdDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
      width: 125,
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccount.id);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:vacancytemplates:update', 'admin:vacancytemplates:delete'])) {
    columns.push({
      accessor: 'actions',
      className: 'action-cell',
      width: 90,
      id: 'action',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const {
          // eslint-disable-next-line react/prop-types
          id,
          // eslint-disable-next-line react/prop-types
          name,
          // eslint-disable-next-line react/prop-types
          readOnly,
          // eslint-disable-next-line react/prop-types
          accountId,
        } = original;

        // eslint-disable-next-line react/prop-types
        const editable = utils.isEditable(accountId, activeAccount.id, readOnly);
        // eslint-disable-next-line react/prop-types
        const deletable = utils.isDeletable(accountId, activeAccount.id);

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:vacancytemplates:update'],
            action: () => {
              // eslint-disable-next-line no-use-before-define,react/prop-types
              setTemplateId(id);
              // eslint-disable-next-line no-use-before-define
              setSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Copy',
            icon: 'Copy',
            permissions: ['admin:vacancytemplates:update'],
            action: () => {
              setTemplateId(id);
              setIsCopyMode(true);
              // eslint-disable-next-line no-use-before-define
              setSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 2,
            label: 'Delete',
            icon: 'Bin',
            isDisabled: !deletable,
            tooltipContent: 'Only the owner can delete this',
            permissions: ['admin:vacancytemplates:delete'],
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            title="Action"
            position="left"
            isOpen={actionMenuId === id}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            isDisabled={!editable}
            tooltipContent="Only the owner can edit this"
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
          />
        );
      },
    });
  }

  return (
    <Fragment>
      <PageColumn state={pageState}>
        <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
          <EnhancedCard className="mb-4 mt-4">
            <EnhancedCardTitle
              title="Vacancy Templates"
              subtitle="Create and update your vacancy temples to enable you to post vacancies consistantly"
            />
            {!tableState.data.length ? (
              <ClientAdminNoDataMessage
                title={languagePack.noVacancyTemplatesTitle || ''}
                message={languagePack.noVacancyTemplatesSubTitle || ''}
                btnProps={{
                  onClick: () => setSliderOpen(true),
                  label: languagePack.noVacancyTemplatesBtn,
                }}
              />
            ) : (
              <StyledTable
                id="vac-templates"
                className="mt-3"
                isLoading={false}
                showPagination={false}
                isRejected={tableState.isRejected}
                data={tableState.data?.sort((a, b) => a.name?.localeCompare(b.name))}
                columns={columns}
                rowHeight={53}
                totalResults={tableState.totalResults}
                pageSize={tableState.rowsPerPage}
                noDataText="There are no vacancy templates"
                errorText="We can't load the vacancy templates right now please try again later"
                hasSelectColumn={false}
              />
            )}
          </EnhancedCard>
        </div>
      </PageColumn>
      <Slider
        title={templateId && templateId.length ? languagePack.editTemplate : languagePack.createTemplate}
        isOpen={sliderOpen}
        toggleOpen={handleClose}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        <VacancyTemplateForm
          id={templateId}
          isCopyMode={isCopyMode}
          onSave={(tmpObj) => {
            const { id, name, description, createdDateTime, readOnly, sharedWith } = tmpObj;

            const dataObj = {
              id,
              name,
              description,
              createdDateTime,
              readOnly,
              sharedWith,
            };

            let updatedTableData = addObjToArray(tableState.data, { ...dataObj });

            if (!(isCreateMode || isCopyMode)) {
              updatedTableData = updateObjInArray(tableState.data, { ...dataObj }, id);
            }

            setTableData({
              data: updatedTableData,
              totalResults: updatedTableData.length,
            });
            handleClose();
          }}
          onCancel={handleClose}
        />
      </Slider>
      <Confirmation
        title={languagePack.deleteTemplateConfTitle}
        show={showConfirm}
        content={`${languagePack.deleteTemplateConfContent} ${(deleteObj || {}).name || ''}`}
        confirmCallback={() => {
          deleteTemplate(
            deleteObj.id,
            () => {
              toast.success(languagePack.templateDeleteSuccess);

              const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);
              setTableData({
                data: updatedTableData,
                totalResults: updatedTableData.length,
              });
            },
            () => {
              toast.error(languagePack.templateDeleteError);
            },
          );

          setShowConfirm(false);
        }}
        cancelCallback={() => {
          setShowConfirm(false);
          setDeleteObj();
        }}
      />
    </Fragment>
  );
}

VacancyTemplates.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  // searchTerm: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  totalAccounts: PropTypes.number,
};

VacancyTemplates.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  // searchTerm: null,
  tablePrefs: {
    pageSize: 25,
  },
  activeAccount: {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    tablePrefs,
    activeAccount: { id: activeAccountId, name: activeAccountName },
    totalAccounts: accountAccess.length,
  };
}

export default connect(mapStateToProps)(VacancyTemplates);
