import { SET_STYLES } from '../constants/actionTypes';

const defaultState = {
  primaryColour: undefined,
  secondaryColour: undefined,
  gradientColour2: undefined,
  gradientColour4: undefined,
  logoUrl: undefined,
};

export default function styleReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_STYLES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
