/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import { useFormKeypress, useMounted } from '../../../Base/hooks';
import { ValueDisplay } from '../../../Base/ValueDisplay';
import { CreateButton, EditButton, CancelButton } from '../../../Base/Buttons';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { Confirmation } from '../../../Base/Modal';
import { retryableAPICall } from '../../../../api/common-api-utils';
import {
  createPublisherBoardAccount,
  updatePublisherBoardAccount,
} from '../../../../api/PublishingAPI/PublishingSettingsAPI';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';
import config from '../../../../config/config';
import { IconSVG } from '../../../Base/SVG';

const { publishing } = config;

function IndeedSettings({ initFormData, languagePack, userDetails }) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const cachedFormData = useRef({});
  const [isEditing, setIsEditing] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [indeedFeedUrl] = useState(`${publishing.vacancyFeedUrl}/indeed/latest/${userDetails.data.tenant}.xml`);

  useEffect(() => {
    if (isMounted()) {
      if (Object.keys(initFormData).length) {
        setFormData(initFormData);
        cachedFormData.current = initFormData;
        setIsEditing(false);
        setIsNew(false);
      }
    }
  }, [initFormData, isMounted]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function saveProfileDetails(callback = () => {}) {
    const { apiKey } = formData;

    let resp;
    if (initFormData.id) {
      resp = await retryableAPICall(() =>
        updatePublisherBoardAccount({
          id: initFormData.id,
          name: 'Indeed',
          type: 'INDEED',
          apiKey,
        }),
      );
    } else {
      resp = await retryableAPICall(() =>
        createPublisherBoardAccount({
          name: 'Indeed',
          type: 'INDEED',
          apiKey,
        }),
      );
    }

    if (typeof resp === 'string') {
      toast.error(languagePack.saveErrorMessage);
      setIsSaving(false);
    } else {
      if (resp.status === 'FAILED' && resp.errorReason === 'INVALID_CREDENTIALS') {
        toast.error(languagePack.invalidCredsMessage);
      } else if (resp.status === 'FAILED') {
        toast.error(languagePack.saveErrorMessage);
      } else {
        toast.success(initFormData.id ? languagePack.saveUpdateSuccessMessage : languagePack.saveSuccessMessage);
        setFormData(resp.account);
        callback();
      }
      setIsSaving(false);
    }
  }

  function handleSave() {
    setIsSaving(true);

    const errObj = validation([{ id: 'apiKey', required: true }], formData);
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors && !isNew) setShowConfirm(true);
    if (isNew) {
      saveProfileDetails(() => {
        setIsEditing(false);
        setShowConfirm(false);
        setIsSaving(false);
        setIsNew(false);
      });
    }
  }

  async function copyUrlToClip() {
    await navigator.clipboard.writeText(indeedFeedUrl);
    toast.success(languagePack.copyUrlSuccess);
  }

  return (
    <Fragment>
      <h5>{languagePack.indeedApiKeyHeader}</h5>
      <Form innerRef={formRef}>
        <p className="mb-2">{languagePack.indeedApiKeyDescription}</p>
        <p className="mb-2">
          {languagePack.indeedFeedXMLPrefix}
          <strong>
            {indeedFeedUrl}
            <Tippy content={languagePack.copyUrl}>
              <span style={{ marginLeft: '2px', cursor: 'pointer', fontSize: '16px' }} onClick={copyUrlToClip}>
                <IconSVG name="File" />
              </span>
            </Tippy>
          </strong>{' '}
          {languagePack.indeedFeedXMLSuffix}
          <br />
          <div className="mt-2">{languagePack.indeedFeedXMLNote}</div>
        </p>
        <FormGroup
          row
          id="apiKey"
          label={`${languagePack.indeedApiKeyTitle || 'API Key'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.apiKey}
        >
          {isEditing ? (
            <Input type="password" id="apiKey" onChange={(e) => handleChange('apiKey', e.target.value)} />
          ) : (
            <ValueDisplay value="************************" />
          )}
        </FormGroup>
        {isEditing ? (
          <Fragment>
            <CreateButton
              className="mt-2"
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
            />
            <CancelButton
              hidden={isNew}
              className="mt-2 ms-2"
              floatRight={false}
              action={() => {
                setFormData(cachedFormData.current);
                setIsEditing(false);
              }}
            />
          </Fragment>
        ) : (
          <EditButton className="mt-2" action={() => setIsEditing(true)} floatRight={false} />
        )}
      </Form>
      <Confirmation
        show={showConfirm}
        content={languagePack.confirmMessage}
        confirmCallback={() => {
          saveProfileDetails(() => {
            setIsEditing(false);
            setShowConfirm(false);
            setIsSaving(false);
            setIsNew(false);
          });
        }}
        cancelCallback={() => {
          setFormData(cachedFormData.current);
          setIsEditing(false);
          setShowConfirm(false);
          setIsSaving(false);
        }}
      />
    </Fragment>
  );
}

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

IndeedSettings.propTypes = {
  initFormData: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  userDetails: PropTypes.shape(),
};

IndeedSettings.defaultProps = {
  initFormData: {},
  languagePack: {},
  userDetails: {},
};

export default connect(mapStateToProps, null)(IndeedSettings);
