import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { event, api } = config;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */

export async function getEventNotifications(lastPollDateTime) {
  let url = `${api.middlewareAPIURL}${event.eventNotificationsPath}`;
  if (lastPollDateTime) {
    url += `?since=${lastPollDateTime}`;
  }
  const result = await axios.get(
    url,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) {
    return result.data;
  }
  return null;
}

export async function markNotificationAsViewed(eventIds, viewed) {
  const body = {
    eventIds,
    viewed,
  };

  const result = await axios.put(
    `${api.middlewareAPIURL}${event.notificationViewedPath}`,
    body,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) {
    return result.data;
  }
  return null;
}

export async function deleteNotifications(eventIds) {
  const result = await axios.delete(
    `${api.middlewareAPIURL}${event.eventNotificationsPath}`,
    { headers: getCommonHeaders(), data: eventIds },
  );

  if (result.status === 201 || result.status === 200) {
    return result.data;
  }
  return null;
}
