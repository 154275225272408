// eslint-disable-next-line import/prefer-default-export
export async function copy(text, onSuccess = () => { }) {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy!', err);
    }
  }
}
