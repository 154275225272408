import React from 'react';
import BaseSVG from '../BaseSVG';

function NoDataTriangle(props) {
  return (
    <BaseSVG
      {...props}
      name="No Data Triangle"
      viewBox="0 0 92 99"
      // eslint-disable-next-line max-len
      d="M65.468 20.236l7.65-9.313c.443-.54.359-1.333-.19-1.771a1.29 1.29 0 0 0-1.797.187l-7.65 9.314c-.443.54-.359 1.333.19 1.77.236.189.52.28.803.28.373 0 .74-.16.994-.467zm-39.28.187c.55-.437.634-1.23.19-1.77l-7.65-9.314a1.29 1.29 0 0 0-1.795-.187 1.247 1.247 0 0 0-.19 1.77l7.65 9.314c.252.308.62.467.993.467.282 0 .566-.091.802-.28zm20.689-4.653V1.26c0-.696-.573-1.26-1.278-1.26-.705 0-1.277.564-1.277 1.26v14.51c0 .695.572 1.26 1.277 1.26s1.278-.565 1.278-1.26zM41.39 93.124h9.22l30.47-46.71H10.92l30.47 46.71zM53.865 99h-15.73L0 40.539h92L53.865 99z"
    />
  );
}

export default NoDataTriangle;
