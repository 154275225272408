import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAllTags } from '../../../../js/actions/tagActions';
import { getTags } from '../../Tags/utils';
import { useMounted } from '../../hooks';

function TagsFilter({
  tags,
  setTags,
  children,
  tagsLoaded,
}) {
  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    if (isMounted()) {
      if (!tags.length && !tagsLoaded) {
        getTags(
          (tagArr) => setTags(tagArr),
          () => toast.error('Error fetching tags, please try again.'),
        );
      }

      setOptionData(tags.map(({ id, name }) => ({ value: id, label: name })));
    }
  }, [isMounted, setTags, tags, tagsLoaded]);

  return children({ optionData });
}

TagsFilter.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  tagsLoaded: PropTypes.bool,
  setTags: PropTypes.func,
  children: PropTypes.func,
};

TagsFilter.defaultProps = {
  tags: [],
  tagsLoaded: false,
  setTags: () => { },
  children: () => { },
};

function mapStateToProps(state) {
  const { tags } = state;
  return {
    tags: tags.tags || [],
    tagsLoaded: tags.tagsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTags: (tags) => {
      dispatch(setAllTags(tags));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsFilter);
