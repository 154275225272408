const inputConfig = [
  {
    id: 'locations',
    label: 'Location',
    uriFieldName: 'division',
    updateFieldId: 'divisions',
    queryId: 'location',
    type: 'select',
    required: true,
  },
  {
    id: 'divisions',
    label: 'Division',
    uriFieldName: 'jobTitle',
    queryFieldNames: ['location'],
    updateFieldId: 'jobTitles',
    queryId: 'division',
    type: 'select',
    helpText: 'Select Location to update options',
    required: true,
  },
  {
    id: 'jobTitles',
    label: 'Job Title',
    uriFieldName: 'paidByRota',
    queryFieldNames: ['location', 'division'],
    updateFieldId: 'paidByRota',
    queryId: 'jobTitle',
    type: 'select',
    helpText: 'Select Location and Division to update options',
    required: true,
  },
  {
    id: 'paidByRota',
    label: 'Paid By Rota',
    uriFieldName: 'payType',
    queryFieldNames: ['location', 'division', 'jobTitle'],
    updateFieldId: 'payType',
    queryId: 'paidByRota',
    updatedBy: 'jobTitles',
    optionLookup: ['Yes', 'No'],
    type: 'select',
    // helpText: 'Select Location, Division, Job Title to update options',
    required: true,
  },
  {
    id: 'payType',
    label: 'Pay Type',
    uriFieldName: 'inclInRota',
    queryFieldNames: ['location', 'division', 'jobTitle', 'paidByRota'],
    updateFieldId: 'inclInRota',
    queryId: 'payType',
    updatedBy: 'paidByRota',
    optionLookup: ['Shift', 'Hourly'],
    type: 'select',
    // helpText: 'Select Location, Division, Job Title, Paid By Rota to update options',
    // fallbackType: 'text',
    required: true,
  },
  {
    id: 'inclInRota',
    label: 'Included in Rota',
    type: 'select',
    updatedBy: 'payType',
    optionLookup: ['Yes', 'No'],
    // helpText: 'Select Location, Division, Job Title, Paid By Rota, Pay Type to update options',
    // fallbackType: 'text',
    required: true,
  },
  {
    id: 'rateOfPay',
    label: 'Rate of Pay',
    type: 'number',
    required: true,
  },
  {
    id: 'annualSalary',
    label: 'Annual Salary',
    type: 'number',
    required: true,
  },
  {
    id: 'employmentType',
    label: 'Employment Type',
    type: 'select-config',
    fallbackType: 'text',
    required: true,
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'select-config',
    fallbackType: 'number',
  },
  {
    id: 'employeeSource',
    label: 'Employee Source',
    type: 'select-config',
    fallbackType: 'text',
    required: true,
  },
  {
    id: 'paymentMethod',
    label: 'Payment Method',
    type: 'select-config',
    required: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
  {
    id: 'terminationDate',
    label: 'Termination Date',
    type: 'date',
  },
];

export default inputConfig;
