import { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { getCommonHeaders } from '../../../../../api/common-api-utils';
import config from '../../../../../config/config';

const { api } = config;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  gap: 8px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #e0e0e0;
  border-radius: 16px;
  font-size: 12px;
  color: #333;
`;

const RemoveIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #c0c0c0;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #a0a0a0;
  }
`;

function fetchData(path, opts) {
  if (!path) return;

  const { value, onSuccess = () => {} } = opts;

  let url = `${api.middlewareAPIURL}${path}`;
  if (value) url += `/${value}`;

  axios
    .get(url, {
      headers: getCommonHeaders(),
    })
    .then((result) => {
      if (result.status === 200) {
        let respArr = [];

        if (result.data && Array.isArray(result.data)) {
          respArr = result.data.map((opt) => ({ value: opt.id, label: opt.name }));
        }

        onSuccess(respArr);
      }
    });
}

function JobSkillsSelect(props) {
  const { formData, schema = {}, name, required, onChange } = props;

  const [freeText, setFreeText] = useState(null);
  const { title, path } = schema;

  function handleChange(val) {
    setFreeText(null);
    if (formData) {
      onChange([...formData, ...val]);
    } else {
      onChange(val);
    }
  }

  function handleRemoveChip(removedValue) {
    const newSelectedChips = formData.filter((chip) => chip.value !== removedValue);

    // Set to undefined for validation to work
    if (newSelectedChips.length === 0) {
      onChange(undefined);
    } else {
      onChange(newSelectedChips);
    }
  }

  function loadOptions(val, callback) {
    if (val.length > 1) {
      fetchData(path, {
        value: val,
        onSuccess: (resp) => {
          setTimeout(() => {
            if (resp && Array.isArray(resp)) {
              const opts = resp.map((opt) => ({ value: opt.value, label: opt.label }));

              callback(opts);
            }
          }, 1000);
        },
      });
    }
  }

  return (
    <>
      {title && (
        <Label className="control-label" htmlFor={name}>
          {title}
          {required && <span className="required">*</span>}
        </Label>
      )}
      <AsyncSelect
        isMulti
        id={`${name}-async-dd-select`}
        classNamePrefix="react-select"
        cacheOptions
        inputId={name}
        defaultOptions={[]}
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder="Start typing for skills..."
        value={freeText}
      />
      <ChipsContainer>
        {formData?.map((chip) => (
          <Chip key={chip.value}>
            {chip.label}
            <RemoveIcon onClick={() => handleRemoveChip(chip.value)}>×</RemoveIcon>
          </Chip>
        ))}
      </ChipsContainer>
    </>
  );
}

JobSkillsSelect.propTypes = {
  formData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ]),
    ),
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  schema: PropTypes.shape({
    title: PropTypes.string,
    isMultiple: PropTypes.bool,
    path: PropTypes.string,
    fetchOnce: PropTypes.bool,
  }).isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

JobSkillsSelect.defaultProps = {
  formData: undefined,
  required: false,
  onChange: () => {},
};

export default JobSkillsSelect;
