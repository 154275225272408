import { useReducer, useCallback } from 'react';

const defaultState = {
  isLoading: false,
  isRejected: false,
  data: [],
  totalPages: 1,
  totalResults: 0,
  rowsPerPage: 25,
};

const SET_LOADING = 'SET_LOADING';
const SET_REJECTED = 'SET_REJECTED';
const SET_DATA = 'SET_DATA';
const SET_ROWS = 'SET_ROWS';

function tableReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
        isRejected: false,
      };
    case SET_REJECTED:
      return {
        ...state,
        isLoading: false,
        isRejected: true,
        data: [],
        totalPages: 1,
        totalResults: 0,
      };
    case SET_DATA:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isRejected: false,
      };
    case SET_ROWS:
      return {
        ...state,
        rowsPerPage: payload,
        isLoading: false,
        isRejected: false,
      };
    default:
      return state;
  }
}

export default function useTableState(state = {}) {
  const [tableState, dispatch] = useReducer(tableReducer, { ...defaultState, ...state });

  const setTableData = useCallback((payload) => dispatch({ type: SET_DATA, payload }), []);
  const setRows = useCallback((payload) => dispatch({ type: SET_ROWS, payload }), []);
  const setTableLoading = useCallback((payload) => dispatch({ type: SET_LOADING, payload }), []);
  const setTableRejected = useCallback(() => dispatch({ type: SET_REJECTED }), []);

  return {
    tableState,
    setTableData,
    setRows,
    setTableLoading,
    setTableRejected,
  };
}
