import React from 'react';
import BaseSVG from '../BaseSVG';

function PaperClip(props) {
  return (
    <BaseSVG
      {...props}
      name="Paper Clip"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M7.12367579,26.6712643 C4.25434642,23.6097179 4.30782408,18.6726705 7.19173827,15.6222452 L17.492449,4.72658537 C19.6686804,2.42457311 23.2061122,2.42436998 25.38254,4.72658537 C27.5378518,7.00635548 27.5404545,10.6846899 25.38254,12.9671515 L16.4033512,22.4557157 C14.9372579,24.0064205 12.5419105,23.9847877 11.1006655,22.4050361 C9.71216151,20.8831243 9.75670167,18.4708661 11.171969,16.973837 L18.2307746,9.51738306 C18.5343549,9.19675072 19.0318101,9.19126635 19.3418725,9.5051448 L20.4645105,10.6415767 C20.774622,10.955506 20.7798764,11.4699193 20.4763453,11.7905516 L13.4182763,19.2462946 C13.17608,19.5024857 13.1611514,19.9281843 13.3864549,20.1751839 C13.6011512,20.4105039 13.9386645,20.414414 14.1568475,20.1835628 L23.1360363,10.6950493 C24.0991744,9.67627736 24.0991744,8.01761181 23.1354962,6.99828127 C22.1931795,6.00159913 20.6822515,6.00109132 19.739542,6.99828127 L9.43878214,17.8938904 C7.73167112,19.699669 7.7053497,22.6226334 9.38029555,24.4097754 C11.0504289,26.1918392 13.7500931,26.1940735 15.4233203,24.4242988 L23.8726923,15.4868626 C24.1760271,15.1659764 24.6734822,15.1601873 24.9837902,15.4738626 L26.1072139,16.6095329 C26.4175218,16.9232082 26.4231691,17.4376215 26.1197853,17.7585077 L17.6704132,26.6958931 C14.7448071,29.7903964 10.0125007,29.7537324 7.12367579,26.6712643 Z"
    />
  );
}

export default PaperClip;
