import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from 'reactstrap';
import IconSVG from '../SVG/IconSVG';

export default function EditButton({ className, disabled, hidden, action, label, isLoading, floatRight, size }) {
  if (hidden) return null;
  return (
    <Button
      disabled={disabled}
      className={cx('edit-button', 'btn-outline', className, { 'float-end': floatRight })}
      onClick={action}
      size={size}
    >
      {isLoading ? (
        <IconSVG name="Talent Funnel" className="pulse btn-loading-icon" />
      ) : (
        <span>
          <i className="fa fa-pencil fa-btn" />
        </span>
      )}
      {label}
    </Button>
  );
}

EditButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  action: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  floatRight: PropTypes.bool,
  size: PropTypes.string,
};

EditButton.defaultProps = {
  className: null,
  disabled: false,
  hidden: false,
  action: null,
  label: 'Edit',
  isLoading: false,
  floatRight: true,
  size: null,
};
