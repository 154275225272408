import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToAccess } from '../../../../api/Integrations/AccessAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Integration from './Integration';

const accessInputConfig = [
  {
    id: 'section',
    label: 'Section',
    type: 'select',
    required: true,
    exclude: true,
    url: 'access/data/sections',
    dataMapper: (data) => {
      return { label: data.description, value: data.guid };
    },
  },
  {
    id: 'subSection',
    label: 'Sub-section',
    type: 'select',
    required: true,
    exclude: true,
    url: 'access/data/subsections/{sectionId}',
    dataMapper: (data) => {
      return { label: data.description, value: data.guid };
    },
  },
  {
    id: 'post',
    label: 'Post',
    type: 'select',
    required: true,
    exclude: true,
    url: 'access/data/posts/{subSectionId}',
    dataMapper: (data) => {
      return { label: data.postTitle, value: data.guid };
    },
  },
  {
    id: 'postStartDate',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'contractBasisGuid',
    label: 'Contract Basis',
    required: true,
    type: 'select',
    url: 'access/data/CONTRACT_BASIS',
  },
  {
    id: 'contractTypeGuid',
    label: 'Contract Type',
    type: 'select',
    required: true,
    url: 'access/data/CONTRACT_TYPE',
  },

  {
    id: 'payrollGuid',
    label: 'Payroll Type',
    type: 'select',
    required: true,
    url: 'access/data/PAYROLL',
  },
  {
    id: 'salaryTypeId',
    label: 'Salary Type',
    type: 'select',
    required: true,
    options: [
      { label: 'Hourly', value: 'Hourly' },
      { label: 'Shift', value: 'Shift' },
      { label: 'Salary', value: 'Salary' },
      { label: 'FixedWeekly', value: 'FixedWeekly' },
      { label: 'Payscale', value: 'Payscale' },
    ],
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'contractDays',
    label: 'Contract Days',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 0,
      max: 7,
    },
  },
  {
    id: 'salaryValue',
    label: 'Salary',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 1,
      max: 999999999,
    },
  },
];

const labelMap = {
  contractBasisGuid: 'contractBasisLabel',
  contractTypeGuid: 'contractTypeLabel',
  payrollGuid: 'payrollLabel',
  section: 'sectionName',
  subSection: 'subSectionName',
  post: 'postName',
};

const resolveUrlAndDisabledState = (urlTemplate, formData, id) => {
  const sectionId = formData.section;
  const subSectionId = formData.subSection;

  switch (id) {
    case 'subSection':
      return sectionId
        ? { url: urlTemplate.replace('{sectionId}', sectionId), isDisabled: false }
        : { url: undefined, isDisabled: true };
    case 'post':
      return subSectionId
        ? { url: urlTemplate.replace('{subSectionId}', subSectionId), isDisabled: false }
        : { url: undefined, isDisabled: true };
    default:
      return { url: urlTemplate, isDisabled: false };
  }
};

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  const { url: convertedUrl, isDisabled: convertedIsDisabled } = resolveUrlAndDisabledState(url, formData, id);

  if (isComplete && labelMap[id]) {
    convertedValue = formData[labelMap[id]];
  }

  return { url: convertedUrl, value: convertedValue, isDisabled: convertedIsDisabled || isDisabled };
}

function AccessForm({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, ...rest } = await retryableAPICall(() =>
        submitToAccess({
          section: formData.section,
          subSection: formData.subSection,
          post: formData.post,
          postStartDate: formData.postStartDate,
          contractBasisGuid: formData.contractBasisGuid,
          contractTypeGuid: formData.contractTypeGuid,
          payrollGuid: formData.payrollGuid,
          salaryTypeId: formData.salaryTypeId,
          contractHours: formData.contractHours,
          contractDays: formData.contractDays,
          salaryValue: formData.salaryValue,
          candidateId: applicantId,
        }),
      );
      return status === 'NOT_FOUND_ERROR' ? 'NOT_FOUND_ERROR' : { status, message, data: rest };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    const updatedData = { ...formData, [id]: value };
    if (id === 'section' || id === 'subSection') {
      updatedData.subSection = id === 'section' ? null : updatedData.subSection;
      updatedData.post = null;
    }
    setFormData(updatedData);
  };

  async function getAccessData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }
      const { status, message, ...rest } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: message,
          data: rest,
        };
      }

      return {
        status: 'SUCCESS',
        data: rest,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return accessInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };
  return (
    <Integration
      title="Access Group"
      applicantId={applicantId}
      integrationName="Access Group"
      formConfig={accessInputConfig}
      formSubmit={formSubmit}
      valueSetter={valueSetter}
      getSubmission={getAccessData}
      componentName="AccessForm"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

AccessForm.propTypes = {
  applicantId: PropTypes.string,
};

AccessForm.defaultProps = {
  applicantId: null,
};

export default AccessForm;
