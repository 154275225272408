// Contains a set of API calls to middleware services in order to get data around events
import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

export async function searchVacancy(keywords, searchFields, filters, fields, page, limit, sort) {
  const body = {
    fields: fields || ['id', 'positionProfile.title'],
    filters: filters || [],
    keywords,
    limit,
    searchFields,
    sort,
    start: page * limit,
  };

  const result = await axios.post(`${config.api.middlewareAPIURL}${config.search.vacancySearchPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function searchCandidates(
  keywords,
  searchFields,
  sort,
  filters,
  fields,
  page,
  limit,
  groupBy,
  betaEnabled,
) {
  const funnelStageIdFilter = filters.find((filter) => filter.field === 'funnelStageId');

  if (funnelStageIdFilter) {
    const finalValues = [];
    funnelStageIdFilter.value.forEach((value) => {
      value.split(',').forEach((split) => finalValues.push(split));
    });
    funnelStageIdFilter.value = finalValues;
  }
  const body = {
    beta: betaEnabled ? betaEnabled : false,
    groupBy,
    keywords,
    searchFields,
    // ignore questionAnswers filters - they're special
    filters: (filters || []).filter(({ field }) => field !== 'questionAnswers'),
    // create questionAnswers from filters
    questionAnswers: (filters || [])
      .filter(({ field }) => field === 'questionAnswers')
      .map((filter) => {
        const { operation, value } = filter;
        return { question: value?.question || '', operation, answer: value?.answer || '' };
      }),
    fields,
    sort,
    start: page * limit,
    limit,
  };

  const result = await axios.post(`${config.api.middlewareAPIURL}${config.search.candidateSearchPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function searchTalentPool(
  keywords,
  skillsRequired,
  filters,
  location,
  page,
  limit,
  sort,
  vacancyId,
  vacancyTitle,
) {
  const body = {};

  body.searchString = keywords;
  body.filters = filters;
  body.skills = skillsRequired;
  body.location = location;
  body.start = page * limit;
  body.limit = limit;
  body.sort = sort;

  if (vacancyId) body.vacancyId = vacancyId;
  if (vacancyTitle) body.vacancyTitle = vacancyTitle;

  const result = await axios.post(`${config.api.middlewareAPIURL}${config.search.talentMatchPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function searchAccount(keywords, searchFields, page, limit) {
  const body = {
    keywords,
    searchFields,
    start: page * limit,
    limit,
  };

  const result = await axios.post(`${config.api.middlewareAPIURL}${config.admin.accountSearchPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function searchCandidatesTypeAhead(query, searchFields, filters, fieldIds, limit, aggs, sort, page, rich) {
  const result = await axios.post(
    `${config.api.middlewareAPIURL}${config.search.candidateTypeAheadSearchPath}?rich=${rich}`,
    {
      groupBy: aggs,
      keywords: query,
      searchFields: searchFields,
      filters: (filters || []).filter(({ field }) => field !== 'questionAnswers'),
      // create questionAnswers from filters
      questionAnswers: (filters || [])
        .filter(({ field }) => field === 'questionAnswers')
        .map((filter) => {
          const { operation, value } = filter;
          return { question: value?.question || '', operation, answer: value?.answer || '' };
        }),
      fields: fieldIds,
      sort,
      start: page * limit,
      limit,
    },
    {
      headers: getCommonHeaders(),
    },
  );

  if (result.status === 200) return result.data;

  return null;
}

export async function textToSpeech(audioBlob) {
  const result = await axios.post(`${config.api.middlewareAPIURL}/tts`, audioBlob, {
    headers: { ...getCommonHeaders(), 'Content-Type': 'application/octet-stream' },
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function getSharedSearch(id) {

  const result = await axios.get(`${config.api.middlewareAPIURL}${config.search.sharePath}/${id}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function shareSearch(sharedWithUserList, message, searchString, filters, type) {

  const postObj = {
    sharedWithUserList,
    type,
    message,
    filters,
    searchString
  }
  const result = await axios.post(`${config.api.middlewareAPIURL}${config.search.sharePath}`, postObj, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}


export async function saveSearch(name, searchString, filters, type) {

  const postObj = {
    name,
    type,
    filters,
    searchString
  }
  const result = await axios.post(`${config.api.middlewareAPIURL}${config.search.savePath}`, postObj, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function getSavedSearch() {

  const result = await axios.get(`${config.api.middlewareAPIURL}${config.search.savePath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function updateSavedSearchUse(id) {

  const result = await axios.put(`${config.api.middlewareAPIURL}${config.search.savePath}/use/${id}`, null,{
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function deleteSavedSearchUse(id) {

  const result = await axios.delete(`${config.api.middlewareAPIURL}${config.search.savePath}/${id}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}
