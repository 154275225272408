import Import from './Import';

function VacancyList(props) {
  return (
    <Import
      desktop={() => import('../views/Vacancy/View/VacancyList/VacancyList')}
      touch={() => import('../touch/Vacancy/Vacancy')}
    >
      {(Comp) => <Comp {...props} />}
    </Import>
  );
}

export default VacancyList;
