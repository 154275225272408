/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from 'moment';
import cx from 'classnames';
import Required from '../../../Base/Forms/Custom/Required';
import { DatePickerInput } from '../../../Base/Forms/Custom/DatePicker';
import { SimpleRadios } from '../../../Base/Forms/Custom/RadioBtns';
import { EmailPillsInput } from '../../../Base/Forms/Custom/EmailPills';
import RTE from '../../../Base/Forms/Custom/RTE/RTE';
import { getOptions } from '../../utils/fetchUtils';
import CandidateSelect from './CandidateSelect';
import InviteEmailForm from './InviteEmailForm';
import SlotSelect from './SlotSelect';
import LocationSelect from './LocationSelect';
import { SelectInput } from '../../../Base/Forms/Custom/CommonComponents';
import ReminderInput from './ReminderInput';
import ReminderDaysContext from './ReminderDaysContext';
import { ValueDisplay } from '../../../Base/ValueDisplay';
import VideoRoomContext from './VideoRoomContext';
import VideoIconLink from './VideoIconLink';
import InputDisplay from './InputDisplay';
import { LocationsContext } from '../../../Admin/Client/Locations';
import { useMounted } from '../../../Base/hooks';

function getValueFromOpts(opts, val) {
  const choice = opts.find(({ value }) => value === val);
  if (choice) return choice.label;
  return val;
}

function EventInput({
  id,
  className,
  label,
  displayMode,
  type,
  value,
  noTemplates,
  // availableSlots,
  options,
  isAsync,
  displayIfLT2,
  isMulti,
  required,
  disabled,
  invalid,
  errorMessage,
  helpText,
  min,
  max,
  onChange,
  onOptionLoad,
  inline,
  activeAccountId,
  isTouch,
  eventTypeId,
  inviteType,
}) {
  const isMounted = useMounted();
  const [selectOpts, setSelectOpts] = useState([]);
  const [asyncLoaded, setAsyncLoaded] = useState(false);

  useEffect(() => {
    if (!isAsync) setSelectOpts(options);
  }, [isAsync, options]);

  useEffect(() => {
    const fetchOptions = async () => {
      const opts = await getOptions(id);
      setAsyncLoaded(true);
      setSelectOpts(opts);
      onOptionLoad(opts, id, displayIfLT2);
    };

    if (isAsync && isMounted() && !asyncLoaded) {
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncLoaded, displayIfLT2, id, isAsync, isMounted]);

  if (type === 'select' && selectOpts.length < 2 && !displayIfLT2) {
    return null;
  }

  return (
    <FormGroup className={className} row={inline} className="disabled-margin-bottom pb-2">
      {type !== 'checkbox' && type !== 'slotSelect' && type !== 'sendReminder' && label && (
        <Label className={cx({ 'read-label': displayMode === 'read' })} for={id} sm={inline ? 2 : null}>
          {label}
          {displayMode === 'read' && ':'}
          {required && displayMode !== 'read' && <Required />}
        </Label>
      )}
      <InputDisplay inline={inline}>
        <>
          {type === 'date' &&
            (displayMode === 'read' ? (
              <ValueDisplay value={(value || moment()).format('DD-MM-YYYY')} />
            ) : (
              <DatePickerInput disabled={disabled} id={id} onChange={onChange} value={value || moment()} />
            ))}
          {type === 'select' &&
            (displayMode === 'read' ? (
              <ValueDisplay value={getValueFromOpts(selectOpts, value)} />
            ) : (
              <>
                <SelectInput
                  disabled={disabled}
                  isMulti={isMulti}
                  onChange={onChange}
                  options={selectOpts}
                  value={value}
                />
                {/* {availableSlots && availableSlots === 0
                && (<p className="error">There are no available slots for this event type. Create a slot?</p>)}
                {availableSlots && availableSlots > 0
                && <p className="success">{`There are ${availableSlots} for this event type`}</p>} */}
              </>
            ))}
          {type === 'locationSelect' && asyncLoaded && (
            <VideoRoomContext.Consumer>
              {({ videoRoomId, bookedInCandidates }) =>
                displayMode === 'read' ? (
                  <>
                    {videoRoomId.length ? (
                      <VideoIconLink bookedInCandidates={bookedInCandidates} videoRoomId={videoRoomId} />
                    ) : (
                      <ValueDisplay value={getValueFromOpts(selectOpts, value)} />
                    )}
                  </>
                ) : (
                  <LocationsContext.Consumer>
                    {({ eventFormat: evtFormat }) => {
                      let locOpts = selectOpts;
                      if (evtFormat) {
                        locOpts = selectOpts.filter(({ eventFormat }) => !eventFormat || eventFormat === evtFormat);
                      }

                      return (
                        <LocationSelect
                          eventFormat={evtFormat}
                          selectProps={{
                            disabled,
                            isMulti,
                            onChange,
                            options: locOpts,
                            value,
                          }}
                        />
                      );
                    }}
                  </LocationsContext.Consumer>
                )
              }
            </VideoRoomContext.Consumer>
          )}
          {type === 'candidateSelect' && (
            <CandidateSelect
              activeAccountId={activeAccountId}
              disabled={disabled}
              onChange={onChange}
              value={value || []}
            />
          )}
          {type === 'slotSelect' && (
            <SlotSelect
              activeAccountId={activeAccountId}
              disabled={disabled}
              eventTypeId={eventTypeId}
              isInviteOnly={inviteType === 'SPECIFIC'}
              isTouch={isTouch}
              label={label}
              onChange={onChange}
              required={required}
              value={value}
            />
          )}
          {/radio|checkbox/.test(type) && (
            <div className={cx(`event-input-${type}`, { 'd-inline-block': displayMode === 'read' })}>
              <FormGroup check inline className="disabled-margin-bottom">
                {type === 'radio' ? (
                  <>
                    {displayMode === 'read' ? (
                      <ValueDisplay value={getValueFromOpts(options, value)} />
                    ) : (
                      options.map((opt) => (
                        <SimpleRadios
                          key={opt.id}
                          {...opt}
                          checked={value === opt.value}
                          name={id}
                          onChange={onChange}
                        />
                      ))
                    )}
                  </>
                ) : (
                  <>
                    {displayMode === 'read' ? (
                      <>
                        <Label for={id}>{label}</Label>
                        <ValueDisplay value={String(value)} />
                      </>
                    ) : (
                      <Label check>
                        <Input
                          checked={value}
                          className="standard-checkbox"
                          disabled={disabled}
                          id={id}
                          onChange={(e) => onChange(e.target.checked)}
                          type="checkbox"
                        />
                        <span className="bottom-0">{label}</span>
                      </Label>
                    )}
                  </>
                )}
              </FormGroup>
            </div>
          )}
          {type === 'emailAddresses' && <EmailPillsInput onChange={onChange} value={value || []} />}
          {type === 'richtext' &&
            (displayMode === 'read' ? (
              <ValueDisplay isMultiline value={value} />
            ) : (
              <RTE
                formData={value}
                onChange={onChange}
                schema={{}}
                uiSchema={{
                  modules: {
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                        { align: '' },
                        { align: 'center' },
                        { align: 'right' },
                      ],
                      ['link'],
                    ],
                  },
                }}
              />
            ))}
          {type === 'email' && (
            <InviteEmailForm
              noTemplates={noTemplates}
              errorMessage={errorMessage}
              invalid={invalid}
              onChange={onChange}
              value={typeof value === 'string' ? {} : value}
            />
          )}
          {type === 'sendReminder' && (
            <ReminderDaysContext.Consumer>
              {({ startDate, inviteType }) => (
                <ReminderInput
                  disabled={disabled}
                  id={id}
                  inviteType={inviteType}
                  label={label}
                  onChange={onChange}
                  startDate={startDate}
                  value={value}
                />
              )}
            </ReminderDaysContext.Consumer>
          )}
          {/^(text|number)$/.test(type) &&
            (displayMode === 'read' ? (
              <ValueDisplay value={value} />
            ) : (
              <Input
                disabled={disabled}
                id={id}
                max={max}
                min={min}
                onChange={(e) => onChange(e.target.value)}
                type={type}
                value={value}
              />
            ))}
        </>
        {helpText && displayMode !== 'read' && <FormText color="muted">{helpText}</FormText>}
        {type !== 'email' && (
          <div className={`invalid-feedback${invalid ? ' d-block' : ' d-none'}`}>{errorMessage}</div>
        )}
      </InputDisplay>
    </FormGroup>
  );
}

EventInput.propTypes = {
  activeAccountId: PropTypes.string,
  availableSlots: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayIfLT2: PropTypes.bool,
  displayMode: PropTypes.string,
  errorMessage: PropTypes.string,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  invalid: PropTypes.bool,
  eventTypeId: PropTypes.string,
  isAsync: PropTypes.bool,
  isMulti: PropTypes.bool,
  isTouch: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onOptionLoad: PropTypes.func,
  noTemplates: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ),
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape(),
  ]),
};

EventInput.defaultProps = {
  activeAccountId: null,
  availableSlots: undefined,
  className: null,
  disabled: false,
  displayIfLT2: true,
  displayMode: 'create',
  errorMessage: null,
  helpText: null,
  inline: false,
  invalid: false,
  eventTypeId: null,
  isAsync: false,
  isMulti: false,
  isTouch: false,
  label: null,
  max: null,
  min: 0,
  onChange: () => {},
  onOptionLoad: () => {},
  options: [],
  required: false,
  type: 'text',
  value: '',
  noTemplates: false,
};

export default EventInput;
