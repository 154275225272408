import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function ProfileImage({ src, alt, className }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const onError = useCallback(() => {
    setLoaded(true);
    setError(true);
  }, []);

  return (
    <Fragment>
      {loaded && error && <span className="text-danger">Error loading image</span>}
      {!loaded && <span>Loading image...</span>}
      <img
        src={src}
        alt={alt}
        className={cx(className, { invisible: !loaded }, { 'd-none': error })}
        onLoad={onLoad}
        onError={onError}
      />
    </Fragment>
  );
}

ProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

ProfileImage.defaultProps = {
  alt: '',
  className: null,
};

export default ProfileImage;
