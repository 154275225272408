import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import { addActiveFilter, updateActiveFilter } from '../../../../js/actions/filterActions';
import FilterOptions from './FilterOptions';
import IconSVG from '../../SVG/IconSVG';
import { PRIMARY_COLOUR } from '../../../../js/utils/colours';

function tippyContent(value) {
  let valArr = Array.isArray(value) ? [...value] : value.toString().split(', ');
  const diff = valArr.length - 50;

  valArr = valArr.slice(0, 50);
  const valStr = valArr.join(', ');

  return diff > 0 ? `${valStr} and ${diff} more` : valStr;
}

class ActiveFilter extends Component {
  constructor(props) {
    super(props);

    const {
      filter: { operation, displayValue, displayOperation, isNew },
    } = props;

    this.state = {
      // value: props.filter.value,
      operation,
      displayValue,
      displayOperation,
      isNew,
      editMode: false,
    };

    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  handleApplyFilter(args) {
    const { value, operation, displayValue, displayOperation, location, field, fieldType, fieldOptions } = args;

    const { filter, filterName, updateFilter, addFilter, onSave } = this.props;

    const { editMode } = this.state;

    let fieldProp = field;
    if (field && filter.baseField) fieldProp = `${filter.baseField}.${field}`;

    const filterObj = {
      ...filter,
      ...(fieldProp ? { field: fieldProp } : {}),
      isNew: false,
      value,
      operation,
      displayValue,
      displayOperation,
      location,
      filterName,
      fieldType,
      fieldOptions,
    };

    if (!editMode) {
      addFilter(filterObj);
    } else {
      updateFilter(filterObj);
    }

    this.setState({
      // value,
      operation,
      displayValue,
      // displayOptions: false,
      editMode: false,
      isNew: false,
      displayOperation,
    });

    onSave(filterObj);
  }

  render() {
    const { filter, removeAction, filterName } = this.props;
    const { isNew, editMode, displayValue, displayOperation, operation } = this.state;

    const { type, label, instanceId } = filter;

    let displayValueStr = '';
    if (Array.isArray(displayValue)) {
      displayValueStr = displayValue.length > 1 ? 'Many' : displayValue[0];
    } else {
      displayValueStr = displayValue;
    }

    return (
      <>
        <div className="filter-body">
          {filter.type && <p className="filter-body-type">{`${filter.type}`}</p>}
          <p className="filter-body-label">
            {operation && displayValue ? (
              <Tippy content={`${displayOperation}: ${tippyContent(displayValue)}`} theme="ats">
                <a
                  className="text-truncate"
                  href="#edit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ editMode: !editMode });
                  }}
                >
                  {`${displayOperation}: ${displayValueStr}`}
                </a>
              </Tippy>
            ) : (
              label
            )}
          </p>
          <button className="close" onClick={() => removeAction(instanceId, filter.label)} type="button">
            <IconSVG className="filter-close-icon" fill={PRIMARY_COLOUR} name="Close Circle" width="0.7em" />
          </button>
        </div>
        {(isNew || editMode) && (
          <FilterOptions
            currentFilter={filter}
            filterName={filterName}
            handleApplyFilter={this.handleApplyFilter}
            type={type}
          />
        )}
      </>
    );
  }
}

ActiveFilter.propTypes = {
  filterName: PropTypes.string,
  filter: PropTypes.shape({
    type: PropTypes.string,
    operation: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    displayValue: PropTypes.string,
    displayOperation: PropTypes.string,
    isNew: PropTypes.bool,
    instanceId: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
  removeAction: PropTypes.func,
  onSave: PropTypes.func,
  updateFilter: PropTypes.func,
  addFilter: PropTypes.func,
};

ActiveFilter.defaultProps = {
  filterName: null,
  filter: {},
  removeAction: () => {},
  onSave: () => {},
  updateFilter: () => {},
  addFilter: () => {},
};

function mapDispatchToProps(dispatch) {
  return {
    updateFilter: (filter) => {
      dispatch(updateActiveFilter(filter));
    },
    addFilter: (filter) => {
      dispatch(addActiveFilter(filter));
    },
  };
}

export default connect(null, mapDispatchToProps)(ActiveFilter);
