const inputConfig = [
  {
    id: 'position',
    configId: 'positions',
    label: 'Position',
    type: 'select',
    required: true,
  },
  {
    id: 'payType',
    configId: 'payTypes',
    label: 'Pay Type',
    type: 'select',
    required: true,
  },
  {
    id: 'payRate',
    label: 'Pay Rate',
    type: 'number',
    required: true,
  },
  {
    id: 'contractedHours',
    configId: 'defaultContractedHours',
    label: 'Contracted Hours',
    type: 'number',
    defaultValue: 0,
  },
  {
    id: 'contractedDays',
    configId: 'defaultContractedDays',
    label: 'Contracted Days',
    type: 'number',
    defaultValue: 0,
    inputProps: {
      min: 0,
      max: 7,
    },
  },
  {
    id: 'contractedHolidays',
    configId: 'defaultContractedHolidays',
    label: 'Contracted Holidays',
    type: 'number',
    defaultValue: 28,
    inputProps: {
      min: 0,
      max: 35,
    },
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
];

export default inputConfig;
