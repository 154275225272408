import { EVENT_FORMATS } from '../../../views/Admin/Client/Scheduler/components';

export const locationTypes = [
  { value: 'ALL', label: 'All' },
  { value: 'EVENT', label: 'Event' },
  { value: 'VACANCY', label: 'Regular' },
];

export const schema = {
  type: 'object',
  required: ['locationName', 'locationType', 'eventFormat'],
  properties: {
    address: {
      type: 'object',
      properties: {
        address: {
          type: 'object',
          title: 'Address',
        },
      },
    },
    locationName: {
      type: 'string',
      title: 'Name',
    },
    defaultLocation: {
      type: 'boolean',
      title: 'This is the default location',
    },
    locationType: {
      type: 'string',
      title: 'Type',
      data: locationTypes,
    },
    sharedWith: {
      type: 'array',
      title: 'Shared With',
    },
    eventFormat: {
      type: 'string',
      title: 'Event Format',
      data: Object.entries(EVENT_FORMATS).map(([key, val]) => ({ value: key, label: val })),
    },
    phoneNumber: {
      type: 'string',
      title: 'Phone Number',
    },
    editable: {
      type: 'boolean',
      title: 'Other accounts can edit this location',
    },
    // payroll: {
    //   type: 'string',
    //   title: 'Integrated Payroll Job Location',
    // },
  },
};

export const uiSchema = {
  'ui:order': Object.keys(schema.properties),
  'ui:rootFieldId': 'location',
  address: {
    address: {
      defaultCountry: 'GB',
      hideCountry: true,
      'ui:field': 'addressLookup',
      'ui:order': ['address'],
      'ui:options': {
        manualEdit: true,
      },
    },
  },
  editable: {
    classNames: 'editable-cb mb-0 mt-3',
  },
  sharedWith: {
    'ui:field': 'accountSelect',
  },
  locationType: {
    'ui:field': 'singleSelect',
    classNames: 'location-type',
  },
  eventFormat: {
    'ui:field': 'singleSelect',
    classNames: 'location-event-format',
  },
  defaultLocation: {
    classNames: 'default-cb',
  },
  // payroll: {
  //   'ui:field': 'remoteSelect',
  // },
};

export const rules = [
  {
    conditions: {
      locationType: {
        not: { equal: 'EVENT' },
      },
    },
    event: {
      type: 'remove',
      params: { field: 'eventFormat' },
    },
  },
  {
    conditions: {
      locationType: {
        equal: 'EVENT',
      },
      eventFormat: {
        not: {
          not: {
            or: [{ equal: 'PHONE' }, { equal: 'VIDEO' }],
          },
        },
      },
    },
    event: {
      type: 'remove',
      params: { field: 'address' },
    },
  },
  {
    conditions: {
      locationType: {
        not: { equal: 'EVENT' },
      },
    },
    event: {
      type: 'remove',
      params: { field: 'phoneNumber' },
    },
  },
  {
    conditions: {
      eventFormat: {
        not: { equal: 'PHONE' },
      },
    },
    event: {
      type: 'remove',
      params: { field: 'phoneNumber' },
    },
  },
];
