import moment from 'moment';
import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  START_POLLING, STOP_POLLING,
  TOGGLE_NOTIFICATION_READ_STATE,
  DELETE_NOTIFICATIONS,
} from '../constants/actionTypes';

const defaultState = {
  lastPollDateTime: null,
  notifications: [],
  error: false,
};

export default function bookingReducer(state = defaultState, action) {
  const { data, type } = action;
  switch (type) {
    case FETCH_NOTIFICATIONS:
      return { ...state, isFetching: true };
    case FETCH_NOTIFICATIONS_SUCCESS:

      let notifs = [];
      if (data && data.notifications.length > 0) {
        const { notifications } = data;
        notifs = [...notifications];
      } else {
        notifs = [...state.notifications];
      }

      return {
        ...state,
        isFetching: false,
        notifications: notifs,
        error: false,
        lastPollDateTime: moment().toISOString(),
      };
    case TOGGLE_NOTIFICATION_READ_STATE:
      const newNotifs = [...state.notifications];

      newNotifs.forEach((notif) => {
        if (notif.ids === data.ids) {
          // eslint-disable-next-line no-param-reassign
          notif.isViewed = data.isViewed;
        }
      });
      return { ...state, notifications: newNotifs };
    case DELETE_NOTIFICATIONS:
      const notifCp = [...state.notifications];
      const filteredNotifs = notifCp.filter((notif) => notif.ids !== data);
      return { ...state, notifications: filteredNotifs };
    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, isFetching: false, error: data };
    case START_POLLING:
      return { ...state, isPolling: true };
    case STOP_POLLING:
      return { ...state, isPolling: false };
    default:
      return state;
  }
}
