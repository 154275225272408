import React from 'react';
import BaseSVG from '../BaseSVG';

function Copy(props) {
  return (
    <BaseSVG
      {...props}
      name="Copy"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M 4 4 L 4 24 L 11 24 L 11 22 L 6 22 L 6 6 L 18 6 L 18 7 L 20 7 L 20 4 Z M 12 8 L 12 28 L 28 28 L 28 8 Z M 14 10 L 26 10 L 26 26 L 14 26 Z"
    />
  );
}

export default Copy;
