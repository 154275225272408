/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

function BankDetailsOptions({
  disabled,
  onChange,
  value,
}) {
  return (
    <FormGroup check inline>
      <Label check>
        <Input
          type="checkbox"
          checked={value.bankName}
          className="standard-checkbox small"
          disabled={disabled}
          onChange={(e) => onChange({ ...value, bankName: e.target.checked })}
        />
        <span>Include Bank Name</span>
      </Label>
    </FormGroup>
  );
}

BankDetailsOptions.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    bankName: PropTypes.bool,
  }),
};

BankDetailsOptions.defaultProps = {
  disabled: false,
  onChange: () => { },
  value: {
    bankName: false,
  },
};

export default BankDetailsOptions;
