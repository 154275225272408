/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const multiPaths = [
  { fill: '#b6e786', d: 'M45.56,31.78l-.25.08a20.19,20.19,0,0,0-9.82,30.72c.16.23.33.46.51.68a119.7,119.7,0,0,0-12.55,15L0,41.83v-.14c.14-.31.29-.62.45-.92l.42-.83a4.83,4.83,0,0,1,.36-.67c.21-.38.52-.9.91-1.52.09-.15.18-.3.28-.44L3,36.42c.13-.2.26-.39.4-.58A36.9,36.9,0,0,1,7.85,30.5c.17-.18.35-.36.54-.53.94-.91,1.89-1.74,2.85-2.5l.21.3a17.55,17.55,0,0,0,28.56,0l.41-.6.07.06C42.26,28.68,44,30.23,45.56,31.78Z' },
  { fill: '#4fe1c2', d: 'M43.29,17.56a17.47,17.47,0,0,1-2.87,9.61l-.41.6a17.55,17.55,0,0,1-28.56,0l-.21-.3a17.56,17.56,0,1,1,32.05-9.91Z' },
  { fill: '#4fe1c2', d: 'M103.28,41.69v.14L79.83,78.3a119.7,119.7,0,0,0-12.55-15c.18-.22.35-.45.51-.68A20.19,20.19,0,0,0,58,31.86l-.25-.08c1.59-1.55,3.3-3.1,5.07-4.55l.06-.05c.14.2.27.4.41.59a17.55,17.55,0,0,0,28.56,0,3.37,3.37,0,0,0,.21-.31h0c1,.76,1.91,1.59,2.85,2.5.19.17.37.35.54.53a36.9,36.9,0,0,1,4.44,5.34c.14.19.27.38.4.58l.59.89c.1.14.19.29.28.44.39.62.7,1.14.91,1.52a4.83,4.83,0,0,1,.36.67l.42.83C103,41.07,103.14,41.38,103.28,41.69Z' },
  { fill: '#1c60a8', d: 'M79.83,78.3l-2.08,3.23L60.9,107.74H42.38L25.53,81.53,23.45,78.3A119.7,119.7,0,0,1,36,63.26a18.9,18.9,0,0,0,2.35,2.55A19.59,19.59,0,0,0,51.62,71h0a19.59,19.59,0,0,0,13.27-5.17,18.9,18.9,0,0,0,2.35-2.55A119.7,119.7,0,0,1,79.83,78.3Z' },
  { fill: '#b6e786', d: 'M95.11,17.56A17.39,17.39,0,0,1,92,27.46a3.37,3.37,0,0,1-.21.31,17.55,17.55,0,0,1-28.56,0c-.14-.19-.27-.39-.41-.59a17.56,17.56,0,1,1,32.26-9.62Z' },
  { fill: '#7c89e3', d: 'M71.5,50.9a20.14,20.14,0,0,1-3.71,11.68c-.16.23-.33.46-.51.68a18.9,18.9,0,0,1-2.35,2.55A19.59,19.59,0,0,1,51.66,71h0a19.59,19.59,0,0,1-13.27-5.17A18.9,18.9,0,0,1,36,63.26c-.18-.22-.35-.45-.51-.68a20.19,20.19,0,0,1,9.82-30.72l.25-.08.08.07a19.68,19.68,0,0,1,5.92-1h.16a19.68,19.68,0,0,1,5.92,1l.08-.07.25.08A20.08,20.08,0,0,1,71.5,50.9Z' },
];

function HashTag(props) {
  const { multi, ...rest } = props;

  return (
    <BaseSVG
      {...rest}
      name="HashTag"
      viewBox="0 0 1000 1000"
      d="M 100 0C 45 0 0 45 0 100C 0 100 0 900 0 900C 0 955 45 1000 100 1000C 100 1000 900 1000 900 1000C 955 1000 1000 955 1000 900C 1000 900 1000 100 1000 100C 1000 45 955 0 900 0C 900 0 100 0 100 0C 100 0 100 0 100 0M 100 75C 100 75 900 75 900 75C 914 75 925 86 925 100C 925 100 925 900 925 900C 925 914 914 925 900 925C 900 925 100 925 100 925C 86 925 75 914 75 900C 75 900 75 100 75 100C 75 86 86 75 100 75C 100 75 100 75 100 75 M 421 412C 421 412 383 588 383 588C 383 588 582 588 582 588C 582 588 619 412 619 412C 619 412 421 412 421 412M 439 112C 450 112 461 117 468 126C 475 135 478 147 476 158C 476 158 437 337 437 337C 437 337 635 337 635 337C 635 337 677 142 677 142C 681 125 696 112 714 112C 725 112 736 117 743 126C 750 135 753 147 751 158C 751 158 712 337 712 337C 712 337 839 337 839 337C 852 337 865 344 872 356C 879 368 879 382 872 394C 865 406 852 413 839 412C 839 412 696 412 696 412C 696 412 658 588 658 588C 658 588 814 588 814 588C 827 587 840 594 847 606C 854 618 854 632 847 644C 840 656 827 663 814 663C 814 663 642 663 642 663C 642 663 601 858 601 858C 598 871 588 882 575 886C 563 890 548 887 538 878C 528 869 524 855 527 842C 527 842 566 663 566 663C 566 663 367 663 367 663C 367 663 326 858 326 858C 323 871 313 882 300 886C 288 890 273 887 263 878C 253 869 249 855 252 842C 252 842 291 663 291 663C 291 663 164 663 164 663C 150 663 138 656 131 644C 124 632 124 618 131 606C 138 594 150 587 164 588C 164 588 307 588 307 588C 307 588 344 412 344 412C 344 412 189 412 189 412C 175 413 163 406 156 394C 149 382 149 368 156 356C 163 344 175 337 189 337C 189 337 360 337 360 337C 360 337 402 142 402 142C 406 125 421 112 439 112C 439 112 439 112 439 112"
      paths={multi ? multiPaths : []}
    />
  );
}

HashTag.propTypes = {
  multi: PropTypes.bool,
};

HashTag.defaultProps = {
  multi: false,
};

export default HashTag;
