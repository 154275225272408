import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';
import config from '../../../../../config/config';
import { getCommonHeadersNoContentType } from '../../../../../api/common-api-utils';

export const MAX_SIZE = 7500000;
export const MAX_WIDTH = 200;
export const MAX_HEIGHT = 100;

export const fileTypes = {
  JPG: 'image',
  JPEG: 'image',
  GIF: 'image',
  PNG: 'image',
  DOC: 'word',
  DOCX: 'word',
  RTF: 'file',
  PDF: 'pdf',
};

export function isImage(str) {
  return /\.(jpe?g|png|gif)$/i.test(str);
}

export function fileExtension(str) {
  if (!str) return '';
  const parts = str.split('.');
  return [...parts].pop();
}

export function truncateFilename(str, len = 10) {
  if (!str) return '';
  const ext = fileExtension(str);
  const filename = str.replace(`.${ext}`, '');
  if (filename.length <= len) return str;
  return `${filename.substring(0, len)}[...].${ext}`;
}

export function stripBase64Prefix(dataStr) {
  if (!dataStr) return '';
  const parts = dataStr.split('base64,');
  return [...parts].pop();
}

export async function deleteFile(fileId, path) {
  try {
    const deleteResponse = await axios.delete(
      `${config.api.middlewareAPIURL}${path}/${fileId}`,
      {
        headers: getCommonHeadersNoContentType(),
      },
    );

    if (/200|201/.test(deleteResponse.status)) return true;

    return false;
  }
  catch (error) {
    return false;
  }
}

export async function uploadFile(file, path, asObj) {
  const data = new FormData();
  data.append('filepond', file);

  try {
    const uploadResponse = await axios.post(
      config.api.middlewareAPIURL + path,
      data,
      { headers: getCommonHeadersNoContentType() },
    );

    if (uploadResponse.status === 201) {
      if (asObj) {
        return {
          id: uploadResponse.data,
          originalFileName: file.name,
          fileType: fileExtension(file.name).toUpperCase(),
        };
      }
      return uploadResponse.data;
    }

    return false;
  }
  catch (error) {
    return false;
  }
}

function loadImage(src, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => {
      if (img.width > maxWidth || img.height > maxHeight) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`The image must be no larger than ${maxWidth}px x ${maxHeight}px`);
      }
      resolve(img);
    });
    img.src = src;
  });
}

async function processFile(file, opts) {
  const { name, size } = file;
  const {
    isBase64 = false,
    maxSize,
    maxHeight,
    maxWidth,
  } = opts;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // eslint-disable-next-line prefer-promise-reject-errors
    if (size > maxSize) reject(`The file: ${name} size must be less than ${(maxSize / 10)} kb`);

    reader.onload = async (e) => {
      const { target: { result } } = e;

      if (maxWidth && maxHeight && isImage(name)) {
        // wait till image loaded before proceeding
        await loadImage(result, maxWidth, maxHeight).catch((err) => reject(err));
      }

      if (isBase64) {
        resolve({
          dataUrl: result,
          name,
          size,
          type: file.type,
        });
      }
      else {
        resolve(file);
      }
    };

    // load file
    reader.readAsDataURL(file);
  })
    .catch((err) => err);
}

export function processFiles(files, opts) {
  return Promise.all([].map.call(files, (file) => processFile(file, opts)));
}

export default function UploadWrapper(props) {
  const {
    schema,
    hasFormData,
    accept,
    onChange,
    isServerError,
    errorMessage,
    children,
    isUploading,
    buttonSize,
  } = props;

  const [errorArray, setErrorArray] = useState([]);
  const uploadFileRef = useRef();

  useEffect(() => {
    if (isServerError) {
      // if server error mesage ot in errors array
      if (!errorArray.includes(errorMessage)) {
        // merge it in
        setErrorArray(errorArray.length ? [...errorArray, errorMessage] : [errorMessage]);
      }
    }
    // if the message is on the array and no server error
    else if (errorArray.includes(errorMessage)) {
      // filter out the error from the array
      setErrorArray(errorArray.filter((err) => err !== errorMessage));
    }
  }, [errorArray, errorMessage, isServerError]);

  const {
    title,
    items,
    maxSize = MAX_SIZE,
    maxHeight = MAX_HEIGHT,
    maxWidth = MAX_WIDTH,
  } = schema;

  const defaultText = hasFormData ? 'Change' : 'Upload';

  function handleChange(e) {
    const { target: { files } } = e;
    // bail if no files
    if (!files.length) return;

    processFiles(files, {
      maxSize,
      maxHeight,
      maxWidth,
    })
      .then((processedFiles) => {
        const errors = [];

        const validFiles = processedFiles.reduce((acc, file) => {
          if (typeof file === 'string') {
            errors.push(file);
          }
          else {
            acc.push(file);
          }

          return acc;
        }, []);

        setErrorArray([...errors]);

        if (validFiles.length && !errors.length) onChange(validFiles);
      });
  }

  return (
    <Fragment>
      {title && (
        <div className="row">
          <div className="col">
            <h5>{title}</h5>
          </div>
        </div>
      )}
      {children && (
        <div className="row">
          <div className="col-12 text-center mt-2">{children}</div>
        </div>
      )}
      <div className="row">
        <div className="col-12 text-center mt-2">
          <input
            type="file"
            name="file"
            style={{ display: 'none' }}
            ref={uploadFileRef}
            onChange={handleChange}
            accept={accept.join(',')}
            multiple={items !== undefined}
          />
          <button
            type="button"
            name="file"
            className={cx(
              'btn',
              'btn-outline',
              { 'btn-sm': buttonSize === 'sm' },
              { 'btn-lg': buttonSize === 'lg' },
            )}
            style={{
              float: 'none',
              marginLeft: 0,
            }}
            disabled={isUploading}
            onClick={() => uploadFileRef.current.click()}
          >
            <span className="fa fa-upload fa-btn" />
            {` ${isUploading ? 'Uploading...' : defaultText}`}
          </button>
          {Boolean(errorArray.length) && (
            <div className="text-danger">
              {errorArray.length === 1 ? <p>{errorArray[0]}</p> : (
                <ul className="list-unstyled text-left">
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {errorArray.map((str, i) => (<li key={`str-${i}`}>{str}</li>))}
                </ul>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

UploadWrapper.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    maxSize: PropTypes.number,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    items: PropTypes.shape(),
  }).isRequired,
  hasFormData: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  isServerError: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isUploading: PropTypes.bool,
  buttonSize: PropTypes.string,
};

UploadWrapper.defaultProps = {
  hasFormData: false,
  onChange: () => { },
  accept: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
    'image/jpeg',
    'image/png',
    'image/gif',
  ],
  isServerError: false,
  errorMessage: 'There was an error processing one or more of your files.',
  children: null,
  isUploading: false,
  buttonSize: null,
};
