import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import UTMDisplay from './UTMDisplay';

export default function ApplicationInfo({ vacancyTitle, accountName, data, isTouch, vacancyAddress }) {
  if (!data) return null;

  const { appliedOnDate, utm } = data;
  return (
    <Fragment>
      <Row>
        {isTouch ? (
          <Col>
            <Row>
              <Col xs="4">
                <p className="detail-title">Job Title</p>
              </Col>
              <Col>
                <p className="detail-value">{vacancyTitle}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <p className="detail-title">Received On</p>
              </Col>
              <Col>
                <p className="detail-value">{moment(appliedOnDate).format('DD-MM-YYYY')}</p>
              </Col>
            </Row>
            {accountName && (
              <Row>
                <Col xs="4">
                  <p className="detail-title">Account</p>
                </Col>
                <Col>
                  <p className="detail-value">{accountName}</p>
                </Col>
              </Row>
            )}
          </Col>
        ) : (
          <Fragment>
            {vacancyTitle && (
              <Col className="d-flex">
                <p className="detail-title me-3">Job Title</p>
                <p className="detail-value">{vacancyTitle}</p>
              </Col>
            )}
            {accountName && (
              <Col className="d-flex">
                <p className="detail-title me-3">Account</p>
                <p className="detail-value">{accountName}</p>
              </Col>
            )}
            {appliedOnDate && (
              <Col className="d-flex">
                <p className="detail-title me-3">Received On</p>
                <p className="detail-value">{moment(appliedOnDate).format('DD-MM-YYYY')}</p>
              </Col>
            )}
          </Fragment>
        )}
      </Row>
      <UTMDisplay isTouch={isTouch} utm={utm} />
      {vacancyAddress && (
        <Row>
          <Col className="d-flex">
            <p className="detail-title me-3">Location</p>
            <p className="detail-value">{vacancyAddress}</p>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

ApplicationInfo.propTypes = {
  vacancyTitle: PropTypes.string,
  accountName: PropTypes.string,
  vacancyAddress: PropTypes.string,
  data: PropTypes.shape({
    appliedOnDate: PropTypes.string,
    utm: PropTypes.shape(),
  }),
  isTouch: PropTypes.bool,
};

ApplicationInfo.defaultProps = {
  vacancyTitle: null,
  accountName: null,
  vacancyAddress: null,
  data: null,
  isTouch: false,
};
