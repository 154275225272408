import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';
import { waitNodes } from '../NodeTypesLists';
import { useHover } from '../../HoverContext';
import { CirclePause } from 'lucide-react';

const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const PauseContainer = styled.div`
  position: absolute;
  right: 3px;
  top: -5px;
`;

const Icon = styled.div`
  background-color: purple;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }

  .error {
    color: red;
  }
`;

export default memo(({ id, data }) => {
  const { hoveredId } = useHover();

  const nodeType = waitNodes.find((node) => node.actionType === data?.actionType);
  const IconComp = nodeType?.icon;
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <div onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} />
        <Handle type="target" position={Position.Top} />
        <Container isHighlighted={id === hoveredId}>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">Wait</div>
            {data.number ? (
              <div className="status">{data.condition}</div>
            ) : (
              <div className="error">Edit to select a time</div>
            )}
            <div className="status">
              {data.number} {data.range}
            </div>
          </Text>
          {data.pauseWindow && (
            <PauseContainer>
              <CirclePause size={8} />
            </PauseContainer>
          )}
        </Container>
        <Handle type="source" position={Position.Bottom} />
      </div>
    </SliderContext.Provider>
  );
});
