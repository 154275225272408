import React, { useEffect, useState } from 'react';
import { Spinner, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { IconButton } from '../../Base/Buttons';
import AccessCodeErrorView from './AccessCodeErrorView';

function AccessCodeEntryView({
  onAccessCodeComplete,
  langPack,
  isLoading,
  error,
  title,
  btnLabel,
  numInputs,
}) {
  function getDefaultAccessCode() {
    const defaultCode = {};

    for (let i = 1; i <= numInputs; i += 1) {
      defaultCode[i] = '';
    }

    return defaultCode;
  }

  const [accessCode, setAccessCode] = useState(getDefaultAccessCode);

  useEffect(() => {
    window.addEventListener('paste', (e) => {
      // cancel paste
      e.preventDefault();
      // get text representation of clipboard
      const text = (e.originalEvent || e).clipboardData.getData('text/plain').trim();

      if (!Number.isNaN(text) && text.length === numInputs) {
        const accessCodePasteObj = {};
        text.split('').forEach((char, i) => {
          accessCodePasteObj[`${i + 1}`] = char;
        });
        setAccessCode(accessCodePasteObj);
      }
    });
  }, []);

  useEffect(() => {
    if (error) {
      setAccessCode(getDefaultAccessCode);
      document.getElementById('1').focus();
      document.getElementById('1').select();
    }
  }, [error]);

  function isAccessCodeComplete(accCode) {
    let complete = true;
    Object.values(accCode).forEach((value) => {
      if (!value || value.length === 0) {
        complete = false;
      }
    });

    return complete;
  }
  useEffect(() => {
    if (isAccessCodeComplete(accessCode)) {
      onAccessCodeComplete(Object.values(accessCode).map((value) => value).join(''));
    }
  }, [accessCode]);

  function monitorForDelete(e) {
    if ((e.keyCode === 46 || e.keyCode === 8)) {
      const code = { ...accessCode };

      let inputToDel;

      if ((parseInt(e.currentTarget.id, 10) === numInputs && accessCode[`${e.currentTarget.id}`] !== '')
        || (parseInt(e.currentTarget.id, 10) !== numInputs && accessCode[`${e.currentTarget.id}`])
        || (parseInt(e.currentTarget.id, 10) === 1)) {
        inputToDel = parseInt(e.currentTarget.id, 10).toString(10);
      } else {
        inputToDel = (parseInt(e.currentTarget.id, 10) - 1).toString(10);
      }

      code[`${inputToDel}`] = '';
      setAccessCode(code);
      document.getElementById(`${inputToDel}`).focus();
      document.getElementById(`${inputToDel}`).select();
    }
  }
  function codeChanged(e) {
    if (e.keyCode === 17 || e.keyCode === 86) {
      return;
    }

    const { value } = e.currentTarget;
    const idNum = parseInt(e.currentTarget.id, 10);

    if (!Number.isNaN(value)) {
      const newEntry = {};
      newEntry[`${e.currentTarget.id}`] = value;
      setAccessCode({ ...accessCode, ...newEntry });
      if (idNum < numInputs) {
        document.getElementById(`${idNum + 1}`).focus();
        document.getElementById(`${idNum + 1}`).select();
      }
    } else {
      const wipeEntry = {};
      wipeEntry[`${e.currentTarget.id}`] = undefined;
      setAccessCode({ ...accessCode, ...wipeEntry });
    }
  }

  async function actionSubmit() {
    onAccessCodeComplete(Object.values(accessCode).map((value) => value).join(''));
  }

  function getInputs() {
    const inputs = [];

    for (let i = 1; i <= numInputs; i += 1) {
      inputs.push(
        <input
          maxLength={1}
          type="text"
          className="form-control single-centered-input"
          onChange={codeChanged}
          autoComplete="off"
          onKeyDown={monitorForDelete}
          id={`${i}`}
          disabled={isLoading}
          value={accessCode[i.toString()]}
        />,
      );
    }

    return inputs;
  }
  return (
    <div className="access-code-wrapper">
      <div className="access-code-inner">
        { title && <h3 className="text-center">{`${title}`}</h3> }
        <FormGroup row className="access-code">
          {getInputs()}
        </FormGroup>
        {error && <AccessCodeErrorView errorCode={error} langPack={langPack} />}
        <div className="d-flex justify-content-center">
          {!isLoading ? (
            <IconButton
              label={`${btnLabel}`}
              floatRight={false}
              disabled={!isAccessCodeComplete(accessCode)}
              iconClassName="fa fa-hand-o-up"
              onClick={() => actionSubmit()}
            />
          ) : <Spinner />}
        </div>
      </div>
    </div>
  );
}

AccessCodeEntryView.propTypes = {
  onAccessCodeComplete: PropTypes.func,
  langPack: PropTypes.shape(),
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  numInputs: PropTypes.number,
};

AccessCodeEntryView.defaultProps = {
  onAccessCodeComplete: () => {
  },
  langPack: {},
  title: undefined,
  btnLabel: undefined,
  error: undefined,
  isLoading: false,
  numInputs: 5,
};

export default AccessCodeEntryView;
