import axios from 'axios';
import { getCommonHeaders } from './common-api-utils';
import config from '../config/config';

const { api, templates } = config;

// eslint-disable-next-line import/prefer-default-export
export async function createTemplate(formData = {}, templateType = 'email') {
  if (!Object.keys(formData).length) return null;

  const result = await axios.post(
    `${api.middlewareAPIURL}${templates[templateType]}`,
    { ...formData },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getTemplatesByType(type, templateType = 'email') {
  if (!type) return null;

  const result = await axios.get(
    `${api.middlewareAPIURL}${templates[templateType]}/type/${type}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getTemplateById(id, templateType = 'email') {
  if (!id) return null;

  const result = await axios.get(
    `${api.middlewareAPIURL}${templates[templateType]}/${id}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
