import {
  ADD_POLARIS_USER,
  REMOVE_POLARIS_USER,
  ADD_POLARIS_KEY_MAP,
  ADD_POLARIS_GLOBAL_CONFIG,
} from '../constants/actionTypes';

export function addPolarisUser(domainUrl, accountIdToSiteId) {
  return {
    type: ADD_POLARIS_USER,
    payload: { domainUrl, accountIdToSiteId },
  };
}

export function removePolarisUser() {
  return { type: REMOVE_POLARIS_USER };
}

export function addKeyMap(accountIdToSiteId) {
  return {
    type: ADD_POLARIS_KEY_MAP,
    payload: accountIdToSiteId,
  };
}

export function addGlobalConfig(config, isComplete) {
  return {
    type: ADD_POLARIS_GLOBAL_CONFIG,
    payload: { config, isComplete },
  };
}
