import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Label, Button } from 'reactstrap';
import Tippy from '@tippyjs/react';
import { selectedValueProp } from './FilterOption';
import { uniqBy } from '../../../../js/utils/arrayOfObjects';

function dedupe(arr) {
  return [...new Set(arr)];
}

function FilterCheckboxes({ optionData, selectedValue, onSelect }) {
  const [selectedValues, setSelectedValues] = useState([]);

  const dedupedValues = dedupe(optionData.map(({ value }) => value));
  const allSelected = selectedValues.length === dedupedValues.length;

  useEffect(() => {
    if (selectedValue) setSelectedValues(selectedValue);
  }, [optionData, selectedValue]);

  function handleChange(value, isChecked) {
    let clone = [...selectedValues];

    if (!isChecked) {
      clone = clone.filter((id) => value !== id);
    } else {
      clone.push(value);
    }

    const deduped = dedupe(clone);
    setSelectedValues(deduped);
    onSelect(deduped);
  }

  function toggleAll(selectAll) {
    let valueArr = [];
    if (selectAll) valueArr = dedupedValues;
    setSelectedValues(valueArr);
    onSelect(valueArr);
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="p-0 font-weight-bold align-self-end"
          color="link"
          onClick={() => toggleAll(!allSelected)}
          size="md"
        >
          {`${allSelected ? 'Unselect' : 'Select'} All`}
        </Button>
      </div>
      <div className="filter-checkboxes-wrapper">
        <ul className="list-unstyled">
          {uniqBy(optionData, 'value').map(({ value, label }, i) => (
            <li key={value} className="my-2">
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <Label check className="filter-checkbox-inline" for={`fcb-${i}`}>
                <input
                  checked={selectedValues.includes(value)}
                  className="standard-checkbox small"
                  id={`fcb-${i}`}
                  onChange={(e) => handleChange(value, e.target.checked)}
                  style={{ minWidth: '20px' }}
                  type="checkbox"
                  value={value}
                />
                <Tippy content={label} delay={500}>
                  <span className="text-truncate">{label}</span>
                </Tippy>
              </Label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

FilterCheckboxes.propTypes = {
  optionData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  selectedValue: selectedValueProp,
  onSelect: PropTypes.func,
};

FilterCheckboxes.defaultProps = {
  optionData: [],
  selectedValue: null,
  onSelect: () => {},
};

export default FilterCheckboxes;
