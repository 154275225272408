import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

// eslint-disable-next-line import/prefer-default-export
export async function tenantAdminSearchUsers(searchQuery) {
  const result = await axios.post(
    `${config.api.middlewareAPIURL}${config.account.tenantAdminUserBasePath}${config.account.tenantAdminUserSearchPath}`,
    searchQuery,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export async function tenantAdminUpdateUser(data, userId) {
  const { firstName, surname, emailAddress } = data;

  const result = await axios.put(
    `${config.api.middlewareAPIURL}${config.account.tenantAdminUserBasePath}`,
    { firstName, surname, email: emailAddress, userId },
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export async function registerTenant(data) {
  const result = await axios.post(`${config.api.middlewareAPIURL}${config.account.registerPath}`, data, {
    headers: { tenant: '1234' },
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}
