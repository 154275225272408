import {
  SET_TABLE_PAGE_SIZE,
  SET_MODAL_TABLE_PAGE_SIZE,
} from '../constants/actionTypes';

export function setTablePageSize(pageSize) {
  return {
    type: SET_TABLE_PAGE_SIZE,
    payload: pageSize,
  };
}

export function setModalTablePageSize(pageSize) {
  return {
    type: SET_MODAL_TABLE_PAGE_SIZE,
    payload: pageSize,
  };
}
