import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

function SliderCard({
  className,
  children,
  noRenderCard,
}) {
  if (noRenderCard) return children;

  return (
    <Card className={className}>
      <CardBody>
        {children}
      </CardBody>
    </Card>
  );
}

SliderCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  noRenderCard: PropTypes.bool,
};

SliderCard.defaultProps = {
  className: null,
  children: null,
  noRenderCard: false,
};

export default SliderCard;
