import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useFormKeypress, batchUpdateCssVars } from '../../../Base/hooks';
import ColorInput from './ColorInput';
import { validateColors } from '../../../Base/ColorPicker/ColorPicker';
import ImageInput from './ImageInput';
import { CreateButton, IconButton } from '../../../Base/Buttons';
import { saveStyleSettings } from '../../../../api/AccountAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { setStyles } from '../../../../js/actions/styleActions';
import { setVarsObject, gradientCreator } from '../../../../js/utils/style-utils';
import ErrorFeedback from '../../../Base/ErrorFeedback/ErrorFeedback';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import TalentHubBranding from './TalentHubBranding';
import EnhancedCard from '../Common/EnhancedCard';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled(Label)`
  font-weight: 400;
`;

const StyledCol = styled(Col)`
  margin-bottom: 2rem;
`;

const MainContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

function BrandingForm({ styles, applyStyles }) {
  const formRef = useFormKeypress();
  const cachedFormData = useRef({});
  const [formData, setFormData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { gradientColour2, gradientColour4, ...rest } = styles;
    if (!Object.keys(cachedFormData.current).length) cachedFormData.current = { ...rest };
    setFormData({ ...rest });
  }, [styles]);

  function handleColorChange(id, color) {
    const colorStr = color.startsWith('#') ? color : `#${color}`;
    const updatedData = { ...formData, [id]: colorStr };
    setErrors({ ...errors, ...validateColors(id, colorStr) });
    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    const { logo, logoUrl, ...colors } = formData;
    const colorErrObj = Object.entries(colors).reduce(
      (acc, [inpId, color]) => ({ ...acc, ...validateColors(inpId, color) }),
      {},
    );
    setErrors(colorErrObj);

    const hasErrors = Object.values(colorErrObj).some((errMsg) => !!errMsg.length);

    if (!hasErrors) {
      const resp = await retryableAPICall(() =>
        saveStyleSettings({
          ...colors,
          ...(logo ? { logo } : {}),
        }),
      );

      if (typeof resp === 'string') {
        toast.error('Error saving styles');
      } else {
        toast.success('Brand styles saved successfully');
        applyStyles({
          ...colors,
          ...(logo ? { logoUrl: `data:image/jpeg;base64,${logo.data}` } : {}),
        });
        batchUpdateCssVars(setVarsObject({ ...gradientCreator(...Object.values(colors)) }));
      }
    }

    setIsSaving(false);
  }

  const isDiff = JSON.stringify(formData) !== JSON.stringify(cachedFormData.current);

  if (!checkPermissions(['admin:branding'])) return null;

  return (
    <MainContentWrapper>
      <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
        <EnhancedCard className="mb-4">
          <EnhancedCardTitle
            title="ATS Branding"
            subtitle="Style the Talent Funnel ATS to match your brand"
          />
          <Form innerRef={formRef}>
            <StyledFormGroup row>
              <StyledLabel sm="2" for="primaryColour">
                Primary Color:
              </StyledLabel>
              <Col sm="3">
                <ColorInput
                  id="primaryColour"
                  value={formData.primaryColour}
                  onChange={(val) => handleColorChange('primaryColour', val)}
                />
                <ErrorFeedback message={errors.primaryColour} />
              </Col>
            </StyledFormGroup>
            <StyledFormGroup row>
              <StyledLabel sm="2" for="secondaryColour">
                Secondary Color:
              </StyledLabel>
              <Col sm="3">
                <ColorInput
                  id="secondaryColour"
                  value={formData.secondaryColour}
                  onChange={(val) => handleColorChange('secondaryColour', val)}
                />
                <ErrorFeedback message={errors.secondaryColour} />
              </Col>
            </StyledFormGroup>
            <StyledFormGroup row>
              <StyledLabel sm="2" for="logo">
                Logo:
              </StyledLabel>
              <Col sm="4">
                <ImageInput
                  value={formData.logo || formData.logoUrl}
                  onChange={(val) => setFormData({ ...formData, logo: val })}
                />
              </Col>
            </StyledFormGroup>
            <div className="mt-4">
              <CreateButton
                label={isSaving ? 'Saving...' : 'Save'}
                isLoading={isSaving}
                disabled={isSaving}
                floatRight={false}
                action={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
              />
              {isDiff && (
                <IconButton
                  className="ms-2"
                  iconClassName="btn-undo"
                  label="Undo"
                  disabled={isSaving}
                  floatRight={false}
                  action={(e) => {
                    e.preventDefault();
                    setFormData({ ...cachedFormData.current });
                  }}
                />
              )}
            </div>
          </Form>
        </EnhancedCard>
        <TalentHubBranding />
      </div>
    </MainContentWrapper>
  );
}


BrandingForm.propTypes = {
  styles: PropTypes.shape({
    primaryColour: PropTypes.string,
    secondaryColour: PropTypes.string,
    gradientColour2: PropTypes.string,
    gradientColour4: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
  applyStyles: PropTypes.func,
};

BrandingForm.defaultProps = {
  styles: {
    primaryColour: '#7c89e3',
    secondaryColour: '#7c89e3',
  },
  applyStyles: () => {},
};

function mapStateToProps(state) {
  const { styles } = state;
  return { styles };
}

function mapDispatchToProps(dispatch) {
  return {
    applyStyles: (styleObj) => dispatch(setStyles(styleObj)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandingForm);
