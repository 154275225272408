import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';

function HeadSearch({ onToggle, isTouch }) {
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  function handleSearch() {
    const queryStr = keywords.trim().length ? `?s=${keywords}` : '';
    navigate(`/candidate${queryStr}`);
    onToggle();
  }

  return (
    <div className={`header-search-wrapper ${isExpanded ? 'expanded' : ''}`}>
      <Input
        type="search"
        className="header-search-input form-control"
        placeholder={isTouch ? 'Candidate Name/Email' : 'Search Candidates'}
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') handleSearch();
        }}
        style={{ display: isExpanded ? 'block' : 'none' }}
      />
      <button
        type="button"
        className="header-search-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="fa fa-search" />
      </button>
    </div>
  );
}

HeadSearch.propTypes = {
  isTouch: PropTypes.bool,
  onToggle: PropTypes.func,
};

HeadSearch.defaultProps = {
  isTouch: false,
  onToggle: () => {},
};

export default HeadSearch;
