import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import dotty from 'dotty';
import { retryableAPICall } from '../../../api/common-api-utils';
import Slider from './Slider';
import { useMounted } from '../hooks';
import { CandidatePagination } from '../../Applications/Candidates/CandidateDetails';

function getFullName(obj = {}) {
  if (!Object.keys(obj).length) return '';
  const { applicantProfile = {} } = obj;
  if (!Object.keys(applicantProfile).length) return '';

  const { personalDetails = {} } = applicantProfile;
  const { familyName, givenName } = personalDetails;
  return `${givenName} ${familyName}`.trim();
}

function CandidateSlider({
  applicantId,
  isOpen,
  onClose,
  apiMethod,
  onLoad,
  pagination,
  // title,
  children,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [loadingErrorStatus, setLoadingErrorStatus] = useState();
  const [applicantData, setApplicantData] = useState({});
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const isMounted = useMounted();

  useEffect(() => {
    setErrorMsg();
    setSuccessMsg();
    setApplicantData({});

    const fetchData = async () => {
      setIsLoading(true);
      // get details
      const response = await retryableAPICall(() => apiMethod(applicantId));

      if (isMounted()) {
        if (typeof response === 'string') {
          setIsLoadingError(true);
          setLoadingErrorStatus(response);
        } else {
          setIsLoadingError(false);
          setApplicantData(response);
          onLoad(response);
        }
        // update state
        setIsLoading(false);
      }
    };
    if (applicantId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiMethod, applicantId, isMounted]);

  function setMessages(msgs = {}) {
    const { err, success } = msgs;
    setErrorMsg(err);
    setSuccessMsg(success);
  }

  function onError(err) {
    setMessages({ err });
  }

  function onSuccess(success) {
    setMessages({ success });
  }

  const fullName = getFullName(applicantData);

  return (
    <Slider
      isLoading={isLoading}
      TitleButton={pagination.showPagination ? <CandidatePagination {...pagination} /> : null}
      // title={fullName || title}
      isOpen={isOpen}
      toggleOpen={() => {
        onClose(true);
        setMessages();
      }}
      className="candidate-slider"
      errorMsg={errorMsg}
      successMsg={successMsg}
      resetError={setErrorMsg}
      resetSuccess={setSuccessMsg}
      isLoadingError={isLoadingError}
      loadingErrorStatus={loadingErrorStatus}
      focusForm={false}
      width="100%"
      pauseNotificationsOnOpen
      noWrapChildren
      closeButtonLabel="Close Candidate"
    >
      {children({
        isLoading,
        fullName,
        applicantData,
        onSuccess,
        onError,
        onApplicantUpdate: (appObj) => setApplicantData(appObj),
      })}
    </Slider>
  );
}

CandidateSlider.propTypes = {
  applicantId: PropTypes.string,
  apiMethod: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  pagination: PropTypes.shape(),
  // title: PropTypes.string,
  children: PropTypes.func.isRequired,
};

CandidateSlider.defaultProps = {
  applicantId: null,
  onLoad: () => {},
  onClose: () => {},
  pagination: {},
  // title: null,
  isOpen: false,
};

export default CandidateSlider;
