import React from 'react';
import BaseSVG from '../BaseSVG';

function Chevron(props) {
  return (
    <BaseSVG
      {...props}
      name="Chevron"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M8,12.7601043 C8,12.3081269 8.16837674,11.8735332 8.48829255,11.5258583 C9.14496185,10.8478922 10.222573,10.8478922 10.8792423,11.5258583 L15.9978953,16.7931334 L21.1165483,11.5084746 C21.7732176,10.8305085 22.8508287,10.8305085 23.507498,11.5084746 C24.1641673,12.1864407 24.1641673,13.2990004 23.507498,13.9769665 L17.1933702,20.4958714 C16.8734544,20.8261625 16.4525125,21 15.9978953,21 C15.5432781,21 15.1223362,20.8087788 14.8024204,20.4958714 L8.48829255,13.9943503 C8.16837674,13.6466754 8,13.194698 8,12.7601043 Z"
    />
  );
}

export default Chevron;
