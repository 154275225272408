import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { IconSVG } from '../../../Base/SVG';
import { checkTranscriptionStatus, requestVideoTranscription } from '../../../../api/CandidateAPI/CandidateAPI';
import { addTranscriptionRequest } from '../../../../js/actions/transcriptionRequestActions';

function VideoTranscriptionLink({ candidateId, fileId, transcription, addTranscriptionReq, transcriptionRequests }) {
  const [transcriptionLoading, setTranscriptionLoading] = useState(false);
  const [transcriptionError, setTranscriptionError] = useState(false);
  const [transcriptionJobId, setTranscriptionJobId] = useState(null);
  const [transcript, setTranscript] = useState(transcription);

  async function checkForTranscriptionStatus() {
    addTranscriptionReq({ fileId, status: 'RECEIVED', transcription: undefined });

    const result = await checkTranscriptionStatus(candidateId, fileId, transcriptionJobId);

    if (typeof result !== 'string' && result.status === 'SUCCESS') {
      setTranscriptionJobId(null);
      setTranscript(result.transcription);
      setTranscriptionLoading(false);
      addTranscriptionReq({ fileId, status: 'SUCCESS', transcription: result.transcription });
    } else if (typeof result !== 'string' && result.status === 'FAILED') {
      toast.error('Transcribing failed');
      setTranscriptionLoading(false);
      setTranscriptionError(true);
      addTranscriptionReq({ fileId, status: 'FAILED', transcription: undefined });
    } else if (typeof result !== 'string' && result.status === 'RECEIVED') {
      doStatusChecking();
    } else {
      // Error
      setTranscriptionError(true);
      addTranscriptionReq({ fileId, status: 'FAILED', transcription: undefined });
      setTranscriptionLoading(false);
    }
  }

  function doStatusChecking() {
    setTimeout(() => {
      if (!transcript || transcript.length === 0) {
        checkForTranscriptionStatus();
      }
    }, 1000);
  }

  useEffect(() => {
    if (transcriptionJobId) {
      doStatusChecking();
    } else {
      setTranscriptionLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptionJobId]);

  // Kill any polling
  useEffect(() => () => window.clearTimeout(), []);

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (transcriptionRequests.requests.hasOwnProperty(fileId) && (!transcription || transcription.length === 0)) {
      const req = transcriptionRequests.requests[fileId];
      if (req.status === 'RECEIVED' && !transcriptionLoading) {
        setTranscriptionLoading(true);
        doStatusChecking();
      } else if (req.status === 'FAILED') {
        setTranscriptionError(true);
      } else if (req.status === 'SUCCESS') {
        setTranscript(req.transcription);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptionRequests]);

  if (!fileId) return null;

  return (
    <Fragment>
      {!transcript && (
        <a
          href="#transcribe-video"
          className="d-inline-flex align-items-center ms-3"
          onClick={async (e) => {
            if (transcriptionLoading) return false;
            e.preventDefault();
            setTranscriptionError(false);
            setTranscriptionLoading(true);
            const result = await retryableAPICall(() => requestVideoTranscription(candidateId, fileId));
            if (typeof result !== 'string') {
              setTranscriptionJobId(result.jobId);
            } else {
              toast.error('Error attempting to transcribe video');
            }
          }}
        >
          <IconSVG name="Pencil" styles={{ marginRight: '0.5em' }} />
          {transcriptionLoading && <span>Transcribing...</span>}
          {!transcriptionLoading && !transcriptionError && <span>Transcribe Video</span>}
          {!transcriptionLoading && transcriptionError && <span>Transcription Failed (Retry?)</span>}
        </a>
      )}
      {transcript && (
        <span className="d-block">
          <strong>Video Transcription: </strong>
          {transcript}
        </span>
      )}
    </Fragment>
  );
}

VideoTranscriptionLink.propTypes = {
  fileId: PropTypes.string,
  candidateId: PropTypes.string,
  transcription: PropTypes.string,
  addTranscriptionReq: PropTypes.func,
  transcriptionRequests: PropTypes.shape(),
};

VideoTranscriptionLink.defaultProps = {
  fileId: null,
  candidateId: null,
  transcription: null,
  addTranscriptionReq: () => {},
  transcriptionRequests: {},
};

function mapStateToProps(state) {
  const { transcriptionRequests } = state;
  return { transcriptionRequests };
}

function mapDispatchToProps(dispatch) {
  return {
    addTranscriptionReq: (transcriptionRequestObj) => dispatch(addTranscriptionRequest(transcriptionRequestObj)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTranscriptionLink);
