import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'reactstrap';
import { ucfirst } from '../../../../js/utils/general-utils';

const skillsPropType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
}));

const subCompetencyPropType = PropTypes.arrayOf(PropTypes.shape({
  competencyName: PropTypes.string,
  skills: skillsPropType,
}));

function CollapsibleItem({ children, list }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="collapsible-list-item">
      {list.length ? (
        <Fragment>
          <Button
            className={`plaintext${isOpen ? ' is-open' : ''}`}
            color="link"
            onClick={() => setIsOpen(!isOpen)}
            title={`${isOpen ? 'Hide' : 'Display'} skills`}
          >
            {children}
          </Button>
          <Collapse isOpen={isOpen}>
            <ul className="mb-2 pl-4">
              {list.map(({ name, id }) => <li key={`${id}`}>{name}</li>)}
            </ul>
          </Collapse>
        </Fragment>
      ) : children}
    </li>
  );
}

CollapsibleItem.propTypes = {
  children: PropTypes.string.isRequired,
  list: skillsPropType,
};

CollapsibleItem.defaultProps = {
  list: [],
};

function SubCompetencies({ data }) {
  if (!data || !data.length) return null;

  return (
    <ul className="pl-4">
      {data.map(({ competencyName, skills }) => {
        const skillsList = skills.map(({ name, id }) => ({ name: ucfirst(name), id }));
        return <CollapsibleItem key={competencyName} list={skillsList}>{competencyName}</CollapsibleItem>;
      })}
    </ul>
  );
}

SubCompetencies.propTypes = {
  data: subCompetencyPropType,
};

SubCompetencies.defaultProps = {
  data: [],
};

export default function Competencies({ data, showTitle, title }) {
  if (!data || !data.length) return null;

  return (
    <div className="row large-content competencies">
      <div className="col">
        {showTitle && <p className="detail-title">{title}</p>}
        <div className="detail-value">
          {data.map(({ competencyName, subCompetency }) => (
            <div key={competencyName} className="mb-3">
              <h5>{competencyName}</h5>
              <SubCompetencies data={subCompetency} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Competencies.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    competencyName: PropTypes.string,
    subCompetency: subCompetencyPropType,
  })),
  showTitle: PropTypes.bool,
  title: PropTypes.string,
};

Competencies.defaultProps = {
  data: [],
  showTitle: true,
  title: 'Competencies',
};
