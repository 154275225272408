import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { RightToWork, InviteToInterview, ProcessNewCandidate } from './DragAndDrop/Templates';
import { File, ArrowLeft } from 'lucide-react';

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #5285ef;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
    border-color: #d1d1d1;
  }
`;

const BackIcon = styled(ArrowLeft)`
  margin-right: 0.25rem;
`;
const CardBox = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  height: 10rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  gap: 1rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  span {
    font-size: 14px;
    font-weight: 600;
  }
`;

const StyledBackButtonContainer = styled.div`
  padding: 0.5rem 1rem;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
`;

const Icon = styled.div`
  svg {
    margin: auto;
    width: 42px !important;
    height: 42px !important;
  }
`;

function WorkflowCreatePanel({ goBackToEdit, onCreateWorkflow }) {
  return (
    <div className="d-flex flex-column" style={{ flex: '1 0', paddingLeft: 0, paddingRight: 0 }}>
      <StyledBackButtonContainer className="d-flex pb-3 justify-content-between w-100">
        <BackButton onClick={() => goBackToEdit()}>
          <BackIcon size={16} />
          Back
        </BackButton>
      </StyledBackButtonContainer>
      <div className="p-4">
        <div>
          <h2>Choose a Template</h2>
        </div>
        <div className="pt-3 d-flex flex-column">
          <h5>Candidates</h5>
          <div className="pt-3 d-flex gap-3">
            <CardBox
              onClick={() => {
                onCreateWorkflow('BLANK_WORKFLOW');
              }}
            >
              <Icon>
                <File />
              </Icon>
              <span>Blank</span>
            </CardBox>
            <CardBox
              onClick={() => {
                onCreateWorkflow('NEW_CANDIDATE_EMAIL');
              }}
            >
              <Icon>
                <File />
              </Icon>
              <span>New Candidate Email</span>
            </CardBox>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkflowCreatePanel.propTypes = {
  onCreateWorkflow: PropTypes.func,
  goBackToEdit: PropTypes.func,
};

WorkflowCreatePanel.defaultProps = {
  onCreateWorkflow: () => {},
  goBackToEdit: () => {},
};

export default WorkflowCreatePanel;
