import { useEffect } from 'react';

function updateHistory(data = {}, uri = '#hash') {
  global.history.pushState({ ...data }, null, uri);
}

export default function usePopstate(onPopstate = () => {}) {
  useEffect(() => {
    function handlePopstate(e) {
      onPopstate(e?.state);
      e?.stopPropagation();
      e?.preventDefault();
      return false;
    }

    global.addEventListener('popstate', () => setTimeout(handlePopstate, 0));

    return () => {
      global.removeEventListener('popstate', handlePopstate);
    };
  }, [onPopstate]);

  return updateHistory;
}
