import React from 'react';
import PropTypes from 'prop-types';

export default function Checkbox({
  isChecked,
  onChange,
  isRadio,
  name,
}) {
  return (
    <input
      type={isRadio ? 'radio' : 'checkbox'}
      name={name}
      className="standard-checkbox small"
      checked={isChecked}
      onChange={(e) => onChange(e.target.checked)}
    />
  );
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  isRadio: PropTypes.bool,
  name: PropTypes.string,
};

Checkbox.defaultProps = {
  isChecked: false,
  onChange: () => { },
  isRadio: false,
  name: '',
};
