export default {
  '&multiLine': {
    control: {
      color: '#5A5A5A',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: '14px',
      minHeight: 150,
    },
    highlighter: {
      border: '1px solid transparent',
      color: '#5A5A5A',
      padding: 9,
    },
    input: {
      border: '1px solid silver',
      color: '#5A5A5A',
      padding: 9,
    },
  },

  '&singleLine': {
    display: 'inline-block',
    highlighter: {
      border: '2px inset transparent',
      padding: 1,
    },

    input: {
      border: '2px inset',
      padding: 1,
    },
    width: 180,
  },

  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  suggestions: {
    item: {
      '&focused': {
        backgroundColor: '#cee4e5',
      },
      padding: '10px 15px',
    },
    list: {
      border: '1px solid rgba(0,0,0,0.15)',
      backgroundColor: 'white',
      fontSize: 14,
    },
  },
};
