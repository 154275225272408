export function compileFormsPayload(formSchemas, formType = 'APPLICATION_FORM', themeId) {
  if (!formSchemas || !Object.keys(formSchemas).length) return [];

  const { applicationForm, attachedForm } = formSchemas;

  const { formName, schema, uiSchema, rules } = applicationForm;

  const flexiForms = [
    {
      order: 0,
      flexiForm: {
        id: applicationForm?.id || attachedForm?.id || 'APPLICATION_FORM',
        tenant: applicationForm?.tenant || attachedForm?.tenant || 'APPLICATION_FORM',
        accountId: applicationForm?.accountId || attachedForm?.accountId || 'APPLICATION_FORM',
        type: formType,
        name: formName,
        formSchema: schema,
        uiSchema,
        rules,
        themeId,
      },
    },
  ];

  if (attachedForm) {
    flexiForms.push({
      order: 1,
      flexiForm: { ...attachedForm },
    });
  }

  return flexiForms;
}

export function uncompileFlexiFormArray(flexiForms = []) {
  return flexiForms.reduce((acc, { flexiForm }) => {
    if (flexiForm.id === 'APPLICATION_FORM') {
      const { formSchema, name, ...rest } = flexiForm;

      return {
        ...acc,
        applicationForm: {
          ...rest,
          schema: formSchema,
          formName: name,
        },
      };
    }

    return { ...acc, attachedForm: { ...flexiForm } };
  }, {});
}

export default function attachFlexiForm(appFormLink, dataObj = {}) {
  if (!appFormLink) return { ...dataObj };

  const formType = 'APPLICATION_FORM';

  const { applicationForm = {} } = appFormLink;
  const { appId } = applicationForm;

  const flexiForms = compileFormsPayload(appFormLink, formType, applicationForm.themeId);

  return {
    ...dataObj,
    ...(!appId
      ? {
          applicationMethod_appId: undefined,
          applicationMethod_applicationForm: {
            type: formType,
            title: '',
            uniqueSubmission: true,
            flexiForms,
            themeId: applicationForm.themeId,
          },
        }
      : {}),
  };
}
