import React from 'react';
import BaseSVG from '../BaseSVG';

function CloudUpload(props) {
  return (
    <BaseSVG
      {...props}
      name="Cloud Upload"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M16,16 L12.75,19.25 L12,18.5 L16.5,14 L21,18.5 L20.25,19.25 L17,16 L17,27 L16,27 L16,16 L16,16 Z M15,21 L8.00281647,21 C5.79793835,21 4,19.209139 4,17 C4,15.1046097 5.32460991,13.5117359 7.10100919,13.1021544 L7.10100919,13.1021544 C7.03467626,12.7448817 7,12.3764904 7,12 C7,8.68629134 9.68629134,6 13,6 C15.6154416,6 17.8400262,7.67345685 18.6614243,10.0080411 C19.435776,9.37781236 20.4237666,9 21.5,9 C23.8583427,9 25.7929639,10.814166 25.9844379,13.1230721 L25.9844379,13.1230721 C27.7144917,13.5630972 29,15.1320162 29,17 C29,19.2046438 27.207878,21 24.9971835,21 L18,21 L18,22 L25.0005601,22 C27.7616745,22 30,19.7558048 30,17 C30,14.9035809 28.7132907,13.1085075 26.8828633,12.3655101 L26.8828633,12.3655101 C26.3600217,9.87224935 24.1486546,8 21.5,8 C20.6371017,8 19.8206159,8.19871575 19.0938083,8.55288165 C17.8911816,6.43144875 15.6127573,5 13,5 C9.13400656,5 6,8.13400656 6,12 C6,12.1381509 6.00400207,12.275367 6.01189661,12.4115388 L6.01189661,12.4115388 C4.23965876,13.1816085 3,14.9491311 3,17 C3,19.7614237 5.23249418,22 7.99943992,22 L15,22 L15,21 L15,21 L15,21 Z"
    />
  );
}

export default CloudUpload;
