import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { SliderContext } from './SliderContext';
import styled from 'styled-components';
import { decisionNodes } from '../NodeTypesLists';
import ActionButtons from './ActionButtons';
import { CirclePause } from 'lucide-react';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const PauseContainer = styled.div`
  position: absolute;
  right: 3px;
  top: -5px;
`;

const StyledCont = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Icon = styled.div`
  background-color: orange;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .error {
    color: red;
  }
`;

function hasData(data) {
  let message = '';
  let className = 'status';

  if (!data.conditions?.length) {
    message = 'Select a condition';
    className = 'error';
  } else {
    data.conditions.forEach((condition) => {
      if (condition.value === 'applicationQuestion') {
        if (!condition?.condition?.answer || !condition?.condition?.question) {
          message = 'Please complete all conditions';
          className = 'error';
        }
      } else {
        if (!condition.condition || !condition.value) {
          message = 'Please complete all conditions';
          className = 'error';
        }
      }
    });
  }
  return { message, className };
}

export default memo(({ id, data, isConnectable }) => {
  const nodeType = decisionNodes.find((node) => node.actionType === data?.actionType);
  const IconComp = nodeType?.icon;
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  const message = hasData(data);
  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <StyledCont onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} />
        <Handle type="target" position={Position.Top} />
        <Container>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">Decision</div>
            <div className="status">{data.condition}</div>
            <div className={message.className}>{message.message}</div>
          </Text>
          {data.pauseWindow && (
            <PauseContainer>
              <CirclePause size={8} />
            </PauseContainer>
          )}
        </Container>
        <Handle
          type="source"
          position={Position.Bottom}
          id="yes"
          style={{ left: '25%', background: '#555' }}
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="no"
          style={{ right: '25%', left: 'unset', background: '#555' }}
          isConnectable={isConnectable}
        />
      </StyledCont>
    </SliderContext.Provider>
  );
});
