export function updateArr(arr, idx, obj) {
  const clone = [...arr];
  if (~idx) {
    if (obj) {
      // update
      clone.splice(idx, 1, obj);
    }
    else {
      // delete
      clone.splice(idx, 1);
    }
  }
  return clone;
}

export function deleteObjFromArray(arr, objId) {
  const idx = arr.findIndex(({ id }) => id === objId);
  return updateArr(arr, idx);
}

export function updateObjInArray(arr, obj, objId) {
  const idx = arr.findIndex(({ id }) => id === objId);
  return updateArr(arr, idx, obj);
}

export function addObjToArray(arr, obj) {
  return [...arr, obj];
}

export function uniqBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export function mergeArrayOfObjects(arr1 = [], arr2 = [], prop = 'id') {
  return [...arr1, ...arr2].reduce((acc, obj) => {
    if (!~acc.findIndex((refObj) => refObj[prop] === obj[prop])) acc.push(obj);
    return acc;
  }, []);
}
