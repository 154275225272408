import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../../../api/common-api-utils';
import { addFile } from '../../../../../../api/FormsAPI/ATSFormsStylesAPI';

export function stripBase64Prefix(dataStr) {
  if (!dataStr) return '';
  const parts = dataStr.split('base64,');
  return [...parts].pop();
}

const removeAfterDot = (inputStr) => {
  const dotIndex = inputStr.indexOf('.');
  return dotIndex !== -1 ? inputStr.slice(0, dotIndex) : inputStr;
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const types = {
  'font/woff': 'woff',
  'font/woff2': 'woff2',
  'font/ttf': 'truetype',
  'font/otf': 'opentype',
  'application/vnd.oasis.opendocument.formula-template': 'opentype',
};

function UploadFile({ onChange, value }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInpRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const image = await toBase64(event.target.files[0]);
    const formData = {
      content: stripBase64Prefix(image),
      filename: event.target.files[0].name,
      accessPermission: 'PUBLIC',
      encoding: 'UTF-8',
      contentType: event.target.files[0].type,
    };

    setIsLoading(true);
    setError(null);
    try {
      const response = await retryableAPICall(() => addFile(formData));
      const modifiedString = types[response.contentType];

      if (response.externalUrl) {
        const css = `
        @font-face {
          font-family: ${removeAfterDot(event.target.files[0].name)};
          src: url(${response.externalUrl}) format('${modifiedString}');
        }`;

        const cssInput = await btoa(css);

        const cssFormData = {
          content: stripBase64Prefix(cssInput),
          filename: `${removeAfterDot(event.target.files[0].name)}.css`,
          accessPermission: 'PUBLIC',
          encoding: 'UTF-8',
          contentType: 'text/css',
        };

        const responseCSS = await retryableAPICall(() => addFile(cssFormData));
        toast.success('Uploaded  Font Successfully');
        onChange(responseCSS.externalUrl, removeAfterDot(response.fileName));
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <input
        ref={fileInpRef}
        accept={'.woff, .woff2, .ttf, .otf'}
        name="file"
        onChange={handleSubmit}
        style={{ display: 'none' }}
        type="file"
      />
      <Button
        className="me-3 d-block"
        color="secondary"
        onClick={(e) => {
          e.preventDefault();
          fileInpRef.current.click();
        }}
        size="sm"
      >
        <span className="fa fa-upload fa-btn" /> Upload
      </Button>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {value && <div>upload successful</div>}
    </div>
  );
}

UploadFile.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

UploadFile.defaultProps = {
  onChange: () => {},
  value: '',
};

export default UploadFile;
