import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMounted } from '../../hooks';

function AccountFilter({ children, accountAccess }) {
  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    if (isMounted() && Array.isArray(accountAccess)) {
      setOptionData(accountAccess.map(({ accountId, accountName, default: def }) => (
        { value: accountId, label: accountName, default: def }
      )));
    }
  }, [accountAccess, isMounted]);

  return children({ optionData });
}

AccountFilter.propTypes = {
  children: PropTypes.func,
  accountAccess: PropTypes.arrayOf(PropTypes.shape({
    accountId: PropTypes.string,
    accountName: PropTypes.string,
    default: PropTypes.bool,
  })),
};

AccountFilter.defaultProps = {
  children: () => { },
  accountAccess: [],
};

function mapStateToProps(state) {
  const { userData: { userDetails: { data: { accountAccess } } } } = state;
  return { accountAccess };
}

export default connect(mapStateToProps)(AccountFilter);
