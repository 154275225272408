import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';

function WorkflowSelect({
  id,
  label,
  required,
  error,
  labelSibling,
  placeholder,
  options,
  value,
  onChange,
  isMulti,
  children,
}) {
  return (
    <FormGroup id={id} label={label} required={required} error={error} labelSibling={labelSibling}>
      <Select
        id={id}
        className="react-select"
        placeholder={placeholder}
        options={options}
        value={
          isMulti
            ? options.filter((a) => value.includes(a.value))
            : options.find(({ value: val }) => val === value) || ''
        }
        onChange={(e) => {
          if (isMulti) {
            onChange(e.map((a) => a.value));
          } else {
            onChange(e.value);
          }
        }}
        isMulti={isMulti}
      />
      {children}
    </FormGroup>
  );
}

WorkflowSelect.propTypes = {
  id: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  labelSibling: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

WorkflowSelect.defaultProps = {
  isMulti: false,
  required: false,
  error: null,
  labelSibling: null,
  placeholder: null,
  options: [],
  value: '',
  onChange: () => {},
  children: null,
};

export default WorkflowSelect;
