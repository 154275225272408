import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

function InfoRow({
  title,
  value,
  html,
  asList,
}) {
  if (!value && !html) return null;

  let valStr = value;
  let isArray = false;
  if (Array.isArray(value)) {
    isArray = true;
    valStr = value.join(', ');
  }

  if (asList && isArray && !value.length) return null;

  return (
    <Row>
      <Col sm="3">
        {title && <p className="detail-title">{title}</p>}
      </Col>
      <Col>
        {asList && isArray ? (
          <ul className="list-unstyled detail-value-list">
            {value.map((str) => <li key={str}>{str}</li>)}
          </ul>
        ) : (
          <Fragment>
            {value && <p className="detail-value">{valStr}</p>}
            {html && (
              <div
                className="detail-value plain-html"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: html }}
              />
            )}
          </Fragment>
        )}
      </Col>
    </Row>
  );
}

InfoRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  html: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  asList: PropTypes.bool,
};

InfoRow.defaultProps = {
  title: null,
  value: null,
  html: null,
  asList: false,
};

export default InfoRow;
