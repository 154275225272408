import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const errors = {
  NOT_FOUND_ERROR: [
    'This is not the page you are looking for...',
    (
      <Fragment>
        This page could not be found. Click
        {' '}
        <Link to="/">here</Link>
        {' '}
        to go home.
      </Fragment>
    ),
  ],
  GENERAL_ERROR: [
    'Something appears to have gone wrong!',
    'Rest assured we\'re working hard to fix it. Please try again later.',
  ],
  PERMISSION_DENIED: [
    'Oops, you don\'t have permission to see this!',
    // eslint-disable-next-line max-len
    'You don\'t have the permissions required to see this page. Please contact your administrator if you think you should be able to access it.',
  ],
  MAINTENANCE: [
    'We\'re currently down for maintenance',
    // eslint-disable-next-line max-len
    'Hello, we\'re currently working hard on our platform and are down for maintenance, we should be back shortly',
  ],
};

const statusConversion = {
  403: 'PERMISSION_DENIED',
  404: 'NOT_FOUND_ERROR',
  409: 'ALREADY_EXISTS_ERROR',
  500: 'GENERAL_ERROR',
  501: 'GENERAL_ERROR',
  503: 'MAINTENANCE',
};

function convertStatus(errorStatus) {
  // attempt to convert to number
  const asNumber = Number(errorStatus);

  // if is number from string E.g. '404'
  if (!Number.isNaN(asNumber) && statusConversion[errorStatus]) {
    // lookup string
    return statusConversion[errorStatus];
  }

  return 'GENERAL_ERROR';
}

function findError(errorStatus) {
  let status = errors.GENERAL_ERROR;

  if (typeof errorStatus === 'string') {
    // if in errors
    if (errors[errorStatus]) {
      status = errors[errorStatus];
    }
    else {
      const errStr = convertStatus(errorStatus);
      status = errors[errStr];
    }
  }
  else if (typeof errorStatus === 'number') {
    const errStr = convertStatus(errorStatus);
    status = errors[errStr];
  }

  return status;
}

export default function useErrors(errorStatus) {
  const errorArr = findError(errorStatus);

  return [...errorArr];
}
