import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function DropDownComponent({ title, children }) {
  return (
    <Fragment>
      {title && (
        <div className="dd-menu-header">
          <p>{title}</p>
        </div>
      )}
      {children}
    </Fragment>
  );
}

DropDownComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DropDownComponent.defaultProps = {
  title: null,
};

export default DropDownComponent;
