import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import { toast } from 'react-toastify';
import WrapVideo from './wrap-video';
import 'react-quill/dist/quill.snow.css';
import { removeControlsFromSource } from './resize-video';

WrapVideo.blotName = 'video';
WrapVideo.className = 'ql-video-wrapper';
WrapVideo.tagName = 'DIV';
const DD_ID = 'personalisation-edoc';
const DD_LABEL = 'Personalisation';

// eslint-disable-next-line new-cap
const AlignStyle = Quill.import('attributors/style/align');
const SizeStyle = Quill.import('attributors/style/size');
Quill.register('formats/video', WrapVideo);
Quill.register(AlignStyle, true);
Quill.register(SizeStyle, true);
function fileWarning() {
  const input = document.querySelector('.ql-image[type="file"]');
  const files = input ? input.files : [];

  if (files.length) {
    const totalSize = [...files].reduce((acc, { size = 0 }) => acc + size, 0);
    if (totalSize > 1000000) {
      // eslint-disable-next-line max-len
      toast.error(
        "Sorry the images you've included should be no more than 1MB in size. Please reduce the image size and try again",
      );
    }
  }
}

function imageHandler() {
  const { tooltip } = this.quill.theme;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;
  // eslint-disable-next-line func-names
  tooltip.save = function () {
    const range = this.quill.getSelection(true);
    const { value } = this.textbox;
    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = () => {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };
  tooltip.edit('image');
  tooltip.textbox.placeholder = 'Embed URL';
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const schema = {
  modules: {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          { align: '' },
          { align: 'center' },
          { align: 'right' },
        ],
        ['link', 'image'],
        [
          {
            [DD_ID]: [
              'Candidate First Name',
              'Candidate Surname',
              'Candidate Email Address',
              'Candidate Phone Number',
              'Candidate Postal Address',
              'Employer Email Address',
              'Employer Name',
              'Vacancy Location',
              'Vacancy Postcode',
              'Vacancy Title',
            ],
          },
        ],
      ],
      handlers: {
        [DD_ID](value) {
          if (value) {
            const modifiedValue = `{${value.toLowerCase().replace(/ /g, '_')}}`;
            const cursorPosition = this.quill.getSelection().index;
            this.quill.insertText(cursorPosition, modifiedValue);
            this.quill.setSelection(cursorPosition + modifiedValue.length);
          }
        },
        image: imageHandler,
      },
    },
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'video',
  'image',
  'color',
];

class RTEDoc extends Component {
  constructor(props) {
    super(props);
    const { formData } = props;
    this.state = { formData };
    this.editor = React.createRef();
  }

  componentDidMount() {
    const dropDownItems = [...document.querySelectorAll(`.ql-${DD_ID} .ql-picker-item`)];
    dropDownItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.textContent = item.dataset.value;
    });

    const dropDownLabel = document.querySelector(`.ql-${DD_ID} .ql-picker-label`);
    if (dropDownLabel) dropDownLabel.innerHTML = `${DD_LABEL} ${dropDownLabel.innerHTML}`;
  }

  componentDidUpdate(prevProps) {
    const { formData } = this.props;
    if (prevProps.formData !== formData) {
      this.onUpdate(formData);
    }
  }

  onUpdate = (value) => {
    this.setState({ formData: value });
  };

  handleChange = (content) => {
    const { onChange } = this.props;
    fileWarning();
    let formData = content?.replace(/&nbsp;/g, ' ');
    if (formData === '<p><br></p>') formData = null;
    this.setState({ formData });
    // remove the controls from the html stored on the server
    onChange(removeControlsFromSource(formData));
  };

  render() {
    const { required } = this.props;
    const { formData } = this.state;

    return (
      <ReactQuill
        ref={this.editor}
        formats={formats}
        modules={schema.modules}
        onChange={this.handleChange}
        value={formData || ''}
      />
    );
  }
}

RTEDoc.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.string,
  required: PropTypes.bool,
};

RTEDoc.defaultProps = {
  onChange: () => {},
  formData: null,
  required: false,
};

export default RTEDoc;
