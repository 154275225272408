import { toast } from 'react-toastify';

export default function useToast(type = 'success', config = {}) {
  return (msg, callback) => {
    if (msg && callback) {
      toast(msg, {
        type: type,
        autoClose: 3000,
        toastId: 'ats-toast',
        onOpen: () => callback(),
        ...config,
      });
    }
  };
}
