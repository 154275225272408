import {
  ADD_SEARCHTERM,
  SET_URL_PARAMS,
  REMOVE_SEARCHTERM,
  RESET_SEARCH_STATE,
  SET_SEARCH_PARAM,
  SET_AI_SEARCH_ERROR,
  ADD_SEARCHTERM_ON_ERROR,
} from '../constants/actionTypes';

const initialState = {
  keywords: undefined,
  funnelFilters: '',
  filterId: undefined,
  searchFields: [],
  fieldIds: [
    'id',
    'userId',
    'vacancyDetails',
    'applicantProfile',
    'funnelStageId',
    'funnelStageOrder',
    'funnelId',
    'accountName',
    'accountId',
    'funnelStageName',
    'applicationFormMetaData.applicationFormId',
    'createdDateTime',
    'appliedOnDate',
    'profileSummary',
    'submittedDateTime',
    'tags',
    'mappedAnswers',
  ],
  sort: [{ field: 'submittedDateTime', order: 'DESC' }],
  funnelId: undefined,
  vacancyId: undefined,
  resultAggs: [],
  appliedOn: undefined,
  appliedOnFrom: undefined,
  appliedOnTo: undefined,
  stageIds: undefined,
  qId: undefined,
  hasAISearchError: false,
};

export default function searchReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_SEARCHTERM:
      return {
        ...state,
        keywords: payload,
        hasAISearchError: false,
      };
    case ADD_SEARCHTERM_ON_ERROR:
      return {
        ...state,
        keywords: '',
      };
    case SET_AI_SEARCH_ERROR:
      return {
        ...state,
        hasAISearchError: true,
      };
    case REMOVE_SEARCHTERM:
      return {
        ...state,
        keywords: '',
        hasAISearchError: false,
      };
    case SET_URL_PARAMS:
      return {
        ...state,
        ...payload,
        hasAISearchError: false,
      };
    case SET_SEARCH_PARAM:
      return {
        ...state,
        [payload.name]: payload.value,
        hasAISearchError: false,
      };
    case RESET_SEARCH_STATE:
      return initialState;
    default:
      return state;
  }
}
