import React, { useEffect, useState, useRef, Fragment, useCallback } from 'react';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ModalPopup, Confirmation } from '../../Modal';
import ColumnList from './ColumnList';
import { useMounted } from '../../hooks';
import { moveArrayItem } from '../../../../js/utils/moveArrayItem';
import { sortColumns, setColumnConfig, removeColumnConfig, getColumnConfig } from '../utils';

function divideColumns(columns) {
  const visibleCols = columns.filter(({ visible }) => visible);
  const hiddenCols = columns.filter(({ visible }) => !visible);
  return { hiddenCols, visibleCols };
}

function ColumnManager({ ns, isOpen, onClose, columns, onOkay, onReset }) {
  const isMounted = useMounted();
  const functionalCols = useRef([]);
  const cachedSortableCols = useRef([]);
  const [sortableCols, setSortableCols] = useState([]);
  const [availableCols, setAvailableCols] = useState([]);
  const [displayColumns, setDisplayColumns] = useState([]);
  const [addColIds, setAddColIds] = useState([]);
  const [removeColIds, setRemoveColIds] = useState([]);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  useEffect(() => {
    if (isMounted()) {
      functionalCols.current = columns.filter((col) => typeof col.Header === 'function');
      const cols = columns.filter((col) => typeof col.Header === 'string').map((col, i) => ({ ...col, orderIndex: i }));

      cachedSortableCols.current = [...cols];
      setSortableCols(cols);

      const { visibleCols, hiddenCols } = divideColumns(cols);
      setAvailableCols(hiddenCols);
      setDisplayColumns(visibleCols);
    }
  }, [columns, isMounted]);

  const handleOnOkay = useCallback(() => {
    const updatedCols = [...functionalCols.current, ...sortableCols];
    const hasChanged = !isEqual(cachedSortableCols.current, sortableCols);
    if (hasChanged) {
      setColumnConfig(
        ns,
        updatedCols.reduce((acc, { id, visible, orderIndex }) => ({ ...acc, [id]: { orderIndex, visible } }), []),
      );
      onOkay(updatedCols);
    }
  }, [ns, onOkay, sortableCols]);

  const handleOrderChange = useCallback(
    (fromIndex, toIndex) => {
      const updatedCols = moveArrayItem([...displayColumns, ...availableCols], fromIndex, toIndex).map((col, i) => ({
        ...col,
        orderIndex: i,
      }));

      setSortableCols(updatedCols);

      const { visibleCols, hiddenCols } = divideColumns(updatedCols);
      setAvailableCols(hiddenCols);
      setDisplayColumns(visibleCols);
    },
    [availableCols, displayColumns],
  );

  const colConf = getColumnConfig(ns);

  return (
    <Fragment>
      <ModalPopup
        title="Manage Columns"
        isOpen={isOpen}
        onToggle={onClose}
        okayLabel="Update"
        onOkay={handleOnOkay}
        footer={({ OkayButton, CancelButton }) => (
          <Fragment>
            {Boolean(Object.keys(colConf).length) && (
              <Button className="btn-outline me-auto" onClick={() => setConfirmIsOpen(true)}>
                Reset
              </Button>
            )}
            <CancelButton />
            <OkayButton />
          </Fragment>
        )}
      >
        <div className="table-manager">
          <div className="table-manager-col table-manager-available-cols">
            <h6>Available Columns</h6>
            <ColumnList
              id="available-cols"
              columns={availableCols}
              selectedIds={addColIds}
              onColumnSelect={setAddColIds}
            />
          </div>
          <div className="table-manager-col table-manager-button-cols">
            <Button
              onClick={() => {
                const toDisplay = [...displayColumns, ...availableCols.filter(({ id }) => addColIds.includes(id))]
                  .sort(sortColumns)
                  .map((col) => ({ ...col, visible: true }));

                const updatedAvailable = availableCols
                  .filter(({ id }) => !addColIds.includes(id))
                  .sort(sortColumns)
                  .map((col) => ({ ...col, visible: false }));

                setAvailableCols(updatedAvailable);
                setDisplayColumns(toDisplay);
                setSortableCols([...updatedAvailable, ...toDisplay].sort(sortColumns));
                setAddColIds([]);
              }}
            >
              {'Add >'}
            </Button>
            <Button
              onClick={() => {
                const toAvailable = [...availableCols, ...displayColumns.filter(({ id }) => removeColIds.includes(id))]
                  .sort(sortColumns)
                  .map((col) => ({ ...col, visible: false }));

                const updatedDisplay = displayColumns
                  .filter(({ id }) => !removeColIds.includes(id))
                  .sort(sortColumns)
                  .map((col) => ({ ...col, visible: true }));

                setDisplayColumns(updatedDisplay);
                setAvailableCols(toAvailable);
                setSortableCols([...updatedDisplay, ...toAvailable].sort(sortColumns));
                setRemoveColIds([]);
              }}
            >
              {'< Remove'}
            </Button>
          </div>
          <div className="table-manager-col table-manager-display-cols">
            <h6>Display in this Order</h6>
            <ColumnList
              id="display-cols"
              columns={displayColumns}
              selectedIds={removeColIds}
              onColumnSelect={setRemoveColIds}
              orderable
              onOrderChange={handleOrderChange}
            />
          </div>
        </div>
      </ModalPopup>
      <Confirmation
        show={confirmIsOpen}
        cancelCallback={() => setConfirmIsOpen(false)}
        confirmCallback={() => {
          removeColumnConfig(ns);
          setConfirmIsOpen(false);
          onReset();
          onClose();
        }}
      />
    </Fragment>
  );
}

ColumnManager.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  isOpen: PropTypes.bool,
  ns: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onOkay: PropTypes.func,
  onReset: PropTypes.func,
};

ColumnManager.defaultProps = {
  columns: [],
  isOpen: false,
  onClose: () => {},
  onOkay: () => {},
  onReset: () => {},
};

export default ColumnManager;
