import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader } from 'reactstrap';

function Header({ toggle, children, hideCloseButton }) {
  if (hideCloseButton) return <ModalHeader>{children}</ModalHeader>;
  return <ModalHeader toggle={toggle}>{children}</ModalHeader>;
}

Header.propTypes = {
  toggle: PropTypes.func,
  children: PropTypes.string.isRequired,
  hideCloseButton: PropTypes.bool,
};

Header.defaultProps = {
  toggle: () => { },
  hideCloseButton: false,
};

export default Header;
