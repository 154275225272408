import React from 'react';

// eslint-disable-next-line max-len
// https://crunchify.com/list-of-all-social-sharing-urls-for-handy-reference-social-media-sharing-buttons-without-javascript/
const NETWORKS = {
  facebook: {
    url: 'https://www.facebook.com/sharer/sharer.php',
    height: 350,
    params: (url) => `u=${url}`,
  },
  twitter: {
    url: 'https://twitter.com/share',
    height: 253,
    params: (url, title) => `url=${url}&text=${title}`,
  },
  linkedin: {
    url: 'https://www.linkedin.com/shareArticle',
    height: 420,
    params: (url, title) => `mini=true&url=${url}&title=${title}`,
  },
};

function openPopup(networkName, uri, title) {
  const { url, height, params } = NETWORKS[networkName];

  global.open(
    `${url}?${params(uri, title)}`,
    `${networkName}-popup`,
    `toolbar=0,status=0,height=${height},width=600`,
  );
}

// caveats about utm usage: https://neilpatel.com/blog/social-media-utm-parameters/
function setUtmParams(url, networkName) {
  const escParams = encodeURIComponent(`?utm_source=${networkName}&utm_medium=social_post&utm_campaign=job_publish`);
  return `${url}${escParams}`;
}

export default function useShareButtons(networks, className) {
  // bail if incorrect param sent
  if (!Array.isArray(networks) || !networks.length) return null;

  // sanitize and validate networks
  const networkNames = networks.reduce((acc, network) => {
    const networkName = network.toLowerCase();
    if (NETWORKS[networkName]) acc.push(networkName);
    return acc;
  }, []);

  // no valid networks bail
  if (!networkNames.length) return null;

  return (url, title) => {
    const escUrl = encodeURIComponent(url);
    const escTitle = encodeURIComponent(title);

    return networkNames.map((networkName) => (
      <button
        key={networkName}
        type="button"
        className={`share-button ${networkName} ${className || ''}`}
        onClick={() => openPopup(networkName, setUtmParams(escUrl, networkName), escTitle)}
      >
        {`Share on ${networkName}`}
      </button>
    ));
  };
}
