import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { applications, api } = config;
const builderPath = api.middlewareAPIURL + applications.applicationBuilderPath;
const appServicePath = api.middlewareAPIURL + applications.applicationServicePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */
export async function createApplicationForm(applicationData) {
  const result = await axios.post(`${builderPath}`, applicationData, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getApplicationFormByApplicationFormId(applicationFormId) {
  const result = await axios.get(`${builderPath}/${applicationFormId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getApplicationURL(vacancyId) {
  const result = await axios.get(`${builderPath}${applications.applicationURLPath}/${vacancyId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

/**
 * Allows the downloading of the application in PDF format
 * @param fileName
 * @returns {Promise<*>}
 */
export async function downloadApplicationPDF(applicationId) {
  const result = await axios.get(`${appServicePath}${applications.applicationPDFDownloadPath}/${applicationId}`, {
    headers: getCommonHeaders(),
  });

  const blob = await (await fetch(`data:image/png;base64,${result.data.data}`)).blob();

  if (result.status === 200) return fileDownload(blob, result.data.fileName);
  return null;
}

export async function deleteFile(applicantId, filename) {
  const result = await axios.delete(
    `${api.middlewareAPIURL}${applications.applicantFilePath}/${applicantId}/${filename}`,
    { headers: getCommonHeaders() },
  );
  if (result.status === 200) return {};
  return null;
}

/**
 * Gets both the application form an supplemental data on the applicant (job skeeker profile data etc)
 * @param applicationId
 * @returns {Promise<*>}
 */
export async function getApplicant(applicationId) {
  const result = await axios.get(`${appServicePath}/${applicationId}`, { headers: getCommonHeaders() });

  if (result.status === 200) return result.data;
  return null;
}

/**
 * Gets both the application form an supplemental data on the applicant (job skeeker profile data etc)
 * @param applicationId
 * @returns {Promise<*>}
 */
export async function getCandidateProfile(candidateId) {
  const result = await axios.get(`${api.middlewareAPIURL}/candidate/profile/${candidateId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function updateFunnelStage(applicantIds, funnelId, funnelStageId, funnelStageReason, email = null) {
  const body = {
    candidateIds: applicantIds,
    email,
    funnelId,
    funnelStageId,
  };

  if (funnelStageReason) body.funnelStageReason = funnelStageReason;

  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantFunnelUpdatePath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function updateCandidateStageOrder(orderedCandidates) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.candidateFunnelStageOrderUpdatePath}`,
    orderedCandidates,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;
  return null;
}

export async function getFunnel(funnelId, candidateCount) {
  let url = `${api.middlewareAPIURL}${applications.applicantFunnelPath}/${funnelId}`;

  if (candidateCount) {
    url += '?candidateCount=true';
  }
  const result = await axios.get(url, { headers: getCommonHeaders() });
  if (result.status === 200) return result.data;
  return null;
}

export async function getAvailableFunnels() {
  const result = await axios.get(
    `${api.middlewareAPIURL}${applications.applicantFunnelPath}${applications.availableFunnelPath}`,
    { headers: getCommonHeaders() },
  );
  if (result.status === 200) return result.data;
  return null;
}

export async function getRejectionReasons() {
  let url = `${api.middlewareAPIURL}/candidate/rejection-reasons`;
  const result = await axios.get(url, { headers: getCommonHeaders() });
  if (result.status === 200) return result.data;
  return null;
}

export async function saveRejectionReasons(rejectionReasons) {
  let url = `${api.middlewareAPIURL}/candidate/rejection-reasons`;
  const result = await axios.put(
    url,
    rejectionReasons,
    { headers: getCommonHeaders() }
  );
  if (result.status === 200) return result.data;
  return null;
}

export async function getFunnelForVacancy(funnelId, vacancyId) {
  const result = await axios.get(
    `${api.middlewareAPIURL}${applications.applicantFunnelPath}/${funnelId}/${vacancyId}`,
    { headers: getCommonHeaders() },
  );
  if (result.status === 200) return result.data;
  return null;
}

export async function getStages(candidateCount, grouped) {
  const url = `${api.middlewareAPIURL}${applications.applicantFunnelPath}/stages`;

  const queryArr = [];
  if (candidateCount) queryArr.push('candidateCount=true');
  if (grouped) queryArr.push('grouped=true');

  const result = await axios.get(`${url}${queryArr.length ? `?${queryArr.join('&')}` : ''}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function sendEmail(
  fromName,
  replyTo,
  subject,
  htmlContent,
  applicationIds,
  ccAddresses,
  bccAddresses,
  attachments,
  delay,
) {
  const body = {
    candidateIds: applicationIds,
    delay,
    fromName,
    htmlContent,
    replyTo,
    subject,
  };

  if (ccAddresses && ccAddresses.length) body.ccAddresses = ccAddresses;
  if (bccAddresses && bccAddresses.length) body.bccAddresses = bccAddresses;
  if (attachments && attachments.length) body.attachments = attachments;

  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantEmailPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function sendSms(content, applicationIds) {
  const body = {
    applicationIds,
    content,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantSmsPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function createNote(content, applicantIds) {
  const body = {
    applicantIds,
    content,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantNotePath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201) return result.data;
  return null;
}

export async function getApplicantNotes(applicantId) {
  const result = await axios.get(`${api.middlewareAPIURL}${applications.applicantNotePath}/${applicantId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function getAllJobseekerApplications(jobseekerId) {
  const result = await axios.get(
    `${api.middlewareAPIURL}${applications.applicantOtherApplicationPath}/${jobseekerId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;
  return null;
}

export async function getCandidateTimeline(candidateId, accountId, types) {
  let url = `${api.middlewareAPIURL}${applications.applicantTimelinePath}/${candidateId}`;

  if (types && types.length > 0) {
    url = `${url}?types=${types.join(',')}`;
  }

  const result = await axios.get(url, {
    headers: getCommonHeaders(accountId),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function updateTags(applicantIds, addedTagIds, removedTagIds) {
  const body = {
    addedTagIds,
    applicantIds,
    removedTagIds,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantTagUpdatePath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function getAllTags() {
  const result = await axios.get(`${api.middlewareAPIURL}${applications.applicantGetAllTagsPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function searchTags(prefix) {
  const result = await axios.get(`${api.middlewareAPIURL}${applications.applicantTagManagementPath}/${prefix}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function createTag(tagName) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.applicantTagManagementPath}/${tagName}`,
    null,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201) return result.data;
  return null;
}

export async function deleteTag(tagId) {
  if (tagId) {
    const result = await axios.delete(`${api.middlewareAPIURL}${applications.applicantTagManagementPath}/${tagId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function editTag(tagId, tagName) {
  if (tagId && tagName) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${applications.applicantTagManagementPath}/${tagId}/${tagName}`,
      null,
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function getTagsForApplicants(applicantIds) {
  const result = await axios.post(`${api.middlewareAPIURL}${applications.applicantGetCurrentTagsPath}`, applicantIds, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;
  return null;
}

export async function requestVideoTranscription(candidateId, fileId) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.candidateVideoTranscriptionPath}/${candidateId}/${fileId}`,
    {},
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;
  return null;
}

export async function checkTranscriptionStatus(candidateId, fileId, jobId) {
  const result = await axios.get(
    `${api.middlewareAPIURL}${applications.candidateVideoTranscriptionPath}/${candidateId}/${fileId}/${jobId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;
  return null;
}

export async function downloadCandidateCSV(candidateIds, exportCategories, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.candidateExportPath}`,
    { candidateIds, exportCategories },
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 && result.data) {
    const { contentType, data, fileName } = result.data;

    if (base64) return data;

    const base64Str = await fetch(`data:${contentType};base64,${data}`);
    const blob = await base64Str.blob();

    if (forceDownload) return fileDownload(blob, fileName);

    return blob;
  }

  return null;
}

export async function exportCandidatesAsPDF(candidateIds, exportCategories, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.exportCandidatesAsPDFPath}`,
    { candidateIds, exportCategories },
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 && result.data) {
    const { contentType, data, fileName } = result.data;

    if (base64) return data;

    const base64Str = await fetch(`data:${contentType};base64,${data}`);
    const blob = await base64Str.blob();

    if (forceDownload) return fileDownload(blob, fileName);

    return blob;
  }

  return null;
}

export async function getCandidateProfileSummary(candidateId) {
  if (candidateId) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${applications.candidateProfileSummaryPath}/${candidateId}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 200) return result.data;
  }

  return null;
}

export async function generateExternalAccessToCandidates(candidateIds, vacancyId, emailAddresses) {
  const result = await axios.post(
    `${api.middlewareAPIURL}${applications.candidateExternalAccessGeneratePath}`,
    { candidateIds, emailAddresses, vacancyId },
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;

  return null;
}

function commonHeadersNoAuth() {
  const {
    auth: { tenantId },
  } = config;

  return {
    'Content-Type': 'application/x-www-form-urlencoded',
    Tenant: tenantId,
  };
}

export async function getCandidateWithAccessToken(token, accessCode) {
  if (token && accessCode) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${applications.candidateAnonAccessPath}?token=${token}&accessCode=${accessCode}`,
      { headers: commonHeadersNoAuth() },
    );

    if (result.status === 200) return result.data;
  }

  return null;
}

export async function downloadCandidateWithAccessToken(token, accessCode, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.get(
    `${api.middlewareAPIURL}${applications.candidateAnonAccessPath}?token=${token}&accessCode=${accessCode}`,
    { headers: commonHeadersNoAuth() },
  );

  if (result.status === 200 && result.data) {
    const { contentType, data, fileName } = result.data;

    if (base64) return data;

    const base64Str = await fetch(`data:${contentType};base64,${data}`);
    const blob = await base64Str.blob();

    if (forceDownload) return fileDownload(blob, fileName);

    return blob;
  }

  return null;
}

export async function moveCandidate(candidateId, vacancyId) {
  if (candidateId && vacancyId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${applications.moveCandidatePath}`,
      { candidateId, vacancyId },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200) return result.data;
  }

  return null;
}
