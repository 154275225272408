import moment from 'moment';

export const DATE_FORMAT = 'dddd Do MMMM YYYY';
export const TIME_FORMAT = 'hh:mm A';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT} HH:mm:00`;
export const ISO_DATE_REGEX = '^\\d{4}-([0]\\d|1[0-2])-([0-2]\\d|3[01])$';

export function getTimeOptions() {
  const interval = 15;
  let startTime = 0;
  const ap = ['AM', 'PM'];
  const times = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; startTime < (24 * 60); i++) {
    const hours = Math.floor(startTime / 60);

    const hrs24 = (`0${hours}`).slice(-2);
    const hrs12 = hours === 0 || hours === 12 ? 12 : (`0${hours % 12}`).slice(-2);
    const mins = `${(`0${(startTime % 60)}`).slice(-2)}`;
    const amPm = `${ap[Math.floor(hours / 12)]}`;

    if (hours >= 6) {
      times.push({
        value: `${hrs24}:${mins}`,
        label: `${hrs12}:${mins} ${amPm}`,
      });
    }

    startTime += interval;
  }

  return times;
}

export function updateDateTime(date, timeStr) {
  const [hours, mins] = timeStr.split(':');
  return date.hour(Number(hours)).minute(Number(mins));
}

export function mergeDateTime(formData) {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
  } = formData;
  return {
    ...formData,
    startDate: updateDateTime(startDate, startTime),
    endDate: updateDateTime(endDate, endTime),
  };
}

export function getIsoDate(date) {
  return moment(date).format(ISO_DATE_FORMAT);
}

export function isPast(date, refDate = new Date()) {
  return moment(date).isSameOrBefore(refDate);
}

export function isFuture(date, refDate = new Date()) {
  return moment(date).isSameOrAfter(refDate);
}

export function roundTimeDifference(date, refDate = new Date()) {
  const [q, unit] = moment(refDate).to(date, true).split(' ');
  const quantity = Number.isNaN(parseFloat(q)) ? 1 : Number(q);
  return [quantity, unit.replace(/s$/, '')];
}

export function getTimeZone() {
  if (global.Intl) return Intl.DateTimeFormat().resolvedOptions().timeZone;
  return 'Europe/London';
}

const isoRx = new RegExp(ISO_DATE_REGEX, 'i');

export function isISODate(dateStr) {
  return isoRx.test(dateStr);
}
