import { retryableAPICall } from '../../../api/common-api-utils';
import {
  createTag,
  getAllTags,
  getTagsForApplicants,
  updateTags,
  deleteTag,
  editTag,
} from '../../../api/CandidateAPI/CandidateAPI';
import { uniqBy } from '../../../js/utils/arrayOfObjects';

export function sortTags(tags) {
  if (tags) {
    return [...tags].map(({ id, label, name }) => (
      { id, name: label || name }
    )).sort((a, b) => a.name.localeCompare(b.name));
  }
  return [];
}

function tagCount(tags) {
  return tags.reduce((acc, { id }) => {
    if (!acc[id]) {
      acc[id] = 1;
    }
    else {
      acc[id] += 1;
    }

    return acc;
  }, {});
}

export async function getTags(onSuccess = () => { }, onError = () => { }) {
  const tags = await retryableAPICall(() => getAllTags());

  if (typeof tags === 'string') {
    onError();
  }
  else {
    const sortedTags = sortTags(tags);
    onSuccess(sortedTags);
  }
}

export async function getSelectedTags(candidateIds, onSuccess = () => { }, onError = () => { }) {
  const candidateTags = await retryableAPICall(() => getTagsForApplicants(candidateIds));

  if (typeof candidateTags === 'string') {
    onError();
  }
  else {
    let rawTags = [];
    let tags = [];
    let count = {};

    if (Array.isArray(candidateTags)) {
      tags = candidateTags.reduce((acc, candidate) => [...acc, ...candidate.tags], []);
      count = tagCount(tags);
      rawTags = candidateTags.map((candidate) => ({
        ...candidate,
        tags: sortTags(candidate.tags),
      }));
    }

    const sortedTags = sortTags(tags);
    const uniqTags = uniqBy(sortedTags, 'id');

    onSuccess(uniqTags, count, rawTags);
  }
}

export async function applyTags(candidateIds, opts = {}) {
  const {
    addTagIds = [],
    removeTagIds = [],
    onSuccess = () => { },
    onError = () => { },
  } = opts;

  const resp = await retryableAPICall(() => updateTags(candidateIds, addTagIds, removeTagIds));

  if (typeof resp === 'string') {
    onError();
  }
  else {
    onSuccess();
  }
}

export async function createNewTag(tagName, onSuccess = () => { }, onError = () => { }) {
  if (tagName && tagName.trim().length) {
    const resp = await retryableAPICall(() => createTag(tagName.trim()));

    if (typeof resp === 'string') {
      onError(resp);
    }
    else {
      onSuccess({ id: resp.id, name: resp.label });
    }
  }
}

export async function deleteTags(tagIds = [], onSuccess = () => { }, onError = () => { }) {
  const respArr = await Promise.all(tagIds.map((tagId) => (
    retryableAPICall(() => deleteTag(tagId))
  )));

  const hasRespError = respArr.some((resp) => (typeof resp === 'string' && resp.length));

  if (hasRespError) {
    onError();
  }
  else {
    onSuccess();
  }
}

export async function editTagName(tagId, tagName, onSuccess = () => { }, onError = () => { }) {
  if (tagId && tagName) {
    const resp = retryableAPICall(() => editTag(tagId, tagName));

    if (typeof resp === 'string') {
      onError();
    }
    else {
      onSuccess();
    }
  }
}
