import React from 'react';
import BaseSVG from '../BaseSVG';

function Tick(props) {
  return (
    <BaseSVG
      {...props}
      name="Tick"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      strokeWidth="4"
      stroke="currentColor"
      // eslint-disable-next-line max-len
      d="M4.5 12.75l6 6 9-13.5"
    />
  );
}

export default Tick;
