import React from 'react';
import PropTypes from 'prop-types';
import EdiText from 'react-editext';

export default function EditInline({
  value,
  onChange,
  onEdit,
  onCancel,
  onToggle,
  showButtonsOnHover,
}) {
  function handleToggle(isEdit) {
    onToggle(isEdit);
  }

  function handleEdit() {
    handleToggle(true);
    onEdit();
  }

  function handleCancel() {
    handleToggle(false);
    onCancel();
  }

  return (
    <EdiText
      type="text"
      value={value}
      onSave={onChange}
      onEditingStart={handleEdit}
      onCancel={handleCancel}
      submitOnEnter
      submitOnUnfocus
      viewContainerClassName="edit-inline"
      editButtonClassName="edit-btn"
      saveButtonClassName="btn btn-sm save-btn"
      cancelButtonClassName="btn btn-sm cancel-btn"
      showButtonsOnHover={showButtonsOnHover}
      inputProps={{
        className: 'form-control',
      }}
    />
  );
}

EditInline.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onToggle: PropTypes.func,
  showButtonsOnHover: PropTypes.bool,
};

EditInline.defaultProps = {
  value: '',
  onChange: () => { },
  onEdit: () => { },
  onCancel: () => { },
  onToggle: () => { },
  showButtonsOnHover: true,
};
