
import { ADD_ATS_FORM } from '../constants/actionTypes';

const initialState = {
  forms: {},
};

const atsFormReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { forms } = state;

  switch (type) {
    case ADD_ATS_FORM:
      return {
        ...state,
        forms: {
          ...forms,
          [payload.formType]: payload,
        },
      };
    default:
      return state;
  }
};

export default atsFormReducer;
