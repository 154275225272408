import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import cx from 'classnames';
import InterviewStatusSelect from './InterviewStatusSelect';
import { DeleteButton } from '../../Base/Buttons';
import InterviewContext from './InterviewContext';

const defaultDate = new Date();

function InterviewHeading({ invite, dateTimeFormat }) {
  const { languagePack, onInviteStatusChange, onCancelInvite } = useContext(InterviewContext);

  const { id, status, createdDateTime } = invite;

  return (
    <div className="d-flex align-items-center">
      <div className="me-auto mb-2">
        <p className="mb-1">
          {`${languagePack.inviteCreated} `}
          {moment
            .utc(createdDateTime || defaultDate)
            .local()
            .format(dateTimeFormat)}
        </p>
        <h4 className={cx('mb-0', { 'text-danger': status === 'NO_SHOW' })}>{languagePack[status] || 'Invited'}</h4>
      </div>
      {status !== 'INVITED' && (
        <InterviewStatusSelect
          value={status}
          onChange={(newStatus, sanitizedStatus) => onInviteStatusChange(newStatus, id, sanitizedStatus)}
        />
      )}
      {invite.status === 'INVITED' && invite.availableSlotCount === 0 && (
        <div className="alert alert-danger mb-0 p-2">
          <a className="text-primary" href="/schedule">
            {languagePack.noAvailableSlotWarning}
          </a>
        </div>
      )}
      {/^(INVITED|ACCEPTED)$/i.test(status) && (
        <Tippy theme="ats" maxWidth="150px" content={languagePack.cancelInviteTooltip}>
          <div>
            <DeleteButton size="sm" className="ms-2" iconOnly action={() => onCancelInvite(id)} />
          </div>
        </Tippy>
      )}
    </div>
  );
}

InterviewHeading.propTypes = {
  invite: PropTypes.shape(),
  dateTimeFormat: PropTypes.string.isRequired,
};

InterviewHeading.defaultProps = {
  invite: {},
};

export default InterviewHeading;
