import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import cx from 'classnames';
import StageLabel from './StageLabel';
import PortalAware from '../../Base/DragAndDrop/PortalAware';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import { IconSVG } from '../../Base/SVG';

const LabelSpan = styled.span`
  max-width: ${({ stageWidth }) => (stageWidth - 40)}px;
`;

function DraggableStage({
  label,
  index,
  total,
  stageWidth,
}) {
  const truncate = (label.split(' ').length - 1) < 1;

  return (
    <Draggable
      isDragDisabled={!checkPermissions(['candidate:funnel:update'])}
      draggableId="drag-stage"
      index={0}
    >
      {(provided, snapshot) => (
        <PortalAware
          provided={provided}
          snapshot={snapshot}
          draggableClassName="drag-stage"
          portalClassName="funnel-portal"
        >
          <StageLabel>
            {snapshot.isDragging ? '' : (
              <Fragment>
                <LabelSpan
                  stageWidth={stageWidth}
                  className={cx({ 'text-truncate': truncate })}
                >
                  {label}
                </LabelSpan>
                <span>
                  {index && total ? `${index}/${total}` : <IconSVG name="Thumb Down Solid" />}
                </span>
              </Fragment>
            )}
          </StageLabel>
        </PortalAware>
      )}
    </Draggable>
  );
}

DraggableStage.propTypes = {
  label: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
  stageWidth: PropTypes.number,
};

DraggableStage.defaultProps = {
  label: 'Drag',
  index: 0,
  total: 0,
  stageWidth: 120,
};

export default memo(DraggableStage);
