// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import { toast } from 'react-toastify';
import { schema, uiSchema } from '../../../../js/model/form-schemas/AccountAdminSchema';
import { transformErrors } from '../../../../js/utils/validation-helper';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { CountrySelect } from '../../../Base/Forms/Custom/AddressLookup';
import { createAccount, updateAccount } from '../../../../api/AccountAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { trimFormData } from '../../../../js/utils/general-utils';
import { requestStatuses } from '../../../../js/constants/requestStatuses';

async function proxyCall(data, id) {
  if (id) {
    return updateAccount({
      id,
      name: data.name,
      restrictAppsPerAccount: data.restrictAppsPerAccount,
      email: data.contactEmail || '',
    });
  }
  return createAccount(data);
}

function AccountAdminEditor({ isEditing, data, onSave, onCancel }) {
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({ country: 'GB' });

  useEffect(() => {
    const { name, vacancySettings } = data;
    setClientData((prevState) => ({
      ...prevState,
      name,
      restrictAppsPerAccount: vacancySettings?.restrictAppsByAccount,
      contactEmail: data.email || undefined,
    }));
  }, [data]);

  useLayoutEffect(() => {
    [...document.querySelectorAll('#accountAdmin .form-group')].forEach((elm) => {
      if (
        isEditing &&
        !elm.classList.contains('account-name') &&
        !elm.classList.contains('contact-email') &&
        !elm.classList.contains('restrict-apps-account')
      ) {
        // eslint-disable-next-line no-param-reassign
        elm.style.display = 'none';
      }
    });
  }, [isEditing]);

  async function handleSave(formData) {
    setIsSaving(true);

    const trimmedData = trimFormData(formData);
    const { postCode = '', ...rest } = trimmedData;
    const updated = { ...rest, postCode: postCode.toUpperCase() };
    setClientData(updated);

    const serverData = {
      ...updated,
      businessType: updated.businessType || '',
    };

    const resp = await retryableAPICall(() => proxyCall(serverData, data.id));

    if (typeof resp === 'string') {
      let reasonStr = '';
      if (isEditing) {
        if (resp === requestStatuses.NOT_FOUND_ERROR) {
          reasonStr = ' Could not update account.';
        } else if (resp === requestStatuses.PERMISSION_DENIED) {
          reasonStr = ' You do not have permission to edit this account.';
        }
      }

      if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
        reasonStr = ' Unable to create account as this name has already been used';
      }

      toast.error(`Error ${isEditing ? 'updating' : 'creating'} account.${reasonStr}`);
    } else {
      toast.success(`Account successfully ${isEditing ? 'updated' : 'created'}`);

      // clean up response
      const { id, name, status, createdDateTime, vacancySettings, email } = resp;

      onSave({
        createdDateTime,
        id,
        name,
        status,
        vacancySettings,
        email,
      });
    }

    setIsSaving(false);
  }

  // on edit only name required rest of fields are hidden
  const formSchema = !isEditing
    ? {
        ...schema,
        required: [...schema.required, 'country', 'postCode'],
        // eslint-disable-next-line sort-keys
        properties: {
          ...schema.properties,
          postCode: {
            ...schema.properties.postCode,
            minLength: 6,
            pattern: '^[a-zA-Z]{1,2}\\d[a-zA-Z\\d]?\\s*\\d[a-zA-Z]{2}$',
          },
        },
      }
    : { ...schema };

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Account`;

  return (
    <Form
      formData={clientData}
      noHtml5Validate
      onSubmit={({ formData }) => handleSave(formData)}
      schema={formSchema}
      showErrorList={false}
      transformErrors={transformErrors}
      uiSchema={uiSchema}
      widgets={{
        countrySelect: CountrySelect,
      }}
    >
      <CreateButton disabled={isSaving} isLoading={isSaving} label={saveBtnText} type="submit" />
      <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
    </Form>
  );
}

AccountAdminEditor.propTypes = {
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

AccountAdminEditor.defaultProps = {
  data: {},
  isEditing: false,
  onCancel: () => {},
  onSave: () => {},
};

export default AccountAdminEditor;
