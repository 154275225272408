import React from 'react';
import PropTypes from 'prop-types';

function ErrorFeedback({ message, className }) {
  if (!message) return null;
  return <div className={`invalid-feedback d-block ${className || ''}`}>{message}</div>;
}

ErrorFeedback.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

ErrorFeedback.defaultProps = {
  message: null,
  className: null,
};

export default ErrorFeedback;
