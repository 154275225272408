/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function IndeterminateCheckbox({
  // checked,
  indeterminate,
  value,
  children,
  labelClassName,
  className,
  onChange,
  ...rest
}) {
  const cbRef = useRef();
  const [clickCount, setClickCount] = useState(0);

  useLayoutEffect(() => {
    if (indeterminate && value === -1) {
      cbRef.current.indeterminate = true;
      cbRef.current.classList.add('is-indeterminate');
    }
    else {
      cbRef.current.checked = value === 1;
      cbRef.current.classList.remove('is-indeterminate');
    }
  }, [indeterminate, value]);

  function handleChange(e) {
    const clckCnt = (clickCount + 1);
    setClickCount(clckCnt);

    // eslint-disable-next-line no-nested-ternary
    const val = !(clckCnt % 3) && indeterminate ? -1 : e.target.checked ? 1 : 0;

    onChange(val);
  }

  if (children) {
    return (
      <label htmlFor={rest.id} className={cx(labelClassName)}>
        <input
          type="checkbox"
          ref={cbRef}
          className={cx(className)}
          onChange={handleChange}
          {...rest}
        />
        {children}
      </label>
    );
  }

  return (
    <input
      type="checkbox"
      ref={cbRef}
      className={cx(className)}
      onChange={handleChange}
      {...rest}
    />
  );
}

IndeterminateCheckbox.propTypes = {
  value: PropTypes.number,
  // checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
};

IndeterminateCheckbox.defaultProps = {
  value: 0,
  // checked: false,
  indeterminate: false,
  labelClassName: null,
  className: null,
  children: null,
  onChange: () => { },
};

export default IndeterminateCheckbox;
