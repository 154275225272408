import React from 'react';
import BaseSVG from '../BaseSVG';

function Columns(props) {
  return (
    <BaseSVG
      {...props}
      name="Columns"
      viewBox="0 0 512 512"
      // eslint-disable-next-line max-len
      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64V160h160v256zm224 0H288V160h160v256z"
    />
  );
}

export default Columns;
