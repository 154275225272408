import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function AddressObjectHandler({ obj }) {
  const { formattedAddress } = obj;
  return (
    <Fragment>
      {formattedAddress}
    </Fragment>
  );
}

AddressObjectHandler.propTypes = {
  obj: PropTypes.shape({
    addressLine1: PropTypes.string,
    formattedAddress: PropTypes.string,
  }),
};

AddressObjectHandler.defaultProps = {
  obj: {},
};

export default AddressObjectHandler;
