import React from 'react';
import PropTypes from 'prop-types';
import BadgePill, { stateLookup } from './BadgePill';

function withLink(Comp) {
  const LinkHoc = ({ status, onClick }) => {
    if (!status || !stateLookup[status.toLowerCase()]) return null;

    const { state } = stateLookup[status.toLowerCase()];

    return (
      <a
        href={`#${state}`}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        <Comp status={status} />
      </a>
    );
  };

  LinkHoc.propTypes = {
    status: PropTypes.string,
    onClick: PropTypes.func,
  };

  LinkHoc.defaultProps = {
    status: null,
    onClick: () => { },
  };

  return LinkHoc;
}

const BadgeLink = withLink(BadgePill);

export default BadgeLink;
