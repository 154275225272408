import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const options = [
  { value: 'maritalStatusBasic', label: 'Marital Status Basic (Married / Single / Divorced / Widowed)' },
  { value: 'maritalStatus', label: 'Marital Status (Includes Civil Partnership and Separated)' },
  { value: 'gender', label: 'Gender' },
  { value: 'genderbinary', label: 'Gender Binary (Limited to Female / Male)' },
  { value: 'titleBasic', label: 'Title (Mr / Mrs / Miss / Ms)' },
  { value: 'title', label: 'Title (Includes Mx, Dr and Other)' },
  { value: 'relationship', label: 'Relationship (Husband, Wife, etc)' },
  { value: 'country', label: 'Country' },
  { value: 'nationality', label: 'Nationality' },
  { value: 'minimumAgeRestriction', label: 'Minimum Age (Are you over 18?)' },
  { value: 'workPermitRestriction', label: 'Work Permit (Are you eligible to work in the UK?)' },
];

function PresetDropdownSelect({ value, onChange, disabled }) {
  return (
    <Select
      isDisabled={disabled}
      onChange={({ value: val }) => {
        if (val === 'minimumAgeRestriction' || val === 'workPermitRestriction') {
          onChange(val, { id: val });
        } else onChange(val);
      }}
      options={options}
      value={options.find(({ value: val }) => val === value)}
    />
  );
}

PresetDropdownSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

PresetDropdownSelect.defaultProps = {
  value: '',
  onChange: () => {},
  disabled: false,
};

export default PresetDropdownSelect;
