export const notOptionalFields = {
  applicationForm: {
    givenName: true,
    familyName: true,
    // emailAddress: true,
    emailAddressReg: true,
    mobilePhone: true,
    termsAndCond: true,
    // doRegister: false,
    // password: false,
    utmSource: false,
    utmCampaign: false,
    utmMedium: false,
  },
  employmentHistory: {
    jobTitle: true,
    employer: true,
    dates: true,
    // dateTo: true,
  },
  reference1: {
    firstNameR1: true,
    surnameR1: true,
    companyR1: true,
    positionR1: true,
    relationR1: true,
    emailR1: true,
    phoneR1: true,
  },
  reference2: {
    firstNameR2: true,
    surnameR2: true,
    companyR2: true,
    positionR2: true,
    relationR2: true,
    emailR2: true,
    phoneR2: true,
  },
};

export const optionalOnlyFields = {
  employmentHistory: ['currentRole'],
};

export const fieldNotes = {
  employmentHistory: {
    // dateTo: 'Not required for current role',
  },
};

export const notOptionalSections = ['applicationForm'];

export const defaultApplicationSection = [
  {
    id: 'applicationForm',
    name: 'Personal Details',
    questions: [
      {
        config: {},
        id: 'givenName',
        type: 'string',
        text: 'First Name',
        minLength: 1,
        maxLength: 50,
        required: true,
      },
      {
        config: {},
        id: 'familyName',
        type: 'string',
        text: 'Surname',
        minLength: 1,
        maxLength: 50,
        required: true,
      },
      {
        config: {},
        id: 'emailAddressReg',
        format: 'email',
        text: 'Email Address',
        type: 'string',
        required: true,
      },
      {
        config: {},
        id: 'mobilePhone',
        type: 'string',
        text: 'Phone',
        minLength: 8,
        maxLength: 15,
        required: true,
      },
      {
        config: {},
        text: 'Upload a CV',
        id: 'cvUpload',
        type: 'fileUpload',
        required: true,
      },
    ],
  },
];

export const formFields = {
  applicationForm: {
    givenName: {
      type: 'string',
      title: 'First Name',
      minLength: 1,
      maxLength: 50,
    },
    familyName: {
      type: 'string',
      title: 'Surname',
      minLength: 1,
      maxLength: 50,
    },
    // emailAddress: {
    //   type: 'string',
    //   title: 'Email Address',
    //   format: 'email',
    // },
    emailAddressReg: {
      type: 'object',
      title: 'Email Address',
      required: ['emailAddress'],
      properties: {
        emailAddress: {
          type: 'string',
          title: 'Email Address',
          format: 'email',
        },
        password: {
          type: 'string',
        },
      },
    },
    postalAddress: {
      type: 'object',
      title: 'Address',
      properties: {
        address: {
          type: 'string',
          formattedAddress: {
            type: 'string',
          },
        },
      },
    },
    mobilePhone: {
      type: 'string',
      title: 'Phone Number',
      minLength: 6,
      maxLength: 20,
    },
    cvUpload: {
      type: 'object',
      title: 'Upload your CV',
      format: 'data-url',
      attachmentType: 'CV',
    },
    coverLetter: {
      type: 'string',
      title: 'Your cover letter',
    },
    termsAndCond: {
      type: 'boolean',
      title: 'Do you agree with the terms and conditions?',
    },
    // doRegister: {
    //   type: 'boolean',
    //   title: 'Do you want to register?',
    // },
    // password: {
    //   type: 'string',
    //   title: 'Enter a password',
    // },
    utmSource: {
      type: 'string',
      title: 'UTM Source',
    },
    utmCampaign: {
      type: 'string',
      title: 'UTM Campaign',
    },
    utmMedium: {
      type: 'string',
      title: 'UTM Medium',
    },
  },
  employmentHistory: {
    jobTitle: {
      type: 'string',
      title: 'Job Title',
    },
    employer: {
      type: 'string',
      title: 'Employer',
    },
    employerAddress: {
      type: 'object',
      title: 'Employer Address',
      properties: {
        address: {
          type: 'string',
          formattedAddress: {
            type: 'string',
          },
        },
      },
    },
    dates: {
      type: 'object',
      title: 'Dates',
      properties: {
        currentRole: {
          type: 'boolean',
          title: 'I currently work here',
        },
        dateFrom: {
          type: 'string',
          title: 'Date From',
        },
        dateTo: {
          type: 'string',
          title: 'Date To',
        },
      },
    },
    mainDuties: {
      type: 'string',
      title: 'Main duties and responsibilities',
    },
    reasonForLeaving: {
      type: 'string',
      title: 'Reason for leaving',
    },
  },
  reference1: {
    firstNameR1: {
      type: 'string',
      title: 'First Name',
    },
    surnameR1: {
      type: 'string',
      title: 'Surname',
    },
    companyR1: {
      type: 'string',
      title: 'Company',
    },
    positionR1: {
      type: 'string',
      title: 'Position',
    },
    relationR1: {
      type: 'string',
      title: 'Relation to applicant',
    },
    emailR1: {
      type: 'string',
      title: 'Email Address',
      format: 'email',
    },
    phoneR1: {
      type: 'string',
      title: 'Phone Number',
    },
  },
  reference2: {
    firstNameR2: {
      type: 'string',
      title: 'First Name',
    },
    surnameR2: {
      type: 'string',
      title: 'Surname',
    },
    companyR2: {
      type: 'string',
      title: 'Company',
    },
    positionR2: {
      type: 'string',
      title: 'Position',
    },
    relationR2: {
      type: 'string',
      title: 'Relation to applicant',
    },
    emailR2: {
      type: 'string',
      title: 'Email Address',
      format: 'email',
    },
    phoneR2: {
      type: 'string',
      title: 'Phone Number',
    },
  },
};

export const formSections = {
  applicationForm: {
    type: 'object',
    title: 'Personal Details',
    required: [
      'givenName',
      'familyName',
      // 'emailAddress',
      'emailAddressReg',
      'mobilePhone',
      // 'password',
      'termsAndCond',
    ],
    properties: { ...formFields.applicationForm },
  },
  employmentHistory: {
    type: 'array',
    title: 'Employment History',
    items: {
      type: 'object',
      required: ['jobTitle', 'employer', 'dates'],
      properties: { ...formFields.employmentHistory },
    },
  },
  reference1: {
    type: 'object',
    title: 'Provide a referee',
    required: ['firstNameR1', 'surnameR1', 'companyR1', 'positionR1', 'relationR1', 'emailR1', 'phoneR1'],
    properties: { ...formFields.reference1 },
  },
  reference2: {
    type: 'object',
    title: 'Provide another referee',
    required: ['firstNameR2', 'surnameR2', 'companyR2', 'positionR2', 'relationR2', 'emailR2', 'phoneR2'],
    properties: { ...formFields.reference2 },
  },
};

export const fieldProps = {
  applicationForm: {
    emailAddressReg: {
      'ui:field': 'emailRegistration',
      'ui:options': { jobSeekerActive: true },
    },
    mobilePhone: {
      'ui:widget': 'phoneNumber',
      'ui:options': { countryCode: 'GB' },
    },
    postalAddress: {
      'ui:field': 'addressLookup',
    },
    cvUpload: {
      'ui:field': 'fileUpload',
    },
    coverLetter: {
      'ui:widget': 'textarea',
      'ui:help': 'Enter or paste your own cover letter here...',
      'ui:options': { rows: 5 },
    },
    // password: {
    //   'ui:widget': 'password',
    // },
    utmSource: {
      'ui:widget': 'hidden',
    },
    utmCampaign: {
      'ui:widget': 'hidden',
    },
    utmMedium: {
      'ui:widget': 'hidden',
    },
  },
  employmentHistory: {
    employerAddress: {
      'ui:field': 'addressLookup',
    },
    dates: {
      'ui:field': 'toggleDateRange',
      dateFrom: {
        'ui:widget': 'dateWidget',
        'ui:options': { limitToPastDates: true },
      },
      dateTo: {
        'ui:widget': 'dateWidget',
        'ui:options': { limitToPastDates: true },
      },
    },
    mainDuties: {
      'ui:widget': 'textarea',
      'ui:options': { rows: 5 },
    },
    reasonForLeaving: {
      'ui:widget': 'textarea',
      'ui:options': { rows: 5 },
    },
  },
};

export const formSchema = {
  properties: {
    applicationForm: {
      properties: {
        cid: {
          type: 'string',
        },
        iid: {
          type: 'string',
        },
        rid: {
          type: 'string',
        },
        givenName: {
          type: 'string',
          title: 'First Name',
          minLength: 1,
          maxLength: 50,
        },
        familyName: {
          type: 'string',
          title: 'Surname',
          minLength: 1,
          maxLength: 50,
        },
        emailAddressReg: {
          type: 'string',
          format: 'email',
          title: 'Email Address',
          properties: {
            emailAddress: {
              type: 'string',
              title: 'Email Address',
              format: 'email',
            },
            password: {
              type: 'string',
            },
          },
        },
        mobilePhone: {
          type: 'string',
          title: 'Phone',
          minLength: 6,
          maxLength: 20,
        },
        cvUpload: {
          attachmentType: 'CV',
          format: 'data-url',
          type: 'object',
          title: 'Upload a CV',
        },
      },
      required: ['givenName', 'familyName', 'emailAddressReg', 'mobilePhone', 'cvUpload'],
      title: 'Personal Details',
      type: 'object',
    },
  },
  required: ['applicationForm'],
  title: 'Application form',
  type: 'object',
};

export const uiSchema = {
  'ui:order': ['applicationForm'],
  applicationForm: {
    'ui:order': ['cid', 'rid', 'iid', 'givenName', 'familyName', 'emailAddressReg', 'mobilePhone', 'cvUpload'],
    cid: {
      'ui:widget': 'hidden',
    },
    iid: {
      'ui:widget': 'hidden',
    },
    rid: {
      'ui:widget': 'hidden',
    },
    cvUpload: {
      'ui:field': 'fileUpload',
    },
    emailAddressReg: {
      'ui:field': 'emailRegistration',
      'ui:options': {
        jobSeekerActive: false,
      },
      title: 'Email Address',
    },
    mobilePhone: {
      'ui:widget': 'phoneNumber',
      title: 'Phone Number',
      'ui:options': {
        countryCode: 'GB',
      },
    },
    cvUpload: {
      'ui:field': 'fileUpload',
      title: 'Upload your CV',
    },
  },
};

export const defaultSchema = {
  formName: 'Application Form',
  rules: [],
  schema: formSchema,
  uiSchema,
};

export function updateToApplicationFormat(schemas) {
  const updateSchema = {
    ...schemas,
    uiSchema: { ...schemas.uiSchema, applicationForm: defaultSchema.uiSchema.applicationForm },
  };

  if (updateSchema.schema.properties.applicationForm.properties.cvUpload) {
    updateSchema.schema.properties.applicationForm.properties.cvUpload = {
      ...updateSchema.schema.properties.applicationForm.properties.cvUpload,
      attachmentType: 'CV',
    };
  } else {
    delete updateSchema.uiSchema.applicationForm.cvUpload;
  }

  if (
    schemas.uiSchema.applicationForm.mobilePhone &&
    schemas.uiSchema.applicationForm.mobilePhone['ui:options']?.countryCode
  ) {
    updateSchema.uiSchema.applicationForm.mobilePhone['ui:options'] =
      schemas.uiSchema.applicationForm.mobilePhone['ui:options'];
  }

  updateSchema.schema.properties.applicationForm.properties.emailAddressReg = {
    ...schemas.schema.properties.applicationForm.properties.emailAddressReg,
    format: 'email',
    properties: {
      emailAddress: {
        format: 'email',
        title: 'Email Address',
        type: 'string',
      },
      password: {
        type: 'string',
      },
    },
  };

  return {
    formName: updateSchema.schema.title,
    rules: updateSchema.rules,
    schema: updateSchema.schema,
    uiSchema: updateSchema.uiSchema,
  };
}

export const formRules = [
  // {
  //   conditions: {
  //     'applicationForm.doRegister': {
  //       not: {
  //         equal: true,
  //       },
  //     },
  //   },
  //   event: {
  //     type: 'remove',
  //     params: {
  //       field: 'applicationForm.password',
  //     },
  //   },
  // },
];
