import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function ModalButton({
  children,
  onClick,
  color,
  disabled,
  className,
}) {
  return (
    <Button
      color={color}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {children}
    </Button>
  );
}

ModalButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ModalButton.defaultProps = {
  onClick: () => { },
  color: 'primary',
  disabled: false,
  className: null,
};

export default ModalButton;
