/* eslint-disable no-bitwise */
const interpolateColor = (start, end, factor) => {
  return Math.round(start + (end - start) * factor);
};

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

function getAltColorForContrast(rgb, lightColour = 'white', darkColour = '#4b4949') {
  const [r, g, b] = rgb.map((val) => val / 255); // Convert to [0, 1] range

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 0.5 ? darkColour : lightColour;
}

export { interpolateColor, hexToRgb, getAltColorForContrast };
