/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { SimpleRadios } from '../../Base/Forms/Custom/RadioBtns';
import { EmailForm } from '../email';
import Select from 'react-select';

/**
 * This modal is concerned with confirming the reason a candidate is being rejected.
 * Please note it supports both the "classic" and "new" ways to define rejection reasons.
 *
 * The classic approach exposes a hardcoded set of radio buttons for rejection reasons, and an option to enter
 * a free-text reason for rejection.
 *
 * The new approach uses a set of "rejection reason" entities exposed by the backend. These rejection reasons
 * have an ID associated with them and can be defined by a user in settings.
 *
 * The new approach is used whenever a user has defined rejection reasons in settings.
 */
export default function ConfirmRejection({
  onChange,
  applicants,
  rejectionReasons,
  invalidReason,
  invalidEmail,
  confirmSubmit,
}) {
  const [showReason, setShowReason] = useState(false);
  const [reasonText, setReasonText] = useState('');
  const [radioOption, setRadioOption] = useState();
  // Some tenants may not have any set up, will use predefined radio options
  const isUsingRejectionReasons = rejectionReasons && rejectionReasons.length > 0;

  // TODO: turn these into useReducer
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailData, setEmailData] = useState();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [candidateIds, setCandidateIds] = useState([]);

  const classApproachRadios = [
    { id: 'unsuitable', label: 'Unsuitable for position', value: 'Unsuitable' },
    { id: 'withdrawn', label: 'Withdrawn', value: 'Withdrawn' },
    { id: 'other', label: 'Other', value: 'Other' },
  ];

  /**
   * Extract the list of candidate IDs on load.
   */
  useEffect(() => {
    setCandidateIds(
      applicants.map(({ applicantId }) => applicantId)
    );
  },[applicants])

  /**
   * Handle the legacy approach of selecting rejection reasons by radio button.
   * This approach is used when no custom rejection reasons are set in settings.
   */
  function handleClassicApproachChange(radVal, text, emailEnabled, emailObj, emailValid) {
    if (radVal === 'Other') {
      setShowReason(true);
      onChange(`Other${text.length ? ` - ${text}` : ''}`, emailEnabled, emailObj, emailValid);
    } else {
      setShowReason(false);
      onChange(radVal, emailEnabled, emailObj, emailValid);
    }
  }

  /**
   * Handle the new approach of using custom rejection reasons set in settings.
   *
   * N.B - There is a quirk of the email object in that what's persisted in emailData is not what
   * the provided onChange function is expecting, so when it's present this method normalises
   * emailData before calling onChange.
   */
  function handleCustomRejectionReasonsApproach(rejectionReason, emailEnabled, emailObj, emailValid) {
    if(emailEnabled && emailObj) {
      const { id, to, message, ...rest } = emailObj;
      const normalisedEmailObj = {
        ...rest,
        candidateIds,
        htmlContent: message,
      }
      onChange(rejectionReason, emailEnabled, normalisedEmailObj, emailValid);
    } else {
      onChange(rejectionReason, emailEnabled, emailObj, emailValid);
    }

  }

  return (
    <>
      <div className="rejection-confirmation-radios">
        <FormGroup check={!isUsingRejectionReasons} inline>
          {isUsingRejectionReasons ? (
            <Select
              menuPosition="fixed"
              id="rejection-reason-select"
              classNamePrefix="react-select"
              className="w-50"
              options={rejectionReasons?.map(({ id, name }) => ({ value: id, label: name }))}
              value={rejectionReason}
              placeholder="Please select a reason"
              onChange={(val) => {
                setRejectionReason(val);
                handleCustomRejectionReasonsApproach(val.label, showEmailForm, emailData, isEmailValid);
              }}
            />
          ) : (
            <>
              {classApproachRadios.map((radio) => (
                <SimpleRadios
                  key={radio.id}
                  {...radio}
                  checked={radioOption === radio.value}
                  name="reason"
                  onChange={(val) => {
                    setRadioOption(val);
                    handleClassicApproachChange(val, reasonText, showEmailForm, emailData, isEmailValid);
                  }}
                />
              ))}
            </>
          )}
        </FormGroup>
        <div className="invalid-feedback" style={{ display: invalidReason ? 'block' : 'none' }}>
          Rejection reason required
        </div>
      </div>
      {showReason && (
        <>
          <Input
            className="mt-3"
            id="other-reason"
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              const finalVal = value.length > 40 ? value.substr(0, 40) : value;
              setReasonText(finalVal);
              handleClassicApproachChange(radioOption, finalVal, showEmailForm, emailData, isEmailValid);
            }}
            placeholder="Please provide a reason"
            type="text"
            value={reasonText}
          />
          <p>Max 40 characters</p>
        </>
      )}
      <div className="rejection-confirmation-email-checkbox">
        <FormGroup check className="ps-0" inline>
          <Label check className="d-flex">
            <Input
              checked={showEmailForm}
              className="standard-checkbox small mt-3 ms-0"
              onChange={(e) => {
                const {
                  target: { checked },
                } = e;
                setShowEmailForm(checked);
                if (!checked) {
                  setEmailData();
                  setIsEmailValid(false);
                }
                if(isUsingRejectionReasons) {
                  handleCustomRejectionReasonsApproach(rejectionReason?.label, showEmailForm, emailData, isEmailValid);
                } else {
                  handleClassicApproachChange(radioOption, reasonText, checked);
                }

              }}
              type="checkbox"
            />
            <span className="bottom-0 mt-3 ms-1">Send rejection email</span>
          </Label>
        </FormGroup>
      </div>
      {showEmailForm && (
        <>
          <h5 className="mt-3">Rejection Email</h5>
          <div className="invalid-feedback" style={{ display: invalidEmail ? 'block' : 'none' }}>
            Email form has missing required fields
          </div>
          <EmailForm
            actionType="REJECTION"
            applicants={applicants}
            data={emailData}
            externalSend
            externalSubmitted={confirmSubmit}
            onChange={(formData, validity) => {
              setIsEmailValid(validity.isValid);
              setEmailData(formData);
              if(isUsingRejectionReasons) {
                handleCustomRejectionReasonsApproach(
                  rejectionReason.label,
                  showEmailForm,
                  formData,
                  validity.isValid
                );
              } else {
                const { id, to, submitted, message, ...rest } = formData;
                handleClassicApproachChange(
                  radioOption,
                  reasonText,
                  showEmailForm,
                  {
                    ...rest,
                    candidateIds,
                    htmlContent: message,
                  },
                  validity.isValid,
                );
              }
            }}
            wrapperClassName="mt-3"
          />
        </>
      )}
    </>
  );
}

ConfirmRejection.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      applicantEmail: PropTypes.string,
      applicantId: PropTypes.string,
      applicantName: PropTypes.string,
    }),
  ),
  rejectionReasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  confirmSubmit: PropTypes.bool,
  invalidEmail: PropTypes.bool,
  invalidReason: PropTypes.bool,
  onChange: PropTypes.func,
};

ConfirmRejection.defaultProps = {
  applicants: [],
  confirmSubmit: false,
  invalidEmail: false,
  invalidReason: false,
  onChange: () => {},
};
