import React from 'react';
import PropTypes from 'prop-types';
import FormsSelector from '../../../../../../../Applications/Other/FormsSelector';
import { IconSVG } from '../../../../../../../Base/SVG';

function SelectFormInput({ option, setOption, type }) {
  return (
    <>
      <FormsSelector
        label={type === 'REQUEST_EDOC' ? 'Select Document' : 'Select Form'}
        onChange={(vals, values) => {
          const docIds = {};

          const mappedAllValues = values.map((val) => {
            return { id: val.value, label: val.label, value: val.value };
          });

          mappedAllValues.forEach((val) => {
            docIds[val.id] = val.label;
          });
          setOption({ docIds: docIds });
        }}
        selectedValues={
          option?.docIds
            ? Object.entries(option.docIds).map(([value, label]) => {
                return { id: value, label, value };
              })
            : []
        }
        type={type === 'REQUEST_EDOC' ? 'EDOC' : 'GENERIC'}
      />
      {type === 'REQUEST_EDOC' && (
        <p style={{ color: 'rgba(5,39,119,0.73)' }}>
          <IconSVG name="Info Circle" />
          <span style={{ marginLeft: '5px' }}>
            Only documents with standard placeholders can be automated. If any documents contain custom placeholders the
            workflow will not be executed.
          </span>
        </p>
      )}
    </>
  );
}

SelectFormInput.propTypes = {
  option: PropTypes.shape(),
  setOption: PropTypes.func,
};

SelectFormInput.defaultProps = {
  option: {},
  setOption: () => {},
};

export default SelectFormInput;
