import PropTypes from 'prop-types';
import CVPreview from './CVPreview';
import { uploadUtils } from '../../../Base/Forms/Custom/FileUpload';
import AttachmentDownload from './AttachmentDownload';

export default function Attachment({
  data,
  applicantId,
  // isTouch,
  titleWidth,
  title,
  btnLabel,
  touchTitleColumnSize,
}) {
  if (!data || !data.fileName || !applicantId) return null;
  const fileExt = uploadUtils.fileExtension(data.fileName);

  if (fileExt === 'pdf' || data.attachmentType === 'CV') {
    return (
      <CVPreview
        btnLabel={btnLabel}
        file={data}
        title={title}
        titleWidth={titleWidth}
        touchTitleColumnSize={touchTitleColumnSize}
      />
    );
  }

  return (
    <AttachmentDownload
      btnLabel="Download"
      data={data}
      title={title}
      titleWidth={titleWidth}
      touchTitleColumnSize={touchTitleColumnSize}
    />
  );
}

Attachment.propTypes = {
  applicantId: PropTypes.string,
  btnLabel: PropTypes.string,
  data: PropTypes.shape({
    content: PropTypes.shape({
      contentType: PropTypes.string,
      fileName: PropTypes.string,
    }),
    fileName: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    attachmentType: PropTypes.string,
  }),
  title: PropTypes.string,
  // isTouch: PropTypes.bool,
  titleWidth: PropTypes.number,
  touchTitleColumnSize: PropTypes.number,
};

Attachment.defaultProps = {
  applicantId: null,
  btnLabel: null,
  data: {},
  title: null,
  // isTouch: false,
  titleWidth: 3,
  touchTitleColumnSize: 4,
};
