import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { s4 },
} = config;

export async function linkAccount(login, password, organisationId, advancedSettings = {}) {
  if (login && password && organisationId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${s4.userPath}`,
      {
        login,
        password,
        organisationId,
        ...advancedSettings,
      },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function unlinkAccount() {
  const result = await axios.delete(`${api.middlewareAPIURL}${s4.userPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function updateLinkedAccount(login, password, organisationId, advancedSettings = {}) {
  if (login && password && organisationId) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${s4.userPath}`,
      {
        login,
        password,
        organisationId,
        ...advancedSettings,
      },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getLinkedAccount() {
  const result = await axios.get(`${api.middlewareAPIURL}${s4.userPath}`, { headers: { ...getCommonHeaders() } });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function updateSiteMapping(sites = {}) {
  if (Object.keys(sites).length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${s4.siteMapPath}`,
      { ...sites },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getSiteMapping() {
  const result = await axios.get(`${api.middlewareAPIURL}${s4.siteMapPath}`, { headers: { ...getCommonHeaders() } });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getGlobalConfig() {
  const result = await axios.get(`${api.middlewareAPIURL}${s4.globalConfigPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSiteLevelPositions(applicantAccountId) {
  let url = `${api.middlewareAPIURL}${s4.positionsPath}`;

  if (applicantAccountId) {
    url = `${api.middlewareAPIURL}${s4.positionsPath}/${applicantAccountId}`;
  }

  const result = await axios.get(url, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSiteLevelPayTypes(applicantAccountId) {
  let url = `${api.middlewareAPIURL}${s4.payTypePath}`;

  if (applicantAccountId) {
    url = `${api.middlewareAPIURL}${s4.payTypePath}/${applicantAccountId}`;
  }

  const result = await axios.get(url, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function submitToS4(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${s4.submitPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function checkS4Submission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${s4.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
