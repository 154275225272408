import config from '../../config/config';

const { env } = config;

export function addScript(url, options = {}) {
  const { id, dataset = {}, callback } = options;

  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  if (id) script.id = id;

  Object.entries(dataset).forEach(([key, val]) => {
    script.dataset[key] = val;
  });

  document.body.appendChild(script);

  if (callback) callback(script);

  return script;
}

export function addZendesk() {
  if (env === 'disabled') {
    addScript(
      'https://static.zdassets.com/ekr/snippet.js?key=eb65ad6a-491c-4cb2-b966-a3712ebb2f6b',
      { id: 'ze-snippet' },
    );
  }
}
