/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const multiPaths = [
  { fill: '#b6e786', d: 'M45.56,31.78l-.25.08a20.19,20.19,0,0,0-9.82,30.72c.16.23.33.46.51.68a119.7,119.7,0,0,0-12.55,15L0,41.83v-.14c.14-.31.29-.62.45-.92l.42-.83a4.83,4.83,0,0,1,.36-.67c.21-.38.52-.9.91-1.52.09-.15.18-.3.28-.44L3,36.42c.13-.2.26-.39.4-.58A36.9,36.9,0,0,1,7.85,30.5c.17-.18.35-.36.54-.53.94-.91,1.89-1.74,2.85-2.5l.21.3a17.55,17.55,0,0,0,28.56,0l.41-.6.07.06C42.26,28.68,44,30.23,45.56,31.78Z' },
  { fill: '#4fe1c2', d: 'M43.29,17.56a17.47,17.47,0,0,1-2.87,9.61l-.41.6a17.55,17.55,0,0,1-28.56,0l-.21-.3a17.56,17.56,0,1,1,32.05-9.91Z' },
  { fill: '#4fe1c2', d: 'M103.28,41.69v.14L79.83,78.3a119.7,119.7,0,0,0-12.55-15c.18-.22.35-.45.51-.68A20.19,20.19,0,0,0,58,31.86l-.25-.08c1.59-1.55,3.3-3.1,5.07-4.55l.06-.05c.14.2.27.4.41.59a17.55,17.55,0,0,0,28.56,0,3.37,3.37,0,0,0,.21-.31h0c1,.76,1.91,1.59,2.85,2.5.19.17.37.35.54.53a36.9,36.9,0,0,1,4.44,5.34c.14.19.27.38.4.58l.59.89c.1.14.19.29.28.44.39.62.7,1.14.91,1.52a4.83,4.83,0,0,1,.36.67l.42.83C103,41.07,103.14,41.38,103.28,41.69Z' },
  { fill: '#1c60a8', d: 'M79.83,78.3l-2.08,3.23L60.9,107.74H42.38L25.53,81.53,23.45,78.3A119.7,119.7,0,0,1,36,63.26a18.9,18.9,0,0,0,2.35,2.55A19.59,19.59,0,0,0,51.62,71h0a19.59,19.59,0,0,0,13.27-5.17,18.9,18.9,0,0,0,2.35-2.55A119.7,119.7,0,0,1,79.83,78.3Z' },
  { fill: '#b6e786', d: 'M95.11,17.56A17.39,17.39,0,0,1,92,27.46a3.37,3.37,0,0,1-.21.31,17.55,17.55,0,0,1-28.56,0c-.14-.19-.27-.39-.41-.59a17.56,17.56,0,1,1,32.26-9.62Z' },
  { fill: '#7c89e3', d: 'M71.5,50.9a20.14,20.14,0,0,1-3.71,11.68c-.16.23-.33.46-.51.68a18.9,18.9,0,0,1-2.35,2.55A19.59,19.59,0,0,1,51.66,71h0a19.59,19.59,0,0,1-13.27-5.17A18.9,18.9,0,0,1,36,63.26c-.18-.22-.35-.45-.51-.68a20.19,20.19,0,0,1,9.82-30.72l.25-.08.08.07a19.68,19.68,0,0,1,5.92-1h.16a19.68,19.68,0,0,1,5.92,1l.08-.07.25.08A20.08,20.08,0,0,1,71.5,50.9Z' },
];

function Globe(props) {
  const { multi, ...rest } = props;

  return (
    <BaseSVG
      {...rest}
      name="Globe"
      viewBox="0 0 1000 1000"
      d="M 50 0C 22 0 0 22 0 50C 0 50 0 950 0 950C 0 978 22 1000 50 1000C 50 1000 950 1000 950 1000C 978 1000 1000 978 1000 950C 1000 950 1000 50 1000 50C 1000 22 978 0 950 0C 950 0 50 0 50 0C 50 0 50 0 50 0M 50 25C 50 25 950 25 950 25C 964 25 975 36 975 50C 975 50 975 950 975 950C 975 964 964 975 950 975C 950 975 50 975 50 975C 36 975 25 964 25 950C 25 950 25 50 25 50C 25 36 36 25 50 25C 50 25 50 25 50 25 M 442 101C 442 101 442 101 442 101C 463 82 482 74 500 74C 518 74 537 82 558 101C 578 120 598 151 615 191C 632 229 645 274 654 323C 654 323 346 322 346 322C 355 273 368 228 385 191C 402 151 422 120 442 101M 199 199C 199 199 199 199 199 199C 251 146 316 109 386 89C 368 112 353 139 339 171C 320 215 305 267 295 322C 295 322 113 321 113 321C 134 276 163 234 199 199M 661 171C 661 171 661 171 661 171C 647 139 632 112 614 89C 684 109 749 146 801 199C 838 235 867 278 888 324C 888 324 705 323 705 323C 695 267 681 216 661 171M 74 500C 74 500 74 500 74 500C 74 456 80 413 94 371C 94 371 287 372 287 372C 282 413 279 456 279 500C 279 548 283 595 289 640C 289 640 97 640 97 640C 82 595 74 548 74 500M 329 500C 329 500 329 500 329 500C 329 456 332 413 338 372C 338 372 662 373 662 373C 668 414 671 457 671 500C 671 548 667 595 661 640C 661 640 339 640 339 640C 333 595 329 548 329 500M 721 500C 721 500 721 500 721 500C 721 457 718 414 713 373C 713 373 907 374 907 374C 920 414 926 457 926 500C 926 548 918 595 903 640C 903 640 711 640 711 640C 717 595 721 548 721 500M 199 801C 199 801 199 801 199 801C 165 768 138 731 118 690C 118 690 297 690 297 690C 307 741 321 788 339 829C 353 861 368 888 386 911C 316 891 251 854 199 801M 385 809C 385 809 385 809 385 809C 370 775 357 734 348 690C 348 690 652 690 652 690C 643 734 630 775 615 809C 598 849 578 880 558 899C 537 918 518 926 500 926C 482 926 463 918 442 899C 422 880 402 849 385 809M 661 829C 661 829 661 829 661 829C 679 788 693 741 703 690C 703 690 882 690 882 690C 861 731 834 769 801 801C 749 854 684 891 614 911C 632 888 647 861 661 829"
      paths={multi ? multiPaths : []}
    />
  );
}

Globe.propTypes = {
  multi: PropTypes.bool,
};

Globe.defaultProps = {
  multi: false,
};

export default Globe;
