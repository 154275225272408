import React, { memo } from 'react';
import PropTypes from 'prop-types';

function StageLabel({
  children,
  title,
  color,
  maxWidth,
  className,
}) {
  return (
    <div
      className={`stage-label ${className || ''}`}
      title={typeof children === 'string' ? children : (title || '')}
      style={{ color, maxWidth }}
    >
      {children}
    </div>
  );
}

StageLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
};

StageLabel.defaultProps = {
  children: null,
  title: null,
  color: null,
  maxWidth: null,
  className: null,
};

export default memo(StageLabel);
