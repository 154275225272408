import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const validQuestionTypes = [
  'text',
  'textarea',
  'phoneNumber',
  'multipleChoice',
  'dropdown',
  'dateOfBirth',
  'ukPostcode',
  'fileUpload',
];

function ChatBotCompabilityIcon({ questionType }) {
  const isChatBotCompatible = validQuestionTypes.includes(questionType);

  if (isChatBotCompatible) {
    return null;
  }

  return (
    <Tippy content="Chatbot compatible question type">
      <Badge color="danger" className="my-auto ml-3 p-1">
        Chatbot Incompatible
      </Badge>
    </Tippy>
  );
}

ChatBotCompabilityIcon.propTypes = {
  questionType: PropTypes.string,
};

ChatBotCompabilityIcon.defaultProps = {
  questionType: '',
};

export default ChatBotCompabilityIcon;
