// User
export const USER_DETAILS_UPDATE = 'USER_DETAILS_UPDATE';
export const USER_SETTINGS_UPDATE = 'USER_SETTINGS_UPDATE';
export const USER_AUTH_UPDATE = 'USER_AUTH_UPDATE';
export const SET_USER_AUTH_ERROR = 'SET_USER_AUTH_ERROR';
export const INCREMENT_FAILED_AUTH_COUNT = 'INCREMENT_FAILED_AUTH_COUNT';
export const CLEAR_FAILED_AUTH_COUNT = 'CLEAR_FAILED_AUTH_COUNT';
export const USER_ROLES_DETAILS_UPDATE = 'USER_ROLES_DETAILS_UPDATE';
export const USER_RESET_STATE = 'USER_RESET_STATE';
export const UPDATE_ACTIVE_ACCOUNT = 'UPDATE_ACTIVE_ACCOUNT';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_LANGUAGE = 'UPDATE_LANGAGE';

// Company
export const COMPANY_DETAILS_UPDATE = 'COMPANY_DETAILS_UPDATE';
export const COMPANY_RESET_STATE = 'COMPANY_RESET_STATE';

// Jobboard Publishing
export const ADD_PUBLISHING_DATA = 'ADD_PUBLISHING_DATA';
export const GET_PUBLISHED_DATA = 'GET_PUBLISHED_DATA';
export const RESET_PUBLISH_STATE = 'RESET_PUBLISH_STATE';

// JOB SITE INFO
export const ADD_JOB_SITE_CONFIG = 'ADD_JOB_SITE_CONFIG';
export const REMOVE_JOB_SITE_CONFIG = 'REMOVE_JOB_SITE_CONFIG';

// FUNNEL
export const SET_FUNNEL_STAGES = 'SET_FUNNEL_STAGES';
export const DELETE_FUNNEL_STAGES = 'DELETE_FUNNEL_STAGES';

// SEARCH
export const ADD_SEARCHTERM = 'ADD_SEARCHTERM';
export const REMOVE_SEARCHTERM = 'REMOVE_SEARCHTERM';
export const SET_SEARCH_FIELDS = 'SET_SEARCH_FIELDS';
export const RESET_SEARCH_STATE = 'RESET_SEARCH_STATE';
export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_AI_SEARCH_ERROR = 'SET_AI_SEARCH_ERROR';
export const ADD_SEARCHTERM_ON_ERROR = 'ADD_SEARCHTERM_ON_ERROR';

// Filters
export const ADD_ACTIVE_FILTER = 'ADD_ACTIVE_FILTER';
export const UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER';
export const RESET_FILTER_STATE = 'RESET_FILTER_STATE';
export const DELETE_ACTIVE_FILTER = 'DELETE_ACTIVE_FILTER';
export const BULK_ADD_FILTERS = 'BULK_ADD_FILTERS';

// Linked Field
export const ADD_FIELD_DATA = 'ADD_FIELD_DATA';
// export const UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER';
// export const RESET_FILTER_STATE = 'RESET_FILTER_STATE';
// export const DELETE_ACTIVE_FILTER = 'DELETE_ACTIVE_FILTER';

// Transcription request
export const ADD_TRANSCRIPTION_REQUEST = 'ADD_TRANSCRIPTION_REQUEST';

// Reporting
export const SET_ACTIVE_REPORTING_FILTER = 'ADD_ACTIVE_REPORTING_FILTER';
export const UPDATE_ACTIVE_REPORTING_FILTER = 'UPDATE_ACTIVE_REPORTING_FILTER';
export const RESET_FILTER_REPORTING_STATE = 'RESET_REPORTING_FILTER_STATE';
export const DELETE_ACTIVE_REPORTING_FILTER = 'DELETE_ACTIVE_REPORTING_FILTER';
export const SET_REPORTING_DATA = 'SET_REPORTING_DATA';
export const RESET_REPORTING_DATA = 'RESET_REPORTING_DATA';
export const DELETE_REPORTING_DATA = 'DELETE_REPORTING_DATA';

// Vacancy Actions
export const ADD_VACANCY = 'ADD_VACANCY';
export const UPDATE_VACANCY = 'UPDATE_VACANCY';
export const DELETE_VACANCY = 'DELETE_VACANCY';
export const SET_VACANCY_CANDIDATE_PAGE = 'SET_VACANCY_CANDIDATE_PAGE';
export const SET_VACANCY_APPLICATION_URL = 'SET_VACANCY_APPLICATION_URL';
export const UPDATE_VACANCY_LAST_VIEWED_DT = 'UPDATE_VACANCY_LAST_VIEWED_DT';
export const RESET_ACTIVE_VACANCY_CANDIDATES = 'RESET_ACTIVE_VACANCY_CANDIDATES';
export const UPDATE_ACTIVE_VACANCY = 'UPDATE_ACTIVE_VACANCY';
export const RESET_VACANCY_STATE = 'REMOVE_ACTIVE_VACANCY';

// Application Form Actions
export const SET_ACTIVE_APPLICATION_FORM_BUILDER_DATA = 'CREATE_APPLICATION_FORM';
export const ADD_APPLICATION_FORM_DATA = 'ADD_APPLICATION_FORM_DATA';
export const GET_APPLICATION_FORM_URL = 'GET_APPLICATION_FORM_URL';
export const RESET_APPLICATION_STATE = 'RESET_APPLICATION_STATE';
export const SET_ACTIVE_APPLICATION_URL = 'SET_ACTIVE_APPLICATION_URL';

// ATS Form Actions
export const ADD_ATS_FORM = 'ADD_ATS_FORM';

// Table Prefs Actions
export const SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE';
export const SET_MODAL_TABLE_PAGE_SIZE = 'SET_MODAL_TABLE_PAGE_SIZE';

// Account Style Actions
export const SET_STYLES = 'SET_STYLES';

// Booking Candidate Selection
export const REMOVE_SLOT_BOOKING = 'REMOVE_SLOT_BOOKING';
export const ADD_SLOT_BOOKING_CANDIDATES = 'ADD_SLOT_BOOKING_CANDIDATES';
export const REMOVE_SLOT_BOOKING_CANDIDATES = 'REMOVE_SLOT_BOOKING_CANDIDATES';

// Dashboard Candidate Selection
export const ADD_DASHBOARD_CANDIDATES = 'ADD_SLOT_BOOKING_CANDIDATES';
export const REMOVE_DASHBOARD_BOOKING_CANDIDATES = 'REMOVE_DASHBOARD_BOOKING_CANDIDATES';

// Account Users
export const ADD_ACCOUNT_USERS = 'ADD_ACCOUNT_USERS';

// Notification
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const TOGGLE_NOTIFICATION_READ_STATE = 'TOGGLE_NOTIFICATION_READ_STATE';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const START_POLLING = 'START_POLLING';
export const STOP_POLLING = 'STOP_POLLING';

// Fourth Integration
export const ADD_FOURTH_USER = 'ADD_FOURTH_USER';
export const REMOVE_FOURTH_USER = 'REMOVE_FOURTH_USER';
export const ADD_FOURTH_DATA = 'ADD_FOURTH_DATA';
export const REMOVE_FOURTH_DATA = 'REMOVE_FOURTH_DATA';
export const ADD_FOURTH_INTEGRATION = 'ADD_FOURTH_INTEGRATION';
export const ADD_FOURTH_GLOBAL_CONFIG = 'ADD_FOURTH_GLOBAL_CONFIG';

// Polaris Integration
export const ADD_POLARIS_USER = 'ADD_POLARIS_USER';
export const REMOVE_POLARIS_USER = 'REMOVE_POLARIS_USER';
export const ADD_POLARIS_KEY_MAP = 'ADD_POLARIS_KEY_MAP';
export const ADD_POLARIS_GLOBAL_CONFIG = 'ADD_POLARIS_GLOBAL_CONFIG';

// S4 Integration
export const ADD_S4_USER = 'ADD_S4_USER';
export const REMOVE_S4_USER = 'REMOVE_S4_USER';
export const ADD_S4_GLOBAL_CONFIG = 'ADD_S4_GLOBAL_CONFIG';
export const ADD_S4_SITE_MAP = 'ADD_S4_SITE_MAP';

// SameSystem Integration
export const ADD_SAMESYSTEM_USER = 'ADD_SAMESYSTEM_USER';
export const REMOVE_SAMESYSTEM_USER = 'REMOVE_SAMESYSTEM_USER';
export const ADD_SAMESYSTEM_GLOBAL_CONFIG = 'ADD_SAMESYSTEM_GLOBAL_CONFIG';

// Tags
export const ADD_TAG = 'ADD_TAG';
export const DELETE_TAGS = 'DELETE_TAGS';
export const SET_ALL_TAGS = 'SET_ALL_TAGS';
export const EDIT_TAG = 'EDIT_TAG';

// Categories
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_IS_LOADING = 'SET_IS_LOADING';

// Categories
export const SET_RR_DATA = 'SET_RR_DATA';
