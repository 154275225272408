import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { DataTable, useTableState } from '../../../Base/Tables';
import {
  ClientAdminSlider,
  ClientAdminNoDataMessage,
  PageColumn,
} from '../Dashboard';
import { ActionButton } from '../../../Base/Buttons';
import { TippyTruncate } from '../../../Base/Truncate';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { deleteFunnel } from '../../../../api/CandidateAPI/FunnelManagement';
import { getAvailableFunnels } from '../../../../api/CandidateAPI/CandidateAPI';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import FunnelEditor from './FunnelEditor';
import EnhancedCard from '../Common/EnhancedCard';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import styled from 'styled-components';

async function deleteAction(id, onSuccess = () => { }, onError = () => { }) {
  if (id) {
    const resp = await retryableAPICall(() => deleteFunnel(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    }
    else {
      onSuccess();
    }
  }
}

function RestrictedCreateButton(props) {
  return null;
}

RestrictedCreateButton.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  floatRight: PropTypes.bool,
  action: PropTypes.func,
  label: PropTypes.string,
};

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0rem;
  }
`;

StyledTable.propTypes = {
  columns: PropTypes.any,
  className: PropTypes.string,
  showPagination: PropTypes.bool,
  hasSelectColumn: PropTypes.bool,
  noDataText: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  rowHeight: PropTypes.number,
};


function FunnelAdmin({
  isCreateMode,
  onClose,
  // totalAccounts,
  // activeAccountId,
  tablePrefs,
}) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const {
    tableState,
    setTableData,
    setTableRejected,
  } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [deleteObj, setDeleteObj] = useState();
  const [funnelObj, setFunnelObj] = useState({});
  const [actionMenuId, setActionMenuId] = useState('');

  const requestData = useCallback(async (callback = () => { }) => {
    const resp = await retryableAPICall(() => getAvailableFunnels());

    if (isMounted()) {
      if (typeof resp === 'string') {
        setTableRejected();
        callback(resp);
      }
      else {
        setTableData({
          data: resp,
          totalResults: resp.length,
        });

        callback();
      }
    }
  }, [isMounted, setTableData, setTableRejected]);

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      }
      else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Funnel Name',
      id: 'funnelName',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name } = original;

        if (checkPermissions(['admin:funnel:update'])) {
          return (
            <a
              href="#funnelDetails"
              onClick={(e) => {
                e.preventDefault();
                setFunnelObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Stages',
      id: 'funnelStages',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { stages = [] } = original;
        const stageNames = stages.map(({ name }) => name).join(' ➞ ');
        return <TippyTruncate>{stageNames}</TippyTruncate>;
      },
    },
  ];

  if (checkPermissions(['admin:funnel:update', 'admin:funnel:delete'])) {
    columns.push(
      {
        id: 'action',
        resizable: false,
        sortable: false,
        filterable: false,
        width: 63,
        className: 'action-cell',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          const {
            // eslint-disable-next-line react/prop-types
            id,
            // eslint-disable-next-line react/prop-types
            name,
            // eslint-disable-next-line react/prop-types
          } = original;

          const menuItems = [
            {
              id: 0,
              label: 'Edit',
              icon: 'Pencil',
              permissions: ['admin:funnel:update'],
              tooltipContent: 'You do not have permission to edit this funnel',
              action: () => {
                setFunnelObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
                setActionMenuId('');
              },
            },
            {
              id: 1,
              label: 'Delete',
              icon: 'Bin',
              permissions: ['admin:funnel:delete'],
              isDisabled: true,
              tooltipContent: 'You do not have permission to delete this funnel',
              action: () => {
                setDeleteObj({ id, name });
                setActionMenuId('');
                setShowConfirm(true);
              },
            },
          ];

          return (
            <ActionButton
              title="Action"
              position="left"
              isOpen={actionMenuId === id}
              onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
              menuItems={menuItems}
              menuStyle={{ width: '150px' }}
              subMenuProps={{
                menuStyle: { width: '100px' },
                position: 'left',
              }}
            />
          );
        },
      },
    );
  }

  return (
    <Fragment>
      <PageColumn state={pageState} className={cx('pt-3', { 'h-scroll-table': tableState.data.length })}>
        <div className="d-flex justify-content-center w-100" style={{marginLeft: '7px'}}>
          <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
            <EnhancedCardTitle
              title="Funnel Settings"
              subtitle="Create and update your recruitment flows"
            />
        {!tableState.data.length ? (
          <ClientAdminNoDataMessage
            title="You currently have no Funnels set up"
            message=""
            btnProps={{
              onClick: () => setIsSliderOpen(true),
              label: 'Create Funnel',
            }}
          />
        ) : (
          <StyledTable
            className="mt-3"
            isLoading={tableState.isLoading}
            isRejected={tableState.isRejected}
            data={tableState.data}
            columns={columns}
            rowHeight={53}
            id="funnels-admin"
            // totalPages={tableState.totalPages}
            totalResults={tableState.totalResults}
            pageSize={tableState.rowsPerPage}
            noDataText="You currently have no Funnels"
            errorText="There has been an error loading Funnels, please try again later"
            hasSelectColumn={false}
            showPagination={false}
          />
        )}
          </EnhancedCard>
        </div>
      </PageColumn>
      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Funnel`}
        isSliderOpen={isSliderOpen}
        closeSlider={handleCloseSlider}
      >
        <FunnelEditor
          isEditing={isEditing}
          data={isEditing ? funnelObj : {}}
          onSave={(fnlObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, fnlObj, fnlObj.id)
              : addObjToArray(tableState.data, fnlObj);

            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });
          }}
          onCancel={handleCloseSlider}
        />
      </ClientAdminSlider>
      <Confirmation
        show={showConfirm}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} funnel?`}
        confirmCallback={() => {
          deleteAction(deleteObj.id, () => {
            toast.success('Funnel successfully deleted');

            const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);
            setTableData({
              data: updatedTableData,
              totalResults: updatedTableData.length,
            });
          }, () => {
            toast.error('Error deleting funnel');
          });

          setShowConfirm(false);
        }}
        cancelCallback={() => {
          setShowConfirm(false);
          setDeleteObj();
        }}
      />
    </Fragment>
  );
}

FunnelAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  // totalAccounts: PropTypes.number,
  // activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

FunnelAdmin.defaultProps = {
  isCreateMode: false,
  // totalAccounts: 0,
  // activeAccountId: null,
  onClose: () => { },
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: {
          accountAccess = [],
        },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(FunnelAdmin);
