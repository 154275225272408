/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import cx from 'classnames';

const countryList = [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'IE', name: 'Ireland' },
  { code: 'DE', name: 'Germany' },
  { code: 'US', name: 'United States' },
  { code: 'DK', name: 'Denmark' },
  { code: 'FR', name: 'France' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'ES', name: 'Spain' },
  { code: 'JP', name: 'Japan' },
  { code: 'IT', name: 'Italy' },
  { code: 'KR', name: 'South Korea' },
  { code: 'XX', name: 'All' },
];

function AddressCountryDropdown({ value, onChange, disabled }) {
  return (
    <div className="country-select-dropdown">
      <FormGroup>
        <Label for="countryRestriction" className={cx('pe-2', { 'is-disabled': disabled })}>
          Country Restriction:
        </Label>
        <select
          disabled={disabled}
          id="countryRestriction"
          className="select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {countryList.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
      </FormGroup>
    </div>
  );
}

AddressCountryDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

AddressCountryDropdown.defaultProps = {
  value: '',
  onChange: () => {},
  disabled: false,
};

export default AddressCountryDropdown;
