import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '../../../Base/Buttons';
import { PDFDisplay } from '../../../Base/PDF';
import { ModalPopup } from '../../../Base/Modal';
import { uploadUtils } from '../../../Base/Forms/Custom/FileUpload';

function fileInfo(headerStr) {
  const header = headerStr.replace('data:', '');
  const [mimeType, fName] = header.split(';');

  let fileType;
  let fileName = 'File';

  switch (mimeType) {
    case 'application/pdf':
      fileType = 'pdf';
      break;
    case 'image/png':
      fileType = 'image';
      break;
    case 'image/jpg':
      fileType = 'image';
      break;
    case 'image/jpeg':
      fileType = 'image';
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    default:
      fileType = 'word';
  }

  if (fName) fileName = fName.replace('name=', '').replace(/%20/gi, ' ');

  return { fileType, fileName };
}

function Base64Files({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!data) return null;

  const [header, base64] = data.split(';base64,');
  if (!base64 || !base64.length) return null;

  const { fileType, fileName } = fileInfo(header);
  if (!fileType) return null;

  return (
    <Fragment>
      <TextButton
        floatRight={false}
        label="View File"
        className="btn-outline"
        size="sm"
        action={() => {
          setIsModalOpen(true);
        }}
      />
      <ModalPopup
        isOpen={isModalOpen}
        title={fileName}
        centerButtons
        centerContent
        cancelLabel="Close"
        hideOkayButton
        onToggle={() => setIsModalOpen(false)}
      >
        {uploadUtils.isImage(fileType) ? <img src={data} alt={fileName} /> : <PDFDisplay file={base64} isBase64 />}
      </ModalPopup>
    </Fragment>
  );
}

Base64Files.propTypes = {
  data: PropTypes.string,
};

Base64Files.defaultProps = {
  data: null,
};

export default Base64Files;
