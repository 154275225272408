import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { retryableAPICall } from '../../../api/common-api-utils';
import TextButton from '../../Base/Buttons/TextButton';
import Can from '../../Base/RBAC/Can/Can';
import config from '../../../config/config';
import { useMounted } from '../../Base/hooks';
import { HeadingPushRight } from '../../Base/Headings';
import { EditButton, ActionButton } from '../../Base/Buttons';
import { downloadCompletedReferences } from '../../../api/CandidateAPI/ReferenceAPI';
import { fetchFormId, generateToken } from './utils';
import { FormModal } from '../../Base/Modal';

const {
  web: { formsWebUrl },
} = config;
const RestrictedTextButton = Can(TextButton);
const RestrictedEditButton = Can(EditButton);

function ReferencesHeader({
  isTouch,
  languagePack,
  status,
  requestRef,
  referenceRequestId,
  referees,
  doRefresh,
  candidate,
  doRequest,
  userDetails,
  completedFormId,
}) {
  const isMounted = useMounted();
  const [reqBtnLoading, setReqBtnLoading] = useState(false);
  const [formId, setFormId] = useState();
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [referencesDownloading, setReferencesDownloading] = useState(false);
  const [refereeActionOpen, setRefereeActionOpen] = useState(false);
  const [openingModal, setOpeningModal] = useState(false);

  useEffect(() => {
    if (isMounted() && ['REFEREES_REQUESTED', 'NO_REFEREES'].includes(status)) {
      fetchFormId((id) => setFormId(id));
    }
  }, [isMounted, status]);

  const hasNoReferees = status === 'NO_REFEREES';

  let btnProps = {
    disabled: reqBtnLoading,
    permissions: ['candidate:references:create'],
  };

  if (hasNoReferees) {
    btnProps = {
      ...btnProps,
      action: async () => {
        setReqBtnLoading(true);
        await requestRef();
        setReqBtnLoading(false);
      },
      label: reqBtnLoading ? languagePack.requestRefereeInformationBtnLoad : languagePack.requestRefereeInformationBtn,
    };
  } else if (status === 'NOT_REQUESTED') {
    btnProps = {
      ...btnProps,
      action: async () => {
        setReqBtnLoading(true);
        await doRequest();
        setReqBtnLoading(false);
      },
      label: reqBtnLoading ? languagePack.requestingReferenceBtn : languagePack.requestReferenceBtn,
    };
  }

  let headingText;

  if (status) {
    if (status === 'NOT_REQUESTED') {
      headingText = languagePack.notRequestedTitle || 'References - Not Requested';
    } else if (['REQUESTED', 'REMINDER_1_SENT', 'REMINDER_2_SENT'].includes(status)) {
      headingText = languagePack.requestedTitle || 'References - Requested';
    } else if (status === 'PART_RECEIVED') {
      headingText = languagePack.partiallyReceivedTitle || 'References - Partially Received';
    } else if (status === 'RECEIVED') {
      headingText = languagePack.receivedTitle || 'References - Received';
    }
  }

  const menuItems = [
    {
      icon: 'Pencil',
      action: async () => {
        setOpeningModal(true);
        generateToken(completedFormId, (token) => {
          setOpeningModal(false);

          setModalConfig({
            isOpen: true,
            url: `${formsWebUrl}?token=${token}`,
          });
        });
      },
      id: 1,
      isHidden: !completedFormId,
      isDisabled: openingModal,
      label: languagePack.editRefereeInfoBtn,
      permissions: ['candidate:references:update'],
    },
    {
      action: async () => {
        setReferencesDownloading(true);
        await retryableAPICall(() => downloadCompletedReferences(referenceRequestId));
        setReferencesDownloading(false);
      },
      icon: 'File',
      isHidden: !referees.find((ref) => ref.completedFormId),
      id: 2,
      label: referencesDownloading ? languagePack.downloadingReferencesBtn : languagePack.downloadReferencesBtn,
      isDisabled: referencesDownloading,
      permissions: ['candidate:references:view'],
    },
  ];

  return (
    <>
      <Row>
        <Col>
          {status && (
            <HeadingPushRight headingAs="h5" headingText={headingText} isTouch={isTouch}>
              {completedFormId && !referees.find((ref) => ref.completedFormId) ? (
                <div>
                  <RestrictedEditButton
                    action={() => {
                      setOpeningModal(true);
                      generateToken(completedFormId, (token) => {
                        setOpeningModal(false);
                        setModalConfig({
                          isOpen: true,
                          url: `${formsWebUrl}?token=${token}`,
                        });
                      });
                    }}
                    className="me-1 float-end"
                    disabled={openingModal}
                    label={languagePack.editRefereeInfoBtn}
                    permissions={['candidate:references:update']}
                  />
                </div>
              ) : (
                <ActionButton
                  buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                  className="ellipsis-opts-btn"
                  iconName="Ellipsis"
                  iconOnly={false}
                  isOpen={refereeActionOpen} // Check if this referee's button is open
                  menuItems={menuItems}
                  onToggle={(isOpen) => setRefereeActionOpen(isOpen)}
                  title="Actions"
                  tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                />
              )}
              {status === 'NOT_REQUESTED' && (
                <div>
                  <RestrictedTextButton {...btnProps} className="float-end" />
                </div>
              )}
            </HeadingPushRight>
          )}
        </Col>
      </Row>
      <FormModal
        contentUrl={modalConfig.url}
        id="referee-header"
        isOpen={modalConfig.isOpen}
        toggle={(closeMethod, isOpen, frameId) => {
          if (closeMethod === 'IFRAME' && isOpen && frameId === 'referee-header') {
            doRefresh();
          }
          setModalConfig({ isOpen: false, url: '' });
        }}
        warnOnClickClose
      />
    </>
  );
}

ReferencesHeader.propTypes = {
  candidate: PropTypes.shape(),
  completedFormId: PropTypes.string,
  doRefresh: PropTypes.func,
  doRequest: PropTypes.func,
  isTouch: PropTypes.bool,
  languagePack: PropTypes.shape(),
  referees: PropTypes.arrayOf(PropTypes.shape()),
  referenceRequestId: PropTypes.string,
  requestRef: PropTypes.func,
  status: PropTypes.string,
  userDetails: PropTypes.shape(),
};

ReferencesHeader.defaultProps = {
  candidate: {},
  completedFormId: null,
  doRefresh: () => {},
  doRequest: () => {},
  isTouch: false,
  languagePack: {},
  referees: [],
  referenceRequestId: null,
  requestRef: () => {},
  status: null,
  userDetails: {},
};

export default ReferencesHeader;
