/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import { CreateButton, DeleteButton } from '../../../../../Base/Buttons';
import { SelectInput } from '../../../../../Base/Forms/Custom/CommonComponents';
import { Prompt, Confirmation } from '../../../../../Base/Modal';

function FormStylesHeader({
  isEdited,
  onOkay,
  changeTheme,
  themes,
  selectedTheme,
  isLoading,
  isLoadingIframe,
  onDeleteCancel,
  onDeleteConfirm,
  onDelete,
  isDeleting,
  isDeleteModalOpen,
}) {
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  return (
    <Row className="pb-2">
      <Col className="d-flex">
        <Tippy
          content="This form has unsaved changes, if you change style they will be lost!"
          disabled={!isEdited}
          theme="ats"
        >
          <div className="flex-grow-1">
            <SelectInput
              className={cx('flex-grow-1', { 'is-edited': isEdited })}
              disabled={themes.length === 0 || isLoading || isLoadingIframe}
              onChange={changeTheme}
              options={themes.map((a) => {
                return { label: a.name, value: a.id };
              })}
              placeholder="No styles available"
              value={selectedTheme}
            />
          </div>
        </Tippy>
        <DeleteButton
          action={() => onDelete()}
          className="ms-2"
          disabled={themes.length === 0 || isLoading}
          floatRight={false}
          iconOnly
          label="Remove form"
        />
      </Col>
      <Col>
        <CreateButton
          action={() => {
            setIsPromptOpen(true);
          }}
          disabled={isLoading}
          floatRight
          label="Create Style"
        />
      </Col>
      <Prompt
        isOpen={isPromptOpen}
        nameTitle="Style Name:"
        onCancel={() => setIsPromptOpen(false)}
        onOkay={(e) => {
          onOkay(e);
          setIsPromptOpen(false);
        }}
        title="Create new Style"
      />
      <Confirmation
        cancelCallback={() => onDeleteCancel()}
        confirmCallback={() => {
          onDeleteConfirm();
        }}
        content={
          <p>
            <strong style={{ color: 'red', fontSize: '20px' }}>Warning!</strong>
            <br />
            <br /> Deleting this form style will mean any forms associated with this style will no longer be styled
            unless they are explicitly assigned a new style from the edit form screen. It is NOT recommended to delete
            form styles unless you are certain they are not in use.
            <br />
            <br />
            You should be <strong>absolutely sure</strong> you want to perform this action before confirming.
          </p>
        }
        isLoading={isDeleting}
        show={isDeleteModalOpen}
      />
    </Row>
  );
}

FormStylesHeader.propTypes = {
  changeTheme: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingIframe: PropTypes.bool,
  isPromptOpen: PropTypes.bool,
  onClosePrompt: PropTypes.func,
  onOkay: PropTypes.func,
  onOpenPrompt: PropTypes.func,
  selectedTheme: PropTypes.string,
  themes: PropTypes.shape(),
  onDelete: PropTypes.func,
  onDeleteCancel: PropTypes.func,
  onDeleteConfirm: PropTypes.func,
  isDeleteModalOpen: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isEdited: PropTypes.bool,
};

FormStylesHeader.defaultProps = {
  changeTheme: () => {},
  onDelete: () => {},
  onDeleteCancel: () => {},
  onDeleteConfirm: () => {},
  isDeleteModalOpen: false,
  isPromptOpen: false,
  isLoading: false,
  isLoadingIframe: false,
  onClosePrompt: () => {},
  onOkay: () => {},
  onOpenPrompt: () => {},
  selectedTheme: '',
  themes: [],
  isDeleting: false,
  isEdited: false,
};

export default FormStylesHeader;
