import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonComp from './ButtonComp';

export default function CancelButton({
  className,
  action,
  label,
  isLoading,
  iconOnly,
  id,
  disabled,
  hidden,
  floatRight,
  size,
}) {
  if (hidden) return null;

  return (
    <ButtonComp
      className={cx('cancel-button', className, { 'float-end ms-2 me-2': floatRight })}
      disabled={disabled}
      id={id}
      onClick={action}
      isLoading={isLoading}
      loadingIconColor="action"
      iconName="fa-times"
      iconOnly={iconOnly}
      size={size}
    >
      {label}
    </ButtonComp>
  );
}

CancelButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  action: PropTypes.func,
  label: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  hidden: PropTypes.bool,
  iconOnly: PropTypes.bool,
  floatRight: PropTypes.bool,
  size: PropTypes.string,
};

CancelButton.defaultProps = {
  className: null,
  id: null,
  disabled: false,
  action: () => {},
  label: 'Cancel',
  isLoading: false,
  hidden: false,
  iconOnly: false,
  floatRight: true,
  size: null,
};
