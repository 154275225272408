import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { LoadingScreen } from '../../../Base/Loading';
import { ErrorMessage } from '../../../Base/ErrorScreen';

function PageColumn({ state, className, children }) {
  if (state.isPending) return <LoadingScreen isEmbeded />;

  return (
    <Col className={className}>
      {state.isRejected ? <ErrorMessage error={state.rejectionStatus} /> : children}
    </Col>
  );
}

PageColumn.propTypes = {
  state: PropTypes.shape(),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PageColumn.defaultProps = {
  state: {},
  className: null,
  children: null,
};

export default PageColumn;
