import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { Col, Row, Badge } from 'reactstrap';
import { ErrorMessage } from '../../Base/ErrorScreen';

function grade(score) {
  if (score < 30) {
    return 'bad';
  }
  if (score < 50) {
    return 'ok';
  }
  if (score < 70) {
    return 'pass';
  }
  return 'great';
}

const colours = {
  bad: '#DD2700',
  ok: '#DD9600',
  pass: '#B6DD00',
  great: '#48DD00',
};

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DISPLAY_FORMAT = 'DD-MM-YYYY HH:mm:ss';

function GaugePieChart({ score, label }) {
  return (
    <PieChart height={260} width={400}>
      <Pie
        blendStroke
        cy="70%"
        data={[{ value: score }, { value: 100 - score }]}
        dataKey="value"
        endAngle={0}
        innerRadius="55%"
        isAnimationActive={false}
        labelLine={false}
        startAngle={180}
      >
        <Label dx={0} dy={-60} value={label} />
        <Cell fill={colours[grade(score)]} />
        <Cell fill="#eaeaea" />
        <Label dx={0} dy={60} fill={colours[grade(score)]} fontSize="30" fontWeight="bold" value={score} />
      </Pie>
    </PieChart>
  );
}
GaugePieChart.propTypes = {
  score: PropTypes.string,
  label: PropTypes.string,
};

GaugePieChart.defaultProps = {
  scores: '',
  label: '',
};

function ScoreBadge({ score }) {
  if (score === '3') {
    return (
      <Badge className="align-bottom" color="success">
        H
      </Badge>
    );
  }

  if (score === '2') {
    return (
      <Badge className="align-bottom" color="warning">
        M
      </Badge>
    );
  }

  return (
    <Badge className="align-bottom" color="danger">
      L
    </Badge>
  );
}

ScoreBadge.propTypes = {
  score: PropTypes.string,
};

ScoreBadge.defaultProps = {
  score: '1',
};

function PersonalityTestCompletedContainer({ ptSummary, summary, languagePack }) {
  const recievedSummary = summary.statusHistory.find((a) => {
    return a.status === 'RECEIVED' || a.status === 'PART_RECEIVED';
  });

  return summary?.scores &&
    (summary?.scores.INTEGRITY_SCORE || summary?.scores.SERVICE_SCORE || summary?.scores.OVERALL_SCORE) ? (
    <>
      <Row className="mt-4">
        <Col style={{ maxWidth: '150px' }}>{languagePack.personalityTestReceivedOnLabel}</Col>
        <Col>
          <p>{moment.utc(recievedSummary.statusDateTime, DATE_TIME_FORMAT).local().format(DISPLAY_FORMAT)}</p>
        </Col>
      </Row>
      {summary?.scores.OVERALL_SCORE ? (
        <Row>
          <Col>
            <Row className="mt-3 fw-bolder">
              <Col>
                <span className=" me-2 fs-5">
                  Score: {languagePack[`personalityTest${summary?.scores.OVERALL_SCORE}ScoreLabel`]}{' '}
                </span>
                <ScoreBadge score={summary?.scores.OVERALL_SCORE} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="fw-bolder">{languagePack.personalityTestLinksLabel}</Col>
            </Row>
            {ptSummary?.reports?.map(({ reportURL, reportType }) => {
              return (
                <Row key={reportURL} className="mt-1">
                  <Col>
                    <a href={reportURL} target="_blank" rel="noopener noreferrer">
                      {reportType?.replace(/_/g, ' ')}
                    </a>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Row className="mt-2">
              {summary.scores && summary.scores.INTEGRITY_SCORE && (
                <Col sm="6">
                  <GaugePieChart
                    label={languagePack.personalityTestIntegrityScore}
                    score={parseInt(summary.scores.INTEGRITY_SCORE, 10)}
                  />
                </Col>
              )}
              {summary.scores && summary.scores.SERVICE_SCORE && (
                <Col sm="6">
                  <GaugePieChart
                    label={languagePack.personalityTestServiceScore}
                    score={parseInt(summary.scores.SERVICE_SCORE, 10)}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </>
  ) : (
    <ErrorMessage />
  );
}

PersonalityTestCompletedContainer.propTypes = {
  languagePack: PropTypes.shape(),
  ptSummary: PropTypes.shape(),
  summary: PropTypes.shape(),
};

PersonalityTestCompletedContainer.defaultProps = {
  languagePack: {},
  ptSummary: {},
  summary: {},
};

export default PersonalityTestCompletedContainer;
