import React, { useEffect, useReducer } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, FormGroup, Input, Row, Table } from 'reactstrap';
import { retryableAPICall } from '../../../../api/common-api-utils';
import {
  addHollwegLocationMapping,
  deleteHollwegLocationMappings,
  getHollwegLocations,
} from '../../../../api/Integrations/HollwegAPI';
import { getLocation } from '../../../../api/LocationAPI';
import { IconButton } from '../../../Base/Buttons';
import { useMounted } from '../../../Base/hooks';

function transformData(data) {
  const result = [];

  for (const key in data) {
    let value = key;

    if (key.startsWith('.')) {
      value = `-${key.slice(1)}`;
    }

    result.push({
      location: value,
      hollwegLocationId: data[key].hollwegId,
      name: data[key].name,
    });
  }

  return result;
}

const initialState = {
  isLoading: false,
  name: '',
  location: null,
  hollwegLocationId: '',
  mappings: [],
  storedlocations: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false };
    case 'SET_MAPPED_LOCATIONS': {
      let mappedPositions = [];

      if (Object.keys(action.hollwegLocations).length > 0) {
        mappedPositions = transformData(action.hollwegLocations);
      }
      const availableLoc = action.locations.reduce((memo, location) => {
        if (location?.location?.geoLocation?.coordinates[0][0]) {
          memo.push({
            ...location,
            isHidden: !!action.hollwegLocations[location?.location?.geoLocation?.coordinates[0][0]],
            label: location.name,
            location: {
              ...location.location,
              geoLocation: {
                ...location.location.geoLocation,
                coordinates: location.location.geoLocation.coordinates[0],
              },
            },
          });
        }
        return memo;
      }, []);

      return { ...state, mappings: mappedPositions, storedlocations: availableLoc };
    }
    case 'ADD_MAPPING': {
      let lat = action.payload.lat.toString();

      if (lat.startsWith('.')) {
        lat = `-${lat.slice(1)}`;
      }
      return {
        ...state,
        mappings: [...state.mappings, { name: state.name, hollwegLocationId: state.hollwegLocationId, location: lat }],
        storedlocations: state.storedlocations.reduce((acc, item) => {
          const newItem = { ...item };
          if (item.location.geoLocation.coordinates[0].toString() === lat) {
            newItem.isHidden = true;
          }
          acc.push(newItem);
          return acc;
        }, []),
        location: null,
        hollwegLocationId: '',
        name: '',
      };
    }
    case 'REMOVE_MAPPING': {
      const filteredData = state.mappings.filter(
        (item) => !(item.hollwegLocationId === action.data.id && item.name === action.data.name),
      );
      return {
        ...state,
        mappings: filteredData,
        storedlocations: state.storedlocations.reduce((acc, item) => {
          const newItem = { ...item };
          if (item.location.geoLocation.coordinates[0] === action.data.location.location.geoLocation.coordinates[0]) {
            newItem.isHidden = false;
          }
          acc.push(newItem);
          return acc;
        }, []),
      };
    }
    case 'CHANGE_NAME':
      return { ...state, name: action.val };
    case 'CHANGE_HOLLWEG_LOCATION_ID':
      return { ...state, hollwegLocationId: action.val };
    case 'CHANGE_LOCATION':
      return { ...state, location: action.val };
    default:
      return state;
  }
};

function HollwegLocations() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });
      const fetchArr = [getHollwegLocations, getLocation];

      try {
        const [hollwegLocations, locations] = await Promise.all(
          fetchArr.map((apiCall) => retryableAPICall(() => apiCall())),
        );
        if (isMounted) {
          if (hollwegLocations && locations && Array.isArray(locations)) {
            dispatch({ type: 'SET_MAPPED_LOCATIONS', hollwegLocations, locations });
          }
        }
      } catch (error) {
        toast.error('Failed to Load location data. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const isAddLocationDisabled =
    state.isLoading ||
    state.location?.value.length === 0 ||
    state.hollwegLocationId.length === 0 ||
    state.name.length === 0;

  const handleAddLocation = async () => {
    if (!isAddLocationDisabled) {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const location = state.storedlocations.find((a) => a.id === state.location.value);
        if (location?.location?.geoLocation?.coordinates[0]) {
          await retryableAPICall(() => addHollwegLocationMapping(state.name, state.hollwegLocationId, location));

          if (isMounted) {
            dispatch({
              type: 'ADD_MAPPING',
              payload: {
                lat: location.location.geoLocation.coordinates[0],
              },
            });
          }
        } else {
          toast.error('Please select a valid location');
        }
      } catch (error) {
        toast.error('Failed to add location. Please try again later or contact support');
      } finally {
        if (isMounted) {
          dispatch({ type: 'SET_LOADING_COMPLETE' });
        }
      }
    }
  };

  const handleDeleteMappedRole = async (name, id, location) => {
    dispatch({ type: 'SET_IS_LOADING' });

    try {
      await retryableAPICall(() => deleteHollwegLocationMappings(id, location));

      if (isMounted) {
        dispatch({
          type: 'REMOVE_MAPPING',
          data: { name, id, location },
        });
      }
    } catch (error) {
      toast.error('Failed to add location. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  return (
    <>
      <div className="mb-1">
        <h5>Locations</h5>
      </div>
      <Row className="gap-5">
        <Col>
          <div className="mb-1 mt-1">
            <h6>Add a Hollweg location</h6>
          </div>
          <FormGroup className="d-flex gap-3">
            <Input
              disabled={state.isLoading}
              onChange={(e) => dispatch({ type: 'CHANGE_NAME', val: e.target.value })}
              placeholder="Name"
              style={{ width: '15rem' }}
              type="text"
              value={state.name}
            />
            <Select
              className="react-select  hollweg-select"
              isDisabled={state.isLoading}
              onChange={(val) => dispatch({ type: 'CHANGE_LOCATION', val })}
              options={state.storedlocations?.reduce((memo, category) => {
                if (!category.isHidden) {
                  memo.push({
                    label: category.label,
                    value: category.id,
                  });
                }
                return memo;
              }, [])}
              placeholder="Select a Location"
              value={state.location}
            />
            <Input
              disabled={state.isLoading}
              onChange={(e) => dispatch({ type: 'CHANGE_HOLLWEG_LOCATION_ID', val: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (isAddLocationDisabled) {
                    return;
                  }
                  handleAddLocation();
                }
              }}
              placeholder="Hollweg location Id"
              style={{ width: '15rem' }}
              type="text"
              value={state.hollwegLocationId}
            />

            <Button color="primary" disabled={isAddLocationDisabled} onClick={() => handleAddLocation()}>
              Add
            </Button>
          </FormGroup>
          <Table style={{ width: '51.5rem' }}>
            <thead>
              <tr>
                <th style={{ width: '31%' }}>Name</th>
                <th style={{ width: '31%' }}>Location</th>
                <th style={{ width: '31%' }}>Hollweg Location ID</th>
                <th style={{ width: '7%' }} />
              </tr>
            </thead>
            <tbody>
              {state.mappings.length > 0 ? (
                state.mappings.map((role, index) => {
                  const location = state.storedlocations.find(
                    (a) => a?.location?.geoLocation?.coordinates[0].toString() === role.location,
                  );
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`${role.name}-${index}`}>
                      <td>{role.name}</td>
                      <td>{location?.name}</td>
                      <td>{role.hollwegLocationId}</td>
                      <td className="text-center">
                        <IconButton
                          action={() => {
                            handleDeleteMappedRole(role.name, role.hollwegLocationId, location);
                          }}
                          disabled={state.isLoading}
                          iconClassName="fa fa-times"
                          iconOnly
                          size="sm"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan="4">
                    No locations added
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

HollwegLocations.propTypes = {};

HollwegLocations.defaultProps = {};

export default HollwegLocations;
