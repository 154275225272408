import React from 'react';
import InitialsDropdown from './InitialsDropdown';
import AccountDropdown from './AccountDropdown';
import HeadSearch from './HeadSearch';
import NavList from './NavList';
import MobileNav from './MobileNav';
import NotificationHeader from '../../views/Notifications/NotficationHeader';

function DefaultHeader() {
  return (
    <header className="app-header container-fluid">
      <div className="app-header-inner">
        <MobileNav />
        <AccountDropdown />
        <NavList className="main-nav" linkClassName="nav-item" />
        <div className="head-search">
          <HeadSearch />
          <NotificationHeader />
          <InitialsDropdown />
        </div>
      </div>
    </header>
  );
}

export default DefaultHeader;
