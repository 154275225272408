import { SET_REPORTING_DATA, DELETE_REPORTING_DATA, RESET_REPORTING_DATA } from '../constants/actionTypes';

const initialState = {
  data: {},
};

// eslint-disable-next-line default-param-last
const reportingFilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { data } = state;

  const dataCp = { ...data };

  switch (type) {
    case SET_REPORTING_DATA:
      if (!data[payload.key]) {
        data[payload.key] = [];
      }

      dataCp[payload.key] = JSON.parse(JSON.stringify(payload.data));

      return {
        ...state,
        data: dataCp,
      };
    case DELETE_REPORTING_DATA:
      return {
        ...state,
        data: {
          ...data,
          [payload.key]: (data[payload.key] || []).filter((filter) => filter.key !== payload.key),
        },
      };
    case RESET_REPORTING_DATA:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};

export default reportingFilterReducer;
