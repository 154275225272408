import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cx from 'classnames';
import IconSVG from '../SVG/IconSVG';

function CloseButton({ label, onClick, className, size, disabled, iconOnly, innerRef }) {
  return (
    <Button
      color="link"
      size={size}
      className={cx('close-btn d-flex', 'align-items-center', className)}
      onClick={onClick}
      disabled={disabled}
      innerRef={innerRef}
    >
      <IconSVG name="Close Circle" width="1.25em" className={cx({ 'me-1': !iconOnly })} />
      {!iconOnly && label}
    </Button>
  );
}

CloseButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
};

CloseButton.defaultProps = {
  label: 'Close',
  onClick: () => {},
  className: null,
  size: 'sm',
  disabled: false,
  iconOnly: false,
  innerRef: null,
};

export default CloseButton;
