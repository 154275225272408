/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const multiPaths = [
  { fill: '#b6e786', d: 'M45.56,31.78l-.25.08a20.19,20.19,0,0,0-9.82,30.72c.16.23.33.46.51.68a119.7,119.7,0,0,0-12.55,15L0,41.83v-.14c.14-.31.29-.62.45-.92l.42-.83a4.83,4.83,0,0,1,.36-.67c.21-.38.52-.9.91-1.52.09-.15.18-.3.28-.44L3,36.42c.13-.2.26-.39.4-.58A36.9,36.9,0,0,1,7.85,30.5c.17-.18.35-.36.54-.53.94-.91,1.89-1.74,2.85-2.5l.21.3a17.55,17.55,0,0,0,28.56,0l.41-.6.07.06C42.26,28.68,44,30.23,45.56,31.78Z' },
  { fill: '#4fe1c2', d: 'M43.29,17.56a17.47,17.47,0,0,1-2.87,9.61l-.41.6a17.55,17.55,0,0,1-28.56,0l-.21-.3a17.56,17.56,0,1,1,32.05-9.91Z' },
  { fill: '#4fe1c2', d: 'M103.28,41.69v.14L79.83,78.3a119.7,119.7,0,0,0-12.55-15c.18-.22.35-.45.51-.68A20.19,20.19,0,0,0,58,31.86l-.25-.08c1.59-1.55,3.3-3.1,5.07-4.55l.06-.05c.14.2.27.4.41.59a17.55,17.55,0,0,0,28.56,0,3.37,3.37,0,0,0,.21-.31h0c1,.76,1.91,1.59,2.85,2.5.19.17.37.35.54.53a36.9,36.9,0,0,1,4.44,5.34c.14.19.27.38.4.58l.59.89c.1.14.19.29.28.44.39.62.7,1.14.91,1.52a4.83,4.83,0,0,1,.36.67l.42.83C103,41.07,103.14,41.38,103.28,41.69Z' },
  { fill: '#1c60a8', d: 'M79.83,78.3l-2.08,3.23L60.9,107.74H42.38L25.53,81.53,23.45,78.3A119.7,119.7,0,0,1,36,63.26a18.9,18.9,0,0,0,2.35,2.55A19.59,19.59,0,0,0,51.62,71h0a19.59,19.59,0,0,0,13.27-5.17,18.9,18.9,0,0,0,2.35-2.55A119.7,119.7,0,0,1,79.83,78.3Z' },
  { fill: '#b6e786', d: 'M95.11,17.56A17.39,17.39,0,0,1,92,27.46a3.37,3.37,0,0,1-.21.31,17.55,17.55,0,0,1-28.56,0c-.14-.19-.27-.39-.41-.59a17.56,17.56,0,1,1,32.26-9.62Z' },
  { fill: '#7c89e3', d: 'M71.5,50.9a20.14,20.14,0,0,1-3.71,11.68c-.16.23-.33.46-.51.68a18.9,18.9,0,0,1-2.35,2.55A19.59,19.59,0,0,1,51.66,71h0a19.59,19.59,0,0,1-13.27-5.17A18.9,18.9,0,0,1,36,63.26c-.18-.22-.35-.45-.51-.68a20.19,20.19,0,0,1,9.82-30.72l.25-.08.08.07a19.68,19.68,0,0,1,5.92-1h.16a19.68,19.68,0,0,1,5.92,1l.08-.07.25.08A20.08,20.08,0,0,1,71.5,50.9Z' },
];

function TalentFunnel(props) {
  const { multi, ...rest } = props;

  return (
    <BaseSVG
      {...rest}
      name="Talent Funnel"
      viewBox="0, 0, 103.38, 107.72"
      d="M102.46,39.92 C99.825,35.097 96.272,30.837 92,27.38 C96.946,20.076 95.706,10.236 89.102,4.387 C82.498,-1.461 72.58,-1.502 65.927,4.292 C59.275,10.086 57.954,19.915 62.84,27.26 C61.036,28.663 59.362,30.226 57.84,31.93 C55.858,31.246 53.777,30.888 51.68,30.87 L51.52,30.87 C49.42,30.887 47.336,31.245 45.35,31.93 C43.849,30.226 42.196,28.663 40.41,27.26 C45.296,19.915 43.975,10.086 37.323,4.292 C30.67,-1.502 20.752,-1.461 14.148,4.387 C7.544,10.236 6.304,20.076 11.25,27.38 C7.022,30.846 3.512,35.106 0.92,39.92 L-0,41.73 L42.43,107.73 L61,107.73 L103.43,41.73 z M77.5,7.1 C83.272,7.122 87.935,11.815 87.92,17.587 C87.905,23.358 83.218,28.027 77.447,28.02 C71.675,28.013 67,23.332 67,17.56 C67.003,14.78 68.111,12.115 70.081,10.152 C72.05,8.19 74.72,7.092 77.5,7.1 z M51.69,64 C46.406,64.126 41.571,61.04 39.461,56.193 C37.351,51.347 38.385,45.705 42.077,41.923 C45.77,38.14 51.385,36.97 56.281,38.963 C61.177,40.955 64.378,45.714 64.38,51 C64.442,54.413 63.134,57.708 60.75,60.15 C58.366,62.593 55.103,63.979 51.69,64 z M25.88,7.1 C31.658,7.094 36.346,11.775 36.35,17.553 C36.354,23.332 31.672,28.018 25.893,28.02 C20.115,28.022 15.43,23.338 15.43,17.56 C15.436,11.789 20.109,7.111 25.88,7.1 z M16.5,32.37 C22.267,36.049 29.651,36.022 35.39,32.3 C36.705,33.268 37.939,34.342 39.08,35.51 C30.801,42.481 29.479,54.734 36.08,63.31 C33.106,66 30.425,68.997 28.08,72.25 L8.25,41.43 C10.437,37.941 13.231,34.873 16.5,32.37 z M57.07,100.62 L46.3,100.62 L32.3,78.76 C34.816,74.79 37.891,71.203 41.43,68.11 C44.499,70.032 48.039,71.07 51.66,71.11 L51.82,71.11 C55.438,71.072 58.975,70.033 62.04,68.11 C65.582,71.203 68.66,74.79 71.18,78.76 z M75.31,72.25 C72.968,68.994 70.286,65.998 67.31,63.31 C73.906,54.732 72.584,42.483 64.31,35.51 C65.449,34.339 66.683,33.266 68,32.3 C73.739,36.022 81.123,36.049 86.89,32.37 C90.157,34.875 92.951,37.943 95.14,41.43z"
      paths={multi ? multiPaths : []}
    />
  );
}

TalentFunnel.propTypes = {
  multi: PropTypes.bool,
};

TalentFunnel.defaultProps = {
  multi: false,
};

export default TalentFunnel;
