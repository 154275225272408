import moment from 'moment';
import {
  ADD_POLARIS_USER,
  REMOVE_POLARIS_USER,
  ADD_POLARIS_KEY_MAP,
  ADD_POLARIS_GLOBAL_CONFIG,
} from '../constants/actionTypes';

const defaultState = {
  connected: false,
  domainUrl: '',
  accountIdToSiteId: {},
  globalConfig: {},
  globalConfigLoaded: false,
  lastUpdateDate: null,
};

export default function polarisReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_POLARIS_USER:
      return {
        ...state,
        connected: true,
        domainUrl: payload.domainUrl,
        ...(payload.accountIdToSiteId ? { accountIdToSiteId: payload.accountIdToSiteId } : {}),
      };
    case REMOVE_POLARIS_USER:
      return { ...defaultState };
    case ADD_POLARIS_KEY_MAP:
      return {
        ...state,
        accountIdToSiteId: payload,
      };
    case ADD_POLARIS_GLOBAL_CONFIG:
      return {
        ...state,
        globalConfig: payload.config,
        globalConfigLoaded: payload.isComplete,
        lastUpdateDate: moment().toISOString(),
      };
    default:
      return state;
  }
}
