import React, { useEffect, useCallback, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getEventType, deleteEventType } from '../../../../api/SchedulingAPI/EventTypeAPI';
import { useTableState, DataTable } from '../../../Base/Tables';
import { LoadingScreen } from '../../../Base/Loading';
import { ErrorMessage } from '../../../Base/ErrorScreen';
import { DeleteButton, CreateButton } from '../../../Base/Buttons';
import { EventTypeEditor, EVENT_FORMATS } from './components';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import Can from '../../../Base/RBAC/Can/Can';
import { ClientAdminSlider, utils } from '../Dashboard';
import { TippyTruncate } from '../../../Base/Truncate';
import EnhancedCard from '../Common/EnhancedCard';
import styled from 'styled-components';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

const RestrictedCreateButton = Can(CreateButton);

async function deleteType(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteEventType(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function ScheduleEventTypes({ tablePrefs, activeAccount, totalAccounts }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteTypeId, setDeleteTypeId] = useState();
  const [typeObj, setTypeObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getEventType());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          // Api doesn't sort so lets do it ourselves by Event Name
          const sortedEvents = resp.sort((a, b) => a.name?.localeCompare(b.name));

          setTableData({
            data: sortedEvents,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Event Type Name',
      id: 'typeName',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name, accountId, readOnly } = original;

        if (checkPermissions(['admin:schedule:update']) && utils.isEditable(accountId, activeAccount.id, readOnly)) {
          return (
            <a
              href="#typeName"
              onClick={(e) => {
                e.preventDefault();
                setTypeObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }
        return name;
      },
    },
    {
      Header: 'Event Format',
      id: 'typeFormat',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { format } = original;
        // eslint-disable-next-line react/no-danger
        return EVENT_FORMATS[format] || '';
      },
    },
    {
      Header: 'Event Description',
      id: 'typeDesc',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { description } = original;

        return (
          <Tippy
            // eslint-disable-next-line react/prop-types
            content={description.replace(/(<([^>]+)>)/gi, '')}
            theme="ats"
            maxWidth="200px"
            delay={[1000, null]}
          >
            <div
              className="truncate-overflow"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Tippy>
        );
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccount.id);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:schedule:delete'])) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, accountId, readOnly } = original;
        // eslint-disable-next-line react/prop-types
        const editable = utils.isEditable(accountId, activeAccount.id, readOnly);
        // eslint-disable-next-line react/prop-types
        const deletable = utils.isDeletable(accountId, activeAccount.id);

        return (
          <Tippy
            content="Only the owner can delete this"
            theme="ats"
            maxWidth="150px"
            disabled={editable && deletable}
            delay={[1000, null]}
          >
            <div>
              <DeleteButton
                label="Delete Event Type"
                action={() => {
                  setDeleteTypeId(id);
                  setShowConfirm(true);
                }}
                disabled={!editable || !deletable}
                className="btn-sm xs"
                iconOnly
                floatRight={false}
              />
            </div>
          </Tippy>
        );
      },
    });
  }

  if (pageState.isPending) return <LoadingScreen isEmbeded />;
  if (pageState.isRejected) return <ErrorMessage error={pageState.rejectionStatus} />;

  return (
    <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="mb-0">Event Types</h3>
        <RestrictedCreateButton
          permissions={['admin:schedule:create']}
          action={() => {
            setIsEditing(false);
            setTypeObj({});
            setIsSliderOpen(true);
          }}
          label="Create a new Event Type"
        />
      </div>
      <StyledTable
        id="sched-evt-types"
        className="mt-3"
        isLoading={tableState.isLoading}
        isRejected={tableState.isRejected}
        data={tableState.data}
        tblHeightOffset={300}
        rowHeight={80}
        columns={columns}
        // totalPages={tableState.totalPages}
        totalResults={tableState.totalResults}
        pageSize={tableState.rowsPerPage}
        noDataText="You currently have no event types"
        errorText="There has been an error loading event types, please try again later"
        showPagination={false}
        hasSelectColumn={false}
      />
      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Event Type`}
        isSliderOpen={isSliderOpen}
        closeSlider={() => setIsSliderOpen(false)}
      >
        <EventTypeEditor
          isEditing={isEditing}
          data={typeObj}
          totalAccounts={totalAccounts}
          activeAccount={activeAccount}
          onSave={(evTypeObj) => {
            setIsSliderOpen(false);

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, evTypeObj, evTypeObj.id)
              : addObjToArray(tableState.data, evTypeObj);

            if (updatedArr.length) {
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            }
          }}
          onCancel={() => setIsSliderOpen(false)}
        />
      </ClientAdminSlider>
      <Confirmation
        content="Are you sure you want to delete this event type?"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteType(
            deleteTypeId,
            () => {
              toast.success('Event Type successfully deleted');

              const updatedArr = deleteObjFromArray(tableState.data, deleteTypeId);

              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            },
            () => {
              toast.error('Error deleting event type');
            },
          );
          setShowConfirm(false);
        }}
      />
    </EnhancedCard>
  );
}

ScheduleEventTypes.propTypes = {
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  totalAccounts: PropTypes.number,
};

ScheduleEventTypes.defaultProps = {
  tablePrefs: {
    pageSize: 25,
  },
  activeAccount: {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    tablePrefs,
    activeAccount: { id: activeAccountId, name: activeAccountName },
    totalAccounts: accountAccess.length,
  };
}

export default connect(mapStateToProps)(ScheduleEventTypes);
