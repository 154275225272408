import cx from 'classnames';
import React from 'react';

const cellProps = (props, { cell }) => [
  props,
  { style: { ...cell.column.style } },
];

function TableRow(props) {
  const { row, isOdd } = props;

  return (
    <div className="rt-tr-group">
      <div
        {...row.getRowProps()}
        className={cx('rt-tr', { '-odd': isOdd }, { '-even': !isOdd })}
      >
        {row.cells.map((cell) => {
          const { column = {} } = cell;
          const { visible } = column;

          if (!visible) return null;

          return (
            <div
              {...cell.getCellProps(cellProps)}
              className={cx('rt-td', column.className)}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TableRow;
