import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';
import { actionNodes } from '../NodeTypesLists';
import { useHover } from '../../HoverContext';
import { CirclePause } from 'lucide-react';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const PauseContainer = styled.div`
  position: absolute;
  right: 3px;
  top: -5px;
`;

const StyledCont = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Icon = styled.div`
  background-color: #0a1551;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }

  .error {
    color: red;
  }
`;

function hasData(data) {
  let message = '';
  if (data.actionType === 'SEND_EMAIL') {
    if (data.type === 'DIRECT') {
      message = data.recipients?.length ? 'Emails Selected' : null;
    } else if (data.type === 'ATS') {
      message = data.recipients?.length && data.templateId ? 'Emails Selected' : null;
    } else {
      message = data.templateId ? 'Template Selected' : null;
    }
  } else if (data.actionType === 'SEND_TO_INTEGRATION') {
    message = data.integrationType;
    if (data.integrationType === 'FLOW') {
      const type = data.operation === 'delete' ? 'Delete Candidate' : 'Create Candidate';
      message = `${message}: ${type}`;
    }
  } else if (data.actionType === 'EVENT_STATUS_CHANGE') {
    message = eventStatusMap[data.condition];
  } else if (data.actionType === 'REQUEST_REFEREES' || data.actionType === 'REQUEST_REFERENCES') {
    message = ' ';
  } else if (data.actionType === 'UPDATE_FUNNEL_STAGE') {
    message = data.funnelId && data.stageId ? 'Funnel Stage Selected' : null;
  } else {
    message = data.templateId ? 'Template Selected' : null;
  }
  return message;
}

export default memo(({ id, data }) => {
  const nodeType = actionNodes.find((node) => node.actionType === data?.actionType);
  const label = nodeType?.label;
  const IconComp = nodeType?.icon;
  const isEditDisabled = nodeType?.isEditDisabled;

  const { hoveredId } = useHover();

  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleDoubleClick = () => {
    if (isEditDisabled) return;
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <StyledCont onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} isEditDisabled={isEditDisabled} />
        <Handle type="target" position={Position.Top} />
        <Container isHighlighted={id === hoveredId}>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">{label}</div>
            {hasData(data) ? (
              <div className="status">{hasData(data)}</div>
            ) : (
              <div className="error">Edit to complete node</div>
            )}
          </Text>
          {data.pauseWindow && (
            <PauseContainer>
              <CirclePause size={8} />
            </PauseContainer>
          )}
        </Container>
        <Handle type="source" position={Position.Bottom} />
      </StyledCont>
    </SliderContext.Provider>
  );
});
