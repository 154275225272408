/* eslint-disable no-param-reassign */
export const transformErrors = (errors) => errors.map((error) => {
  const { name, params: { format, pattern } } = error;

  if (name === 'required') {
    error.message = 'This field is required';
  }

  if (name === 'format') {
    if (format === 'email') {
      error.message = 'This field must be a valid email address';
    }
  }

  if (name === 'pattern') {
    if (pattern === '^[a-zA-Z]{1,2}\\d[a-z\\d]?\\s*\\d[a-zA-Z]{2}$') {
      error.message = 'This field must be a valid Postcode';
    }
  }

  return error;
});

/**
 * Provides validation for all of our custom components
 * @param form
 * @param errors
 */

export function validateFields(form, errors) {
  let errs = { ...errors };

  if (form.address) { // There is an address field in here
    // Make sure at least city, country and lat/long are set
    const { address: { country, lat, long } } = form.address;
    if (!country || !lat || !long) errs.address.addError('Address is required');
  }

  setTimeout(() => {
    errs = document.getElementsByClassName('error-detail');
    if (errs[0]) {
      errs[0].scrollIntoView();
      if (document.getElementsByClassName('slide-pane__content')[0]) {
        document.getElementsByClassName('slide-pane__content')[0].scrollBy(0, -100);
      }
    }
  }, 100);
  return errs;
}
