// import { v4 as uuidv4 } from 'uuid';
import validation from '../../../js/utils/validation';
import { DATE_FORMAT, TIME_FORMAT } from './dateTimeUtils';

// TODO: Replace with util from arrayOfObjects
export function updateEventArr(eventArr, idx, eventObj) {
  const clone = [...eventArr];
  if (~idx) {
    if (eventObj) {
      // update
      clone.splice(idx, 1, eventObj);
    }
    else {
      // delete
      clone.splice(idx, 1);
    }
  }
  else if (eventObj) {
    // add
    clone.push(eventObj);
  }
  return clone;
}

// TODO: use with util from arrayOfObjects
export function deleteEvent(eventArr, eventId, isRecurrence) {
  if (isRecurrence) {
    return eventArr.filter((eventObj) => (
      // ignore events to be deleted
      eventObj.extendedProps.recurrenceGroupingId !== eventId
    ));
  }

  const idx = eventArr.findIndex((eventObj) => eventObj.id === eventId);
  return updateEventArr(eventArr, idx);
}

// TODO: Replace with util from arrayOfObjects
export function updateEvent(eventArr, eventId, newEvent) {
  const idx = eventArr.findIndex(({ id }) => id === eventId);
  return updateEventArr(eventArr, idx, newEvent);
}

export function getEventTitle(formData) {
  if (!Object.keys(formData).length) return null;

  const { startDate, endDate, allDay } = formData;
  const diffDays = endDate.diff(startDate, 'days');

  let format = DATE_FORMAT;
  if (!allDay) format = `${DATE_FORMAT}, ${TIME_FORMAT}`;

  // set format for start date
  const startStr = startDate.format(format);

  // set time format for end date if on same day no need to repeat date string
  if (!allDay && diffDays === 0) format = TIME_FORMAT;
  const endDateStr = endDate.format(format);

  let endStr = '';
  // show end str if more than one day, is not an all day event
  if (diffDays > 1 || (!allDay && (diffDays === 1 || diffDays === 0))) endStr = ` - ${endDateStr}`;

  return `${startStr}${endStr}`;
}

export function setRequiredFalse(inpId, inputConfig) {
  const clone = [...inputConfig];
  const idx = clone.findIndex(({ id }) => id === inpId);
  if (~idx) clone.splice(idx, 1, { ...clone[idx], required: false });
  return clone;
}

export function validateEventData(inputConfig, formData, isEditable) {
  let config = [...inputConfig];

  const {
    startDate,
    endDate,
    allDay,
    recurringEvent,
    recurrenceEndDate,
    inviteType,
    emailDetails = {},
  } = formData;
  const { message, subject } = emailDetails;

  // if recurrence is NO then date is not required
  if (!recurringEvent) config = setRequiredFalse('recurrenceEndDate', config);

  // if SELF BOOK invite type slot id is not required
  if (inviteType === 'SELF_BOOK' || inviteType === 'INSTANT') config = setRequiredFalse('slotId', config);

  // if editable
  if (isEditable) {
    // ensure at least one candidate id added
    config.push({
      id: 'candidateIds',
      label: 'Candidates',
      required: true,
      type: 'array',
    });
  }

  let errObj = validation(
    config,
    {
      ...formData,
      // convert to Date objects as dates are instances of Moment
      ...(startDate ? { startDate: startDate.toDate() } : {}),
      ...(endDate ? { endDate: endDate.toDate() } : {}),
      ...(recurringEvent && recurrenceEndDate ? { recurrenceEndDate: recurrenceEndDate.toDate() } : {}),
    },
  );

  if (recurringEvent && recurrenceEndDate && startDate && !recurrenceEndDate.isAfter(startDate)) {
    errObj = {
      ...errObj,
      recurrenceEndDate: {
        invalid: true,
        errors: [...((errObj.recurrenceEndDate || {}).errors || []), 'Date must be after Start Date'],
      },
    };
  }

  if (!allDay && endDate && startDate) {
    if (!endDate.isSameOrAfter(startDate)) {
      errObj = {
        ...errObj,
        endDate: {
          invalid: true,
          errors: [...((errObj.endDate || {}).errors || []), 'Date must be after Start Date'],
        },
      };
    }

    if (endDate.isSame(startDate, 'day') && endDate.isSameOrBefore(startDate, 'minute')) {
      errObj = {
        ...errObj,
        endTime: {
          invalid: true,
          errors: [...((errObj.endTime || {}).errors || []), 'Time must be after Start Time'],
        },
      };
    }

    if (startDate.isSameOrBefore(new Date(), 'minute')) {
      errObj = {
        ...errObj,
        startTime: {
          invalid: true,
          errors: [...((errObj.startTime || {}).errors || []), 'Start Time must be in the future'],
        },
      };
    }
  }

  if (isEditable && (!message || !subject)) {
    errObj = {
      ...errObj,
      emailDetails: {
        invalid: true,
        errors: [...((errObj.emailDetails || {}).errors || []), 'Email form has missing required fields'],
      },
    };
  }

  return errObj;
}
