/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const lookUp = {
  email: {
    viewBox: '0 0 32 32',
    d: 'M23.1277259,0 L2.39252336,0 C1.07115265,0 0,1.07115265 0,2.39252336 L0,16.7476636 C0,18.0690343 1.07115265,19.1401869 2.39252336,19.1401869 L23.1277259,19.1401869 C24.4490966,19.1401869 25.5202492,18.0690343 25.5202492,16.7476636 L25.5202492,2.39252336 C25.5202492,1.07115265 24.4490966,0 23.1277259,0 Z M23.1277259,2.39252336 L23.1277259,4.42641745 C22.0101184,5.33652336 20.2283863,6.75170093 16.4193396,9.73433022 C15.5799128,10.3946168 13.917109,11.9809097 12.7601246,11.9624174 C11.6033396,11.981109 9.93998754,10.3943676 9.10090966,9.73433022 C5.29246106,6.75214953 3.51028037,5.3366729 2.39252336,4.42641745 L2.39252336,2.39252336 L23.1277259,2.39252336 Z M2.39252336,16.7476636 L2.39252336,7.49647352 C3.53465421,8.40618069 5.15434268,9.68274143 7.62307788,11.6159003 C8.71252336,12.4734704 10.6204112,14.3666044 12.7601246,14.3550903 C14.8893209,14.3666044 16.7730343,12.5009346 17.8967227,11.6162991 C20.3654081,9.68319003 21.9855452,8.40628037 23.1277259,7.49647352 L23.1277259,16.7476636 L2.39252336,16.7476636 Z',
  },
  emailSolid: {
    viewBox: '0 0 512 512',
    d: 'M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z',
  },
};

function Email(props) {
  const { type, ...rest } = props;

  const config = lookUp[type];

  return (
    <BaseSVG
      {...rest}
      name="Email"
      viewBox={config.viewBox}
      d={config.d}
    />
  );
}

Email.propTypes = {
  type: PropTypes.string,
};

Email.defaultProps = {
  type: 'email',
};

export default Email;
