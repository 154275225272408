import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { ActionButton } from '../../Base/Buttons';
import { FormModal } from '../../Base/Modal';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import waiting from '../../../assets/img/nodata/waiting.png';
import { toast } from 'react-toastify';

function RightToWorkRequestedContainer({
  rtwDetails,
  languagePack,
  isExpired,
  sendReminder,
  downloadingPDF,
  reminderSending,
  rtwStatus,
}) {
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [actionBtnOpen, setActionButtonOpen] = useState(false);

  const menuItems = [
    {
      action: async () => {
        sendReminder();
        setActionButtonOpen(false);
      },
      icon: 'Paper Plane',
      id: 2,
      isDisabled: downloadingPDF,
      label: reminderSending
        ? languagePack.rtwSendReminderSendingBtnLabel
        : rtwStatus === 'REQUESTED'
        ? languagePack.rtwSendReminderBtnLabel
        : languagePack.rtwSendFinalReminderBtnLabel,
      permissions: ['rtw:create'],
      isHidden: /^REMINDER_2_SENT/.test(rtwStatus),
    },
    {
      action: () => {
        window.open(`${rtwDetails.idCheckUrl}`);
        setActionButtonOpen(false);
      },
      icon: 'Pencil',
      id: 1,
      label: languagePack.rightToWorkSelfFillInFormBtn,
      permissions: ['rtw:create'],
      tooltipContent: 'You are unable to edit records that are over 12 months old',
    },
    {
      action: () => {
        navigator.clipboard.writeText(rtwDetails.idCheckUrl).then(
          () => {
            toast.success(`${languagePack.rtwTestURL} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
        setActionButtonOpen(false);
      },
      icon: 'Paper Clip',
      id: 3,
      isDisabled: false,
      label: `Copy URL to clipboard`,
    },
  ];

  let reminderText;

  if (rtwStatus === 'REMINDER_1_SENT') {
    reminderText = 'First Reminder Sent';
  } else if (rtwStatus === 'REMINDER_2_SENT') {
    reminderText = 'Final Reminder Sent';
  }
  return (
    <>
      <Row>
        <Col>
          <div className="onboarding-container-empty mb-4">
            <div className="onboarding-container-center">
              <Row className="mb-1">
                <Col className="text-center">{languagePack.onboardingInformationNotReceived}</Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {isExpired && (
                    <p className="mt-1">
                      {languagePack.rightToWorkLinkExpiredDescriptionPrefix}
                      <strong> {moment(rtwDetails.expiryDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} </strong>
                      {languagePack.rightToWorkLinkExpiredDescriptionSuffix}
                    </p>
                  )}
                  {!isExpired && (
                    <>
                      <ActionToBePerformedNoDataState
                        image={waiting}
                        title="Right To Work Check Requested"
                        subTitle={reminderText}
                        description="The Right To Work check has been requested and we are waiting for the candidate to complete it."
                        button={
                          <ActionButton
                            buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                            className="ellipsis-opts-btn"
                            iconName="Ellipsis"
                            iconOnly={false}
                            isOpen={actionBtnOpen}
                            menuItems={menuItems}
                            onToggle={(isOpen) => {
                              setActionButtonOpen(isOpen);
                            }}
                            title="Actions"
                            tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                          />
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {modalConfig.isOpen && (
        <FormModal
          contentUrl={modalConfig.url}
          id="rtw-details"
          isOpen={modalConfig.isOpen}
          toggle={(closeMethod, isOpen, frameId) => {
            if (closeMethod === 'IFRAME' && isOpen && frameId === 'rtw-details') {
              // getData((status) => {
              //   setModalConfig({ isOpen: false, url: '' });
              //   onUpdate(status);
              //  });
            } else {
              setModalConfig({ isOpen: false, url: '' });
            }
          }}
          usePostMessage={false}
        />
      )}
    </>
  );
}

RightToWorkRequestedContainer.propTypes = {
  isExpired: PropTypes.bool,
  languagePack: PropTypes.shape(),
  rtwDetails: PropTypes.shape(),
};

RightToWorkRequestedContainer.defaultProps = {
  isExpired: false,
  languagePack: {},
  rtwDetails: {},
};

export default RightToWorkRequestedContainer;
