import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import BuildDetails from './BuildDetails';
import { getCommonHeaders } from '../../../../api/common-api-utils';
import config from '../../../../config/config';

const { api } = config;

const dict = {
  visaType: 'Visa Type',
  visaNumber: 'Visa Number',
  visaExpiryDate: 'Visa Expiry Date',
};

const visaTypes = {
  1: 'Dependant Visa',
  2: 'EEA Family Permit',
  3: 'Entrepreneur Dependant',
  4: 'ILR',
  5: 'Other',
  6: 'Right For Abode',
  7: 'Tier 1 (Post Study',
  8: 'Tier 2 Visa',
  9: 'Tier 4 (General) Student Visa',
  10: 'UK Ancestry Visa',
  11: 'Tier 5 Youth Mob',
  12: 'Pre-Settled',
  13: 'Settled',
  14: 'N/A - EU resident already residing in UK',
};

function VisaDetails({ value, vertical }) {
  const [visas, setVisas] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let newData = visaTypes;

      try {
        const response = await axios.get(`${api.jsAPIURL}/polaris/config/visa/type`, {
          headers: getCommonHeaders(),
        });

        if (response !== undefined) {
          newData = response.data.reduce((memo, visa) => {
            // eslint-disable-next-line no-param-reassign
            memo[visa.id] = visa.commonName;
            return memo;
          }, {});
        }
      } catch (error) {
        console.log(`could not fetch data with error: ${error}`);
      }

      setVisas(newData);
    };

    getData();
  }, [value]);

  if (!Object.keys(value).length) return null;

  return Object.entries(value).map(([key, val]) => {
    let displayVal = val;

    if (key === 'visaType') {
      displayVal = visas[val] || val;
    }

    return (
      <BuildDetails key={key} title={dict[key]} titleColumnSize="4" vertical={vertical}>
        {displayVal}
      </BuildDetails>
    );
  });
}

VisaDetails.propTypes = {
  value: PropTypes.shape({
    visaType: PropTypes.string,
    visaNumber: PropTypes.string,
    visaExpiryDate: PropTypes.string,
  }),
  vertical: PropTypes.bool,
};

VisaDetails.defaultProps = {
  value: {},
  vertical: false,
};

export default VisaDetails;
