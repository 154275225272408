import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { polaris },
} = config;

export async function linkAccount(domainUrl) {
  if (domainUrl) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${polaris.userPath}`,
      { domainUrl },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function unlinkAccount() {
  const result = await axios.delete(`${api.middlewareAPIURL}${polaris.userPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function updateLinkedAccount(domainUrl) {
  if (domainUrl) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${polaris.userPath}`,
      { domainUrl },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getLinkedAccount() {
  const result = await axios.get(`${api.middlewareAPIURL}${polaris.userPath}`, { headers: { ...getCommonHeaders() } });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function updateKeyMapping(keys = {}) {
  if (Object.keys(keys).length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${polaris.keyMapPath}`,
      { ...keys },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateAccountKeyMapping(accountId, keys = {}) {
  if (accountId && Object.keys(keys).length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${polaris.accountKeyMapPath}/${accountId}`,
      { ...keys },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getKeyMapping() {
  const result = await axios.get(`${api.middlewareAPIURL}${polaris.keyMapPath}`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getGlobalConfig() {
  const result = await axios.get(`${api.middlewareAPIURL}${polaris.globalConfigPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSiteLevelConfig(activeAccount) {
  const result = await axios.get(`${api.middlewareAPIURL}${polaris.siteConfigPath}/${activeAccount}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function submitToPolaris(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${polaris.submitPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function checkPolarisSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${polaris.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
