/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// import merge from 'lodash.merge';
import { VacancyForm } from '../../../Vacancy';
import FormHeader from './FormHeader';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { createVacancyTemplate, getCreateVacancyTemplateById, updateVacancyTemplate } from '../../../../api/VacancyAPI';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { useMounted, useLanguage } from '../../../Base/hooks';
import { saveVacancyTemplate } from '../../../../js/language/pages';

async function proxyCall(name, description, readOnly, sharedWith, data, id) {
  if (id) return updateVacancyTemplate(id, name, description, readOnly, sharedWith, data);
  return createVacancyTemplate(name, description, readOnly, sharedWith, data);
}

function VacancyTemplateForm({ id, isCopyMode, onSave, onCancel, activeAccount, totalAccounts }) {
  const { langPack: languagePack } = useLanguage(saveVacancyTemplate);
  const headerVal = useRef({});
  const formAsyncCache = useRef({});
  const isMounted = useMounted();
  const [isError, setIsError] = useState(false);
  const [initHeaderData, setInitHeaderData] = useState({});
  const [initFormData, setInitFormData] = useState({});

  const getVacancyTemplate = useCallback(async () => {
    const resp = await retryableAPICall(() => getCreateVacancyTemplateById(id));

    if (isMounted()) {
      if (typeof resp === 'string') {
        toast.error(languagePack.getTemplateByIdError);
      } else {
        const { data, ...rest } = resp;
        setInitHeaderData((prevProps) => {
          const val = {
            ...prevProps,
            ...rest,
            name: isCopyMode ? `${rest.name} (Copy)` : rest.name,
            sharedWith: (rest.sharedWith || []).filter(({ accountId }) => accountId !== activeAccount.id),
          };

          headerVal.current = { ...val };
          return val;
        });
        setInitFormData((prevProps) => ({ ...prevProps, ...data }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isMounted]);

  useEffect(() => {
    if (id) getVacancyTemplate();
  }, [getVacancyTemplate, id]);

  async function handleSave(tempData) {
    const mergedHeaderObj = {
      ...initHeaderData,
      ...headerVal.current,
      sharedWith: [
        ...(headerVal.current.sharedWith || []),
        // makesure activeAccount is always passed
        { accountId: activeAccount.id, accountName: activeAccount.name },
      ],
    };

    const { name = '', description = '', readOnly = false, sharedWith = [] } = mergedHeaderObj;

    if (!name.trim().length) {
      setIsError(true);
    } else {
      setIsError(false);

      // If copy mode, set id to null to create a new template
      const newId = isCopyMode ? null : id;

      const resp = await retryableAPICall(() => proxyCall(name, description, readOnly, sharedWith, tempData, newId));

      if (typeof resp === 'string') {
        let errorMsg = languagePack.generalError;

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          errorMsg = languagePack.templateNameAlreadyExists;
        }

        toast.error(errorMsg);
      } else {
        toast.success(languagePack.templateSaveSuccess);
        onSave(resp);
      }
    }
  }

  return (
    <Fragment>
      <FormHeader
        data={initHeaderData}
        isError={isError}
        onChange={(valObj) => {
          headerVal.current = { ...valObj };
        }}
        totalAccounts={totalAccounts}
      />
      <hr />
      <VacancyForm
        isEditing={!!(id && id.length)}
        className="vacancy-template-form"
        hideSubmitButton
        vacancyData={initFormData}
        asyncCache={formAsyncCache.current}
        onCancel={onCancel}
        onTemplateSave={(tempData) => {
          const cloneData = { ...tempData };

          const { applicationMethod } = cloneData;

          if (applicationMethod && applicationMethod.appFormLink && applicationMethod.appFormTemplate) {
            // if an appForm schema object added
            if (Object.keys(applicationMethod.appFormLink).length) {
              // delete the template id
              delete cloneData.applicationMethod.appFormTemplate;
            }
          }

          handleSave(cloneData);
          // handleSave(merge(initFormData, tempData));
        }}
      />
    </Fragment>
  );
}

VacancyTemplateForm.propTypes = {
  id: PropTypes.string,
  isCopyMode: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  totalAccounts: PropTypes.number,
};

VacancyTemplateForm.defaultProps = {
  id: null,
  isCopyMode: false,
  onSave: () => {},
  onCancel: () => {},
  activeAccount: {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    activeAccount: { id: activeAccountId, name: activeAccountName },
    totalAccounts: accountAccess.length,
  };
}

export default connect(mapStateToProps)(VacancyTemplateForm);
