import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonComp from './ButtonComp';

export default function CreateButton({
  className,
  isOutline,
  disabled,
  type,
  action,
  label,
  isLoading,
  iconOnly,
  floatRight,
  size,
}) {
  return (
    <ButtonComp
      className={cx('create-button', { 'btn-outline': isOutline }, className, { 'float-end': floatRight })}
      disabled={disabled}
      type={type}
      onClick={action}
      isLoading={isLoading}
      loadingIconColor="action"
      iconName="fa-plus"
      iconOnly={iconOnly}
      size={size}
    >
      {label}
    </ButtonComp>
  );
}

CreateButton.propTypes = {
  className: PropTypes.string,
  isOutline: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  action: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  iconOnly: PropTypes.bool,
  floatRight: PropTypes.bool,
  size: PropTypes.string,
};

CreateButton.defaultProps = {
  className: null,
  isOutline: true,
  disabled: false,
  type: null,
  action: () => {},
  label: 'Create',
  isLoading: false,
  iconOnly: false,
  floatRight: true,
  size: null,
};
