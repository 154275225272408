import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonComp from './ButtonComp';

export default function TextButton({ action, label, size, type, disabled, isLoading, floatRight, className }) {
  return (
    <ButtonComp
      className={cx('text-button', className, { 'float-end': floatRight })}
      onClick={action}
      size={size}
      type={type}
      disabled={disabled}
      isLoading={isLoading}
      loadingIconColor="white"
    >
      {label}
    </ButtonComp>
  );
}

TextButton.propTypes = {
  action: PropTypes.func,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  floatRight: PropTypes.bool,
  className: PropTypes.string,
};

TextButton.defaultProps = {
  action: () => {},
  size: null,
  type: 'button',
  disabled: false,
  isLoading: false,
  floatRight: false,
  className: null,
};
