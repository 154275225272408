// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { getFlexiFormViewIdsByType } from '../../../api/FormsAPI/ATSFormsAPI';
import { SelectInput } from '../../Base/Forms/Custom/CommonComponents';

import { retryableAPICall } from '../../../api/common-api-utils';
import { checkPermissions } from '../../../js/auth/AuthUtils';

function FormsSelector({
  languagePack,
  onChange,
  type,
  alreadySelectedFormIds,
  permissionsRequired,
  label,
  selectedValues,
}) {
  const [formOptions, setFormOptions] = useState([]);

  async function getForms() {
    const result = await retryableAPICall(() => getFlexiFormViewIdsByType(type));

    if (typeof result === 'string') {
      if (type === 'EDOC') {
        toast.error(languagePack.getEDocFormTypesError);
      } else {
        toast.error(languagePack.getGenericFormTypesError);
      }
    } else {
      const genericFormEntries = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(result)) {
        if (!alreadySelectedFormIds.includes(key)) {
          genericFormEntries.push({ id: key, label: value, value: key });
        }
      }
      setFormOptions(genericFormEntries);
    }
  }
  useEffect(() => {
    if (type) {
      getForms();
    }
  }, [type]);

  function onFormSelectChange(ids, values) {
    const selectedVals = values.filter((val) => ids.includes(val.id));
    onChange(selectedVals, values);
  }

  if (!checkPermissions(permissionsRequired)) return null;

  return (
    <Row className="mb-2">
      <Col>
        <label htmlFor="eDoc-form-select">{label || languagePack.genericFormSelectionTitle}</label>
        <SelectInput
          disabled={formOptions.length === 0}
          id="eDoc-form-select"
          isMulti
          onChange={(val, values) => onFormSelectChange(val, values)}
          options={formOptions}
          placeholder={formOptions.length === 0 ? languagePack.noAvailableFormsError : languagePack.selFormsToContinue}
          value={selectedValues || {}}
        />
      </Col>
    </Row>
  );
}

FormsSelector.propTypes = {
  alreadySelectedFormIds: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  languagePack: PropTypes.shape(),
  onChange: PropTypes.func,
  permissionsRequired: PropTypes.arrayOf(PropTypes.string),
  selectedValues: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string,
};

FormsSelector.defaultProps = {
  alreadySelectedFormIds: [],
  label: null,
  languagePack: {},
  onChange: () => {},
  permissionsRequired: [],
  selectedValues: [],
  type: null,
};

export default FormsSelector;
