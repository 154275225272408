import { ADD_JOB_SITE_CONFIG, REMOVE_JOB_SITE_CONFIG } from '../constants/actionTypes';

export const addJobSiteConfig = (payload) => ({
  type: ADD_JOB_SITE_CONFIG,
  payload,
});

export const removeJobSiteConfig = () => ({
  type: REMOVE_JOB_SITE_CONFIG,
});
