import PropTypes from 'prop-types';
import { checkPermissions } from '../../../../js/auth/AuthUtils';

function withCan(Comp) {
  function Can({ dispatch, permissions, deniedDisplay, ...rest }) {
    if (!checkPermissions(permissions)) return deniedDisplay;
    return <Comp {...rest} />;
  }

  Can.propTypes = {
    deniedDisplay: PropTypes.shape(),
    dispatch: PropTypes.func,
    permissions: PropTypes.arrayOf(PropTypes.string),
  };

  Can.defaultProps = {
    deniedDisplay: null,
    dispatch: () => {},
    permissions: [],
  };

  return Can;
}

export default withCan;
