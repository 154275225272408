import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Upgrade to react-router v6 has deprecated withRouter
// they suggest changing class based components to functional
// or create this hoc to use hooks
const withRouter = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return <Component {...props} location={location} navigate={navigate} params={params} />;
};

export default withRouter;
