// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconSVG from '../SVG/IconSVG';
import { ACTION_COLOUR } from '../../../js/utils/colours';

function SidebarContainer({ isToggle, defaultOpen, hideBorder, title, children }) {
  const [contentOpen, setContentOpen] = useState(defaultOpen);

  return (
    <div className={`sidebar-container ${hideBorder ? 'no-border' : ''}`}>
      {isToggle && (
        <div
          className="sidebar-head"
          onClick={() => setContentOpen(!contentOpen)}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          {title && <h5 className="side-bar-title">{title}</h5>}
          <IconSVG
            className={`btn-chevron-toggle ${contentOpen ? '' : 'toggle-right'}`}
            fill={ACTION_COLOUR}
            name="Chevron"
          />
        </div>
      )}
      <div className="sidebar-content" style={{ display: !isToggle || contentOpen ? 'block' : 'none' }}>
        {children}
      </div>
    </div>
  );
}

SidebarContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  defaultOpen: PropTypes.bool,
  hideBorder: PropTypes.bool,
  isToggle: PropTypes.bool,
  title: PropTypes.string,
};

SidebarContainer.defaultProps = {
  children: null,
  defaultOpen: false,
  hideBorder: false,
  isToggle: true,
  title: null,
};

export default SidebarContainer;
