import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonComp from './ButtonComp';

export default function DeleteButton({
  className,
  action,
  label,
  isLoading,
  disabled,
  id,
  iconOnly,
  floatRight,
  size,
  innerRef,
}) {
  return (
    <ButtonComp
      className={cx('delete-button', className, { 'float-end': floatRight })}
      onClick={action}
      id={id}
      isLoading={isLoading}
      disabled={disabled}
      loadingIconColor="white"
      iconName="fa-trash"
      iconOnly={iconOnly}
      size={size}
      innerRef={innerRef}
    >
      {label}
    </ButtonComp>
  );
}

DeleteButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  action: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
  floatRight: PropTypes.bool,
  size: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
};

DeleteButton.defaultProps = {
  className: null,
  id: null,
  action: () => {},
  label: 'Delete',
  isLoading: false,
  disabled: false,
  iconOnly: false,
  floatRight: true,
  size: null,
  innerRef: null,
};
