/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import { toast } from 'react-toastify';
import React, { useEffect, useReducer } from 'react';
import { Card, CardBody, Col, Row, Input, Label } from 'reactstrap';
import { retryableAPICall } from '../../../../../../api/common-api-utils';
import {
  createFormStyle,
  deleteFormStyle,
  getAllFormStyles,
  updateFormStyle,
} from '../../../../../../api/FormsAPI/ATSFormsStylesAPI';
import { AccountFormSelect } from '../../../../../Base/Account';
import { CreateButton, IconButton } from '../../../../../Base/Buttons';
import { Loader } from '../../../../../Base/Loading';
import FormStylesHeader from './FormStylesHeader';
import FormStylesIFrame from './FormStylesIFrame';
import FormStylesReducer, {
  ADD_CUSTOM_FONT,
  ADD_GOOGLE_FONT,
  CHANGE_BACKGROUND_IMAGE,
  CHANGE_BACKGROUND_COLOUR,
  CHANGE_LOGO,
  CHANGE_THEME,
  CREATE_STYLE,
  CREATE_STYLE_SUCCESS,
  DELETE_FONT,
  DELETE_STYLE,
  DELETE_STYLE_SUCCESS,
  EDIT_STYLE,
  LOADED_IFRAME,
  LOADED_IFRAME_FAILED,
  LOADED_IFRAME_SUCCESSFULLY,
  LOADED_THEMES,
  UNDO_CHANGES,
  UPDATE_GOOGLE_FONT,
  UPDATE_STYLE,
  UPDATE_STYLE_SUCCESS,
  UPDATE_SHARING,
  UPDATE_NAME,
  UPDATE_IS_DEFAULT,
  DELETE_STYLE_CONFIRM,
  DELETE_STYLE_CANCEL,
  DELETE_STYLE_ERROR,
} from './FormStylesReducer';
import ButtonsSection from './Sections/Buttons';
import ColourSection from './Sections/Colours';
import FontSection from './Sections/Fonts';
import ImageSection from './Sections/Images';
import SectionsSection from './Sections/Sections';

require('rc-collapse/assets/index.css');

const IFRAME_TIMEOUT = 5000;

function FormStyles({ activeAccount }) {
  const [
    {
      isEdited,
      iframeState,
      backgroundImage,
      backgroundColour,
      logo,
      sharedWith,
      isDefault,
      name,
      customFonts,
      isLoadingThemes,
      isLoadingIframe,
      themes,
      currentMUITheme,
      isSaving,
      isDeleteModalOpen,
      selectedTheme,
      isDeleting,
      googleFontText,
    },
    dispatch,
  ] = useReducer(FormStylesReducer, {
    backgroundImage: null,
    currentMUITheme: null,
    backgroundColour: '#ffffff',
    customFonts: [{ type: 'GOOGLE', url: 'Open Sans' }],
    sharedWith: [],
    iframeState: 'UNKNOWN',
    isDefault: false,
    isEdited: false,
    isLoadingThemes: true,
    isLoadingIframe: true,
    logo: null,
    name: '',
    selectedTheme: null,
    themes: [],
    isDeleting: false,
    isDeleteModalOpen: false,
    googleFontText: '',
  });

  const iframe = document.getElementById('form');

  // Only our forms will send this message so we
  // can safely assume that the iframe has loaded
  window.onmessage = (e) => {
    if (e.data === 'successfully_loaded') {
      dispatch({
        type: LOADED_IFRAME_SUCCESSFULLY,
      });
    }
  };

  // If we don't get a message from the iframe it means either the url is wrong
  // or there are error in the ats-forms code
  useEffect(() => {
    let timeOutId = null;
    if (iframe) {
      timeOutId = setTimeout(() => {
        if (iframeState === 'UNKNOWN') {
          dispatch({
            type: LOADED_IFRAME_FAILED,
          });
        }
      }, IFRAME_TIMEOUT);
    }
    return () => {
      if (timeOutId) clearTimeout(timeOutId);
    };
  }, [iframe]);

  // update the iframe with the current theme
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (iframe && iframe.contentWindow) {
      const timeOutId = setTimeout(
        () =>
          iframe.contentWindow.postMessage(
            { backgroundColour, backgroundImage, fonts: customFonts, muiTheme: currentMUITheme, logo },
            '*',
          ),
        250,
      );
      return () => clearTimeout(timeOutId);
    }
  }, [selectedTheme, backgroundColour, customFonts, currentMUITheme, iframe, backgroundImage, logo]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await retryableAPICall(() => getAllFormStyles());
      dispatch({
        type: LOADED_THEMES,
        payload: response,
      });
    }
    fetchData();
  }, []);

  async function handleSave() {
    dispatch({
      type: UPDATE_STYLE,
    });
    try {
      const resp = await retryableAPICall(() =>
        updateFormStyle(
          name,
          selectedTheme,
          currentMUITheme,
          customFonts,
          backgroundImage,
          logo,
          backgroundColour,
          sharedWith,
          isDefault,
        ),
      );

      dispatch({
        type: UPDATE_STYLE_SUCCESS,
        payload: resp,
      });
      toast.success('Form style has been successfully updated');
    } catch (e) {
      toast.error('Failed to update style');
    }
  }

  async function createNew(styleName) {
    dispatch({
      type: CREATE_STYLE,
    });
    try {
      const resp = await retryableAPICall(() =>
        createFormStyle(styleName, [{ accountId: activeAccount.id, accountName: activeAccount.name }]),
      );

      dispatch({
        type: CREATE_STYLE_SUCCESS,
        payload: resp,
      });
      toast.success('Form style has been successfully created');
    } catch (e) {
      toast.error('Failed to create style');
    }
  }

  async function handleDelete(id) {
    dispatch({
      type: DELETE_STYLE_CONFIRM,
    });
    try {
      const resp = await retryableAPICall(() => deleteFormStyle(id));

      if (resp) {
        dispatch({ type: DELETE_STYLE_SUCCESS, payload: id });
        toast.success('Successfully deleted style');
      } else {
        dispatch({ type: DELETE_STYLE_ERROR });
        toast.error('Failed to delete style');
      }
    } catch (error) {
      dispatch({ type: DELETE_STYLE_ERROR });
      toast.error('Failed to delete style');
    }
  }

  // if (!checkPermissions(['forms:admin:style'])) return null;
  return isLoadingThemes ? (
    <Loader wrapperClass="w-100 min-vh-100  d-flex position-absolute m-auto bg-white py-5" />
  ) : (
    <Col className="pt-3 h-100">
      <FormStylesHeader
        changeTheme={(id) => {
          dispatch({
            type: CHANGE_THEME,
            payload: {
              id,
            },
          });
        }}
        isDeleteModalOpen={isDeleteModalOpen}
        isDeleting={isDeleting}
        isEdited={isEdited}
        isLoading={isLoadingThemes || (isLoadingThemes?.length > 0 && isLoadingIframe)}
        isLoadingIframe={isLoadingIframe}
        onDelete={() =>
          dispatch({
            type: DELETE_STYLE,
          })
        }
        onDeleteCancel={() =>
          dispatch({
            type: DELETE_STYLE_CANCEL,
          })
        }
        onDeleteConfirm={() => handleDelete(selectedTheme)}
        onOkay={(val) => {
          createNew(val);
        }}
        selectedTheme={selectedTheme}
        themes={themes}
      />
      <Card className="min-vh-100">
        {themes.length > 0 ? (
          <CardBody>
            {isLoadingIframe && (
              <Loader wrapperClass="iframe-loading-wrapper min-vh-100  d-flex position-absolute m-auto bg-white py-5" />
            )}
            <Row>
              <FormStylesIFrame
                iframeState={iframeState}
                onIframeLoaded={() => {
                  if (iframe && iframe.contentWindow) {
                    iframe.contentWindow.postMessage(
                      { backgroundColour, backgroundImage, fonts: customFonts, muiTheme: currentMUITheme, logo },
                      '*',
                    );
                  }

                  dispatch({
                    type: LOADED_IFRAME,
                  });
                }}
              />
              <Col>
                <Row>
                  <Col xs="6">
                    <Label for="form-name">Style name</Label>
                    <Input
                      onChange={(e) => {
                        dispatch({
                          type: UPDATE_NAME,
                          payload: e.target.value,
                        });
                      }}
                      type="text"
                      value={name}
                    />
                  </Col>
                  <Col className="m-auto" xs="6">
                    <Label check className="pt-4">
                      <Input
                        checked={isDefault}
                        className="standard-checkbox small position-relative ms-1 me-2"
                        onChange={() => {
                          dispatch({
                            type: UPDATE_IS_DEFAULT,
                          });
                        }}
                        type="checkbox"
                      />
                      <span style={{ bottom: 0 }}>This is the default style</span>
                    </Label>
                  </Col>
                </Row>
                <AccountFormSelect
                  className="mt-3"
                  currentAccounts={(sharedWith || []).map(({ accountId, accountName }) => ({
                    id: accountId,
                    name: accountName,
                  }))}
                  id="sharedWith"
                  isMulti
                  label="Shared With"
                  onChange={(accountObjs = []) => {
                    dispatch({
                      type: UPDATE_SHARING,
                      payload: accountObjs,
                    });
                  }}
                />
                <Collapse accordion>
                  <Panel header="Colours">
                    <ColourSection
                      changeStyle={(property, val) => {
                        dispatch({
                          type: EDIT_STYLE,
                          payload: {
                            property,
                            val,
                          },
                        });
                      }}
                      currentMUITheme={currentMUITheme}
                    />
                  </Panel>
                  <Panel header="Buttons">
                    <ButtonsSection
                      changeStyle={(property, val) => {
                        dispatch({
                          type: EDIT_STYLE,
                          payload: {
                            property,
                            val,
                          },
                        });
                      }}
                      currentMUITheme={currentMUITheme}
                      customFonts={customFonts}
                    />
                  </Panel>
                  <Panel header="Images">
                    <ImageSection
                      backgroundColour={backgroundColour}
                      backgroundImage={backgroundImage}
                      changeBackgroundColour={(val) => {
                        dispatch({
                          type: CHANGE_BACKGROUND_COLOUR,
                          payload: val,
                        });
                      }}
                      changeBackgroundImage={(val) => {
                        dispatch({
                          type: CHANGE_BACKGROUND_IMAGE,
                          payload: val,
                        });
                      }}
                      changeLogo={(val) => {
                        dispatch({
                          type: CHANGE_LOGO,
                          payload: val,
                        });
                      }}
                      changeStyle={(property, val) => {
                        dispatch({
                          type: EDIT_STYLE,
                          payload: {
                            property,
                            val,
                          },
                        });
                      }}
                      currentMUITheme={currentMUITheme}
                      logo={logo}
                    />
                  </Panel>
                  <Panel header="Font">
                    <FontSection
                      addFont={() => {
                        dispatch({
                          type: ADD_GOOGLE_FONT,
                        });
                      }}
                      changeStyle={(property, val) => {
                        dispatch({
                          type: EDIT_STYLE,
                          payload: {
                            property,
                            val,
                          },
                        });
                      }}
                      currentMUITheme={currentMUITheme}
                      customFonts={customFonts}
                      googleFontText={googleFontText}
                      deleteFont={(font) => {
                        dispatch({
                          type: DELETE_FONT,
                          payload: font,
                        });
                      }}
                      addCustomFont={(url, fontName) => {
                        dispatch({
                          type: ADD_CUSTOM_FONT,
                          payload: { url, fontName },
                        });
                      }}
                      updateFont={(val) => {
                        dispatch({
                          type: UPDATE_GOOGLE_FONT,
                          payload: val,
                        });
                      }}
                    />
                  </Panel>
                  <Panel header="Section Headers">
                    <SectionsSection
                      changeStyle={(property, val) => {
                        dispatch({
                          type: EDIT_STYLE,
                          payload: {
                            property,
                            val,
                          },
                        });
                      }}
                      currentMUITheme={currentMUITheme}
                      customFonts={customFonts}
                    />
                  </Panel>
                </Collapse>
                {isEdited && (
                  <CreateButton
                    action={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}
                    className="mt-3 me-3"
                    disabled={isSaving || !isEdited}
                    floatRight={false}
                    isLoading={isSaving}
                    label={isSaving ? 'Saving...' : 'Save'}
                  />
                )}
                {isEdited && (
                  <IconButton
                    action={(e) => {
                      e.preventDefault();
                      dispatch({
                        type: UNDO_CHANGES,
                      });
                    }}
                    className="mt-3 ml-3"
                    disabled={isSaving}
                    floatRight={false}
                    iconClassName="btn-undo"
                    label="Undo"
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        ) : (
          <CardBody>
            <Row className="pt-5">
              <Col>
                <h3 className="text-center">Please Create a style</h3>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </Col>
  );
}

FormStyles.propTypes = {
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

FormStyles.defaultProps = {
  activeAccount: {},
};

export default FormStyles;
