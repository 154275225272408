/* eslint-disable max-len */
import React from 'react';
import BaseSVG from '../BaseSVG';

function ShareNodes(props) {
  return (
    <BaseSVG
      {...props}
      viewBox="0 0 32 32"
      name="ShareNodesIcon"
      // eslint-disable-next-line max-len
      d="M26 21.25c-1.581 0.003-2.981 0.781-3.839 1.974l-0.010 0.014-11.642-5.821c0.152-0.422 0.24-0.909 0.24-1.417s-0.088-0.995-0.249-1.447l0.009 0.030 11.642-5.82c0.87 1.19 2.261 1.953 3.831 1.953 2.614 0 4.733-2.119 4.733-4.733s-2.119-4.733-4.733-4.733c-2.614 0-4.733 2.119-4.733 4.733 0 0.006 0 0.012 0 0.018v-0.001c0.007 0.509 0.095 0.995 0.25 1.449l-0.010-0.033-11.642 5.821c-0.872-1.209-2.277-1.987-3.864-1.987-2.623 0-4.75 2.127-4.75 4.75s2.127 4.75 4.75 4.75c1.587 0 2.992-0.778 3.855-1.974l0.010-0.014 11.642 5.821c-0.146 0.422-0.233 0.908-0.24 1.414l-0 0.003c0 2.623 2.127 4.75 4.75 4.75s4.75-2.127 4.75-4.75c0-2.623-2.127-4.75-4.75-4.75v0zM26 2.75c1.795 0 3.25 1.455 3.25 3.25s-1.455 3.25-3.25 3.25c-1.795 0-3.25-1.455-3.25-3.25v0c0.002-1.794 1.456-3.248 3.25-3.25h0zM6 19.25c-1.795 0-3.25-1.455-3.25-3.25s1.455-3.25 3.25-3.25c1.795 0 3.25 1.455 3.25 3.25v0c-0.002 1.794-1.456 3.248-3.25 3.25h-0zM26 29.25c-1.795 0-3.25-1.455-3.25-3.25s1.455-3.25 3.25-3.25c1.795 0 3.25 1.455 3.25 3.25v0c-0.001 1.794-1.456 3.249-3.25 3.25h-0z"
    />
  );
}

export default ShareNodes;
