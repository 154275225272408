// Contains a set of API calls to middleware services in order to get data around events
import axios from 'axios';
import fileDownload from 'js-file-download';
import config from '../config/config';
import { getCommonHeaders } from './common-api-utils';

const { api, vacancy } = config;

export async function updateVacancyStatus(vacancyIds, status) {
  if (!Array.isArray(vacancyIds)) {
    console.log('updateVacancyStatus needs to be provided an array of IDs');
  } else {
    const req = { ids: vacancyIds, status };
    const result = await axios.put(`${api.middlewareAPIURL}${api.vacancyServicePath}${vacancy.updateStatusPath}`, req, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200) return true;
  }
  return null;
}

export async function submitNewVacancy(formData) {
  const result = await axios.post(`${api.middlewareAPIURL}${api.vacancyServicePath}`, formData, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getVacancyCategories() {
  const result = await axios.get(`${api.middlewareAPIURL}${api.vacancyServicePath}/categories`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getVacancyCategoryList() {
  const result = await axios.get(`${api.middlewareAPIURL}${api.vacancyServicePath}/categories/list`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function updateVacancyCategories(categories) {
  const result = await axios.put(`${api.middlewareAPIURL}${api.vacancyServicePath}/categories`, categories, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function copyVacancy(vacancyId, name) {
  const body = {
    vacancyId,
    name,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${api.vacancyServicePath}/copy`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function updateVacancy(id, formData) {
  const result = await axios.put(`${api.middlewareAPIURL}${api.vacancyServicePath}/${id}`, formData, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getVacancy(vacancyId, includePublishings) {
  const includeAdsQS = includePublishings ? '?includeAds=true' : '';

  const result = await axios.get(`${api.middlewareAPIURL}${api.vacancyServicePath}/${vacancyId}${includeAdsQS}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getVacancyFunnel(vacancyId, funnelId = 'DEFAULT') {
  const result = await axios.get(
    `${api.middlewareAPIURL}${api.vacancyServicePath}${vacancy.vacancyFunnelPath}/${funnelId}/${vacancyId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getCreateVacancyTemplate(fields) {
  let url = `${api.middlewareAPIURL}/vacancy/template`;
  if (fields) url += `?fields=${fields}`;

  const result = await axios.get(url, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getCreateVacancyTemplateById(id) {
  const result = await axios.get(`${api.middlewareAPIURL}/vacancy/template/${id}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getVacancyListForAccounts() {
  const result = await axios.get(`${api.middlewareAPIURL}${api.vacancyServicePath}/list/account`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createVacancyTemplate(name, description, readOnly, share, data) {
  const payload = {
    name,
    description,
    data,
    readOnly,
    share,
  };

  const result = await axios.post(`${api.middlewareAPIURL}/vacancy/template`, payload, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function updateVacancyTemplate(id, name, description, readOnly, share, data) {
  if (id) {
    const payload = {
      id,
      name,
      description,
      data,
      readOnly,
      share,
    };

    const result = await axios.put(`${api.middlewareAPIURL}/vacancy/template`, payload, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function deleteVacancyTemplate(id) {
  const result = await axios.delete(`${api.middlewareAPIURL}/vacancy/template/${id}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getCreatedBy() {
  const result = await axios.get(`${api.middlewareAPIURL}${vacancy.createdByUserPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function downloadVacancyCSV(vacancyIds, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.post(`${api.middlewareAPIURL}${vacancy.vacancyExportPath}`, vacancyIds, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 && result.data) {
    const { contentType, data, fileName } = result.data;

    if (base64) return data;

    const base64Str = await fetch(`data:${contentType};base64,${data}`);
    const blob = await base64Str.blob();

    if (forceDownload) return fileDownload(blob, fileName);

    return blob;
  }

  return null;
}

export async function assignUserToVacancy(vacancyIds, userId, userName) {
  const payload = {
    userToAssign: { userId, userName },
    vacancyIds,
  };

  const result = await axios.post(`${api.middlewareAPIURL}/vacancy/assign`, payload, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function newApplications(lastLoginTime) {
  const result = await axios.get(`${api.middlewareAPIURL}/vacancy/count/new-applications?since=${lastLoginTime}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
