const iconLookup = {
  EMAIL_SENT: { name: 'Email' },
  EMAIL_RECEIVED: { name: 'Email' },
  SMS_SENT: { name: 'Message' },
  SMS_RECEIVED: { name: 'Message' },
  APPLICATION_RECEIVED: { name: 'File' },
  APPLICATION_EDITED: { name: 'Pencil' },
  ATTACHMENT_ADDED: { name: 'File' },
  ATTACHMENT_DELETED: { name: 'Bin' },
  CANDIDATE_EDITED: { name: 'Pencil' },
  CANDIDATE_FORWARDED: { name: 'Message' },
  CANDIDATE_DOWNLOADED: { name: 'File' },
  DOCUMENT_RECIEVED: { name: 'File' },
  DOCUMENT_REMINDER: { name: 'Pencil' },
  FUNNEL_STATE_CHANGE: { name: 'Funnel' },
  NOTE_ADDED: { name: 'File' },
  GENERIC_FORM_REQUESTED: { name: 'Message' },
  GENERIC_FORM_RECIEVED: { name: 'File' },
  NOTE_DELETED: { name: 'File' },
  NOTE_EDITED: { name: 'Pencil' },
  TAGS_UPDATED: { name: 'Tag' },
  INVITE_STATE_CHANGE: { name: 'Edit' },
  REFEREE_DETAILS_RECEIVED: { name: 'File' },
  REFEREE_DETAILS_UPDATED: { name: 'Pencil' },
  REFERENCE_UPDATED: { name: 'Pencil' },
  REFERENCE_RECEIVED: { name: 'File' },
  REFEREES_REQUESTED: { name: 'Message' },
  REFEREE_REMINDER: { name: 'Clock', styles: { marginBottom: '2px' } },
  INVITE_TO_EVENT: { name: 'Message' },
  EVENT_INVITE_CANCELLED: { name: 'Cross' },
  INSTANT_EVENT_BOOKING: { name: 'Message' },
  RTW_REQUESTED: { name: 'Message' },
  RTW_RECEIVED: { name: 'Email' },
  RTW_REMINDER_1_SENT: { name: 'Email' },
  RTW_REMINDER_2_SENT: { name: 'Email' },
  RTW_REQUEST_FAILURE: { name: 'Cross' },
  PERSONALITY_TEST_RECEIVED: { name: 'Email' },
  PERSONALITY_TEST_REQUESTED: { name: 'Message' },
  PERSONALITY_TEST_REMINDER_1_SENT: { name: 'Email' },
  PERSONALITY_TEST_REMINDER_2_SENT: { name: 'Email' },
  PT_TEST_REQUEST_FAILURE: { name: 'Cross' },
  ONBOARDING_REQUESTED: { name: 'Message' },
  ONBOARDING_RECEIVED: { name: 'Email' },
  ONBOARDING_REMINDER: { name: 'Email' },
  ONBOARDING_REMINDER_2_SENT: { name: 'Email' },
  ONBOARDING_EDITED: { name: 'Pencil' },
  PT_TEST_REQUESTED: { name: 'Message' },
  COMPLETED_PT_TEST_RECEIVED: { name: 'Email' },
  SUBMISSION_RESULT: { name: 'Email' },
  CANDIDATE_BOOKED_IN_TO_EVENT: { name: 'Calendar' },
  NOTE: { name: 'File' },
  VACANCY_CHANGED: { name: 'Chevron Left' },
  WORKFLOW_BEGIN: { name: 'Gear' },
  WORKFLOW_COMPLETE: { name: 'Tick' },
  WORKFLOW_FAILED_TEMP: { name: 'Exclamation Circle' },
  WORKFLOW_FAILED: { name: 'Cross' },
};

export default iconLookup;
