import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { useErrors } from '../hooks';
import IconSVG from '../SVG/IconSVG';

export default function ErrorMessage({ error }) {
  const [headline, message] = useErrors(error);

  return (
    <Container>
      <Row className="align-items-center mt-3">
        <Col>
          <Row className="text-center align-middle mb-3">
            <Col><IconSVG name="Falling People" width="6em" fill="#8A9EAD" /></Col>
          </Row>
          <Row>
            <Col><p className="large-blue-text">{headline}</p></Col>
          </Row>
          <Row>
            <Col>
              <p className="text-center">{message}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ErrorMessage.defaultProps = {
  error: 500,
};
