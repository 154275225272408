import {
  ADD_VACANCY,
  DELETE_VACANCY,
  RESET_ACTIVE_VACANCY_CANDIDATES,
  RESET_VACANCY_STATE,
  SET_VACANCY_CANDIDATE_PAGE,
  SET_VACANCY_APPLICATION_URL,
  UPDATE_VACANCY_LAST_VIEWED_DT,
  UPDATE_VACANCY,
} from '../constants/actionTypes';

export const addVacancy = (vacancy) => ({
  payload: vacancy,
  type: ADD_VACANCY,
});

export const updateVacancyAct = (vacancy) => ({
  payload: vacancy,
  type: UPDATE_VACANCY,
});

export const deleteVacancy = (id) => ({
  payload: id,
  type: DELETE_VACANCY,
});

export const resetVacancyState = () => ({
  type: RESET_VACANCY_STATE,
});

export const setVacancyApplicantPage = (page) => ({
  payload: page,
  type: SET_VACANCY_CANDIDATE_PAGE,
});

export const setVacancyApplicationURL = (urlData) => ({
  type: SET_VACANCY_APPLICATION_URL,
  payload: urlData,
});

export const updateLastViewedDT = (vacancyId) => ({
  type: UPDATE_VACANCY_LAST_VIEWED_DT,
  payload: vacancyId,
});

export const resetVacancyCandidates = () => ({
  type: RESET_ACTIVE_VACANCY_CANDIDATES,
});
