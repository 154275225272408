import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import IconSVG from '../SVG/IconSVG';

export default function NoDataMessage({
  img,
  title,
  message,
  children,
}) {
  return (
    <Row>
      <Col xs="12" className="center-block text-center h-100">
        {img && (
          <Row className="mb-2 mt-5">
            <Col>
              {img || <IconSVG name="No Data Triangle" width="6em" fill="#8A9EAD" />}
            </Col>
          </Row>
        )}
        <Row className="mb-2 mt-5">
          <Col>
            <h2>{title}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{message}</p>
          </Col>
        </Row>
        {children}
      </Col>
    </Row>
  );
}

NoDataMessage.propTypes = {
  img: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NoDataMessage.defaultProps = {
  img: null,
  children: null,
};
