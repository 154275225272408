import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { TextButton } from '../../../Base/Buttons';
import StageInputsGroup from './StageInputsGroup';
import { deleteObjFromArray, updateObjInArray } from '../../../../js/utils/arrayOfObjects';
import { moveArrayItem } from '../../../../js/utils/moveArrayItem';

function StageInput({ stages, onChange, isEditing, errors, languagePack }) {
  const handleChange = useCallback(
    (stageId, value, type, stageConfig, title, url, message) => {
      const stageObj = stages.find(({ id }) => id === stageId);
      if (stageObj) {
        const updated = updateObjInArray(
          stages,
          {
            ...stageObj,
            name: value,
            type,
            stageConfig: stageConfig || null,
            supplementalInfo: {
              title,
              url,
              message,
            },
          },
          stageObj.id,
        );
        onChange(updated);
      }
    },
    [onChange, stages],
  );

  const handleDeleteStage = useCallback(
    (id) => {
      const updated = deleteObjFromArray(stages, id);
      onChange(updated.map((stage, i) => ({ ...stage, order: i })));
    },
    [onChange, stages],
  );

  const handleAddStage = useCallback(() => {
    const clone = [...stages];
    clone.splice(stages.length - 1, 0, {
      name: '',
      type: 'CUSTOM',
      supplementalInfo: {},
      id: uuidv4(),
      isNew: true,
    });

    onChange(clone.map((stage, i) => ({ ...stage, order: i })));
  }, [onChange, stages]);

  const handleOrderStage = useCallback(
    (fromIndex, toIndex) => {
      const updatedCols = moveArrayItem(stages, fromIndex, toIndex).map((stage, i) => ({ ...stage, order: i }));
      onChange(updatedCols);
    },
    [onChange, stages],
  );

  let hiredStage, rejectedStage, defaultStage;
  const customStages = [];

  stages.forEach((stage) => {
    switch (stage.type) {
      case 'HIRED':
        hiredStage = stage;
        break;
      case 'REJECTED':
        rejectedStage = stage;
        break;
      case 'DEFAULT':
        defaultStage = stage;
        break;
      case 'CUSTOM':
      case 'INTERVIEW':
      case 'REFERENCES':
      case 'ONBOARDING':
      case 'RTW':
        customStages.push(stage);
        break;
      default:
        break;
    }
  });

  return (
    <div className="funnel-admin-stage-input">
      <h6>{languagePack.rejectedStageNameLabel}</h6>
      <StageInputsGroup
        languagePack={languagePack}
        key={rejectedStage.id}
        helpText={languagePack.rejectedStageHelpText}
        {...rejectedStage}
        onChange={handleChange}
        onDelete={handleDeleteStage}
        onOrder={handleOrderStage}
        allowDelete={!isEditing}
        error={errors[rejectedStage.id]}
      />
      <h6>{languagePack.defaultStageNameLabel}</h6>
      <StageInputsGroup
        languagePack={languagePack}
        helpText={languagePack.defaultStageHelpText}
        key={defaultStage.id}
        {...defaultStage}
        onChange={handleChange}
        onDelete={handleDeleteStage}
        onOrder={handleOrderStage}
        allowDelete={!isEditing}
        error={errors[defaultStage.id]}
      />
      <h6>Custom Stages</h6>
      {customStages.map((stage) => (
        <StageInputsGroup
          languagePack={languagePack}
          key={stage.id}
          {...stage}
          onChange={handleChange}
          onDelete={handleDeleteStage}
          onOrder={handleOrderStage}
          allowDelete={!isEditing}
          error={errors[stage.id]}
        />
      ))}
      <TextButton
        className="btn-outline mb-4"
        label={`+ ${languagePack.addStageBtnLabel}`}
        size="sm"
        floatRight={false}
        action={handleAddStage}
      />
      <h6>{languagePack.hiredStageNameLabel}</h6>
      <StageInputsGroup
        languagePack={languagePack}
        helpText={languagePack.hiredStageHelpText}
        key={hiredStage.id}
        {...hiredStage}
        onChange={handleChange}
        onDelete={handleDeleteStage}
        onOrder={handleOrderStage}
        allowDelete={!isEditing}
        error={errors[hiredStage.id]}
      />
    </div>
  );
}

StageInput.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.number,
      type: PropTypes.string,
      id: PropTypes.string,
      stageConfig: PropTypes.shape(),
      url: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
  errors: PropTypes.shape(),
  languagePack: PropTypes.shape(),
};

StageInput.defaultProps = {
  stages: [],
  onChange: () => {},
  isEditing: false,
  errors: {},
  languagePack: {},
};

export default StageInput;
