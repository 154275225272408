import {
  COMPANY_DETAILS_UPDATE,
  COMPANY_RESET_STATE,
} from '../constants/actionTypes';

const defaultState = {
  companyDetails: {
    companyId: '',
    companyName: undefined,
    companyLogo: undefined,
    companyIdent: undefined,
    contactEmail: undefined,
  },
};

const initialState = {
  companyDetails: {
    companyId: '',
    companyName: undefined,
    companyLogo: undefined,
    companyIdent: undefined,
    contactEmail: undefined,
  },
};

const companyDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_DETAILS_UPDATE:
      return {
        ...state,
        companyDetails: action.payload,
      };
    case COMPANY_RESET_STATE:
      return { ...defaultState };
    default:
      return state;
  }
};

export default companyDataReducer;
