import { SET_IS_LOADING, SET_CATEGORIES } from '../constants/actionTypes';

const initialState = { categories: [], isLoading: true };

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default categoryReducer;
