import { DELETE_FUNNEL_STAGES, SET_FUNNEL_STAGES } from '../constants/actionTypes';

export const setFunnelStages = (payload) => ({
  type: SET_FUNNEL_STAGES,
  payload,
});

export const deleteFunnelStages = () => ({
  type: DELETE_FUNNEL_STAGES,
});
