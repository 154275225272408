import { useEffect } from 'react';

const $root = document.getElementById('root');

// https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
export default function useOutsideClick(ref, callback, elmSelector) {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    $root.addEventListener('click', handleClick);

    let $elm;
    if (elmSelector) {
      const nodeList = document.querySelectorAll(elmSelector);
      if (nodeList.length) [$elm] = nodeList;
    }
    if ($elm) $elm.addEventListener('click', handleClick);

    return () => {
      $root.removeEventListener('click', handleClick);
      if ($elm) $elm.removeEventListener('click', handleClick);
    };
  });
}
