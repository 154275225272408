import React from 'react';
import PropTypes from 'prop-types';

export default function RadioBtns({ schema, uiSchema, formData, removable, removableCB, name, onChange }) {
  const { name: schemaName, title, enum: radioItems, enumNames } = schema;
  const { display } = uiSchema;

  // useEffect(() => {
  //   radioItems.forEach((item, i) => {
  //     const checked = formData ? item === formData : i === 0;
  //     if (checked) {
  //       setTimeout(() => {
  //         onChange(item);
  //       }, 100);
  //     }
  //   });
  // }, [formData, onChange, radioItems]);

  function handleOptionChange(newVal) {
    onChange(newVal);
  }

  const isDisabled = uiSchema && uiSchema['ui:readonly'];
  const style = isDisabled ? { backgroundColor: 'lightgray', cursor: 'forbidden' } : {};

  return (
    <span style={{ display: display || 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <div>
        {removable && (
          <a
            href="#remove"
            onClick={(e) => {
              e.preventDefault();
              removableCB(schemaName);
            }}
          >
            <i className="fa fa-remove" />
          </a>
        )}
        <span className="control-label mb-2">{title}</span>
      </div>
      <div className="field-radio-group">
        {radioItems.map((item, i) => {
          const isChecked = formData ? item === formData : i === 0;
          const id = `radio-${i}-${item}`;
          return (
            <label key={item} htmlFor={id}>
              <input
                disabled={isDisabled}
                type="radio"
                name={name}
                id={id}
                value={item}
                defaultChecked={isChecked}
                onClick={(e) => handleOptionChange(e.currentTarget.value)}
                style={style}
              />
              <span>{enumNames[i] || item}</span>
            </label>
          );
        })}
      </div>
    </span>
  );
}

RadioBtns.propTypes = {
  schema: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    enum: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
    enumNames: PropTypes.arrayOf(PropTypes.string),
  }),
  uiSchema: PropTypes.shape({
    display: PropTypes.string,
  }),
  formData: PropTypes.string,
  removable: PropTypes.bool,
  removableCB: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

RadioBtns.defaultProps = {
  schema: {},
  uiSchema: {},
  formData: null,
  removable: false,
  removableCB: () => {},
  name: null,
  onChange: () => {},
};
