import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

function TippyTruncate({
  maxWidth,
  tippyContent,
  children,
}) {
  return (
    <Tippy
      theme="ats"
      maxWidth={maxWidth}
      content={tippyContent || children}
      delay={[2000, null]}
    >
      <span className="text-truncate">
        {children}
      </span>
    </Tippy>
  );
}

TippyTruncate.propTypes = {
  maxWidth: PropTypes.string,
  tippyContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TippyTruncate.defaultProps = {
  maxWidth: '200px',
  tippyContent: null,
};

export default TippyTruncate;
