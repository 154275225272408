import React from 'react';
import PropTypes from 'prop-types';

function TagDisplay({
  tag,
  deleteTag,
  onDelete,
  className,
}) {
  const { name, id, label } = tag;

  return (
    <div className={`react-tags__selected-tag ${className || ''}`} title={name}>
      <span className="react-tags__selected-tag-name">
        {name || label}
      </span>
      {deleteTag && (
        <span className="react-tags__selected-tag-delete">
          <a
            href="/deletetag"
            onClick={(e) => {
              e.preventDefault();
              onDelete(id);
            }}
          >
            ×
          </a>
        </span>
      )}
    </div>
  );
}

TagDisplay.propTypes = {
  tag: PropTypes.shape(),
  deleteTag: PropTypes.bool,
  onDelete: PropTypes.func,
  className: PropTypes.string,
};

TagDisplay.defaultProps = {
  tag: {},
  deleteTag: true,
  onDelete: () => { },
  className: null,
};

export default TagDisplay;
