/* eslint-disable max-len */
import React from 'react';
import BaseSVG from '../BaseSVG';

function Message(props) {
  return (
    <BaseSVG
      {...props}
      name="Message"
      viewBox="0 0 490 490"
      d="M383.945,490L230.694,364.254H50.716c-27.924,0-50.63-23.11-50.63-51.527V51.535C0.086,23.118,22.792,0,50.716,0h388.568
      c27.924,0,50.63,23.118,50.63,51.535v261.193c0,28.417-22.707,51.527-50.63,51.527h-55.339V490z M50.716,30.614
      c-11.032,0-20.016,9.388-20.016,20.92v261.193c0,11.525,8.984,20.913,20.016,20.913h190.921l111.694,91.633V333.64h85.953
      c11.032,0,20.016-9.388,20.016-20.913V51.535c0-11.533-8.984-20.92-20.016-20.92H50.716z"
    />
  );
}

export default Message;
