/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { CreateButton } from '../../../Base/Buttons';
import { submitToUbeya } from '../../../../api/Integrations/UbeyaAPI';

function UbeyaIntegration({ candidate }) {
  const [smartStatus, setSmartStatus] = useState('NOT_SUBMITTED');

  async function handleSubmit() {
    setSmartStatus('SUBMITTING');
    const result = await retryableAPICall(() => submitToUbeya(candidate.id));

    if (typeof result === 'string') {
      toast.error('Could not submit to Ubeya'); // @todo we need to return relevant errors
      setSmartStatus('NOT_SUBMITTED');
    } else {
      setSmartStatus('SUBMITTED');
    }
  }

  return (
    <>
      <hr />
      <Row>
        <Col>
          <Tippy
            content="Both onboarding and reference information must have been received before sending to Ubeya is enabled"
            disabled={smartStatus === 'SUBMITTING' || smartStatus === 'SUBMITTED'}
          >
            <div className="float-end">
              <CreateButton
                action={handleSubmit}
                disabled={smartStatus === 'SUBMITTING' || smartStatus === 'SUBMITTED'}
                label="Send to Ubeya"
              />
            </div>
          </Tippy>
        </Col>
      </Row>
    </>
  );
}

UbeyaIntegration.propTypes = {
  candidate: PropTypes.shape(),
};

UbeyaIntegration.defaultProps = {
  candidate: null,
};

export default UbeyaIntegration;
