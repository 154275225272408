import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import IconSVG from '../Base/SVG/IconSVG';
import NotificationStatusText from './NotificationStatusText';
import { deleteNotifications, markNotificationAsViewed } from '../../api/EventAPI/EventAPI';
import { deleteNotificationsFromView, toggleNotificationReadState } from '../../js/actions/eventNotificationActions';

function NotificationContent({
  notification,
  action,
  icon,
  toggleReadState,
  deleteNotifs,
  isViewedMode,
  isLink,
  languagePack,
  iconStyle,
}) {
  const { shortContent, ids, isViewed } = notification;

  function doDelete() {
    deleteNotifs(ids);
    deleteNotifications(ids);
  }

  function toggleViewed() {
    toggleReadState(ids, !isViewed);
    markNotificationAsViewed(ids, !isViewed);
  }

  return (
    <Fragment>
      <Row>
        <Col className="notification-item-main-content">
          <span style={{ marginRight: '5px' }}>
            <IconSVG name={icon} styles={iconStyle} />
          </span>
          <p>
            {isLink
            && (
            <a
              href="#markread"
              onClick={(e) => {
                e.preventDefault();
                action();
                // If new dispatch as read
                if (!isViewed) {
                  toggleReadState(ids, true);
                  markNotificationAsViewed(ids, true);
                }
              }}
            >
              {shortContent || ''}
            </a>
            )}
            {!isLink
            && <span>{shortContent || ''}</span>}
          </p>
        </Col>
      </Row>
      <NotificationStatusText
        notification={notification}
        markNotifAsRead={toggleReadState}
        languagePack={languagePack}
        isViewedMode={isViewedMode}
        doDelete={doDelete}
        toggleViewed={toggleViewed}
      />
    </Fragment>
  );
}

NotificationContent.propTypes = {
  notification: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  iconStyle: PropTypes.shape(),
  action: PropTypes.func,
  icon: PropTypes.string,
  toggleReadState: PropTypes.func,
  deleteNotifs: PropTypes.func,
  isLink: PropTypes.bool,
  isViewedMode: PropTypes.bool,
};

NotificationContent.defaultProps = {
  notification: {},
  iconStyle: {},
  languagePack: {},
  action: () => {
  },
  deleteNotifs: () => {
  },
  icon: null,
  isLink: true,
  isViewedMode: false,
  toggleReadState: () => {
  },
};

function mapDispatchToProps(dispatch) {
  return {
    toggleReadState: (notificationIds, isViewed) => {
      dispatch(toggleNotificationReadState(notificationIds, isViewed));
    },
    deleteNotifs: (notificationIds) => {
      dispatch(deleteNotificationsFromView(notificationIds));
    },
  };
}

export default connect(null, mapDispatchToProps)(NotificationContent);
