/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Form, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useFormKeypress, useMounted } from '../../../Base/hooks';
import { EditButton, CreateButton, CancelButton } from '../../../Base/Buttons';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import validation, { mapErrors } from '../../../../js/utils/validation';
import FourthData from './FourthData';
import { retryableAPICall } from '../../../../api/common-api-utils';
import {
  getLinkedAccount,
  unlinkAccount,
  linkAccount,
  updateLinkedAccount,
} from '../../../../api/Integrations/FourthAPI';
import { IntegrationInput } from '../Dashboard';
import { Prompt } from '../../../Base/Modal';
import { addFourthUser, removeFourthUser } from '../../../../js/actions/fourthActions';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';

async function accountProxy(username, password, organisationId, edit, unlink) {
  let resp;

  if (username && password && organisationId) {
    if (unlink) {
      resp = await retryableAPICall(() => unlinkAccount(username, password, organisationId));
    } else if (edit) {
      resp = await retryableAPICall(() => updateLinkedAccount(username, password, organisationId));
    } else {
      resp = await retryableAPICall(() => linkAccount(username, password, organisationId));
    }
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  return resp;
}

function FourthAdmin({ user, connected, setFourthUser, deleteFourthUser }) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  useEffect(() => {
    setFormData({ ...user, password: '' });
    if (connected) setIsAuthorised(true);
  }, [connected, user]);

  useEffect(() => {
    if (isMounted()) {
      const fetchData = async () => {
        const resp = await accountProxy();

        if (typeof resp === 'string') {
          if (resp !== 'NOT_FOUND_ERROR') {
            toast.error('Error fetching account information. Please try again later or contact support');
          }
        } else {
          const { name: username, organisationId } = resp;
          setFourthUser(username, organisationId);
        }
      };
      if (!connected) fetchData();
    }
  }, [connected, isMounted, setFourthUser]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function handleSave(editCreds, unlink) {
    setIsSaving(true);

    const errObj = validation(
      [
        { id: 'username', required: true },
        { id: 'password', required: true },
        { id: 'organisationId', required: true },
      ],
      formData,
    );

    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const { username, password, organisationId } = formData;
      const resp = await accountProxy(username, password, organisationId, editCreds, unlink);

      if (typeof resp === 'string' && resp.length) {
        toast.error('Error submitting data. Please try again later or contact support');
      } else if (typeof resp === 'string' && !resp.length) {
        const successMsg = `Account ${unlink ? 'Unlinked' : 'Linked'} successfully`;

        toast.success(successMsg);
        setIsAuthorised(!unlink);
        setIsEditing(false);

        if (unlink) {
          deleteFourthUser();
        } else {
          setFourthUser(username, organisationId);
        }
      }
    }

    setIsSaving(false);
  }

  if (!checkPermissions(['fourth:admin'])) return null;

  return (
    <Fragment>
      <Col className="pt-3">
        <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
          <EnhancedCard className="mb-4 mt-2">
            <EnhancedCardTitle
              title="Fourth Integration"
              subtitle="Manage your connection details to pass candidates to Fourth"
            />
            <Form innerRef={formRef}>
              <IntegrationInput
                label="Username"
                id="username"
                value={formData.username || ''}
                onChange={(val) => handleChange('username', val)}
                error={errors.username}
                isAuthorised={isAuthorised}
                isEditing={isEditing}
              />
              <IntegrationInput
                label="Password"
                id="password"
                type="password"
                value={formData.password || ''}
                onChange={(val) => handleChange('password', val)}
                error={errors.password}
                isAuthorised={isAuthorised}
                isEditing={isEditing}
                hideValue
              />
              <IntegrationInput
                label="Organisation ID"
                id="organisationId"
                value={formData.organisationId || ''}
                onChange={(val) => handleChange('organisationId', val)}
                error={errors.organisationId}
                isAuthorised={isAuthorised}
                isEditing={isEditing}
              />
              {isAuthorised && !isEditing ? (
                <Fragment>
                  <EditButton
                    className="mt-2"
                    floatRight={false}
                    label="Edit Credentials"
                    action={() => setIsEditing(true)}
                  />
                  <CancelButton
                    className="mt-2"
                    label="Unlink Account"
                    isLoading={isSaving}
                    disabled={isSaving}
                    action={(e) => {
                      e.preventDefault();
                      setIsPromptOpen(true);
                    }}
                  />
                </Fragment>
              ) : (
                <CreateButton
                  className="mt-2"
                  label={isSaving ? 'Authenticating...' : 'Link Account'}
                  isLoading={isSaving}
                  disabled={isSaving}
                  floatRight={false}
                  action={(e) => {
                    e.preventDefault();
                    handleSave(isEditing);
                  }}
                />
              )}
              {isEditing && (
                <CancelButton
                  className="mt-2 ms-2"
                  isLoading={isSaving}
                  disabled={isSaving}
                  floatRight={false}
                  action={() => {
                    setIsEditing(false);
                    setErrors({});
                  }}
                />
              )}
            </Form>
            {isAuthorised && (
              <Fragment>
                <hr />
                <h4>Field Data</h4>
                <FourthData />
              </Fragment>
            )}
          </EnhancedCard>
        </div>
      </Col>
      <Prompt
        isOpen={isPromptOpen}
        onCancel={() => setIsPromptOpen(false)}
        closeOnOkay={false}
        title="Please provide account password to Unlink account"
        inputType="password"
        onChange={(val) => handleChange('password', val)}
        onOkay={(val) => {
          if (val.length) {
            handleSave(false, true);
            setIsPromptOpen(false);
          }
        }}
      />
    </Fragment>
  );
}

FourthAdmin.propTypes = {
  user: PropTypes.shape(),
  connected: PropTypes.bool,
  setFourthUser: PropTypes.func,
  deleteFourthUser: PropTypes.func,
};

FourthAdmin.defaultProps = {
  user: {
    username: '',
    organisationId: '',
  },
  connected: false,
  setFourthUser: () => {},
  deleteFourthUser: () => {},
};

function mapStateToProps(state) {
  const {
    fourth: { user, connected },
  } = state;
  return { user, connected };
}

function mapDispatchToProps(dispatch) {
  return {
    setFourthUser: (username, organisationId) => {
      dispatch(addFourthUser(username, organisationId));
    },
    deleteFourthUser: () => {
      dispatch(removeFourthUser());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthAdmin);
