import React from 'react';
import PropTypes from 'prop-types';
import BuildDetails from './BuildDetails';
import { ucfirst } from '../../../../js/utils/general-utils';

export default function SkillsSummary({ data }) {
  if (!data || !data.length) return null;
  const skillsList = data.map(({ name }) => ucfirst(name));
  return <BuildDetails title="Skills Summary">{skillsList.join(', ')}</BuildDetails>;
}

SkillsSummary.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

SkillsSummary.defaultProps = {
  data: [],
};
