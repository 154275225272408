import { COMPANY_DETAILS_UPDATE, COMPANY_RESET_STATE } from '../constants/actionTypes';

export const updateCompanyDetails = (companyDetails) => ({
  type: COMPANY_DETAILS_UPDATE,
  payload: companyDetails,
});

export const resetCompanyState = () => ({
  type: COMPANY_RESET_STATE,
});
