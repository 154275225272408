import React from 'react';
import PropTypes from 'prop-types';

function Video({
  src,
  poster,
  className,
  maxWidth,
  innerRef,
  vtt,
}) {
  if (!src) return null;

  return (
    <video
      src={src}
      controls
      playsInline
      width="100%"
      poster={poster}
      ref={innerRef}
      className={className}
      style={{ maxWidth }}
    >
      <track kind="captions" />
      {vtt && <track label="English" kind="subtitles" srcLang="en" src={`data:text/vtt;base64, ${vtt}`} default /> }
    </video>
  );
}

Video.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  vtt: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
};

Video.defaultProps = {
  src: null,
  poster: null,
  className: null,
  maxWidth: null,
  innerRef: null,
  vtt: null,
};

export default Video;
