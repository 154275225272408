/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Required from '../../../Base/Forms/Custom/Required';
import { useLanguage } from '../../../Base/hooks';
import ErrorFeedback from '../../../Base/ErrorFeedback/ErrorFeedback';
import { AccountFormSelect } from '../../../Base/Account';
import { saveVacancyTemplate } from '../../../../js/language/pages';

function FormHeader({ data, onChange, isError, totalAccounts }) {
  const { langPack: languagePack } = useLanguage(saveVacancyTemplate);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (Object.keys(data).length) setFormData(data);
  }, [data]);

  function handleChange(id, val) {
    const updatedData = { ...formData, [id]: val };
    setFormData(updatedData);
    onChange(updatedData);
  }

  const { inputs: inpLang = {} } = languagePack;

  return (
    <Form tag="div">
      <FormGroup>
        <Label for="name">
          {`${inpLang.name || 'Form name'}:`}
          <Required />
        </Label>
        <Input
          type="text"
          id="name"
          value={formData.name || ''}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        {isError && <ErrorFeedback message="Required field" />}
      </FormGroup>
      <FormGroup>
        <Label for="description">{`${inpLang.description || 'Description'}:`}</Label>
        <Input
          type="textarea"
          id="description"
          value={formData.description || ''}
          onChange={(e) => handleChange('description', e.target.value)}
        />
      </FormGroup>
      {totalAccounts > 1 && (
        <Fragment>
          <FormGroup>
            <Label check>
              <Input
                type="checkbox"
                className="standard-checkbox small position-relative ms-0"
                checked={!formData.readOnly}
                onChange={(e) => handleChange('readOnly', !e.target.checked)}
              />
              <span>{inpLang.readOnly || 'Editable'}</span>
            </Label>
          </FormGroup>
          <FormGroup>
            <AccountFormSelect
              id="sharedWith"
              label={`${inpLang.sharedWith || 'Shared With'}:`}
              className="account-opts"
              currentAccounts={(formData.sharedWith || []).map(({ accountId, accountName }) => ({
                id: accountId,
                name: accountName,
              }))}
              onChange={(accountObjs = []) => {
                const accounts = accountObjs.map(({ value, label }) => ({ accountId: value, accountName: label }));
                handleChange('sharedWith', accounts);
              }}
              isMulti
              ignoreActiveAccount
            />
          </FormGroup>
        </Fragment>
      )}
    </Form>
  );
}

FormHeader.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  totalAccounts: PropTypes.number,
};

FormHeader.defaultProps = {
  data: {},
  onChange: () => {},
  isError: false,
  totalAccounts: 0,
};

export default FormHeader;
