import React, {useEffect, useState} from 'react';

import {Card, CardBody, Col, Input, Table} from 'reactstrap';
import {toast} from 'react-toastify';
import {useLanguagePack, useMounted} from '../../../Base/hooks';
import {CreateButton, IconButton} from '../../../Base/Buttons';
import {Confirmation} from '../../../Base/Modal';
import {LoadingScreen} from '../../../Base/Loading';
import {retryableAPICall} from "../../../../api/common-api-utils";
import {getRejectionReasons, saveRejectionReasons} from "../../../../api/CandidateAPI/CandidateAPI";
import {v4 as uuidv4} from 'uuid';
import EnhancedCard from '../Common/EnhancedCard';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';

function RejectionReasons() {
  const languagePack = useLanguagePack('rejection-reasons');
  const [isLoading, setIsLoading] = useState(true);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [newRejectionReasonValue, setNewRejectionReasonValue] = useState('');
  const [rejectionReasonToRemove, setRejectionReasonToRemove] = useState(undefined);
  const [isError, setIsError] = useState(false);

  const isMounted = useMounted();

  useEffect(() => {
    const doGetRejectionReasons = async () => {
      const resp = await retryableAPICall(() => getRejectionReasons());
      if (typeof resp === 'string') {
        setIsError(true);
      } else {
        setRejectionReasons(resp);
        setIsLoading(false);
      }
    }
    doGetRejectionReasons();
  }, [isMounted]);

  function doSaveRejectionReasons(rejectionReasonsToSave) {
    return saveRejectionReasons(rejectionReasonsToSave)
      .then((response) => {
        setRejectionReasons(response);
      })
      .catch(() => {
        setIsError(true);
        toast.error(languagePack.couldNotUpdateRejectionReasons);
      });
  }

  function removeRejectionReason() {
    const updatedCategories = rejectionReasons.filter(
      (rejectionReason) => rejectionReason.id !== rejectionReasonToRemove.id,
    );

    doSaveRejectionReasons(updatedCategories).finally(() => {
      setRejectionReasonToRemove(undefined);
    });
  }

  function addRejectionReason() {
    const updatedRejectionReasons = [
      ...rejectionReasons,
      { id: uuidv4(), name: newRejectionReasonValue },
    ];

    doSaveRejectionReasons(updatedRejectionReasons).then(() => {
      setNewRejectionReasonValue('');
    });
  }

  return (
    <Col className="mt-2">
      {isLoading && <LoadingScreen isEmbeded />}
      {!isLoading && (
        <div className="d-flex justify-content-center w-100" style={{marginLeft: '7px'}}>
        <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
          <EnhancedCardTitle
            title="Rejection Reasons"
            subtitle="Customise your rejection reasons to give deeper insights when reporting"
          />
          <CardBody>
            <div className="mb-3">
              <h5 className={"rejection-reasons-header"}>{languagePack.rolesHeading}</h5>
              <p>{languagePack.rolesExplanation}</p>
            </div>
            <>
              {rejectionReasons.length > 0 && (
                <Table style={{ width: '500px' }} className={"rejection-reasons"}>
                  <tbody>
                    {rejectionReasons.map((rejectionReason, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={`${rejectionReason.id}-${index}`}>
                        <td>{rejectionReason.name}</td>
                        <td width={50}>
                          <IconButton
                            iconOnly
                            iconClassName="fa fa-times"
                            className={"rejection-reason-delete"}
                            size="sm"
                            action={() => setRejectionReasonToRemove(rejectionReason)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {rejectionReasons.length === 0 && !isError && (
                <div className="mb-3">
                  <p className="font-weight-bold">
                    {languagePack.noRejectionReasonsLabel}
                  </p>
                </div>
              )}
              {isError && (
                <div className="mb-3">
                  <p className="font-weight-bold">
                    {languagePack.retrieveRejectionReasonError}
                  </p>
                </div>
              )}
            </>

            {!isError && !isLoading && (
              <>
                <Input
                  placeholder={languagePack.addRejectionReasonPlaceholder}
                  className="mb-1 rejection-reasons-input"
                  style={{ width: '500px' }}
                  value={newRejectionReasonValue}
                  onChange={(e) => setNewRejectionReasonValue(e.currentTarget.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') addRejectionReason();
                  }}
                />
                <CreateButton
                  floatRight={false}
                  label={languagePack.addRejectionReasonButtonLabel}
                  action={addRejectionReason}
                  disabled={!newRejectionReasonValue || newRejectionReasonValue === ''}
                />
              </>
            )}
          </CardBody>
        </EnhancedCard>
        </div>
      )}
      <Confirmation
        content={languagePack.removeAreYouSureRejectionReason}
        title={`${languagePack.removeAreYouSureRejectionReasonTitle} ${rejectionReasonToRemove?.name}`}
        confirmCallback={removeRejectionReason}
        show={!!rejectionReasonToRemove}
      />
    </Col>
  );
}

export default RejectionReasons;
