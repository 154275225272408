import React from 'react';
import PropTypes from 'prop-types';

export default function Shortlist({
  isChecked,
  onChange,
}) {
  return (
    <a
      href="#shortlist"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onChange(!isChecked);
      }}
    >
      <span alt="Shortlist" className={`fa fa-star${isChecked ? ' checked' : ''}`} />
    </a>
  );
}

Shortlist.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

Shortlist.defaultProps = {
  isChecked: false,
  onChange: () => { },
};
