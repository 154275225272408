/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import cx from 'classnames';

function WidthDropdown({ value, onChange, disabled }) {
  return (
    <div className="width-dropdown">
      <FormGroup>
        <Label for="inputWidth" className={cx({ 'is-disabled': disabled })}>Width:</Label>
        <select
          disabled={disabled}
          id="inputWidth"
          className="select"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="w-100">100%</option>
          <option value="w-75">75%</option>
          <option value="w-50">50%</option>
          <option value="w-25">25%</option>
        </select>
      </FormGroup>
    </div>
  );
}

WidthDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

WidthDropdown.defaultProps = {
  value: '',
  onChange: () => { },
  disabled: false,
};

export default WidthDropdown;
