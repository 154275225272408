import {
  SET_ACTIVE_REPORTING_FILTER,
  DELETE_ACTIVE_REPORTING_FILTER,
  RESET_FILTER_REPORTING_STATE,
  // UPDATE_ACTIVE_REPORTING_FILTER,
} from '../constants/actionTypes';

const initialState = {
  activeFilters: {},
};

//
const reportingFilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { activeFilters } = state;

  const activeFilterCp = { ...activeFilters };

  switch (type) {
    case SET_ACTIVE_REPORTING_FILTER:
      if (!activeFilterCp[payload.name]) {
        activeFilterCp[payload.name] = [];
      }

      activeFilterCp[payload.name] = (JSON.parse(JSON.stringify(payload)));

      return {
        ...state,
        activeFilters: activeFilterCp,
      };
    case DELETE_ACTIVE_REPORTING_FILTER:
      return {
        ...state,
        activeFilters: {
          ...activeFilters,
          [payload.name]: (activeFilterCp[payload.name] || []).filter((filter) => (
            filter.instanceId !== payload.instanceId
          )),
        },
      };
    case RESET_FILTER_REPORTING_STATE:
      return {
        ...state,
        activeFilters: {},
      };
    default:
      return state;
  }
};

export default reportingFilterReducer;
