import { ADD_FIELD_DATA } from '../constants/actionTypes';

const initialState = {
  fieldData: {},
};

const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { fieldData } = state;

  switch (type) {
    case ADD_FIELD_DATA:

      if (!fieldData[payload.fieldId]) {
        fieldData[payload.fieldId] = {};
      }

      fieldData[payload.fieldId] = payload.data;

      return {
        ...state,
        fieldData: { ...fieldData },
      };
    default:
      return state;
  }
};

export default filterReducer;
