import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { sapnl },
} = config;

// eslint-disable-next-line import/prefer-default-export
export async function submitToSAPNL(candidateId) {
  if (candidateId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${sapnl.basePath}/${candidateId}`,
      {
        submissionRequest: {
          dataFields: {},
        },
      },
      {
        headers: getCommonHeaders(),
      },
    );

    if (result.status === 201 || result.status === 200) return true;
  }
  return null;
}

export async function getSAPNL(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${sapnl.basePath}/${candidateId}`, {
      headers: { ...getCommonHeaders() },
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }
  return null;
}

export async function deleteSAPNL(candidateId) {
  if (candidateId) {
    const result = await axios.delete(`${api.middlewareAPIURL}${sapnl.basePath}/${candidateId}`, {
      headers: { ...getCommonHeaders() },
    });

    if (result.status === 201 || result.status === 200) return true;
  }
  return null;
}
