import {
  ADD_ACCOUNT_USERS,
} from '../constants/actionTypes';

const defaultState = {
  users: [],
};

export default function accountReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ACCOUNT_USERS:
      return {
        ...state,
        users: payload,
      };
    default:
      return state;
  }
}
