import React from 'react';
import BaseSVG from '../BaseSVG';

function Mobile(props) {
  return (
    <BaseSVG
      {...props}
      viewBox="0 0 24 24"
      name="Mobile"
      // eslint-disable-next-line max-len
      d="M8 2C6.34315 2 5 3.34315 5 5V19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19V5C19 3.34315 17.6569 2 16 2H8ZM7 5C7 4.44772 7.44772 4 8 4H16C16.5523 4 17 4.44772 17 5V19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19V5ZM10 17C9.44772 17 9 17.4477 9 18C9 18.5523 9.44772 19 10 19H14C14.5523 19 15 18.5523 15 18C15 17.4477 14.5523 17 14 17H10Z"
    />
  );
}

export default Mobile;
