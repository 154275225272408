// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import LoadingScreen from '../../Base/Loading/LoadingScreen';
import { useLanguagePack, useMounted } from '../../Base/hooks';
import { downloadEDoc, getEDocDetails, sendEDocReminder } from '../../../api/CandidateAPI/EDocAPI';
import { TextButton } from '../../Base/Buttons';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getTemplatesByType } from '../../../api/TemplateAPI';
import EDocRequestCreation from './EDocRequestCreation';

function EDocDetailsContainer({ candidate, vacancyTitle, updateSummary, vacancy }) {
  const languagePack = useLanguagePack('edoc-container');
  const [eDocs, setEDocs] = useState([]);
  const [selectedToRequestEDocs] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasReminderEmailTemplate, setHasReminderEmailTemplate] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState(null);

  const [sendingReminder, setSendingReminder] = useState(false);
  const isMounted = useMounted();

  const getData = useCallback(
    async (callback = () => {}) => {
      const result = await retryableAPICall(() => getEDocDetails(candidate.id));
      const requestChaseEmailTemplate = await retryableAPICall(() => getTemplatesByType('EDOC_REQUEST_CHASE'));

      if (isMounted()) {
        if (typeof result === 'string') {
          setError(true);
        } else {
          setEDocs(result);
        }

        setHasReminderEmailTemplate(requestChaseEmailTemplate && requestChaseEmailTemplate.length !== 0);

        setTimeout(() => {
          setLoading(false);
        }, 75);
        if (typeof callback === 'function') {
          callback(result.status);
        }
      }
    },
    [candidate.id, isMounted],
  );

  useEffect(() => {
    if (candidate && candidate.id) getData();
  }, [candidate, getData]);

  async function handleSendReminder(id) {
    setSendingReminder(true);

    try {
      const result = await sendEDocReminder(id);
      if (typeof result === 'string') {
        toast.error(languagePack.errorSendingReminder);
      } else {
        toast.success(languagePack.successSendingReminder);
        getData();
      }
    } catch (e) {
      toast.error(languagePack.errorSendingReminder);
    }

    setSendingReminder(false);
  }

  function getReminderLabel(status) {
    let reminderBtnLabel;

    if (sendingReminder) {
      reminderBtnLabel = languagePack.sendingReminderLabel;
    } else if (!sendingReminder && status === 'REQUESTED') {
      reminderBtnLabel = languagePack.sendReminder1;
    } else if (!sendingReminder && (status === 'REMINDER_1_SENT' || status === 'PART_RECEIVED')) {
      reminderBtnLabel = languagePack.sendReminder2;
    }

    return reminderBtnLabel;
  }

  const selEFormIds = {};
  selectedToRequestEDocs.forEach((edoc) => {
    selEFormIds[edoc.label] = edoc.id;
  });

  function handleUpdate() {
    updateSummary();
    getData();
    setLoading(false);
  }

  async function handleDownload(id) {
    setDownloadingDocument(id);
    const result = await retryableAPICall(() => downloadEDoc(id));

    if (typeof result === 'string') {
      toast.error(languagePack.downloadPDFError);
    } else {
      toast.success(languagePack.downloadPDFSuccess);
    }
    setDownloadingDocument(null);
  }

  return (
    <Card>
      <CardBody>
        {loading ? (
          <LoadingScreen notFixed />
        ) : (
          <>
            <Row>
              <Col>
                <h4>{languagePack.eDocTitle || 'E-Documents'}</h4>
              </Col>
            </Row>
            <hr />
            {error && (
              <h5 className="text-danger mb-0">
                {/* eslint-disable-next-line max-len */}
                {languagePack.loadingError || 'An error occurred when loading the users eDoc information'}
              </h5>
            )}
            {!error && !loading && eDocs.length === 0 && (
              <Row>
                <Col>
                  <h5>{languagePack.noEDocDataStateTitle}</h5>
                </Col>
              </Row>
            )}
            <EDocRequestCreation
              candidate={candidate}
              doEDocRequestRefresh={() => getData()}
              existingEDocRequests={eDocs}
              hasReminderEmailTemplate={hasReminderEmailTemplate}
              languagePack={languagePack}
              onUpdate={handleUpdate}
              vacancyTitle={vacancyTitle}
              vacancyLocation={
                vacancy?.address?.address.formattedAddress ||
                vacancy?.positionProfile.location.formattedAddress ||
                vacancy?.positionProfile.location.city
              }
              employerName={vacancy?.vacancyDetails?.companyName || vacancy?.company?.name}
            />
            {!error && eDocs.length > 0 && (
              <Row>
                <Col>
                  <hr />
                  <h5>{languagePack.previouslyRequestedDocumentsTitle}</h5>
                </Col>
              </Row>
            )}
            {!error &&
              eDocs.length > 0 &&
              eDocs.map((edocReq) => (
                <Row key={edocReq.id}>
                  <Col>
                    <div className="documents-requested-container">
                      <Row>
                        <Col>
                          <p style={edocReq.status !== 'RECEIVED' ? { lineHeight: '30px' } : {}}>
                            {`${languagePack.documentsRequestedOnTitle}: ${moment
                              .utc(edocReq.createdDateTime)
                              .local()
                              .format('DD-MM-YYYY HH:mm:ss')}`}
                          </p>
                        </Col>
                        {!['RECEIVED', 'REMINDER_2_SENT', 'WITHDRAWN'].includes(edocReq.status) && (
                          <Col>
                            <Tippy
                              content={languagePack.needToSetReminderEmailTemplate}
                              disabled={hasReminderEmailTemplate}
                              theme="ats"
                            >
                              <div style={{ float: 'right' }}>
                                <TextButton
                                  action={() => handleSendReminder(edocReq.id)}
                                  disabled={!hasReminderEmailTemplate}
                                  floatRight
                                  label={getReminderLabel(edocReq.status)}
                                />
                              </div>
                            </Tippy>
                          </Col>
                        )}
                      </Row>
                      {edocReq?.edocs?.map((edoc) => (
                        <div key={edoc.id} className="document-requested-container">
                          <Row>
                            <Col>
                              <h5>{edoc.flexiFormName}</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h5>
                                {`${languagePack.documentStatusTitle}: ${
                                  languagePack[edoc.status] || edoc.status.replace('_', ' ')
                                } - `}
                                <span className="heading-note ms-0">
                                  {edoc.status !== 'WITHDRAWN' &&
                                    moment.utc(edoc.statusLastUpdatedDateTime).local().format('DD-MM-YYYY HH:mm:ss')}
                                  {edoc.status === 'WITHDRAWN' &&
                                    moment.utc(edoc.deletedDateTime).local().format('DD-MM-YYYY HH:mm:ss')}
                                </span>
                              </h5>
                            </Col>
                          </Row>
                          {edoc.status === 'RECEIVED' && (
                            <Row>
                              <Col>
                                <TextButton
                                  action={() => handleDownload(edoc.id)}
                                  disabled={downloadingDocument === edoc.id}
                                  label={
                                    downloadingDocument === edoc.id
                                      ? languagePack.viewDownloadDocumentInProgressLabel
                                      : languagePack.viewDownloadDocumentLabel
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              ))}
          </>
        )}
      </CardBody>
    </Card>
  );
}

EDocDetailsContainer.propTypes = {
  candidate: PropTypes.shape(),
  updateSummary: PropTypes.func,
  vacancyTitle: PropTypes.string,
  vacancy: PropTypes.shape(),
};

EDocDetailsContainer.defaultProps = {
  candidate: null,
  updateSummary: () => {},
  vacancyTitle: null,
  vacancy: null,
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(EDocDetailsContainer);
