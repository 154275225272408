import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TABLE_PAGE_BOUNDARIES, getColumnConfig } from '../utils';
import { IconSVG } from '../../SVG';

function DefaultButton({ className, disabled, onClick, title, children }) {
  return (
    <button className={className} disabled={disabled} onClick={onClick} title={title} type="button">
      {children}
    </button>
  );
}

DefaultButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

DefaultButton.defaultProps = {
  className: null,
  disabled: false,
  title: null,
  onClick: () => {},
};

function calculatePagination(currentPage, pageCount) {
  const range = [1];
  const rangeWithDots = [];
  const delta = 2;
  let l;

  if (pageCount <= 1) return range;

  for (let i = currentPage - delta; i <= currentPage + delta; i += 1) {
    if (i < pageCount && i > 1) range.push(i);
  }
  range.push(pageCount);

  range.forEach((idx) => {
    if (l) {
      if (idx - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (idx - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(idx);
    l = idx;
  });

  return rangeWithDots;
}

export default function Pagination({
  pages,
  page,
  onPageChange,
  previousText,
  nextText,
  showPageSizeOptions,
  pageSize,
  pageSizeOptions,
  onPageSizeChange,
  rowsText,
  rowsSelectorText,
  pageSizeOptionsDisabled,
  columnManager,
  onColumnManagerClick,
  onResetColWidth,
  ns,
  hasCustomColWidth,
}) {
  const currentPageNumber = page + 1;
  const prevPage = currentPageNumber - 1;
  const nextPage = currentPageNumber + 1;
  const pageDisplay = calculatePagination(currentPageNumber, pages);

  function handlePageChange(pageNumber) {
    onPageChange(pageNumber - 1);
  }

  const colConf = getColumnConfig(ns);

  return (
    <div className="table__page-management">
      {columnManager && (
        <div className="table__column-manager">
          <DefaultButton
            className={cx('table__pageButton', { 'is-managed': Object.keys(colConf).length })}
            onClick={onColumnManagerClick}
            title="Manage Columns"
          >
            <IconSVG name="Columns" />
          </DefaultButton>
        </div>
      )}
      {hasCustomColWidth && (
        <div className="table__column-width-reset">
          <DefaultButton className={cx('table__pageButton')} onClick={onResetColWidth} title="Reset Column Widths">
            <IconSVG name="Undo" />
          </DefaultButton>
        </div>
      )}
      <div className="table__pagination">
        <div className="table__prevPageWrapper">
          <DefaultButton
            className="table__pageButton"
            disabled={currentPageNumber === 1}
            onClick={() => handlePageChange(prevPage)}
          >
            {previousText}
          </DefaultButton>
        </div>
        <div className="table__visiblePagesWrapper">
          {pageDisplay.map((item, i) => {
            // if ellipsis no need for button
            // eslint-disable-next-line react/no-array-index-key
            if (item === '...')
              return (
                <span key={i} className="pagination-ellipsis">
                  &hellip;
                </span>
              );

            const isActive = item === currentPageNumber;
            const aria = { 'aria-label': isActive ? `Page ${item}` : `Goto page ${item}` };
            // aria for current page link
            if (isActive) aria['aria-current'] = 'page';

            return (
              <DefaultButton
                key={`page-${item}`}
                className={`table__pageButton${isActive ? ' table__pageButton--active' : ''}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...aria}
                onClick={() => handlePageChange(item)}
              >
                {item}
              </DefaultButton>
            );
          })}
        </div>
        <div className="table__nextPageWrapper">
          <DefaultButton
            className="table__pageButton"
            disabled={currentPageNumber === pages}
            onClick={() => handlePageChange(nextPage)}
          >
            {nextText}
          </DefaultButton>
        </div>
      </div>
      {showPageSizeOptions && (
        <div className="table__page-size">
          <select
            aria-label={rowsSelectorText}
            className="table__page-size--select select"
            disabled={pageSizeOptionsDisabled}
            onChange={(e) => onPageSizeChange(Number(e.target.value))}
            value={pageSize}
          >
            {pageSizeOptions.map((opt, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={`pSize-${i}`} value={opt}>
                {`${opt} ${rowsText}`}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  onResetColWidth: PropTypes.func,
  hasCustomColWidth: PropTypes.bool,
  onPageChange: PropTypes.func,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
  showPageSizeOptions: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func,
  rowsText: PropTypes.string,
  rowsSelectorText: PropTypes.string,
  pageSizeOptionsDisabled: PropTypes.bool,
  columnManager: PropTypes.bool,
  onColumnManagerClick: PropTypes.func,
  ns: PropTypes.string,
};

Pagination.defaultProps = {
  pages: 1,
  page: 1,
  hasCustomColWidth: false,
  onResetColWidth: () => {},
  onPageChange: () => {},
  previousText: 'Previous',
  nextText: 'Next',
  showPageSizeOptions: true,
  pageSize: 25,
  pageSizeOptions: TABLE_PAGE_BOUNDARIES,
  onPageSizeChange: () => {},
  rowsText: 'rows',
  rowsSelectorText: 'rows per page',
  pageSizeOptionsDisabled: false,
  columnManager: false,
  onColumnManagerClick: () => {},
  ns: null,
};
