/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InputRange from 'react-input-range';
import { Col, Row, FormGroup, Input, Label } from 'reactstrap';
import { IconButton } from '../../../../../../Base/Buttons';
import { SelectInput } from '../../../../../../Base/Forms/Custom/CommonComponents';
import UploadFont from '../UploadFont';

function Fonts({
  currentMUITheme,
  customFonts,
  changeStyle,
  deleteFont,
  updateFont,
  addFont,
  addCustomFont,
  googleFontText,
}) {
  return (
    <>
      <ul className="list-unstyled text-left">
        {customFonts?.map((font) => (
          <li key={font.name}>
            {font.type}
            {font.type === 'CUSTOM' ? `- ${font.name} ` : `- ${font.url} `}
            <IconButton
              action={() => {
                deleteFont(font);
              }}
              className="ms-3 mt-2"
              disabled={(font.type === 'CUSTOM' ? font.name : font.url) === currentMUITheme?.typography.fontFamily}
              iconClassName="fa fa-trash"
              iconOnly
            />
          </li>
        ))}
      </ul>
      <FormGroup row>
        <Label for="logo">Google Font</Label>
        <Col className="d-flex">
          <Input
            value={googleFontText}
            className="w-50"
            onChange={(e) => {
              updateFont(e.target.value);
            }}
            type="text"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                addFont();
              }
            }}
          />
          <IconButton
            action={addFont}
            className="ms-3"
            iconClassName="fa fa-plus"
            iconOnly
            disabled={googleFontText ? googleFontText.length === 0 : true}
          />
        </Col>
        <p className="MuiFormHelperText-root">
          Enter the name of a{' '}
          <a
            className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
            href="https://fonts.google.com/"
            rel="noreferrer"
            target="_blank"
          >
            Google Font
          </a>
        </p>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Row className="mt-2">
            <Label for="logo">Upload Custom Font</Label>
            <UploadFont
              onChange={(url, name) => {
                addCustomFont(url, name);
              }}
            />
          </Row>
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font</Label>
        <SelectInput
          onChange={(val) => {
            changeStyle('typography.fontFamily', val);
          }}
          options={customFonts.map((a) => {
            if (a.type === 'CUSTOM') {
              return { label: a.name, value: a.name };
            }
            return { label: a.url, value: a.url };
          })}
          value={currentMUITheme?.typography.fontFamily}
        />
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Size</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={48}
            minValue={12}
            onChange={(val) => {
              changeStyle('typography.fontSize', val);
            }}
            value={currentMUITheme?.typography.fontSize}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Font Weight</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={1000}
            minValue={100}
            onChange={(val) => {
              changeStyle('typography.fontWeightRegular', val);
            }}
            step={100}
            value={currentMUITheme?.typography?.fontWeightRegular}
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Label for="logo">Box Corners</Label>
        <Col className="w-75 py-3 ms-1">
          <InputRange
            maxValue={30}
            minValue={1}
            onChange={(val) => {
              changeStyle('shape.borderRadius', val);
            }}
            step={1}
            value={currentMUITheme?.shape?.borderRadius}
          />
        </Col>
      </FormGroup>
    </>
  );
}

Fonts.propTypes = {
  addCustomFont: PropTypes.func,
  addFont: PropTypes.func,
  changeStyle: PropTypes.func,
  currentMUITheme: PropTypes.shape(),
  customFonts: PropTypes.arrayOf(PropTypes.shape()),
  deleteFont: PropTypes.func,
  updateFont: PropTypes.func,
  googleFontText: PropTypes.string,
};

Fonts.defaultProps = {
  addCustomFont: () => {},
  addFont: () => {},
  changeStyle: () => {},
  currentMUITheme: {},
  customFonts: [],
  deleteFont: () => {},
  updateFont: () => {},
  googleFontText: '',
};

export default Fonts;
