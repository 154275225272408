import { industries } from './data/industries';
import { categories } from './data/categories';

export const hoursType = [
  { value: 'FULL_TIME', label: 'Full Time' },
  { value: 'PART_TIME', label: 'Part Time' },
  { value: 'BOTH', label: 'Both' },
];

export const workEnvironments = [
  { value: 'INDOORS', label: 'Indoors' },
  { value: 'OUTDOORS', label: 'Outdoors' },
  { value: 'MIXED', label: 'Mixed' },
];

export const shiftTypes = [
  { value: 'DAYTIME', label: 'Daytime' },
  { value: 'EVENING', label: 'Evening' },
  { value: 'WEEKEND', label: 'Weekend' },
];

export const vacancyAvailable = {
  enum: ['IMMEDIATE', 'DATE', 'ALWAYS'],
  enumNames: ['Immediately', 'On a particular date', 'Always recruiting'],
};

export const careerLevel = [
  { value: 'STUDENT', label: 'Current Student' },
  { value: 'RECENT_GRADUATE', label: 'School/College Leaver' },
  { value: 'ENTRY_LEVEL', label: 'Graduate' },
];

export const contractTerm = [
  { value: 'STUDENT', label: 'Student' },
  { value: 'SEASONAL', label: 'Seasonal' },
  { value: 'APPRENTICESHIP', label: 'Apprenticeship' },
  { value: 'INTERNSHIP', label: 'Internship' },
  { value: 'DIRECT_HIRE', label: 'Direct Hire' },
];

export const renumerationType = [
  { value: 'FIXED', label: 'Fixed Salary' },
  { value: 'RANGE', label: 'Salary Range' },
];

export const renumerationInterval = [
  { value: 'YEARLY', label: 'Yearly' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'DAILY', label: 'Daily' },
  { value: 'HOURLY', label: 'Hourly' },
];

export const categoriesInternship = [
  { value: 'Industrial Placement', label: 'Industrial Placement' },
  { value: 'Spring Internship', label: 'Spring Internship' },
  { value: 'Summer Internship', label: 'Summer Internship' },
  { value: 'Autumn Internship', label: 'Autumn Internship' },
  { value: 'Winter Internship', label: 'Winter Internship' },
];

export const vacancySchema = {
  type: 'object',
  properties: {
    vacancyDetails: {
      type: 'object',
      required: [
        'companyName',
        'title',
        'referenceCode',
        'description',
        'numPositions',
        'hoursType',
        'contractTerm',
        'category',
        'categoryInternship',
        'industry',
        'careerLevel',
        'roleStartDate',
        'shiftTypes',
        'durationDesc',
        'environment',
      ],
      title: 'Vacancy Details',
      properties: {
        companyName: {
          type: 'string',
          title: 'Company name',
        },
        industry: {
          type: 'array',
          title: 'Company Sector(s)',
          isMultiple: true,
          data: industries,
        },
        title: {
          type: 'string',
          title: 'Vacancy title',
          maxLength: 60,
        },
        referenceCode: {
          type: 'string',
          title: 'Vacancy Reference code',
          maxLength: 30,
        },
        description: {
          type: 'string',
          title: 'Vacancy Description',
        },
        numPositions: {
          type: 'number',
          title: 'Positions Open',
          default: 1,
        },
        hoursType: {
          type: 'string',
          title: 'Vacancy Type',
          data: hoursType,
        },
        category: {
          type: 'string',
          title: 'Position Type',
          data: categories,
        },
        categoryInternship: {
          type: 'string',
          title: 'Position Type',
          data: categoriesInternship,
        },
        contractTerm: {
          type: 'string',
          title: 'Contract Term',
          data: contractTerm,
        },
        careerLevel: {
          type: 'string',
          title: 'Career Level',
          data: careerLevel,
        },
        environment: {
          type: 'string',
          title: 'Work Environments',
          data: workEnvironments,
        },
        shiftTypes: {
          type: 'array',
          title: 'Shift Types',
          isMultiple: true,
          data: shiftTypes,
        },
        remoteWorking: {
          type: 'boolean',
          title: 'Remote Working?',
        },
        vacancyAvailable: {
          type: 'string',
          title: 'When is this vacancy available',
          enum: vacancyAvailable.enum,
          enumNames: vacancyAvailable.enumNames,
        },
        applicationCloseDate: {
          type: 'object',
          title: 'Application Close Date (leave blank for none)',
        },
        durationDesc: {
          type: 'string',
          title: 'Duration Description',
        },
        roleStartDate: {
          type: 'object',
          title: 'Role Start Date',
        },
        roleEndDate: {
          type: 'object',
          title: 'Role End Date (leave blank for none) ',
        },
      },
    },
    address: {
      type: 'object',
      title: 'Address Information',
      required: ['locationDescription'],
      properties: {
        address: {
          type: 'object',
          title: 'Address Lookup',
          label: 'Vacancy Address',
        },
        locationDescription: {
          type: 'string',
          title: 'Location description (Shown to candidates)',
        },
        accommodationProvided: {
          type: 'boolean',
          title: 'Accommodation Provided',
        },
      },
    },
    remuneration: {
      title: 'Remuneration',
      required: ['remunerationType', 'interval', 'salaryFixed', 'minSal', 'maxSal', 'salaryNotes'],
      type: 'object',
      properties: {
        remunerationType: {
          type: 'string',
          title: 'Type',
          default: 'FIXED',
          data: renumerationType,
        },
        interval: {
          type: 'string',
          title: 'Interval',
          data: renumerationInterval,
        },
        salaryNotes: {
          type: 'string',
          title: 'Details of pay (shown to candidates)',
        },
        salaryFixed: {
          type: 'number',
          title: 'Rate',
        },
        minSal: {
          type: 'number',
          title: 'Minimum',
        },
        maxSal: {
          type: 'number',
          title: 'Maximum',
        },
      },
    },
    appFormTemplate: {
      type: 'string',
      title: 'Application Form Template',
      path: '/application-builder/templates',
    },
  },
};

export const createVacancyUiSchema = {
  'ui:order': ['vacancyDetails', 'address', 'remuneration', 'appFormTemplate'],
  vacancyDetails: {
    'ui:order': [
      'contractTerm',
      'careerLevel',
      'companyName',
      'title',
      'referenceCode',
      'industry',
      'category',
      'categoryInternship',
      'hoursType',
      'vacancyAvailable',
      'roleStartDate',
      'durationDesc',
      'roleEndDate',
      'applicationCloseDate',
      'numPositions',
      'shiftTypes',
      'environment',
      'remoteWorking',
      'description',
    ],
    title: {
      'ui:help': 'The position to be filled i.e. Customer Service Agent',
    },
    referenceCode: {
      'ui:help': 'Your internal reference to help you identify this role',
    },
    description: {
      'ui:field': 'rte',
      'ui:help':
        'Enter some descriptive text about the role. This should include the roles and responsibilities of the' +
        ' vacancy, general expectations of the candidate and any other pertinent information about the role',
    },
    numPositions: {
      'ui:widget': 'updown',
    },
    hoursType: {
      'ui:field': 'select',
    },
    remoteWorking: {
      'ui:help':
        'If this position can be worked in a remote manner please select this box, work from home positions can be specifically searched for',
    },
    applicationCloseDate: {
      'ui:field': 'datePicker',
    },
    industry: {
      'ui:field': 'select',
    },
    shiftTypes: {
      'ui:field': 'select',
    },
    category: {
      'ui:field': 'select',
    },
    categoryInternship: {
      'ui:field': 'select',
    },
    environment: {
      'ui:field': 'select',
    },
    careerLevel: {
      'ui:field': 'select',
    },
    contractTerm: {
      'ui:field': 'select',
    },
    roleStartDate: {
      'ui:field': 'datePicker',
    },
    roleEndDate: {
      'ui:field': 'datePicker',
    },
    vacancyAvailable: {
      'ui:field': 'radioBtns',
      display: 'block',
    },
  },
  address: {
    address: {
      'ui:order': ['address'],
      'ui:field': 'addressLookup',
    },
  },
  remuneration: {
    'ui:order': ['remunerationType', '*', 'interval', 'salaryNotes'],
    remunerationType: {
      'ui:field': 'select',
      'ui:help':
        "Fixed Salary should be selected if the remuneration is a fixed value i.e £7.56 an hour or Salary Range should be selected if you're open to speaking to a variety candidates with differing experience levels",
    },
    interval: {
      'ui:field': 'select',
    },
    salaryNotes: {
      'ui:widget': 'textarea',
      'ui:options': {
        title: 'Details of pay (shown to candidates)',
        rows: 6,
      },
      'ui:help': 'Details about the pay, which can include exceptions or detailed payment clauses.',
    },
  },
  appFormTemplate: {
    'ui:field': 'select',
  },
};

export const vacancyRules = [
  {
    conditions: { 'vacancyDetails.vacancyAvailable': { not: { equal: 'DATE' } } },
    event: {
      type: 'remove',
      params: {
        field: 'vacancyDetails.roleStartDate',
      },
    },
  },
  {
    conditions: { 'remuneration.remunerationType': { not: { equal: 'FIXED' } } },
    event: {
      type: 'remove',
      params: {
        field: 'remuneration.salaryFixed',
      },
    },
  },
  {
    conditions: { 'vacancyDetails.contractTerm': { not: { equal: 'INTERNSHIP' } } },
    event: {
      type: 'remove',
      params: {
        field: 'vacancyDetails.categoryInternship',
      },
    },
  },
  {
    conditions: {
      'vacancyDetails.contractTerm': {
        or: [{ equal: 'INTERNSHIP' }, { equal: 'DIRECT_HIRE' }, { equal: 'APPRENTICESHIP' }],
      },
    },
    event: {
      type: 'remove',
      params: {
        field: 'vacancyDetails.category',
      },
    },
  },
  {
    conditions: { 'remuneration.remunerationType': { not: { equal: 'RANGE' } } },
    event: {
      type: 'remove',
      params: {
        field: ['remuneration.minSal', 'remuneration.maxSal'],
      },
    },
  },
  {
    conditions: { 'vacancyDetails.contractTerm': { not: { equal: 'INTERNSHIP' } } },
    event: {
      type: 'remove',
      params: {
        field: 'vacancyDetails.durationDesc',
      },
    },
  },
];
