import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalPopup, Confirmation } from '../../../Modal';
import { FormTemplatePrompt } from '../../../../Admin/Client/FlexiForms';
import {
  defaultSchema,
  updateToApplicationFormat,
} from '../../../../../js/model/form-schemas/flexiforms/default-application-form';
import { createSchemas, createSections } from '../../../../Admin/Client/FlexiForms/utils';
import { DeleteButton } from '../../../Buttons';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { useMounted } from '../../../hooks';
import { getAllFormStyles } from '../../../../../api/FormsAPI/ATSFormsStylesAPI';
import { FormManagerContext, FormManager } from '../../../../Admin/Client/FlexiForms/FormManager';
import { uiSchema } from '../../../../../js/model/form-schemas/DescriptionSchema';
/* eslint-disable */

function ApplicationFormModal({
  formData,
  uiSchema: { 'ui:options': uiOptions },
  onChange,
  activeAccount,
  readonly = false,
}) {
  const { text } = uiOptions;

  const isMounted = useMounted();
  const [formCreated, setFormCreated] = useState(false);
  const [allFormThemes, setAllFormThemes] = useState([]);
  const [formThemeId, setFormThemeId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [formSchemas, setFormSchemas] = useState(defaultSchema);
  const [newSections, setNewSections] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const [isTempPromptOpen, setIsTempPromptOpen] = useState(false);
  const [templateFormSchemas, setTemplateFormSchemas] = useState({});

  useEffect(() => {
    if (isMounted() && formData.applicationForm && Object.keys(formData.applicationForm).length) {
      setFormSchemas({ ...formData.applicationForm });
      setFormCreated(true);
    }
  }, [formData, isMounted]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await retryableAPICall(() => getAllFormStyles());
      setAllFormThemes(response);
    }
    fetchData();
  }, []);

  const formItems = createSections(formSchemas);

  const hideCreateNewButton = formCreated || readonly;

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        {formCreated && `"${formSchemas.formName}" attached`}
        {!hideCreateNewButton && (
          <a
            href="#openmodal"
            id="open-modal"
            className="px-0 btn btn-link btn-sm"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            {text}
          </a>
        )}
        {formCreated && (
          <DeleteButton
            label="Remove form"
            action={() => setShowConfirm(true)}
            className="btn-sm xs ms-2"
            iconOnly
            disabled={readonly}
            floatRight={false}
          />
        )}
      </div>
      <ModalPopup
        title="Create a new Application form"
        isOpen={isModalOpen}
        className="application-form-modal"
        closeOnOkay={false}
        onToggle={(isOpen) => setIsModalOpen(isOpen)}
        onOkay={() => {
          setTemplateFormSchemas({ applicationForm: formSchemas });
          setIsTempPromptOpen(true);
        }}
        footer={({ OkayButton, CancelButton }) => (
          <Fragment>
            <CancelButton />
            <OkayButton />
          </Fragment>
        )}
      >
        <FormManagerContext.Provider
          value={{
            newOptions: newOptions,
            newQuestions: newQuestions,
            newSections: newSections,
            onOptionAdd: (optionId) => {
              if (formCreated) setNewOptions([...newOptions, optionId]);
            },
            onOptionEdit: (optionId) => setNewOptions(newOptions.filter((optId) => optId !== optionId)),
            onQuestionAdd: (questionId) => {
              if (formCreated) setNewQuestions([...newQuestions, questionId]);
            },
            onQuestionEdit: (questionId) => {
              setNewQuestions(newQuestions.filter((qId) => qId !== questionId));
            },
            onSectionAdd: (sectionId) => {
              if (formCreated) setNewSections([...newSections, sectionId]);
            },
            onSectionEdit: (sectionId) => {
              setNewSections(newSections.filter((sId) => sId !== sectionId));
            },
          }}
        >
          <FormManager
            formThemes={allFormThemes}
            formName={formSchemas.formName}
            formSections={formItems}
            formType={'APPLICATION_FORM'}
            isEditing={true}
            themeId={formThemeId}
            onChange={(sections, name, formType, formReadOnly, formShare, themeId) => {
              const schemas = createSchemas(sections, name);
              const applicationFormSchema = updateToApplicationFormat(schemas);

              setFormThemeId(themeId);
              setFormSchemas(applicationFormSchema);
            }}
          />
        </FormManagerContext.Provider>
      </ModalPopup>
      <Confirmation
        show={showConfirm}
        title="Delete Form?"
        content="Are you sure you want to delete the form?"
        confirmCallback={() => {
          setFormCreated(false);
          setFormSchemas(defaultSchema);
          setFormThemeId(null);
          onChange({});
          setShowConfirm(false);
        }}
        cancelCallback={() => setShowConfirm(false)}
      />
      <FormTemplatePrompt
        isOpen={isTempPromptOpen}
        onOkay={(createdform) => {
          setFormCreated(true);
          let form = formSchemas;
          if (createdform) {
            form = {
              formName: createdform.flexiForm.name,
              rules: createdform.flexiForm.rules,
              uiSchema: createdform.flexiForm.uiSchema,
              schema: createdform.flexiForm.formSchema,
              themeId: createdform.flexiForm.themeId,
            };
          }

          if (!form.themeId) {
            form.themeId = formThemeId;
          }

          onChange({ applicationForm: form });
          setIsModalOpen(false);
          setFormThemeId(null);
          setFormSchemas(formSchemas);
          setIsTempPromptOpen(false);
          setTemplateFormSchemas({});
        }}
        closePrompt={() => {
          setIsTempPromptOpen(false);
        }}
        formSchemas={templateFormSchemas}
        formType="APPLICATION_FORM"
        activeAccount={activeAccount}
        themeId={formThemeId}
      />
    </Fragment>
  );
}

ApplicationFormModal.propTypes = {
  formData: PropTypes.shape({
    applicationForm: PropTypes.shape({
      schema: PropTypes.shape(),
      uiSchema: PropTypes.shape(),
      formName: PropTypes.string,
    }),
  }),
  uiSchema: PropTypes.shape({
    'ui:options': PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
  onChange: PropTypes.func,
};

ApplicationFormModal.defaultProps = {
  formData: {},
  uiSchema: {
    'ui:options': {
      text: 'Flexi Form',
    },
  },
  onChange: () => {},
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;
  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccount: { id: activeAccountId, name: activeAccountName },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFormModal);
