// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import cx from 'classnames';
import ModalButton from './ModalButton';
import Header from './ModalHeader';

export default function ModalPopup({
  isOpen,
  title,
  onToggle,
  onOkay,
  onCancel,
  closeOnOkay,
  className,
  isCentered,
  children,
  minHeight,
  maxHeight,
  size,
  hideCloseButton,
  hideOkayButton,
  hideCancelButton,
  disableButtons,
  disableOkayButton,
  centerButtons,
  centerContent,
  okayLabel,
  cancelLabel,
  allowOverflow,
  footer,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  function handleToggle() {
    setModalOpen(!modalOpen);
    onToggle(!modalOpen);
  }

  function handleClose(isCancel) {
    if (isCancel) onCancel();
    setModalOpen(false);
    onToggle(false);
  }

  function handleOkay() {
    if (closeOnOkay) handleClose();
    onOkay();
  }

  function OkayButton(props) {
    return (
      <ModalButton onClick={handleOkay} {...props}>
        {okayLabel}
      </ModalButton>
    );
  }

  function CancelButton(props) {
    return (
      <ModalButton className="btn-outline" onClick={() => handleClose(true)} {...props}>
        {cancelLabel}
      </ModalButton>
    );
  }

  useEffect(() => {
    return () => {
      // reactstrap Modal ontop of react modal can cause some issues with scrollbar being removed permenantly
      // https://github.com/reactstrap/reactstrap/issues/2574
      document.body.style.removeProperty('overflow');
    };
  });

  return (
    <Modal
      centered={isCentered}
      className={className}
      innerRef={modalRef}
      isOpen={modalOpen}
      size={size}
      toggle={handleToggle}
    >
      {title && (
        <Header hideCloseButton={hideCloseButton} toggle={handleToggle}>
          {title}
        </Header>
      )}
      <ModalBody
        className={cx({ 'd-flex justify-content-center': centerContent })}
        style={{
          maxHeight,
          minHeight,
          ...(allowOverflow ? { overflowY: 'initial' } : {}),
        }}
      >
        {typeof children === 'function' ? children({ modalRef }) : children}
      </ModalBody>
      {!(hideOkayButton && hideCancelButton) &&
      <ModalFooter className={cx({ 'justify-content-center': centerButtons })}>
        {typeof footer === 'function' ? (
          footer({ OkayButton, CancelButton })
        ) : (
          <>
            {!hideCancelButton && <CancelButton disabled={disableButtons} />}
            {!hideOkayButton && <OkayButton disabled={disableButtons || disableOkayButton} />}
          </>
        )}
      </ModalFooter>
      }
    </Modal>
  );
}

ModalPopup.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  onOkay: PropTypes.func,
  onCancel: PropTypes.func,
  closeOnOkay: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  size: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  hideOkayButton: PropTypes.bool,
  isCentered: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  disableButtons: PropTypes.bool,
  disableOkayButton: PropTypes.bool,
  centerContent: PropTypes.bool,
  centerButtons: PropTypes.bool,
  okayLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  allowOverflow: PropTypes.bool,
  footer: PropTypes.func,
};

ModalPopup.defaultProps = {
  isOpen: false,
  title: null,
  onToggle: () => {},
  onOkay: () => {},
  onCancel: () => {},
  closeOnOkay: true,
  className: null,
  children: () => {},
  minHeight: '100px',
  maxHeight: '75vh',
  size: 'lg',
  hideCloseButton: false,
  hideOkayButton: false,
  isCentered: true,
  disableButtons: false,
  disableOkayButton: false,
  hideCancelButton: false,
  centerContent: false,
  centerButtons: false,
  okayLabel: 'Okay',
  cancelLabel: 'Cancel',
  allowOverflow: false,
  footer: null,
};
