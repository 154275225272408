import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const { funnelManagement: { funnelPath }, api: { middlewareAPIURL } } = config;

export async function createFunnel(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${middlewareAPIURL}${funnelPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateFunnel(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.put(
      `${middlewareAPIURL}${funnelPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function deleteFunnel(id) {
  if (id) {
    const result = await axios.delete(
      `${middlewareAPIURL}${funnelPath}/${id}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
