import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import TableSearchBar from './TableSearchBar';

export default function withSearchBar(Comp) {
  const SearchBar = ({ hasSearch, onSearch, ...props }) => {
    if (!hasSearch) return <Comp {...props} />;

    return (
      <Fragment>
        <Row className="mb-3">
          <Col>
            <TableSearchBar minWidth="50%" onSearch={onSearch} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Comp {...props} />
          </Col>
        </Row>
      </Fragment>
    );
  };

  SearchBar.propTypes = {
    hasSearch: PropTypes.bool,
    onSearch: PropTypes.func,
  };

  SearchBar.defaultProps = {
    hasSearch: true,
    onSearch: () => { },
  };

  return SearchBar;
}
