import React, { memo, useCallback, useState, useContext } from 'react';
import EditNodeSlider from '../Sliders/EditNodeModal/EditNodeModal';
import { Button } from 'reactstrap';
import { Edit, Trash, Copy } from 'lucide-react';
import { NodeToolbar, useReactFlow } from '@xyflow/react';
import { SliderContext } from './SliderContext';
import { Confirmation } from '../../../../../Base/Modal';
import { getNextId } from '../utils';

const handleEdit = (value, id, nodes, setNodes, setIsSliderOpen) => {
  const updatedNodes = nodes.map((node) => {
    if (node.id === id) {
      return { ...node, data: { ...node.data, ...value } };
    }
    return node;
  });
  setNodes(updatedNodes);
  setIsSliderOpen(false);
};

export default memo(({ id, data, disableCopy, isEditDisabled }) => {
  const { setNodes, setEdges, getNodes, getEdges, deleteElements } = useReactFlow();
  const nodes = getNodes();
  const edges = getEdges();
  const { isSliderOpen, setIsSliderOpen } = useContext(SliderContext);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCopy = useCallback(() => {
    setNodes((currentNodes) => {
      const nodeToCopy = currentNodes.find((node) => node.id === id);

      if (nodeToCopy) {
        const newId = getNextId(currentNodes);
        const newNode = {
          ...nodeToCopy,
          id: newId,
          position: {
            x: nodeToCopy.position.x + 50, // Slight offset to prevent overlap
            y: nodeToCopy.position.y + 50,
          },
          selected: true, // Select the new node
        };

        const updatedNodes = currentNodes.map((node) => ({
          ...node,
          selected: false,
        }));

        return [...updatedNodes, newNode]; // Add the new node to the nodes array
      }

      return currentNodes; // Return the current state if no node is found to copy
    });
  }, [id, setNodes]);

  const handleDeleteClick = () => {
    if (id === '0') {
      setShowConfirm(true);
    } else {
      deleteElements({ nodes: [{ id }] });
    }
  };

  const confirmDelete = () => {
    setNodes([]);
    setEdges([]);
    setShowConfirm(false); // Close the confirmation modal
  };

  return (
    <>
      <NodeToolbar isVisible={data.forceToolbarVisible || undefined} position={data.toolbarPosition}>
        <div className="button-group d-flex gap-1">
          {isEditDisabled ? null : (
            <Button color="primary" size="sm" onClick={() => setIsSliderOpen(true)}>
              <Edit size={16} />
            </Button>
          )}
          {disableCopy ? null : (
            <Button color="secondary" size="sm" onClick={handleCopy}>
              <Copy size={16} />
            </Button>
          )}
          <Button color="danger" size="sm" onClick={handleDeleteClick}>
            <Trash size={16} />
          </Button>
        </div>
      </NodeToolbar>
      {isSliderOpen && (
        <EditNodeSlider
          isOpen={isSliderOpen}
          toggle={() => setIsSliderOpen(false)}
          onSave={(value) => handleEdit(value, id, nodes, setNodes, setIsSliderOpen)}
          selectedNode={nodes.find((node) => node.id === id)}
          selectedEdges={edges.filter((edge) => edge.source === id)}
          onDelete={handleDeleteClick}
        />
      )}
      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={confirmDelete}
        content="Are you sure you want to delete this node and all related edges?"
        show={showConfirm}
      />
    </>
  );
});
