import React from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { IconSVG } from '../../../../../../Base/SVG';

const daysOfWeek = [
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' },
];

function PauseWindowInput({ pauseWindow, setOption }) {
  const handlePauseWindowChange = (field, value) => {
    setOption({
      ...pauseWindow,
      [field]: value,
    });
  };

  return (
    <>
      <Row className="mt-2">
        <p style={{ color: 'rgba(5,39,119,0.73)' }}>
          <IconSVG name="Info Circle" />
          <span style={{ marginLeft: '5px' }}>
            Between these times the workflow will not be executed and will be executed within 10 minutes of the pause
            window finishing. All times are in UTC.
          </span>
        </p>
      </Row>
      <Row className="mt-2">
        <Col md={6}>
          <FormGroup>
            <Label for="startDay">Start Day</Label>
            <Select
              id="startDay"
              options={daysOfWeek}
              value={daysOfWeek.find((day) => day.value === pauseWindow.startDay) || ''}
              onChange={(selectedOption) => handlePauseWindowChange('startDay', selectedOption?.value)}
              placeholder="Select Day"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="startTime">Start Time</Label>
            <Input
              type="time"
              id="startTime"
              value={pauseWindow.startTime || ''}
              onChange={(e) => handlePauseWindowChange('startTime', e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="endDay">End Day</Label>
            <Select
              id="endDay"
              options={daysOfWeek}
              value={daysOfWeek.find((day) => day.value === pauseWindow.endDay) || ''}
              onChange={(selectedOption) => handlePauseWindowChange('endDay', selectedOption?.value)}
              placeholder="Select Day"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="endTime">End Time</Label>
            <Input
              type="time"
              id="endTime"
              value={pauseWindow.endTime || ''}
              onChange={(e) => handlePauseWindowChange('endTime', e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default PauseWindowInput;
