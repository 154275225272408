/* eslint-disable import/no-unresolved */

import set from 'lodash.setwith';
import cloneDeep from 'lodash.clonedeep';

export const UPDATE_STYLE = 'UPDATE_STYLE';
export const LOADED_IFRAME = 'LOADED_IFRAME';
export const LOADED_IFRAME_SUCCESSFULLY = 'LOADED_IFRAME_SUCCESSFULLY';
export const LOADED_IFRAME_FAILED = 'LOADED_IFRAME_FAILED';
export const CREATE_STYLE = 'CREATE_STYLE';
export const EDIT_STYLE = 'EDIT_STYLE';
export const CREATE_STYLE_SUCCESS = 'CREATE_STYLE_SUCCESS';
export const UPDATE_STYLE_SUCCESS = 'UPDATE_STYLE_SUCCESS';
export const DELETE_STYLE_SUCCESS = 'DELETE_STYLE_SUCCESS';
export const DELETE_STYLE = 'DELETE_STYLE';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_WIDTH = 'CHANGE_WIDTH';
export const UNDO_CHANGES = 'UNDO_CHANGES';
export const EDIT_FONT = 'EDIT_FONT';
export const CHANGE_BACKGROUND_IMAGE = 'CHANGE_BACKGROUND_IMAGE';
export const CHANGE_LOGO = 'CHANGE_LOGO';
export const UPDATE_CUSTOM_FONT = 'UPDATE_CUSTOM_FONT';
export const UPDATE_GOOGLE_FONT = 'UPDATE_GOOGLE_FONT';
export const ADD_GOOGLE_FONT = 'ADD_GOOGLE_FONT';
export const ADD_CUSTOM_FONT = 'ADD_CUSTOM_FONT';
export const DELETE_FONT = 'DELETE_FONT';
export const LOADED_THEMES = 'LOADED_THEMES';
export const UPDATE_SHARING = 'UPDATE_SHARING';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_IS_DEFAULT = 'UPDATE_IS_DEFAULT';
export const DELETE_STYLE_CONFIRM = 'DELETE_STYLE_CONFIRM';
export const DELETE_STYLE_CANCEL = 'DELETE_STYLE_CANCEL';
export const DELETE_STYLE_ERROR = 'DELETE_STYLE_ERROR';
export const CHANGE_BACKGROUND_COLOUR = 'CHANGE_BACKGROUND_COLOUR';

export default function FormStylesReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_STYLE:
      return {
        ...state,
        isEdited: false,
        isSaving: true,
      };
    case UPDATE_STYLE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        customFonts: payload.theme.customFonts,
        sharedWith: payload.sharedWith,
        isDefault: payload.theme.isDefault,
        name: payload.theme.name,
        logo: payload.theme.logo,
        backgroundImage: payload.theme.backgroundImage,
        backgroundColour: payload.theme.backgroundColour,
        currentMUITheme: payload.theme.muiTheme,
        selectedTheme: payload.id,
      };
    case LOADED_IFRAME:
      return {
        ...state,
        iframeState: 'UNKNOWN',
      };
    case LOADED_IFRAME_SUCCESSFULLY:
      return {
        ...state,
        iframeState: 'SUCCESS',
        isLoadingIframe: false,
      };
    case LOADED_IFRAME_FAILED:
      return {
        ...state,
        iframeState: state.iframeState === 'UNKNOWN' ? 'FAILED' : 'SUCCESS',
        isLoadingIframe: false,
      };
    case CREATE_STYLE:
      return {
        ...state,
        isEdited: false,
        isSaving: true,
      };
    case CREATE_STYLE_SUCCESS:
      return {
        ...state,
        customFonts: payload.customFonts,
        sharedWith: payload.sharedWith,
        isDefault: payload.isDefault,
        name: payload.name,
        logo: payload.logo,
        backgroundImage: payload.backgroundImage,
        backgroundColour: payload.backgroundColour,
        isEdited: false,
        selectedTheme: payload.id,
        currentMUITheme: payload.muiTheme,
        isSaving: false,
        selectedTheme: payload.id,
        themes: [...state.themes, payload],
      };
    case DELETE_STYLE:
      return {
        ...state,
        isDeleteModalOpen: true,
      };
    case DELETE_STYLE_CONFIRM:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_STYLE_CANCEL:
      return {
        ...state,
        isDeleteModalOpen: false,
      };
    case DELETE_STYLE_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_STYLE_SUCCESS: {
      const filteredThemes = state.themes.filter((a) => a.id !== payload);
      return {
        ...state,
        currentMUITheme: filteredThemes.length > 0 ? filteredThemes[0].muiTheme : null,
        isDeleting: false,
        isEdited: false,
        selectedTheme: filteredThemes.length > 0 ? filteredThemes[0].id : null,
        themes: filteredThemes,
        customFonts: filteredThemes.length > 0 ? filteredThemes[0].customFonts : null,
        sharedWith: filteredThemes.length > 0 ? filteredThemes[0].sharedWith : null,
        isDefault: filteredThemes.length > 0 ? filteredThemes[0].isDefault : null,
        name: filteredThemes.length > 0 ? filteredThemes[0].name : null,
        logo: filteredThemes.length > 0 ? filteredThemes[0].logo : null,
        backgroundImage: filteredThemes.length > 0 ? filteredThemes[0].backgroundImage : null,
        backgroundColour: filteredThemes.length > 0 ? filteredThemes[0].backgroundColour : '#ffffff',
        isDeleteModalOpen: false,
      };
    }
    case CHANGE_THEME:
      return {
        ...state,
        customFonts: state.themes.find((a) => a.id === payload.id).customFonts,
        sharedWith: state.themes.find((a) => a.id === payload.id).sharedWith,
        isDefault: state.themes.find((a) => a.id === payload.id).isDefault,
        currentMUITheme: state.themes.find((a) => a.id === payload.id).muiTheme,
        name: state.themes.find((a) => a.id === payload.id).name,
        logo: state.themes.find((a) => a.id === payload.id).logo,
        backgroundImage: state.themes.find((a) => a.id === payload.id).backgroundImage,
        backgroundColour: state.themes.find((a) => a.id === payload.id).backgroundColour,
        isEdited: false,
        selectedTheme: payload.id,
      };
    case EDIT_STYLE: {
      const newState = cloneDeep(state.currentMUITheme);
      set(newState, action.payload.property, action.payload.val);

      return {
        ...state,
        currentMUITheme: newState,
        isEdited:
          JSON.stringify({
            ...state.currentMUITheme,
            ...action.payload.muiTheme,
          }) !== JSON.stringify(state.themes.filter((a) => a.id === state.selectedTheme).muiTheme),
      };
    }
    case EDIT_FONT:
      return {
        ...state,
        customFonts: action.payload.font,
      };
    case UPDATE_GOOGLE_FONT:
      return {
        ...state,
        googleFontText: action.payload,
      };
    case ADD_GOOGLE_FONT:
      return {
        ...state,
        customFonts: [...state.customFonts, { type: 'GOOGLE', url: state.googleFontText }],
        googleFontText: '',
      };
    case ADD_CUSTOM_FONT:
      return {
        ...state,
        customFonts: [...state.customFonts, { name: payload.fontName, type: 'CUSTOM', url: payload.url }],
      };
    case DELETE_FONT:
      return {
        ...state,
        customFonts: state.customFonts.filter((f) => f.url !== action.payload.url),
      };
    case CHANGE_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: action.payload,
        isEdited: true,
      };
    case CHANGE_BACKGROUND_COLOUR:
      return {
        ...state,
        backgroundColour: action.payload,
        isEdited: true,
      };
    case CHANGE_LOGO:
      return {
        ...state,
        logo: action.payload,
        isEdited: true,
      };
    case UNDO_CHANGES:
      return {
        ...state,
        currentMUITheme: state.themes.find((a) => a.id === state.selectedTheme).muiTheme,
        customFonts: state.themes.find((a) => a.id === state.selectedTheme).customFonts,
        sharedWith: state.themes.find((a) => a.id === state.selectedTheme).sharedWith,
        isDefault: state.themes.find((a) => a.id === state.selectedTheme).isDefault,
        isEdited: false,
        name: state.themes.find((a) => a.id === state.selectedTheme).name,
        logo: state.themes.find((a) => a.id === state.selectedTheme).logo,
        backgroundImage: state.themes.find((a) => a.id === state.selectedTheme).backgroundImage,
        backgroundColour: state.themes.find((a) => a.id === state.selectedTheme).backgroundColour,
      };
    case LOADED_THEMES:
      return {
        ...state,
        isLoadingThemes: false,
        logo: action.payload.length > 0 ? action.payload[0].logo : null,
        backgroundImage: action.payload.length > 0 ? action.payload[0].backgroundImage : null,
        backgroundColour: action.payload.length > 0 ? action.payload[0].backgroundColour : '#ffffff',
        currentMUITheme: action.payload.length > 0 ? action.payload[0].muiTheme : null,
        customFonts: action.payload.length > 0 ? action.payload[0].customFonts : null,
        sharedWith: action.payload.length > 0 ? action.payload[0].sharedWith : null,
        isDefault: action.payload.length > 0 ? action.payload[0].isDefault : null,
        name: action.payload.length > 0 ? action.payload[0].name : null,
        selectedTheme: action.payload.length > 0 ? action.payload[0].id : null,
        themes: action.payload,
      };
    case UPDATE_SHARING:
      return {
        ...state,
        sharedWith: action.payload.map(({ value, label }) => ({
          accountId: value,
          accountName: label,
        })),
        isEdited: true,
      };
    case UPDATE_NAME:
      return {
        ...state,
        isEdited: state.themes.find((a) => a.id === state.selectedTheme).name !== action.payload,
        name: action.payload,
      };
    case UPDATE_IS_DEFAULT: {
      const newState = !state.isDefault;
      return {
        ...state,
        isDefault: newState,
        isEdited: state.themes.find((a) => a.id === state.selectedTheme).isDefault !== newState,
      };
    }
    default:
      return state;
  }
}
