// eslint-disable-next-line import/prefer-default-export
export const requestStatuses = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  GENERAL_ERROR: 'GENERAL_ERROR',
  MAINTENANCE: 'MAINTENANCE',
  OK: 'OK',
  OFFLINE_ERROR: 'OFFLINE_ERROR',
  NOT_FOUND_ERROR: 'NOT_FOUND_ERROR',
  ALREADY_EXISTS_ERROR: 'ALREADY_EXISTS_ERROR',
  EXPIRED_ERROR: 'EXPIRED_ERROR',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  PRECONDITION_FAILED: 'PRECONDITION_FAILED',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
};
