const inputConfig = [
  {
    id: 'jobId',
    configId: 'jobs',
    serverDataIds: ['jobName', 'job_name', 'job_id'],
    label: 'Position / Job Title',
    type: 'select',
    required: true,
  },
  {
    id: 'roleHierarchyId',
    configId: 'hierarchyRoles',
    serverDataIds: ['roleHierarchyName', 'role_name', 'role_hierarchy_id'],
    label: 'Role',
    type: 'select',
    required: true,
  },
  {
    id: 'departmentId',
    configId: 'departments',
    serverDataIds: ['departmentName', 'department_name', 'department_id'],
    label: 'Department',
    type: 'select',
    required: true,
  },
  {
    id: 'contractType',
    configId: 'contractTypes',
    label: 'Contract Type',
    type: 'select',
  },
  {
    id: 'tacId',
    configId: 'tacs',
    serverDataIds: ['tacName', 't_and_c_name', 't_and_c_id'],
    label: 'Terms & Conditions',
    type: 'select',
    required: true,
  },
  {
    id: 'jobClass',
    configId: 'jobClasses',
    label: 'Job Class',
    type: 'select',
  },
  {
    id: 'isHouseLogin',
    label: 'Is House Login',
    type: 'checkbox',
  },
  {
    id: 'contractedHours',
    configId: 'defaultContractedHours',
    label: 'Contract Hours',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 1,
    },
  },
  {
    id: 'contractedDays',
    configId: 'defaultContractedDays',
    label: 'Contract Days',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 1,
      max: 7,
    },
  },
  {
    id: 'rateOfPay',
    label: 'Pay Rate',
    type: 'number',
    required: true,
    defaultValue: 0,
    inputProps: {
      min: 1,
    },
  },
  {
    id: 'rateFrequency',
    configId: 'rateFrequencies',
    label: 'Pay Rate Frequency',
    type: 'select',
    required: true,
  },
  {
    id: 'payFrequency',
    configId: 'payFrequencies',
    label: 'Pay Frequency',
    type: 'select',
    required: true,
  },
  {
    id: 'payMethod',
    configId: 'payMethods',
    label: 'Pay method',
    type: 'select',
    required: true,
  },
  {
    id: 'empLivingIn',
    label: 'Living on the Premises',
    type: 'checkbox',
  },
  {
    id: 'startDate',
    label: 'Start Date',
    type: 'date',
    required: true,
  },
  {
    id: 'endDate',
    label: 'Termination Date',
    type: 'date',
  },
  {
    id: 'visaNotes',
    label: 'Visa Notes',
    type: 'textarea',
  },
];

export default inputConfig;
