import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import moment from 'moment';
import {
  inviteInputConfig,
  InviteEmailContext,
  ReminderDaysContext,
  InterviewFormEventInputs,
} from './components/inputs';
import { CreateButton, CancelButton, TextButton } from '../Base/Buttons';
import { emailFormInputConfig } from '../Communication/email';
import validation from '../../js/utils/validation';
import { useFormKeypress } from '../Base/hooks';
import { dateTimeUtils, eventUtils, fetchUtils, dataUtils } from './utils';

const { inputs: emailInputs, message: emailMessage, attachments } = emailFormInputConfig;
const emailValidationConfig = [...emailInputs, { ...emailMessage }, attachments];

function validateEmailDetails(data) {
  const inpErrs = validation(emailValidationConfig, data);
  return {
    emailDetails: {
      errors: ['Email form has missing required fields'],
      invalid: Object.values(inpErrs).some(({ invalid }) => invalid),
    },
  };
}

function InterviewForm({
  applicants,
  onCancel,
  onSuccess,
  onError,
  isInSlider,
  companyName,
  contactEmail,
  isTouch,
  changeCandidateViewTab,
}) {
  const formRef = useFormKeypress();
  const [isSending, setIsSending] = useState(false);
  const [formData, setFormData] = useState({
    emailDetails: {
      attachments: [],
      bccAddresses: [],
      candidateIds: applicants.map(({ applicantId }) => applicantId),
      ccAddresses: [],
      fromName: companyName,
      message: '',
      replyTo: contactEmail,
      subject: '',
    },
    eventTypeId: '',
    inviteType: 'SELF_BOOK',
    reminderDays: 0,
    sendSms: true,
    slotId: '',
  });
  const [errors, setErrors] = useState({});
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [selectedInviteType, setSelectedInviteType] = useState(undefined);
  const [eventTypeRequired, setEventTypeRequired] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(undefined);

  const inputConfig = JSON.parse(JSON.stringify(inviteInputConfig));

  if (!showContactDetails) {
    delete inputConfig.emailDetails;
    delete inputConfig.reminderDays;
    delete inputConfig.sendSms;
  }

  // flatten config object into single array
  const inputs = Object.values(inputConfig);

  function getAvailableCount(eventTypeId) {
    fetchUtils.getAvailableSlotCountCall(
      eventTypeId,
      applicants[0].accountId,
      (result) => {
        setAvailableSlots(result);
      },
      () => {
        // Do nothing
      },
    );
  }

  async function handleChange(id, val, inpConfig, valObj) {
    if (submitted) {
      if (id === 'emailDetails') {
        setErrors({
          ...errors,
          ...validateEmailDetails(val),
        });
      } else {
        const inpErrs = eventUtils.validateEventData([inpConfig], { [id]: val });
        setErrors({ ...errors, ...inpErrs });
      }
    }

    if (id === 'inviteType') {
      setShowContactDetails(val !== 'INSTANT');
      setSelectedInviteType(val);
    }

    let isInstantBook;

    if (id === 'inviteType' && val === 'INSTANT') {
      isInstantBook = true;
    } else if (id === 'eventTypeId') {
      isInstantBook = formData.instantBook;
    } else {
      isInstantBook = false;
    }

    let updatedData = {
      ...formData,
      [id]: val,
      // reset slotId if SELF_BOOK selected
      ...(id === 'inviteType' && (val === 'SELF_BOOK' || val === 'INSTANT') ? { slotId: '' } : {}),
      // this val is used for reminderDays props not sent to server
      ...(id === 'slotId' ? { startDate: valObj.startDate } : {}),
      instantBook: isInstantBook,
    };

    if (id === 'eventTypeId') {
      setAvailableSlots(undefined);
      getAvailableCount(val);
      const eventTypeObj = await dataUtils.eventTypeEmailDetailsObject(val);
      const { emailDetails = {} } = eventTypeObj;

      if (Object.keys(emailDetails).length) {
        updatedData = {
          ...updatedData,
          emailDetails: {
            ...updatedData.emailDetails,
            ...emailDetails,
          },
        };
      }
    }

    setFormData(updatedData);
  }

  function handleSend() {
    setIsSending(true);
    setSubmitted(true);

    let inpConfigs = [...inputs];
    // set eventTypeId as not required if not loaded
    if (!eventTypeRequired) inpConfigs = eventUtils.setRequiredFalse('eventTypeId', inpConfigs);
    // no need to validate startDate
    const { startDate, ...rest } = formData;
    const errObj = eventUtils.validateEventData(inpConfigs, { ...rest });
    // validate email object
    const emailErrObj = validateEmailDetails(formData.emailDetails);

    setErrors({ ...errObj, ...emailErrObj });

    const hasErrors = Object.values({ ...errObj, ...emailErrObj }).some(({ invalid }) => invalid);

    if (!hasErrors) {
      const { slotId, inviteType, reminderDays, ...frmProps } = rest;
      const { candidateIds, ccAddresses, bccAddresses, message, ...emlProps } = formData.emailDetails;

      // for ensuring reminder days is correct when
      const [num, unit] = dateTimeUtils.roundTimeDifference(startDate);
      const diffDays = /minute|hour/.test(unit) ? 0 : num;

      const inviteData = {
        ...frmProps,
        candidateIds,
        emailDetails: {
          ...emlProps,
          bccAddresses,
          ccAddresses,
          htmlContent: message,
        },
        type: inviteType,
        ...(inviteType === 'SPECIFIC' ? { slotIds: [slotId] } : {}),
        reminderDays: diffDays <= 2 && inviteType === 'SPECIFIC' ? 0 : Number(reminderDays),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      setSubmitted(false);

      fetchUtils.inviteCandidates(
        inviteData,
        () => {
          onSuccess(
            selectedInviteType === 'INSTANT' ? 'Successfully created Instant Event' : 'Invite sent successfully',
          );
          setIsSending(false);
          changeCandidateViewTab();
        },
        () => {
          onError(
            selectedInviteType === 'INSTANT'
              ? 'Error creating Instant Event, please try again'
              : 'Error sending invite, please try again',
          );
          setIsSending(false);
        },
      );
    } else {
      setIsSending(false);
    }
  }

  let sendBtnText;

  if (selectedInviteType !== 'INSTANT') {
    sendBtnText = isSending ? 'Sending...' : 'Send Invitation';
  } else {
    sendBtnText = isSending ? 'Creating...' : 'Create Instant Event';
  }

  return (
    <Form className="interview-form clearfix" innerRef={formRef}>
      <InviteEmailContext.Provider value={{ applicants }}>
        <ReminderDaysContext.Provider
          value={{
            inviteType: formData.inviteType,
            startDate: formData.startDate,
          }}
        >
          <InterviewFormEventInputs
            activeAccountId={applicants[0].accountId}
            availableSlots={availableSlots}
            errors={errors}
            formData={formData}
            inputs={inputs}
            isTouch={isTouch}
            onChange={handleChange}
            onOptionLoad={(opts, inpId, displayIfLT2) => {
              if (!displayIfLT2 && !opts.length && inpId === 'eventTypeId') {
                setEventTypeRequired(false);
              }
            }}
          />
        </ReminderDaysContext.Provider>
      </InviteEmailContext.Provider>
      <hr />
      {isInSlider ? (
        <>
          <CreateButton
            action={(e) => {
              e.preventDefault();
              handleSend();
            }}
            disabled={isSending}
            isLoading={isSending}
            isOutline={false}
            label={sendBtnText}
          />
          <CancelButton action={onCancel} className="btn-outline" disabled={isSending} />
        </>
      ) : (
        <TextButton
          action={(e) => {
            e.preventDefault();
            handleSend();
          }}
          disabled={isSending}
          floatRight
          isLoading={isSending}
          label={sendBtnText}
        />
      )}
    </Form>
  );
}

InterviewForm.propTypes = {
  applicants: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      applicantEmail: PropTypes.string,
      applicantId: PropTypes.string.isRequired,
      applicantName: PropTypes.string,
    }),
  ),
  changeCandidateViewTab: PropTypes.func,
  companyName: PropTypes.string,
  contactEmail: PropTypes.string,
  isInSlider: PropTypes.bool,
  isTouch: PropTypes.bool,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

InterviewForm.defaultProps = {
  applicants: [],
  changeCandidateViewTab: () => {},
  companyName: '',
  contactEmail: '',
  isInSlider: true,
  isTouch: false,
  onCancel: () => {},
  onError: () => {},
  onSuccess: () => {},
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyName, contactEmail },
    },
  } = state;
  return { companyName, contactEmail };
}

export default connect(mapStateToProps)(InterviewForm);
