import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import STATUS_LOOKUP from './statusLookup';

function BadgeComp({ className, label, color }) {
  return (
    <Badge className={className} color={color}>
      {label}
    </Badge>
  );
}

BadgeComp.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  color: PropTypes.string,
};

BadgeComp.defaultProps = {
  className: null,
  label: null,
  color: 'success',
};

function StatusBadge({
  status,
  className,
  label,
  children,
}) {
  const { text, color } = STATUS_LOOKUP[status || 'LIVE'];
  const badgeLabel = label || text;

  if (children && typeof children === 'function') {
    return children({
      text,
      color,
      Badge: (
        <BadgeComp
          color={color}
          className={className}
          label={badgeLabel}
        />
      ),
    });
  }

  return (
    <BadgeComp
      color={color}
      className={className}
      label={badgeLabel}
    />
  );
}

StatusBadge.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.func,
};

StatusBadge.defaultProps = {
  status: 'LIVE',
  className: null,
  label: null,
  children: null,
};

export default StatusBadge;
