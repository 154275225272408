import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

export default function Education({ data, showTitle, title }) {
  if (!data || !data.length) return null;

  return (
    <Row className="large-content">
      <Col>
        {showTitle && <p className="detail-title">{title}</p>}
        <div className="detail-value pl-2">
          {data.map(({ institution, educationResults = [] }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="mb-4" key={`edu-${i}`}>
              {institution.name && <h5 className="mb-2 text-capitalize">{institution.name}</h5>}
              {institution.type && <p className="mb-2 text-capitalize">{institution.type}</p>}
              {educationResults.map(({ examType, subjects }, j) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`edu-res-${j}`}>
                  {examType && <p className="mb-2 text-capitalize">{examType}</p>}
                  {Boolean(subjects.length) && <p className="mb-2 text-capitalize">{subjects.join(', ')}</p>}
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}

Education.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    institution: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
    educationResults: PropTypes.arrayOf(PropTypes.shape({
      examType: PropTypes.string,
      subjects: PropTypes.arrayOf(PropTypes.string),
    })),
  })),
  showTitle: PropTypes.bool,
  title: PropTypes.string,
};

Education.defaultProps = {
  data: [],
  showTitle: true,
  title: 'Education',
};
