import { Card, CardBody, Col } from 'reactstrap';
import { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { saveCredentials, getCredentials } from '../../../../api/Integrations/HollwegAPI';
import { useMounted } from '../../../Base/hooks';
import { CancelButton, CreateButton, EditButton } from '../../../Base/Buttons';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import IntegrationInput from '../Dashboard/IntegrationInput';
import HollwegLocations from './HollwegLocations';
import HollwegRoles from './HollwegRoles';

const initialState = {
  isAuthorised: true,
  isEditing: false,
  isLoading: false,
  isPromptOpen: false,
  isSaving: false,
  originalPassword: '',
  originalToken: '',
  originalUsername: '',
  password: '',
  token: '',
  username: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_USERNAME':
      return { ...state, username: action.val };
    case 'CHANGE_PASSWORD':
      return { ...state, password: action.val };
    case 'CHANGE_TOKEN':
      return { ...state, token: action.val };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_CREDENTIALS':
      return {
        ...state,
        originalPassword: action.payload.apiPassword,
        originalToken: action.payload.accountToken,
        originalUsername: action.payload.apiUserName,
        password: action.payload.apiPassword,
        token: action.payload.accountToken,
        username: action.payload.apiUserName,
      };
    case 'ADD_CREDENTIALS':
      return {
        ...state,
        originalPassword: state.password,
        originalToken: state.token,
        originalUsername: state.username,
        password: state.password,
        token: state.token,
        username: state.username,
      };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false };
    case 'SET_IS_EDITING':
      return { ...state, isEditing: true };
    case 'SET_IS_EDITING_CANCELLED':
      return { ...state, isEditing: false };
    default:
      return state;
  }
};

function HollwegAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const resp = await retryableAPICall(() => getCredentials());

        if (isMounted) {
          if (resp) {
            dispatch({ type: 'SET_CREDENTIALS', payload: resp });
          } else {
            toast.error('Failed to Load Credentials. Please try again later or contact support');
          }
        }
      } catch (error) {
        toast.error('Failed to Load Credentials. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const handleSave = async () => {
    dispatch({ type: 'SET_IS_LOADING' });
    try {
      const resp = await retryableAPICall(() => saveCredentials(state.username, state.token, state.password));

      if (typeof resp === 'string' && resp === requestStatuses.ALREADY_EXISTS_ERROR) {
        toast.error('A user for this account already exists. Please contact support for assistance.');
      } else if (typeof resp === 'string') {
        toast.error('Failed to add credentials. Please try again later or contact support');
      } else {
        dispatch({
          type: 'ADD_CREDENTIALS',
        });
      }
    } catch (error) {
      toast.error('Failed to add credentials. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  return (
    <Col className="mt-2">
      <Card>
        <CardBody>
          <div className="mb-1">
            <h5>Credentials</h5>
          </div>

          <IntegrationInput
            id="username"
            isAuthorised={state.isAuthorised}
            isEditing={state.isEditing}
            label="Username"
            onChange={(val) => {
              dispatch({
                type: 'CHANGE_USERNAME',
                val,
              });
            }}
            value={state.username || ''}
          />
          <IntegrationInput
            hideValue
            id="password"
            isAuthorised={state.isAuthorised}
            isEditing={state.isEditing}
            label="Password"
            onChange={(val) =>
              dispatch({
                type: 'CHANGE_PASSWORD',
                val,
              })
            }
            type="password"
            value={state.password || ''}
          />
          <IntegrationInput
            hideValue
            id="token"
            isAuthorised={state.isAuthorised}
            isEditing={state.isEditing}
            label="API Token"
            onChange={(val) => {
              dispatch({
                type: 'CHANGE_TOKEN',
                val,
              });
            }}
            type="password"
            value={state.token || ''}
          />
          {state.isEditing ? (
            <>
              <CreateButton
                action={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
                className="mt-2"
                disabled={state.isSaving}
                floatRight={false}
                isLoading={state.isSaving}
                label={state.isSaving ? 'Authenticating...' : 'Save'}
              />
              <CancelButton
                action={() => {
                  dispatch({
                    type: 'SET_IS_EDITING_CANCELLED',
                  });
                }}
                className="mt-2 ms-2"
                disabled={state.isSaving}
                floatRight={false}
                isLoading={state.isSaving}
              />
            </>
          ) : (
            <EditButton
              action={() =>
                dispatch({
                  type: 'SET_IS_EDITING',
                })
              }
              className="mt-2"
              floatRight={false}
              label="Edit Credentials"
            />
          )}
        </CardBody>
      </Card>
      {state.originalUsername && state.originalPassword && state.originalToken && (
        <Card className="mt-2">
          <CardBody>
            <HollwegLocations />
            <HollwegRoles />
          </CardBody>
        </Card>
      )}
    </Col>
  );
}

HollwegAdmin.propTypes = {};

HollwegAdmin.defaultProps = {};

export default HollwegAdmin;
