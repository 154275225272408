/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import moment from 'moment';
import FormManagerContext from './FormManagerContext';
import ErrorMessage from './ErrorMessage';
import { useFormKeypress } from '../../../../Base/hooks';
import { AccountFormSelect } from '../../../../Base/Account';
import { DatePickerInput } from '../../../../Base/Forms/Custom/DatePicker';
import RTEEDoc from '../../../../Base/Forms/Custom/RTE/RTEEDoc';

const defaultContractForm = [
  {
    id: 'edocSection',
    name: '',
    questions: [
      {
        id: 'edoc-text',
        text: 'Content',
        type: 'informationalText',
        required: false,
        value: '',
        config: {},
        number: 1,
      },
      {
        id: 'signature-date',
        text: 'Signature Date',
        type: 'date',
        required: true,
        value: '',
        config: { currentDateOnly: true },
        number: 1,
      },
      {
        id: 'signature-info',
        text: 'Signature',
        type: 'signature',
        required: true,
        config: {},
      },
    ],
  },
];

function EDocFormManager({
  formSections,
  formName: fName,
  formType: fType,
  sharedWith,
  readOnly,
  isSubmitted,
  onChange,
  totalAccounts,
}) {
  const [formName, setFormName] = useState('');
  const [signatureTitle, setSignatureTitle] = useState('Please Sign Here...');
  const [formType, setFormType] = useState('');
  const [formReadOnly, setFormReadOnly] = useState(false);
  const [formShare, setFormShare] = useState([]);
  const [sections, setSections] = useState(defaultContractForm);
  const formRef = useFormKeypress();

  useEffect(() => {
    setFormName(fName);
    setFormType('EDOC');
    if (formSections[0]?.id !== 'edocSection') {
      setSections(defaultContractForm);
    } else {
      setSections(formSections);
    }

    setFormReadOnly(readOnly);
    setFormShare(sharedWith);
  }, [fName, fType, formSections, readOnly, sharedWith]);

  return (
    <FormManagerContext.Consumer>
      {() => (
        <Fragment>
          <Form className="flexiform" innerRef={formRef}>
            <Row>
              <Col>
                <h5>eDoc Settings</h5>
              </Col>
            </Row>
            <Row form>
              <Col>
                <FormGroup className="disabled-margin-bottom pb-2">
                  <Label for="form-name">eDoc name (Internal Only)</Label>
                  <Input
                    type="text"
                    name="formName"
                    id="form-name"
                    value={formName}
                    // invalid={isSubmitted && !formName.length}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setFormName(value);
                      onChange(sections, value, formType, formReadOnly, formShare);
                    }}
                  />
                  <ErrorMessage isInvalid={isSubmitted && !formName.length}>Form name required</ErrorMessage>
                </FormGroup>
              </Col>
            </Row>
            {totalAccounts > 1 && (
              <Row>
                <Col>
                  <FormGroup className="disabled-margin-bottom pb-2">
                    <Label check>
                      <Input
                        type="checkbox"
                        className="standard-checkbox small position-relative ms-0"
                        checked={!formReadOnly}
                        onChange={(e) => {
                          onChange(sections, formName, formType, !e.target.checked, formShare);
                        }}
                      />
                      <span>Editable</span>
                    </Label>
                  </FormGroup>
                  <FormGroup className="disabled-margin-bottom pb-2">
                    <AccountFormSelect
                      id="sharedWith"
                      label="Shared With"
                      className="account-opts disabled-margin-bottom"
                      currentAccounts={(formShare || []).map(({ accountId, accountName }) => ({
                        id: accountId,
                        name: accountName,
                      }))}
                      onChange={(accountObjs = []) => {
                        const accounts = accountObjs.map(({ value, label }) => ({
                          accountId: value,
                          accountName: label,
                        }));
                        onChange(sections, formName, formType, formReadOnly, accounts);
                      }}
                      isMulti
                      ignoreActiveAccount
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <h5>eDoc Content</h5>
              </Col>
            </Row>
            <Row>
              <Col className="contract-quill-editor-container">
                <RTEEDoc
                  formData={sections[0].questions[0].value}
                  onChange={(val, valObj) => {
                    const secCp = sections;
                    secCp[0].questions[0].value = val;
                    setSections(secCp);
                    onChange(secCp, formName, formType, formReadOnly, formShare);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup className="disabled-margin-bottom pb-2">
                  <Label for="form-name">Signature Date (shows uneditable current date to candidate)</Label>
                  <DatePickerInput
                    id="logic-date-picker"
                    value={moment()}
                    onChange={() => {}}
                    showClearDate={false}
                    readOnly
                    appendToBody
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup className="disabled-margin-bottom pb-2">
                  <Label for="form-name">Signature Request Title</Label>
                  <Input
                    type="text"
                    name="signatureTitle"
                    id="signature-title"
                    value={signatureTitle}
                    // invalid={isSubmitted && !formName.length}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setSignatureTitle(value);
                      const secCp = sections;
                      secCp[0].questions[1].value = value;
                      setSections(secCp);
                      onChange(sections, formName, formType, formReadOnly, formShare);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <div className="signature-example-container">
                    <p>Candidates Signature</p>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Fragment>
      )}
    </FormManagerContext.Consumer>
  );
}

EDocFormManager.propTypes = {
  formSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  formName: PropTypes.string,
  formType: PropTypes.string,
  sharedWith: PropTypes.arrayOf(PropTypes.shape()),
  readOnly: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  onChange: PropTypes.func,
  totalAccounts: PropTypes.number,
};

EDocFormManager.defaultProps = {
  formSections: [],
  formName: '',
  formType: 'GENERIC',
  sharedWith: [],
  readOnly: false,
  isSubmitted: false,
  onChange: () => {},
  totalAccounts: 0,
};

export default EDocFormManager;
