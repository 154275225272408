import { Quill } from 'react-quill';
import ResizeVideo from './resize-video';

// https://github.com/quilljs/quill/issues/1707#issuecomment-485019341
// https://github.com/lancetipton/quill-video-resize
const QuillVideo = Quill.import('formats/video');
const BlockEmbed = Quill.import('blots/block/embed');

const VIDEO_ATTRIBUTES = ['width'];

export default class WrapVideo extends BlockEmbed {
  // src is the video source url
  static create(value) {
    const iframeNode = QuillVideo.create(value.src || value || '');
    // node is created wrapper around the iframe
    const node = super.create();
    const resize = new ResizeVideo(node, iframeNode);
    node.appendChild(iframeNode);
    node.appendChild(resize.createControls());
    resize.init((value.width || null), (value.styles || null));
    return node;
  }

  static formats(domNode) {
    const iframe = domNode.getElementsByTagName('iframe')[0];
    return VIDEO_ATTRIBUTES.reduce((formats, attribute) => {
      const formatObj = { ...formats };
      if (iframe.hasAttribute(attribute)) {
        formatObj[attribute] = iframe.getAttribute(attribute);
      }
      return formatObj;
    }, {});
  }

  // https://codepen.io/alexkrolick/pen/PWrKdx
  static value(domNode) {
    const { style } = domNode;
    const iframe = domNode.getElementsByTagName('iframe')[0];

    return {
      src: iframe.getAttribute('src'),
      width: style.width,
      // props should match reset styles ResizeVideo.resetStyles()
      styles: {
        margin: style.margin,
        float: style.float,
        marginLeft: style.marginLeft,
        marginRight: style.marginRight,
        right: style.right,
      },
    };
  }

  format(name, value) {
    if (VIDEO_ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      }
      else {
        this.domNode.removeAttribute(name);
      }
    }
    else {
      super.format(name, value);
    }
  }
}
