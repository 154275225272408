import { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import queryString from 'query-string';
import withRouter from '../../../../hoc/withRouter';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { resetPassword } from '../../../../api/AuthAPI/AuthAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { ResolveLogo } from '../../../Base/SVG';
import { validatePassword } from '../../../../js/utils/validation';

class ForgotPasswordChange extends Component {
  constructor(route) {
    super();
    this.state = {
      formData: {
        password1: undefined,
        password2: undefined,
      },
      tokenVerified: true,
      tokenExpired: false,
      nonMatchingPasswords: false,
      otherError: false, // Includes token not found
      passwordInvalid: false,
      qs: queryString.parse(route.location.search),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // @todo add error handling here
  async handleSubmit(e) {
    e.preventDefault();

    const {
      formData: { password1, password2 },
      qs: { email, token, id },
    } = this.state;

    this.setState({
      resetSuccess: false,
      resetError: false,
      tokenExpired: false,
      offlineError: false,
      isRequesting: true,
      nonMatchingPasswords: false,
      passwordInvalid: false,
    });

    // Check the passwords match
    if (!validatePassword(password1)) {
      this.setState({ passwordInvalid: true, isRequesting: false });
    } else if (password1 !== password2) {
      this.setState({ nonMatchingPasswords: true, isRequesting: false });
    } else {
      const result = await retryableAPICall(async () => resetPassword(email, password1, token, id));

      if (typeof result === 'string') {
        switch (result) {
          case requestStatuses.NOT_FOUND_ERROR:
            this.setState({ tokenExpired: true, isRequesting: false });
            break;
          case requestStatuses.GENERAL_ERROR:
            this.setState({ resetError: true, isRequesting: false });
            break;
          case requestStatuses.OFFLINE_ERROR:
            this.setState({ offlineError: true, isRequesting: false });
            break;
          default:
        }
      } else {
        this.setState({ resetSuccess: true, isRequesting: false });
      }
    }
  }

  handleChange(e) {
    const { formData: data } = this.state;
    const formData = { ...data };
    formData[e.target.name] = e.target.value;
    this.setState({ formData });
  }

  render() {
    const {
      resetError,
      offlineError,
      nonMatchingPasswords,
      passwordInvalid,
      resetSuccess,
      tokenVerified,
      tokenExpired,
      otherError,
      isRequesting,
    } = this.state;

    let error;
    if (resetError) {
      error = (
        <p className="text-danger">
          There has been an error when resetting your password, please check your details and try again. If this
          continues please contact support.
        </p>
      );
    } else if (offlineError) {
      error = (
        <p className="text-danger">
          You appear to be offline, or the service may be experiencing an outage, please check your internet connection
          and try again.
        </p>
      );
    } else if (nonMatchingPasswords) {
      error = <p className="text-danger">Your passwords do not match. Please check and try again</p>;
    } else if (passwordInvalid) {
      error = (
        <p className="text-danger">Your password isn&apos;t strong enough. Please check with the requirements above</p>
      );
    }

    let content;
    if (resetSuccess) {
      content = (
        <Row>
          <p>Your password has now been reset please click the link below to login with your new password</p>
          <a href="/login">Click here to login</a>
        </Row>
      );
    } else if (!tokenVerified && !tokenExpired && !otherError) {
      content = (
        <Row>
          <Col className="text-center" sm="12">
            <p>We&apos;re just verifying your request</p>
          </Col>
        </Row>
      );
    } else if (tokenExpired) {
      content = (
        <Row>
          <Col className="text-center" sm="12">
            <p>
              Your password reset request has now expired or you have previously reset your password using this link.
              Requests are only valid for 24 hours.
              <br />
              <a href="/password-recovery">Request another password reset</a>
            </p>
          </Col>
        </Row>
      );
    } else if (otherError) {
      content = (
        <Row>
          <Col className="text-center" sm="12">
            <p>
              There was a problem with your password request request. This could be because you&apos;ve already reset a
              password with this request. If you need to reset your password again please click the link below
              <br />
              <a href="/password-recovery">Request another password reset</a>
            </p>
          </Col>
        </Row>
      );
    } else if (tokenVerified) {
      content = (
        <Form>
          <p className="text-muted mb-2 text-center">
            Please enter a new password. It should be at least 8 characters long, contain at least one uppercase letter,
            one lowercase letter, one number, and one special character, and not contain any whitespace.
          </p>
          {error}
          <InputGroup className="mb-3 mt-2">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="icon-lock" />
              </span>
            </div>
            <Input name="password1" onChange={this.handleChange} placeholder="New password" type="password" />
          </InputGroup>
          <InputGroup className="mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="icon-lock" />
              </span>
            </div>
            <Input name="password2" onChange={this.handleChange} placeholder="Confirm new password" type="password" />
          </InputGroup>
          <Row>
            <Col style={{ marginTop: 4 }} xs="6">
              <Button className="px-4" disabled={isRequesting} onClick={this.handleSubmit} type="submit">
                {!isRequesting ? 'Reset Password' : 'Loading...'}
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }
    return (
      <div className="app flex-row align-items-center auth-page login-background-image">
        <Container>
          <Row className="justify-content-center">
            <Col xs={8}>
              <CardGroup>
                <Card className="p-4 login-box-bg">
                  <CardBody>
                    <div className="text-center mb-4">
                      <ResolveLogo loginPage />
                    </div>
                    {content}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(ForgotPasswordChange);
