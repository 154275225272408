import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import ModalPopup from './ModalPopup';

function QRModal({
  isOpen,
  onClose,
  url,
  content,
}) {
  return (
    <ModalPopup
      title="Quick Application QR Code"
      okayLabel="Close"
      isOpen={isOpen}
      onToggle={onClose}
      hideCancelButton
      minHeight="300px"
    >
      <p className="text-center">{content}</p>
      <div className="text-center mt-5">
        <QRCode value={url} size={200} />
      </div>
    </ModalPopup>
  );
}

QRModal.propTypes = {
  isOpen: PropTypes.bool,
  url: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
};

QRModal.defaultProps = {
  url: undefined,
  content: undefined,
  isOpen: false,
  onClose: () => { },
};

export default QRModal;
