import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-jsonschema-form';
import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
import moment from 'moment';
import merge from 'lodash.merge';
import { useLanguagePack } from '../Base/hooks';
import { transformErrors, validateFields } from '../../js/utils/validation-helper';
import RTE from '../Base/Forms/Custom/RTE/RTE';
import SingleDDSelect from '../Base/Forms/Custom/DDSelect/SingleDDSelect';
import AsyncDDSelect from '../Base/Forms/Custom/DDSelect/AsyncDDSelect';
import LimitedTextArea from '../Base/Forms/Custom/LimitedTextArea/LimitedTextArea';
import AddressLookup from '../Base/Forms/Custom/AddressLookup/AddressLookup';
import DatePicker from '../Base/Forms/Custom/DatePicker/DatePicker';
import { RadioBtns } from '../Base/Forms/Custom/RadioBtns';
import ApplicationFormModal from '../Base/Forms/Custom/FlexiFormModal/ApplicationFormModal';
import { addFlexiFormField } from '../../js/model/form-schemas/flexiforms';
import { CreateButton, CancelButton } from '../Base/Buttons';
import StaticField from '../Base/Forms/Custom/Static/StaticField';
import LocationSelector from '../Base/Forms/Custom/LocationSelector/LocationSelector';
import { EmailPills } from '../Base/Forms/Custom/EmailPills';
import JobSkillsSelect from '../Base/Forms/Custom/JobSkillsSelect/JobSkillsSelect';

function translateLabels(uiSchema = {}, langPack = {}) {
  if (Object.keys(uiSchema).length && Object.keys(langPack).length) {
    return merge(uiSchema, langPack);
  }
  return uiSchema;
}

function VacancyForm({
  vacancyData,
  companyName,
  formSchemas,
  className,
  isEditing,
  hideSubmitButton,
  onSubmit,
  onTemplateSave,
  onCancel,
  asyncCache,
}) {
  const languagePack = useLanguagePack('vacancy-form');
  const tempData = useRef();
  const [formData, setFormData] = useState({
    applicationMethod: {
      appFormLink: {},
      appMethod: 'APPLICATION_FORM',
    },
    vacancyDetails: {
      referenceCode: `${companyName.substring(0, 3).toUpperCase()}-${moment().format('MMDDHHmmss')}`,
      companyName,
    },
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (Object.keys(vacancyData).length) setFormData(vacancyData);
  }, [vacancyData]);

  function handleSubmit(formObj) {
    const { formData: submittedData } = formObj;

    setIsSaving(true);
    setFormData(submittedData);
    onSubmit(submittedData, () => setIsSaving(false));
  }

  if (!Object.keys(formSchemas).length) return null;

  const { formSchema: schema, uiSchema, rules } = formSchemas;
  const translatedUiSchema = translateLabels(uiSchema, languagePack.uiSchema);
  const CondForm = applyRules(schema, translatedUiSchema, rules, Engine)(Form);

  // eslint-disable-next-line no-nested-ternary
  const submitBtnLabel = isSaving
    ? languagePack.savingLabel
    : isEditing
    ? languagePack.updateButtonLabel
    : languagePack.createButtonLabel;

  return (
    <CondForm
      className={className}
      showErrorList={false}
      noHtml5Validate
      formData={formData}
      onSubmit={handleSubmit}
      validate={validateFields}
      transformErrors={transformErrors}
      fields={{
        rte: RTE,
        addressLookup: AddressLookup,
        locationSelect: LocationSelector,
        datePicker: DatePicker,
        static: StaticField,
        singleSelect: SingleDDSelect,
        remoteSelect: AsyncDDSelect,
        radioBtns: RadioBtns,
        jobSkillsSelect: JobSkillsSelect,
        limitedTextArea: LimitedTextArea,
        applicationFormModal: ApplicationFormModal,
      }}
      widgets={{
        emailPills: EmailPills,
      }}
      onChange={({ formData: currentData }) => {
        tempData.current = { ...currentData };
      }}
      formContext={{
        asyncCache,
      }}
    >
      {!hideSubmitButton && (
        <CreateButton isLoading={isSaving} disabled={isSaving} type="submit" label={submitBtnLabel} />
      )}
      <CreateButton
        disabled={isSaving}
        floatRight={hideSubmitButton}
        action={(e) => {
          e.preventDefault();
          onTemplateSave(tempData.current);
        }}
        label={languagePack.templateButtonLabel}
      />
      <CancelButton disabled={isSaving} action={onCancel} label={languagePack.cancelButtonLabel} />
    </CondForm>
  );
}

VacancyForm.propTypes = {
  vacancyData: PropTypes.shape(),
  companyName: PropTypes.string,
  formSchemas: PropTypes.shape({
    formSchema: PropTypes.shape(),
    uiSchema: PropTypes.shape(),
    rules: PropTypes.arrayOf(PropTypes.shape()),
  }),
  className: PropTypes.string,
  isEditing: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  onSubmit: PropTypes.func,
  onTemplateSave: PropTypes.func,
  onCancel: PropTypes.func,
  asyncCache: PropTypes.shape(),
};

VacancyForm.defaultProps = {
  vacancyData: {},
  companyName: null,
  formSchemas: {
    formSchema: {},
    uiSchema: {},
    rules: [],
  },
  className: null,
  isEditing: false,
  hideSubmitButton: false,
  onSubmit: () => {},
  onTemplateSave: () => {},
  onCancel: () => {},
  asyncCache: {},
};

function mapStateToProps(state) {
  const {
    companyData: {
      companyDetails: { companyName },
    },
    formData: {
      forms: {
        createVacancyForm: { formData },
      },
    },
  } = state;

  return { companyName, formSchemas: addFlexiFormField(formData) };
}

export default connect(mapStateToProps)(VacancyForm);
