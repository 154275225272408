export default function getFormattedAddress(suggestion) {
  let formattedAdd = '';

  if (suggestion) {
    if (suggestion.type === 'Country') {
      formattedAdd = suggestion.countryName;
    } else {
      if (suggestion.poiName) {
        formattedAdd = `${suggestion.poiName}, `;
      }

      if (suggestion.addressLine1) {
        formattedAdd += `${suggestion.addressLine1} `;
      }

      if (suggestion.addressLine2) {
        formattedAdd += `${suggestion.addressLine2}, `;
      }

      if (suggestion.addressLine3) {
        formattedAdd += `${suggestion.addressLine3}, `;
      }

      if (suggestion.city) {
        formattedAdd += `${suggestion.city}, `;
      }

      if (suggestion.county && suggestion.type !== 'Municipality') {
        formattedAdd += `${suggestion.county}, `;
      }

      if (suggestion.postCode) {
        formattedAdd += `${suggestion.postCode}, `;
      }

      if (suggestion.country) {
        formattedAdd += `${suggestion.country}`;
      }
    }
  }

  return formattedAdd;
}
