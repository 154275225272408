import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IconSVG from '../SVG/IconSVG';

export default function TabItem({
                                  isActiveTab,
                                  onClick,
                                  label,
                                  anchor,
                                  isVertical,
                                  icon,
                                  hideLabels,
                                  className,
                                  labelComponent,
                                  isGroupItem,
                                }) {
  const hash = (anchor || label).toLowerCase().replace(' ', '_');
  return (
    <li
      className={cx(
        className,
        {
          'is-vertical': isVertical,
          'tab-item-active': isActiveTab,
          'group-item': isGroupItem,
        },
        hash,
      )}
    >
      <a href={`#${hash}`} onClick={onClick}>
        {icon && <IconSVG name={icon} />}
        <span className={cx({ 'sr-only': hideLabels })}>{label}</span>
        {labelComponent}
        {isVertical && !isGroupItem && <IconSVG name="Chevron Left" width="1.75em" />}
      </a>
    </li>
  );
}

TabItem.propTypes = {
  isActiveTab: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  isVertical: PropTypes.bool,
  icon: PropTypes.string,
  hideLabels: PropTypes.bool,
  className: PropTypes.string,
  labelComponent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TabItem.defaultProps = {
  isActiveTab: false,
  onClick: () => { },
  anchor: null,
  isVertical: false,
  icon: null,
  hideLabels: false,
  className: 'tab-item',
  labelComponent: null,
};
