import React from 'react';
import BaseSVG from '../BaseSVG';

function Redo(props) {
  return (
    <BaseSVG
      {...props}
      name="Redo"
      viewBox="0 0 512 512"
      // eslint-disable-next-line max-len
      d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
    />
  );
}

export default Redo;
