import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Input } from 'reactstrap';
import { checkPermissions } from '../../../../js/auth/AuthUtils';

const options = [
  { label: 'Less Than', value: 'LT' },
  { label: 'Greater Than', value: 'GT' },
  { label: 'Equal To', value: 'EQ' },
];

const holweggPT = [{ label: 'Overall Score', value: 'OVERALL_SCORE' }];

const assesioPT = [
  { label: 'Integrity Score', value: 'INTEGRITY_SCORE' },
  { label: 'Service Score', value: 'SERVICE_SCORE' },
];

let typeOptions = [];

const hollwegScores = [
  { label: 'Low', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'High', value: 3 },
];

function PersonalityTestFilter({ onSelect, selectedValue }) {
  const customStyles = {
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
    }),
  };

  const hasAssesioPT = checkPermissions(['pt:read']);
  const hasHolweggoPT = checkPermissions(['pth:read']);

  if (hasAssesioPT && hasHolweggoPT) {
    typeOptions = [...assesioPT, ...holweggPT];
  } else if (hasAssesioPT) {
    typeOptions = assesioPT;
  } else if (hasHolweggoPT) {
    typeOptions = holweggPT;
  }

  const currentOption = options.find((a) => a.value === selectedValue?.operation);
  const currentTypeOption = typeOptions.find((a) => a.value === selectedValue?.type);

  const currentHollweggScore = hollwegScores.find((a) => a.value === selectedValue?.value);

  return (
    <>
      <Select
        classNamePrefix="react-select"
        id="filter-question-select"
        isClearable
        menuPosition="fixed"
        onChange={(val) => {
          onSelect({
            ...selectedValue,
            type: val?.value,
          });
        }}
        options={typeOptions}
        placeholder="Select Score Type"
        styles={customStyles}
        value={currentTypeOption}
      />
      <Select
        className="mt-2"
        classNamePrefix="react-select"
        id="filter-question-select"
        isClearable
        menuPosition="fixed"
        onChange={(val) => {
          onSelect({
            ...selectedValue,
            operation: val?.value,
          });
        }}
        options={options}
        placeholder="Select Operation"
        styles={customStyles}
        value={currentOption}
      />
      {currentTypeOption?.value === 'OVERALL_SCORE' ? (
        <Select
          className="mt-2"
          classNamePrefix="react-select"
          isClearable
          menuPosition="fixed"
          onChange={(val) => {
            onSelect({
              ...selectedValue,
              value: val?.value,
            });
          }}
          options={hollwegScores}
          placeholder="Score Achieved"
          styles={customStyles}
          value={currentHollweggScore}
        />
      ) : (
        <Input
          className="mt-2"
          id="pt_value"
          onChange={(e) => {
            const isValid =
              selectedValue?.operation === 'LT' || selectedValue?.operation === 'GT'
                ? (e.currentTarget.value <= 100 && e.currentTarget.value >= 0) || e.currentTarget.value === ''
                : true;

            if (isValid) {
              onSelect({
                ...selectedValue,
                value: e.currentTarget.value,
              });
            }
          }}
          placeholder="Enter Value"
          step={1}
          type={selectedValue?.operation === 'LT' || selectedValue?.operation === 'GT' ? 'number' : 'text'}
          value={selectedValue?.value || ''}
        />
      )}
    </>
  );
}

PersonalityTestFilter.propTypes = {
  onSelect: PropTypes.func,
  selectedValue: PropTypes.shape(),
};

PersonalityTestFilter.defaultProps = {
  onSelect: () => {},
  selectedValue: {},
};

export default PersonalityTestFilter;
