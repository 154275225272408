import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconSVG from '../../Base/SVG/IconSVG';
import { PRIMARY_COLOUR } from '../../../js/utils/colours';
import { TextButton } from '../../Base/Buttons';

function UploadArea({
  isDragActive,
  languagePack,
  inputProps,
}) {
  return (
    <Fragment>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input className="dropzone-input" {...inputProps} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content large">{languagePack.releaseToDrop}</p>
        ) : (
          <Fragment>
            <IconSVG name="Cloud Upload" className="mt-3" width="3.5em" fill={PRIMARY_COLOUR} />
            <p className="dropzone-content mb-2">
              {`${languagePack.dragAndDrop} ${languagePack.or}`}
            </p>
            <TextButton label={languagePack.browseFiles || 'Browse Files'} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

UploadArea.propTypes = {
  isDragActive: PropTypes.bool,
  languagePack: PropTypes.shape(),
  inputProps: PropTypes.shape(),
};

UploadArea.defaultProps = {
  isDragActive: false,
  languagePack: {},
  inputProps: {},
};

export default UploadArea;
