import React, {
  Fragment,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BasicSelect from '../BasicSelect/BasicSelect';

const THIS_YEAR = new Date().getFullYear();

function yearRange() {
  const endYear = THIS_YEAR + 5;
  const startYear = THIS_YEAR - 80;
  const years = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    years.push({ value: i.toString(), label: i.toString() });
  }

  return years.reverse();
}

const YEARS = yearRange();

const MONTHS = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

const DAYS = Array.from({ length: 31 }, (_, i) => {
  const dayNum = (i + 1);
  const dayStr = dayNum.toString();

  return {
    value: dayNum < 10 ? `0${dayNum}` : dayStr,
    label: dayStr,
  };
});

function getISOString(dateObj) {
  const { year, month, day } = dateObj;
  return `${year || THIS_YEAR}-${month || '01'}-${day || '01'}`;
}

function BasicDateSelect({
  disabled,
  onChange,
  date,
  isTouch,
  id,
  className,
}) {
  const [dateObj, setDateObj] = useState({
    year: '',
    month: '',
    day: '',
    isoStr: '',
  });

  useEffect(() => {
    if (date) {
      let year = '';
      let month = '';
      let day = '';
      let isoStr = '';

      [isoStr] = date.toISOString().split('T');
      if (isoStr.length) [year, month, day] = isoStr.split('-');

      setDateObj({
        isoStr,
        year,
        month,
        day,
      });
    }
  }, [date]);

  function handleChange(val, prop) {
    const updatedDateObj = { ...dateObj, [prop]: val };
    const isoStr = getISOString(updatedDateObj);
    setDateObj({ ...updatedDateObj, isoStr });
    onChange(new Date(isoStr));
  }

  if (isTouch) {
    return (
      <input
        id={id}
        className={cx('form-control', className)}
        type="date"
        value={dateObj.isoStr}
        onChange={(e) => {
          const { value } = e.target;
          onChange(new Date(value));
        }}
      />
    );
  }

  return (
    <Fragment>
      <BasicSelect
        disabled={disabled}
        disableFirstOption
        value={dateObj.year}
        options={[{ value: '', label: 'Year' }, ...YEARS]}
        onChange={(val) => handleChange(val, 'year')}
      />
      <BasicSelect
        disabled={disabled}
        disableFirstOption
        value={dateObj.month}
        options={[{ value: '', label: 'Month' }, ...MONTHS]}
        onChange={(val) => handleChange(val, 'month')}
      />
      <BasicSelect
        disabled={disabled}
        disableFirstOption
        value={dateObj.day}
        options={[{ value: '', label: 'Day' }, ...DAYS]}
        onChange={(val) => handleChange(val, 'day')}
      />
    </Fragment>
  );
}

BasicDateSelect.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  isTouch: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};

BasicDateSelect.defaultProps = {
  disabled: false,
  onChange: () => { },
  date: new Date(),
  isTouch: false,
  id: null,
  className: null,
};

export default BasicDateSelect;
