// Contains a set of API calls to middleware services in order to get data around events
import axios from 'axios';
import config from '../../config/config';

import { getCommonHeaders } from '../common-api-utils';

export async function setTalentMatchStatus(vacancyId, jobSeekerId, status) {
  const body = {
    vacancyId,
    jobSeekerId,
    status,
  };

  const result = await axios.put(
    `${config.api.middlewareAPIURL}${config.jobseeker.talentMatchSetStatusPath}`,
    [body],
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;

  return null;
}

export async function setTalentMatchFavourite(vacancyId, jobSeekerIds, favourite) {
  const body = {
    vacancyId,
    jobSeekerIds,
    favourite,
  };

  const result = await axios.put(
    `${config.api.middlewareAPIURL}${config.jobseeker.talentMatchSetFavouritePath}`,
    body,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;

  return null;
}

export async function getJobSeekerProfile(jobSeekerId) {
  const result = await axios.get(
    `${config.api.middlewareAPIURL}${config.jobseeker.talentMatchProfilePath}/${jobSeekerId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function inviteToApply(jobSeekerIds, vacancyId, fromName, subject, htmlContent, attachments) {
  const result = await axios.post(
    `${config.api.middlewareAPIURL}${config.jobseeker.talentMatchInviteToApply}`,
    {
      jobSeekerIds,
      vacancyId,
      fromName,
      subject,
      htmlContent,
      attachments,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
