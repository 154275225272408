export const UPDATE_FUNNEL = 'UPDATE_FUNNEL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_SMS = 'UPDATE_SMS';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS';

export function candidateDataReducer(state, action) {
  const { type, id, payload } = action;

  switch (type) {
    case UPDATE_FUNNEL:
      return {
        ...state,
        [id]: {
          ...state[id],
          funnelData: payload,
        },
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        [id]: {
          ...state[id],
          emailData: { ...payload },
        },
      };
    case UPDATE_SMS:
      return {
        ...state,
        [id]: {
          ...state[id],
          smsData: payload,
        },
      };
    case UPDATE_NOTE:
      return {
        ...state,
        [id]: {
          ...state[id],
          noteData: payload,
        },
      };
    case UPDATE_ATTACHMENTS:
      return {
        ...state,
        [id]: {
          ...state[id],
          attData: payload,
        },
      };
    default:
      return state;
  }
}
