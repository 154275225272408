import {
  START_POLLING,
  STOP_POLLING,
  TOGGLE_NOTIFICATION_READ_STATE,
  DELETE_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
} from '../constants/actionTypes';

export const toggleNotificationReadState = (notificationIds, isViewed) => ({
  type: TOGGLE_NOTIFICATION_READ_STATE,
  data: { ids: notificationIds, isViewed },
});

export const deleteNotificationsFromView = (notificationIds) => ({
  type: DELETE_NOTIFICATIONS,
  data: notificationIds,
});

export const fetchNotifications = (data) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  data,
});

export function startPolling() {
  return { type: START_POLLING };
}

export function stopPolling() {
  return { type: STOP_POLLING };
}
