import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import { connect } from 'react-redux';
import ChatEntry from './ChatEntry';
import { EmailForm, SMSForm } from '../../../Communication';
import { getCandidateNotifications } from '../../../../api/NotificationAPI/NotificationAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { IconSVG } from '../../../Base/SVG';
import { useLanguagePack } from '../../../Base/hooks';

function ChatContainer({ candidate, userName }) {
  const [chatEntries, setChatEntries] = useState([]);
  const chatEntriesRef = useRef(chatEntries);
  const pollingTimerRef = useRef(null);
  const pollingCountRef = useRef(0);
  const lastPollDateRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedMedium, setSelectedMedium] = useState('email');
  const [emailData, setEmailData] = useState({});
  const [showError, setShowError] = useState(false);
  const [userScrolledToBottom, setUserScrolledToBottom] = useState(true);
  const chatInnerRef = useRef();
  const languagePack = useLanguagePack('chat');

  function scrollToBottom() {
    const chatObj = document.getElementById('chat-bubble-container');
    if (chatObj) chatObj.scrollTop = chatObj.scrollHeight;
  }

  const onScroll = () => {
    if (chatInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setUserScrolledToBottom(true);
      } else {
        setUserScrolledToBottom(false);
      }
    }
  };

  async function getNotifications(since) {
    let fromDate;
    if (!since || pollingCountRef.current > 360) {
      fromDate = candidate.applicationDT;
      pollingCountRef.current = 0; // We refresh all the data every 360 polls to make sure all the data is correct
    } else {
      fromDate = since;
    }
    const result = await retryableAPICall(() => getCandidateNotifications(candidate.applicantId, fromDate));

    if (typeof result === 'string') {
      setShowError(true);
    } else {
      let data;
      if (!since) {
        data = result;
      } else {
        data = JSON.parse(JSON.stringify(chatEntriesRef.current));
        result.forEach((res) => {
          const objIndex = data.findIndex((entry) => entry.messageId === res.messageId);
          if (objIndex !== -1) data[objIndex] = res; else data.push(res);
        });
      }
      setChatEntries(data);
      setShowError(false);
      if (data.length !== chatEntriesRef.current.length && userScrolledToBottom) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
      chatEntriesRef.current = data;
      pollingCountRef.current += 1;
      lastPollDateRef.current = moment().utc(false);
    }
  }

  function pollForChanges() {
    let actualPollTime = lastPollDateRef.current?.subtract({ hours: 1 });
    if (actualPollTime && actualPollTime.seconds() === 0) {
      // Weird bug that is seconds === 0 then the API call fails
      actualPollTime = actualPollTime.subtract({ seconds: 1 });
    }

    getNotifications(actualPollTime?.format('YYYY-MM-DD HH:mm:ss'));
    pollingTimerRef.current = setTimeout(pollForChanges, 5000);
  }

  useEffect(() => {
    pollForChanges();

    return () => clearTimeout(pollingTimerRef.current);
  }, []);

  useEffect(() => {
    if (initialLoad && chatEntries.length > 0) {
      scrollToBottom();
      setInitialLoad(false);
      switch (chatEntries[chatEntries.length - 1]?.type) {
        case 'DEFAULT_EMAIL':
          setSelectedMedium('email');
          break;
        case 'TEMPLATE_EMAIL':
          setSelectedMedium('email');
          break;
        case 'TEMPLATE_SMS':
          setSelectedMedium('sms');
          break;
        case 'DEFAULT_SMS':
          setSelectedMedium('sms');
          break;
        default:
          setSelectedMedium('email');
          break;
      }
    }
  }, [chatEntries]);

  function handleSuccessfulSend(successMessage, content) {
    const chatCp = [...chatEntries];

    const date = moment.utc().format('YYYY-MM-DD HH:mm:SS');

    chatCp.push({
      title: content.title,
      content: content.body,
      attachments: content.attachments,
      direction: 'SENT',
      status: 'SENT',
      sentUserName: userName,
      createdDateTime: date,
      messageId: content['0']?.messageId,
    });
    setChatEntries(chatCp);
    chatEntriesRef.current = chatCp;
    setTimeout(() => {
      const chatObj = document.getElementById('chat-bubble-container');
      chatObj.scrollTop = chatObj.scrollHeight;
    }, 100);
  }

  function doReply(entry) {
    const {
      title, messageId, type,
    } = entry;
    const newEmailVal = {
      fromName: `${userName}`,
      replyTo: 'not-required@talent-funnel.com',
      subject: title,
      message: emailData?.message || '',
      attachments: [],
      bccAddresses: [],
      ccAddresses: [],
      replyToMessageId: messageId,
      to: [`${candidate.applicantName}`],
    };
    setEmailData(newEmailVal);

    if (type.includes('_EMAIL')) {
      setSelectedMedium('email');
    } else if (type.includes('_SMS')) {
      setSelectedMedium('sms');
    }
  }

  return (
    <Fragment>
      <div className="chat-container">
        {showError && <div className="chat-error-banner">{languagePack.errorLoadingChat}</div>}
        <div className="chat-bubbles" id="chat-bubble-container" onScroll={onScroll} ref={chatInnerRef}>
          {chatEntries.map((entry) => (
            <ChatEntry
              id={entry.id}
              languagePack={languagePack}
              entry={entry}
              doReply={doReply}
              candidate={candidate}
              isSent
            />
          ))}
        </div>
        <div className="chat-compose-container">
          <div>
            <div className="chat-selection-icon" onClick={() => setSelectedMedium('email')}>
              <IconSVG width="2.8em" name="Email" fill={selectedMedium === 'email' ? '#4DBD74FF' : '#000'} />
            </div>
            <div className="chat-selection-icon" onClick={() => setSelectedMedium('sms')}>
              <IconSVG width="1.8em" name="Message" fill={selectedMedium === 'sms' ? '#4DBD74FF' : '#000'} />
            </div>
            {/* <div className="chat-selection-icon" onClick={() => setSelectedMedium('whatsapp')}> */}
            {/*  <IconSVG width="2em" name="WhatsApp" fill={selectedMedium === 'whatsapp' ? '#4DBD74FF' : '#000'} /> */}
            {/* </div> */}
          </div>
          <div>
            {
            selectedMedium === 'email'
            && (
              <Fragment>
                <div className="chat-email-compose-container">
                  <EmailForm
                    data={emailData}
                    applicants={[candidate]}
                    hideInputs={['replyTo', 'to']}
                    onChange={(formData) => {
                      const {
                        fromName,
                        replyTo,
                        subject,
                        message,
                        ...rest
                      } = formData;

                      setEmailData({
                        fromName,
                        replyTo,
                        subject,
                        message,
                        ...rest,
                      });
                    }}
                    onSuccess={handleSuccessfulSend}
                    onError={() => toast.error(languagePack.errorSendingMessage)}
                  />
                </div>
              </Fragment>
            )
          }
            {
            selectedMedium === 'sms'
            && (
              <SMSForm
                applicants={[candidate]}
                onSuccess={handleSuccessfulSend}
                onError={() => toast.error(languagePack.errorSendingMessage)}
              />
            )
          }

          </div>
        </div>

      </div>

    </Fragment>
  );
}

ChatContainer.propTypes = {
  candidate: PropTypes.shape(),
  userName: PropTypes.string,
};

ChatContainer.defaultProps = {
  candidate: {},
  userName: null,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        firstName, lastName,
      },
    },
  } = state;
  return { userName: `${firstName} ${lastName}` };
}

export default connect(mapStateToProps)(ChatContainer);
