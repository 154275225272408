import validation, { mapErrors } from '../../../../js/utils/validation';

export default function validateWorkflow(data) {
  const baseErrObj = validation([
    { id: 'name', required: true },
    { id: 'type', required: true },
  ], data);
  const { messages: baseMsgs, hasErrors: baseErrs } = mapErrors(baseErrObj);

  let filterErrObj = {};
  if (data.type === 'ONBOARDING_STATUS_CHANGE') {
    filterErrObj = validation([{ id: 'status', required: true }], data.filters);
  }
  else if (data.type === 'EVENT_STATUS_CHANGE') {
    filterErrObj = validation([
      { id: 'status', required: true },
      { id: 'eventTypeId', required: true },
    ], data.filters);
  }
  else if (data.type === 'FUNNEL_STATE_CHANGE') {
    filterErrObj = validation([
      { id: 'funnelId', required: true },
      { id: 'stageId', required: true },
    ], data.filters);
  }
  const { messages: filterMsgs, hasErrors: filterErrs } = mapErrors(filterErrObj);

  let actionErrors = [];
  if (data.type && data?.actions?.length) {
    actionErrors = data.actions.map((action) => {
      const { type, emailTemplateConfig, funnelStageConfig } = action;

      let actionErrObj = validation([
        { id: 'type', required: true },
        { id: 'delay', required: true, type: 'number' },
      ], action);

      if (type === 'REQUEST_ONBOARDING') {
        actionErrObj = {
          ...actionErrObj,
          ...validation([{ id: 'id', required: true }], emailTemplateConfig),
        };
      }
      else if (type === 'UPDATE_FUNNEL_STAGE') {
        actionErrObj = {
          ...actionErrObj,
          ...validation([
            { id: 'funnelId', required: true },
            { id: 'stageId', required: true },
          ], funnelStageConfig),
        };
      }

      return mapErrors(actionErrObj);
    });
  }

  const messages = {
    ...baseMsgs,
    filters: { ...filterMsgs },
    actions: actionErrors.map(({ messages: msg }) => msg),
  };
  const hasErrors = baseErrs || filterErrs || actionErrors.some(({ hasErrors: hasErrs }) => hasErrs);

  return { messages, hasErrors };
}
