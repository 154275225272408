import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';
import { CreateButton } from '../../../Base/Buttons';
import { getSAPNL, submitToSAPNL, deleteSAPNL } from '../../../../api/Integrations/SAPNLAPI';
import styled from 'styled-components';

const StyledSpan = styled.div`
  width: fit-content;
  float: right;
`;

function SAPNLForm({ applicantId }) {
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Fetch candidate status on component mount and applicantId change
  useEffect(() => {
    const fetchStatus = async () => {
      const response = await retryableAPICall(() => getSAPNL(applicantId));

      if (response === '') {
        return;
      }

      if (response && response.status === 'SUCCESS') {
        setSubmissionStatus('SUCCESS');
      } else {
        toast.error('Failed to fetch candidate status');
      }
    };

    if (applicantId) {
      fetchStatus();
    }
  }, [applicantId]);

  const handleSubmit = async () => {
    if (submissionStatus && submissionStatus === 'SUCCESS') {
      setSubmissionStatus('SUBMITTING');

      // Resubmitting the candidate involves deleting the current submission first
      const deleteResponse = await retryableAPICall(() => deleteSAPNL(applicantId));

      if (!deleteResponse) {
        toast.error('Failed to delete previous submission');
        setSubmissionStatus(null);
        return;
      }
    }
    setSubmissionStatus('SUBMITTING');
    // Submit or resubmit candidate
    const submitResponse = await retryableAPICall(() => submitToSAPNL(applicantId));

    if (submitResponse) {
      toast.success('Candidate successfully submitted to SAP');
      setSubmissionStatus('SUCCESS'); // Update status with new submission details
    } else {
      toast.error('Submission failed');
      setSubmissionStatus(null);
    }
  };

  let buttonText = 'Submit to SAP NL';

  if (submissionStatus === 'SUBMITTING') {
    buttonText = 'Submitting...';
  }

  if (submissionStatus === 'SUCCESS') {
    buttonText = 'Resubmit to SAP NL';
  }

  return (
    <>
      <hr />
      <Row>
        <Col>
          <Tippy content="Submit candidate to SAP NL" disabled={!applicantId}>
            <StyledSpan>
              <CreateButton
                action={handleSubmit}
                disabled={!applicantId || submissionStatus === 'SUBMITTING'}
                label={buttonText}
              />
            </StyledSpan>
          </Tippy>
        </Col>
      </Row>
    </>
  );
}

SAPNLForm.propTypes = {
  applicantId: PropTypes.string,
};

SAPNLForm.defaultProps = {
  applicantId: null,
};

export default SAPNLForm;
