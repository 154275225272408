import {
  call,
  put,
  take,
  race,
  all,
  select,
} from 'redux-saga/effects';

import {
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  START_POLLING,
  STOP_POLLING,
} from '../constants/actionTypes';
import { getEventNotifications } from '../../api/EventAPI/EventAPI';

export const userAuthState = (state) => state.userData.userAuth;

function delay(duration) {
  const promise = new Promise((resolve) => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

function* fetchNotifications() {
  while (true) {
    try {
      const userAuth = yield select(userAuthState);

      if (userAuth && userAuth.isAuthenticated && !document.hidden) {
        const data = yield call(() => getEventNotifications());

        yield put({ type: FETCH_NOTIFICATIONS_SUCCESS, data });
      }

      yield call(delay, 30000);
    } catch (e) {
      yield call(delay, 30000);
      yield put({ type: FETCH_NOTIFICATIONS_FAILURE, message: true });
    }
  }
}

function* watchPollNotificationsSaga() {
  while (true) {
    const data = yield take(START_POLLING);
    yield race([call(fetchNotifications, data), take(STOP_POLLING)]);
  }
}

export default function* root() {
  yield all([watchPollNotificationsSaga()]);
}
