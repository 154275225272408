import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import moment from 'moment';
import * as utils from '../../utils';
import Required from '../../../Base/Forms/Custom/Required';
import { SelectInput } from '../../../Base/Forms/Custom/CommonComponents';
import { RangePicker } from '../../../Base/Forms/Custom/DatePicker';
import { useMounted } from '../../../Base/hooks';

async function fetchOptions(id, callback, startDate, endDate, accountId, eventTypeId, isInviteOnly) {
  const eventsArr = await utils.fetchUtils.getOptions(id, [
    startDate,
    endDate,
    accountId,
    undefined,
    eventTypeId,
    isInviteOnly,
  ]);
  const opts = eventsArr.map((evObj) => {
    const { id: evId, eventName, allDay, startDate: sDate, endDate: eDate } = evObj;
    const evStart = moment.utc(sDate).local();
    const evEnd = moment.utc(eDate).local();

    return {
      label: `${eventName}: ${utils.eventUtils.getEventTitle({ allDay, endDate: evEnd, startDate: evStart })}`,
      startDate: sDate,
      value: evId,
    };
  });

  callback(opts);
}

function SlotDropdown({ required, ...rest }) {
  return (
    <FormGroup>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <Label>
        Choose slot
        {required && <Required />}
      </Label>
      <SelectInput {...rest} />
    </FormGroup>
  );
}

SlotDropdown.propTypes = {
  required: PropTypes.bool,
};

SlotDropdown.defaultProps = {
  required: false,
};

function DateRange(props) {
  return (
    <FormGroup>
      <Label htmlFor="endDate">Filter by date range</Label>
      <div>
        <RangePicker {...props} id="date-range" />
      </div>
    </FormGroup>
  );
}

function SlotSelect({
  value,
  label,
  onChange,
  required,
  disabled,
  isTouch,
  activeAccountId,
  eventTypeId,
  isInviteOnly,
}) {
  const isMounted = useMounted();
  const [dateRange, setDateRange] = useState({
    endDate: moment(new Date()).add(1, 'month'),
    startDate: moment(new Date()),
  });

  const [selectOpts, setSelectOpts] = useState([]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const startDate = utils.dateTimeUtils.getIsoDate(dateRange.startDate);
      const endDate = utils.dateTimeUtils.getIsoDate(dateRange.endDate);

      fetchOptions(
        'slotId',
        (opts) => {
          if (isMounted()) setSelectOpts(opts);
        },
        startDate,
        endDate,
        activeAccountId,
        eventTypeId,
        isInviteOnly,
      );
    }
  }, [dateRange.endDate, dateRange.startDate, isMounted, eventTypeId]);

  return (
    <>
      {label && <h6>{label}</h6>}
      {isTouch ? (
        <>
          <DateRange
            disabled={disabled}
            endDate={dateRange.endDate}
            isTouch
            onChange={({ startDate, endDate }) => setDateRange({ endDate, startDate })}
            startDate={dateRange.startDate}
          />
          <SlotDropdown
            disabled={disabled}
            onChange={onChange}
            options={selectOpts}
            required={required}
            value={value}
          />
        </>
      ) : (
        <div className="date-picker">
          <DateRange
            disabled={disabled}
            endDate={dateRange.endDate}
            onChange={({ startDate, endDate }) => setDateRange({ endDate, startDate })}
            startDate={dateRange.startDate}
          />
          <SlotDropdown
            disabled={disabled}
            onChange={onChange}
            options={selectOpts}
            required={required}
            value={value}
          />
        </div>
      )}
    </>
  );
}

SlotSelect.propTypes = {
  activeAccountId: PropTypes.string,
  disabled: PropTypes.bool,
  eventTypeId: PropTypes.string,
  isInviteOnly: PropTypes.bool,
  isTouch: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
};

SlotSelect.defaultProps = {
  activeAccountId: null,
  disabled: false,
  eventTypeId: null,
  isInviteOnly: false,
  isTouch: false,
  label: null,
  onChange: () => {},
  required: false,
  value: '',
};

export default SlotSelect;
