import React, { useEffect, useCallback, Fragment, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { useLanguagePack, useMounted, usePageLoading } from '../../../Base/hooks';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { deleteTemplate, emailTemplateSearch } from '../../../../api/CommsAPI/EmailTemplateAPI';
import { useTableState, DataTable } from '../../../Base/Tables';
import { LoadingScreen } from '../../../Base/Loading';
import { ErrorMessage } from '../../../Base/ErrorScreen';
import { DeleteButton, CreateButton } from '../../../Base/Buttons';
import { CommunicationsEditor } from './components';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import Can from '../../../Base/RBAC/Can/Can';
import { ClientAdminSlider } from '../Dashboard';
import EnhancedCard from '../Common/EnhancedCard';
import styled from 'styled-components';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;

const RestrictedCreateButton = Can(CreateButton);
const DeleteRefButton = forwardRef((props, ref) => <DeleteButton {...props} innerRef={ref} />);

async function deleteTemp(tempProps, onSuccess = () => {}, onError = () => {}) {
  const [id, group] = tempProps;

  if (id && group) {
    const resp = await retryableAPICall(() => deleteTemplate(id, group));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function ScheduleCommunication({ tablePrefs }) {
  const isMounted = useMounted();
  const languagePack = useLanguagePack('client-settings');
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteTempProps, setDeleteTempProps] = useState([]);
  const [templateObj, setTemplateObj] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() =>
        emailTemplateSearch(null, [
          { field: 'group', operation: 'EQ', value: ['COMPANY'] },
          {
            field: 'type',
            operation: 'IN',
            value: [
              'EVENT_INVITE',
              'EVENT_CONFIRMATION',
              'EVENT_INVITE_REMINDER',
              'EVENT_BOOKING_REMINDER',
              'EVENT_PHONE_CONFIRMATION',
              'EVENT_VIDEO_CONFIRMATION',
              'EVENT_FACE_TO_FACE_CONFIRMATION',
              'EVENT_UPDATED',
              'EVENT_CANCELLED',
              'EVENT_REMINDER_24',
              'INVITE_WITHDRAWN',
            ],
          },
        ]),
      );

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          // Api doesn't sort so lets do it ourselves by Event Name
          const sortedCommunications = resp.sort((a, b) => a.name?.localeCompare(b.name));

          setTableData({
            data: sortedCommunications,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'Name',
      id: 'templateName',
      minWidth: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name } = original;

        if (checkPermissions(['admin:schedule:update'])) {
          return (
            <a
              href="#editTemplate"
              onClick={(e) => {
                e.preventDefault();
                setTemplateObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Subject',
      id: 'subject',
      accessor: 'subject',
      minWidth: 100,
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: 'type',
      width: 160,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => (
        // eslint-disable-next-line react/prop-types,implicit-arrow-linebreak
        <span>{languagePack[original.type] ? languagePack[original.type] : original.type}</span>
      ),
    },
  ];

  if (checkPermissions(['admin:schedule:delete'])) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, group, deletable } = original;

        return (
          <Tippy
            theme="ats"
            maxWidth="150px"
            // eslint-disable-next-line max-len
            content="This email is required by the system and can\'t be removed, if you wish to change the contents you can edit it"
            disabled={deletable}
          >
            <DeleteRefButton
              label="Delete Template"
              action={() => {
                setDeleteTempProps([id, group]);
                setShowConfirm(true);
              }}
              disabled={!deletable}
              className="btn-sm xs"
              iconOnly
              floatRight={false}
            />
          </Tippy>
        );
      },
    });
  }
  if (pageState.isPending) return <LoadingScreen isEmbeded />;
  if (pageState.isRejected) return <ErrorMessage error={pageState.rejectionStatus} />;

  return (
    <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="mb-0">Communication</h3>
        <RestrictedCreateButton
          permissions={['admin:schedule:create']}
          action={() => {
            setIsEditing(false);
            setTemplateObj({
              typeChange: true,
            });
            setIsSliderOpen(true);
          }}
          label="Create a new Email Template"
        />
      </div>
      <StyledTable
        id="sched-comms"
        className="mt-3"
        isLoading={tableState.isLoading}
        isRejected={tableState.isRejected}
        data={tableState.data}
        columns={columns}
        // totalPages={tableState.totalPages}
        totalResults={tableState.totalResults}
        pageSize={tableState.rowsPerPage}
        noDataText="You currently have no Email Templates"
        errorText="There has been an error loading templates, please try again later"
        hasSelectColumn={false}
        showPagination={false}
      />
      <ClientAdminSlider
        title={`${isEditing ? 'Edit' : 'Create'} Email Template`}
        isSliderOpen={isSliderOpen}
        closeSlider={() => setIsSliderOpen(false)}
      >
        <CommunicationsEditor
          isEditing={isEditing}
          data={templateObj}
          onSave={(tempObj) => {
            setIsSliderOpen(false);

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, tempObj, tempObj.id)
              : addObjToArray(tableState.data, tempObj);

            if (updatedArr.length) {
              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            }
          }}
          onCancel={() => setIsSliderOpen(false)}
        />
      </ClientAdminSlider>
      <Confirmation
        content="Are you sure you want to delete this template?"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteTemp(
            deleteTempProps,
            () => {
              const [delTempId] = deleteTempProps;

              toast.success('Template successfully deleted');

              const updatedArr = deleteObjFromArray(tableState.data, delTempId);

              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            },
            () => {
              toast.error('Error deleting template');
            },
          );
          setShowConfirm(false);
        }}
      />
    </EnhancedCard>
  );
}

ScheduleCommunication.propTypes = {
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

ScheduleCommunication.defaultProps = {
  tablePrefs: {
    pageSize: 25,
  },
};

export default ScheduleCommunication;
