import React from 'react';
import BaseSVG from '../BaseSVG';

function Tag(props) {
  return (
    <BaseSVG
      {...props}
      name="Tag"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M14.353896,3.06833876 C15.0241589,2.87699107 15.7582564,3.10023004 16.2370157,3.57859925 L28.3975002,15.7929598 C29.1954323,16.5583505 29.1954323,17.8021104 28.4294175,18.5675012 L18.53506,28.4537982 C17.7690452,29.1872977 16.5242712,29.1872977 15.7582564,28.4219069 L3.56585458,16.2394377 C3.08709535,15.7610685 2.89559165,15.027569 3.05517806,14.3578521 L4.33186936,5.74720634 C4.4276212,5.39640225 4.58720762,5.10938072 4.84254587,4.85425048 C5.06596685,4.59912023 5.38513967,4.43966383 5.73622978,4.34398998 L14.353896,3.06833876 Z M26.3867114,17.1642848 L14.7049861,5.49207609 L6.66183097,6.6401622 L5.5128088,14.6767649 L17.1626168,26.3489737 L26.3867114,17.1642848 Z M10.5,13 C9.11928813,13 8,11.8807119 8,10.5 C8,9.11928813 9.11928813,8 10.5,8 C11.8807119,8 13,9.11928813 13,10.5 C13,11.8807119 11.8807119,13 10.5,13 Z"
    />
  );
}

export default Tag;
