import {
  ADD_S4_USER,
  REMOVE_S4_USER,
  ADD_S4_GLOBAL_CONFIG,
  ADD_S4_SITE_MAP,
} from '../constants/actionTypes';

const defaultState = {
  connected: false,
  user: { login: '', organisationId: '' },
  accountIdToSiteId: {},
  // advancedSettings: {},
  globalConfig: {},
  globalConfigLoaded: false,
};

export default function s4Reducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_S4_USER:
      return {
        ...state,
        connected: true,
        user: payload.user,
        accountIdToSiteId: payload.accountIdToSiteId,
      };
    case REMOVE_S4_USER:
      return { ...defaultState };
    case ADD_S4_GLOBAL_CONFIG:
      return {
        ...state,
        globalConfig: payload.config,
        globalConfigLoaded: payload.isComplete,
      };
    case ADD_S4_SITE_MAP:
      return {
        ...state,
        accountIdToSiteId: payload,
      };
    default:
      return state;
  }
}
