import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function TagPill({
  children,
  removeTag,
  index,
  showRemove,
  isError,
}) {
  return (
    <div
      data-tag
      className={cx(
        'tag',
        { 'is-error': isError },
      )}
    >
      {children}
      {showRemove && (
        <span
          role="button"
          tabIndex="0"
          data-tag-handle
          onClick={() => removeTag(index)}
          onKeyUp={() => removeTag(index)}
        >
          ×
        </span>
      )}
    </div>
  );
}

TagPill.propTypes = {
  children: PropTypes.string.isRequired,
  removeTag: PropTypes.func,
  index: PropTypes.number.isRequired,
  showRemove: PropTypes.bool,
  isError: PropTypes.bool,
};

TagPill.defaultProps = {
  removeTag: () => { },
  showRemove: true,
  isError: false,
};
