import React, { useState, useRef, useEffect, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import Stage from './FunnelStage';
import DraggableStage from './DraggableStage';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import { useUpdateFunnel } from '../../Base/hooks';
import RejectionModal from './RejectionModal';
import { IconSVG } from '../../Base/SVG';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getRejectionReasons } from '../../../api/CandidateAPI/CandidateAPI';

// https://codesandbox.io/s/mmrp44okvj
// https://codesandbox.io/s/9z7qwmmr7r
function Funnel({
  applicantId,
  applicantFunnelStageId,
  applicantEmail,
  funnelStageReason,
  data,
  onChange,
  onError,
  onSuccess,
  funnelStages,
  isLoadingStages,
  funnelId,
  stageMinWidth,
}) {
  const [currentStageId, setCurrentStageId] = useState(applicantFunnelStageId);
  const [prevStageId, setPrevStageId] = useState(applicantFunnelStageId);
  const [stages, setStages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [scrollLeftPos, setScrollLeftPos] = useState(0);
  const [rejectedStageId, setRejectedStageId] = useState();
  const [funnelStageRsn, setFunnelStageRsn] = useState(funnelStageReason);
  const scrollStageRef = useRef();
  const [stageWidth, setStageWidth] = useState(stageMinWidth);
  const updateFunnel = useUpdateFunnel();
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    const doGetRejectionReasons = async () => {
      try {
        const resp = await retryableAPICall(() => getRejectionReasons());
        if (typeof resp !== 'string') {
          setRejectionReasons(resp);
        } else {
          setRejectionReasons([]);
        }
      } catch (error) {
        console.error('Failed to fetch rejection reasons:', error);
        setRejectionReasons([]);
      }
    };

    doGetRejectionReasons();
  }, [applicantId]);

  useEffect(() => {
    setCurrentStageId(applicantFunnelStageId);
  }, [applicantId, applicantFunnelStageId]);

  const resizeStages = useCallback(() => {
    if (scrollStageRef.current) {
      const { stageId } = data;
      let { leftPos } = data;

      let { width } = scrollStageRef.current.getBoundingClientRect();
      width = Math.round(width);
      let singleStageWidth = Math.round(width / stages.length);
      // insures there is a min width for stages
      singleStageWidth = singleStageWidth > stageMinWidth ? singleStageWidth : stageMinWidth;
      if (singleStageWidth > stageMinWidth) setStageWidth(singleStageWidth);

      const requiredWidth = singleStageWidth * stages.length;
      const stageIdx = stages.findIndex(({ id }) => id === (stageId || applicantFunnelStageId));

      // if scroll required
      if (stageIdx && width < requiredWidth) leftPos = singleStageWidth * stageIdx;

      scrollStageRef.current.scrollLeft = leftPos;
    }
  }, [applicantFunnelStageId, data, stageMinWidth, stages]);

  useEffect(() => {
    if (funnelStages.length) {
      const mappedStages = funnelStages.reduce((acc, stage) => {
        const { stageId, name, order, type } = stage;
        if (type === 'REJECTED') {
          setRejectedStageId(stageId);
        } else {
          // keep order dictated in object
          acc.splice(order - 1, 0, {
            id: stageId,
            label: name,
          });
        }

        return acc;
      }, []);

      setStages(mappedStages);
    }
  }, [applicantFunnelStageId, funnelStages]);

  useEffect(() => {
    if (data.stageId) setCurrentStageId(data.stageId);
  }, [data.stageId]);

  useEffect(() => {
    resizeStages();
    window.addEventListener('resize', resizeStages);
    return () => {
      window.removeEventListener('resize', resizeStages);
    };
  }, [resizeStages]);

  async function updateStage(stageId, scrollLeft, stageReason, email, callback = () => {}) {
    updateFunnel(
      {
        applicantIds: [applicantId],
        funnelId,
        stageId,
        stageReason,
        email,
      },
      () => {
        const isRejected = stageId === rejectedStageId;
        let stageLabel = 'Rejected';

        if (!isRejected) {
          const stageObj = stages.find(({ id }) => id === stageId);
          stageLabel = stageObj.label;
        } else {
          setFunnelStageRsn(stageReason);
        }

        onSuccess('Funnel updated successfully');
        callback();
        onChange(stageId, scrollLeft, stageLabel, stageReason);
      },
      () => {
        onError('There was an error updating Funnel');
        setCurrentStageId(prevStageId);
        onChange(prevStageId, scrollLeft);
      },
    );
  }

  function handleStageIdChange(stageId) {
    if (!checkPermissions(['candidate:funnel:update'])) return;

    let scrollLeft = 0;
    if (scrollStageRef.current) scrollLeft = scrollStageRef.current.scrollLeft;

    setCurrentStageId(stageId);
    setScrollLeftPos(scrollLeft);

    // if rejected stage
    if (stageId === rejectedStageId) {
      setShowConfirm(true);
      // bail here let the confirm update parent and server
      return;
    }

    updateStage(stageId, scrollLeft);
  }

  function onDragStart() {
    setIsDragging(true);
    // store previous so if rejection cancelled funnel reset
    setPrevStageId(currentStageId);
  }

  function onDragEnd(droppedObj) {
    setIsDragging(false);

    const { destination, source } = droppedObj;
    if (!destination || destination.droppableId === source.droppableId) return;
    handleStageIdChange(destination.droppableId);
  }

  return (
    <div>
      <div className="funnel-stages">
        {isLoadingStages && <p>Loading stages...</p>}
        {!isLoadingStages && (
          <Fragment>
            {stages.length ? (
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Stage
                  id={rejectedStageId}
                  className="static-stage"
                  label={<IconSVG name="Thumb Down Solid" />}
                  title="Rejected"
                  isCurrent={!isDragging && currentStageId === rejectedStageId}
                  onDoubleClick={handleStageIdChange}
                >
                  {currentStageId === rejectedStageId && <DraggableStage label="Rejected" />}
                </Stage>
                <div className="scroll-stages" ref={scrollStageRef}>
                  <div className="stages-wrapper">
                    {stages.map((stage, i) => {
                      const workingIdx = i + 1;
                      const isCurrent = stage.id === currentStageId;

                      return (
                        <Stage
                          key={stage.id}
                          {...stage}
                          index={workingIdx}
                          total={stages.length}
                          isCurrent={!isDragging && isCurrent}
                          onDoubleClick={handleStageIdChange}
                          stageWidth={stageWidth}
                        >
                          {isCurrent && (
                            <DraggableStage
                              label={stage.label}
                              index={workingIdx}
                              total={stages.length}
                              stageWidth={stageWidth}
                            />
                          )}
                        </Stage>
                      );
                    })}
                  </div>
                </div>
              </DragDropContext>
            ) : (
              <p className="text-danger">Error loading stages</p>
            )}
          </Fragment>
        )}
      </div>
      {funnelStageRsn && currentStageId === rejectedStageId && (
        <p className="mt-1 text-danger">
          <IconSVG name="Pencil" />
          <span
            className="ms-1"
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
          >{`Rejected Reason: ${funnelStageRsn}`}</span>
        </p>
      )}
      <RejectionModal
        isOpen={showConfirm}
        onClose={() => {
          setCurrentStageId(prevStageId);
          setShowConfirm(false);
        }}
        applicants={applicantEmail}
        rejectionReasons={rejectionReasons}
        onOkay={(rejection, emailData, successCb) => {
          setCurrentStageId(rejectedStageId);

          updateStage(rejectedStageId, scrollLeftPos, rejection, emailData, () => {
            setShowConfirm(false);
            successCb();
          });
        }}
      />
    </div>
  );
}

Funnel.propTypes = {
  applicantId: PropTypes.string.isRequired,
  applicantFunnelStageId: PropTypes.string,
  funnelStageReason: PropTypes.string,
  applicantEmail: PropTypes.arrayOf(
    PropTypes.shape({
      applicantId: PropTypes.string,
      applicantEmail: PropTypes.string,
      applicantName: PropTypes.string,
    }),
  ),
  data: PropTypes.shape({
    stageId: PropTypes.string,
    leftPos: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  funnelStages: PropTypes.arrayOf(
    PropTypes.shape({
      stageId: PropTypes.string,
      name: PropTypes.string,
      order: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  isLoadingStages: PropTypes.bool,
  funnelId: PropTypes.string,
  stageMinWidth: PropTypes.number,
};

Funnel.defaultProps = {
  applicantFunnelStageId: 'DEFAULT',
  funnelStageReason: null,
  applicantEmail: [],
  data: {
    leftPos: 0,
  },
  onChange: () => {},
  onError: () => {},
  onSuccess: () => {},
  funnelStages: [],
  isLoadingStages: true,
  funnelId: 'DEFAULT',
  stageMinWidth: 120,
};

export default Funnel;
