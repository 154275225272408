/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, Fragment, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, FormGroup, Label, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { CancelButton, CreateButton } from '../../../../Base/Buttons';
import Required from '../../../../Base/Forms/Custom/Required';
import { SelectInput } from '../../../../Base/Forms/Custom/CommonComponents';
import AsyncSelectInput from './AsyncSelectInput';
import { ModalPopup } from '../../../../Base/Modal';
import DescriptionEditor from './DescriptionEditor';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { getDescription } from '../../../../../api/SchedulingAPI/DescriptionsAPI';
import validation, { mapErrors } from '../../../../../js/utils/validation';
import ErrorFeedback from '../../../../Base/ErrorFeedback/ErrorFeedback';
import { updateEventType, createEventType } from '../../../../../api/SchedulingAPI/EventTypeAPI';
import { useFormKeypress } from '../../../../Base/hooks';
import CommunicationsEditor from './CommunicationsEditor';
import { AccountFormSelect } from '../../../../Base/Account';

export const EVENT_FORMATS = {
  VIDEO: 'Video',
  PHONE: 'Phone',
  IN_PERSON: 'Face to Face',
};

async function getDesc(id, onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getDescription(id));
  if (typeof resp === 'string') {
    toast.error('Error fetching description');
  } else {
    onSuccess(resp.description);
  }
}

async function proxyCall(data, id) {
  if (id) return updateEventType({ ...data, id });
  return createEventType(data);
}

const defaultModalState = {
  modalTitle: null,
  isOpen: false,
  children: null,
  name: null,
};

const defaultNewValsState = {
  values: {
    desc: '',
    invite: '',
    conf: '',
  },
  opts: {
    desc: [],
    invite: [],
    conf: [],
  },
};

const HIDE_MODAL = 'HIDE_MODAL';
const SHOW_MODAL = 'SHOW_MODAL';
const SET_NEW_VALS = 'SET_NEW_VALS';
const SET_NEW_OPTS = 'SET_NEW_OPTS';
const SET_NEW_DESC = 'SET_NEW_DESC';
const SET_NEW_INVITE = 'SET_NEW_INVITE';
const SET_NEW_CONF = 'SET_NEW_CONF';

function modalReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case HIDE_MODAL:
      return { ...defaultModalState, name: state.name };
    case SHOW_MODAL:
      return { ...payload, isOpen: true };
    default:
      return state;
  }
}

function newValsReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NEW_VALS:
      return { ...state, values: { ...state.values, ...payload } };
    case SET_NEW_OPTS:
      return { ...state, opts: { ...state.opts, ...payload } };
    case SET_NEW_DESC:
      return {
        ...state,
        opts: { ...state.opts, desc: payload.opts },
        values: { ...state.values, desc: payload.value },
      };
    case SET_NEW_INVITE:
      return {
        ...state,
        opts: { ...state.opts, invite: payload.opts },
        values: { ...state.values, invite: payload.value },
      };
    case SET_NEW_CONF:
      return {
        ...state,
        opts: { ...state.opts, conf: payload.opts },
        values: { ...state.values, conf: payload.value },
      };
    default:
      return state;
  }
}

function EventTypeEditor({ isEditing, data, totalAccounts, activeAccount, onSave, onCancel }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [valObj, setValObj] = useState({});
  const [errors, setErrors] = useState({});
  const [modalState, dispatchModal] = useReducer(modalReducer, defaultModalState);
  const [newValsState, dispatchNewVals] = useReducer(newValsReducer, defaultNewValsState);

  useEffect(() => {
    const {
      id,
      name: typeName,
      description,
      format,
      invitationEmailId,
      confirmationEmailId,
      sharedWith,
      readOnly,
    } = data;

    setValObj({
      id,
      eventTypeName: typeName || '',
      eventTypeFormat: format,
      descriptionStr: description,
      inviteEmailId: invitationEmailId,
      confirmEmailId: confirmationEmailId,
      sharedWith: (sharedWith || []).filter(({ accountId }) => accountId !== activeAccount.id),
      readOnly: readOnly || false,
    });
  }, [activeAccount.id, data]);

  function handleChange(id, val) {
    const updatedVals = { ...valObj, [id]: val };
    setValObj(updatedVals);
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validation(
      [
        { id: 'eventTypeName', required: true },
        { id: 'eventTypeFormat', required: true },
        { id: 'descriptionStr', required: true },
        { id: 'inviteEmailId', required: true },
        { id: 'confirmEmailId', required: true },
      ],
      valObj,
    );
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const {
        id,
        eventTypeName,
        eventTypeFormat,
        descriptionStr,
        inviteEmailId,
        confirmEmailId,
        readOnly,
        sharedWith: share,
      } = valObj;

      const serverData = {
        name: eventTypeName,
        format: eventTypeFormat,
        description: descriptionStr,
        invitationEmailId: inviteEmailId,
        confirmationEmailId: confirmEmailId,
        readOnly,
        share: [...(share || []), { accountId: activeAccount.id, accountName: activeAccount.name }],
      };

      const resp = await retryableAPICall(() => proxyCall(serverData, id));

      if (typeof resp === 'string') {
        toast.error(`Error ${isEditing ? 'updating' : 'creating'} event type`);
      } else {
        toast.success(`Event Type successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Event Type`;

  return (
    <Fragment>
      <Form className="event-type-editor" innerRef={formRef}>
        <FormGroup>
          <Label htmlFor="eventTypeName">
            Event Type Name
            <Required />
          </Label>
          <Input
            type="text"
            id="eventTypeName"
            value={valObj.eventTypeName || ''}
            onChange={(e) => handleChange('eventTypeName', e.target.value)}
          />
          <ErrorFeedback message={errors.eventTypeName} />
        </FormGroup>
        {totalAccounts > 1 && (
          <Fragment>
            <FormGroup>
              <Label check>
                <Input
                  type="checkbox"
                  className="standard-checkbox small position-relative ms-0"
                  checked={!valObj.readOnly}
                  onChange={(e) => handleChange('readOnly', !e.target.checked)}
                />
                <span>Editable</span>
              </Label>
            </FormGroup>
            <FormGroup>
              <AccountFormSelect
                id="sharedWith"
                label="Shared With"
                className="account-opts"
                currentAccounts={(valObj.sharedWith || []).map(({ accountId, accountName }) => ({
                  id: accountId,
                  name: accountName,
                }))}
                onChange={(accountObjs = []) => {
                  const accounts = accountObjs.map(({ value, label }) => ({ accountId: value, accountName: label }));
                  handleChange('sharedWith', accounts);
                }}
                isMulti
                ignoreActiveAccount
              />
            </FormGroup>
          </Fragment>
        )}
        <FormGroup>
          <Label htmlFor="eventTypeFormat">
            Format
            <Required />
          </Label>
          <SelectInput
            value={valObj.eventTypeFormat}
            onChange={(val) => handleChange('eventTypeFormat', val)}
            options={Object.entries(EVENT_FORMATS).map(([key, val]) => ({ value: key, label: val }))}
          />
          <ErrorFeedback message={errors.eventTypeFormat} />
        </FormGroup>
        <AsyncSelectInput
          label="Event Description"
          id="eventTypeDescription"
          apiName="descriptions"
          value={newValsState.values.desc}
          onChange={(val) => {
            dispatchNewVals({ type: SET_NEW_VALS, payload: { desc: val } });
            getDesc(val, (str) => handleChange('descriptionStr', str));
          }}
          updatedOptions={newValsState.opts.desc}
          required
        >
          <Fragment>
            {valObj.descriptionStr && (
              <div className="description-preview">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: valObj.descriptionStr }}
                  className="mt-3 px-2 pb-2 pt-3 border rounded-sm"
                />
              </div>
            )}
            <ErrorFeedback message={errors.descriptionStr} />
            <Button
              color="link"
              size="sm"
              className="px-0"
              onClick={() => {
                dispatchModal({
                  type: SHOW_MODAL,
                  payload: {
                    title: 'Create Description',
                    name: 'description',
                  },
                });
              }}
            >
              Create new Description
            </Button>
          </Fragment>
        </AsyncSelectInput>
        <AsyncSelectInput
          label="Invitation Email"
          id="inviteEmailId"
          apiName="EVENT_INVITE"
          value={valObj.inviteEmailId}
          onChange={(val) => {
            dispatchNewVals({ type: SET_NEW_VALS, payload: { invite: val } });
            handleChange('inviteEmailId', val);
          }}
          updatedOptions={newValsState.opts.invite}
          required
        >
          <Fragment>
            <ErrorFeedback message={errors.inviteEmailId} />
            <Button
              color="link"
              size="sm"
              className="px-0"
              onClick={() => {
                dispatchModal({
                  type: SHOW_MODAL,
                  payload: {
                    title: 'Create Invitation Email',
                    name: 'inviteEmail',
                  },
                });
              }}
            >
              Create new Invitation Email
            </Button>
          </Fragment>
        </AsyncSelectInput>
        <AsyncSelectInput
          label="Confirmation Email"
          id="confirmEmailId"
          apiName="EVENT_CONFIRMATION"
          value={valObj.confirmEmailId}
          onChange={(val) => {
            dispatchNewVals({ type: SET_NEW_VALS, payload: { conf: val } });
            handleChange('confirmEmailId', val);
          }}
          updatedOptions={newValsState.opts.conf}
          required
        >
          <ErrorFeedback message={errors.confirmEmailId} />
          <Button
            color="link"
            size="sm"
            className="px-0"
            onClick={() => {
              dispatchModal({
                type: SHOW_MODAL,
                payload: {
                  title: 'Create Confirmation Email',
                  name: 'confEmail',
                },
              });
            }}
          >
            Create new Confirmation Email
          </Button>
        </AsyncSelectInput>
        <CreateButton
          action={(e) => {
            e.preventDefault();
            handleSave();
          }}
          isLoading={isSaving}
          disabled={isSaving}
          label={saveBtnText}
        />
        <CancelButton label="Cancel" disabled={isSaving} action={onCancel} />
      </Form>
      <ModalPopup
        title={modalState.title}
        isOpen={modalState.isOpen}
        onToggle={() => dispatchModal({ type: HIDE_MODAL })}
        hideCancelButton
        hideOkayButton
        maxHeight="80vh"
      >
        {modalState.name === 'description' && (
          <DescriptionEditor
            onCancel={() => dispatchModal({ type: HIDE_MODAL })}
            onSave={({ id: descId, name, description }) => {
              dispatchNewVals({
                type: SET_NEW_DESC,
                payload: {
                  opts: [...newValsState.opts.desc, { value: descId, label: name }],
                  value: descId,
                },
              });
              handleChange('descriptionStr', description);
              dispatchModal({ type: HIDE_MODAL });
            }}
          />
        )}
        {modalState.name === 'inviteEmail' && (
          <CommunicationsEditor
            strictType="EVENT_INVITE"
            onCancel={() => dispatchModal({ type: HIDE_MODAL })}
            onSave={({ id: emlId, name }) => {
              dispatchNewVals({
                type: SET_NEW_INVITE,
                payload: {
                  opts: [...newValsState.opts.invite, { value: emlId, label: name }],
                  value: emlId,
                },
              });
              handleChange('inviteEmailId', emlId);
              dispatchModal({ type: HIDE_MODAL });
            }}
          />
        )}
        {modalState.name === 'confEmail' && (
          <CommunicationsEditor
            strictType="EVENT_CONFIRMATION"
            onCancel={() => dispatchModal({ type: HIDE_MODAL })}
            onSave={({ id: emlId, name }) => {
              dispatchNewVals({
                type: SET_NEW_CONF,
                payload: {
                  opts: [...newValsState.opts.conf, { value: emlId, label: name }],
                  value: emlId,
                },
              });
              handleChange('confirmEmailId', emlId);
              dispatchModal({ type: HIDE_MODAL });
            }}
          />
        )}
      </ModalPopup>
    </Fragment>
  );
}

EventTypeEditor.propTypes = {
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  totalAccounts: PropTypes.number,
};

EventTypeEditor.defaultProps = {
  data: {},
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
  activeAccount: {},
  totalAccounts: 0,
};

export default EventTypeEditor;
