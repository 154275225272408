import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { candidateViews } from '../../../../js/constants/viewNames';

function TableOffsetResults({ tableState, toDividerString, ofDividerString, candidateView, resultTypeString }) {
  const { totalResults, start, limit } = tableState;
  const page = start / limit + 1;
  let end = totalResults;

  if (totalResults === 0) return null;

  if (limit < totalResults) {
    end = limit * page;
    if (end > totalResults) end = totalResults;
  }

  const offsetResultsText = `${start + 1} ${toDividerString} ${end} ${ofDividerString} `;

  return (
    <div className="table-offset-results">
      {candidateView === candidateViews.TABLE && offsetResultsText}
      <span className="font-weight-bold">{pluralize(resultTypeString, totalResults, true)}</span>
    </div>
  );
}

TableOffsetResults.propTypes = {
  candidateView: PropTypes.string,
  ofDividerString: PropTypes.string,
  resultTypeString: PropTypes.string,
  tableState: PropTypes.shape({
    limit: PropTypes.number,
    start: PropTypes.number,
    totalResults: PropTypes.number,
  }),
  toDividerString: PropTypes.string,
};

TableOffsetResults.defaultProps = {
  candidateView: candidateViews.TABLE,
  ofDividerString: 'of',
  resultTypeString: 'Rows',
  tableState: {
    limit: 0,
    start: 0,
    totalResults: 0,
  },
  toDividerString: 'to',
};

export default TableOffsetResults;
