import React from 'react';
import BaseSVG from '../BaseSVG';

function Bin(props) {
  return (
    <BaseSVG
      {...props}
      name="Bin"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M18.1931464,23.9750779 L19.3894081,23.9750779 C19.7197466,23.9750779 19.9875389,23.7072856 19.9875389,23.376947 L19.9875389,12.6105919 C19.9875389,12.2802534 19.7197466,12.0124611 19.3894081,12.0124611 L18.1931464,12.0124611 C17.8628079,12.0124611 17.5950156,12.2802534 17.5950156,12.6105919 L17.5950156,23.376947 C17.5950156,23.7072856 17.8628079,23.9750779 18.1931464,23.9750779 Z M26.3676012,7.22741433 L22.2599377,7.22741433 L20.5652336,4.40124611 C20.1326113,3.68037557 19.3533716,3.23947502 18.512648,3.23987539 L13.487352,3.23987539 C12.6469837,3.23982528 11.8682028,3.68068037 11.4357632,4.40124611 L9.74006231,7.22741433 L5.63239875,7.22741433 C5.19194736,7.22741433 4.83489097,7.58447073 4.83489097,8.02492212 L4.83489097,8.82242991 C4.83489097,9.2628813 5.19194736,9.61993769 5.63239875,9.61993769 L6.42990654,9.61993769 L6.42990654,26.3676012 C6.42990654,27.6889554 7.50107574,28.7601246 8.82242991,28.7601246 L23.1775701,28.7601246 C24.4989243,28.7601246 25.5700935,27.6889554 25.5700935,26.3676012 L25.5700935,9.61993769 L26.3676012,9.61993769 C26.8080526,9.61993769 27.165109,9.2628813 27.165109,8.82242991 L27.165109,8.02492212 C27.165109,7.58447073 26.8080526,7.22741433 26.3676012,7.22741433 Z M13.4001246,5.77744548 C13.4543474,5.68719366 13.5520336,5.63210316 13.6573209,5.63239875 L18.3426791,5.63239875 C18.4477888,5.63227864 18.5452455,5.68734637 18.5993769,5.77744548 L19.4696573,7.22741433 L12.5303427,7.22741433 L13.4001246,5.77744548 Z M23.1775701,26.3676012 L8.82242991,26.3676012 L8.82242991,9.61993769 L23.1775701,9.61993769 L23.1775701,26.3676012 Z M12.6105919,23.9750779 L13.8068536,23.9750779 C14.1371921,23.9750779 14.4049844,23.7072856 14.4049844,23.376947 L14.4049844,12.6105919 C14.4049844,12.2802534 14.1371921,12.0124611 13.8068536,12.0124611 L12.6105919,12.0124611 C12.2802534,12.0124611 12.0124611,12.2802534 12.0124611,12.6105919 L12.0124611,23.376947 C12.0124611,23.7072856 12.2802534,23.9750779 12.6105919,23.9750779 Z"
    />
  );
}

export default Bin;
