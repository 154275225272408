import React, { useState, useRef } from 'react';
import { NavbarToggler, Navbar } from 'reactstrap';
import cx from 'classnames';
import NavList from './NavList';
import { useOutsideClick } from '../../views/Base/hooks';

function MobileNav() {
  const navRef = useRef();
  const [collapsed, setCollapsed] = useState(true);
  useOutsideClick(navRef, () => setCollapsed(true));

  return (
    <Navbar className="mobile-nav navbar-light" ref={navRef}>
      <NavbarToggler onClick={() => setCollapsed(!collapsed)} />
      <NavList className={cx('vertical-nav', { collapsed })} onClick={() => setCollapsed(true)} />
    </Navbar>
  );
}

export default MobileNav;
