import { combineReducers } from 'redux';
import publishingReducer from './publishingReducer';
import userDataReducer from './userDataReducer';
import vacancyReducer from './vacancyReducer';
import filterReducer from './filterReducer';
import applicationFormReducer from './applicationFormReducer';
import { USER_RESET_STATE } from '../constants/actionTypes';
import companyDataReducer from './companyDataReducer';
import jobSiteConfigReducer from './jobSiteConfigReducer';
import atsFormReducer from './atsFormReducer';
import tablePrefsReducer from './tablePrefsReducer';
import reportingFilterReducer from './reportingFilterReducer';
import reportingDataReducer from './reportingDataReducer';
import styleReducer from './styleReducer';
import bookingReducer from './bookingReducer';
import linkedSelectFormReducer from './linkedSelectFormReducer';
import accountReducer from './accountReducer';
import eventNotificationReducer from './eventNotificationReducer';
import fourthReducer from './fourthReducer';
import tagReducer from './tagReducer';
import transcriptionRequestReducer from './transcriptionRequestReducer';
import s4Reducer from './s4Reducer';
import polarisReducer from './polarisReducer';
import sameSystemReducer from './sameSystemReducer';
import categoryReducer from './categoryReducer';
import funnelStagesReducer from './funnelStagesReducer';
import rotaReadyReducer from './rotaReadyReducer';
import searchReducer from './searchReducer';

const appReducer = combineReducers({
  account: accountReducer,
  applicationForm: applicationFormReducer,
  bookings: bookingReducer,
  categoryReducer,
  companyData: companyDataReducer,
  eventNotificationReducer,
  filters: filterReducer,
  formData: atsFormReducer,
  fourth: fourthReducer,
  funnelStages: funnelStagesReducer,
  jobSiteConfig: jobSiteConfigReducer,
  linkedSelectForm: linkedSelectFormReducer,
  polaris: polarisReducer,
  publishingReducer,
  reportingData: reportingDataReducer,
  reportingFilters: reportingFilterReducer,
  rotaReady: rotaReadyReducer,
  s4: s4Reducer,
  sameSystem: sameSystemReducer,
  search: searchReducer,
  styles: styleReducer,
  tablePrefs: tablePrefsReducer,
  tags: tagReducer,
  transcriptionRequests: transcriptionRequestReducer,
  userData: userDataReducer,
  vacancy: vacancyReducer,
});

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === USER_RESET_STATE) newState = undefined;
  return appReducer(newState, action);
};

export default rootReducer;
