import {
  ADD_S4_USER,
  REMOVE_S4_USER,
  ADD_S4_GLOBAL_CONFIG,
  ADD_S4_SITE_MAP,
} from '../constants/actionTypes';

export function addS4User(login, organisationId, accountIdToSiteId) {
  return {
    type: ADD_S4_USER,
    payload: {
      user: { login, organisationId },
      accountIdToSiteId,
    },
  };
}

export function removeS4User() {
  return { type: REMOVE_S4_USER };
}

export function addGlobalConfig(config, isComplete) {
  return {
    type: ADD_S4_GLOBAL_CONFIG,
    payload: { config, isComplete },
  };
}

export function addSiteMap(accountIdToSiteId) {
  return {
    type: ADD_S4_SITE_MAP,
    payload: accountIdToSiteId,
  };
}
