import React from 'react';
import BaseSVG from '../BaseSVG';

function Industry(props) {
  return (
    <BaseSVG
      {...props}
      name="Industry"
      viewBox="0 0 1792 1792"
      // eslint-disable-next-line max-len
      d="M448 0q26 0 45 19t19 45v891l536-429q17-14 40-14 26 0 45 19t19 45v379l536-429q17-14 40-14 26 0 45 19t19 45v1152q0 26-19 45t-45 19h-1664q-26 0-45-19t-19-45v-1664q0-26 19-45t45-19h384z"
    />
  );
}

export default Industry;
