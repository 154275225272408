export default {
  en: {
    buttonSending: 'Sending...',
    buttonStatic: 'Send SMS',
    sendError: 'There was an error sending this SMS',
    sendSuccess: 'SMS sent successfully',
    findError: 'Error finding applicants',
    smsUserError: 'There was an error sending an SMS to:',
    phoneNumbersNotFound: 'Applicant does not have a telephone number',
    applicantsSelected: 'Applicants selected',
    doNotHaveAPhoneNumber: 'do not have a phone number',
    view: 'View',
    hide: 'Hide',
    applicants: 'Applicants',
  },
};
