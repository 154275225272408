/* eslint-disable jsx-a11y/label-has-for */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, FormGroup as CheckboxFormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { createWorkflow, updateWorkflow } from '../../../../api/WorkflowAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { trimFormData } from '../../../../js/utils/general-utils';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';
import {
  triggerTypes,
  eventStates,
  onboardingStates,
  referenceStates,
  rtwResultTypes,
  candidateDataFilter,
  ptTestStates,
  payrollStatusStates,
} from './options-schemas';
import { AccountFormSelect } from '../../../Base/Account';
import FunnelSelectInputs from '../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import { useFormKeypress } from '../../../Base/hooks';
import validateWorkflow from './validateWorkflow';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import WorkflowActions from './WorkflowActions';
import { defaultAction } from './WorkflowAction';
import WorkflowSelect from './WorkflowSelect';
import EventTypeSelect from './EventTypeSelect';

async function proxyCall(data, id) {
  if (id) return updateWorkflow({ ...data, id });
  return createWorkflow(data);
}

const defaultFilters = {
  status: '',
  funnelId: '',
  stageId: '',
  eventTypeId: '',
};

const defaultClientData = {
  name: '',
  type: '',
  filters: { ...defaultFilters },
  actions: [{ ...defaultAction, order: 0 }],
};

function WorkflowEditor({ isEditing, data, onSave, onCancel, activeAccountId, activeAccountName, totalAccounts }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({ ...defaultClientData });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { sharedWith, readOnly, ...rest } = data;

    setClientData((prevState) => ({
      ...prevState,
      ...rest,
      sharedWith: sharedWith || [],
      readOnly: readOnly || false,
    }));
  }, [activeAccountId, data]);

  function handleChange(id, value) {
    let updated = { ...clientData, [id]: value };

    if (id === 'type') {
      updated = {
        ...updated,
        filters: { ...defaultFilters },
        actions: [...defaultClientData.actions],
      };
    }

    setClientData(updated);
  }

  async function handleSave() {
    setIsSaving(true);

    const trimmedData = trimFormData(clientData);
    setClientData(trimmedData);
    const { messages, hasErrors } = validateWorkflow(trimmedData);
    setErrors(messages);

    if (!hasErrors) {
      const { sharedWith, actions, ...rest } = trimmedData;
      sharedWith.forEach((data) => delete data.readOnly);
      if (sharedWith.length === 0) {
        sharedWith.push({ accountId: activeAccountId, accountName: activeAccountName });
      }
      const serverData = {
        ...rest,
        accountId: isEditing && data.accountId ? data.accountId : activeAccountId,
        sharedWith: [...(sharedWith || [])],
        actions: actions.map((action) => {
          const { funnelStageConfig, emailTemplateConfig, ...actionProps } = action;

          return {
            ...actionProps,
            ...(actionProps.type === 'UPDATE_FUNNEL_STAGE' ? { funnelStageConfig } : { emailTemplateConfig }),
          };
        }),
      };

      const resp = await retryableAPICall(() => proxyCall(serverData, data.id));

      if (typeof resp === 'string') {
        let reasonStr = '';
        if (isEditing) {
          if (resp === requestStatuses.NOT_FOUND_ERROR) {
            reasonStr = '. Could not update workflow';
          } else if (resp === requestStatuses.PERMISSION_DENIED) {
            reasonStr = '. You do not have permission to edit this workflow';
          }
        }

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          reasonStr = '. Unable to create workflow as this name has already been used';
        }

        toast.error(`Error ${isEditing ? 'updating' : 'creating'} workflow${reasonStr}`);
      } else {
        toast.success(`Workflow successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Workflow`;

  let statusOptions = [];
  let dropDownLabel;

  if (clientData.type === 'EVENT_STATUS_CHANGE') statusOptions = [...eventStates];
  if (
    clientData.type === 'ONBOARDING_STATUS_CHANGE' ||
    clientData.type === 'REFEREE_STATUS_CHANGE' ||
    clientData.type === 'EDOC_STATUS_CHANGE' ||
    clientData.type === 'GENERIC_FORM_STATUS_CHANGE' ||
    clientData.type === 'RTW_STATUS_CHANGE'
  )
    statusOptions = [...onboardingStates];
  if (clientData.type === 'REFERENCE_STATUS_CHANGE') statusOptions = [...referenceStates];
  if (clientData.type === 'CREATE_CANDIDATE_EVENT') {
    statusOptions = [...candidateDataFilter];
    dropDownLabel = 'Candidate Filter Type';
  }
  if (clientData.type === 'PERSONALITY_TEST_STATUS_CHANGE') {
    statusOptions = [...ptTestStates];
  }
  if (clientData.type === 'PAYROLL_INTEGRATION_SUBMISSION') {
    statusOptions = [...payrollStatusStates];
  }

  let outcomeOptions = [];

  if (clientData.type === 'RTW_STATUS_CHANGE' && clientData.filters?.status.includes('RECEIVED')) {
    outcomeOptions = rtwResultTypes;
  }

  if (!isEditing && clientData.sharedWith && !clientData.sharedWith.find((obj) => obj.accountId === activeAccountId)) {
    clientData.sharedWith.push({ accountId: activeAccountId, accountName: activeAccountName, readOnly: true });
  }

  return (
    <Form
      className="workflow-editor"
      innerRef={formRef}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup error={errors.name} id="name" label="Workflow Name" required>
        <Input
          id="name"
          onChange={(e) => handleChange('name', e.target.value)}
          type="text"
          value={clientData.name || ''}
        />
      </FormGroup>
      {totalAccounts > 1 && (
        <>
          <CheckboxFormGroup>
            <Label check>
              <Input
                checked={!clientData.readOnly}
                className="standard-checkbox small position-relative ms-0"
                onChange={(e) => handleChange('readOnly', !e.target.checked)}
                type="checkbox"
              />
              <span>Editable</span>
            </Label>
          </CheckboxFormGroup>
          <AccountFormSelect
            className="account-opts"
            currentAccounts={(clientData.sharedWith || []).map(({ accountId, accountName, readOnly }) => ({
              id: accountId,
              name: accountName,
              readOnly: accountId === data.accountId || readOnly,
            }))}
            id="sharedWith"
            isMulti
            label="Shared With"
            onChange={(accountObjs = []) => {
              const accounts = accountObjs.map(({ value, label, readOnly }) => ({
                accountId: value,
                accountName: label,
                readOnly,
              }));
              handleChange('sharedWith', accounts);
            }}
          />
        </>
      )}
      <WorkflowSelect
        error={errors.type}
        id="type"
        label="Trigger"
        onChange={(val) => handleChange('type', val)}
        options={triggerTypes}
        placeholder="Choose Trigger"
        required
        value={clientData.type}
      />
      {clientData.type === 'FUNNEL_STATE_CHANGE' && (
        <FunnelSelectInputs
          funnelSelectProps={{
            error: errors?.filters?.funnelId,
            id: 'funnelId',
            label: 'Funnel',
            required: true,
            value: clientData?.filters?.funnelId[0],
          }}
          onChange={(val) => {
            handleChange('filters', {
              funnelId: [val.funnelId],
              stageId: [val.stageId],
            });
          }}
          stageSelectProps={{
            error: errors?.filters?.stageId,
            id: 'stageId',
            label: 'Stage',
            required: true,
            value: clientData?.filters?.stageId[0],
          }}
        />
      )}
      {/* eslint-disable-next-line max-len */}
      {/^(ONBOARDING_STATUS_CHANGE|CREATE_CANDIDATE_EVENT|GENERIC_FORM_STATUS_CHANGE|EVENT_STATUS_CHANGE|REFEREE_STATUS_CHANGE|EDOC_STATUS_CHANGE|REFERENCE_STATUS_CHANGE|RTW_STATUS_CHANGE|PERSONALITY_TEST_STATUS_CHANGE|PAYROLL_INTEGRATION_SUBMISSION)$/.test(
        clientData.type,
      ) && (
        <WorkflowSelect
          error={errors?.filters?.status}
          id="filters"
          label={dropDownLabel || 'Choose Status'}
          onChange={(val) => {
            handleChange('filters', {
              candidateFilterVal: clientData.filters?.candidateFilterVal,
              eventTypeId: clientData?.filters?.eventTypeId ? clientData.filters.eventTypeId : '',
              outcome: clientData?.filters?.outcome,
              status: [val],
            });
          }}
          options={statusOptions}
          placeholder="Choose Status"
          required
          value={clientData?.filters?.status[0]}
        />
      )}
      {/^(RTW_STATUS_CHANGE)$/.test(clientData.type) && outcomeOptions.length > 0 && (
        <WorkflowSelect
          error={errors?.filters?.outcome}
          id="filters"
          label="Outcome"
          onChange={(val) => {
            handleChange('filters', {
              candidateFilterVal: clientData.filters?.candidateFilterVal,
              eventTypeId: clientData?.filters?.eventTypeId ? clientData.filters.eventTypeId : '',
              outcome: [val],
              status: clientData.filters?.status,
            });
          }}
          options={outcomeOptions}
          placeholder="Choose Outcome (If Required)"
          required
          value={clientData?.filters?.outcome ? clientData?.filters?.outcome[0] : ''}
        />
      )}
      {/^(PERSONALITY_TEST_STATUS_CHANGE)$/.test(clientData.type) &&
        clientData.filters.status.includes('PART_RECEIVED') && (
          <>
            <WorkflowSelect
              error={errors?.filters?.operator}
              id="personalityTestScoreOperator"
              label="When score is"
              onChange={(val) => {
                handleChange('filters', {
                  operator: [val],
                  scoreType: clientData.filters?.scoreType,
                  status: clientData.filters?.status,
                });
              }}
              options={[
                { label: 'Any Score', value: '' },
                { label: 'Less than', value: 'LT' },
                { label: 'Equal', value: 'EQ' },
                { label: 'Greater than', value: 'GT' },
              ]}
              placeholder="Choose Outcome (If Required)"
              required
              value={clientData?.filters?.operator ? clientData?.filters?.operator[0] : ''}
            />
            {clientData.filters?.operator && clientData.filters?.operator !== '' && (
              <>
                <FormGroup error={errors.name} id="scoreValue" label="Score Value" required>
                  <Input
                    autoComplete="off"
                    error={errors?.filters?.score}
                    id="newCandidateFilter"
                    label="Score"
                    onChange={(val) => {
                      handleChange('filters', {
                        operator: clientData.filters?.operator,
                        outcome: clientData.filters?.outcome,
                        score: [val.currentTarget.value],
                        scoreType: clientData.filters?.scoreType,
                        status: clientData.filters?.status,
                      });
                    }}
                    placeholder="Score"
                    required
                    style={{ marginBottom: '5px', width: '75px' }}
                    type="number"
                    value={clientData?.filters?.score}
                  />
                </FormGroup>
                <WorkflowSelect
                  error={errors?.filters?.scoreType}
                  id="personalityTestScoreType"
                  label="Score Type"
                  onChange={(val) => {
                    handleChange('filters', {
                      operator: clientData.filters?.operator,
                      outcome: clientData.filters?.outcome,
                      score: clientData.filters?.score,
                      scoreType: [val],
                      status: clientData.filters?.status,
                    });
                  }}
                  options={[{ label: 'Integrity Score', value: 'INTEGRITY_SCORE' }]}
                  placeholder="Choose Test Score"
                  required
                  value={clientData?.filters?.scoreType ? clientData?.filters?.scoreType[0] : ''}
                />
              </>
            )}
          </>
        )}
      {/^(CREATE_CANDIDATE_EVENT)$/.test(clientData.type) &&
        clientData.filters?.status &&
        clientData.filters.status?.some((element) => ['utmSource', 'utmMedium', 'utmCampaign'].includes(element)) && (
          <div style={{ marginBottom: '10px' }}>
            <Label for="newCandidateFilter">Value</Label>
            <Input
              error={errors?.filters?.candidateFilterVal}
              id="newCandidateFilter"
              label="Value"
              onChange={(val) => {
                handleChange('filters', {
                  candidateFilterVal: val.currentTarget.value,
                  eventTypeId: clientData?.filters?.eventTypeId ? clientData.filters.eventTypeId : '',
                  outcome: clientData.filters?.status,
                  status: clientData.filters?.status,
                });
              }}
              placeholder="Set value to filter this workflow on"
              required
              type="text"
              value={clientData?.filters?.candidateFilterVal}
            />
          </div>
        )}
      {/^(CREATE_CANDIDATE_EVENT)$/.test(clientData.type) &&
        clientData.filters?.status &&
        clientData.filters.status?.some((element) => ['ALL'].includes(element)) && (
          <div style={{ marginBottom: '10px' }}>
            <FunnelSelectInputs
              funnelSelectProps={{
                error: errors?.filters?.funnelId,
                id: 'funnelId',
                label: 'Funnel',
                multi: true,
                required: true,
                value: clientData?.filters?.funnelId ? clientData?.filters?.funnelId[0] : '',
              }}
              onChange={(val) =>
                handleChange('filters', {
                  eventTypeId: clientData?.filters?.eventTypeId ? clientData.filters.eventTypeId : '',
                  funnelId: [val.funnelId],
                  outcome: clientData.filters?.status,
                  status: clientData.filters?.status,
                })
              }
              stageSelectProps={{
                hidden: true,
              }}
            />
          </div>
        )}
      {clientData.type === 'EVENT_STATUS_CHANGE' && (
        <EventTypeSelect
          error={errors?.filters?.eventTypeId}
          id="eventTypeId"
          onChange={(val) => {
            handleChange('filters', {
              eventTypeId: val,
              status: clientData?.filters?.status ? clientData.filters.status : '',
            });
          }}
          value={clientData?.filters?.eventTypeId}
        />
      )}
      <WorkflowActions
        data={clientData}
        errors={errors.actions || []}
        maxActions={clientData.type === 'FUNNEL_STATE_CHANGE' ? 5 : 1}
        onChange={(actionsArr) => handleChange('actions', actionsArr)}
      />
      <CreateButton action={handleSave} disabled={isSaving} isLoading={isSaving} label={saveBtnText} />
      <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
    </Form>
  );
}

WorkflowEditor.propTypes = {
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  totalAccounts: PropTypes.number,
};

WorkflowEditor.defaultProps = {
  activeAccountId: undefined,
  activeAccountName: undefined,
  data: {},
  isEditing: false,
  onCancel: () => {},
  onSave: () => {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountId,
        activeAccountName,
        data: { accountAccess = [] },
      },
    },
  } = state;

  return { activeAccountId, activeAccountName, totalAccounts: accountAccess.length };
}

export default connect(mapStateToProps)(WorkflowEditor);
