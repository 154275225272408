import axios from 'axios';
import { getCommonHeaders, getCommonHeadersNoContentType } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { rotaReady },
} = config;

export async function createUser(organisationId, apiKey, secret) {
  if (organisationId && apiKey && secret) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${rotaReady.userPath}`,
      { apiKey, organisationId, secret },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateUser(organisationId, apiKey, secret) {
  if (organisationId && apiKey && secret) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${rotaReady.userPath}`,
      { apiKey, organisationId, secret },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getUser() {
  const result = await axios.get(`${api.middlewareAPIURL}${rotaReady.userPath}`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${rotaReady.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToRotaReady(candidateId, data = {}) {
  if (candidateId) {
    if (Object.keys(data).length) {
      const result = await axios.post(
        `${api.middlewareAPIURL}${rotaReady.submitPath}/${candidateId}`,
        { ...data },
        { headers: getCommonHeaders() },
      );

      if (result.status === 201 || result.status === 200) return result.data;
    }
  }

  return null;
}

export async function uploadFile(file, url) {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        try {
          const result = await axios.put(
            `${api.middlewareAPIURL}${url}`,
            {
              extension: file.name.split('.').pop(), // file extension
              fileContent: reader.result, // entire base64 string including MIME type
              type: file.type, // MIME type
            },
            {
              headers: { ...getCommonHeadersNoContentType(), 'Content-Type': 'application/json' },
            },
          );
          if (result.status === 201 || result.status === 200) {
            resolve(result.data);
          } else {
            reject(new Error('Failed to upload file'));
          }
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = function () {
        reject(new Error('Failed to read file'));
      };
    } else {
      reject(new Error('No file provided'));
    }
  });
}
