import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getCreatedBy } from '../../../../api/VacancyAPI';
import { addAccountUsers } from '../../../../js/actions/accountActions';
import { useMounted } from '../../hooks';

function getOptions(userArr) {
  return userArr.map(({ id, name }) => ({ value: id, label: name }));
}

function UserFilter({ children, users, applyUsers }) {
  const isMounted = useMounted();
  const [optionData, setOptionData] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const allUsers = await retryableAPICall(() => getCreatedBy());

      if (isMounted()) {
        if (Array.isArray(allUsers)) {
          setOptionData(getOptions(allUsers));
          applyUsers(allUsers);
        }
        else {
          toast.error('Error loading users, please try again');
        }
      }
    };

    if (users.length) {
      if (isMounted()) setOptionData(getOptions(users));
    }
    else {
      fetchUsers();
    }
  }, [applyUsers, isMounted, users]);

  return children({ optionData });
}

UserFilter.propTypes = {
  children: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  applyUsers: PropTypes.func,
};

UserFilter.defaultProps = {
  children: () => { },
  users: [],
  applyUsers: () => { },
};

function mapStateToProps(state) {
  const { account: { users } } = state;
  return { users };
}

function mapDispatchToProps(dispatch) {
  return {
    applyUsers: (usersArr) => dispatch(addAccountUsers(usersArr)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter);
