/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { ValueDisplay } from '../../../Base/ValueDisplay';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';

function IntegrationInput({
  label,
  labelClassName,
  id,
  value,
  className,
  colClassName,
  onChange,
  error,
  isAuthorised,
  isEditing,
  type,
  inputProps,
  hideValue,
}) {
  if (type === 'checkbox') {
    return (
      <FormGroup
        className={`${className || ''} d-flex align-items-center`}
        id={id}
        label={label}
        labelProps={{
          className: `${labelClassName || ''} mb-0`,
        }}
      >
        <input
          checked={value}
          disabled={isAuthorised && !isEditing}
          id={id}
          onChange={(e) => onChange(e.target.checked)}
          type="checkbox"
        />
      </FormGroup>
    );
  }

  return (
    <FormGroup
      className={className}
      colProps={{
        className: colClassName,
        sm: !isEditing ? '10' : '4',
      }}
      error={error}
      id={id}
      label={label}
      labelProps={{
        sm: '2',
      }}
      required
      row
    >
      {isAuthorised && !isEditing ? (
        <ValueDisplay value={hideValue ? '************' : value} />
      ) : (
        <>
          {type === 'file' ? (
            <Input id={id} type={type} value={value} onChange={(e) => onChange(e)} spellCheck="false" />
          ) : (
            <Input id={id} onChange={(e) => onChange(e.target.value)} spellCheck="false" type={type} value={value} />
          )}
        </>
      )}
    </FormGroup>
  );
}

IntegrationInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  colClassName: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isAuthorised: PropTypes.bool,
  isEditing: PropTypes.bool,
  type: PropTypes.string,
  inputProps: PropTypes.shape(),
  hideValue: PropTypes.bool,
};

IntegrationInput.defaultProps = {
  labelClassName: null,
  value: '',
  className: null,
  colClassName: null,
  onChange: () => {},
  error: null,
  isAuthorised: false,
  isEditing: false,
  type: 'text',
  inputProps: {},
  hideValue: false,
};

export default IntegrationInput;
