/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const lookUp = {
  regular: {
    viewBox: '0 0 32 32',
    d: 'M24.4373832,7.8847352 L20.2554517,3.70280374 C19.8068536,3.25420561 19.1987539,3 18.5657321,3 L8.39252336,3 C7.07165109,3.00498442 6,4.07663551 6,5.39750779 L6,26.1327103 C6,27.4535826 7.07165109,28.5252336 8.39252336,28.5252336 L22.7476636,28.5252336 C24.0685358,28.5252336 25.1401869,27.4535826 25.1401869,26.1327103 L25.1401869,9.57943925 C25.1401869,8.94641745 24.8859813,8.33333333 24.4373832,7.8847352 Z M22.553271,9.38504673 L18.7601246,9.38504673 L18.7601246,5.59190031 L22.553271,9.38504673 Z M8.39252336,26.1327103 L8.39252336,5.39750779 L16.3676012,5.39750779 L16.3676012,10.5813084 C16.3676012,11.2442368 16.9009346,11.7775701 17.5638629,11.7775701 L22.7476636,11.7775701 L22.7476636,26.1327103 L8.39252336,26.1327103 Z',
  },
  solid: {
    viewBox: '0 0 384 512',
    d: 'M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z',
  },
  formAlt: {
    viewBox: '0 0 384 512',
    d: 'M288 248v28c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm-12 72H108c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12zm108-188.1V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0h204.1C264.8 0 277 5.1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232c-13.3 0-24-10.7-24-24V48H48v416h288z',
  },
  image: {
    viewBox: '0 0 384 512',
    d: 'M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z',
  },
  imageAlt: {
    viewBox: '0 0 32 32',
    d: 'M22,24.0457281 L22,18 L11,18 L11,25 L11,25 L13.5,23 L14.9579388,24.2496618 L18.5930302,21 L22,24.0457281 L22,24.0457281 L22,24.0457281 Z M19.5,3 L9.00276013,3 C7.89666625,3 7,3.89833832 7,5.00732994 L7,27.9926701 C7,29.1012878 7.89092539,30 8.99742191,30 L24.0025781,30 C25.1057238,30 26,29.1017876 26,28.0092049 L26,10.5 L26,10 L20,3 L19.5,3 L19.5,3 L19.5,3 Z M19,4 L8.9955775,4 C8.44573523,4 8,4.45526288 8,4.99545703 L8,28.004543 C8,28.5543187 8.45470893,29 8.9999602,29 L24.0000398,29 C24.5523026,29 25,28.5550537 25,28.0066023 L25,11 L20.9979131,11 C19.8944962,11 19,10.1134452 19,8.99408095 L19,4 L19,4 Z M20,4.5 L20,8.99121523 C20,9.54835167 20.4506511,10 20.9967388,10 L24.6999512,10 L20,4.5 L20,4.5 Z M10,17 L10,27 L23,27 L23,17 L10,17 L10,17 Z M14,21 C14.5522848,21 15,20.5522848 15,20 C15,19.4477152 14.5522848,19 14,19 C13.4477152,19 13,19.4477152 13,20 C13,20.5522848 13.4477152,21 14,21 L14,21 Z',
  },
  pdf: {
    viewBox: '0 0 384 512',
    d: 'M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z',
  },
  pdfAlt: {
    viewBox: '0 0 512 512',
    paths: [
      { d: 'M458,118.2L346.5,8.4C345,6.9,342.9,6,340.7,6H135c-31.1,0-56.3,26.6-56.3,59.4v219.2H51.3v148.6h27.4v13.4 c0,32.7,25.3,59.4,56.3,59.4h269.4c31.1,0,56.3-26.6,56.3-59.4V124.7C460.7,122.3,459.8,119.9,458,118.2z M337.9,24.4l97,95.6 h-52.8c-24.4,0-44.2-19.5-44.2-43.4V24.4z M443.7,446.6c0,22.9-17.6,41.5-39.3,41.5H135c-21.7,0-39.3-18.6-39.3-41.5v-13.4h187 c36.5,0,66-33.3,66-74.3s-29.6-74.3-66-74.3h-187V65.4c0-22.9,17.6-41.5,39.3-41.5h185.9v52.7c0,33.8,27.4,61.3,61.2,61.3h61.6 V446.6z M103.9,396.4v-75h28.7c8.3,0,14.8,2.1,19.4,6.4s7,9.9,7,16.9c0,7-2.3,12.7-7,16.9c-4.7,4.2-11.2,6.4-19.4,6.4h-16.2v28.4 H103.9z M174.2,396.4v-75H199c9.5,0,17.2,3,23.2,8.9c6,5.9,8.9,13.6,8.9,22.9v11.4c0,9.4-3,17-8.9,22.9c-6,5.9-13.7,8.9-23.2,8.9 H174.2z M290.8,354.4v10h-30.2v32H248v-75h48v10h-35.5v22.9H290.8z' },
      { d: 'M143.1,354.2c2.3-2.5,3.5-5.6,3.5-9.4s-1.2-7-3.5-9.5c-2.3-2.5-5.8-3.8-10.5-3.8h-16.2v26.5h16.2 C137.3,357.9,140.7,356.7,143.1,354.2z' },
      { d: 'M199,331.5h-12.3v55H199c6,0,10.8-2,14.3-6.1c3.5-4.1,5.3-9.3,5.3-15.7v-11.5c0-6.3-1.8-11.5-5.3-15.6 C209.8,333.5,205,331.5,199,331.5z' },
    ],
  },
  word: {
    viewBox: '0 0 384 512',
    d: 'M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z',
  },
};

function File(props) {
  const { type, ...rest } = props;

  const config = lookUp[type];

  return (
    <BaseSVG
      {...rest}
      name="File"
      viewBox={config.viewBox}
      d={config.d}
      paths={config.paths}
    />
  );
}

File.propTypes = {
  type: PropTypes.string,
};

File.defaultProps = {
  type: 'regular',
};

export default File;
