export default function addFlexiFormField(schemas = {}) {
  if (!Object.keys(schemas).length) {
    return {
      formSchema: {},
      uiSchema: {},
      rules: [],
    };
  }

  const { formSchema = {}, uiSchema = {}, rules = [] } = schemas;

  const cloneSchema = {
    ...formSchema,
    properties: {
      ...formSchema.properties,
      applicationMethod: {
        ...formSchema.properties.applicationMethod,
        properties: {
          ...formSchema.properties.applicationMethod.properties,
          appFormLink: {
            type: 'object',
            title: 'Create form',
          },
        },
      },
    },
  };

  const cloneUiSchema = {
    ...uiSchema,
    applicationMethod: {
      ...uiSchema.applicationMethod,
      appFormLink: {
        'ui:field': 'applicationFormModal',
        'ui:options': {
          text: 'Create a new Application form',
        },
      },
    },
  };

  const cloneRules = [
    ...rules,
    {
      conditions: {
        'applicationMethod.appMethod': {
          not: {
            equal: 'APPLICATION_FORM',
          },
        },
      },
      event: {
        type: 'remove',
        params: {
          field: 'applicationMethod.appFormLink',
        },
      },
    },
    {
      conditions: {
        'applicationMethod.appFormLink': {
          not: 'empty',
        },
      },
      event: {
        type: 'remove',
        params: {
          field: 'applicationMethod.appFormTemplate',
        },
      },
    },
  ];

  return {
    formSchema: cloneSchema,
    uiSchema: cloneUiSchema,
    rules: cloneRules,
  };
}
