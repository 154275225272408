import { Card, CardBody, Col } from 'reactstrap';
import React, { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { createUser, updateUser, getUser } from '../../../../api/Integrations/RotaReadyAPI';
import { useMounted } from '../../../Base/hooks';
import { Loader } from '../../../Base/Loading';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { CancelButton, CreateButton, EditButton } from '../../../Base/Buttons';
import IntegrationInput from '../Dashboard/IntegrationInput';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';

const initialState = {
  isAuthorised: true,
  isEditing: false,
  isLoading: true,
  isPromptOpen: false,
  isSaving: false,
  originalApiToken: '',
  originalOrgId: '',
  originalSecret: '',
  apiToken: '',
  orgId: '',
  secret: '',
  isExistingUser: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_ORG_ID':
      return { ...state, orgId: action.val };
    case 'CHANGE_SECRET':
      return { ...state, secret: action.val };
    case 'CHANGE_API_TOKEN':
      return { ...state, apiToken: action.val };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_CREDENTIALS':
      return {
        ...state,
        originalApiToken: action.payload.apiKey,
        originalOrgId: action.payload.organisationId,
        originalSecret: action.payload.secret,
        apiToken: action.payload.apiKey,
        orgId: action.payload.organisationId,
        secret: action.payload.secret,
        isExistingUser: action.payload.isExistingUser,
        isEditing: false,
      };
    case 'ADD_USER':
      return {
        ...state,
        originalApiToken: action.payload.apiKey,
        originalOrgId: action.payload.organisationId,
        originalSecret: action.payload.secret,
        apiToken: action.payload.apiKey,
        orgId: action.payload.organisationId,
        secret: action.payload.secret,
        isExistingUser: action.payload.isExistingUser,
        isEditing: false,
      };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false };
    case 'SET_IS_EDITING':
      return { ...state, isEditing: true };
    case 'SET_IS_EDITING_CANCELLED':
      return {
        ...state,
        apiToken: state.originalApiToken,
        orgId: state.originalOrgId,
        secret: state.originalSecret,
        isEditing: false,
      };
    default:
      return state;
  }
};

function RotaReadyAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const resp = await retryableAPICall(() => getUser());

        // The user hasn't been created yet
        if (typeof resp === 'string' && resp === requestStatuses.NOT_FOUND_ERROR) {
          dispatch({ type: 'SET_LOADING_COMPLETE' });
        } else if (typeof resp === 'string' && resp === requestStatuses.PERMISSION_DENIED) {
          toast.error('You do not have the required permissions. Please contact support');
        } else if (typeof resp === 'string') {
          toast.error('Failed to load credentials. Please try again later or contact support');
        } else {
          dispatch({ type: 'SET_CREDENTIALS', payload: { ...resp, isExistingUser: true } });
        }
      } catch (error) {
        toast.error('Failed to load credentials. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const handleSave = async () => {
    dispatch({ type: 'SET_IS_LOADING' });

    try {
      let resp;

      if (state.isExistingUser) {
        resp = await retryableAPICall(() => updateUser(state.orgId, state.apiToken, state.secret));
      } else {
        resp = await retryableAPICall(() => createUser(state.orgId, state.apiToken, state.secret));
      }

      if (isMounted) {
        if (typeof resp === 'string' && resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          toast.error('A user for this account already exists. Please contact support for assistance.');
        } else if (typeof resp === 'string' && resp === requestStatuses.PERMISSION_DENIED) {
          toast.error('You do not have the required permissions. Please contact support');
        } else if (typeof resp === 'string' && resp === requestStatuses.NOT_FOUND_ERROR) {
          toast.error('The user for this tenant could not be found');
        } else if (typeof resp === 'string') {
          toast.error('Failed to add credentials. Please try again later or contact support');
        } else {
          toast.success(`Successfully ${state.isExistingUser ? 'updated' : 'created'} user`);
          dispatch({
            type: 'ADD_USER',
            payload: { ...resp, isExistingUser: true },
          });
        }
      }
    } catch (error) {
      toast.error('Failed to add credentials. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  if (!checkPermissions(['admin:rota'])) return null;

  return (
    <Col className="mt-2">
      {state.isLoading ? (
        <Loader className="mt-5" />
      ) : (
        <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
          <EnhancedCard className="mb-4 mt-2">
            <EnhancedCardTitle
              title="Rota Ready Integration"
              subtitle="Manage your connection details to pass candidates to Rota Ready"
            />

            <IntegrationInput
              id="orgId"
              isAuthorised={state.isAuthorised}
              isEditing={state.isEditing}
              label="Organisation Id"
              onChange={(val) => {
                dispatch({
                  type: 'CHANGE_ORG_ID',
                  val,
                });
              }}
              value={state.orgId || ''}
            />
            <IntegrationInput
              hideValue
              id="apiToken"
              isAuthorised={state.isAuthorised}
              isEditing={state.isEditing}
              label="API Token"
              onChange={(val) => {
                dispatch({
                  type: 'CHANGE_API_TOKEN',
                  val,
                });
              }}
              type="password"
              value={state.apiToken || ''}
            />
            <IntegrationInput
              hideValue
              id="secret"
              isAuthorised={state.isAuthorised}
              isEditing={state.isEditing}
              label="Secret"
              onChange={(val) => {
                dispatch({
                  type: 'CHANGE_SECRET',
                  val,
                });
              }}
              type="password"
              value={state.secret || ''}
            />
            {state.isEditing ? (
              <>
                <CreateButton
                  action={(e) => {
                    e.preventDefault();
                    handleSave();
                  }}
                  className="mt-2"
                  disabled={state.isSaving || !state.orgId || !state.apiToken || !state.secret}
                  floatRight={false}
                  isLoading={state.isSaving}
                  label={state.isSaving ? 'Authenticating...' : 'Save'}
                />
                <CancelButton
                  action={() => {
                    dispatch({
                      type: 'SET_IS_EDITING_CANCELLED',
                    });
                  }}
                  className="mt-2 ms-2"
                  disabled={state.isSaving}
                  floatRight={false}
                  isLoading={state.isSaving}
                />
              </>
            ) : (
              <EditButton
                action={() =>
                  dispatch({
                    type: 'SET_IS_EDITING',
                  })
                }
                className="mt-2"
                floatRight={false}
                label="Edit Credentials"
              />
            )}

          </EnhancedCard>
        </div>
      )}
    </Col>
  );
}

RotaReadyAdmin.propTypes = {};

RotaReadyAdmin.defaultProps = {};

export default RotaReadyAdmin;
