/* eslint-disable max-len */
import React from 'react';
import BaseSVG from '../BaseSVG';

function Money(props) {
  const paths = [
    {
      d: 'M2 14C2 10.2288 2 8.34315 3.17157 7.17157C4.34315 6 6.22876 6 10 6H14C17.7712 6 19.6569 6 20.8284 7.17157C22 8.34315 22 10.2288 22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14Z',
      stroke: '#000000',
      strokeWidth: '1.5',
    },
    {
      d: 'M16 6C16 4.11438 16 3.17157 15.4142 2.58579C14.8284 2 13.8856 2 12 2C10.1144 2 9.17157 2 8.58579 2.58579C8 3.17157 8 4.11438 8 6',
      stroke: '#000000',
      strokeWidth: '1.5',
    },
    {
      d: 'M12 17.3333C13.1046 17.3333 14 16.5871 14 15.6667C14 14.7462 13.1046 14 12 14C10.8954 14 10 13.2538 10 12.3333C10 11.4129 10.8954 10.6667 12 10.6667M12 17.3333C10.8954 17.3333 10 16.5871 10 15.6667M12 17.3333V18M12 10V10.6667M12 10.6667C13.1046 10.6667 14 11.4129 14 12.3333',
      stroke: '#000000',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
    },
  ];
  return <BaseSVG {...props} viewBox="0 0 24 24" name="Money" paths={paths} />;
}

export default Money;
