import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';

function UploadProgress({
  totalFiles,
  attachmentData,
  languagePack,
}) {
  let totalPercentage = 0;
  Object.values(attachmentData.activeUploads).forEach((entry) => {
    totalPercentage += entry.percentage ? entry.percentage : 0;
  });
  totalPercentage = Math.round(totalPercentage / totalFiles);

  let fileProgress;
  const ffLength = Object.keys(attachmentData.filesFailed).length;
  const fsLength = Object.keys(attachmentData.filesSuccess).length;

  if (ffLength + fsLength === totalFiles) {
    fileProgress = totalFiles;
  } else {
    fileProgress = ffLength + fsLength + 1;
  }

  return (
    <div className="text-center">
      <CircularProgressbar value={totalPercentage} text={`${totalPercentage}%`} />
      <p
        style={{ marginTop: '5px' }}
      >
        {languagePack.uploadingFile}
        {' '}
        {fileProgress}
        {' '}
        {languagePack.of}
        {' '}
        {totalFiles}
      </p>
    </div>
  );
}

UploadProgress.propTypes = {
  totalFiles: PropTypes.number,
  attachmentData: PropTypes.shape(),
  languagePack: PropTypes.shape(),
};

UploadProgress.defaultProps = {
  totalFiles: 0,
  attachmentData: {},
  languagePack: {},
};

export default UploadProgress;
