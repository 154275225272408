/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import PortalAware from '../../../../Base/DragAndDrop/PortalAware';
import EditInline from '../../../../Base/Forms/Custom/EditInline/EditInline';
import InlineRadios from './InlineRadios';

export default function FormField({
  title,
  name,
  index,
  isDisabled,
  isDragDisabled,
  isEditInlineDisabled,
  notes,
  radioBtns,
  checkedValue,
  onRadioChange,
  onTitleChange,
}) {
  const [isTextEditing, setIsTextEditing] = useState(false);

  return (
    <Draggable draggableId={name} index={index} isDragDisabled={isDisabled || isDragDisabled}>
      {(provided, snapshot) => (
        <PortalAware
          provided={provided}
          snapshot={snapshot}
          draggableClassName="my-3 flexiform-field"
          portalClassName="flexiform-portal"
        >
          <div className="d-flex">
            <div className="control-label flex-grow-1" style={{ opacity: isDisabled ? 0.5 : 1 }}>
              {isEditInlineDisabled || isDisabled ? (
                title
              ) : (
                <EditInline
                  value={title}
                  onChange={(newVal) => onTitleChange(newVal, name)}
                  onToggle={(isEdit) => setIsTextEditing(isEdit)}
                />
              )}
            </div>
            <InlineRadios
              name={name}
              radioBtns={radioBtns}
              value={checkedValue}
              onChange={(newVal) => onRadioChange(newVal, name)}
              className={cx('me-0', { 'ms-2': isTextEditing })}
              isDisabled={isDisabled}
            />
          </div>
          {notes && <p className="small text-muted">{notes}</p>}
        </PortalAware>
      )}
    </Draggable>
  );
}

FormField.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  isDisabled: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  isEditInlineDisabled: PropTypes.bool,
  notes: PropTypes.string,
  radioBtns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  checkedValue: PropTypes.string,
  onRadioChange: PropTypes.func,
  onTitleChange: PropTypes.func,
};

FormField.defaultProps = {
  index: 0,
  isDisabled: false,
  isDragDisabled: false,
  isEditInlineDisabled: false,
  notes: null,
  radioBtns: [],
  checkedValue: 'optional',
  onRadioChange: () => {},
  onTitleChange: () => {},
};
