import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import ResolveLogo from '../../SVG/ResolveLogo';
import LoadingIcon from '../../SVG/Icons/LoadingIcon';

function TableLoading({ loading, companyLogo }) {
  return (
    <div className={cx('-loading', { '-active': loading })}>
      <LoadingIcon size="2.5sm" />
      {/*<ResolveLogo imgSrc={companyLogo} imgWidth="40px" svgWidth="2.5em" className="pulse" />*/}
    </div>
  );
}

TableLoading.propTypes = {
  loading: PropTypes.bool,
  companyLogo: PropTypes.string,
};

TableLoading.defaultProps = {
  loading: false,
  companyLogo: null,
};

function mapStateToProps(state) {
  const { companyData: { companyDetails: { companyLogo } }, styles } = state;
  const { logoUrl } = styles;
  return { companyLogo: logoUrl || companyLogo };
}

export default connect(mapStateToProps)(TableLoading);
