import {
  ADD_ACCOUNT_USERS,
} from '../constants/actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function addAccountUsers(usersArr) {
  return {
    type: ADD_ACCOUNT_USERS,
    payload: usersArr,
  };
}
