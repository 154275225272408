import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import IconSVG from '../../../Base/SVG/IconSVG';
import { ACTION_COLOUR } from '../../../../js/utils/colours';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  border-left: 1px solid #e5e5e5;
  margin-left: 1.25rem;
`;

const StyledHeader = styled.h6`
  margin-left: 0.75rem;
`;

function DashboardMenu({ title, id, iconType, isOpen, onToggle, items, activeItem, onClick }) {
  function handleToggle() {
    onToggle(id, !isOpen);
  }

  function handleClick(uri) {
    onClick(uri, id);
  }

  const allowedItems = items.filter((item) => checkPermissions(item.permissions));
  return (
    <>
      <div
        className="dashboard-menu-toggle d-flex align-items-center mb-3"
        onClick={handleToggle}
        onKeyPress={handleToggle}
        role="button"
        tabIndex="0"
      >
        <IconSVG name={iconType} stroke="grey" height="16px" width="16px" />
        <StyledHeader className="mb-0">{title}</StyledHeader>
        <IconSVG
          name="Chevron"
          fill={ACTION_COLOUR}
          className={cx('btn-chevron-toggle', 'ms-auto', { 'toggle-right': isOpen })}
        />
      </div>
      <StyledList className={cx('list-unstyled', 'admin-nav', { 'is-open': isOpen })}>
        {allowedItems.map(({ uri, label = [] }) => (
          <li key={uri} className={cx({ active: activeItem === uri })}>
            <a
              href={`./${uri}`}
              onClick={(e) => {
                e.preventDefault();
                handleClick(uri);
              }}
              style={{ paddingLeft: '0.6rem' }}
            >
              {label}
            </a>
          </li>
        ))}
      </StyledList>
    </>
  );
}

DashboardMenu.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      label: PropTypes.string,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  activeItem: PropTypes.string,
  onClick: PropTypes.func,
  iconType: PropTypes.string.isRequired,
};

DashboardMenu.defaultProps = {
  isOpen: true,
  onToggle: () => {},
  items: [],
  activeItem: null,
  onClick: () => {},
  iconType: 'Gear',
};

export default DashboardMenu;
