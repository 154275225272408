import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import ModalPopup from './ModalPopup';

function Prompt({
  isOpen,
  title,
  nameTitle,
  descriptionTitle,
  nameErrorMsg,
  showDescription,
  content,
  errorMsg,
  value,
  disableButtons,
  onOkay,
  onCancel,
  closeOnOkay,
  inputType,
  noCacheValue,
  onChange,
}) {
  const [inputValue, setInputValue] = useState('');
  const [descInputValue, setDescInputValue] = useState('');
  const [displayTitleError, setDisplayTitleError] = useState(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  function emptyInput() {
    setTimeout(() => setInputValue(''), 500);
  }

  return (
    <ModalPopup
      isOpen={isOpen}
      minHeight="0"
      size="md"
      title={title}
      disableButtons={disableButtons}
      closeOnOkay={closeOnOkay}
      onOkay={() => {
        if (!inputValue || /^\s*$/.test(inputValue)) {
          setDisplayTitleError(nameErrorMsg);
        } else {
          setDisplayTitleError(null);
          onOkay(inputValue, descInputValue);
          if (closeOnOkay || noCacheValue) emptyInput();
        }
      }}
      onToggle={() => {
        onCancel();
        emptyInput();
      }}
      hideCloseButton
    >
      {content}
      {errorMsg && <p className="text-danger">{errorMsg}</p>}
      {nameTitle && (
        <p className="mb-1">
          {`${nameTitle} `}
          <span className="required">*</span>
        </p>
      )}
      <Input
        type={inputType}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {displayTitleError && <p className="text-danger">{displayTitleError}</p>}
      {descriptionTitle && <p className="mt-1 mb-1">{descriptionTitle}</p>}
      {showDescription
      && <Input type="textarea" value={descInputValue} onChange={(e) => setDescInputValue(e.target.value)} /> }
    </ModalPopup>
  );
}

Prompt.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  nameTitle: PropTypes.string,
  nameErrorMsg: PropTypes.string,
  descriptionTitle: PropTypes.string,
  disableButtons: PropTypes.bool,
  showDescription: PropTypes.bool,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  errorMsg: PropTypes.string,
  value: PropTypes.string,
  onOkay: PropTypes.func,
  onCancel: PropTypes.func,
  closeOnOkay: PropTypes.bool,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  noCacheValue: PropTypes.bool,
};

Prompt.defaultProps = {
  isOpen: false,
  title: null,
  nameTitle: null,
  nameErrorMsg: null,
  descriptionTitle: null,
  showDescription: false,
  content: null,
  disableButtons: false,
  value: '',
  errorMsg: '',
  onOkay: () => { },
  onCancel: () => { },
  closeOnOkay: true,
  inputType: 'text',
  onChange: () => { },
  noCacheValue: false,
};

export default Prompt;
