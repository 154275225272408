export default {
  en: {
    editTemplate: 'Edit Template',
    createTemplate: 'Create Template',
    nameEmptyError: 'Please enter a template name',
    templateNameAlreadyExists: 'A template with that name already exists',
    generalError: 'An error occurred when creating the template, please try again later',
    templateSaveSuccess: 'Successfully saved vacancy template',
    templateDeleteSuccess: 'Successfully deleted vacancy template',
    templateDeleteError: 'There was an error deleting template',
    noVacancyTemplatesTitle: 'You currently have no vacancy templates',
    noVacancyTemplatesSubTitle: 'Create a template to start using it when you create a vacancy',
    noVacancyTemplatesBtn: 'Create a template',
    deleteTemplateConfTitle: 'Delete Template?',
    deleteTemplateConfContent: 'Are you sure you want to delete template',
    getTemplateByIdError: 'Error fetching template, please try again',
    inputs: {
      name: 'Enter a name',
      description: 'Enter a description',
      vacancyFormName: 'Template Name',
      vacancyFormDescription: 'Template Description',
      readOnly: 'Editable',
      sharedWith: 'Shared With',
      sharePlaceholder: 'Select Account',
    },
    saveButtonLabel: 'Save As Template',
  },
};
