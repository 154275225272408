/* eslint-disable sort-keys */
const inviteInputConfig = {
  eventType: {
    id: 'eventTypeId',
    label: 'Event Type',
    type: 'select',
    dynamicOptions: true,
    required: true,
    displayIfLT2: true,
    inline: true,
  },
  inviteType: {
    id: 'inviteType',
    type: 'radio',
    options: [
      { id: 'selfbook', value: 'SELF_BOOK', label: 'Allow applicant to choose slot' },
      { id: 'specific', value: 'SPECIFIC', label: 'Invite to specific slot' },
      { id: 'instant', value: 'INSTANT', label: 'Instant Event/Interview' },
    ],
  },
  slotId: {
    id: 'slotId',
    label: 'Event slot',
    type: 'slotSelect',
    dynamicOptions: true,
    hideIfSelfBook: true,
    required: true,
  },
  emailDetails: {
    id: 'emailDetails',
    type: 'email',
  },
  sendSms: {
    id: 'sendSms',
    label: 'Also invite by SMS',
    type: 'checkbox',
    className: 'sms-form-group',
  },
  reminderDays: {
    id: 'reminderDays',
    label: "If the applicant hasn't booked in, how many days after your invite should a reminder be sent?",
    type: 'sendReminder',
    className: 'reminder-form-group',
  },
};

export default inviteInputConfig;
