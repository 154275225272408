import {Card, CardBody, Col} from 'reactstrap';
import React, {useEffect, useReducer} from 'react';
import {toast} from 'react-toastify';
import {retryableAPICall} from '../../../../api/common-api-utils';
import {useMounted} from "../../../Base/hooks";
import {requestStatuses} from "../../../../js/constants/requestStatuses";
import {getUser} from "../../../../api/AuthAPI/AuthAPI";
import {checkPermissions} from "../../../../js/auth/AuthUtils";
import {Loader} from "../../../Base/Loading";
import {IntegrationInput} from "../Dashboard";
import {CancelButton, CreateButton, EditButton} from "../../../Base/Buttons";
import {getDbsUser} from "../../../../api/DbsAPI/DbsAPI";
import {createUser, updateUser} from "../../../../api/Integrations/DbsCheckAPI";
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';

const initialState = {
  isAuthorised: true,
  isEditing: false,
  isLoading: true,
  isPromptOpen: false,
  isSaving: false,
  originalCustomerId: '',
  isExistingUser: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_CUSTOMER_ID':
      return { ...state, customerId: action.val };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };
    case 'SET_DBS_CONFIG':
      return {
        ...state,
        originalCustomerId: action.payload.organisationId,
        customerId: action.payload.organisationId,
        isEditing: false,
        isExistingUser: true
      };
    case 'SET_LOADING_COMPLETE':
      return { ...state, isLoading: false, isExistingUser: true };
    case 'SET_IS_EDITING':
      return { ...state, isEditing: true };
    case 'SET_IS_EDITING_CANCELLED':
      return {
        ...state,
        customerId: state.originalCustomerId,
        isEditing: false,
      };
    default:
      return state;
  }
};

function DbsCheckAdmin() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useMounted();

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SET_IS_LOADING' });

      try {
        const resp = await retryableAPICall(() => getDbsUser());

        if (typeof resp === 'string' && resp === requestStatuses.NOT_FOUND_ERROR) {
          dispatch({ type: 'SET_LOADING_COMPLETE' });
        } else if (typeof resp === 'string' && resp === requestStatuses.PERMISSION_DENIED) {
          toast.error('You do not have the required permissions. Please contact support');
        } else if (typeof resp === 'string') {
          toast.error('Failed to load. Please try again later or contact support');
        } else {
          dispatch({ type: 'SET_DBS_CONFIG', payload: { ...resp } });
        }
      } catch (error) {
        toast.error('Failed to load config. Please try again later or contact support');
      }
      dispatch({ type: 'SET_LOADING_COMPLETE' });
    };
    fetchData();
  }, [isMounted]);

  const handleSave = async () => {
    dispatch({ type: 'SET_IS_LOADING' });

    try {
      let resp;

      if (state.isExistingUser) {
        resp = await retryableAPICall(() => updateUser(state.customerId));
      } else {
        resp = await retryableAPICall(() => createUser(state.customerId));
      }

      if (isMounted) {
        if (typeof resp === 'string' && resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          toast.error('A user for this account already exists. Please contact support for assistance.');
        } else if (typeof resp === 'string' && resp === requestStatuses.PERMISSION_DENIED) {
          toast.error('You do not have the required permissions. Please contact support');
        } else if (typeof resp === 'string' && resp === requestStatuses.NOT_FOUND_ERROR) {
          toast.error('The user for this tenant could not be found');
        } else if (typeof resp === 'string') {
          toast.error('Failed to add config. Please try again later or contact support');
        } else {
          toast.success(`Successfully saved config`);
        }
      }
    } catch (error) {
      toast.error('Failed to add config. Please try again later or contact support');
    } finally {
      if (isMounted) {
        dispatch({ type: 'SET_LOADING_COMPLETE' });
      }
    }
  };

  if (!checkPermissions(['dbs:admin'])) return null;

  return (
    <Col className="mt-2">
      {state.isLoading ? (
        <Loader className="mt-5" />
      ) : (
        <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
        <EnhancedCard className="mb-4 mt-2">
          <EnhancedCardTitle
            title="DBS Check"
            subtitle="Manage your integration details with our DBS provider"
          />
            <IntegrationInput
              id="customerId"
              isAuthorised={state.isAuthorised}
              isEditing={state.isEditing}
              label="Customer Id"
              onChange={(val) => {
                dispatch({
                  type: 'CHANGE_CUSTOMER_ID',
                  val,
                });
              }}
              value={state.customerId || ''}
            />
            {state.isEditing ? (
              <>
                <CreateButton
                  action={(e) => {
                    e.preventDefault();
                    handleSave();
                  }}
                  className="mt-2"
                  disabled={state.isSaving || !state.customerId }
                  floatRight={false}
                  isLoading={state.isSaving}
                  label={state.isSaving ? 'Authenticating...' : 'Save'}
                />
                <CancelButton
                  action={() => {
                    dispatch({
                      type: 'SET_IS_EDITING_CANCELLED',
                    });
                  }}
                  className="mt-2 ms-2"
                  disabled={state.isSaving}
                  floatRight={false}
                  isLoading={state.isSaving}
                />
              </>
            ) : (
              <EditButton
                action={() =>
                  dispatch({
                    type: 'SET_IS_EDITING',
                  })
                }
                className="mt-2"
                floatRight={false}
                label="Edit Config"
              />
            )}
        </EnhancedCard>
        </div>
      )}
    </Col>
  );
}

DbsCheckAdmin.propTypes = {};

DbsCheckAdmin.defaultProps = {};

export default DbsCheckAdmin;
