import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';
import fileDownload from "js-file-download";

const { scheduling, api } = config;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */

export async function getInviteNotes(inviteIds) {
  const url = `${api.middlewareAPIURL}${scheduling.inviteNotesPath}?ids=${inviteIds.join()}`;

  const result = await axios.get(
    url,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) {
    return result.data;
  }
  return null;
}

export async function downloadInterviewNotes(inviteId) {
  const url = `${api.middlewareAPIURL}${scheduling.downloadInviteNotesPath}/${inviteId}`;

  const result = await axios.get(
    url,
    { headers: getCommonHeaders() },
  );

  const blob = await (await fetch(`data:application/pdf;base64,${result.data.data}`)).blob();

  if (result.status === 200) return fileDownload(blob, result.data.fileName);
  return null;
}
