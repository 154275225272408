import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const MoveQuestionContext = createContext({
  sections: [],
  onChange: () => { },
});

function MoveQuestionProvider({ children, sections, onChange }) {
  return (
    <MoveQuestionContext.Provider value={{ sections, onChange }}>
      {children}
    </MoveQuestionContext.Provider>
  );
}

MoveQuestionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
};

MoveQuestionProvider.defaultProps = {
  sections: [],
  onChange: () => { },
};

export { MoveQuestionContext as default, MoveQuestionProvider };
