import React from 'react';
import PropTypes from 'prop-types';

function TableNoData({ children }) {
  return (
    <div className="p-4 text-center">
      {children}
    </div>
  );
}

TableNoData.propTypes = {
  children: PropTypes.string,
};

TableNoData.defaultProps = {
  children: null,
};

export default TableNoData;
