import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';

const getBrowserLanguage = () => {
  return navigator.language || navigator.languages[0];
};

const dateFormatMapping = {
  'en-US': 'MM/DD/YYYY', // United States
  'en-GB': 'DD/MM/YYYY', // United Kingdom (England)
  'de-DE': 'DD.MM.YYYY', // Germany
  de: 'DD.MM.YYYY', // German (general)
  'ja-JP': 'YYYY/MM/DD', // Japan
  ja: 'YYYY/MM/DD', // Japanese (general)
};

const getDisplayFormat = (defaultFormat, language) => {
  return dateFormatMapping[language] || defaultFormat;
};

function DatePickerInput({
  id,
  disabled,
  value,
  onChange,
  displayFormat,
  placeholder,
  openDirection,
  numberOfMonths,
  appendToBody,
  pastDates,
  futureDates,
  showClearDate,
  readOnly,
  withPortal,
}) {
  const [isFocussed, setIsFocussed] = useState(false);

  const browserDisplayFormat = useMemo(() => getDisplayFormat(displayFormat, getBrowserLanguage()), [displayFormat]);

  let propObj = {
    id,
    showDefaultInputIcon: true,
    appendToBody,
    numberOfMonths,
    displayFormat: browserDisplayFormat,
    placeholder,
    openDirection,
    focused: isFocussed,
    disabled,
    date: value,
    onDateChange: onChange,
    onFocusChange: ({ focused }) => setIsFocussed(focused),
    showClearDate,
    readOnly,
    withPortal,
    verticalSpacing: 10,
  };

  if (pastDates) propObj = { ...propObj, isOutsideRange: () => false };
  if (!futureDates) {
    propObj = { ...propObj, isOutsideRange: (day) => !isInclusivelyBeforeDay(day, moment()) };
  }

  return <SingleDatePicker {...propObj} />;
}

DatePickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  displayFormat: PropTypes.string,
  placeholder: PropTypes.string,
  openDirection: PropTypes.string,
  numberOfMonths: PropTypes.number,
  appendToBody: PropTypes.bool,
  pastDates: PropTypes.bool,
  futureDates: PropTypes.bool,
  showClearDate: PropTypes.bool,
  readOnly: PropTypes.bool,
  withPortal: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  disabled: false,
  value: null,
  onChange: () => {},
  displayFormat: 'DD-MM-YYYY',
  placeholder: '',
  openDirection: 'down',
  numberOfMonths: 1,
  appendToBody: false,
  pastDates: false,
  futureDates: true,
  showClearDate: false,
  readOnly: false,
  withPortal: false,
};

export default DatePickerInput;
