import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { DefaultHeader, DefaultFooter } from './DefaultLayout';
import { retryableAPICall } from '../api/common-api-utils';
import { getVacancyCategories } from '../api/VacancyAPI';
import { setCategories } from '../js/actions/categoriesActions';

function SiteWrapper({ children, isTouch, header, className, styles, footer }) {
  const dispatch = useDispatch();

  useEffect(() => {
    retryableAPICall(getVacancyCategories).then((response) => {
      const categoriesToSet = typeof response !== 'string' ? response.categories : [];

      dispatch(setCategories(categoriesToSet));
    });
  }, []);

  return (
    <div className={cx('app', { 'is-touch': isTouch }, className)} style={{ ...styles }}>
      {header || <DefaultHeader />}
      <div className="app-body">
        <main className="main">
          {children}
          {isTouch && <div className="main-overlay" />}
        </main>
      </div>
      {!isTouch && <DefaultFooter />}
      {footer}
    </div>
  );
}

SiteWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isTouch: PropTypes.bool,
  styles: PropTypes.shape(),
};

SiteWrapper.defaultProps = {
  className: null,
  footer: null,
  header: null,
  isTouch: false,
  styles: {},
};

export default SiteWrapper;
