import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { hollweg },
} = config;

export async function getHollwegLocations() {
  const result = await axios.get(`${api.middlewareAPIURL}${hollweg.mappingPath}locations`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function addHollwegLocationMapping(name, hollwegLocationId, location) {
  if (name && location && hollwegLocationId) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${hollweg.mappingPath}location`,
      {
        hollwegLocation: {
          hollwegId: hollwegLocationId,
          name,
        },
        location: location.location,
      },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function deleteHollwegLocationMappings(hollwegLocationId, location) {
  if (location && hollwegLocationId) {
    const result = await axios.put(`${api.middlewareAPIURL}${hollweg.mappingPath}location/remove`, location.location, {
      headers: { ...getCommonHeaders() },
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getHollwegPositionMappings() {
  const result = await axios.get(`${api.middlewareAPIURL}${hollweg.mappingPath}positions`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function addHollwegPositionMapping(category, hollwegPositionId, name) {
  if ((category && hollwegPositionId, name)) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${hollweg.mappingPath}position`,
      {
        category,
        hollwegPosition: {
          name,
          hollwegId: hollwegPositionId,
        },
      },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function deleteHollwegPositionMappings(name) {
  if (name) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${hollweg.mappingPath}position/remove/${name}`,
      { name },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function saveCredentials(username, token, password) {
  if (username && token && password) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${hollweg.userPath}`,
      { accountToken: token, apiPassword: password, apiUserName: username },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getCredentials() {
  const result = await axios.get(`${api.middlewareAPIURL}${hollweg.userPath}`, { headers: { ...getCommonHeaders() } });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
