import PropTypes from 'prop-types';
import { BuildDetails, ObjectHandler, BankDetails, NINDisplay, VisaDetails, Attachments } from '../CandidateDetails';
import { SectionToggle } from '../../../Base/SectionDividers';
import { flexiFormUtils } from '../../../Admin/Client/FlexiForms';

const ninRx = new RegExp(flexiFormUtils.PATTERNS.nin, 'i');

function MappedAnswerView({
  mappedAnswers,
  hasToggle,
  headingTag,
  attachments,
  isTouch,
  candidateId,
  headingClassName,
  vertical,
}) {
  if (!Object.keys(mappedAnswers).length || !mappedAnswers.sections) return null;

  return mappedAnswers.sections.map((section, i) => {
    const sectionId = `section-${i}`;

    return (
      <SectionToggle
        key={sectionId}
        chevronFullRight={false}
        className={`${headingClassName || ''} mapped-answers`}
        hasToggle={hasToggle}
        heading={section.title}
        headingAs={headingTag}
      >
        {(section.questions || []).map((question, j) =>
          question.map((answer, k) => {
            const { fieldId, display, value, text } = answer;

            if (!display) return null;

            const keyStr = `details-${i}-${j}-${k}`;

            if (fieldId === 'nationalInsNumber' || (value && ninRx.test(value))) {
              return <NINDisplay key={keyStr} title={text} value={value} vertical={vertical} />;
            }

            if (typeof value === 'string' || typeof value === 'number') {
              return (
                <BuildDetails key={keyStr} title={text} titleColumnSize={4} vertical={vertical}>
                  {value}
                </BuildDetails>
              );
            }

            if (fieldId === 'bankDetails' || (value && value.bankName)) {
              return <BankDetails key={keyStr} value={value} vertical={vertical} />;
            }

            if (fieldId === 'visaDetails' || (value && value.visaType)) {
              return <VisaDetails key={keyStr} value={value} vertical={vertical} />;
            }

            if (typeof value === 'object' && value.hasOwnProperty('fileName')) {
              // eslint-disable-next-line react/prop-types
              const attachment = attachments.find(
                (att) =>
                  /((\w{4,12}-?)){5}/.exec(att.fileName)[0]?.slice(0, -1) ===
                  /((\w{4,12}-?)){5}/.exec(value.fileName)[0]?.slice(0, -1),
              );

              if (attachment) {
                return (
                  <Attachments
                    applicantId={candidateId}
                    btnLabel="View/Download"
                    data={attachment}
                    isTouch={isTouch}
                    title={answer.text}
                    titleWidth={4}
                    touchTitleColumnSize={5}
                  />
                );
              }

              return (
                <BuildDetails key={keyStr} title={text} titleColumnSize={4} vertical={vertical}>
                  <span className="text-muted">No file uploaded</span>
                </BuildDetails>
              );
            }

            if (Array.isArray(value)) {
              return (
                <BuildDetails key={keyStr} title={text} titleColumnSize={4} vertical={vertical}>
                  {value.join(', ')}
                </BuildDetails>
              );
            }

            return (
              <BuildDetails key={keyStr} title={text} titleColumnSize={4} vertical={vertical}>
                <ObjectHandler obj={value} />
              </BuildDetails>
            );
          }),
        )}
      </SectionToggle>
    );
  });
}

MappedAnswerView.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  candidateId: PropTypes.string,
  hasToggle: PropTypes.bool,
  headingClassName: PropTypes.string,
  headingTag: PropTypes.string,
  isTouch: PropTypes.bool,
  mappedAnswers: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        questions: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              display: PropTypes.bool,
              fieldId: PropTypes.string,
              text: PropTypes.string,
              value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape(),
                PropTypes.arrayOf(PropTypes.string),
                PropTypes.number,
              ]),
            }),
          ),
        ),
        title: PropTypes.string,
      }),
    ),
  }),
  vertical: PropTypes.bool,
};

MappedAnswerView.defaultProps = {
  attachments: [],
  candidateId: null,
  hasToggle: true,
  headingClassName: null,
  headingTag: 'h4',
  isTouch: false,
  mappedAnswers: {
    sections: [],
  },
  vertical: false,
};

export default MappedAnswerView;
