import React from 'react';

const VacancyContext = React.createContext({
  vacancy: null,
  vacancyId: '',
  vacancyTitle: '',
  companyName: '',
});

export default VacancyContext;
