import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import styled from 'styled-components';
import StageLabel from './StageLabel';

const StageElm = styled.div`
  ${({ stageWidth }) => `
    width: ${stageWidth}px;
    flex-basis: ${stageWidth}px;
  `}
`;

const Dropzone = styled.div`
  width: ${({ stageWidth, endStage }) => (stageWidth - (endStage ? 5 : 10))}px;
`;

function Stage({
  id,
  label,
  title,
  children,
  index,
  total,
  isCurrent,
  className,
  onDoubleClick,
  stageWidth,
}) {
  return (
    <StageElm
      id={id}
      className={cx(['stage', className, { 'is-current': isCurrent }])}
      data-stage-index={index}
      data-stage-total={total}
      stageWidth={stageWidth}
    >
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <Dropzone
            ref={provided.innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
            className={cx('stage-dropzone', { 'is-dragging': snapshot.isDraggingOver })}
            onDoubleClick={() => {
              if (!isCurrent) onDoubleClick(id);
            }}
            stageWidth={stageWidth}
            endStage={(index === total)}
          >
            {children}
            <StageLabel className="drop-label" title={title}>{label}</StageLabel>
            {provided.placeholder}
          </Dropzone>
        )}
      </Droppable>
    </StageElm>
  );
}

Stage.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
  isCurrent: PropTypes.bool,
  className: PropTypes.string,
  onDoubleClick: PropTypes.func,
  stageWidth: PropTypes.number,
};

Stage.defaultProps = {
  label: null,
  children: null,
  title: null,
  index: 0,
  total: 0,
  isCurrent: false,
  className: null,
  onDoubleClick: () => { },
  stageWidth: 120,
};

export default memo(Stage);
