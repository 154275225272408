import PropTypes from 'prop-types';
import DropDownItem from './DropdownItem';

export default function DropdownMenu({ title, menuItems, action }) {
  return (
    <>
      {title && (
        <div className="dd-menu-header">
          <p>{title}</p>
        </div>
      )}
      {menuItems
        .filter((item) => !item.notMenuItem)
        .map((item, i) => {
          const { label, labelComponent, ...rest } = item;
          return (
            <DropDownItem
              // eslint-disable-next-line react/no-array-index-key
              key={`dd-mi_${i}`}
              action={action}
              {...rest}
              id={rest.id || i}
            >
              {label}
              {labelComponent}
            </DropDownItem>
          );
        })}
    </>
  );
}

DropdownMenu.propTypes = {
  action: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
};

DropdownMenu.defaultProps = {
  action: null,
  menuItems: {},
  title: null,
};
