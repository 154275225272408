import * as EmailValidator from 'email-validator';

export function validatePassword(password) {
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const isAtLeast8Chars = password.length >= 8;

  return hasSpecialChar && hasUpperCase && hasNumber && hasLowerCase && isAtLeast8Chars;
}

function stringHasLen(value, len = 1) {
  if (typeof value !== 'string') return false;
  return value.trim().length >= len;
}

function isNumeric(num) {
  return !Number.isNaN(parseFloat(num));
}

function validateUrl(url) {
  return /^https?:\/\/(-\.)?[-a-zA-Z0-9@:%_+.~#?&=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&=]*)?$/gi.test(url);
}

function hasValue(value) {
  let hasLength = stringHasLen(value);
  if (!hasLength && Array.isArray(value)) hasLength = value.length > 0;
  const numeric = isNumeric(value);
  const defined = value || (numeric && value <= 0);
  const isDate = value instanceof Date;

  return {
    valid: Boolean(defined && (numeric || hasLength || isDate)),
    message: 'Required field',
  };
}

export function isEmailAddress(value) {
  return {
    valid:
      !stringHasLen(value) ||
      EmailValidator.validate(value) ||
      value === '{employer_email_address}' ||
      value === '{user_email_address}',
    message: 'Please provide a valid email address',
  };
}

export function isNumber(value) {
  return {
    valid: isNumeric(value) && Number(value) >= 0,
    message: 'Please provide a valid number',
  };
}

export function isGtOrEq(value, min) {
  return {
    valid: Number(value) >= Number(min),
    message: `Value must be greater than or equal to ${min}`,
  };
}

export function isLtOrEq(value, max) {
  return {
    valid: Number(value) <= Number(max),
    message: `Value must be less than or equal to ${max}`,
  };
}

export function isPassword(value) {
  return {
    valid: validatePassword(value),
    message:
      "Your password isn't strong enough. It should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character, and not contain any whitespace",
  };
}

export function isUrl(value) {
  return {
    valid: validateUrl(value),
    message: 'Please provide a valid URL',
  };
}

export function isStringSizeGT(value, max) {
  const stringSize = new TextEncoder().encode(value).length;

  return {
    valid: stringSize < max,
    message: 'Message is too large. Please remove some content.',
  };
}

export function isDecimalPlaces(value, maxDp) {
  if (!isNumeric(value)) {
    return {
      valid: false,
      message: 'Please provide a valid number',
    };
  }
  const decimalPart = String(value).split('.')[1];
  const numDecimalPlaces = decimalPart ? decimalPart.length : 0;
  return {
    valid: numDecimalPlaces <= maxDp,
    message: `Please provide a number with no more than ${maxDp} decimal place${maxDp !== 1 ? 's' : ''}`,
  };
}

// function isPhoneNumber(value) {
//   const hasLength = stringHasLen(value, 10);
//   const isPhone = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/.test(value);

//   return {
//     valid: hasLength && isPhone,
//     message: 'Please provide a valid phone number',
//   };
// }

export function validateField(value, testTypes = {}) {
  const errorMessages = [];
  const { required, emailAddress, number, min, max, password, url, maxStringSize, decimalPlaces } = testTypes;

  if (required) {
    const { valid, message } = hasValue(value);
    if (!valid) errorMessages.push(message);
  }

  if (emailAddress) {
    const { valid, message } = isEmailAddress(value);
    if (!valid) errorMessages.push(message);
  }

  if (password) {
    const { valid, message } = isPassword(value);
    if (!valid) errorMessages.push(message);
  }

  if (number) {
    const { valid, message } = isNumber(value);
    if (!valid) errorMessages.push(message);
  }

  if (number && min) {
    const { valid, message } = isGtOrEq(value, min);
    if (!valid) errorMessages.push(message);
  }

  if (number && max) {
    const { valid, message } = isLtOrEq(value, max);
    if (!valid) errorMessages.push(message);
  }

  if (url) {
    const { valid, message } = isUrl(value);
    if (!valid) errorMessages.push(message);
  }

  if (maxStringSize) {
    const { valid, message } = isStringSizeGT(value, maxStringSize);
    if (!valid) errorMessages.push(message);
  }

  if (number && decimalPlaces !== undefined) {
    const { valid, message } = isDecimalPlaces(value, decimalPlaces);
    if (!valid) errorMessages.push(message);
  }

  return errorMessages;
}

export function mapErrors(errObj) {
  const mappedErrs = Object.entries(errObj).reduce((acc, [fieldId, { invalid, errors: fieldErrs }]) => {
    const [errMsg] = fieldErrs;
    if (invalid) acc[fieldId] = errMsg;
    return acc;
  }, {});

  const hasErrors = Object.values(errObj).some(({ invalid }) => invalid);

  return {
    messages: mappedErrs,
    hasErrors,
  };
}

export default function validation(inputs, formData) {
  // Keep track of email addresses already used in the validation so we don't display the
  // error message for all the email address inputs
  const usedEmailAddress = [];

  const inputValidation = inputs.reduce((acc, input) => {
    const { id, type, required, novalidate, min, max, password, url, maxStringSize, decimalPlaces } = input;

    if (id in formData && !novalidate) {
      const errors = validateField(formData[id], {
        required,
        emailAddress: type === 'email',
        number: type === 'number',
        min,
        max,
        password,
        url,
        maxStringSize,
        decimalPlaces,
      });

      acc[id] = { invalid: !!errors.length, errors };
    }

    // Email addresses require validation to stop users inputting values accross
    // send inputs
    if ((id === 'bccAddresses' || id === 'ccAddresses' || id === 'to') && id in formData && formData[id].length > 0) {
      const emailError = usedEmailAddress.some((item) => formData[id].includes(item));

      const errors = [];

      if (acc[id] && acc[id]?.errors) {
        errors.push(...acc[id].errors);
      }

      if (emailError) {
        errors.push('You have added an email address already included.');
      }
      acc[id] = {
        errors,
        invalid: errors.length > 0,
      };

      usedEmailAddress.push(...formData[id]);
    }

    return acc;
  }, {});

  return inputValidation;
}
