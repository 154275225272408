import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function TopDetailBar({ className, title, badge, subItems, children }) {
  return (
    <div className={cx('details-bar position-relative', className)}>
      <div className="details-bar-header d-flex align-items-center d-flex justify-content-between">
        {title && (
          <h1 className="h2 mb-0">
            {title}
            {badge}
          </h1>
        )}
        {children}
      </div>
      {!!subItems.length && (
        <div className="details-bar-items mt-1 d-flex align-items-center">
          {subItems.map((item) => {
            const { title: itemTitle, content } = item;

            return (
              <div key={itemTitle} className="d-flex align-items-center me-4">
                <p className="detail-title-block">
                  {itemTitle}
                  {itemTitle && !(!content && content !== 0) ? ': ' : ''}
                  <span className="detail-value-block">{content}</span>
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

TopDetailBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
    }),
  ),
};

TopDetailBar.defaultProps = {
  className: null,
  title: null,
  badge: null,
  children: null,
  subItems: [],
};

export default connect()(TopDetailBar);
