import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';

export default function SimpleRadios({
  id,
  name,
  value,
  label,
  checked,
  onChange,
}) {
  function handleChange(e) {
    const { target: { value: val } } = e;
    onChange(val);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <Label check for={id}>
      <Input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <span>{label}</span>
    </Label>
  );
}

SimpleRadios.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

SimpleRadios.defaultProps = {
  checked: false,
  onChange: () => { },
};
