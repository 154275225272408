import { ADD_JOB_SITE_CONFIG, REMOVE_JOB_SITE_CONFIG } from '../constants/actionTypes';

const initialState = {
  baseUrl: '',
  vacancyBaseUrl: '',
  formattedName: '',
};

export default function jobSiteConfigReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_JOB_SITE_CONFIG:
      return {
        ...state,
        ...payload,
      };
    case REMOVE_JOB_SITE_CONFIG:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
