import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { smart },
} = config;

export async function submitToSmart(candidateId) {
  if (candidateId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${smart.basePath}/${candidateId}`,
      {},
      {
        headers: getCommonHeaders(),
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;

    return null;
  }
}
