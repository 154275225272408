import React, { Fragment, useState } from 'react';
import { Col, Row } from 'reactstrap';
import queryString from 'query-string';
import { ResolveLogo } from '../../Base/SVG';
import AccessCodeEntryView from './AccessCodeEntryView';
import { mapErrorResponse } from '../../../api/common-api-utils';
import { getCandidateWithAccessToken } from '../../../api/CandidateAPI/CandidateAPI';
import CandidateDocumentView from './CandidateDocumentView';
import { externalCandidateView } from '../../../js/language/pages';
import { useLanguage } from '../../Base/hooks';

function ExternalCandidateView() {
  const { langPack } = useLanguage(externalCandidateView);
  const [token] = useState(queryString.parse(window.location.search)?.t);
  const [candidateData, setCandidateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [finalAccessCode, setFinalAccessCode] = useState(undefined);

  async function onAccessCodeComplete(accCode) {
    setFinalAccessCode(accCode);
    setLoading(true);
    try {
      const result = await getCandidateWithAccessToken(token, accCode);
      setCandidateData(result);
    } catch (err) {
      const { response = {} } = err;
      setError(mapErrorResponse(response));
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <Row style={{ height: '60px' }}>
        <Col>
          <header className="app-header container-fluid">
            <div className="app-header-inner">
              <ResolveLogo svgWidth="2.5em" className="me-4" />
              <p className="large-blue-text">Talent-Funnel</p>
            </div>
          </header>
        </Col>
      </Row>
      {!token && <p>Token not valid</p>}
      {token && !candidateData && (
        <AccessCodeEntryView
          onAccessCodeComplete={onAccessCodeComplete}
          langPack={langPack}
          isLoading={loading}
          btnLabel={langPack.viewCandidateBtnLabel}
          title={langPack.accessCodeTitle}
          error={error}
        />
      )}
      {candidateData && !loading && (
        <CandidateDocumentView
          candidateData={candidateData}
          langPack={langPack}
          token={token}
          accessCode={finalAccessCode}
        />
      )}
    </Fragment>
  );
}

ExternalCandidateView.propTypes = {};

ExternalCandidateView.defaultProps = {};

export default ExternalCandidateView;
