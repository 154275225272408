import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback } from 'reactstrap';
import 'quill-emoji/dist/quill-emoji.css';
import ReactQuill from 'react-quill';

class NoteBodyInput extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
    this.editor = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.onUpdate(value);
    }
  }

  onUpdate = (value) => {
    this.setState({ value });
  }

  handleChange = (val) => {
    const { onChange } = this.props;
    this.setState({ value: val });
    onChange(val);
  }

  render() {
    const {
      invalid,
      errorMsg,
    } = this.props;
    const { value } = this.state;

    return (
      <FormGroup>
        <ReactQuill
          ref={this.editor}
          value={value || ''}
          className={`message-body ${invalid ? 'is-invalid' : ''}`}
          onChange={this.handleChange}
          modules={NoteBodyInput.modules}
        />
        <FormFeedback>{errorMsg}</FormFeedback>
      </FormGroup>
    );
  }
}

/*
* Quill modules to attach to editor
* See http://quilljs.com/docs/modules/ for complete options
*/
NoteBodyInput.modules = {
  toolbar: false,
};

NoteBodyInput.propTypes = {
  value: PropTypes.string,
  invalid: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
};

NoteBodyInput.defaultProps = {
  value: null,
  invalid: false,
  errorMsg: null,
  onChange: () => { },
};

export default NoteBodyInput;
