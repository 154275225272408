import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';
import fileDownload from 'js-file-download';

const { onboarding, api } = config;
const basePath = api.middlewareAPIURL + onboarding.basePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */
export async function getOnboardingDetails(applicantId) {
  const result = await axios.get(`${basePath}/${applicantId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function onboardingRequest(
  applicantId,
  subject,
  content,
  replyTo,
  fromName,
  attachments,
  ccAddresses,
  bccAddresses,
  delay,
) {
  const result = await axios.post(
    `${basePath}`,
    {
      candidateIds: applicantId,
      subject,
      htmlContent: content,
      replyTo,
      fromName,
      attachments,
      bccAddresses,
      ccAddresses,
      delay,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendOnboardingReminder(candidateId) {
  const result = await axios.post(`${basePath}/reminder/${candidateId}`, null, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function downloadOnboarding(onboardingId) {
  const url = `${api.middlewareAPIURL}${onboarding.basePath}${onboarding.downloadOnboardingPath}/${onboardingId}`;

  const result = await axios.get(url, { headers: getCommonHeaders() });

  const blob = await (await fetch(`data:application/pdf;base64,${result.data.data}`)).blob();

  if (result.status === 200) return fileDownload(blob, result.data.fileName);
  return null;
}
