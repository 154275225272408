import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { Col, Row, Badge } from 'reactstrap';
import cx from 'classnames';
import { DeleteButton, IconButton } from '../../../Base/Buttons';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getFlexiFormList, deleteFlexiForm, getFlexiForm } from '../../../../api/FormsAPI/ATSFormsAPI';
import { countFormTypes, spliceItem } from './utils';
import { FormSlider, FormStyles } from './components';
import { Confirmation } from '../../../Base/Modal';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { useTableState, DataTable } from '../../../Base/Tables';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import Can from '../../../Base/RBAC/Can/Can';
import { startPolling } from '../../../../js/actions/eventNotificationActions';
import PageColumn from '../Dashboard/PageColumn';
import { isEditable, isDeletable } from '../Dashboard/utils';
import { TippyTruncate } from '../../../Base/Truncate';
import Tabs from '../../../Base/Tabs/Tabs';
import PreviewFormModal from './components/PreviewFormModal';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';
import styled from 'styled-components';

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0;
  }
`;
const RestrictedFormSlider = Can(FormSlider);

async function handleDownload(formId) {
  try {
    // Fetch JSON data (replace the URL with your JSON data source)
    const response = await retryableAPICall(() => getFlexiForm(formId));

    if (typeof response !== 'string') {
      const { formSchema, uiSchema, rules, name, type, readOnly, sharedWith, themeId } = response;

      const blob = new Blob(
        [
          JSON.stringify(
            {
              formSchema,
              name,
              readOnly,
              rules: rules || [],
              sharedWith: [],
              themeId,
              type,
              uiSchema,
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      );

      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${name || 'form'}.json`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error('Error fetching and downloading JSON:', error);
  }
}

function getUniqueItems(data) {
  const dictOfUniqueAccounts = data.reduce((uniqueItems, currentItem) => {
    const identifier = `${currentItem.accountId}-${currentItem.accountName}`;

    if (!uniqueItems[identifier]) {
      // eslint-disable-next-line no-param-reassign
      uniqueItems[identifier] = currentItem;
    }

    return uniqueItems;
  }, {});

  return Object.values(dictOfUniqueAccounts);
}

function FlexiForms({
  tablePrefs,
  isCreateMode,
  initialData,
  restrictedToFormTypes,
  onClose,
  totalAccounts,
  formTypeTitleName,
  editorType,
  activeAccount,
  startPoll,
  canCreateForm,
}) {
  const isMounted = useMounted();
  const [activeTab, setActiveTab] = useState('forms');
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const {
    tableState,
    setTableData,
    // setTableLoading,
    setTableRejected,
  } = useTableState({ rowsPerPage: tablePrefs.pageSize });

  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [previewModal, setPreviewModal] = useState({
    formConfig: null,
    id: null,
    isOpen: false,
  });
  const [isCopying, setIsCopying] = useState(false);
  const [formId, setFormId] = useState();
  const [formTypeCount, setFormTypeCount] = useState({});

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getFlexiFormList(restrictedToFormTypes));

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          // We don't need to see general forms anymore, they are deprecated atm
          const filteredForms = resp
            ?.filter((form) => form.type !== 'GENERAL')
            .sort((a, b) => a.name?.localeCompare(b.name));

          setTableData({
            data: filteredForms,
            totalResults: filteredForms.length,
          });
          setFormTypeCount(countFormTypes(filteredForms));
          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
  }, [isCreateMode]);

  async function handleFormDelete(fId) {
    const idx = tableState.data.findIndex(({ id }) => id === fId);
    const updatedFormList = spliceItem(tableState.data, idx);
    setTableData({
      data: updatedFormList,
      totalResults: updatedFormList.length,
    });

    const resp = await retryableAPICall(() => deleteFlexiForm(fId));
    if (typeof resp === 'string') {
      toast.error('Error deleting form');
      // reset if error
      setTableData({
        data: tableState.data,
        totalResults: tableState.data.length,
      });
    } else {
      toast.success('Form deleted successfully');
    }
  }

  const columns = [
    {
      Header: `${formTypeTitleName} Name`,
      id: 'formName',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        const {
          // eslint-disable-next-line react/prop-types
          name,
          // eslint-disable-next-line react/prop-types
          id,
          // eslint-disable-next-line react/prop-types
          type,
          // eslint-disable-next-line react/prop-types
          readOnly,
          // eslint-disable-next-line react/prop-types
          accountId,
        } = original;

        return (
          <>
            {/* eslint-disable-next-line react/prop-types */}
            {checkPermissions(['admin:forms:update']) && isEditable(accountId, activeAccount.id, readOnly) ? (
              <a
                href="#formName"
                onClick={(e) => {
                  e.preventDefault();
                  setFormId(id);
                  setIsSliderOpen(true);
                }}
              >
                {name}
              </a>
            ) : (
              name
            )}
            {(type === 'ONBOARDING' || type === 'REFERENCE') && (
              <Tippy
                // eslint-disable-next-line max-len
                content="The form is available for everyone to use but only 'shared with' accounts can edit if editable"
                delay={[1000, null]}
                maxWidth="150px"
                theme="ats"
              >
                <span>
                  <Badge className="ms-2" color="success" style={{ fontSize: '10px', padding: '0.3rem' }}>
                    Global
                  </Badge>
                </span>
              </Tippy>
            )}
          </>
        );
      },
    },
  ];

  if (restrictedToFormTypes.length !== 1) {
    columns.push({
      Header: `${formTypeTitleName} Type`,
      id: 'formType',
      className: 'text-capitalize',
      width: 100,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { type } = original;
        // eslint-disable-next-line react/prop-types
        return (
          <>
            <span>{type === 'GENERIC' ? 'Flexi-Form' : type.replace('_', ' ').toLowerCase()}</span>{' '}
            {type === 'GENERAL' && (
              <Tippy
                // eslint-disable-next-line max-len
                content="General forms were used in application forms but are no longer required. You can now create application forms directly in the form builder. General forms will be removed in an upcoming version."
                delay={[1000, null]}
                maxWidth="150px"
                theme="ats"
              >
                <span>
                  <Badge className="ms-2" color="warning" style={{ fontSize: '10px', padding: '0.3rem' }}>
                    Deprecated
                  </Badge>
                </span>
              </Tippy>
            )}
          </>
        );
      },
    });
  }
  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types,prefer-const
          let { sharedWith = [] } = original;

          const filteredAccounts = getUniqueItems(sharedWith);

          if (!filteredAccounts.length) {
            return null;
          }

          if (filteredAccounts.length === totalAccounts) {
            return 'All';
          }

          const accountNames = filteredAccounts.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }
  if (checkPermissions(['admin:emails:delete']) || canCreateForm) {
    columns.push({
      width: 63,
      id: 'action',
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, accountId, readOnly } = original;
        // eslint-disable-next-line react/prop-types
        const editable = isEditable(accountId, activeAccount.id, readOnly);
        // eslint-disable-next-line react/prop-types
        const deletable = isDeletable(accountId, activeAccount.id);

        return (
          <div className="justify-content-center d-flex gap-1">
            {checkPermissions(['talentfunnel:admin']) && editorType === 'FORM' ? (
              <IconButton
                iconOnly
                iconClassName="fa fa-download"
                size="sm"
                className="btn-sm xs btn-outline"
                action={async () => {
                  try {
                    const selectedForm = await handleDownload(id);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            ) : null}
            {editorType === 'FORM' ? (
              <IconButton
                iconOnly
                iconClassName="fa fa-eye"
                size="sm"
                className="btn-sm xs btn-outline"
                action={() => {
                  setPreviewModal({
                    formConfig: original,
                    id,
                    isOpen: true,
                  });
                }}
              />
            ) : null}
            {canCreateForm && editorType === 'FORM' ? (
              <IconButton
                iconOnly
                iconClassName="fa fa-copy"
                size="sm"
                className="btn-sm xs btn-outline"
                action={() => {
                  setFormId(id);
                  setIsSliderOpen(true);
                  setIsCopying(true);
                }}
              />
            ) : null}
            {checkPermissions(['admin:emails:delete']) ? (
              <Tippy
                content="Only the owner can delete this"
                delay={[1000, null]}
                disabled={editable && deletable}
                maxWidth="150px"
                theme="ats"
              >
                <div>
                  <DeleteButton
                    action={() => {
                      setDeleteFormId(id);
                      setShowConfirm(true);
                    }}
                    className="btn-sm xs btn-outline"
                    disabled={!editable || !deletable}
                    floatRight={false}
                    iconOnly
                    label="Delete Form"
                  />
                </div>
              </Tippy>
            ) : null}
          </div>
        );
      },
    });
  }

  return (
    <>
      <Col>
        {editorType === 'FORM' ? (
          <Row className="pb-3">
            <Col className="mx-auto">
              <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
                <Row className="tab-row">
                  <Col className="ps-0">
                    <Tabs
                      activeTab={activeTab}
                      className="email-settings-tabs mb-1"
                      onClick={(id) => setActiveTab(id)}
                      positionAbsolute={false}
                      tabs={[
                        { anchor: 'forms', label: 'Forms' }, // ToDo permission
                        { anchor: 'branding', label: 'Form Styles' /* permissions: ['forms:admin:style'] */ },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null}
        <Row>
          {activeTab === 'forms' && (
            <PageColumn className={cx('pt-3', { 'h-scroll-table': tableState.data.length })} state={pageState}>
              <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
                <EnhancedCard className="mb-4 mt-2">
                  <EnhancedCardTitle
                    title={restrictedToFormTypes.length === 1 ? 'eDocs' : 'Forms '}
                    subtitle={`Create and update your ${restrictedToFormTypes.length === 1 ? 'eDocs' : 'Forms '} templates`}
                  />
                  <StyledTable
                    className="mt-3"
                    data={tableState.data}
                    errorText={`There has been an error loading ${formTypeTitleName.toLowerCase()}s, please try again later`}
                    hasSelectColumn={false}
                    id="flexi-forms"
                    isLoading={tableState.isLoading}
                    isRejected={tableState.isRejected}
                    noDataText={`You currently have no ${formTypeTitleName.toLowerCase()}s`}
                    pageSize={tableState.rowsPerPage}
                    showPagination={false}
                    tblHeightOffset={315}
                    columns={columns}
                    // totalPages={tableState.totalPages}
                    totalResults={tableState.totalResults}
                  />
                </EnhancedCard>
              </div>
            </PageColumn>
          )}

          {activeTab === 'branding' && (
            <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
              <FormStyles activeAccount={activeAccount} />
            </div>
          )}
        </Row>
      </Col>

      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          handleFormDelete(deleteFormId);
          setShowConfirm(false);
        }}
        content="Are you sure you want to delete the form?"
        show={showConfirm}
        title="Delete Form?"
      />
      <PreviewFormModal
        formConfig={previewModal.formConfig}
        id={previewModal.id}
        isOpen={previewModal.isOpen}
        onClose={() => {
          setPreviewModal({
            formConfig: null,
            id: null,
            isOpen: false,
          });
        }}
      />
      <RestrictedFormSlider
        activeAccount={activeAccount}
        closeSlider={() => {
          setIsSliderOpen(false);
          setIsCopying(false);
          setFormId();
          if (isCreateMode) onClose();
          startPoll();
        }}
        editorType={editorType}
        formId={formId}
        formTypeCount={formTypeCount}
        formTypeTitleName={formTypeTitleName}
        initialData={initialData}
        isCopying={isCopying}
        isEditing={!isCreateMode}
        isSliderOpen={isSliderOpen}
        openPreviewForm={(id, formConfig) => {
          setPreviewModal({
            formConfig,
            id,
            isOpen: true,
          });
        }}
        onFormSubmit={(flexiForm) => {
          const { accountId, id, type, name, readOnly, sharedWith } = flexiForm;
          let cloneFormList = [...tableState.data];

          if (isCreateMode || isCopying) {
            cloneFormList.push({
              accountId,
              id,
              type,
              name,
              readOnly,
              sharedWith,
            });
          } else {
            const idx = cloneFormList.findIndex((frm) => frm.id === id);
            cloneFormList = spliceItem(cloneFormList, idx, flexiForm);
          }

          setIsCopying(false);
          setFormTypeCount(countFormTypes(cloneFormList));
          setTableData({
            data: cloneFormList,
            totalResults: cloneFormList.length,
          });
        }}
        permissions={['admin:forms:update']}
        totalAccounts={totalAccounts}
      />
    </>
  );
}

FlexiForms.propTypes = {
  initialData: PropTypes.shape(),
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  isCreateMode: PropTypes.bool,
  restrictedToFormTypes: PropTypes.arrayOf(PropTypes.string),
  formTypeTitleName: PropTypes.string,
  editorType: PropTypes.string,
  totalAccounts: PropTypes.number,
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func,
  startPoll: PropTypes.func,
  canCreateForm: PropTypes.bool,
};

FlexiForms.defaultProps = {
  initialData: null,
  tablePrefs: {
    pageSize: 25,
  },
  isCreateMode: false,
  restrictedToFormTypes: [],
  totalAccounts: 0,
  formTypeTitleName: 'Form',
  editorType: 'FORM',
  activeAccount: {},
  onClose: () => {},
  startPoll: () => {},
  canCreateForm: false,
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;
  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccount: { id: activeAccountId, name: activeAccountName },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    startPoll: () => {
      dispatch(startPolling());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlexiForms);
