import {
  ADD_FOURTH_USER,
  REMOVE_FOURTH_USER,
  ADD_FOURTH_DATA,
  REMOVE_FOURTH_DATA,
  ADD_FOURTH_INTEGRATION,
  ADD_FOURTH_GLOBAL_CONFIG,
} from '../constants/actionTypes';

export function addFourthIntegration(userObj, dataObj) {
  return {
    type: ADD_FOURTH_INTEGRATION,
    payload: {
      user: userObj,
      data: dataObj,
    },
  };
}

export function addFourthUser(username, organisationId) {
  return {
    type: ADD_FOURTH_USER,
    payload: { username, organisationId },
  };
}

export function removeFourthUser() {
  return { type: REMOVE_FOURTH_USER };
}

export function addFourthData(dataObj) {
  return {
    type: ADD_FOURTH_DATA,
    payload: dataObj,
  };
}

export function removeFourthData() {
  return { type: REMOVE_FOURTH_DATA };
}

export function addGlobalConfig(configObj) {
  return {
    type: ADD_FOURTH_GLOBAL_CONFIG,
    payload: configObj,
  };
}
