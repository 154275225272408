import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import ColumnListSearch from './ColumnListSearch';
import ColumnOrderControl from './ColumnOrderControl';

function SelectableCol({ id, label, checked, disabled, onChange }) {
  return (
    <FormGroup check>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <Label check for={id} title={disabled ? 'Cannot be removed; Required column' : null}>
        <Input
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={disabled ? 'is-disabled' : ''}>{label}</span>
      </Label>
    </FormGroup>
  );
}

SelectableCol.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

SelectableCol.defaultProps = {
  checked: false,
  disabled: false,
  onChange: () => {},
};

function ColumnList({ id, columns, selectedIds, onColumnSelect, onOrderChange, orderable }) {
  const optionalCols = useMemo(() => columns.filter(({ optional }) => optional), [columns]);
  const [searchStr, setSearchStr] = useState('');

  const handleCheckAll = useCallback(
    (checked) => {
      let updated = [];
      if (checked) updated = optionalCols.map(({ id: colId }) => colId);
      onColumnSelect(updated);
    },
    [onColumnSelect, optionalCols],
  );

  const handleChange = useCallback(
    (checked, colId) => {
      let updated = [...selectedIds];

      if (checked) {
        updated.push(colId);
      } else {
        updated = updated.filter((item) => item !== colId);
      }

      onColumnSelect(updated);
    },
    [onColumnSelect, selectedIds],
  );

  return (
    <div className="column-list">
      <div className="column-list-header">
        <SelectableCol
          id={`col-list-heading-${id}`}
          label={`${selectedIds.length} items selected`}
          checked={Boolean(optionalCols.length) && selectedIds.length === optionalCols.length}
          onChange={handleCheckAll}
        />
      </div>
      <div className="column-list-body">
        <ColumnListSearch
          onSearch={(str) => {
            setSearchStr(str.toLowerCase());
            handleCheckAll(false);
          }}
        />
        <div className="column-list-wrapper">
          <ul className="list-unstyled">
            {columns.map((column) => {
              const { Header, id: colId, optional, orderIndex } = column;
              if (typeof Header !== 'string') return null;

              if (!Header.toLowerCase().includes(searchStr)) return null;

              const inpId = `${id}-${colId}`;

              return (
                <li key={inpId} data-header={Header}>
                  <SelectableCol
                    id={inpId}
                    label={Header.toLowerCase()}
                    checked={selectedIds.includes(colId)}
                    disabled={!optional}
                    onChange={(checked) => handleChange(checked, colId)}
                  />
                  {orderable && (
                    <ColumnOrderControl
                      index={orderIndex}
                      length={columns.length}
                      onClick={(newIndex) => onOrderChange(orderIndex, newIndex)}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

ColumnList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  id: PropTypes.string.isRequired,
  onColumnSelect: PropTypes.func,
  onOrderChange: PropTypes.func,
  orderable: PropTypes.bool,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
};

ColumnList.defaultProps = {
  columns: [],
  onColumnSelect: () => {},
  onOrderChange: () => {},
  orderable: false,
  selectedIds: [],
};

export default ColumnList;
