import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import InfoRow from './InfoRow';

function RowsWrapper({ rows }) {
  if (!rows.length) return null;

  return (
    <Row>
      <Col sm="12" className="detail-rows">
        {/* eslint-disable-next-line react/no-array-index-key */}
        {rows.map((rowObj, i) => (<InfoRow key={`${rowObj.title}-${i}`} {...rowObj} />))}
      </Col>
    </Row>
  );
}

RowsWrapper.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()),
};

RowsWrapper.defaultProps = {
  rows: [],
};

export default RowsWrapper;
