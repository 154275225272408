import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { personalityTest, api } = config;
const basePath = api.middlewareAPIURL + personalityTest.basePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */

export async function requestPersonalityTestDocumentation(
  candidateId,
  subject,
  content,
  replyTo,
  fromName,
  attachments,
) {
  const result = await axios.post(
    `${basePath}/request`,
    {
      candidateId,
      configId: 'black_sheep_1',
      emailTemplate: {
        subject,
        content,
        replyTo,
        fromName,
        attachments,
      },
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendPersonalityTestReminder(candidateId) {
  const result = await axios.post(`${basePath}/request/reminder/${candidateId}`, null, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getPersonalityTestData(candidateId) {
  const result = await axios.get(`${basePath}/test/${candidateId}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
