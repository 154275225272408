/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function styles(isDragging, draggableStyles) {
  return { ...draggableStyles };
}

// https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
export default function PortalAware({
  portalClassName,
  draggableClassName,
  children,
  provided,
  snapshot,
}) {
  const usePortal = snapshot.isDragging;

  const [portal, setPortal] = useState();

  useEffect(() => {
    const portalDiv = document.createElement('div');
    portalDiv.className = portalClassName;
    document.body.appendChild(portalDiv);
    setPortal(portalDiv);

    return () => {
      document.body.removeChild(portalDiv);
    };
  }, [portalClassName]);

  const child = (
    <div
      ref={provided.innerRef}
      className={`${draggableClassName}${snapshot.isDragging ? ' is-dragging' : ''}`}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={styles(snapshot.isDragging, provided.draggableProps.style)}
    >
      {children}
    </div>
  );

  if (!usePortal) return child;

  return ReactDOM.createPortal(child, portal);
}

PortalAware.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  provided: PropTypes.shape().isRequired,
  snapshot: PropTypes.shape({
    isDragging: PropTypes.bool,
  }).isRequired,
  portalClassName: PropTypes.string,
  draggableClassName: PropTypes.string,
};

PortalAware.defaultProps = {
  portalClassName: 'drag-portal',
  draggableClassName: 'drag-item',
};
