import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EventInputs from './EventInputs';

function InterviewFormEventInputs({
  inputs,
  formData,
  onChange,
  availableSlots,
  activeAccountId,
  errors,
  onOptionLoad,
  isTouch,
}) {
  return (
    <>
      <EventInputs
        activeAccountId={activeAccountId}
        availableSlots={availableSlots}
        configs={inputs.filter(({ id }) => !/^(sendSms|reminderDays)$/.test(id))}
        errors={errors}
        formData={formData}
        isTouch={isTouch}
        onChange={onChange}
        onOptionLoad={onOptionLoad}
      />
      <div className="d-flex">
        <EventInputs
          activeAccountId={activeAccountId}
          availableSlots={availableSlots}
          configs={inputs.filter(({ id }) => /^(sendSms|reminderDays)$/.test(id))}
          errors={errors}
          formData={formData}
          isTouch={isTouch}
          onChange={onChange}
          onOptionLoad={onOptionLoad}
        />
      </div>
    </>
  );
}

InterviewFormEventInputs.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.shape()),
  formData: PropTypes.shape(),
  onChange: PropTypes.func,
  availableSlots: PropTypes.number,
  errors: PropTypes.shape(),
  onOptionLoad: PropTypes.func,
  isTouch: PropTypes.bool,
};

InterviewFormEventInputs.defaultProps = {
  inputs: [],
  formData: {},
  onChange: () => {},
  availableSlots: undefined,
  errors: {},
  onOptionLoad: () => {},
  isTouch: false,
};

export default InterviewFormEventInputs;
