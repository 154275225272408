// eslint-disable-next-line import/prefer-default-export
export const industries = [
  { value: 'Admin/Secretarial', label: 'Admin/Secretarial' },
  { value: 'Agriculture/Conservation', label: 'Agriculture/Conservation' },
  { value: 'Arts/Music/Literature', label: 'Arts/Music/Literature' },
  { value: 'Catering/Hospitality/Leisure', label: 'Catering/Hospitality/Leisure' },
  { value: 'Charity/Not for Profit/Fundraising', label: 'Charity/Not for Profit/Fundraising' },
  { value: 'Childcare/Care Work', label: 'Childcare/Care Work' },
  { value: 'Construction/Maintenance Services/Trades', label: 'Construction/Maintenance Services/Trades' },
  { value: 'Customer Service/Call Centre', label: 'Customer Service/Call Centre' },
  { value: 'Defence/Security', label: 'Defence/Security' },
  { value: 'Education', label: 'Education' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Health/Medical', label: 'Health/Medical' },
  { value: 'IT', label: 'IT' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Manufacturing/Industrial', label: 'Manufacturing/Industrial' },
  { value: 'Media/New Media', label: 'Media/New Media' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Promotions/Events', label: 'Promotions/Events' },
  { value: 'Real Estate/Property', label: 'Real Estate/Property' },
  { value: 'Recruitment', label: 'Recruitment' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Transport/Logistics', label: 'Transport/Logistics' },
];
