import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { api, scheduling } = config;

export async function inviteToInterview(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${scheduling.sendInvitePath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function inviteBooking(id) {
  if (id) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${scheduling.bookingPath}/${id}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateInviteStatus(status = '', inviteIds = []) {
  if (status.length && inviteIds.length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${scheduling.inviteStatusPath}`,
      { inviteIds, status },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function cancelInvite(inviteId, emailDetails = {}) {
  if (inviteId) {
    const result = await axios.delete(
      `${api.middlewareAPIURL}${scheduling.cancelInvitePath}`,
      {
        headers: getCommonHeaders(),
        data: {
          inviteId,
          ...(Object.keys(emailDetails).length ? { emailDetails } : {}),
        },
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getRoomDetails(roomId) {
  if (roomId) {
    const result = await axios.get(
      `${api.middlewareAPIURL}${scheduling.joinVideoMeetingPath}?roomId=${roomId}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function checkConnections(roomId) {
  if (roomId) {
    const result = await axios.get(
      `${api.jsAPIURL}${scheduling.participantCheckPath}?roomId=${roomId}`,
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
