import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

// eslint-disable-next-line import/prefer-default-export
export async function getCandidateNotifications(id, fromDate, order = 'ASC') {
  const result = await axios.get(
    `${config.api.middlewareAPIURL}${config.applications.applicantNotificationPath}/${id}?fromDate=${fromDate}&order=${order}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}
