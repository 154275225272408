import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';

function JobBoardEntry({ logo, name, description, width, onChange, alreadyPublished, id, children, canToggle }) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(alreadyPublished);
  }, [alreadyPublished]);

  return (
    <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
      <EnhancedCard className="mb-4 mt-2">
        <div className="d-flex align-items-center">
          <img src={logo} alt={`${name} logo`} height="60" />
          <div className="ms-4">
            <h6>{name}</h6>
            <p>{description}</p>
          </div>
          {canToggle && (
            <div class="form-check form-switch mt-2  flex-grow-1">
              <input
                id={`toggle-details-for-${name ? name.replace(' ', '') : ''}`}
                class="text-muted rememberme-switch form-check-input float-end"
                type="checkbox"
                checked={isSelected}
                onChange={() => {
                  setIsSelected(!isSelected);
                  onChange(id, !isSelected);
                }}
              />
            </div>
          )}
        </div>
        {isSelected && children && (
          <Fragment>
            <hr />
            <Row className="mt-3">
              <Col>{children}</Col>
            </Row>
          </Fragment>
        )}
      </EnhancedCard>
    </div>
  );
}

JobBoardEntry.propTypes = {
  canToggle: PropTypes.bool,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  width: PropTypes.string,
  alreadyPublished: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

JobBoardEntry.defaultProps = {
  canToggle: true,
  name: null,
  description: null,
  onChange: () => {},
  id: null,
  width: 'auto',
  alreadyPublished: false,
  children: null,
};

export default JobBoardEntry;
