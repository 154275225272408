/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import { LoadingInput } from '../../../Loading';
import config from '../../../../../config/config';
import { countryList } from '../../../../../js/model/other/countries';
import { getCommonHeaders } from '../../../../../api/common-api-utils';
import ManualAddress from './ManualAddress';
import getFormattedAddress from './getFormattedAddress';

const { api } = config;

// Use your imagination to render suggestions.
let timeoutId = 0;

function renderSuggestion(suggestion) {
  return <span>{getFormattedAddress(suggestion)}</span>;
}

class AddressLookup extends Component {
  constructor(props) {
    super(props);

    const {
      uiSchema: { defaultCountry },
    } = this.props;

    this.state = {
      country: defaultCountry ? { value: defaultCountry, label: '' } : { value: 'GB', label: 'United Kingdom' },
      suggestions: [],
      manualAddressInputVisible: false,
      value: '',
      // selectedSuggestion: undefined,
      loadingResults: false,
    };

    this.lookupAddress = this.lookupAddress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleManualChange = this.handleManualChange.bind(this);
    this.delayLookup = this.delayLookup.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  componentDidMount() {
    const {
      formData: { poiName, addressLine1, addressLine2, addressLine3, city, county, country, postCode, lat, long },
    } = this.props;

    this.setState({
      value: getFormattedAddress({
        poiName,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        county,
        country,
        postCode,
        lat,
        long,
      }),
    });
  }

  handleSuggestionSelected(event, suggestion) {
    const { onChange } = this.props;

    const data = suggestion.suggestion;

    const addObj = {
      addressLine1: `${data.addressLine1 || ''} ${data.addressLine2 || ''}`,
      addressLine2: data.addressLine3,
      addressLine3: '',
      buildingName: data.poiName,
      city: data.city,
      country: data.country,
      county: data.county,
      formattedAddress: event.target.innerText,
      lat: data.lat,
      long: data.long,
      postCode: data.postCode,
    };

    onChange(addObj);
  }

  handleInputChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  handleManualChange(addressObj) {
    const { onChange } = this.props;
    const updated = {
      ...addressObj,
      formattedAddress: getFormattedAddress({
        ...addressObj,
        poiName: addressObj.buildingName,
      }),
    };

    onChange(updated);
  }

  onCountryChange(countrySelection) {
    const { onChange } = this.props;
    onChange({});

    this.setState(
      {
        // selectedSuggestion: undefined,
        country: countrySelection,
        value: '',
      },
      () => {
        this.lookupAddress(true);
      },
    );
  }

  lookupAddress() {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange({});

    if (value.length > 3) {
      this.setState({ loadingResults: true });

      const qs = `?address=${value}&country=GB,IE,US,FR,DE,HK,NL,ES,JP,IT,KR,AE,DK,QA`;

      axios
        .get(`${api.middlewareAPIURL}${api.addressLookupURL}${qs}`, { headers: getCommonHeaders() })
        .then((res) => {
          this.setState({ loadingResults: false, suggestions: res.data });
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            this.setState({
              // showAdditionalAdd: true,
              // addressNotFound: true,
              loadingResults: false,
            });
          } else {
            this.setState({
              // showAdditionalAdd: true,
              // error: true,
              loadingResults: false,
            });
          }
        });
    }
  }

  delayLookup() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(this.lookupAddress, 250);
  }

  renderInput(props) {
    const { loadingResults } = this.state;

    return (
      <span className="address-lookup">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...props} />
        <LoadingInput loading={loadingResults} />
      </span>
    );
  }

  render() {
    const {
      formData,
      uiSchema: { hideCountry, 'ui:options': uiOpts = {} },
    } = this.props;
    const { value, suggestions, country, manualAddressInputVisible } = this.state;

    const inputProps = {
      placeholder: 'Start typing your address',
      value,
      onChange: this.handleInputChange,
      autoComplete: 'fgdsg3485093u5',
      autofill: 'off',
    };

    return (
      <div className="form-group">
        {!hideCountry && (
          <div className="form-group field field-string">
            <label htmlFor="country">Country</label>
            <Select
              classNamePrefix="react-select"
              defaultValue={country}
              id="country"
              onChange={this.onCountryChange}
              options={countryList}
              value={country}
            />
          </div>
        )}
        <div className="form-group field field-string">
          <ManualAddress
            id="address-lookup"
            onChange={this.handleManualChange}
            onToggle={(visible) => this.setState({ manualAddressInputVisible: visible })}
            value={formData}
          />
          {!manualAddressInputVisible && (
            <>
              <label htmlFor="autolookup">
                Address
                <span className="required">*</span>
              </label>
              <Autosuggest
                getSuggestionValue={getFormattedAddress}
                id="autolookup"
                inputProps={inputProps}
                onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
                onSuggestionSelected={this.handleSuggestionSelected}
                onSuggestionsFetchRequested={this.delayLookup}
                renderInputComponent={this.renderInput}
                renderSuggestion={renderSuggestion}
                suggestions={suggestions}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

AddressLookup.propTypes = {
  formData: PropTypes.shape(),
  onChange: PropTypes.func,
  uiSchema: PropTypes.shape().isRequired,
};

AddressLookup.defaultProps = {
  formData: {},
  onChange: () => {},
};

export default AddressLookup;
