import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { api, scheduling } = config;

export async function getAvailableSlotCount(eventTypeId, accountId) {
  const result = await axios.get(
    `${api.middlewareAPIURL}${scheduling.slotPath}${scheduling.availableSlotPath}?eventTypeId=${eventTypeId}&accountId=${accountId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
