import React from 'react';
import PropTypes from 'prop-types';
import EmailPillsInput from './EmailPillsInput';

function EmailPills({ value, onChange }) {
  return (
    <EmailPillsInput
      value={value.length ? value.split(',') : []}
      onChange={(emails) => onChange(emails.join(','))}
    />
  );
}

EmailPills.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

EmailPills.defaultProps = {
  value: '',
  onChange: () => { },
};

export default EmailPills;
