// eslint-disable-next-line no-unused-vars
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';
import kebabCase from 'lodash.kebabcase';
import ReactGA from 'react-ga4';
import { deleteFilter } from '../../../js/actions/filterActions';
// eslint-disable-next-line import/no-cycle
import { CheckboxFilter, ActiveFilter, AddFilter } from './components';
import { addTempFilter, removeTempFilter } from './filter-utils';
import IconSVG from '../SVG/IconSVG';
import { PRIMARY_COLOUR } from '../../../js/utils/colours';
import { checkPermissions } from '../../../js/auth/AuthUtils';

class FilterContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addFilterClicked: false,
      tempFilters: {},
      // updated: true,
    };
  }

  handleFilterRemove = (instanceId) => {
    const { filterName, applyDeleteFilter } = this.props;

    const { tempFilters } = this.state;

    applyDeleteFilter(filterName, instanceId);
    this.setState({
      tempFilters: removeTempFilter(tempFilters, { filterName, instanceId }),
    });
    // this.setState({ updated: this.state.updated });
  };

  handleFilterAdd = (event) => {
    const { filterData, filterName } = this.props;
    const { tempFilters } = this.state;

    const option = {
      ...filterData.find((opt) => opt.id === event.currentTarget.dataset.id),
    };

    if (option) {
      ReactGA.event({
        action: 'FILTER_CLICK',
        category: 'FILTER_ACTION',
        label: `FILTER_${option.label?.replaceAll(' ', '_')?.toUpperCase()}_CLICK`,
      });
      this.setState({
        tempFilters: addTempFilter(tempFilters, filterName, {
          ...option,
          filterName,
          instanceId: uuidv1(),
          isNew: true,
        }),
      });
    }

    this.setState({ addFilterClicked: false });
  };

  render() {
    const { activeFilters, categories, filterName, filterData, statusFilterAsDefault, emptyStateButton } = this.props;

    const { addFilterClicked, tempFilters } = this.state;

    const compiledFilters = [...(activeFilters[filterName] || []), ...(tempFilters[filterName] || [])];

    const filtersToDisplay = compiledFilters.map((compiledFilter) => {
      if (compiledFilter.field !== 'positionProfile.category') {
        return compiledFilter;
      }

      return { ...compiledFilter, data: categories };
    });

    const filters = filtersToDisplay
      .filter((filter) => !/Checkbox|Funnel/.test(filter.type))
      .map((filter) => (
        <ActiveFilter
          key={`filter_${filter.instanceId}`}
          filter={filter}
          filterName={filterName}
          onSave={(filterObj) => {
            ReactGA.event({
              action: `FILTER_SAVE`,
              category: 'FILTER_ACTION',
              label: `FILTER_${filterObj.label?.replaceAll(' ', '_')?.toUpperCase()}_SAVE`,
            });
            this.setState({
              tempFilters: removeTempFilter(tempFilters, filterObj),
            });
          }}
          removeAction={this.handleFilterRemove}
        />
      ));

    return (
      <>
        {filters}
        {addFilterClicked && (
          <div className="filter-selector">
            <div className="filter-selector-header d-flex">
              <p className="me-auto">Filter</p>
              <button className="close" onClick={() => this.setState({ addFilterClicked: false })} type="button">
                <IconSVG className="filter-close-icon" fill={PRIMARY_COLOUR} name="Close Circle" width="0.7em" />
              </button>
            </div>
            <ul className="list-unstyled m-0">
              {filterData.map(({ id, icon, label, field, notMenuItem, type }) => {
                if (
                  type === 'Status' &&
                  !checkPermissions(['candidate:onboarding:read', 'candidate:references:view', 'rtw:read'])
                ) {
                  return null;
                }

                return (
                  <Fragment key={`${id}-${field}`}>
                    {!notMenuItem && (
                      <li>
                        <div
                          data-id={id}
                          onClick={this.handleFilterAdd}
                          onKeyUp={this.handleFilterAdd}
                          role="button"
                          tabIndex={0}
                        >
                          {icon && <IconSVG className={kebabCase(icon)} name={icon} />}
                          {label}
                        </div>
                      </li>
                    )}
                  </Fragment>
                );
              })}
            </ul>
          </div>
        )}
        {statusFilterAsDefault && <CheckboxFilter {...this.props} removeFilter={this.handleFilterRemove} />}
        {filters.length === 0 && emptyStateButton && !addFilterClicked ? (
          emptyStateButton(() => {
            ReactGA.event({
              action: 'FILTER_CLICK',
              category: 'FILTER_ACTION',
              label: 'ADD_NEW_FILTER_CLICK',
            });
            this.setState({ addFilterClicked: !addFilterClicked });
          })
        ) : (
          <AddFilter
            handleClick={() => {
              ReactGA.event({
                action: 'FILTER_CLICK',
                category: 'FILTER_ACTION',
                label: 'ADD_NEW_FILTER_CLICK',
              });
              this.setState({ addFilterClicked: !addFilterClicked });
            }}
          />
        )}
      </>
    );
  }
}

FilterContainer.propTypes = {
  activeFilters: PropTypes.shape(),
  applyActiveFilter: PropTypes.func,
  applyDeleteFilter: PropTypes.func,
  categories: PropTypes.arrayOf(),
  emptyStateButton: PropTypes.func,
  filterData: PropTypes.arrayOf(PropTypes.shape()),
  filterName: PropTypes.string,
  statusFilterAsDefault: PropTypes.bool,
};

FilterContainer.defaultProps = {
  activeFilters: {},
  applyActiveFilter: () => {},
  applyDeleteFilter: () => {},
  categories: [],
  emptyStateButton: null,
  filterData: [],
  filterName: null,
  statusFilterAsDefault: false,
};

function mapStateToProps(state) {
  const {
    categoryReducer: { categories },
    filters: { activeFilters },
  } = state;

  return {
    activeFilters,
    categories: categories.map((category) => ({
      label: category.label,
      value: category.id,
    })),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applyDeleteFilter: (filterName, instanceId) => {
      dispatch(
        deleteFilter({
          filterName,
          instanceId,
        }),
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
