import PropTypes from 'prop-types';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import DropdownManager from './DropdownManager';
import SubMenuContext from './SubMenuContext';
import Can from '../RBAC/Can/Can';
import IconSVG from '../SVG/IconSVG';

const RestrictedTippy = Can(Tippy);

function MenuItem({
  id,
  children,
  icon,
  iconPosition,
  triggerRef,
  onClick,
  action,
  className,
  isDisabled,
  isHidden,
  permissions,
  tooltipContent,
}) {
  let iconLeft = true;
  if (iconPosition === 'right') iconLeft = false;

  if(isHidden) return null

  return (
    <RestrictedTippy
      content={tooltipContent}
      disabled={!isDisabled || !tooltipContent.length}
      maxWidth={150}
      permissions={permissions}
      theme="ats"
    >
      <div
        ref={triggerRef}
        className={cx('dd-menu-item', className, { 'is-disabled': isDisabled })}
        data-id={id}
        onClick={isDisabled ? () => {} : action || onClick}
        onKeyUp={isDisabled ? () => {} : action || onClick}
        role="button"
        tabIndex={0}
      >
        {icon && iconLeft && <IconSVG name={icon} />}
        {children && <span>{children}</span>}
        {icon && !iconLeft && <IconSVG name={icon} />}
      </div>
    </RestrictedTippy>
  );
}

MenuItem.propTypes = {
  action: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  triggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
};

MenuItem.defaultProps = {
  action: null,
  children: null,
  className: null,
  icon: null,
  iconPosition: null,
  isDisabled: false,
  onClick: () => {},
  permissions: [],
  tooltipContent: '',
  triggerRef: null,
};

export default function DropDownItem({ subMenuTitle, subMenuClassName, subMenuComponent, subItems, ...rest }) {
  if (subItems || subMenuComponent) {
    return (
      <SubMenuContext.Consumer>
        {({ menuStyle, position, title, className, onClick, showSubMenuId }) => (
          <DropdownManager
            menuClassName={cx(subMenuClassName, className)}
            menuComponent={subMenuComponent}
            menuItems={subItems}
            menuStyle={menuStyle}
            position={position}
            showMenu={showSubMenuId === rest.id}
            title={subMenuTitle || title}
            trigger={({ ref }) => <MenuItem {...rest} onClick={() => onClick(rest.id)} triggerRef={ref} />}
          />
        )}
      </SubMenuContext.Consumer>
    );
  }

  return <MenuItem {...rest} />;
}

DropDownItem.propTypes = {
  subItems: PropTypes.arrayOf(PropTypes.shape()),
  subMenuClassName: PropTypes.string,
  subMenuComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subMenuTitle: PropTypes.string,
};

DropDownItem.defaultProps = {
  subItems: null,
  subMenuClassName: null,
  subMenuComponent: null,
  subMenuTitle: null,
};
