import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import OtherApplication from './OtherApplication';
import { useLanguagePack } from '../../Base/hooks';

function OtherApplicationsContainer({ otherApps }) {
  const languagePack = useLanguagePack('other-applications');

  return (
    <Card className="other-applications">
      <CardBody>
        <Row>
          <Col>
            <h4 className="mb-0 me-auto">Other Applications</h4>
          </Col>
        </Row>
        <Row>
          <Col className="mt-4">
            {otherApps.map((app, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`app-${i}`} className="position-relative">
                <OtherApplication languagePack={languagePack} app={app} />
                {/* don't put after last application */}
                {i + 1 !== otherApps.length && <hr />}
              </div>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

OtherApplicationsContainer.propTypes = {
  otherApps: PropTypes.arrayOf(PropTypes.shape()),
};

OtherApplicationsContainer.defaultProps = {
  otherApps: [],
};

export default OtherApplicationsContainer;
