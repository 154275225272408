import {
  ADD_TAG,
  DELETE_TAGS,
  SET_ALL_TAGS,
  EDIT_TAG,
} from '../constants/actionTypes';

export function addTag(tagObj) {
  return {
    type: ADD_TAG,
    payload: tagObj,
  };
}

export function deleteTags(ids) {
  return {
    type: DELETE_TAGS,
    payload: ids,
  };
}

export function setAllTags(tags) {
  return {
    type: SET_ALL_TAGS,
    payload: tags,
  };
}

export function editTag(tagId, tagName) {
  return {
    type: EDIT_TAG,
    payload: { tagId, tagName },
  };
}
