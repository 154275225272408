/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap';
import { forgotPassword } from '../../../../api/AuthAPI/AuthAPI';
import { handleErrors, retryableAPICall } from '../../../../api/common-api-utils';
import { ResolveLogo } from '../../../Base/SVG';
import { useFormKeypress } from '../../../Base/hooks';
import validation from '../../../../js/utils/validation';
import ErrorFeedback from '../../../Base/ErrorFeedback/ErrorFeedback';

function isInvalid(value, cb = () => {}) {
  const errObj = validation([{ id: 'email', type: 'email', required: true }], {
    email: value,
  });
  const hasErrors = Object.values(errObj).some(({ invalid, errors }) => {
    cb(errors.length ? errors[0] : '');
    return invalid;
  });

  return hasErrors;
}

function ForgotPasswordEmail() {
  const formRef = useFormKeypress();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleChange(value) {
    if (isSubmitted) isInvalid(value, setErrorMsg);
    setEmailAddress(value);
  }

  async function handleSubmit() {
    setIsSubmitted(true);
    setIsLoading(true);

    const hasErrors = isInvalid(emailAddress, setErrorMsg);

    if (!hasErrors) {
      const resp = await retryableAPICall(() => forgotPassword(emailAddress));

      if (typeof result === 'string') {
        setIsSuccess(false);
        handleErrors(
          resp,
          () => {},
          () => {
            setIsServerError(true);
          },
        );
      } else {
        setIsServerError(false);
        setIsSuccess(true);
      }
    }

    setIsLoading(false);
  }

  return (
    <div className="app flex-row align-items-center auth-page login-background-image">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={7} sm={10} xl={5} xs={12}>
            <CardGroup>
              <Card className="p-4 login-box-bg">
                <CardBody>
                  <div className="text-center mb-4">
                    <ResolveLogo loginPage />
                  </div>
                  {isSuccess ? (
                    <p>
                      {`An email has been sent to ${emailAddress} containing password reset instructions. `}
                      <a href="/login">Click here to login</a>
                    </p>
                  ) : (
                    <Form innerRef={formRef}>
                      <p className="text-muted text-center">
                        Please enter the email address you registered with to start the reset process
                      </p>
                      {isServerError && (
                        <p className="text-danger text-center">
                          {isServerError &&
                            'There has been an error when resetting your password, please check your details and try again. If this continues please contact support.'}
                          {/* isOfflineError && ('You appear to be offline, or the service may be experiencing an outage, please check your internet connection and try again.') */}
                        </p>
                      )}
                      <InputGroup className="mt-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="icon-user" />
                          </span>
                        </div>
                        <Input
                          id="email"
                          name="email"
                          onChange={(e) => handleChange(e.target.value)}
                          placeholder="joe@bloggs.com"
                          type="email"
                        />
                      </InputGroup>
                      <ErrorFeedback message={errorMsg} />
                      <Button
                        className="px-4 mt-3"
                        disabled={isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        type="submit"
                      >
                        {!isLoading ? 'Reset Password' : 'Loading...'}
                      </Button>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPasswordEmail;
