// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { FunnelList } from './components';
import { addActiveFilter, updateActiveFilter, deleteFilter } from '../../../js/actions/filterActions';

function FunnelFilter({
  funnelId,
  resultAggs,
  vacancyId,
  filterName,
  updateFilters,
  onFilterUpdate,
  activeFilters,
  updateFilter,
  addFilter,
  applyDeleteFilter,
  onChange,
  isSelectDropdown,
}) {
  const filters = activeFilters[filterName] || [];
  const funnelFilter = filters.reduce((acc, filter) => (filter.type === 'Funnel' ? filter : acc), {});

  function handleChange(stages) {
    if (!stages.length) {
      if (Object.keys(funnelFilter).length) {
        applyDeleteFilter(filterName, funnelFilter.instanceId);
        onChange('DELETE');
      }
    } else {
      const stageIds = stages.map((stage) => stage.id);
      const stageLabels = stages.map((stage) => stage.label);

      const filterObj = {
        displayOperation: 'Funnel Stage',
        displayValue: stageLabels.join(', '),
        field: 'funnelStageId',
        filterName,
        operation: 'IN',
        type: 'Funnel',
        value: stageIds,
      };

      if (Object.keys(funnelFilter).length) {
        updateFilter({
          ...funnelFilter,
          ...filterObj,
          isNew: false,
        });
        onChange('UPDATE', filterObj.displayValue);
      } else {
        addFilter({
          ...filterObj,
          instanceId: uuidv1(),
          isNew: true,
        });
        onChange('ADD', filterObj.displayValue);
      }
    }
  }

  return (
    <div className="funnel-filter">
      <FunnelList
        funnelId={funnelId}
        isSelectDropdown={isSelectDropdown}
        onChange={handleChange}
        onFilterUpdate={onFilterUpdate}
        resultAggs={resultAggs}
        stageIds={funnelFilter.value}
        updateFilters={updateFilters}
        vacancyId={vacancyId}
      />
    </div>
  );
}

FunnelFilter.propTypes = {
  activeFilters: PropTypes.shape(),
  addFilter: PropTypes.func,
  applyDeleteFilter: PropTypes.func,
  filterName: PropTypes.string,
  funnelId: PropTypes.string,
  isSelectDropdown: PropTypes.bool,
  onChange: PropTypes.func,
  onFilterUpdate: PropTypes.func,
  resultAggs: PropTypes.arrayOf(PropTypes.shape()),
  updateFilter: PropTypes.func,
  updateFilters: PropTypes.bool,
  vacancyId: PropTypes.string,
};

FunnelFilter.defaultProps = {
  activeFilters: {},
  addFilter: () => {},
  applyDeleteFilter: () => {},
  filterName: null,
  funnelId: null,
  isSelectDropdown: false,
  onChange: () => {},
  onFilterUpdate: () => {},
  resultAggs: [],
  updateFilter: () => {},
  updateFilters: false,
  vacancyId: null,
};

function mapStateToProps(state) {
  const {
    filters: { activeFilters },
  } = state;
  return { activeFilters };
}

function mapDispatchToProps(dispatch) {
  return {
    addFilter: (filter) => {
      dispatch(addActiveFilter(filter));
    },
    applyDeleteFilter: (filterName, instanceId) => {
      dispatch(
        deleteFilter({
          filterName,
          instanceId,
        }),
      );
    },
    updateFilter: (filter) => {
      dispatch(updateActiveFilter(filter));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FunnelFilter);
