import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import FunnelSelectInputs from '../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import EmailTemplateSelect from './EmailTemplateSelect';
import {
  delayTypes,
  eventActions,
  onboardingActions,
  funnelActions,
  refereeActions,
  referenceActions,
  rtwActions,
  eDocActions,
  flexiFormActions,
  personalityTestActions,
  payrollSubmissionActions
} from './options-schemas';
import { DeleteButton } from '../../../Base/Buttons';
import WorkflowSelect from './WorkflowSelect';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import { IconSVG } from '../../../Base/SVG';
import FormsSelector from '../../../Applications/Other/FormsSelector';

export const defaultEmailTemplateConfig = {
  id: '',
};

export const defaultFunnelStageConfig = {
  funnelId: '',
  stageId: '',
};

export const defaultAction = {
  delay: 0,
  type: '',
  emailTemplateConfig: { ...defaultEmailTemplateConfig },
  funnelStageConfig: { ...defaultFunnelStageConfig },
};

function WorkflowAction({ id, data, workflowType, onChange, errors, canDelete, onDelete }) {
  function handleChange(inpId, value) {
    let updated = { ...data, [inpId]: value };

    if (inpId === 'type') {
      updated = {
        ...updated,
        emailTemplateConfig: { ...defaultEmailTemplateConfig },
        funnelStageConfig: { ...defaultFunnelStageConfig },
      };
    }

    onChange(updated);
  }

  let actionTypeOptions = [...funnelActions]
    .filter(
      (action) =>
        (action.value === 'SEND_TO_UBEYA' && checkPermissions(['ubeya:admin'])) || action.value !== 'SEND_TO_UBEYA',
    )
    .filter(
      (action) =>
        (action.value === 'REQUEST_RTW' && checkPermissions(['rtw:create'])) || action.value !== 'REQUEST_RTW',
    )
    .filter(
      (action) =>
        (action.value === 'REQUEST_EDOC' && checkPermissions(['candidate:edoc:create'])) ||
        action.value !== 'REQUEST_EDOC',
    )
    .filter(
      (action) =>
        (action.value === 'SEND_TO_SMART' && checkPermissions(['smart:write'])) || action.value !== 'SEND_TO_SMART',
    )
    .filter(
      (action) =>
        (action.value === 'SEND_TO_SAP' && checkPermissions(['sap:write'])) || action.value !== 'SEND_TO_SAP',
    )
    .filter(
      (action) =>
        (action.value === 'SEND_TO_FLOW' && checkPermissions(['flow:write'])) || action.value !== 'SEND_TO_FLOW',
    )
    .filter(
      (action) =>
        (action.value === 'REMOVE_FROM_FLOW' && checkPermissions(['flow:write'])) || action.value !== 'REMOVE_FROM_FLOW',
    )
    .filter(
      (action) =>
        (action.value === 'REQUEST_GENERIC_FORM' && checkPermissions(['candidate:genericforms:create'])) ||
        action.value !== 'REQUEST_GENERIC_FORM',
    )
    .filter(
      (action) =>
        (action.value === 'REQUEST_PERSONALITY_TEST' && checkPermissions(['pt:create', 'pth:create'])) ||
        action.value !== 'REQUEST_PERSONALITY_TEST',
    );
  if (workflowType === 'EVENT_STATUS_CHANGE') actionTypeOptions = [...eventActions];
  if (workflowType === 'ONBOARDING_STATUS_CHANGE') actionTypeOptions = [...onboardingActions];
  if (workflowType === 'GENERIC_FORM_STATUS_CHANGE') actionTypeOptions = [...flexiFormActions];
  if (workflowType === 'REFEREE_STATUS_CHANGE') actionTypeOptions = [...refereeActions];
  if (workflowType === 'EDOC_STATUS_CHANGE') actionTypeOptions = [...eDocActions];
  if (workflowType === 'REFERENCE_STATUS_CHANGE') actionTypeOptions = [...referenceActions];
  if (workflowType === 'RTW_STATUS_CHANGE') actionTypeOptions = [...rtwActions];
  if (workflowType === 'PERSONALITY_TEST_STATUS_CHANGE') actionTypeOptions = [...personalityTestActions];
  if (workflowType === 'PAYROLL_INTEGRATION_SUBMISSION') actionTypeOptions = [...payrollSubmissionActions];

  return (
    <div className={`workflow-action ${id}`}>
      <WorkflowSelect
        error={errors.type}
        id={`actionType-${id}`}
        label="Action"
        labelSibling={
          canDelete && (
            <DeleteButton
              action={onDelete}
              className="mb-2 ms-auto"
              floatRight={false}
              label="Delete Action"
              size="sm"
            />
          )
        }
        onChange={(val) => handleChange('type', val)}
        options={actionTypeOptions}
        placeholder="Choose Action"
        required
        value={data.type}
      />
      {data.type === 'UPDATE_FUNNEL_STAGE' && (
        <FunnelSelectInputs
          funnelSelectProps={{
            id: `actionFunnelId-${id}`,
            label: 'Funnel',
            required: true,
            error: errors.funnelId,
            value: data?.funnelStageConfig?.funnelId || '',
          }}
          onChange={(val) => handleChange('funnelStageConfig', val)}
          stageSelectProps={{
            id: `actionStageId-${id}`,
            label: 'Stage',
            required: true,
            error: errors.stageId,
            value: data?.funnelStageConfig?.stageId || '',
          }}
        />
      )}
      {/^(REQUEST_ONBOARDING|SEND_CONTACT_TEMPLATE_EMAIL|REQUEST_RTW|REQUEST_EDOC|REQUEST_GENERIC_FORM|REQUEST_PERSONALITY_TEST)$/.test(
        data.type,
      ) && (
        <EmailTemplateSelect
          actionType={data.type}
          error={errors.id}
          id={`emailTemplateConfig-${id}`}
          onChange={(val) => handleChange('emailTemplateConfig', { id: val })}
          required
          value={data?.emailTemplateConfig?.id || ''}
        />
      )}
      {/^(REQUEST_EDOC|REQUEST_GENERIC_FORM)$/.test(data.type) && (
        <>
          <FormsSelector
            label={data.type === 'REQUEST_EDOC' ? 'Select Document' : 'Select Form'}
            onChange={(vals, values) => {
              const docIds = {};

              const mappedAllValues = values.map((val) => {
                return { id: val.value, label: val.label, value: val.value };
              });

              mappedAllValues.forEach((val) => {
                docIds[val.id] = val.label;
              });
              handleChange('docIds', docIds);
            }}
            selectedValues={
              data?.docIds
                ? Object.entries(data.docIds).map(([value, label]) => {
                    return { id: value, label, value };
                  })
                : []
            }
            type={data.type === 'REQUEST_EDOC' ? 'EDOC' : 'GENERIC'}
          />
          {data.type === 'REQUEST_EDOC' && (
            <p style={{ color: 'rgba(5,39,119,0.73)' }}>
              <IconSVG name="Info Circle" />
              <span style={{ marginLeft: '5px' }}>
                Only documents with standard placeholders can be automated. If any documents contain custom placeholders
                the workflow will not be executed.
              </span>
            </p>
          )}
        </>
      )}
      <WorkflowSelect
        error={errors.delay}
        id={`delay-${id}`}
        label="When"
        onChange={(val) => handleChange('delay', val)}
        options={delayTypes}
        placeholder="Choose Delay"
        required
        value={data.delay}
      />
    </div>
  );
}

WorkflowAction.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape(),
  workflowType: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.shape(),
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

WorkflowAction.defaultProps = {
  id: null,
  data: {},
  workflowType: null,
  onChange: () => {},
  errors: {},
  canDelete: true,
  onDelete: () => {},
};

export default WorkflowAction;
