import React from 'react';
import PropTypes from 'prop-types';
import NotificationContent from './NotificationContent';
import { downloadFiles } from '../../api/FileAPI/FileAPI';
import { DANGER } from '../../js/utils/colours';

function NotificationItem({ notification, languagePack, isViewedMode }) {
  const {
    eventType,
    metadata,
    vacancyId,
  } = notification;

  function getNotificationContent(icon, action, isLink = true, iconStyle) {
    return (
      <NotificationContent
        notification={notification}
        languagePack={languagePack}
        isViewedMode={isViewedMode}
        icon={icon}
        isLink={isLink}
        action={action}
        iconStyle={iconStyle}
      />
    );
  }

  return (
    <div className="notification-item-container">
      {(eventType === 'REFEREES_RECEIVED' || eventType === 'REFERENCE_RECEIVED') && metadata && (
        getNotificationContent(
          'Comment',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=references`),
        )
      )}
      {eventType === 'APPLICATION' && (
        getNotificationContent('Paper Clip', () => window.open(`/vacancy/view?id=${vacancyId}`))
      )}
      {eventType === 'ONBOARDING_RECEIVED' && metadata && (
        getNotificationContent(
          'File',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=onboarding`),
        )
      )}
      {eventType === 'SCHEDULING_BOOKED_INTO_SLOT' && metadata && metadata.slotData && (
        getNotificationContent(
          'Calendar',
          () => window.open(`/schedule?action=openSlider&actionId=${metadata.slotData.slotId}`),
        )
      )}
      {eventType === 'SCHEDULING_CANDIDATE_CANCELLED_BOOKING' && metadata && (
        getNotificationContent(
          'Close Circle',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=appDetails`),
        )
      )}
      {eventType === 'SCHEDULING_CANDIDATE_NO_SLOTS' && metadata && (
        getNotificationContent('Eye Slash', () => window.open('/schedule'))
      )}
      {eventType === 'APPLICATION_EXPORT' && metadata && (
        getNotificationContent('File', () => downloadFiles([metadata.fileId]))
      )}
      {eventType === 'APPLICATION_EXPORT_FAILED' && (
        getNotificationContent('Exclamation Circle', () => {}, false, { color: DANGER })
      )}
      {eventType === 'FOURTH_SUBMISSION_SUCCESS' && (
        getNotificationContent(
          'User',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=onboarding`),
        )
      )}
      {eventType === 'FOURTH_SUBMISSION_ERROR' && (
        getNotificationContent(
          'Exclamation Circle',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=onboarding`),
          true,
          { color: DANGER },
        )
      )}
      {eventType === 'POLARIS_SETUP_ERROR' && (
        getNotificationContent(
          'Exclamation Circle',
          () => window.open('/settings/polaris'),
          true,
          { color: '#ea0909' },
        )
      )}
      {eventType === 'RTW_CHECK_RECEIVED' && (
        getNotificationContent(
          'File',
          () => window.open(`/candidate?action=openSlider&actionId=${metadata.candidateId}&actionTab=rtw`),
        )
      )}
    </div>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  isViewedMode: PropTypes.bool,
};

NotificationItem.defaultProps = {
  notification: {},
  languagePack: {},
  isViewedMode: false,
};

export default NotificationItem;
