import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import moment from 'moment';
import { getValueInputType } from '../utils';
import LogicMultiOptions from './LogicMultiOptions';
import { DatePickerInput } from '../../../../Base/Forms/Custom/DatePicker';

function LogicValueInput({ type, condition, multiOptions, value, onChange }) {
  const inputType = getValueInputType(type);

  function handleChange(val) {
    onChange(val);
  }

  if (inputType === 'date') {
    const dateVal = !Array.isArray(value) && value && value.length ? moment(value, 'YYYY-MM-DD') : undefined;

    return (
      <DatePickerInput
        id="logic-date-picker"
        value={dateVal}
        onChange={(date) => handleChange(date ? date.format('YYYY-MM-DD') : undefined)}
        showClearDate
        readOnly
        appendToBody
        withPortal
        pastDates
      />
    );
  }

  if (inputType === 'text' && condition !== 'Has answered') {
    const strVal = Array.isArray(value) ? '' : value;
    return <Input value={strVal} onChange={(e) => handleChange(e.target.value)} />;
  }

  if (inputType === 'multi' || inputType === 'visaDetails') {
    const arrVal = Array.isArray(value) ? value : [];
    return (
      <LogicMultiOptions
        identifier={inputType === 'visaDetails' ? 'id' : 'text'}
        onChange={(optArr) => handleChange(optArr)}
        options={multiOptions}
        value={arrVal}
      />
    );
  }

  return null;
}

LogicValueInput.propTypes = {
  type: PropTypes.string,
  condition: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  multiOptions: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
};

LogicValueInput.defaultProps = {
  type: null,
  condition: null,
  value: '',
  multiOptions: [],
  onChange: () => {},
};

export default LogicValueInput;
