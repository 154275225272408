import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardBody, Modal, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { TextButton, ActionButton } from '../../Base/Buttons';
import { retryableAPICall } from '../../../api/common-api-utils';
import {
  downloadOnboarding,
  getOnboardingDetails,
  sendOnboardingReminder,
} from '../../../api/CandidateAPI/OnboardingAPI';
import { requestStatuses } from '../../../js/constants/requestStatuses';
import { EmailForm } from '../../Communication';
import MappedAnswerView from '../Candidates/MappedAnswerView/MappedAnswerView';
import { FormModal } from '../../Base/Modal';
import config from '../../../config/config';
import { getFlexiFormViewIdsByType } from '../../../api/FormsAPI/ATSFormsAPI';
import Can from '../../Base/RBAC/Can/Can';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import {
  FourthForm,
  PolarisForm,
  S4Form,
  SAPForm,
  AccessForm,
  SonaForm,
  RotaReadyForm,
  SAPNLForm,
  PeopleHRForm,
  TamigoForm,
  NoryForm,
} from './IntegrationComponents';
import { LoadingScreen } from '../../Base/Loading';
import { useLanguagePack, useMounted } from '../../Base/hooks';
import { HeadingPushRight } from '../../Base/Headings';
import SmartIntegration from './IntegrationComponents/SmartIntegration';
import UbeyaIntegration from './IntegrationComponents/UbeyaIntegration';
import { generateToken } from './utils';
import AnalyticsFlowChart from '../../Base/FlowCharts/AnalyticsFlowChart';
import { searchAnalytics } from '../../../api/AnalyticsAPI/AnalyticsAPI';
import { handlePayrollSubmitTo } from '../../../api/PayrollAPI/PayrollAPI';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import onboardingNotRequestImg from '../../../assets/img/nodata/onboarding_nds.png';
import personalityTest from '../../../assets/img/nodata/personality-test.png';
import waiting from '../../../assets/img/nodata/waiting.png';

const { web } = config;
const RestrictedTextButton = Can(TextButton);
const RestrictedFourthForm = Can(FourthForm);
const RestrictedSmartIntegration = Can(SmartIntegration);
const RestrictedUbeyaIntegration = Can(UbeyaIntegration);
const RestrictedSAPNLForm = Can(SAPNLForm);
const RestrictedS4Form = Can(S4Form);
const RestrictedSAPForm = Can(SAPForm);
const RestrictedPolarisForm = Can(PolarisForm);
const RestrictedAccessForm = Can(AccessForm);
const RestrictedSonaForm = Can(SonaForm);
const RestrictedRotaReadyForm = Can(RotaReadyForm);
const RestrictedPeopleHRForm = Can(PeopleHRForm);
const RestrictedTamigoForm = Can(TamigoForm);
const RestrictedNoryForm = Can(NoryForm);

function OnboardingDetails({ applicant, onError, onSuccess, onUpdate, isTouch, candidateProfileSummary, userDetails }) {
  const languagePack = useLanguagePack('onboarding-container');
  const [onBoardingDetails, setOnboardingDetails] = useState();
  const [onboardingStatus, setOnboardingStatus] = useState('notRequested');
  const [error, setError] = useState(false);
  const [infReqExpanded, setInfReqExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tenantOnboardingFormId, setTenantOnboardingFormId] = useState(undefined);
  const [onboardingActionOpen, setOnboardingActionOpen] = useState(false);
  const [onboardingPostActionOpen, setOnboardingPostActionOpen] = useState(false);
  const [sendingReminder, setSendingReminder] = useState(false);
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [journeyModalConfig, setJourneyModalConfig] = useState({ isOpen: false, url: '' });
  const isMounted = useMounted();

  const formUrl = `${web.formsWebUrl}?id=${tenantOnboardingFormId}&cid=${applicant.id}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`;

  function setStatus(status) {
    if (status === 'REQUESTED') {
      setOnboardingStatus('requested');
    } else if (status === 'RECEIVED') {
      setOnboardingStatus('received');
    } else if (status === 'REMINDER_1_SENT') {
      setOnboardingStatus('reminder1Sent');
    } else if (status === 'REMINDER_2_SENT') {
      setOnboardingStatus('reminder2Sent');
    }
  }

  function toggleReqInfo() {
    setInfReqExpanded(!infReqExpanded);
  }

  const getData = useCallback(
    async (callback = () => {}) => {
      const result = await retryableAPICall(() => getOnboardingDetails(applicant.id));
      const onboardingForm = await retryableAPICall(() => getFlexiFormViewIdsByType('ONBOARDING'));

      if (isMounted()) {
        if (typeof result === 'string') {
          if (result === requestStatuses.NOT_FOUND_ERROR) {
            const obfId = Object.keys(onboardingForm).map((id) => id);

            if (Array.isArray(obfId) && obfId.length > 0) {
              setTenantOnboardingFormId(obfId[0]);
            }

            setOnboardingStatus('notRequested');
          } else {
            setError(true);
          }
        } else {
          setStatus(result.status);
          setTenantOnboardingFormId(result.onboardingFormId);
          setOnboardingDetails(result);
        }

        setLoading(false);
        callback(result.status);
      }
    },
    [applicant.id, isMounted],
  );

  useEffect(() => {
    if (applicant && applicant.id) getData();
  }, [applicant, getData]);

  const onboardingFormExpirationDate = moment(onBoardingDetails?.statusLastUpdatedDateTime).add(1, 'year');
  const isOnboardingFormOutdated = moment().isAfter(onboardingFormExpirationDate);

  const menuItems = [
    {
      action: () => {
        generateToken(onBoardingDetails.completedFormId, (token) => {
          setModalConfig({
            isOpen: true,
            url: `${web.formsWebUrl}?token=${token}`,
          });
          setOnboardingActionOpen(false);
        });
      },
      icon: 'Pencil',
      id: 1,
      isDisabled: isOnboardingFormOutdated,
      label: languagePack.editLabel,
      permissions: ['candidate:onboarding:read'],
      tooltipContent: 'You are unable to edit records that are over 12 months old',
    },
    {
      action: async () => {
        try {
          await retryableAPICall(() => downloadOnboarding(onBoardingDetails.id));
        } catch (e) {
          toast.error(languagePack.downloadOnboardingError);
        } finally {
          setOnboardingActionOpen(false);
        }
      },
      icon: 'File',
      id: 2,
      label: languagePack.pdfDownloadLabel,
      permissions: ['candidate:onboarding:read'],
    },
    {
      action: () => {
        setJourneyModalConfig({
          isOpen: true,
        });
      },
      icon: 'Eye',
      id: 3,
      label: languagePack.viewJourneyBtn,
      permissions: ['candidate:onboarding:read'],
    },
  ];

  const preRequestMenuItems = [
    {
      action: () => {
        setOnboardingActionOpen(false);
        toggleReqInfo();
      },
      icon: 'Pencil',
      id: 1,
      isDisabled: false,
      label: languagePack.onboardingReqInfoBtn,
      permissions: ['candidate:onboarding:create'],
    },
    {
      action: async () => {
        setOnboardingActionOpen(false);
        setModalConfig({
          isOpen: true,
          url: formUrl,
        });
      },
      icon: 'File',
      id: 2,
      label: languagePack.onboardingCompleteBtn,
      permissions: ['candidate:onboarding:create'],
    },
  ];

  let reminderBtnLabel;

  if (sendingReminder) {
    reminderBtnLabel = languagePack.sendingReminderLabel;
  } else if (!sendingReminder && onboardingStatus === 'requested') {
    reminderBtnLabel = languagePack.sendReminder1;
  } else if (!sendingReminder && onboardingStatus === 'reminder1Sent') {
    reminderBtnLabel = languagePack.sendReminder2;
  }

  async function sendReminder() {
    try {
      const result = await sendOnboardingReminder(onBoardingDetails.candidateId);

      if (typeof result === 'string' || result.status === 'FAILED') {
        if (result === 'NOT_FOUND_ERROR') {
          toast.error(languagePack.errorSendingReminderNotFound);
        } else {
          toast.error(languagePack.errorSendingReminder);
        }
      } else {
        setStatus(result.status);
        setOnboardingDetails({
          ...onBoardingDetails,
          statusLastUpdatedDateTime: moment().utc().format('DD-MM-YYYY HH:mm:ss'),
        });
      }
      setSendingReminder(false);
    } catch (exception) {
      toast.error(languagePack.errorSendingReminder);
    }
  }

  const postRequestMenuItems = [
    {
      action: () => {
        const { onboardingFormId, candidateId } = onBoardingDetails;
        setModalConfig({
          isOpen: true,
          url: `${web.formsWebUrl}?id=${onboardingFormId}&cid=${candidateId}&userid=${userDetails.id}&username=${userDetails.firstName}%20${userDetails.lastName}`,
        });
      },
      icon: 'File',
      id: 1,
      isDisabled: false,
      label: languagePack.onboardingSelfFillInFormBtn,
      permissions: ['candidate:onboarding:create'],
    },
    {
      action: () => {
        navigator.clipboard.writeText(formUrl).then(
          () => {
            toast.success(`${languagePack.onboardingFormURL} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
      },
      icon: 'File',
      id: 3,
      isDisabled: false,
      label: languagePack.onboardingFormURL,
      permissions: ['candidate:onboarding:create'],
    },
    {
      action: () => {
        setSendingReminder(true);
        sendReminder();
      },
      icon: 'File',
      id: 2,
      isHidden: onboardingStatus === 'reminder2Sent',
      label: reminderBtnLabel,
      permissions: ['candidate:onboarding:create'],
      isDisabled: sendingReminder,
    },
    // {
    //   action: () => {
    //     setJourneyModalConfig({
    //       isOpen: true,
    //     });
    //   },
    //   icon: 'Eye',
    //   id: 3,
    //   label: languagePack.viewJourneyBtn,
    //   permissions: ['talentfunnel:admin'],
    // },
    {
      action: () => {
        handlePayrollSubmitTo('Flow', applicant.id)
          .then(() => {
            toast.success(`Successfully submitted to Flow`);
          })
          .catch(() => {
            toast.error(`Submitting to Flow failed`);
          });
      },
      icon: 'File',
      id: 4,
      label: languagePack.submitToFlowBtn,
      permissions: ['flow:write'],
    },
    {
      action: () => {
        handlePayrollSubmitTo('Smart', applicant.id)
          .then(() => {
            toast.success(`Successfully submitted to SMART`);
          })
          .catch(() => {
            toast.error(`Submitting to SMART failed`);
          });
      },
      icon: 'File',
      id: 5,
      label: languagePack.submitToSmartBtn,
      permissions: ['flow:write'],
    },
    {
      action: () => {
        handlePayrollSubmitTo('Prospec', applicant.id)
          .then(() => {
            toast.success(`Successfully submitted to Prospec`);
          })
          .catch(() => {
            toast.error(`Submitting to Prospec failed`);
          });
      },
      icon: 'File',
      id: 6,
      label: languagePack.submitToProspecBtn,
      permissions: ['flow:write'],
    },
  ];

  function handleError() {
    onError(languagePack.onboardingSendError);
  }

  function handleSuccess(message, onboardingResponse) {
    toggleReqInfo();
    setOnboardingStatus('requested');
    if (onboardingResponse && onboardingResponse[0] && onboardingResponse[0].onboarding) {
      setOnboardingDetails(onboardingResponse[0].onboarding);
    }
    onSuccess(languagePack.onboardingSendSuccess);
    onUpdate('REQUESTED');
  }

  let reminderText;
  if (onBoardingDetails) {
    const updatedFormattedTime = moment
      .utc(onBoardingDetails.statusLastUpdatedDateTime)
      .local()
      .format('DD-MM-YYYY HH:mm:ss');
    if (onboardingStatus === 'reminder1Sent') {
      reminderText = `First Reminder Sent at: ${updatedFormattedTime}`;
    } else if (onboardingStatus === 'reminder2Sent') {
      reminderText = `Final Reminder Sent at: ${updatedFormattedTime}`;
    } else if (onboardingStatus === 'requested') {
      reminderText = `Requested at: ${updatedFormattedTime}`;
    }
  }

  const headerExclusionStatuses = ['notRequested', 'requested', 'reminder1Sent', 'reminder2Sent'];

  return (
    <>
      <Card>
        <CardBody>
          {loading ? (
            <LoadingScreen notFixed />
          ) : (
            <>
              {!headerExclusionStatuses.includes(onboardingStatus) && tenantOnboardingFormId && (
                <div className="d-flex justify-content-between mb-2 align-items-center">
                  <h4 className="m-0">{languagePack.onboardingTitle || 'Onboarding'}</h4>
                </div>
              )}
              <Row>
                <Col>
                  {error ? (
                    <h5 className="text-danger mb-0">
                      {languagePack.onboardingError ||
                        'An error occurred when loading the users onboarding information'}
                    </h5>
                  ) : (
                    <>
                      {!headerExclusionStatuses.includes(onboardingStatus) && tenantOnboardingFormId && (
                        <HeadingPushRight
                          headingAs="h5"
                          headingClassName="d-flex align-items-center"
                          headingText={
                            <>
                              {languagePack[onboardingStatus] || 'Unknown'}{' '}
                              {onBoardingDetails && onBoardingDetails?.statusLastUpdatedDateTime && (
                                <span className="heading-note">
                                  {`${languagePack.lastUpdated}: ${moment
                                    .utc(onBoardingDetails.statusLastUpdatedDateTime)
                                    .local()
                                    .format('DD-MM-YYYY HH:mm:ss')}`}
                                </span>
                              )}
                            </>
                          }
                          isTouch={isTouch}
                        >
                          <>
                            {['requested', 'reminder1Sent'].includes(onboardingStatus) && (
                              <ActionButton
                                buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                                className="ellipsis-opts-btn"
                                iconName="Ellipsis"
                                iconOnly={false}
                                isOpen={onboardingPostActionOpen}
                                menuItems={postRequestMenuItems}
                                onToggle={(isOpen) => {
                                  setOnboardingPostActionOpen(isOpen);
                                }}
                                title="Actions"
                                tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                              />
                            )}
                            {onboardingStatus === 'received' && onBoardingDetails.completedFormId && (
                              <ActionButton
                                buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                                className="ellipsis-opts-btn"
                                iconName="Ellipsis"
                                iconOnly={false}
                                isOpen={onboardingActionOpen}
                                menuItems={menuItems}
                                onToggle={(isOpen) => {
                                  setOnboardingActionOpen(isOpen);
                                }}
                                title="Actions"
                                tooltipContent={languagePack.tooltipContent || 'Select a candidate'}
                              />
                            )}
                          </>
                        </HeadingPushRight>
                      )}
                      {onboardingStatus === 'notRequested' && tenantOnboardingFormId && !infReqExpanded && (
                        <Row className="mb-4">
                          <Col>
                            <ActionToBePerformedNoDataState
                              image={onboardingNotRequestImg}
                              title="Onboarding Not Yet Requested"
                              description="No onboarding information has been requested from the candidate"
                              buttonText="Request Onboarding"
                              button={
                                <ActionButton
                                  buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                                  className="ellipsis-opts-btn"
                                  iconName="Ellipsis"
                                  iconOnly={false}
                                  isOpen={onboardingActionOpen}
                                  menuItems={preRequestMenuItems}
                                  onToggle={(isOpen) => {
                                    setOnboardingActionOpen(isOpen);
                                  }}
                                  title="Actions"
                                  tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {onboardingStatus === 'notRequested' && !tenantOnboardingFormId && (
                    <p>
                      {checkPermissions(['admin:forms:create']) ? (
                        <Link style={{ lineHeight: '25px' }} to="/settings/forms">
                          {languagePack.noOnboardingConfigured}
                        </Link>
                      ) : (
                        languagePack.noOnboardingConfiguredCantCreate
                      )}
                    </p>
                  )}
                  {infReqExpanded && (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={onboardingNotRequestImg}
                          alt="Onboarding"
                          style={{ maxWidth: '100%', marginBottom: '2px', opacity: 0.7, width: '80px' }}
                        />
                        <h2 style={{ marginTop: '0', marginLeft: '10px' }}>{languagePack.onboardingRequestedTitle}</h2>
                      </div>
                      <EmailForm
                        actionType="ONBOARDING"
                        applicants={[
                          {
                            applicantEmail: applicant.applicantProfile.communication.emailAddress,
                            applicantId: applicant.id,
                          },
                        ]}
                        onError={handleError}
                        onSuccess={handleSuccess}
                        sendBtnLabel={languagePack.emailSubmit}
                      />
                    </>
                  )}
                  {!infReqExpanded &&
                    onboardingStatus !== 'received' &&
                    onboardingStatus !== 'notRequested' &&
                    onBoardingDetails &&
                    !onBoardingDetails.mappedAnswers && (
                      <div className="mb-4">
                        <ActionToBePerformedNoDataState
                          image={waiting}
                          title="Onboarding Requested"
                          subTitle={reminderText}
                          description="Onboarding information has been requested and we are waiting for the candidate to complete it."
                          button={
                            <ActionButton
                              buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                              className="ellipsis-opts-btn"
                              iconName="Ellipsis"
                              iconOnly={false}
                              isOpen={onboardingPostActionOpen}
                              menuItems={postRequestMenuItems}
                              onToggle={(isOpen) => {
                                setOnboardingPostActionOpen(isOpen);
                              }}
                              title="Actions"
                              tooltipContent={languagePack.tooltipContent || 'Perform actions'}
                            />
                          }
                        />
                      </div>
                    )}
                  {onBoardingDetails && onBoardingDetails.mappedAnswers && (
                    <MappedAnswerView
                      attachments={onBoardingDetails.attachments}
                      candidateId={applicant.id}
                      headingTag="h5"
                      isTouch={isTouch}
                      mappedAnswers={onBoardingDetails.mappedAnswers}
                    />
                  )}
                </Col>
              </Row>
              {onboardingStatus === 'received' && (
                <>
                  <RestrictedPolarisForm
                    applicantAccountId={applicant.accountId}
                    applicantId={applicant.id}
                    candidateProfileSummary={candidateProfileSummary}
                    onBoardingAnswers={onBoardingDetails.mappedAnswers}
                    permissions={['polaris:write']}
                  />
                  <RestrictedS4Form
                    applicantAccountId={applicant.accountId}
                    candidateProfileSummary={candidateProfileSummary}
                    applicantId={applicant.id}
                    permissions={['s4:write']}
                  />
                  <RestrictedFourthForm
                    applicantId={applicant.id}
                    permissions={['fourth:write']}
                    candidateProfileSummary={candidateProfileSummary}
                  />
                  <RestrictedSmartIntegration
                    candidate={applicant}
                    candidateProfileSummary={candidateProfileSummary}
                    permissions={['smart:write']}
                  />
                  <RestrictedAccessForm
                    candidateProfileSummary={candidateProfileSummary}
                    applicantId={applicant.id}
                    permissions={['accessgrp:write']}
                  />
                  <RestrictedSonaForm
                    candidateProfileSummary={candidateProfileSummary}
                    applicantId={applicant.id}
                    permissions={['sona:write']}
                  />
                  <RestrictedRotaReadyForm
                    applicantId={applicant.id}
                    candidateProfileSummary={candidateProfileSummary}
                    permissions={['rota:write']}
                  />
                  <RestrictedUbeyaIntegration candidate={applicant} permissions={['talentfunnel:admin']} />
                  <RestrictedSAPNLForm applicantId={applicant.id} permissions={['sap-nl:read']} />
                  <RestrictedPeopleHRForm applicantId={applicant.id} permissions={['peoplehr:write']} />
                  <RestrictedSAPForm
                    applicantId={applicant.id}
                    permissions={['sap:write']}
                    candidateProfileSummary={candidateProfileSummary}
                  />
                  <RestrictedTamigoForm applicantId={applicant.id} permissions={['tamigo:write']} />
                  <RestrictedNoryForm applicantId={applicant.id} permissions={['nory:write']} />
                </>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {modalConfig.isOpen && (
        <FormModal
          contentUrl={modalConfig.url}
          id="onboard-details"
          isOpen={modalConfig.isOpen}
          contentUrl={modalConfig.url}
          toggle={(closeMethod, isOpen, frameId) => {
            if (closeMethod === 'IFRAME' && isOpen && frameId === 'onboard-details') {
              setLoading(true);
              getData((status) => {
                setModalConfig({ isOpen: false, url: '' });
                onUpdate(status);
              });
            } else {
              setModalConfig({ isOpen: false, url: '' });
            }
          }}
          warnOnClickClose
        />
      )}
      {journeyModalConfig.isOpen && (
        <Modal
          style={{ maxWidth: '800px', width: '100%', height: '100%' }}
          id="onboard-details"
          isOpen={journeyModalConfig.isOpen}
          warnOnClickClose
        >
          <ModalHeader
            toggle={() => {
              setJourneyModalConfig({ isOpen: false });
            }}
          >
            Onboarding Events
          </ModalHeader>
          <AnalyticsFlowChart
            apiCall={() => searchAnalytics({ types: ['ONBOARDING'], candidateIds: [applicant.id] })}
          />
        </Modal>
      )}
    </>
  );
}

OnboardingDetails.propTypes = {
  applicant: PropTypes.shape(),
  candidateProfileSummary: PropTypes.shape(),
  isTouch: PropTypes.bool,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  onUpdate: PropTypes.func,
  userDetails: PropTypes.shape(),
};

OnboardingDetails.defaultProps = {
  applicant: null,
  candidateProfileSummary: null,
  isTouch: false,
  onError: () => {},
  onSuccess: () => {},
  onUpdate: () => {},
  userDetails: {},
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(OnboardingDetails);
