import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { ActionButton, TextButton } from '../../Base/Buttons';
import Can from '../../Base/RBAC/Can/Can';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import waiting from '../../../assets/img/nodata/waiting.png';

const RestrictedTextButton = Can(TextButton);

function PersonalityTestRequestedContainer({ status, testURL, languagePack }) {

  let reminderText;

  if(status === 'REMINDER_1_SENT') {
    reminderText = 'First Reminder Sent'
  } else if(status === 'REMINDER_2_SENT') {
    reminderText = 'Final Reminder Sent'
  }

  return (
    <Row>
      <Col>
          <ActionToBePerformedNoDataState
            image={waiting}
            title='Personality Test Requested'
            subTitle={reminderText}
            description='The Personality Test has been requested and we are waiting for the candidate to complete it.'
            button={<RestrictedTextButton
              action={() => {
                window.open(`${testURL}`);
              }}
              className="mt-3 mb-2"
              label={languagePack.personalityTestSelfFillInFormBtn}
              permissions={['pt:create', 'pth:create']}
            />}
          />
      </Col>
    </Row>
  );
}

PersonalityTestRequestedContainer.propTypes = {
  languagePack: PropTypes.shape(),
  testURL: '',
};

PersonalityTestRequestedContainer.defaultProps = {
  languagePack: {},
  testURL: '',
};

export default PersonalityTestRequestedContainer;
