// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export const optionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  }),
);

function SelectInput({
  id,
  className,
  value,
  options,
  onChange,
  isMulti,
  disabled,
  placeholder,
  isClearable,
  updatedOptions,
  useDefaultOption,
}) {
  const opts = [...options, ...updatedOptions];

  function computeValues() {
    if (isMulti && Array.isArray(value)) {
      return value.map((val) => {
        return { label: val.label, value: val.value };
      });
    }
    return opts.find((opt) => {
      if (!value && useDefaultOption) return opt.default;
      return opt.value === value;
    });
  }
  return (
    <Select
      className={className}
      classNamePrefix="react-select"
      id={id}
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={isMulti}
      onChange={(choices) => {
        let val = (choices || {}).value || '';
        if (Array.isArray(choices)) val = choices.map((choice) => choice.value);
        onChange(val, choices);
      }}
      options={opts}
      placeholder={placeholder}
      value={computeValues()}
    />
  );
}

SelectInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  options: optionPropType,
  placeholder: PropTypes.string,
  updatedOptions: optionPropType,
  useDefaultOption: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
};

SelectInput.defaultProps = {
  className: null,
  disabled: false,
  id: null,
  isClearable: false,
  isMulti: false,
  onChange: () => {},
  options: [],
  placeholder: 'Select...',
  updatedOptions: [],
  useDefaultOption: true,
  value: '',
};

export default SelectInput;
