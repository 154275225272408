/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import InputRange from 'react-input-range';

function EmailDelaySend({ value, onChange }) {
  const [isDelayedSend, setIsDelayedSend] = useState(false);
  const [delayNum, setDelayNum] = useState(1);

  useEffect(() => {
    setIsDelayedSend(value > 0);
    setDelayNum(value / 3600);
  }, [value]);

  return (
    <div className="email-delay-send">
      <FormGroup check inline>
        <Label check>
          <Input
            type="checkbox"
            className="standard-checkbox small"
            checked={isDelayedSend}
            onChange={(e) => {
              const { checked } = e.target;
              let delay = 0;
              setIsDelayedSend(checked);

              if (checked) {
                delay = 3600;
                setDelayNum(1);
              }

              onChange(delay);
            }}
          />
          <span className="bottom-0">Delay sending</span>
        </Label>
      </FormGroup>
      {isDelayedSend && (
        <div className="email-delay-send-range">
          <p>Delay by (hours):</p>
          <div className="hour-range">
            <InputRange
              maxValue={72}
              minValue={1}
              value={delayNum}
              onChange={(val) => {
                setDelayNum(val);
                onChange(val * 3600);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

EmailDelaySend.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

EmailDelaySend.defaultProps = {
  value: 0,
  onChange: () => {},
};

export default EmailDelaySend;
