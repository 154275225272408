import {
  SET_TABLE_PAGE_SIZE,
  SET_MODAL_TABLE_PAGE_SIZE,
} from '../constants/actionTypes';

const defaultState = {
  pageSize: 25,
  modalPageSize: 10,
};

export default function tablePrefsReducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TABLE_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      };
    case SET_MODAL_TABLE_PAGE_SIZE:
      return {
        ...state,
        modalPageSize: payload,
      };
    default:
      return state;
  }
}
