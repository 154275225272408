import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { ActionButton, IconButton } from '../../Base/Buttons';
import MappedAnswerView from '../Candidates/MappedAnswerView/MappedAnswerView';
import Can from '../../Base/RBAC/Can/Can';
import TextButton from '../../Base/Buttons/TextButton';
import InterviewContext from './InterviewContext';
import { IconSVG } from '../../Base/SVG';
import { HeadingPushRight } from '../../Base/Headings';
import { downloadInterviewNotes } from '../../../api/EventAPI/InviteNotesAPI';
import { generateToken } from './interviewUtils';

const defaultDate = new Date();

const RestrictedTextButton = Can(TextButton);

function InterviewNotes({ invite, dateTimeFormat }) {
  const {
    languagePack,
    notes,
    interviewFormOptions,
    onFormChange,
    onActiveInviteId,
    onModal,
    onBooking,
    onEdit,
  } = useContext(InterviewContext);

  const [selectedForm, setSelectedForm] = useState();
  const [notesActionsOpen, setNotesActionOpen] = useState(false);
  const { id, status, availableSlotCount } = invite;
  const inviteNote = notes.find((note) => note.inviteId === id);

  const menuItems = [{
    id: 1,
    label: 'Edit Notes',
    icon: 'Pencil',
    permissions: ['candidate:interviewnotes:read'],
    isDisabled: false,
    // eslint-disable-next-line max-len
    tooltipContent: 'This email is required by the system and can\'t be removed, if you wish to change the contents you can edit it',
    action: () => {
      generateToken(inviteNote.originalFormId, (token) => {
        setNotesActionOpen(false);
        onEdit(token, invite);
      }, () => toast.error(languagePack.editNotesError));
    },
  },
  {
    id: 2,
    label: 'Download PDF',
    icon: 'File',
    permissions: ['candidate:interviewnotes:read'],
    action: async () => {
      try {
        await downloadInterviewNotes(inviteNote.id);
      } catch (e) {
        toast.error(languagePack.downloadNotesError);
      } finally {
        setNotesActionOpen(false);
      }
    },
  }];

  return (
    <div className="interview-notes-wrapper">
      {status !== 'INVITED' ? (
        <Fragment>
          {!inviteNote ? (
            <Fragment>
              <h6>{languagePack.makeNotesTitle || 'Make Interview Notes'}</h6>
              {interviewFormOptions.length ? (
                <Fragment>
                  <p className="mb-2">{languagePack.makeNotesDesc || ''}</p>
                  <Select
                    value={interviewFormOptions.find((opt) => (opt.value === selectedForm))}
                    onChange={({ value: val }) => {
                      onFormChange(val);
                      onActiveInviteId(invite.id);
                      setSelectedForm(val);
                    }}
                    options={interviewFormOptions}
                    classNamePrefix="react-select"
                  />
                </Fragment>
              ) : (
                <p>
                  {`${languagePack.noInterviewFormsPrefix} `}
                  <a
                    href="/settings/forms"
                    target="_blank"
                  >
                    {languagePack.noInterviewFormsSettings}
                  </a>
                  {` ${languagePack.noInterviewFormsAffix}`}
                </p>
              )}
              {selectedForm && (
                <IconButton
                  label={languagePack.startButtonLabel || 'Start'}
                  className="mt-2"
                  SVGIcon={<IconSVG name="Comments Solid" />}
                  action={() => onModal(true)}
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col>
                  <p className="mb-1">
                    {`${languagePack.notesLastUpdated} `}
                    {moment.utc((inviteNote.updatedDateTime || defaultDate)).local().format(dateTimeFormat)}
                  </p>
                  <HeadingPushRight
                    headingAs="h5"
                    headingText={languagePack.notesHeading}
                  >
                    <ActionButton
                      buttonLabel={languagePack.actionBtnLabel || 'Actions'}
                      tooltipContent={languagePack.tooltipContent || 'Select a candidate'}
                      title="Actions"
                      iconName="Ellipsis"
                      className="ellipsis-opts-btn"
                      iconOnly={false}
                      menuItems={menuItems}
                      isOpen={notesActionsOpen}
                      onToggle={(isOpen) => { setNotesActionOpen(isOpen); }}
                    />
                  </HeadingPushRight>
                </Col>
              </Row>
              <MappedAnswerView
                mappedAnswers={inviteNote.mappedAnswers}
                hasToggle={false}
                headingTag="h5"
                vertical
              />
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <p className="mb-2">{languagePack.notAbleToCompleteNotes}</p>
          <RestrictedTextButton
            permissions={[]}
            disabled={availableSlotCount === 0}
            label={languagePack.bookCandidateInButtonLabel || 'Book Candidate In'}
            action={() => onBooking(invite)}
          />
        </Fragment>
      )}
    </div>
  );
}

InterviewNotes.propTypes = {
  invite: PropTypes.shape(),
  dateTimeFormat: PropTypes.string.isRequired,
};

InterviewNotes.defaultProps = {
  invite: {},
};

export default InterviewNotes;
