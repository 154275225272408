import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import cx from 'classnames';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getTemplatesByType } from '../../../api/TemplateAPI';
import TemplateSelect from './TemplateSelect';
import TemplatePrompt from './TemplatePrompt';
import { useMounted } from '../../Base/hooks';

async function fetchTemplates(templateType, onSuccess = () => {}) {
  if (templateType) {
    const resp = await retryableAPICall(() => getTemplatesByType(templateType));

    if (Array.isArray(resp)) {
      onSuccess(resp);
    } else {
      toast.error('Error fetching templates, please try again.');
    }
  }
}

function FieldButtons({ onClick, buttons, selected }) {
  return (
    <ButtonGroup className="">
      {buttons.map((btn) => {
        const isDisplayed = selected.includes(btn.id);

        return (
          <Button
            key={btn.id}
            color="link"
            className={cx({ 'is-displayed': isDisplayed })}
            onClick={() => onClick(btn.id, isDisplayed)}
          >
            {btn.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

FieldButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
};

FieldButtons.defaultProps = {
  onClick: () => {},
  selected: [],
};

function EmailToolbar({
  noTemplates,
  templateType,
  onError,
  onTemplateChange,
  onTemplateCreate,
  onValidate,
  formRef,
  toggleButtons,
  children,
  renderToolbar,
}) {
  const isMounted = useMounted();
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [toggleInps, setToggleInps] = useState();
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [displayedInputs, setDisplayedInputs] = useState([]);

  useEffect(() => {
    if (isMounted() && !noTemplates) {
      fetchTemplates(templateType, (resp) => {
        setTemplateList(resp);
      });
    }
  }, [isMounted, noTemplates, templateType]);

  useEffect(() => {
    if (isMounted() && formRef.current) {
      const elms = formRef.current.querySelectorAll('.is-toggle');
      if (elms.length) {
        setToggleInps([...elms]);

        const notHiddenInps = toggleButtons.reduce((acc, inp) => {
          if (!inp.isHidden) acc.push(inp.id);
          return acc;
        }, []);
        setDisplayedInputs(notHiddenInps);
      }
    }
  }, [formRef, isMounted, toggleButtons]);

  return (
    <Fragment>
      {renderToolbar && (
        <div className="email-toolbar mt-3">
          {!noTemplates && (
            <TemplateSelect
              templateList={templateList}
              selectedTemplateId={selectedTemplate}
              onError={onError}
              onSelect={(templateObj) => {
                setSelectedTemplate(templateObj.id);
                onTemplateChange(templateObj);
              }}
            />
          )}
          <Row className="mb-2">
            <Col sm={{ size: 9, offset: 3 }}>
              <FieldButtons
                buttons={toggleButtons}
                selected={displayedInputs}
                onClick={(inpId, isDisplayed) => {
                  const elm = toggleInps.find((el) => el.dataset.inputId === inpId);
                  if (elm) {
                    elm.classList.toggle('is-hidden');

                    const dupeArr = [...displayedInputs];

                    if (!isDisplayed) {
                      dupeArr.push(inpId);
                    } else {
                      const idx = dupeArr.indexOf(inpId);
                      dupeArr.splice(idx, 1);
                    }

                    setDisplayedInputs(dupeArr);
                  }
                }}
              />
            </Col>
          </Row>
        </div>
      )}
      {children({
        openPrompt: () => setIsPromptOpen(true),
        isTemplateSelected: !!selectedTemplate,
        deselectTemplate: () => setSelectedTemplate(),
      })}
      <TemplatePrompt
        templateType={templateType}
        isOpen={isPromptOpen}
        closePrompt={() => setIsPromptOpen(false)}
        onError={onError}
        onSuccess={(templateObj) => {
          setTemplateList([...templateList, templateObj]);
          setSelectedTemplate(templateObj.id);
          onTemplateCreate('Template successfully created');
        }}
        validate={onValidate}
      />
    </Fragment>
  );
}

EmailToolbar.propTypes = {
  noTemplates: PropTypes.bool,
  templateType: PropTypes.string,
  onError: PropTypes.func,
  onTemplateChange: PropTypes.func,
  onTemplateCreate: PropTypes.func,
  onValidate: PropTypes.func,
  formRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
  toggleButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  children: PropTypes.func,
  renderToolbar: PropTypes.bool,
};

EmailToolbar.defaultProps = {
  noTemplates: false,
  templateType: 'CANDIDATE_CONTACT',
  onError: () => {},
  onTemplateChange: () => {},
  onTemplateCreate: () => {},
  onValidate: () => {},
  formRef: null,
  toggleButtons: [],
  children: () => {},
  renderToolbar: true,
};

export default EmailToolbar;
