import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { LoadingScreen } from '../Loading';
import { Confirmation } from './index';

export default class FormModal extends Component {
  constructor(props) {
    super(props);

    const { usePostMessage, frameHeight } = props;

    this.state = {
      defaultFrameHeight: frameHeight,
      iFrameHeight: frameHeight,
      isLoaded: !usePostMessage,
      displayCloseWarning: false,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIframe);
  }

  componentWillUnmount() {
    this.setState({ isLoaded: false, iFrameHeight: this.state.defaultFrameHeight });
    window.removeEventListener('message', this.handleIframe);
  }

  handleIframe = (e) => {
    const { toggle, id, isOpen } = this.props;
    const { data } = e;

    if (typeof data === 'number' && data > 0) {
      this.setState({ iFrameHeight: `${data}px` }, () => {
        setTimeout(() => this.setState({ isLoaded: true }), 150);
      });
    } else if (data === 'doClose') {
      toggle('IFRAME', isOpen, id);
    }
  };

  handleClickOutsideClose(warnOnClickClose) {
    const { toggle } = this.props;
    if (warnOnClickClose) {
      this.setState({ displayCloseWarning: true });
    } else {
      toggle('DEFAULT');
    }
  }

  render() {
    const { toggle, isOpen, contentUrl, warnOnClickClose, title, id } = this.props;
    const { isLoaded, iFrameHeight, displayCloseWarning } = this.state;

    return (
      <Fragment>
        <Confirmation
          show={displayCloseWarning}
          title="Are you sure?"
          content="Closing this window may result in the loss of all of the entered information. Are you sure you want to close it?"
          confirmCallback={() => {
            this.setState({ displayCloseWarning: false });
            toggle('DEFAULT');
          }}
          cancelCallback={() => this.setState({ displayCloseWarning: false })}
        />
        <Modal
          toggle={() => this.handleClickOutsideClose(warnOnClickClose)}
          onClosed={() => this.setState({ iFrameHeight: this.state.defaultFrameHeight })}
          isOpen={isOpen}
          size="lg"
          centered
          className="forms-modal-iframe"
        >
          {title && <ModalHeader toggle={() => toggle('HEAD')}>{title}</ModalHeader>}
          <ModalBody>
            {!isLoaded && <LoadingScreen isModal />}
            <Iframe
              src={contentUrl}
              width="100%"
              height={iFrameHeight}
              frameBorder="0"
              scrolling="no"
              allow="camera;microphone"
              id={id}
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

FormModal.propTypes = {
  id: PropTypes.string,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  warnOnClickClose: PropTypes.bool,
  contentUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  usePostMessage: PropTypes.bool,
  frameHeight: PropTypes.string,
};

FormModal.defaultProps = {
  id: null,
  toggle: () => {},
  isOpen: false,
  warnOnClickClose: false,
  title: null,
  usePostMessage: true,
  frameHeight: '700px',
};
