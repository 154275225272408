import React from 'react';
import PropTypes from 'prop-types';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getTemplateById } from '../../../api/TemplateAPI';
import { FormGroup } from '../../Base/Forms/Custom/CommonComponents';

async function getTemplate(id, onSuccess = () => { }, onError = () => { }) {
  const resp = await retryableAPICall(() => getTemplateById(id));

  if (typeof resp === 'string') {
    onError('Error fetching template, please try again or contact support');
  }
  else {
    // returned object to email keys
    const {
      fromName = '',
      replyTo = '',
      ccAddresses = [],
      bccAddresses = [],
      subject = '',
      content = '',
      attachments = [],
    } = resp;

    onSuccess({
      id,
      fromName,
      replyTo,
      ccAddresses,
      bccAddresses,
      subject,
      message: content,
      attachments,
    });
  }
}

function TemplateSelect({
  selectedTemplateId,
  templateList,
  onSelect,
  onError,
}) {
  if (!templateList.length) return null;

  return (
    <FormGroup
      row
      id="email-tmp"
      label="Choose Template"
      labelProps={{ sm: 3 }}
      colProps={{ sm: 9 }}
    >
      <select
        id="email-tmp"
        className="select w-100"
        onChange={(e) => getTemplate(e.target.value, onSelect, onError)}
        value={selectedTemplateId}
      >
        <option value="" disabled>Select...</option>
        {templateList.map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </select>
    </FormGroup>
  );
}

TemplateSelect.propTypes = {
  templateList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  selectedTemplateId: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func,
};

TemplateSelect.defaultProps = {
  templateList: [],
  selectedTemplateId: '',
  onSelect: () => { },
  onError: () => { },
};

export default TemplateSelect;
