import { useRef, useEffect } from 'react';

export default function useFormKeypress() {
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener('keypress', (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          return false;
        }
        return true;
      });
    }
  }, []);

  return formRef;
}
