import moment from 'moment';

function merge(objects) {
  const out = {};

  for (let i = 0; i < objects.length; i++) {
    for (const p in objects[i]) {
      out[p] = objects[i][p];
    }
  }

  return out;
}

export function flattenResults(obj, name, stem) {
  const newStem = typeof stem !== 'undefined' && stem !== '' ? `${stem}_${name}` : name;
  let out = {};

  if (typeof obj !== 'object' || Array.isArray(obj)) {
    out[newStem] = obj;
    return out;
  }

  for (const p in obj) {
    const prop = flattenResults(obj[p], p, newStem);
    out = merge([out, prop]);
  }

  return out;
}

export function getNested(p, o) {
  p.reduce((xs, x) => {
    if (xs && xs[x]) return xs[x];
    return null;
  }, o);
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getArrayOfObjectValuesBy(key, arr = []) {
  if (!key || !arr.length) return [];
  return arr.reduce((acc, obj) => {
    if (obj[key]) acc.push(obj[key]);
    return acc;
  }, []);
}

const alpha = 'abcdefghijklmnopqrstuvwxyz';
const num = '0123456789';
const chars = `${alpha}${alpha.toUpperCase()}${num}`;

export function randomStr(len = 6) {
  return Array(len)
    .join()
    .split(',')
    .map(
      () => chars.charAt(Math.floor(Math.random() * chars.length)),
      // eslint-disable-next-line newline-per-chained-call
    )
    .join('');
}

export function trimFormData(formData = {}) {
  return Object.entries(formData).reduce((acc, [k, v]) => {
    if (typeof v === 'string') {
      return { ...acc, [k]: v.trim() };
    }
    if (typeof v === 'object' && !Array.isArray(v)) {
      return { ...acc, [k]: trimFormData(v) };
    }
    return { ...acc, [k]: v };
  }, {});
}

export function groupBy(xs, key, orderBy) {
  const result = xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  if (orderBy) {
    Object.entries(result).forEach(([objKey, value]) => {
      // eslint-disable-next-line no-nested-ternary
      const sortedVals = value.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : b[orderBy] > a[orderBy] ? -1 : 0));
      result[objKey] = sortedVals;
    });
  }
  return result;
}

export function groupByNested(xs, parent, key, orderBy) {
  const result = xs.reduce((rv, x) => {
    (rv[x[parent][key]] = rv[x[parent][key]] || []).push(x);
    return rv;
  }, {});

  if (orderBy) {
    Object.entries(result).forEach(([objKey, value]) => {
      // eslint-disable-next-line no-nested-ternary
      const sortedVals = value.sort((a, b) =>
        a[parent][orderBy] > b[parent][orderBy] ? 1 : b[parent][orderBy] > a[parent][orderBy] ? -1 : 0,
      );
      result[objKey] = sortedVals;
    });
  }
  return result;
}

export function computeDaysAgo(date) {
  if (!date) {
    return null;
  }

  return moment().diff(moment(date, 'YYYY-MM-DD HH:mm:ss'), 'days');
}

export function getDaysText(days) {
  let daysText;
  switch (days) {
    case 0:
      daysText = 'Today';
      break;
    case 1:
      daysText = '1 day ago';
      break;
    default:
      daysText = `${days} days ago`;
      break;
  }

  return daysText;
}

export function getSwimlaneWarningClass(days) {
  let clazz = '';
  if (days >= 7 && days <= 13) {
    clazz = 'swimlane-text-warning';
  } else if (days >= 14) {
    clazz = 'swimlane-text-danger';
  }

  return clazz;
}

export function isCtrlCmdClick(event) {
  return event.ctrlKey || event.metaKey;
}

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function getJobsiteUrl(baseUrl, vacancyBaseUrl, accountJobsiteDetails, vacancyId) {
  if (!baseUrl || !vacancyId || !vacancyBaseUrl) {
    return '';
  }

  if (!accountJobsiteDetails || !accountJobsiteDetails.length) {
    return baseUrl.length && vacancyBaseUrl.length ? `${baseUrl}${vacancyBaseUrl}` : null;
  }

  const jobSite = accountJobsiteDetails.find((site) => site.accountIds.includes(vacancyId));

  if (jobSite) {
    return jobSite.baseUrl && jobSite.vacancyBaseUrl ? `${jobSite.baseUrl}${jobSite.vacancyBaseUrl}` : null;
  } else {
    return baseUrl.length && vacancyBaseUrl.length ? `${baseUrl}${vacancyBaseUrl}` : null;
  }
}
