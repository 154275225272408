/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const lookUp = {
  video: {
    viewBox: '0 0 576 512',
    d: 'M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z',
  },
  videoSlash: {
    viewBox: '0 0 640 512',
    d: 'M633.8 458.1l-55-42.5c15.4-1.4 29.2-13.7 29.2-31.1v-257c0-25.5-29.1-40.4-50.4-25.8L448 177.3v137.2l-32-24.7v-178c0-26.4-21.4-47.8-47.8-47.8H123.9L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4L42.7 82 416 370.6l178.5 138c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.5-6.9 4.2-17-2.8-22.4zM32 400.2c0 26.4 21.4 47.8 47.8 47.8h288.4c11.2 0 21.4-4 29.6-10.5L32 154.7v245.5z',
  },
};

function Video(props) {
  const { type, ...rest } = props;

  const config = lookUp[type];

  return (
    <BaseSVG
      {...rest}
      name="Video"
      viewBox={config.viewBox}
      d={config.d}
    />
  );
}

Video.propTypes = {
  type: PropTypes.string,
};

Video.defaultProps = {
  type: 'video',
};

export default Video;
