import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import BasicDateSelect from './BasicDateSelect';
import IconSVG from '../../../SVG/IconSVG';

const getBrowserLanguage = () => {
  return navigator.language || navigator.languages[0];
};

const dateFormatMapping = {
  'en-US': 'MM/DD/YYYY', // United States
  'en-GB': 'DD/MM/YYYY', // United Kingdom (England)
  'de-DE': 'DD.MM.YYYY', // Germany
  de: 'DD.MM.YYYY', // German (general)
  'ja-JP': 'YYYY/MM/DD', // Japan
  ja: 'YYYY/MM/DD', // Japanese (general)
};

const getDisplayFormat = (defaultFormat, language) => {
  return dateFormatMapping[language] || defaultFormat;
};

function RangePicker({ isTouch, block, disabled, startDate, endDate, onChange, id, displayFormat, futureDates }) {
  const [focusedInput, setFocusedInput] = useState();
  const [dateRange, setDateRange] = useState({ startDate: undefined, endDate: undefined });
  const browserDisplayFormat = useMemo(() => getDisplayFormat(displayFormat, getBrowserLanguage()), [displayFormat]);

  function handleChange(date, prop) {
    const dateObj = { ...dateRange, [prop]: moment(date) };
    setDateRange(dateObj);
    onChange(dateObj);
  }

  const startDateId = `${id ? `${id}-` : ''}start-date`;
  const endDateId = `${id ? `${id}-` : ''}end-date`;

  if (isTouch) {
    return (
      <div className="d-flex align-items-center">
        <BasicDateSelect
          isTouch
          date={startDate ? startDate.toDate() : null}
          id={startDateId}
          onChange={(date) => handleChange(date, 'startDate')}
          className="start-date"
        />
        <div className="px-1">
          <IconSVG name="Arrow Right" width="0.5em" />
        </div>
        <BasicDateSelect
          isTouch
          date={endDate ? endDate.toDate() : null}
          id={endDateId}
          onChange={(date) => handleChange(date, 'endDate')}
          className="end-date"
        />
      </div>
    );
  }

  let propObj = {
    showDefaultInputIcon: true,
    startDateId,
    endDateId,
    displayFormat: browserDisplayFormat,
    openDirection: 'down',
    startDate,
    endDate,
    onDatesChange: onChange,
    focusedInput,
    onFocusChange: (fInp) => setFocusedInput(fInp),
    disabled,
    block,
    verticalSpacing: 10,
  };

  if (!futureDates) {
    propObj = { ...propObj, isOutsideRange: (day) => !isInclusivelyBeforeDay(day, moment()) };
  }

  return <DateRangePicker {...propObj} />;
}

RangePicker.propTypes = {
  isTouch: PropTypes.bool,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  id: PropTypes.string,
  displayFormat: PropTypes.string,
  futureDates: PropTypes.bool,
};

RangePicker.defaultProps = {
  isTouch: false,
  block: true,
  disabled: false,
  startDate: null,
  endDate: null,
  onChange: () => {},
  id: null,
  displayFormat: 'DD-MM-YYYY',
  futureDates: true,
};

export default RangePicker;
