// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLanguagePack, useMounted } from '../../Base/hooks';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getCandidateTimeline } from '../../../api/CandidateAPI/CandidateAPI';
import TimelineContainer from './TimelineContainer';
import TimelineEntry from './TimelineEntry';

async function getTimeline(candidateId, accountId, types, onSuccess = () => {}, onError = () => {}) {
  const resp = await retryableAPICall(() => getCandidateTimeline(candidateId, accountId, types));

  if (typeof result === 'string') {
    onError();
  } else {
    onSuccess(resp);
  }
}

function replaceKeys(content, languagePack) {
  return content.replace(/\{(\w*)}/g, (m, key) => (languagePack[key] ? languagePack[key] : key));
}

function CandidateTimeline({ candidateId, accountId }) {
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [error, setError] = useState(false);
  const languagePack = useLanguagePack('timeline-container');
  const [loading, setLoading] = useState(false);
  const isMounted = useMounted();
  const [availableTypes, setAvailableTypes] = useState([]);

  function updateTimeline(types) {
    getTimeline(
      candidateId,
      accountId,
      types,
      (resp) => {
        if (availableTypes.length === 0) {
          setAvailableTypes(resp.map((evn) => evn.type));
        }

        setTimelineEvents(
          resp.map((evt) => ({
            ...evt,
            eventDatetime: moment.utc(evt.eventDatetime).local().format('DD-MM-YYYY HH:mm:ss'),
          })),
        );
        setLoading(false);
      },
      () => setError(true),
    );
  }
  useEffect(() => {
    if (isMounted() && candidateId) {
      setLoading(true);

      updateTimeline();
    }
  }, [accountId, candidateId, isMounted]);

  return (
    <TimelineContainer
      className="candidate-timeline"
      error={error}
      errorMessage={languagePack.timelineError || 'An error occurred when loading the timeline'}
      loading={loading}
      onTypeChange={(val) => updateTimeline(val)}
      title={languagePack.fullTimelineTitle || 'Application Timeline'}
      types={availableTypes}
    >
      {timelineEvents.map((evt) => {
        const { id, title, eventDatetime, userName, content, type } = evt;
        return (
          <TimelineEntry
            key={id}
            content={content && !!content.length && replaceKeys(content, languagePack)}
            createdDateTime={eventDatetime}
            subtitle={userName}
            title={replaceKeys(title, languagePack)}
            type={type}
          />
        );
      })}
    </TimelineContainer>
  );
}

CandidateTimeline.propTypes = {
  accountId: PropTypes.string,
  candidateId: PropTypes.string,
};

CandidateTimeline.defaultProps = {
  accountId: null,
  candidateId: null,
};

export default CandidateTimeline;
