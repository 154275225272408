import React from 'react';
import PropTypes from 'prop-types';
import AccountFormSelect from './AccountFormSelect';

function AccountSelectWidget(props) {
  const {
    schema: { title },
    idSchema: { $id: id },
    formContext,
    uiSchema,
    onChange,
    required,
    formData,
  } = props;
  const { 'ui:options': uiOpts = {}, className } = uiSchema;
  const { isMulti = true } = uiOpts;
  let { ignoreActiveAccount = true } = uiOpts;

  // override options with context from form
  if (formContext.ignoreActiveAccount) {
    ignoreActiveAccount = formContext.ignoreActiveAccount;
  }

  return (
    <AccountFormSelect
      className={`account-opts disabled-margin-bottom pb-2 ${className || ''}`}
      currentAccounts={(formData || []).map(({ accountId, accountName }) => ({ id: accountId, name: accountName }))}
      id={id}
      ignoreActiveAccount={ignoreActiveAccount}
      isMulti={isMulti}
      label={title}
      onChange={(accountObj) => {
        let updatedVal = accountObj;

        if (isMulti) {
          updatedVal = accountObj.map(({ value, label }) => ({ accountId: value, accountName: label }));
        } else {
          updatedVal = [
            {
              accountId: accountObj.value,
              accountName: accountObj.label,
            },
          ];
        }

        onChange(updatedVal);
      }}
      required={required}
    />
  );
}

AccountSelectWidget.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  idSchema: PropTypes.shape({
    $id: PropTypes.string,
  }),
  uiSchema: PropTypes.shape({
    'ui:options': PropTypes.shape({
      ignoreActiveAccount: PropTypes.bool,
      isMulti: PropTypes.bool,
    }),
    className: PropTypes.string,
  }),
  formContext: PropTypes.shape({
    ignoreActiveAccount: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  formData: PropTypes.arrayOf(PropTypes.shape()),
  required: PropTypes.bool,
};

AccountSelectWidget.defaultProps = {
  schema: {},
  idSchema: {},
  uiSchema: {},
  formContext: {},
  onChange: () => {},
  formData: [],
  required: false,
};

export default AccountSelectWidget;
