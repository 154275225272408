import React from 'react';
import PropTypes from 'prop-types';

import { ModalBody, Button, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import { Loader } from '../Loading';

export default function Confirmation({
  show,
  className,
  error,
  confirmCallback,
  cancelCallback,
  type,
  hideCancel,
  content,
  confText,
  cancelText,
  size,
  title,
  isLoading,
}) {
  const typeClass = type ? `modal-${type}` : 'modal-info';

  return (
    <Modal centered className={cx(className, typeClass)} isOpen={show} size={size}>
      {title && <ModalHeader>{title}</ModalHeader>}
      <ModalBody>
        {error && <p className="error-detail">{error}</p>}
        {isLoading ? (
          <div style={{ height: '200px' }}>
            <Loader />
          </div>
        ) : (
          content
        )}
      </ModalBody>
      {!isLoading && (
        <ModalFooter>
          {!hideCancel && (
            <Button className="btn-outline" id="confirmation-cancel-btn" onClick={cancelCallback}>
              {cancelText}
            </Button>
          )}
          <Button color={type} id="confirmation-confirm-btn" onClick={confirmCallback}>
            {confText}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

Confirmation.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  confirmCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  type: PropTypes.string,
  hideCancel: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  confText: PropTypes.string,
  cancelText: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

Confirmation.defaultProps = {
  show: false,
  className: null,
  error: null,
  confirmCallback: () => {},
  cancelCallback: () => {},
  type: 'info',
  hideCancel: false,
  content: 'Are you sure?',
  confText: 'Confirm',
  cancelText: 'Cancel',
  size: 'md',
  title: null,
  isLoading: false,
};
