import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import {FormModal} from '../../Base/Modal';
import ActionToBePerformedNoDataState from '../Other/ActionToBePerformedNoDataState';
import waiting from '../../../assets/img/nodata/waiting.png';

function DbsCheckRequestedContainer({languagePack,}) {
  const [modalConfig, setModalConfig] = useState({isOpen: false, url: ''});

  return (
    <>
      <Row>
        <Col>
          <div className="onboarding-container-empty mb-4">
            <div className="onboarding-container-center">
              <Row className="mb-1">
                <Col className="text-center">{languagePack.onboardingInformationNotReceived}</Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <>
                    <ActionToBePerformedNoDataState
                      image={waiting}
                      title="DBS Screening Requested"
                      description="The DBS Screening has been requested and we are waiting for the candidate to complete it."
                    />
                  </>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

DbsCheckRequestedContainer.propTypes = {
  languagePack: PropTypes.shape(),
};

DbsCheckRequestedContainer.defaultProps = {
  languagePack: {},
};

export default DbsCheckRequestedContainer;
