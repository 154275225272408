import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import UploadWrapper, { uploadFile, deleteFile, fileTypes, truncateFilename } from './UploadWrapper';
import config from '../../../../../config/config';
import { Confirmation } from '../../../Modal';
import IconSVG from '../../../SVG/IconSVG';

function handleDeleteError() {
  toast.error('Error deleting file', {
    autoClose: true,
  });
}

export default function FileUpload(props) {
  const { formData, onChange } = props;

  const [filesInfo, setFilesInfo] = useState(formData);
  const [isUploading, setIsUploading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState();
  const [deleteId, setDeleteId] = useState();

  async function handleChange(files) {
    // bail if no file to handle
    if (!Array.isArray(files) || !files.length) return;

    setIsUploading(true);

    const uploads = await Promise.all(files.map((file) => uploadFile(file, config.company.companyDocumentPath, true)));

    if (!uploads.includes(false)) {
      setIsServerError(false);
      setFilesInfo([...filesInfo, ...uploads]);
      onChange([...filesInfo, ...uploads]);
    } else {
      setIsServerError(true);
    }

    setIsUploading(false);
  }

  function handleConfirm(file) {
    setIsServerError(false);
    setConfirmText(`Are you sure you want to delete ${file.fileName}?`);
    setShowConfirm(true);
    setDeleteId(file.id);
  }

  async function handleDeleteFile() {
    if (~filesInfo.findIndex((file) => file.id === deleteId)) {
      const deleteRes = await deleteFile(deleteId, config.company.companyDocumentPath);

      if (deleteRes) {
        // remove from files to be uploaded
        const updatedFilesInfo = filesInfo.filter((file) => file.id !== deleteId);
        setFilesInfo(updatedFilesInfo);
        onChange(updatedFilesInfo);
      } else {
        handleDeleteError();
      }
    } else {
      handleDeleteError();
    }
    setShowConfirm(false);
  }

  return (
    <>
      <UploadWrapper
        {...props}
        errorMessage="There was an error processing one or more of your files."
        isServerError={isServerError}
        isUploading={isUploading}
        onChange={handleChange}
      >
        {Boolean(filesInfo.length) && (
          <ul className="list-unstyled uploaded-documents">
            {filesInfo.map((file) => {
              const { id: url, originalFileName, fileType } = file;

              return (
                <li key={url} title={originalFileName}>
                  <IconSVG fill="#7C89E3" name="File" type={fileTypes[fileType] || 'file'} />
                  {truncateFilename(originalFileName)}
                  <button
                    className="delete-file"
                    onClick={() => handleConfirm(file)}
                    title={`Delete ${originalFileName}`}
                    type="button"
                  >
                    +
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </UploadWrapper>
      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={handleDeleteFile}
        content={confirmText}
        show={showConfirm}
        title="Delete File?"
      />
    </>
  );
}

FileUpload.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      originalFileName: PropTypes.string,
      fileType: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  formData: [],
  onChange: () => {},
};
