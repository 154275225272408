import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { LoadingScreen } from '../Loading';
import { useMounted } from '../hooks';

export const FADE_TIMES = {
  delay: 500,
  duration: 1000,
};

function FadeIn({
  delay,
  duration,
  onDisplay,
  children,
}) {
  const isMounted = useMounted();
  const timer = useRef();
  const [hidden, setHidden] = useState(true);

  const transition = `opacity ${(duration / 1000)}s`;

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (timer.current) clearTimeout(timer.current);
      if (isMounted()) {
        setHidden(false);
        onDisplay();
      }
    }, delay);
  }, [hidden, delay, onDisplay, isMounted]);

  return (
    <Fragment>
      <LoadingScreen
        isEmbeded
        styles={{
          opacity: hidden ? 1 : 0,
          transition,
          zIndex: -1,
        }}
      />
      <div
        style={{
          opacity: hidden ? 0 : 1,
          transition,
        }}
      >
        {!hidden && children}
      </div>
    </Fragment>
  );
}

FadeIn.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.number,
  onDisplay: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FadeIn.defaultProps = {
  ...FADE_TIMES,
  onDisplay: () => { },
  children: null,
};

export default FadeIn;
