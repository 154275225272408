import React from 'react';
import PropTypes from 'prop-types';

export const optionDataProp = PropTypes.arrayOf(PropTypes.oneOfType([
  PropTypes.shape(),
  PropTypes.string,
]));

export default function SelectOption({ value, disabled, children }) {
  return <option value={value} disabled={disabled}>{children}</option>;
}

SelectOption.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

SelectOption.defaultProps = {
  disabled: false,
};
