import {
  REMOVE_SLOT_BOOKING,
  ADD_SLOT_BOOKING_CANDIDATES,
  REMOVE_SLOT_BOOKING_CANDIDATES,
} from '../constants/actionTypes';

function removeSlot(id, obj) {
  const { [id]: arr, ...rest } = obj;
  return { ...rest };
}

const defaultState = {
  slotBookings: {},
};

export default function bookingReducer(state = defaultState, action) {
  const { type, payload = {} } = action;

  const { id, candidateIds } = payload;

  switch (type) {
    case ADD_SLOT_BOOKING_CANDIDATES:
      return {
        ...state,
        slotBookings: {
          ...state.slotBookings,
          [id]: state.slotBookings[id] ? candidateIds.reduce((acc, candidateId) => {
            if (!acc.includes(candidateId)) acc.push(candidateId);
            return acc;
          }, state.slotBookings[id]) : candidateIds,
        },
      };
    case REMOVE_SLOT_BOOKING_CANDIDATES:
      return {
        ...state,
        slotBookings: {
          ...state.slotBookings,
          [id]: state.slotBookings[id].filter((candidateId) => !candidateIds.includes(candidateId)),
        },
      };
    case REMOVE_SLOT_BOOKING:
      return {
        ...state,
        slotBookings: removeSlot(id, state.slotBookings),
      };
    default:
      return state;
  }
}
