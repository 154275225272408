import React from 'react';
import PropTypes from 'prop-types';
import {
  Arrow,
  Bell,
  Bin,
  Briefcase,
  Calendar,
  Chevron,
  Clock,
  CloudUpload,
  Columns,
  Comment,
  Copy,
  CrossCircle,
  Edit,
  Ellipsis,
  Email,
  ExclamationCircle,
  Eye,
  Exit,
  Desktop,
  FallingPeople,
  Funnel,
  Gear,
  Industry,
  Link,
  InfoCircle,
  MapMarker,
  Message,
  Microphone,
  Mobile,
  Money,
  NoDataTriangle,
  File,
  PaperClip,
  PaperPlane,
  Pencil,
  Phone,
  ShareNodes,
  Tablet,
  Tag,
  TalentFunnel,
  ThumbDown,
  User,
  Video,
  HashTag,
  Globe,
  Tick,
  Cross,
  QRCode,
  BarChart,
  Tree,
  Fire,
  Undo,
} from './Icons';
import Help from './Icons/Help';
import ThumbUp from './Icons/ThumbUp';
import WhatsApp from './Icons/WhatsApp';
import Redo from './Icons/Redo';
import Expand from './Icons/Expand';

function IconSVG(props) {
  const { name } = props;

  switch (name) {
    case 'Add Circle':
      return <CrossCircle {...props} transform="rotate(45 16 16)" />;
    case 'Arrow Down':
      return <Arrow {...props} transform="rotate(90 224 256)" />;
    case 'Arrow Left':
      return <Arrow {...props} transform="rotate(180 224 256)" />;
    case 'Arrow Right':
      return <Arrow {...props} />;
    case 'Arrow Up':
      return <Arrow {...props} transform="rotate(-90 224 256)" />;
    case 'Bar Chart':
      return <BarChart {...props} />;
    case 'Bell':
      return <Bell {...props} />;
    case 'Bell Hollow':
      return <Bell {...props} type="bellHollow" />;
    case 'Bell Solid':
      return <Bell {...props} type="notificationBell" />;
    case 'Bin':
      return <Bin {...props} />;
    case 'Briefcase':
      return <Briefcase {...props} />;
    case 'Calendar':
      return <Calendar {...props} />;
    case 'Chevron':
      return <Chevron {...props} />;
    case 'Chevron Left':
      return <Chevron {...props} transform="rotate(-90 16 16)" />;
    case 'Chevron Right':
      return <Chevron {...props} transform="rotate(90 16 16)" />;
    case 'Chevron Up':
      return <Chevron {...props} transform="rotate(180 16 16)" />;
    case 'Clock':
      return <Clock {...props} />;
    case 'Close Circle':
      return <CrossCircle {...props} />;
    case 'Cloud Upload':
      return <CloudUpload {...props} />;
    case 'Columns':
      return <Columns {...props} />;
    case 'Comment':
      return <Comment {...props} />;
    case 'Comment Solid':
      return <Comment {...props} type="commentSolid" />;
    case 'Comments':
      return <Comment {...props} type="comments" />;
    case 'Comments Solid':
      return <Comment {...props} type="commentsSolid" />;
    case 'Copy':
      return <Copy {...props} />;
    case 'Cross':
      return <Cross {...props} />;
    case 'Eye':
      return <Eye {...props} />;
    case 'Eye Slash':
      return <Eye {...props} type="eyeSlash" />;
    case 'Edit':
      return <Edit {...props} />;
    case 'Ellipsis':
      return <Ellipsis {...props} />;
    case 'Email':
      return <Email {...props} transform="translate(3, 6)" />;
    case 'Email Solid':
      return <Email {...props} type="emailSolid" />;
    case 'Exclamation Circle':
      return <ExclamationCircle {...props} />;
    case 'Expand':
      return <Expand {...props} />;
    case 'Exit':
      return <Exit {...props} />;
    case 'Desktop':
      return <Desktop {...props} />;
    case 'Falling People':
      return <FallingPeople {...props} />;
    case 'File':
      return <File {...props} />;
    case 'File Form Alt':
      return <File {...props} type="formAlt" />;
    case 'File Solid':
      return <File {...props} type="solid" />;
    case 'File Image':
      return <File {...props} type="image" />;
    case 'File PDF':
      return <File {...props} type="pdf" />;
    case 'File Word':
      return <File {...props} type="word" />;
    case 'Fire':
      return <Fire {...props} />;
    case 'Funnel':
      return <Funnel {...props} />;
    case 'Gear':
      return <Gear {...props} />;
    case 'Globe':
      return <Globe {...props} />;
    case 'HashTag':
      return <HashTag {...props} />;
    case 'Help':
      return <Help {...props} />;
    case 'Industry':
      return <Industry {...props} />;
    case 'Info Circle':
      return <InfoCircle {...props} />;
    case 'Link':
      return <Link {...props} />;
    case 'Map Marker':
      return <MapMarker {...props} />;
    case 'Message':
      return <Message {...props} />;
    case 'Microphone':
      return <Microphone {...props} />;
    case 'Microphone Off':
      return <Microphone {...props} type="micSlash" />;
    case 'Mobile':
      return <Mobile {...props} />;
    case 'Money':
      return <Money {...props} />;
    case 'No Data Triangle':
      return <NoDataTriangle {...props} />;
    case 'Paper Clip':
      return <PaperClip {...props} />;
    case 'Paper Clip Solid':
      return <PaperClip {...props} type="solid" />;
    case 'Paper Plane':
      return <PaperPlane {...props} />;
    case 'Paper Plane Boxed':
      return <PaperPlane {...props} transform="translate(1000, 0) scale(-1, 1)" type="paperPlaneBoxed" />;
    case 'Plus Circle':
      return <CrossCircle {...props} transform="rotate(45 16 16)" />;
    case 'Pencil':
      return <Pencil {...props} />;
    case 'Phone':
      return <Phone {...props} />;
    case 'QR Code':
      return <QRCode {...props} />;
    case 'Redo':
      return <Redo {...props} />;
    case 'Tablet':
      return <Tablet {...props} />;
    case 'Tag':
      return <Tag {...props} />;
    case 'Tick':
      return <Tick {...props} />;
    case 'Talent Funnel':
      return <TalentFunnel {...props} />;
    case 'Thumb Down':
      return <ThumbDown {...props} />;
    case 'Thumb Down Solid':
      return <ThumbDown {...props} type="thumbDownSolid" />;
    case 'Thumb Up':
      return <ThumbUp {...props} />;
    case 'Thumb Up Solid':
      return <ThumbUp {...props} type="thumbUpSolid" />;
    case 'Tree':
      return <Tree {...props} />;
    case 'ShareNodes':
      return <ShareNodes {...props} />;
    case 'Undo':
      return <Undo {...props} />;
    case 'User':
      return <User {...props} type="user" />;
    case 'User Multi':
      return <User {...props} type="userMulti" />;
    case 'Video':
      return <Video {...props} />;
    case 'Video Off':
      return <Video {...props} type="videoSlash" />;
    case 'WhatsApp':
      return <WhatsApp {...props} />;
    default:
      return null;
  }
}

IconSVG.propTypes = {
  name: PropTypes.string.isRequired,
};

export default IconSVG;
