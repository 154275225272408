import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
import Form from 'react-jsonschema-form';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMounted } from '../../../Base/hooks';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getCompanyProfileForAccount, updateCompanyProfile } from '../../../../api/CompanyAPI/CompanyAPI';
import { CancelButton, EditButton } from '../../../Base/Buttons';
import { ClientAdminSlider, PageColumn } from '../Dashboard';
import {
  companyProfileSchema,
  companyProfileUiSchema,
} from '../../../../js/model/form-schemas/company-profile/CompanyProfileSchema';
import { transformErrors, validateFields } from '../../../../js/utils/validation-helper';
import LimitedTextArea from '../../../Base/Forms/Custom/RTE/RTE';
import AddressLookup from '../../../Base/Forms/Custom/AddressLookup/AddressLookup';
import DatePicker from '../../../Base/Forms/Custom/DatePicker/DatePicker';
import DDSelect from '../../../Base/Forms/Custom/DDSelect/DDSelect';
import RadioBtns from '../../../Base/Forms/Custom/RadioBtns/RadioBtns';
import { ImageUpload, FileUpload } from '../../../Base/Forms/Custom/FileUpload';
import { RowsWrapper } from '../../../Base/ValueDisplay';
import ProfileImage from './ProfileImage';
import EnhancedCard from '../Common/EnhancedCard';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';

function mapLatLongToGeolocation(address) {
  const { lat, long, ...rest } = address;
  return {
    name: '',
    address: {
      ...rest,
      geoLocation: {
        coordinates: [long, lat],
      },
    },
  };
}

function mapGeolocationToLatLong(address) {
  const { address: { geoLocation: { coordinates } } } = address;
  const [long, lat] = coordinates[0];

  return {
    address: {
      ...address.address,
      lat,
      long,
    },
  };
}

async function getCompanyProfile(onSuccess = () => {
}, onError = () => {
}) {
  const resp = await retryableAPICall(() => getCompanyProfileForAccount());

  if (typeof resp === 'string') {
    onError(resp);
  } else {
    const {
      profile = {},
      documents = [],
      locations = [],
      id,
    } = resp;
    const {
      logoFullUrl = '',
      bannerUrl = '',
      additionalFields = {},
    } = profile;

    onSuccess({
      ...profile,
      logo: logoFullUrl,
      banner: bannerUrl,
      documents,
      address: locations && locations.length ? mapGeolocationToLatLong(locations[0]) : {},
      phone: (additionalFields || {}).phoneNumber,
      companyId: id,
    });
  }
}

const FormWithConditionals = applyRules(companyProfileSchema, companyProfileUiSchema, [], Engine)(Form);

function CompanyProfile({ openSlider, onClose }) {
  const isMounted = useMounted();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [errorStatus, setErrorStatus] = useState();
  const [companyObj, setCompanyObj] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isMounted()) {
      getCompanyProfile((resp) => {
        setLoadingError(false);
        setCompanyObj(resp);
        setIsLoading(false);
      }, (resp) => {
        setLoadingError(true);
        setErrorStatus(resp);
        setIsLoading(false);
      });
    }
  }, [isMounted]);

  useEffect(() => {
    setIsSliderOpen(openSlider);
  }, [openSlider]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (openSlider) onClose();
  }

  async function handleSubmit({ formData }) {
    const { companyId } = companyObj;

    setIsSaving(true);
    setCompanyObj({ ...companyObj, ...formData });

    const documents = formData.documents ? [...formData.documents] : [];
    const locations = [mapLatLongToGeolocation(formData.address.address)];

    const updatedData = {
      ...formData,
      logoFullUrl: formData.logo,
      bannerUrl: formData.banner,
      additionalFields: {
        phoneNumber: formData.phone,
      },
    };
    delete updatedData.logo;
    delete updatedData.address;
    delete updatedData.documents;
    delete updatedData.phone;

    const resp = await retryableAPICall(() => updateCompanyProfile(companyId, updatedData, locations, documents));

    if (typeof resp === 'string') {
      toast.error('There was an error updating the profile. Please try later.');
    } else {
      toast.success('Successfully updated the profile');
    }

    setIsSaving(false);
    handleCloseSlider();
  }

  const saveBtnText = isSaving ? 'Saving...' : 'Save Profile';

  return (
    <Fragment>
      <PageColumn
        state={{
          isPending: isLoading,
          isRejected: loadingError,
          rejectionStatus: errorStatus,
        }}
        className='pt-3'
      >
        <div className='d-flex justify-content-center w-100' style={{ marginLeft: '7px' }}>
          <EnhancedCard className='mt-3' style={{ maxWidth: '1600px', width: '100%' }}>
            <EnhancedCardTitle
              title="Company Profile"
              subtitle="Information describing your branch/location"
            />
            <CardBody>
              <RowsWrapper
                rows={[
                  { title: 'Logo', value: companyObj.logo ? <ProfileImage src={companyObj.logo} alt='Logo' /> : null },
                  {
                    title: 'Banner',
                    value: companyObj.banner ? (
                      <ProfileImage src={companyObj.banner} alt='Banner' className='w-100' />
                    ) : null,
                  },
                  { title: 'Address', value: companyObj?.address?.address?.formattedAddress },
                  { title: 'Company Sector', value: companyObj.industry },
                  { title: 'Website URL', value: companyObj.website },
                  { title: 'Phone number', value: companyObj.phone },
                  { title: 'Founded Year', value: companyObj.foundedYear },
                  { title: 'Size', value: companyObj.size },
                  { title: 'Description / Profile', html: companyObj.description },
                  {
                    title: 'Documents',
                    value: (companyObj.documents || []).map(({ originalFileName }) => originalFileName),
                    asList: true,
                  },
                ]}
              />
            </CardBody>
          </EnhancedCard>
        </div>
      </PageColumn>
      <ClientAdminSlider
        title='Edit Company Profile'
        isSliderOpen={isSliderOpen}
        closeSlider={handleCloseSlider}
      >
        <FormWithConditionals
          formData={companyObj}
          onSubmit={handleSubmit}
          noHtml5Validate
          showErrorList={false}
          validate={validateFields}
          transformErrors={transformErrors}
          fields={{
            rte: LimitedTextArea,
            addressLookup: AddressLookup,
            datePicker: DatePicker,
            select: DDSelect,
            radioBtns: RadioBtns,
            imageUpload: ImageUpload,
            fileUpload: FileUpload,
          }}
        >
          <EditButton isLoading={isSaving} disabled={isSaving} label={saveBtnText} type='submit' />
          <CancelButton disabled={isSaving} label='Cancel' type='submit' action={handleCloseSlider} />
        </FormWithConditionals>
      </ClientAdminSlider>
    </Fragment>
  );
}

CompanyProfile.propTypes = {
  openSlider: PropTypes.bool,
  onClose: PropTypes.func,
};

CompanyProfile.defaultProps = {
  openSlider: false,
  onClose: () => {
  },
};

export default CompanyProfile;
