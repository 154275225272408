import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getEditFormToken } from '../../../api/FormsAPI/ATSFormsAPI';

export async function generateToken(formId, onSuccess = () => {}) {
  if (formId) {
    const resp = await retryableAPICall(() => getEditFormToken(formId));

    if (typeof resp === 'string') {
      toast.error('Error generating token');
    } else {
      const { token } = resp;
      if (token) onSuccess(token);
    }
  }
}
