import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { references, api } = config;
const referenceBasePath = api.middlewareAPIURL + references.referenceBasePath;
const refereeBasePath = api.middlewareAPIURL + references.refereeBasePath;

/**
 * All API calls expected to be called inside retryableAPICall function to manage error handling.
 */

export async function getReferences(candidateId) {
  const result = await axios.get(
    `${referenceBasePath}/${candidateId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function requestRefereeDetails(candidateId) {
  const result = await axios.get(
    `${refereeBasePath}/${candidateId}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendReferenceRequests(referenceRequestId, emails) {
  let url = `${referenceBasePath}/reference/request/${referenceRequestId}`;

  if (emails && Array.isArray(emails)) {
    url += '?';
    emails.forEach((email) => {
      url += `refereeEmails=${email}&`;
    });
    url = url.substring(0, url.length - 1);
  }

  const result = await axios.post(
    url,
    {},
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function sendReferenceReminder(candidateId, refereeId) {
  const result = await axios.post(
    `${refereeBasePath}/reminder`,
    {
      candidateId,
      refereeId,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function downloadCompletedReferences(referenceRequestId) {
  const url = `${api.middlewareAPIURL}${references.referenceBasePath}${references.downloadReferencesPath}/${referenceRequestId}`;

  const result = await axios.get(
    url,
    { headers: getCommonHeaders() },
  );

  const blob = await (await fetch(`data:application/pdf;base64,${result.data.data}`)).blob();

  if (result.status === 200) return fileDownload(blob, result.data.fileName);
  return null;
}
