import React from 'react';
import PropTypes from 'prop-types';

function ColumnListSearch({ onSearch }) {
  return (
    <div className="custom-search-input">
      <input
        type="search"
        className="search-query form-control"
        // defaultValue={value}
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Search..."
      />
      <span className="fa fa-search form-control-feedback" />
    </div>
  );
}

ColumnListSearch.propTypes = {
  onSearch: PropTypes.func,
};

ColumnListSearch.defaultProps = {
  onSearch: () => { },
};

export default ColumnListSearch;
