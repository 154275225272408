function createSelect(opts) {
  const select = document.createElement('select');

  opts.forEach((opt) => {
    const { text, value } = opt;
    const option = document.createElement('option');
    option.textContent = text;
    if (value) {
      option.value = value;
    } else {
      option.disabled = 'disabled';
      option.selected = 'selected';
    }
    select.appendChild(option);
  });

  return select;
}

function calculatePadding(width, padding) {
  return `${(width / 100) * padding}%`;
}

const CONTROL_CLASS = 'ql-video-controls';

export function removeControlsFromSource(content) {
  if (!content) return content;
  let wrapper = document.createElement('div');
  wrapper.innerHTML = content;
  const controls = wrapper.querySelectorAll(`.${CONTROL_CLASS}`);
  if (controls && controls.length) controls[0].parentNode.removeChild(controls[0]);
  const source = wrapper.innerHTML;
  wrapper = null;
  return source;
}

export default class ResizeVideo {
  constructor(videoContainer, iframe) {
    this.videoContainer = videoContainer;
    this.iframe = iframe;

    this.sizes = [25, 50, 75, 100];
    this.paddingBottom = 56.25;
    this.positions = ['Left', 'Float Left', 'Center', 'Float Right', 'Right'];
  }

  init(width, styles) {
    this.setContainerStyles(width, styles);
    this.setIframeStyles();
  }

  setContainerStyles(width, styles) {
    this.videoContainer.style.position = 'relative';
    this.videoContainer.style.height = 0;
    this.videoContainer.style.maxWidth = '100%';
    this.videoContainer.style.width = width || '100%';
    this.videoContainer.style.paddingBottom = width
      ? calculatePadding(parseInt(width, 10), this.paddingBottom)
      : `${this.paddingBottom}%`;

    if (styles) {
      // set position styles on load
      Object.entries(styles).forEach(([style, val]) => {
        if (val && val.length) this.videoContainer.style[style] = val;
      });
    }
  }

  setIframeStyles() {
    this.iframe.style.position = 'absolute';
    this.iframe.style.left = 0;
    this.iframe.style.top = 0;
    this.iframe.height = '100%';
    this.iframe.width = '100%';
  }

  handleSizeChange(width) {
    const { right } = this.videoContainer.style;

    this.videoContainer.style.width = `${width}%`;
    this.videoContainer.style.paddingBottom = calculatePadding(width, this.paddingBottom);

    // recalulate right position
    if (right) this.videoContainer.style.right = `-${100 - width}%`;
    // 100% doesn't require any extra styles
    if (width === 100) this.resetStyles();
  }

  resetStyles() {
    // any styles added here need to be added in styles object
    // WrapVideo.value() -> styles object props
    this.videoContainer.style.margin = null;
    this.videoContainer.style.marginLeft = null;
    this.videoContainer.style.marginRight = null;
    this.videoContainer.style.float = null;
    this.videoContainer.style.left = null;
    this.videoContainer.style.right = null;
  }

  handlePositionChange(position) {
    const { width } = this.videoContainer.style;

    if (width === '100%') return;

    this.resetStyles();

    if (position === 'center') {
      this.videoContainer.style.margin = '0 auto';
    } else if (position === 'right') {
      this.videoContainer.style.right = `-${100 - parseInt(width, 10)}%`;
    } else if (position === 'float-start') {
      this.videoContainer.style.float = 'left';
      this.videoContainer.style.marginRight = '1rem';
    } else if (position === 'float-end') {
      this.videoContainer.style.float = 'right';
      this.videoContainer.style.marginLeft = '1rem';
    } else {
      this.videoContainer.style.left = 0;
    }
  }

  createSizeControl() {
    const wrapper = document.createElement('div');
    wrapper.className = 'ql-video-size-controls';

    const sizes = this.sizes.reduce(
      (acc, size) => {
        acc.push({
          text: `${size}%`,
          value: size,
        });
        return acc;
      },
      [{ text: 'Size' }],
    );

    const select = createSelect(sizes);
    select.addEventListener('change', (e) => {
      const {
        target: { value },
      } = e;
      this.handleSizeChange(value);
    });

    wrapper.appendChild(select);

    return wrapper;
  }

  createAlignControl() {
    const wrapper = document.createElement('div');
    wrapper.className = 'ql-video-size-controls';

    const positions = this.positions.reduce(
      (acc, position) => {
        acc.push({
          text: position,
          value: position.toLowerCase().replace(' ', '-'),
        });
        return acc;
      },
      [{ text: 'Align' }],
    );

    const select = createSelect(positions);
    select.addEventListener('change', (e) => {
      const {
        target: { value },
      } = e;
      this.handlePositionChange(value);
    });

    wrapper.appendChild(select);

    return wrapper;
  }

  createControls() {
    const controls = document.createElement('div');
    controls.setAttribute('contenteditable', 'false');
    controls.className = CONTROL_CLASS;
    controls.appendChild(this.createSizeControl());
    controls.appendChild(this.createAlignControl());
    return controls;
  }
}
