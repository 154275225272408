/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import cx from 'classnames';
import { DeleteButton, CreateButton } from '../../../../Base/Buttons';
import Question, { questionPropType } from './Question';
import { Confirmation } from '../../../../Base/Modal';
import { createID, spliceItem, wrapperRefPropType } from '../utils';
import FormManagerContext from './FormManagerContext';
import { IconSVG } from '../../../../Base/SVG';
import ErrorMessage from './ErrorMessage';
import { RestrictedMarker } from '../components';

function Section({
  id,
  name,
  questions: qArr,
  questionProps,
  index,
  isApplicationForm,
  isLastItem,
  isSubmitted,
  logicQuestionsIds,
  onDelete,
  onChange,
  onSetLogic,
  onMoveItem,
  formType,
}) {
  const isRestricted = useRef();
  const [sectionName, setSectionName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteQNum, setDeleteQNum] = useState(0);

  useEffect(() => {
    setSectionName(name);
    setQuestions(qArr);
    isRestricted.current = qArr.some(({ config = {} }) => config.restricted);
  }, [name, qArr]);

  function handleDeleteQuestion(qNum) {
    const updatedQuestions = spliceItem(questions, qNum - 1);
    setQuestions(updatedQuestions);
    onChange({
      id,
      name: sectionName,
      questions: updatedQuestions,
    });
  }

  function handleMoveQuestion(currentPosition, newPosition) {
    const newQuestions = [...questions];
    const questionToMove = newQuestions[currentPosition];

    newQuestions[currentPosition] = newQuestions[newPosition];
    newQuestions[newPosition] = questionToMove;

    setQuestions(newQuestions);
    onChange({
      id,
      name: sectionName,
      questions: newQuestions,
    });
  }

  function handleChangeQuestion(qConfig, oldId) {
    const { number } = qConfig;
    const idx = number - 1;
    const updatedQuestions = spliceItem(questions, idx, {
      ...questions[idx],
      ...qConfig,
    });
    const hasIdChanged = qConfig.id !== oldId;
    const conditionalChange = hasIdChanged ? { newId: qConfig.id, oldId } : null;
    setQuestions(updatedQuestions);
    onChange(
      {
        id,
        name: sectionName,
        questions: updatedQuestions,
      },
      conditionalChange,
    );
  }

  function handleAddQuestion(newQuestArr) {
    setQuestions(newQuestArr);
    onChange({
      id,
      name: sectionName,
      questions: newQuestArr,
    });
  }

  return (
    <FormManagerContext.Consumer>
      {({ newSections, onQuestionAdd, onQuestionEdit }) => (
        <>
          <FormGroup
            className={cx('flexiform-section', { 'is-restricted': isRestricted.current || isApplicationForm })}
            tag="fieldset"
          >
            <div className="d-flex align-items-center mb-2">
              <Label className="mb-0 me-auto" for={id}>
                Section name
              </Label>
              <Tippy
                content="Move section up"
                disabled={index === 0 || (formType === 'APPLICATION_FORM' && index === 1)}
              >
                <div>
                  <Button
                    color="link"
                    disabled={index === 0 || (formType === 'APPLICATION_FORM' && index === 1)}
                    onClick={() => {
                      onMoveItem(index, index - 1);
                    }}
                    size="sm"
                  >
                    <IconSVG name="Chevron Up" width="1.5em" />
                  </Button>
                </div>
              </Tippy>
              <Tippy content="Move section down" disabled={isLastItem || (isApplicationForm && index === 0)}>
                <div>
                  <Button
                    color="link"
                    disabled={isLastItem || (isApplicationForm && index === 0)}
                    onClick={() => {
                      onMoveItem(index, index + 1);
                    }}
                    size="sm"
                  >
                    <IconSVG name="Chevron" width="1.5em" />
                  </Button>
                </div>
              </Tippy>
              {isRestricted.current || isApplicationForm ? (
                <RestrictedMarker
                  tooltipMessage={
                    isApplicationForm
                      ? 'This area is mandatory so cannot be deleted'
                      : 'This section cannot be removed because it has a restricted question.'
                  }
                >
                  Section
                </RestrictedMarker>
              ) : (
                <DeleteButton
                  disabled={isApplicationForm && index === 0}
                  action={onDelete}
                  className="btn-sm xs"
                  iconOnly
                  label="Delete Question"
                />
              )}
            </div>
            <FormGroup>
              <Input
                id={id}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  setSectionName(value);
                  onChange({
                    id,
                    name: value,
                    questions,
                  });
                }}
                type="text"
                // invalid={isSubmitted && !sectionName.length && !newSections.includes(id)}
                value={sectionName}
              />
              <ErrorMessage isInvalid={isSubmitted && !sectionName.length && !newSections.includes(id)}>
                Section name required
              </ErrorMessage>
            </FormGroup>
            <FormGroup tag="fieldset">
              <legend>Questions</legend>
              {questions.map((obj, i) => (
                <Question
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  index={i}
                  isApplicationForm={isApplicationForm && index === 0}
                  isLastItem={i === questions.length - 1}
                  isSubmitted={isSubmitted}
                  logicQuestionsIds={logicQuestionsIds}
                  onMoveItem={(currentPosition, newPosition) => {
                    handleMoveQuestion(currentPosition, newPosition);
                  }}
                  questionConfig={{ ...obj, number: i + 1 }}
                  sectionIndex={index}
                  {...questionProps}
                  onChange={(qConfig) => {
                    onQuestionEdit(qConfig.id);
                    handleChangeQuestion(qConfig, obj.id);
                  }}
                  onDelete={(qNum) => {
                    setDeleteQNum(qNum);
                    setShowConfirm(true);
                  }}
                  onSetLogic={(questionId, logic) => onSetLogic(id, questionId, logic)}
                />
              ))}
              <CreateButton
                action={(e) => {
                  e.preventDefault();
                  const qId = createID();
                  onQuestionAdd(qId);
                  handleAddQuestion([...questions, { id: qId }]);
                }}
                disabled={isApplicationForm && index === 0}
                className="btn-sm"
                floatRight={false}
                label="Add Question"
              />
            </FormGroup>
          </FormGroup>
          <Confirmation
            cancelCallback={() => setShowConfirm(false)}
            confirmCallback={() => {
              handleDeleteQuestion(deleteQNum);
              setShowConfirm(false);
            }}
            content="Are you sure you want to delete the question?"
            show={showConfirm}
            title="Delete Question?"
          />
        </>
      )}
    </FormManagerContext.Consumer>
  );
}

Section.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  isApplicationForm: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  logicQuestionsIds: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveItem: PropTypes.func,
  onSetLogic: PropTypes.func,
  questionProps: PropTypes.shape({
    isModal: PropTypes.bool,
    wrapperRef: wrapperRefPropType,
  }),
  questions: PropTypes.arrayOf(questionPropType),
};

Section.defaultProps = {
  id: null,
  index: 0,
  isApplicationForm: false,
  isLastItem: false,
  isSubmitted: false,
  logicQuestionsIds: [],
  name: '',
  onChange: () => {},
  onDelete: () => {},
  onMoveItem: () => {},
  onSetLogic: () => {},
  questionProps: {
    isModal: false,
    wrapperRef: null,
  },
  questions: [],
};

export default Section;
