import React from 'react';
import PropTypes from 'prop-types';

function PaginationResultCount({ currentResult, totalResults }) {
  return <span className="mx-2 font-weight-light">{`${currentResult} of ${totalResults}`}</span>;
}

PaginationResultCount.propTypes = {
  currentResult: PropTypes.number,
  totalResults: PropTypes.number,
};

PaginationResultCount.defaultProps = {
  currentResult: 1,
  totalResults: 0,
};

export default PaginationResultCount;
