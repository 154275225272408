// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Can from '../RBAC/Can/Can';
import TabItem from './TabItem';
import { usePopstate } from '../hooks';
import GroupTabItem from './GroupTabItem';

const RestrictedTab = Can(TabItem);

function getFirstItemAnchor(objArr) {
  // bail if wrong shiz passed
  if (!objArr || !Array.isArray(objArr) || !objArr.length) return null;
  // get first item
  const firstTab = { ...objArr[0] };
  // if anchor set return this
  if (firstTab.anchor) return firstTab.anchor;
  return null;
}

export default function Tabs(props) {
  const {
    onClick,
    onPopstate,
    tabs,
    languagePack,
    updateUrl,
    positionAbsolute,
    isVertical,
    tabProps,
    className,
    isToggle,
  } = props;
  let { activeTab } = props;

  // fail over to first item anchor
  if (!activeTab && !isToggle) activeTab = getFirstItemAnchor(tabs || []);

  const [activeTabId, setActiveTabId] = useState();
  const pushHistory = usePopstate((historyState) => {
    const tab = historyState?.tab;
    if (updateUrl) onPopstate(tab);
  });

  useEffect(() => {
    if (activeTab && !isToggle) setActiveTabId(activeTab);
  }, [activeTab, isToggle]);

  function handleTabClick(anchor, e) {
    e.preventDefault();

    if (updateUrl) pushHistory({ tab: anchor }, `#${anchor}`);

    if (isToggle) {
      if (anchor === activeTabId) {
        setActiveTabId();
      } else {
        setActiveTabId(anchor);
      }
    }

    onClick(anchor);
  }

  return (
    <ul className={cx('tab-list', className, { absolute: positionAbsolute })}>
      {tabs.map((tabObj) => {
        const { anchor, label, permissions, additionalLabel, labelComponent, icon, items } = tabObj;
        const isRestricted = 'permissions' in tabObj;
        const isActiveTab = isToggle && !activeTab ? false : activeTabId === anchor;
        const isGroup = Array.isArray(items);

        if (isGroup) {
          return (
            <GroupTabItem
              key={anchor || label}
              anchor={anchor}
              icon={icon}
              isActiveTab={isActiveTab}
              isVertical={isVertical}
              label={`${languagePack[label] || label}${additionalLabel ? ` ${additionalLabel}` : ''}`}
              labelComponent={labelComponent}
              onClick={(e) => handleTabClick(anchor, e)}
              items={items}
              activeTabId={activeTabId}
              handleTabClick={handleTabClick}
              languagePack={languagePack}
              {...tabProps}
            />
          );
        }

        if (isRestricted) {
          return (
            <RestrictedTab
              key={anchor || label}
              anchor={anchor}
              icon={icon}
              isActiveTab={isActiveTab}
              isVertical={isVertical}
              label={`${languagePack[label] || label}${additionalLabel ? ` ${additionalLabel}` : ''}`}
              labelComponent={labelComponent}
              onClick={(e) => handleTabClick(anchor, e)}
              permissions={permissions}
              {...tabProps}
            />
          );
        }

        return (
          <TabItem
            key={anchor || label}
            anchor={anchor}
            icon={icon}
            isActiveTab={isActiveTab}
            isVertical={isVertical}
            label={`${languagePack[label] || label}${additionalLabel ? ` ${additionalLabel}` : ''}`}
            labelComponent={labelComponent}
            onClick={(e) => handleTabClick(anchor, e)}
            {...tabProps}
          />
        );
      })}
    </ul>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string,
  className: PropTypes.string,
  isToggle: PropTypes.bool,
  isVertical: PropTypes.bool,
  languagePack: PropTypes.shape(),
  onClick: PropTypes.func,
  onPopstate: PropTypes.func,
  positionAbsolute: PropTypes.bool,
  tabProps: PropTypes.shape({
    hideLabels: PropTypes.bool,
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  updateUrl: PropTypes.bool,
};

Tabs.defaultProps = {
  activeTab: undefined,
  className: null,
  isToggle: false,
  isVertical: false,
  languagePack: {},
  onClick: () => {},
  onPopstate: () => {},
  positionAbsolute: true,
  tabProps: {},
  updateUrl: true,
};
