import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { peopleHR },
} = config;

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${peopleHR.basePath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToPeopleHR(applicantId, data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${peopleHR.basePath}/${applicantId}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
