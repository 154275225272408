import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Col, Row} from 'reactstrap';

function DbsCheckCompletedContainer({details, languagePack}) {

  const StatusBadge = () => {
    switch (details?.status) {
      case 'SUCCESS':
        return <Badge color="success">{languagePack[details.status]}</Badge>;
      case 'WITHDRAWN':
        return <Badge color="danger">{languagePack[details.status]}</Badge>;
      case 'FAILED':
        return <Badge color="danger">{languagePack[details.status]}</Badge>;
      default:
        return <Badge color="info">Unknown</Badge>;
    }
  }

  function loginToDbsProvider() {
    window.open("https://secure.mapitandgo.co.uk/sites/cbsref/indexp_eR2.html", '_blank', 'rel=noopener noreferrer');
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="onboarding-container-center">
            <Row className="mt-2">
              <Col style={{maxWidth: '200px'}}>{languagePack.dbsCheckStatusLabel}</Col>
              <Col><StatusBadge/></Col>
            </Row>
            <Row>
              <Col style={{marginTop: '20px'}}><Button onClick={loginToDbsProvider}>Login to DBS Provider</Button></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

DbsCheckCompletedContainer.propTypes = {
  details: PropTypes.shape(),
  languagePack: PropTypes.shape(),
};

DbsCheckCompletedContainer.defaultProps = {
  details: {},
  languagePack: {},
};

export default DbsCheckCompletedContainer;
