/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, Fragment, useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Tippy from '@tippyjs/react';
import { Confirmation } from '../../../../Base/Modal';
import { createSchemas, DEFAULT_SCHEMAS, createSections } from '../utils';
import { updateToApplicationFormat } from '../../../../../js/model/form-schemas/flexiforms/default-application-form';
import { FormManagerContext, FormManager } from '../FormManager';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { createFlexiForm, getFlexiForm, updateFlexiForm } from '../../../../../api/FormsAPI/ATSFormsAPI';
import { CreateButton } from '../../../../Base/Buttons';
import Slider from '../../../../Base/Slider/Slider';
import { useMounted } from '../../../../Base/hooks';
import EDocFormManager from '../FormManager/EDocFormManager';
import { getAllFormStyles } from '../../../../../api/FormsAPI/ATSFormsStylesAPI';

const defaultFormConf = {
  cachedSchemas: JSON.stringify({ ...DEFAULT_SCHEMAS }),
  hasChanged: false,
  id: undefined,
  name: '',
  readOnly: false,
  schemas: { ...DEFAULT_SCHEMAS },
  sharedWith: [],
  formStyle: null,
  type: 'GENERIC',
};

const UPDATE_FORM = 'UPDATE_FORM';
const RESET_FORM = 'RESET_FORM';

function getUniqueItems(data) {
  const dictOfUniqueAccounts = data.reduce((uniqueItems, currentItem) => {
    const identifier = `${currentItem.accountId}-${currentItem.accountName}`;

    if (!uniqueItems[identifier]) {
      // eslint-disable-next-line no-param-reassign
      uniqueItems[identifier] = currentItem;
    }

    return uniqueItems;
  }, {});

  return Object.values(dictOfUniqueAccounts);
}

function formConfReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...payload,
      };
    case RESET_FORM:
      return { ...defaultFormConf };
    default:
      return state;
  }
}

function FormSlider({
  formId,
  isSliderOpen,
  closeSlider,
  isEditing,
  formTypeTitleName,
  editorType,
  formTypeCount,
  onFormSubmit,
  activeAccount,
  totalAccounts,
  isCopying,
  initialData,
  openPreviewForm,
}) {
  const isMounted = useMounted();
  const [showConfirm, setShowConfirm] = useState(false);
  const [loadStatus, setLoadStatus] = useState('idle');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newSections, setNewSections] = useState([]);
  const [newQuestions, setNewQuestions] = useState([]);
  const [newOptions, setNewOptions] = useState([]);
  const [formConf, dispatch] = useReducer(formConfReducer, defaultFormConf);
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [allFormThemes, setAllFormThemes] = useState([]);

  const requestForm = useCallback(
    async (id) => {
      if (id) {
        const resp = await retryableAPICall(() => getFlexiForm(id));

        if (typeof resp === 'string') {
          setErrorMsg('Error loading form');
        } else {
          const { formSchema: schema, uiSchema, rules, name, type, readOnly, sharedWith, themeId } = resp;

          if (isMounted()) {
            dispatch({
              payload: {
                cachedSchemas: JSON.stringify({ rules, schema, uiSchema }),
                hasChanged: false,
                id: isCopying ? null : id,
                name: isCopying ? `${name} Copy` : name,
                readOnly: readOnly || false,
                schemas: {
                  rules,
                  schema: {
                    ...schema,
                    title: isCopying ? `${schema.title} Copy` : schema.title,
                  },
                  uiSchema,
                },
                sharedWith: getUniqueItems(sharedWith || []),
                type,
                themeId,
              },
              type: UPDATE_FORM,
            });
          }
        }
      }
    },
    [activeAccount.id, isMounted, isCopying],
  );

  useEffect(() => {
    if (formId) {
      requestForm(formId);
    } else if (initialData) {
      const { formSchema, uiSchema, rules, name, type, sharedWith, themeId } = initialData;

      dispatch({
        payload: {
          cachedSchemas: JSON.stringify({ rules, schema: formSchema, uiSchema }),
          hasChanged: false,
          id: null,
          name,
          readOnly: false,
          schemas: {
            rules,
            schema: formSchema,
            uiSchema,
          },
          sharedWith: getUniqueItems(sharedWith || []),
          themeId,
          type,
        },
        type: UPDATE_FORM,
      });
    } else if (!isEditing) {
      dispatch({ type: RESET_FORM });
    }
  }, [formId, requestForm, isEditing]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await retryableAPICall(() => getAllFormStyles());
      setAllFormThemes(response);
    }
    fetchData();
  }, [formId]);

  async function handleUpdateForm() {
    const { name: formName, schemas: formSchemas, type: formType, id, readOnly, sharedWith, themeId } = formConf;
    setLoadStatus('pending');
    setIsSubmitted(false);

    let sharedWithAccounts = [...(sharedWith || [])];

    // Always share with the current account
    if (!sharedWithAccounts.find((account) => account.accountId === activeAccount.id)) {
      sharedWithAccounts = [...sharedWithAccounts, { accountId: activeAccount.id, accountName: activeAccount.name }];
    }
    // remove emptys
    sharedWithAccounts = sharedWithAccounts.filter((account) => account?.accountId);

    const resp = await retryableAPICall(() =>
      updateFlexiForm(id, formName, formSchemas, formType, readOnly, sharedWithAccounts, themeId),
    );

    if (typeof resp === 'string') {
      setErrorMsg('Error updating form');
      setLoadStatus('rejected');
    } else {
      setSuccessMsg('Form successfully updated');
      onFormSubmit(resp.flexiForm);
      setLoadStatus('idle');
      closeSlider();
    }
  }

  async function handleFormSubmit() {
    const { name: formName, schemas: formSchemas, type: formType, readOnly, sharedWith, themeId } = formConf;
    setLoadStatus('pending');
    setIsSubmitted(false);

    let sharedWithAccounts = [...(sharedWith || [])];

    // Always share with the current account
    if (!sharedWithAccounts.find((account) => account.accountId === activeAccount.id)) {
      sharedWithAccounts = [...sharedWithAccounts, { accountId: activeAccount.id, accountName: activeAccount.name }];
    }

    // remove emptys
    sharedWithAccounts = sharedWithAccounts.filter((account) => account?.accountId);

    const resp = await retryableAPICall(() =>
      createFlexiForm(formName, formSchemas, formType, readOnly, sharedWithAccounts, themeId),
    );

    if (typeof resp === 'string') {
      setErrorMsg('Error creating form');
      setLoadStatus('rejected');
    } else {
      setSuccessMsg('Form successfully submitted');
      onFormSubmit(resp.flexiForm);
      setLoadStatus('idle');
      closeSlider();
    }
  }

  function resetForm(submitted) {
    setLoadStatus('idle');
    setNewSections([]);
    setNewQuestions([]);
    setNewOptions([]);
    setIsSubmitted(submitted);

    // reset form fields
    if (!submitted) {
      dispatch({
        payload: { ...defaultFormConf },
        type: UPDATE_FORM,
      });
    }
  }

  const sectionArr = createSections(formConf.schemas);
  const hasFormName = Boolean(formConf.name.length);
  //  const enablePreview = sectionArr.every((sec) => (sec.previewEnabled && !!sec.name.length));

  let modalTitle = '';

  if (isEditing) {
    if (isCopying) {
      modalTitle = `Create a copy of ${formTypeTitleName}`;
    } else {
      modalTitle = `Edit ${formTypeTitleName}`;
    }
  } else {
    modalTitle = `Create ${formTypeTitleName}`;
  }

  return (
    <>
      <Slider
        errorMsg={errorMsg}
        focusForm={false}
        isLoading={loadStatus === 'pending'}
        isOpen={isSliderOpen}
        pauseNotificationsOnOpen
        resetError={setErrorMsg}
        resetSuccess={setSuccessMsg}
        successMsg={successMsg}
        title={modalTitle}
        toggleOpen={(isBgClick) => {
          if (isBgClick && formConf.hasChanged) {
            setShowConfirm(true);
          } else {
            closeSlider();
            resetForm(false);
          }
        }}
        width="100%"
      >
        <FormManagerContext.Provider
          value={{
            newOptions,
            newQuestions,
            newSections,
            onOptionAdd: (optionId) => {
              if (isSubmitted) setNewOptions([...newOptions, optionId]);
            },
            onOptionEdit: (optionId) => setNewOptions(newOptions.filter((optId) => optId !== optionId)),
            onQuestionAdd: (questionId) => {
              if (isSubmitted) setNewQuestions([...newQuestions, questionId]);
            },
            onQuestionEdit: (questionId) => setNewQuestions(newQuestions.filter((qId) => qId !== questionId)),
            onSectionAdd: (sectionId) => {
              if (isSubmitted) setNewSections([...newSections, sectionId]);
            },
            onSectionEdit: (sectionId) => setNewSections(newSections.filter((sId) => sId !== sectionId)),
          }}
        >
          {editorType === 'FORM' && (
            <FormManager
              formName={formConf.name}
              formSections={sectionArr}
              formThemes={allFormThemes}
              formType={formConf.type}
              formTypeCount={formTypeCount}
              formTypeTitleName={formTypeTitleName}
              isEditing={isEditing}
              isSubmitted={isSubmitted}
              onChange={(sections, name, type, readOnly, sharedWith, themeId) => {
                let schemas = createSchemas(sections, name);

                if (type === 'APPLICATION_FORM') {
                  schemas = updateToApplicationFormat(schemas);
                }

                const updated = JSON.stringify(schemas);
                dispatch({
                  payload: {
                    hasChanged: updated !== formConf.cachedSchemas,
                    name,
                    readOnly,
                    schemas,
                    sharedWith,
                    type,
                    themeId,
                  },
                  type: UPDATE_FORM,
                });
              }}
              openPreviewForm={() => {
                openPreviewForm(formId, {
                  formSchema: formConf.schemas.schema,
                  name: formConf.name,
                  rules: formConf.schemas.rules,
                  themeId: formConf.themeId,
                  type: formConf.type,
                  uiSchema: formConf.schemas.uiSchema,
                });
              }}
              readOnly={formConf.readOnly}
              schema={formConf.schemas.schema}
              sharedWith={formConf.sharedWith}
              themeId={formConf.themeId}
              totalAccounts={totalAccounts}
            />
          )}
          {editorType === 'EDOC' && (
            <EDocFormManager
              formName={formConf.name}
              formSections={sectionArr}
              formType={formConf.type}
              formTypeCount={formTypeCount}
              formTypeTitleName={formTypeTitleName}
              isEditing={isEditing}
              isSubmitted={isSubmitted}
              onChange={(sections, name, type, readOnly, sharedWith, formStyle) => {
                const schemas = createSchemas(sections, name);
                const updated = JSON.stringify(schemas);

                dispatch({
                  payload: {
                    formStyle,
                    hasChanged: updated !== formConf.cachedSchemas,
                    name,
                    readOnly,
                    schemas,
                    sharedWith,
                    type,
                  },
                  type: UPDATE_FORM,
                });
              }}
              readOnly={formConf.readOnly}
              sharedWith={formConf.sharedWith}
              totalAccounts={totalAccounts}
            />
          )}
        </FormManagerContext.Provider>
        <div className="clearfix my-3">
          <CreateButton
            action={(e) => {
              e.preventDefault();
              // validity check
              if (hasFormName) {
                if (isEditing && !isCopying) {
                  handleUpdateForm();
                } else {
                  handleFormSubmit();
                }
              } else {
                resetForm(true);
              }
            }}
            label={editorType === 'EDOC' ? 'Save eDoc' : 'Save Form'}
          />
        </div>
      </Slider>
      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          closeSlider();
          setShowConfirm(false);
          resetForm(false);
        }}
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        title="Warning"
      />
    </>
  );
}

FormSlider.propTypes = {
  activeAccount: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  closeSlider: PropTypes.func,
  editorType: PropTypes.string,
  formId: PropTypes.string,
  formTypeCount: PropTypes.objectOf(PropTypes.number),
  formTypeTitleName: PropTypes.string,
  initialData: PropTypes.shape(),
  isCopying: PropTypes.string,
  isEditing: PropTypes.bool,
  isSliderOpen: PropTypes.bool,
  onFormSubmit: PropTypes.func,
  openPreviewForm: PropTypes.func,
  totalAccounts: PropTypes.number,
};

FormSlider.defaultProps = {
  activeAccount: {},
  closeSlider: () => {},
  editorType: 'FORM',
  formId: null,
  formTypeCount: {},
  formTypeTitleName: 'Form',
  initialData: null,
  isCopying: false,
  isEditing: false,
  isSliderOpen: false,
  onFormSubmit: () => {},
  openPreviewForm: () => {},
  totalAccounts: 0,
};

export default FormSlider;
