import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { retryableAPICall } from '../../../../../../api/common-api-utils';
import { addFile } from '../../../../../../api/FormsAPI/ATSFormsStylesAPI';

export function stripBase64Prefix(dataStr) {
  if (!dataStr) return '';
  const parts = dataStr.split('base64,');
  return [...parts].pop();
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function UploadFile({ onChange, value, isFont }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const fileInpRef = useRef();

  const handleRemoveImage = () => {
    onChange(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const image = await toBase64(event.target.files[0]);
    const formData = {
      content: stripBase64Prefix(image),
      filename: event.target.files[0].name,
      accessPermission: 'PUBLIC',
      encoding: 'UTF-8',
      contentType: event.target.files[0].type,
    };

    setIsLoading(true);
    setError(null);

    try {
      const response = await retryableAPICall(() => addFile(formData));

      onChange(response.externalUrl);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex ">
        <input
          ref={fileInpRef}
          accept={isFont ? '.css, .woff, .woff2, .ttf' : 'image/*'}
          name="file"
          onChange={handleSubmit}
          style={{ display: 'none' }}
          type="file"
        />
        <Button
          className="me-2 d-block"
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            fileInpRef.current.click();
          }}
          size="sm"
        >
          <span className="fa fa-upload fa-btn" /> Upload
        </Button>
        {value && !isFont ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleRemoveImage();
            }}
            color="secondary"
            size="sm"
          >
            <span className="fa fa-trash fa-btn" /> Remove
          </Button>
        ) : null}
      </div>
      {isLoading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {value && (
        <div className="position-relative mt-2" style={{ width: '300px', height: '200px' }}>
          {isFont ? (
            <span>{value}</span>
          ) : (
            <img
              alt={value}
              className="w-100 h-100"
              src={value}
              style={{
                objectFit: 'contain',
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

UploadFile.propTypes = {
  onChange: PropTypes.func,
  isFont: PropTypes.bool,
  value: PropTypes.string,
};

UploadFile.defaultProps = {
  isFont: false,
  onChange: () => {},
  value: '',
};

export default UploadFile;
