import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { Input, Form, FormGroup, FormText, Label } from 'reactstrap';
import ModalPopup from './ModalPopup';
import CopyToClipboardButton from '../Buttons/CopyToClipboardButton';

const StyledBox = styled.div`
  padding: 1rem;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 0.5rem;

  textarea {
    min-height: 100px;
  }

  button {
    width: 100%;
    margin-right: 0 !important;
  }
`;

function gernerateTrackedURL(url, sourceId, mediumId, campaignId) {
  const params = [];

  if (sourceId) {
    params.push(`utm_source=${encodeURIComponent(sourceId)}`);
  }

  if (mediumId) {
    params.push(`utm_medium=${encodeURIComponent(mediumId)}`);
  }

  if (campaignId) {
    params.push(`utm_campaign=${encodeURIComponent(campaignId)}`);
  }

  if (params.length === 0) {
    return url;
  }

  return `${url}?${params.join('&')}`;
}

function TrackedURLModal({ isOpen, onClose, url }) {
  const [sourceId, setSourceId] = useState('');
  const [mediumId, setMediumId] = useState('');
  const [campaignId, setCampaignId] = useState('');

  useEffect(() => {
    setSourceId('');
    setMediumId('');
    setCampaignId('');
  }, [isOpen]);

  return (
    <ModalPopup
      title="Create Tracked URL"
      okayLabel="Close"
      isOpen={isOpen}
      onToggle={onClose}
      minHeight="300px"
      hideCancelButton
    >
      <Form>
        <FormGroup>
          <Label for="sourceId">Source Id*</Label>
          <Input
            id="sourceId"
            value={sourceId}
            onChange={(e) => {
              setSourceId(e.target.value);
            }}
          />
          <FormText>The referrer (e.g. google, newsletter)</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="mediumId">Medium Id</Label>
          <Input
            id="mediumId"
            value={mediumId}
            onChange={(e) => {
              setMediumId(e.target.value);
            }}
          />
          <FormText>Marketing medium (e.g. cpc, banner, email)</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="campaignId">Campaign Id</Label>
          <Input
            id="campaignId"
            value={campaignId}
            onChange={(e) => {
              setCampaignId(e.target.value);
            }}
          />
          <FormText>The ads campaign id.</FormText>
        </FormGroup>
        <StyledBox>
          <Label for="url">Use this url to asscociate the application to a campaign</Label>
          <Input id="url" type="textarea" value={gernerateTrackedURL(url, sourceId, mediumId, campaignId)} disabled />
          <Tippy
            // eslint-disable-next-line max-len
            content={sourceId.length === 0 ? 'Source Id is required for a Tracked URL' : 'Copy URL to clipboard'}
            maxWidth="100%"
            theme="ats"
          >
            <span className="w-100">
              <CopyToClipboardButton
                disabled={sourceId.length === 0}
                label="URL"
                textToCopy={gernerateTrackedURL(url, sourceId, mediumId, campaignId)}
              />
            </span>
          </Tippy>
        </StyledBox>
      </Form>
    </ModalPopup>
  );
}

TrackedURLModal.propTypes = {
  isOpen: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
};

TrackedURLModal.defaultProps = {
  url: undefined,
  isOpen: false,
  onClose: () => {},
};

export default TrackedURLModal;
