import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const Heading = styled.h1`
  margin-right: auto;
`;

function HeadingPushRight({
  isTouch,
  headingAs,
  headingText,
  headingClassName,
  className,
  children,
}) {
  return (
    <Wrapper className={cx(
      { 'align-items-center': !isTouch },
      { 'flex-column': isTouch },
      className,
    )}
    >
      {headingText && (
        <Heading
          as={headingAs}
          className={cx(headingClassName, { 'mb-0': !isTouch })}
        >
          {headingText}
        </Heading>
      )}
      {children}
    </Wrapper>
  );
}

HeadingPushRight.propTypes = {
  isTouch: PropTypes.bool,
  headingAs: PropTypes.string,
  headingText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headingClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HeadingPushRight.defaultProps = {
  isTouch: false,
  headingAs: null,
  headingText: null,
  headingClassName: null,
  className: null,
  children: null,
};

export default HeadingPushRight;
