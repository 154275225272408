import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import UploadWrapper, { uploadFile, isImage, MAX_HEIGHT } from './UploadWrapper';
import config from '../../../../../config/config';

export default function ImageUpload(props) {
  const {
    formData,
    schema: {
      maxHeight = MAX_HEIGHT,
    },
    onChange,
  } = props;
  const loadedImg = isImage(formData);

  const [imageUrl, setImageUrl] = useState(loadedImg ? formData : null);
  const [imageLoaded, setImageLoaded] = useState(loadedImg);
  const [isUploading, setIsUploading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const imageRef = useRef();

  const loadImage = useCallback((srcStr) => {
    const { current } = imageRef;

    if (srcStr && current) {
      const img = new Image();
      img.addEventListener('load', () => {
        if (img.width > 700) {
          current.style.width = '100%';
          current.style.height = 'auto';
        }
        else {
          current.style.height = `${Math.min(img.height, maxHeight)}px`;
        }
        current.style.opacity = 1;
      }, false);

      img.src = srcStr;
    }
  }, [maxHeight]);

  useEffect(() => {
    if (imageUrl) loadImage(imageUrl);
  }, [imageUrl, loadImage]);

  async function handleChange(files) {
    // bail if no file to handle
    if (!Array.isArray(files) || !files.length) return;

    setIsUploading(true);

    const uploadedUrl = await uploadFile(files[0], config.company.companyImagePath);

    if (uploadedUrl) {
      setImageLoaded(true);
      setIsServerError(false);
      setImageUrl(uploadedUrl);
      onChange(uploadedUrl);
    }
    else {
      setIsServerError(true);
    }

    setIsUploading(false);
  }

  return (
    <UploadWrapper
      {...props}
      hasFormData={loadedImg}
      onChange={handleChange}
      isServerError={isServerError}
      errorMessage="There was an error processing your image."
      isUploading={isUploading}
      accept={[
        'image/x-png',
        'image/gif',
        'image/jpeg',
      ]}
    >
      {imageLoaded && (
        <img
          ref={imageRef}
          src={imageUrl}
          alt=""
          style={{
            height: 0,
            opacity: 0,
          }}
        />
      )}
    </UploadWrapper>
  );
}

ImageUpload.propTypes = {
  formData: PropTypes.string,
  schema: PropTypes.shape({
    maxHeight: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
};

ImageUpload.defaultProps = {
  formData: null,
  onChange: () => { },
};
