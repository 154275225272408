import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import EmailForm from '../../Communication/email/EmailForm';

import FormsSelector from '../Other/FormsSelector';

function GenericFormsRequestCreation({ candidate, languagePack, onUpdate, alreadySelectedFormIds }) {
  const [selectedToRequestEDocs, setSelectedToRequestEDocs] = useState([]);

  function toggleReqInfo() {
    setSelectedToRequestEDocs([]);
  }

  function handleSuccess(message) {
    toggleReqInfo();
    toast.success(languagePack.successfullySentDocumentRequest);
    onUpdate(message, () => {});
  }

  function onFormSelectChange(selectedVal, allValues) {
    const mappedAllValues = allValues.map((val) => {
      return { id: val.value, label: val.label, value: val.value };
    });

    setSelectedToRequestEDocs(mappedAllValues);
  }

  const selEFormIds = {};
  selectedToRequestEDocs.forEach((edoc) => {
    selEFormIds[edoc.label] = edoc.id;
  });

  const disableSend = Object.keys(selEFormIds).length === 0;

  return (
    <div className="mb-1">
      <FormsSelector
        languagePack={languagePack}
        onChange={onFormSelectChange}
        permissionsRequired={['candidate:genericforms:create']}
        selectedValues={selectedToRequestEDocs}
        type="GENERIC"
        alreadySelectedFormIds={alreadySelectedFormIds}
      />
      {!disableSend && (
        <Row>
          <Col>
            <EmailForm
              applicants={[
                {
                  applicantEmail: candidate.applicantProfile.communication.emailAddress,
                  applicantId: candidate.id,
                  applicantName: `${candidate.applicantProfile.personalDetails.givenName}
                      ${candidate.applicantProfile.personalDetails.familyName}`,
                },
              ]}
              actionType="GENERIC_FORM"
              docFormIds={selEFormIds}
              onSuccess={(e, resp) => handleSuccess(e, resp)}
              sendBtnLabel={languagePack.requestDocumentButtonLabel}
              onError={() => {
                toast.error(languagePack.errorSendingDocumentRequest);
              }}
              disableSend={disableSend}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

GenericFormsRequestCreation.propTypes = {
  alreadySelectedFormIds: PropTypes.arrayOf(PropTypes.string),
  candidate: PropTypes.shape(),
  languagePack: PropTypes.shape(),
  onUpdate: PropTypes.func,
};

GenericFormsRequestCreation.defaultProps = {
  alreadySelectedFormIds: [],
  candidate: null,
  languagePack: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  const {
    userData: { userDetails },
  } = state;
  return { userDetails };
}

export default connect(mapStateToProps, null)(GenericFormsRequestCreation);
