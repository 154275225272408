// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import BuildDetails from './BuildDetails';
import { uploadUtils } from '../../../Base/Forms/Custom/FileUpload';
import { ModalPopup } from '../../../Base/Modal';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { downloadFiles } from '../../../../api/FileAPI/FileAPI';
import { IconButton, TextButton } from '../../../Base/Buttons';
import { PDFDisplay } from '../../../Base/PDF';

function downloadAttachment(fileId) {
  retryableAPICall(() => downloadFiles([fileId]));
}

function isValidBase64StartAndEnd(base64) {
  let base64Data = base64;
  if (Array.isArray(base64)) {
    base64Data = base64Data.join(''); // Join array into a single string
  }
  // Clip the beginning and the end of the base64 string for validation
  const start = base64Data.substring(0, 50); // Check the first 50 characters
  const end = base64Data.slice(-50); // Check the last 50 characters
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  return base64regex.test(start + end);
}

async function getFileBase64(fileId, fileName, callbacks = {}) {
  const { onLoad = () => {}, onError = () => {}, onSuccess = () => {}, onComplete = () => {} } = callbacks;

  onLoad();

  try {
    const base64 = await retryableAPICall(() => downloadFiles([fileId], { base64: true, forceDownload: false }));

    // Check if the base64 string starts and ends correctly for large files
    if (!isValidBase64StartAndEnd(base64)) {
      onError();
    } else {
      onSuccess(base64);
    }
  } catch (error) {
    console.error(error);
    onError();
  } finally {
    onComplete();
  }
}

function CVPreview({ file, titleWidth, btnLabel, touchTitleColumnSize, title, isIconOnly, className }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  const [previewError, setPreviewError] = useState(undefined);

  let Button;

  if (isIconOnly) {
    Button = IconButton;
  } else {
    Button = TextButton;
  }

  const fileExt = uploadUtils.fileExtension(file.fileName);
  if (fileExt !== 'pdf' && file.attachmentType !== 'CV') return null;

  const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  let cleanedFileName;

  if (uuidRegex.test(file.fileName.substring(0, 36))) {
    cleanedFileName = file.fileName.substring(37, file.fileName.length);
  } else {
    cleanedFileName = title;
  }

  // Check if the cleaned file name ends with .pdf
  // If not, replace the current extension (if any) with .pdf
  const fileExtensionRegex = /\.[^/\s]+$/;

  if (!cleanedFileName.toLowerCase().endsWith('.pdf')) {
    cleanedFileName = `${cleanedFileName.replace(fileExtensionRegex, '')}.pdf`;
  }

  const DownloadButton = (
    <Button
      action={() => {
        const { id, fileName } = file;

        getFileBase64(id, fileName, {
          onComplete: () => setIsFileLoading(false),
          onError: () => {
            setPreviewFile({ fileName, id });
            setPreviewError('Sorry the preview could not be loaded at this time');
          },
          onLoad: () => {
            setIsFileLoading(true);
            setIsModalOpen(true);
            setPreviewError(undefined);
          },
          onSuccess: (base64) => setPreviewFile({ base64, fileName, id }),
        });
      }}
      className={`btn-outline ${className || ''}`}
      floatRight={false}
      iconClassName="icon-doc"
      iconOnly={isIconOnly}
      label={btnLabel || 'View/Download'}
      size="sm"
    />
  );
  return (
    <>
      {!isIconOnly && (
        <BuildDetails
          title={title}
          titleColumnSize={titleWidth}
          touchTitleColumnSize={touchTitleColumnSize || titleWidth}
        >
          {DownloadButton}
        </BuildDetails>
      )}
      {isIconOnly && DownloadButton}
      <ModalPopup
        cancelLabel="Close Preview"
        centerButtons
        centerContent
        className="p-2"
        isOpen={isModalOpen}
        okayLabel={btnLabel || 'View/Download'}
        onOkay={() => {
          if (previewFile) downloadAttachment(previewFile.id);
        }}
        onToggle={() => {
          setIsModalOpen(false);
          setPreviewFile();
        }}
        title={cleanedFileName}
      >
        {previewError && (
          <div className="m-auto">
            <p className="text-danger">{previewError}</p>
          </div>
        )}
        {previewFile && !previewError ? (
          <PDFDisplay
            file={previewFile.base64}
            isBase64
            onFileDownload={() => {
              setPreviewError("CV isn't in a previewable format. It has been downloaded to your device.");
              downloadAttachment(previewFile.id);
            }}
          />
        ) : (
          <div className="mt-4">{isFileLoading && !previewError ? <Spinner color="secondary" /> : null}</div>
        )}
      </ModalPopup>
    </>
  );
}

CVPreview.propTypes = {
  btnLabel: PropTypes.string,
  className: PropTypes.string,
  file: PropTypes.shape(),
  isIconOnly: PropTypes.bool,
  title: PropTypes.string,
  titleWidth: PropTypes.number,
  touchTitleColumnSize: PropTypes.number,
};

CVPreview.defaultProps = {
  btnLabel: 'CV',
  className: '',
  file: {},
  isIconOnly: false,
  title: 'CV',
  titleWidth: 3,
  touchTitleColumnSize: 4,
};

export default CVPreview;
