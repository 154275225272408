import {
  SET_USER_AUTH_ERROR,
  INCREMENT_FAILED_AUTH_COUNT,
  UPDATE_ACTIVE_ACCOUNT,
  USER_AUTH_UPDATE,
  USER_DETAILS_UPDATE,
  USER_SETTINGS_UPDATE,
  USER_RESET_STATE,
  USER_ROLES_DETAILS_UPDATE,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  CLEAR_FAILED_AUTH_COUNT,
  UPDATE_LANGUAGE,
} from '../constants/actionTypes';
import { updateArr } from '../utils/arrayOfObjects';

function deleteAccount(arr, id) {
  const idx = arr.findIndex(({ accountId }) => accountId === id);
  return updateArr(arr, idx);
}

function updateAccount(arr, accObj, id) {
  const idx = arr.findIndex(({ accountId }) => accountId === id);
  return updateArr(arr, idx, accObj);
}

const defaultState = {
  userAuth: {
    token: undefined,
    refreshToken: undefined,
    isAuthenticated: false,
    authError: undefined,
    ulToken: undefined,
  },
  userDetails: {
    email: '',
    givenName: '',
    surname: '',
    lastLoginInstant: undefined,
  },
  userSettings: {},
  userRoles: [],
  failedAuthCount: 0,
};

const initialState = {
  userAuth: {
    token: undefined,
    refreshToken: undefined,
    isAuthenticated: false,
    ulToken: undefined,
  },
  userDetails: {
    username: '',
    lastLoginInstant: undefined,
  },
  userSettings: {},
  userRoles: [],
  failedAuthCount: 0,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_AUTH_UPDATE:
      return {
        ...state,
        userAuth: action.payload,
      };
    case SET_USER_AUTH_ERROR:
      return {
        ...state,
        userAuth: {
          ...state.userAuth,
          authError: action.payload,
        },
      };
    case INCREMENT_FAILED_AUTH_COUNT:
      return {
        ...state,
        failedAuthCount: state.failedAuthCount + 1,
      };
    case CLEAR_FAILED_AUTH_COUNT:
      return {
        ...state,
        failedAuthCount: 0,
      };
    case USER_DETAILS_UPDATE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    case USER_SETTINGS_UPDATE:
      return {
        ...state, userSettings: action.payload,
      };
    case UPDATE_ACTIVE_ACCOUNT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          activeAccountId: action.payload.value,
          activeAccountName: action.payload.label,
        },
      };
    case USER_ROLES_DETAILS_UPDATE:
      return {
        ...state,
        userRoles: action.payload,
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            accountAccess: [
              ...state.userDetails.data.accountAccess,
              action.payload,
            ],
          },
        },
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            accountAccess: updateAccount(
              state.userDetails.data.accountAccess,
              action.payload,
              action.payload.accountId,
            ),
          },
        },
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            accountAccess: deleteAccount(state.userDetails.data.accountAccess, action.payload),
          },
        },
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          language: {
            ...state.userSettings.language,
            languageCode: action.payload,
          },
        },
      };
    case USER_RESET_STATE:
      return { ...defaultState };
    default:
      return state;
  }
};

export default userDataReducer;
