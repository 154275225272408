import React from 'react';
import PropTypes from 'prop-types';
import e4sLogo from '../../../assets/img/brand/logo/e4slogo.png';
import tfLogoName from '../../../assets/img/brand/logo/tflogoname.png';
import tfLogo from '../../../assets/img/brand/logo/tflogo.png';

function ResolveLogo({ imgWidth, imgSrc, className, loginPage }) {
  if (document.location.host === 'ats.e4s.co.uk') {
    return <img alt="logo" src={e4sLogo} width={imgWidth} />;
  }

  if (imgSrc) return <img alt="logo" className={className} src={imgSrc} width={imgWidth} />;
  if (loginPage) return <img alt="logo" className={className} src={tfLogoName} width="250px" />;

  return <img alt="logo" className={className} src={tfLogo} width={imgWidth} />;
}

ResolveLogo.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  imgWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loginPage: PropTypes.bool,
};

ResolveLogo.defaultProps = {
  className: null,
  imgSrc: null,
  imgWidth: 75,
  loginPage: false,
};

export default ResolveLogo;
