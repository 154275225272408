import moment from 'moment';
import { industries } from '../data/industries';
// import { countries } from '../data/countries';

export const companySizes = [
  { label: '1-10', value: '1-10' },
  { label: '10-25', value: '10-25' },
  { label: '25-50', value: '25-50' },
  { label: '50-100', value: '50-100' },
  { label: '100-500', value: '100-500' },
  { label: '500+', value: '500+' },
];

export const years = () => {
  const yearsArr = [];
  for (let i = 1850; i <= moment().year(); i += 1) {
    yearsArr.push({ label: i, value: i });
  }
  return yearsArr.reverse();
};

export const companyProfileSchema = {
  type: 'object',
  required: [],
  properties: {
    logo: {
      type: 'string',
      title: 'Upload your logo',
      maxHeight: 400,
      maxWidth: 400,
    },
    banner: {
      type: 'string',
      title: 'Upload your banner',
      maxHeight: 400,
      maxWidth: 1800,
      // maxSize: 120000,
    },
    documents: {
      type: 'array',
      title: 'Upload files for download',
      items: {
        type: 'object',
      },
    },
    address: {
      type: 'object',
      title: '',
      properties: {
        address: {
          type: 'object',
          title: 'Address Lookup',
        },
      },
    },
    industry: {
      type: 'string',
      title: 'Company Sector',
      data: industries,
    },
    website: {
      type: 'string',
      title: 'Website URL',
      default: 'https://',
    },
    phone: {
      type: 'string',
      title: 'Phone number',
      minLength: 10,
    },
    foundedYear: {
      type: 'number',
      title: 'Founded Year',
      data: years(),
    },
    size: {
      type: 'string',
      title: 'Size',
      data: companySizes,
    },
    description: {
      type: 'string',
      title: 'Description/profile',
    },
  },
};

export const companyProfileUiSchema = {
  'ui:order': [
    'logo',
    'banner',
    'address',
    'industry',
    'website',
    'phone',
    'foundedYear',
    'size',
    'description',
    'documents',
  ],
  description: {
    'ui:field': 'rte',
    // eslint-disable-next-line max-len
    'ui:help': 'Enter some descriptive text about the company. This should include the history of the company and why it is a great place to work',
  },
  industry: {
    'ui:field': 'select',
  },
  foundedYear: {
    'ui:field': 'select',
  },
  size: {
    'ui:field': 'select',
  },
  // country: {
  //   'ui:field': 'select',
  // },
  logo: {
    'ui:field': 'imageUpload',
    'ui:help': 'Your logo must be a maximum size of 400x400. You will need to save for the logo changes to take effect',
  },
  banner: {
    'ui:field': 'imageUpload',
    'ui:help': 'Upload a banner to display on your profile',
  },
  documents: {
    'ui:field': 'fileUpload',
  },
  address: {
    address: {
      'ui:order': ['address'],
      'ui:field': 'addressLookup',
      hideCountry: true,
      defaultCountry: 'GB',
    },
  },
};
