import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import BuildDetails from './BuildDetails';
import ObjectHandler from './ObjectHandler';
import Base64Files from './Base64Files';
import { Attachments } from './index';

const Heading = styled.h4``;

function getDetailsValues(answer) {
  const { text } = answer;
  let { value } = answer;
  const match = /^\{(.*?)}/gi.exec(value);

  if (match) {
    const [, str] = match;
    const isVideo = str.includes('type=VIDEO');

    if (!str.length) return {};

    const sanitizedVal = str
      .split(',')
      .map((item) => item.trim())
      .reduce(
        (acc, item) => {
          const [key, val] = item.split('=');
          // video creates a object
          if (isVideo) return { ...acc, [key]: val };
          acc.push(`${key}: ${val}`);
          return acc;
        },
        isVideo ? {} : [],
      );

    value = isVideo ? sanitizedVal : sanitizedVal.join(', ');
  } else if (Array.isArray(value)) {
    if (value.length) {
      value = value.join(', ');
    } else {
      value = '';
    }
  }

  return { text, value };
}

function MappedQuestionAnswer({ answer, titleWidth, touchTitleColumnSize, isTouch, attachments, candidateId }) {
  if (!Object.keys(answer).length) return null;

  const { display, fieldId } = answer;
  const { text, value } = getDetailsValues(answer);

  if (
    fieldId.startsWith('utm') ||
    fieldId.startsWith('termsAndCond') ||
    fieldId.startsWith('cvUpload') ||
    /^(cid|rid)$/.test(fieldId)
  )
    return null;
  if (!display) return null;

  if (typeof value === 'string') {
    if (value.startsWith('data:')) {
      return (
        <BuildDetails
          title={text}
          titleColumnSize={titleWidth}
          touchTitleColumnSize={touchTitleColumnSize || titleWidth}
        >
          <Base64Files data={value} />
        </BuildDetails>
      );
    }

    return (
      <BuildDetails title={text} titleColumnSize={titleWidth} touchTitleColumnSize={touchTitleColumnSize || titleWidth}>
        {fieldId === 'mobilePhone' ? <>{isTouch ? <a href={`tel:+${value}`}>{`+${value}`}</a> : `+${value}`}</> : value}
      </BuildDetails>
    );
  }

  // eslint-disable-next-line no-prototype-builtins
  if (typeof value === 'object' && value.hasOwnProperty('fileName')) {
    // eslint-disable-next-line react/prop-types
    const attachment = attachments.find(
      (att) =>
        /((\w{4,12}-?)){5}/.exec(att.fileName)[0]?.slice(0, -1) ===
        /((\w{4,12}-?)){5}/.exec(value.fileName)[0]?.slice(0, -1),
    );

    if (attachment) {
      return (
        <Attachments
          applicantId={candidateId}
          btnLabel="View/Download"
          data={attachment}
          isTouch={isTouch}
          title={answer.text}
          titleWidth={5}
          touchTitleColumnSize={5}
        />
      );
    }
    return null;
  }

  return (
    <BuildDetails title={text} titleColumnSize={titleWidth} touchTitleColumnSize={touchTitleColumnSize || titleWidth}>
      <ObjectHandler candidateId={candidateId} obj={value} />
    </BuildDetails>
  );
}

MappedQuestionAnswer.propTypes = {
  answer: PropTypes.shape(),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  candidateId: PropTypes.string,
  isTouch: PropTypes.bool,
  titleWidth: PropTypes.number,
  touchTitleColumnSize: PropTypes.number,
};

MappedQuestionAnswer.defaultProps = {
  answer: {},
  attachments: [],
  candidateId: null,
  isTouch: false,
  titleWidth: 3,
  touchTitleColumnSize: 4,
};

function MappedAnswers({ answers, titleWidth, touchTitleColumnSize, isTouch, attachments, candidateId, headingAs }) {
  if (!Object.keys(answers).length || !answers.sections) return null;

  return answers.sections.map((section, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={`section-${i}`}>
      {section.questions.filter((q) => q.length > 0).length !== 0 && (
        <Row className="mt-3">
          <Col>
            <Heading as={headingAs}>{section.title}</Heading>
          </Col>
        </Row>
      )}
      {!!section.questions.length &&
        section.questions.map((question) =>
          question.map((qAnswer, k) => (
            <MappedQuestionAnswer
              // eslint-disable-next-line react/no-array-index-key
              key={`answer-${i}-${k}`}
              answer={qAnswer}
              attachments={attachments}
              candidateId={candidateId}
              isTouch={isTouch}
              titleWidth={titleWidth}
              touchTitleColumnSize={touchTitleColumnSize}
            />
          )),
        )}
    </Fragment>
  ));
}

MappedAnswers.propTypes = {
  answers: PropTypes.shape({
    section: PropTypes.arrayOf(PropTypes.shape()),
  }),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  candidateId: PropTypes.string,
  headingAs: PropTypes.string,
  isTouch: PropTypes.bool,
};

MappedAnswers.defaultProps = {
  answers: {
    sections: [],
  },
  attachments: [],
  candidateId: null,
  headingAs: null,
  isTouch: false,
};

export default MappedAnswers;
