import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function TableSearchBar({
  minWidth,
  onSearch,
  placeholder,
  className,
  value,
  children,
  StartComponent,
}) {
  let typingTimer;
  const doneTypingInterval = 2500;

  return (
    <div className="d-flex align-items-center">
      {StartComponent}
      <div className={cx('custom-search-input ms-auto', { 'me-3': children }, className)} style={{ minWidth }}>
        <input
          type="search"
          className="search-query form-control"
          defaultValue={value}
          onChange={(e) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => onSearch(e.target.value), doneTypingInterval);
          }}
          placeholder={placeholder || 'Search'}
        />
        <span className="fa fa-search form-control-feedback" />
      </div>
      {children}
    </div>
  );
}

TableSearchBar.propTypes = {
  className: PropTypes.string,
  minWidth: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  StartComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

TableSearchBar.defaultProps = {
  className: null,
  minWidth: '150px',
  onSearch: () => {},
  placeholder: null,
  value: '',
  children: null,
  StartComponent: null,
};
