/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useFormKeypress, useLanguagePack, useMounted } from '../../../Base/hooks';
import { ValueDisplay } from '../../../Base/ValueDisplay';
import { CreateButton, EditButton, CancelButton } from '../../../Base/Buttons';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { Confirmation } from '../../../Base/Modal';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { savePublishingSettings } from '../../../../api/PublishingAPI/PublishingSettingsAPI';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';

function VacancyPosterSettings({ initFormData }) {
  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const languagePack = useLanguagePack('vacancy-poster');
  const cachedFormData = useRef({});
  const [isEditing, setIsEditing] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (isMounted()) {
      if (Object.keys(initFormData).length) {
        setFormData(initFormData);
        cachedFormData.current = initFormData;
        setIsEditing(false);
        setIsNew(false);
      }
    }
  }, [initFormData, isMounted]);

  function handleChange(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  async function saveProfileDetails(callback = () => {}) {
    const { username, password, apiKey } = formData;

    const resp = await retryableAPICall(() =>
      savePublishingSettings({
        username,
        multiPosterService: 'VACANCY_POSTER',
        password,
        apiKey,
      }),
    );

    if (typeof resp === 'string') {
      toast.error(languagePack.saveErrorMessage);
      setIsSaving(false);
    } else {
      if (resp.status === 'FAILED' && resp.errorReason === 'INVALID_CREDENTIALS') {
        toast.error(languagePack.invalidCredsMessage);
      } else if (resp.status === 'FAILED') {
        toast.error(languagePack.saveErrorMessage);
      } else {
        toast.success(languagePack.saveSuccessMessage);
        setFormData(resp.account);
        callback();
      }
      setIsSaving(false);
    }
  }

  function handleSave() {
    setIsSaving(true);

    const errObj = validation(
      [
        { id: 'username', required: true },
        { id: 'password', required: true },
        { id: 'apiKey', required: true },
      ],
      formData,
    );
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors && !isNew) setShowConfirm(true);
    if (isNew) {
      saveProfileDetails(() => {
        setIsEditing(false);
        setShowConfirm(false);
        setIsSaving(false);
        setIsNew(false);
      });
    }
  }

  return (
    <Fragment>
      <h5>{languagePack.pageHeading}</h5>
      <Form innerRef={formRef}>
        <FormGroup
          row
          id="username"
          label={`${languagePack.usernameTitle || 'Username'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.username}
        >
          {isEditing ? (
            <Input
              type="text"
              id="username"
              value={formData.username}
              onChange={(e) => handleChange('username', e.target.value)}
            />
          ) : (
            <ValueDisplay value={formData.username} />
          )}
        </FormGroup>
        <FormGroup
          row
          id="password"
          label={`${languagePack.passwordTitle || 'Password'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.password}
        >
          {isEditing ? (
            <Input type="password" id="password" onChange={(e) => handleChange('password', e.target.value)} />
          ) : (
            <ValueDisplay value="**********" />
          )}
        </FormGroup>
        <FormGroup
          row
          id="apiKey"
          label={`${languagePack.apiKeyTitle || 'API Key'}`}
          required={isEditing}
          labelProps={{ sm: '2' }}
          colProps={{ sm: '3', xs: '4' }}
          error={errors.apiKey}
        >
          {isEditing ? (
            <Input type="password" id="apiKey" onChange={(e) => handleChange('apiKey', e.target.value)} />
          ) : (
            <ValueDisplay value="************************" />
          )}
        </FormGroup>
        {isEditing ? (
          <Fragment>
            <CreateButton
              className="mt-2"
              label={isSaving ? 'Saving...' : 'Save'}
              isLoading={isSaving}
              disabled={isSaving}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                handleSave();
              }}
            />
            <CancelButton
              hidden={isNew}
              className="mt-2 ms-2"
              floatRight={false}
              action={() => {
                setFormData(cachedFormData.current);
                setIsEditing(false);
              }}
            />
          </Fragment>
        ) : (
          <EditButton className="mt-2" action={() => setIsEditing(true)} floatRight={false} />
        )}
      </Form>
      <Confirmation
        show={showConfirm}
        content={languagePack.confirmMessage}
        confirmCallback={() => {
          saveProfileDetails(() => {
            setIsEditing(false);
            setShowConfirm(false);
            setIsSaving(false);
            setIsNew(false);
          });
        }}
        cancelCallback={() => {
          setFormData(cachedFormData.current);
          setIsEditing(false);
          setShowConfirm(false);
          setIsSaving(false);
        }}
      />
    </Fragment>
  );
}

VacancyPosterSettings.propTypes = {
  initFormData: PropTypes.shape(),
};

VacancyPosterSettings.defaultProps = {
  initFormData: {},
};

export default VacancyPosterSettings;
