/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import applyRules from 'react-jsonschema-form-conditionals';
import Engine from 'json-rules-engine-simplified';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { schema, uiSchema, rules } from '../../../../js/model/form-schemas/LocationSchema';
import SingleDDSelect from '../../../Base/Forms/Custom/DDSelect/SingleDDSelect';
import AsyncDDSelect from '../../../Base/Forms/Custom/DDSelect/AsyncDDSelect';
import RadioBtns from '../../../Base/Forms/Custom/RadioBtns/RadioBtns';
import AddressLookup from '../../../Base/Forms/Custom/AddressLookup/AddressLookup';
import { transformErrors } from '../../../../js/utils/validation-helper';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { createLocation, updateLocation } from '../../../../api/LocationAPI';
import { isNumber } from '../../../../js/utils/validation';
import { AccountSelectField } from '../../../Base/Account';

const LocationForm = applyRules(schema, uiSchema, rules, Engine)(Form);

function getGeoLoc(lat, long) {
  if (lat && long) return [lat, long];
  return [];
}

function getLatLong(geoLocation = {}) {
  if (!Object.keys(geoLocation).length) return {};

  const { coordinates = [] } = geoLocation;
  if (!coordinates.length) return {};

  const [lat, long] = coordinates[0];
  return { lat, long };
}

async function proxyCall(data, id) {
  if (id) return updateLocation({ ...data, id });
  return createLocation(data);
}

function LocationEditor({
  isEditing,
  data,
  onSave,
  onCancel,
  locationType: locType,
  eventFormat: evtFormat,
  hideDefault,
  activeAccountId,
  activeAccountName,
  totalAccounts,
}) {
  const {
    default: defaultLoc,
    name,
    location = {},
    id,
    type = 'ALL',
    eventFormat = null,
    phoneNumber: phone,
    sharedWith,
    readOnly,
  } = data;

  const { geoLocation, ...locObj } = location;
  const mappedData = {
    locationName: name,
    address: {
      address: { ...locObj, ...getLatLong(geoLocation) },
    },
    defaultLocation: defaultLoc,
    locationType: locType || type,
    eventFormat: evtFormat || eventFormat,
    phoneNumber: phone,
    sharedWith: (sharedWith || []).filter(({ accountId }) => accountId !== activeAccountId),
    editable: !readOnly,
  };

  const [clientData, setClientData] = useState(mappedData);
  const [isSaving, setIsSaving] = useState(false);
  const [addAnotherLocation, setAddAnotherLocation] = useState(false);
  const [key, setKey] = useState('1');

  useLayoutEffect(() => {
    [...document.querySelectorAll('.form-group')].forEach((elm) => {
      if (
        // hide location types dropdown if requested
        (locType && elm.classList.contains('location-type')) ||
        // hide format types dropdown if requested
        (evtFormat && elm.classList.contains('location-event-format')) ||
        // hide default checkbox if requested
        (hideDefault && elm.classList.contains('default-cb')) ||
        // hide editable checkbox when only access to one account
        (totalAccounts < 2 && elm.classList.contains('editable-cb'))
      ) {
        elm.style.display = 'none';
      }
    });
  }, [evtFormat, hideDefault, locType, totalAccounts]);

  async function handleSave(formData) {
    setIsSaving(true);
    setClientData(formData);

    const {
      defaultLocation,
      locationName,
      address: { address },
      locationType,
      eventFormat: format = null,
      phoneNumber,
      sharedWith: share,
      editable,
    } = formData;
    const { lat, long, ...rest } = address;

    const serverData = {
      name: locationName,
      ...(Object.keys(address).length
        ? {
            location: { ...rest, geoLocation: { coordinates: getGeoLoc(lat, long) } },
          }
        : {}),
      default: defaultLocation,
      eventFormat: format,
      type: locationType,
      ...(phoneNumber ? { phoneNumber: phoneNumber.replace(/ /g, '') } : {}),
      readOnly: !editable,
      share: [...(share || []), { accountId: activeAccountId, accountName: activeAccountName }],
    };

    if (!isEditing) serverData.accountId = activeAccountId;

    const resp = await retryableAPICall(() => proxyCall(serverData, id));

    if (typeof resp === 'string') {
      toast.error(`Error ${isEditing ? 'updating' : 'creating'} location`);
    } else {
      toast.success(`Location successfully ${isEditing ? 'updated' : 'created'}`);
      onSave(resp, addAnotherLocation);
      if (addAnotherLocation) {
        setKey(`${parseInt(key, 10) + 1}`);
      }
      setClientData(mappedData);
    }

    setIsSaving(false);
  }

  function handleFormDataUpdate(form) {
    const { formData } = form;
    const address = formData;
    const addr = address?.address?.address;

    if (addr && addr.city && addr.county && addr.country) {
      if (!formData?.locationName) {
        setClientData({ ...formData, locationName: `${addr.city}, ${addr.county}, ${addr.country}` });
      }
    }
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Location`;

  return (
    <Fragment key={key}>
      <p className="mb-2 font-weight-bold">{`This location will be created/owned by account: ${activeAccountName}`}</p>
      <LocationForm
        fields={{
          accountSelect: AccountSelectField,
          addressLookup: AddressLookup,
          radioBtns: RadioBtns,
          remoteSelect: AsyncDDSelect,
          singleSelect: SingleDDSelect,
        }}
        formData={clientData}
        noHtml5Validate
        onChange={handleFormDataUpdate}
        onSubmit={({ formData }) => handleSave(formData)}
        showErrorList={false}
        transformErrors={transformErrors}
        validate={(form, errors) => {
          const errs = { ...errors };

          const { phoneNumber, address, locationType, eventFormat: format } = form;

          if (phoneNumber) {
            // makesure phone number is a number
            const number = phoneNumber.replace(/ /g, '');
            const isNumValidObj = isNumber(number);
            if (!isNumValidObj.valid) errs.phoneNumber.addError(isNumValidObj.message);
          }

          // only validate address if locationType is ALL OR VACANCY
          // OR eventFormat is Face to Face
          if (address && (/ALL|VACANCY/.test(locationType) || format === 'IN_PERSON')) {
            const {
              address: { country, lat, long },
            } = address;
            if (!country || !lat || !long) errs.address.addError('Address is required');
          }

          return errs;
        }}
      >
        <div className="float-end w-100">
          <CreateButton disabled={isSaving} isLoading={isSaving} label={saveBtnText} type="submit" />
          <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
        </div>
      </LocationForm>
    </Fragment>
  );
}

LocationEditor.propTypes = {
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  data: PropTypes.shape({
    default: PropTypes.bool,
    eventFormat: PropTypes.string,
    id: PropTypes.string,
    location: PropTypes.shape(),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    readOnly: PropTypes.bool,
    sharedWith: PropTypes.arrayOf(PropTypes.shape()),
    type: PropTypes.string,
  }),
  eventFormat: PropTypes.string,
  hideDefault: PropTypes.bool,
  isEditing: PropTypes.bool,
  locationType: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  totalAccounts: PropTypes.number,
};

LocationEditor.defaultProps = {
  activeAccountId: undefined,
  activeAccountName: undefined,
  data: {},
  eventFormat: null,
  hideDefault: false,
  isEditing: false,
  locationType: null,
  onCancel: () => {},
  onSave: () => {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountId,
        activeAccountName,
        data: { accountAccess = [] },
      },
    },
  } = state;

  return { totalAccounts: accountAccess.length, activeAccountId, activeAccountName };
}

export default connect(mapStateToProps)(LocationEditor);
