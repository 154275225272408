import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSubmission, submitToRotaReady, uploadFile } from '../../../../api/Integrations/RotaReadyAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Integration from './Integration';

const rotaReadyInputConfig = [
  {
    id: 'dateStart',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'entityId',
    label: 'Entity Id',
    type: 'select',
    required: true,
    url: 'rotaready/data/ENTITY_GROUP',
  },
  {
    id: 'groupId',
    label: 'Group Id',
    type: 'select',
    required: true,
    url: 'rotaready/data/STAFF_GROUP',
  },
  {
    id: 'positionId',
    label: 'Position Id',
    type: 'select',
    required: true,
    url: 'rotaready/data/POSITION',
  },
  {
    id: 'contractBasis',
    label: 'Contract Basis',
    type: 'select',
    required: true,
    url: 'rotaready/data/CONTRACT_BASIS',
  },
  {
    id: 'visaFileId',
    label: 'Visa Upload',
    type: 'fileUpload',
    url: '/rotaready/submission/visa/{applicantId}',
  },
  {
    id: 'documentCategory',
    label: 'Visa Type',
    required: false,
    type: 'select',
    url: 'rotaready/data/document/category',
  },
  {
    id: 'documentExpiry',
    label: 'Visa Expiry Date',
    required: true,
    type: 'date',
  },
  {
    id: 'payAmount',
    configId: 'defaultContractedHours',
    label: 'Salary',
    type: 'number',
    required: false,
    defaultValue: 0,
    inputProps: {
      min: 1,
    },
  },
  {
    id: 'payAmountType',
    label: 'Salary Frequency',
    required: false,
    type: 'select',
    url: 'rotaready/data/PAY_AMOUNT_TYPE',
  },
];

function RotaReadyForm({ applicantId, formSchemaData, candidateProfileSummary }) {
  async function getRotaReadyData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }
      const { status, request } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: resp.errors?.join(', ') || resp.validationResult?.join(', '),
          data:
            status === 'FAILED'
              ? {
                  companyId: request.companyId.value,
                  contractBasis: request.contractBasis?.value,
                  dateStart: request.dateStart,
                  documentCategory: request.documentCategory?.value,
                  documentExpiry: request.documentExpiry,
                  entityId: request.entityId.value,
                  groupId: request.groupId.value,
                  payAmount: request.payAmount,
                  payAmountType: request.payAmountType?.value,
                  payrollPaymentMethod: request.payrollPaymentMethod?.value,
                  positionId: request.positionId.value,
                  visaFileId: request.visaFileId,
                }
              : request,
        };
      }

      return {
        status: 'SUCCESS',
        data: {
          companyId: (request.companyId || {}).label,
          contractBasis: (request.contractBasis || {}).label,
          dateStart: request.dateStart,
          documentCategory: (request.documentCategory || {}).label,
          documentExpiry: request.documentExpiry,
          entityId: (request.entityId || {}).label,
          groupId: (request.groupId || {}).label,
          payAmount: request.payAmount,
          payAmountType: (request.payAmountType || {}).label,
          payrollPaymentMethod: (request.payrollPaymentMethod || {}).label,
          positionId: (request.positionId || {}).label,
          visaFileId: request.visaFileId,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const isExpirableCategory = (formData, formSchemaData) => {
    return formSchemaData.documentCategory?.find((cat) => cat.value === formData.documentCategory)?.expirable;
  };

  function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
    let convertedUrl = id === 'visaFileId' ? url?.replace('{applicantId}', applicantId) : url;
    let convertedValue = type === 'number' && !formData[id] ? 0 : value;
    let convertedIsDisabled = isDisabled;
    let isHidden = false;

    if (isComplete) {
      convertedValue = formData[id];
    }

    if (
      id === 'documentExpiry' &&
      (!isExpirableCategory(formData, formSchemaData) || formData.documentCategory === '')
    ) {
      isHidden = true;
    }

    return { url: convertedUrl, value: convertedValue, isDisabled: convertedIsDisabled, isHidden };
  }

  function getValidationConfig(formData) {
    const isExpirableCategory = formSchemaData.documentCategory?.find(
      (cat) => cat.value === formData.documentCategory,
    )?.expirable;

    return rotaReadyInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      let isRequired = required;
      if (id === 'documentExpiry' && (!isExpirableCategory || formData.documentCategory === '')) {
        isRequired = false;
      }

      return [...acc, { id, required: isRequired, ...(type === 'number' ? { max, min, type } : {}) }];
    }, []);
  }

  async function formSubmit(applicantId, formData) {
    try {
      const resp = await retryableAPICall(() =>
        submitToRotaReady(applicantId, {
          companyId: '1',
          contractBasis: formData.contractBasis,
          dateStart: formData.dateStart,
          documentCategory: formData.documentCategory,
          documentExpiry: formData.documentExpiry,
          entityId: formData.entityId,
          groupId: formData.groupId,
          payAmount: formData.payAmount,
          payAmountType: formData.payAmountType,
          payEffectiveDate: formData.dateStart,
          payElementId: 1,
          payrollPaymentMethod: 'ELECTRONIC',
          positionId: formData.positionId,
          visaFileId: formData.visaFileId,
        }),
      );

      const { request, status } = resp;
      if (status === 'NOT_FOUND_ERROR') {
        return 'NOT_FOUND_ERROR';
      }

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: resp.errors?.join(', ') || resp.validationResult?.join(', ') || `${resp.stage} failed`,
          data:
            status === 'FAILED'
              ? {
                  companyId: (request.companyId || {}).value,
                  contractBasis: (request.contractBasis || {}).value,
                  dateStart: request.dateStart,
                  documentCategory: (request.documentCategory || {}).value,
                  documentExpiry: request.documentExpiry,
                  entityId: (request.entityId || {}).value,
                  groupId: (request.groupId || {}).value,
                  payAmount: request.payAmount,
                  payAmountType: (request.payAmountType || {}).label,
                  payrollPaymentMethod: (request.payrollPaymentMethod || {}).value,
                  positionId: (request.positionId || {}).value,
                  visaFileId: request.visaFileId,
                }
              : {},
        };
      }

      return {
        status: 'SUCCESS',
        data: {
          companyId: (request.companyId || {}).label,
          contractBasis: (request.contractBasis || {}).label,
          dateStart: request.dateStart,
          documentCategory: (request.documentCategory || {}).label,
          documentExpiry: request.documentExpiry,
          entityId: (request.entityId || {}).label,
          groupId: (request.groupId || {}).label,
          payAmount: request.payAmount,
          payAmountType: (request.payAmountType || {}).label,
          payrollPaymentMethod: (request.payrollPaymentMethod || {}).label,
          positionId: (request.positionId || {}).label,
          visaFileId: request.visaFileId,
        },
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  return (
    <Integration
      title="Rota Ready"
      applicantId={applicantId}
      integrationName="Rota Ready"
      formConfig={rotaReadyInputConfig}
      formSubmit={formSubmit}
      valueSetter={valueSetter}
      getSubmission={getRotaReadyData}
      componentName="rota_ready_submit_button"
      getValidationConfig={getValidationConfig}
      uploadFile={uploadFile}
      candidateProfileSummary={candidateProfileSummary}
    />
  );
}

RotaReadyForm.propTypes = {
  applicantId: PropTypes.string,
  candidateProfileSummary: PropTypes.shape(),
};

RotaReadyForm.defaultProps = {
  applicantId: null,
  candidateProfileSummary: null,
};
function mapStateToProps(state) {
  return { formSchemaData: state.rotaReady };
}

export default connect(mapStateToProps, null)(RotaReadyForm);
