import React from 'react';
import PropTypes from 'prop-types';
import BuildDetails from './BuildDetails';

const dict = {
  bankName: 'Bank Name',
  bankAccountName: 'Name of Account Holder',
  bankSortCode: 'Bank Sort Code',
  bankAccNumber: 'Bank Account Number',
};

function BankDetails({ value, vertical }) {
  if (!Object.keys(value).length) return null;

  return Object.entries(value).map(([key, val]) => (
    <BuildDetails key={key} title={dict[key]} titleColumnSize="4" vertical={vertical}>
      {val}
    </BuildDetails>
  ));
}

BankDetails.propTypes = {
  value: PropTypes.shape({
    bankName: PropTypes.string,
    bankAccountName: PropTypes.string,
    bankSortCode: PropTypes.string,
    bankAccNumber: PropTypes.string,
  }),
  vertical: PropTypes.bool,
};

BankDetails.defaultProps = {
  value: {},
  vertical: false,
};

export default BankDetails;
