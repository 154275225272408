import React from 'react';
import PropTypes from 'prop-types';
import { getConditionOptionValues } from '../utils';

function LogicConditionSelect({ type, value, onChange }) {
  const options = getConditionOptionValues(type);

  return (
    <select
      className="select"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="" disabled>Select Condition</option>
      {options.map((opt, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <option key={`cond-opt-${i}`} value={opt}>{opt}</option>
      ))}
    </select>
  );
}

LogicConditionSelect.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

LogicConditionSelect.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => { },
};

export default LogicConditionSelect;
