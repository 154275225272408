import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../Base/Slider/Slider';
import { Confirmation } from '../../../Base/Modal';

function ClientAdminSlider({
  isSliderOpen,
  closeSlider,
  children,
  title,
}) {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Fragment>
      <Slider
        title={title}
        isOpen={isSliderOpen}
        toggleOpen={(isBgClick) => {
          if (isBgClick) {
            setShowConfirm(true);
          }
          else {
            closeSlider();
          }
        }}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        {children}
      </Slider>
      <Confirmation
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          closeSlider();
        }}
      />
    </Fragment>
  );
}

ClientAdminSlider.propTypes = {
  isSliderOpen: PropTypes.bool,
  closeSlider: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

ClientAdminSlider.defaultProps = {
  isSliderOpen: false,
  closeSlider: () => { },
  children: () => { },
  title: null,
};

export default ClientAdminSlider;
