import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { access },
} = config;

export async function saveCredentials(organisationId, apiKey) {
  if (organisationId && apiKey) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${access.userPath}`,
      { apiKey, organisationId },
      { headers: { ...getCommonHeaders() } },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getCredentials() {
  const result = await axios.get(`${api.middlewareAPIURL}${access.userPath}`, { headers: { ...getCommonHeaders() } });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${access.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToAccess(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${access.submitPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getAccessData(endpoint) {
  const result = await axios.get(`${api.middlewareAPIURL}${access.dataPath}/${endpoint}`, {
    headers: { ...getCommonHeaders() },
  });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
