/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseSVG from '../BaseSVG';

const lookUp = {
  paperPlane: {
    viewBox: '0 0 512 512',
    d: 'M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z',
  },
  paperPlaneBoxed: {
    viewBox: '0 0 1000 1000',
    d: 'M 75 0C 75 0 75 0 75 0C 33 0 0 33 0 75C 0 75 0 925 0 925C 0 967 33 1000 75 1000C 75 1000 925 1000 925 1000C 967 1000 1000 967 1000 925C 1000 925 1000 75 1000 75C 1000 33 967 0 925 0C 925 0 75 0 75 0M 75 50C 75 50 925 50 925 50C 939 50 950 61 950 75C 950 75 950 925 950 925C 950 939 939 950 925 950C 925 950 75 950 75 950C 61 950 50 939 50 925C 50 925 50 75 50 75C 50 61 61 50 75 50 M 185 525C 185 525 185 525 185 525C 244 492 309 465 370 438C 476 394 581 350 688 309C 709 302 746 296 750 326C 748 370 740 413 734 457C 720 552 703 647 687 742C 681 774 642 790 616 770C 555 729 494 688 434 646C 414 626 432 597 450 583C 500 533 553 491 601 439C 614 408 576 434 563 442C 495 490 428 540 355 581C 318 602 275 584 238 573C 205 559 156 546 185 525C 185 525 185 525 185 525',
  },
};

const multiPaths = [
  { fill: '#b6e786', d: 'M45.56,31.78l-.25.08a20.19,20.19,0,0,0-9.82,30.72c.16.23.33.46.51.68a119.7,119.7,0,0,0-12.55,15L0,41.83v-.14c.14-.31.29-.62.45-.92l.42-.83a4.83,4.83,0,0,1,.36-.67c.21-.38.52-.9.91-1.52.09-.15.18-.3.28-.44L3,36.42c.13-.2.26-.39.4-.58A36.9,36.9,0,0,1,7.85,30.5c.17-.18.35-.36.54-.53.94-.91,1.89-1.74,2.85-2.5l.21.3a17.55,17.55,0,0,0,28.56,0l.41-.6.07.06C42.26,28.68,44,30.23,45.56,31.78Z' },
  { fill: '#4fe1c2', d: 'M43.29,17.56a17.47,17.47,0,0,1-2.87,9.61l-.41.6a17.55,17.55,0,0,1-28.56,0l-.21-.3a17.56,17.56,0,1,1,32.05-9.91Z' },
  { fill: '#4fe1c2', d: 'M103.28,41.69v.14L79.83,78.3a119.7,119.7,0,0,0-12.55-15c.18-.22.35-.45.51-.68A20.19,20.19,0,0,0,58,31.86l-.25-.08c1.59-1.55,3.3-3.1,5.07-4.55l.06-.05c.14.2.27.4.41.59a17.55,17.55,0,0,0,28.56,0,3.37,3.37,0,0,0,.21-.31h0c1,.76,1.91,1.59,2.85,2.5.19.17.37.35.54.53a36.9,36.9,0,0,1,4.44,5.34c.14.19.27.38.4.58l.59.89c.1.14.19.29.28.44.39.62.7,1.14.91,1.52a4.83,4.83,0,0,1,.36.67l.42.83C103,41.07,103.14,41.38,103.28,41.69Z' },
  { fill: '#1c60a8', d: 'M79.83,78.3l-2.08,3.23L60.9,107.74H42.38L25.53,81.53,23.45,78.3A119.7,119.7,0,0,1,36,63.26a18.9,18.9,0,0,0,2.35,2.55A19.59,19.59,0,0,0,51.62,71h0a19.59,19.59,0,0,0,13.27-5.17,18.9,18.9,0,0,0,2.35-2.55A119.7,119.7,0,0,1,79.83,78.3Z' },
  { fill: '#b6e786', d: 'M95.11,17.56A17.39,17.39,0,0,1,92,27.46a3.37,3.37,0,0,1-.21.31,17.55,17.55,0,0,1-28.56,0c-.14-.19-.27-.39-.41-.59a17.56,17.56,0,1,1,32.26-9.62Z' },
  { fill: '#7c89e3', d: 'M71.5,50.9a20.14,20.14,0,0,1-3.71,11.68c-.16.23-.33.46-.51.68a18.9,18.9,0,0,1-2.35,2.55A19.59,19.59,0,0,1,51.66,71h0a19.59,19.59,0,0,1-13.27-5.17A18.9,18.9,0,0,1,36,63.26c-.18-.22-.35-.45-.51-.68a20.19,20.19,0,0,1,9.82-30.72l.25-.08.08.07a19.68,19.68,0,0,1,5.92-1h.16a19.68,19.68,0,0,1,5.92,1l.08-.07.25.08A20.08,20.08,0,0,1,71.5,50.9Z' },
];

function PaperPlane(props) {
  const { type, multi, ...rest } = props;

  const config = lookUp[type];

  return (
    <BaseSVG
      {...rest}
      name="Paper Plane"
      viewBox={config.viewBox}
      d={config.d}
      paths={multi && type === 'paperPlaneBoxed' ? multiPaths : []}
    />
  );
}

PaperPlane.propTypes = {
  type: PropTypes.string,
  multi: PropTypes.bool,
};

PaperPlane.defaultProps = {
  type: 'paperPlane',
  multi: false,
};

export default PaperPlane;
