import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { company, api } = config;

/**
 * Gets a the company associated with the logged in user
 * @param getCompanyProfileForAccount
 */
export async function getCompanyProfileForAccount() {
  const result = await axios.get(
    `${api.middlewareAPIURL}${company.companyByAccountPath}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

/**
 * Gets a the company associated with the logged in user
 * @param getCompanyProfileForAccount
 */
export async function updateCompanyProfile(companyId, profile, locations, documents) {
  const result = await axios.put(
    `${api.middlewareAPIURL}${company.companyProfilePath}`,
    {
      companyId,
      profile,
      locations,
      documents,
    },
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
