import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import DropdownMenu from './DropdownMenu';
import { useOutsideClick } from '../hooks';
import DropDownComponent from './DropDownComponent';

export default function DropdownManager({
  position,
  trigger,
  onDocumentClick,
  showMenu,
  menuClassName,
  menuStyle,
  menuItems,
  menuComponent,
  title,
}) {
  const wrapperRef = useRef();
  useOutsideClick(wrapperRef, onDocumentClick);

  return (
    <Manager>
      <Reference>{({ ref }) => trigger({ ref })}</Reference>
      <Popper placement={position}>
        {({ ref, style, placement }) =>
          showMenu && (
            <div ref={wrapperRef}>
              <div
                ref={ref}
                className={`dd-menu ${menuClassName || ''}`}
                data-placement={placement}
                style={{
                  ...style,
                  zIndex: 1,
                  ...menuStyle,
                }}
              >
                {menuComponent ? (
                  <DropDownComponent title={title}>{menuComponent}</DropDownComponent>
                ) : (
                  <DropdownMenu menuItems={menuItems} title={title} />
                )}
              </div>
            </div>
          )
        }
      </Popper>
    </Manager>
  );
}

DropdownManager.propTypes = {
  menuClassName: PropTypes.string,
  menuComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  menuItems: PropTypes.arrayOf(PropTypes.shape()),
  menuStyle: PropTypes.shape(),
  onDocumentClick: PropTypes.func,
  position: PropTypes.string,
  showMenu: PropTypes.bool,
  title: PropTypes.string,
  trigger: PropTypes.func,
};

DropdownManager.defaultProps = {
  menuClassName: null,
  menuComponent: null,
  menuItems: {},
  menuStyle: {},
  onDocumentClick: () => {},
  position: 'bottom',
  showMenu: false,
  title: null,
  trigger: () => {},
};
