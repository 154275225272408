import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const { api, forms } = config;

const basicThemeOne = {
  palette: {
    type: 'light',
    primary: {
      main: '#7C89E',
    },
    text: {
      primary: '#495057',
    },
    error: {
      main: '#a11e1e',
    },
  },
  typography: {
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightLight: 100,
    fontFamily: 'Open Sans',
    h1: {
      fontSize: '32px',
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: '0em',
      color: '#5c6873',
    },
    h6: {
      fontSize: '1.2rem',
    },
    button: {
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: '0.1em',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        // The props to change the default for.
        size: 'small',
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: '#17a2b8',
          color: 'white',
          '&:hover': {
            backgroundColor: '#138496',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#ffffff',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        // The props to change the default for.
        size: 'small',
      },
    },
  },
  props: {},
};

export async function createFormStyle(name, sharedWith) {
  const body = {
    theme: {
      name,
      muiTheme: basicThemeOne,
      customFonts: [
        {
          type: 'GOOGLE',
          url: 'Open Sans',
        },
      ],
      backgroundImage: null,
      logo: null,
      backgroundColour: '#ffffff',
      isDefault: false,
    },
    sharedWith,
  };

  const result = await axios.post(`${api.middlewareAPIURL}${forms.formStyles}`, body, { headers: getCommonHeaders() });
  if (result.status === 201 || result.status === 200) {
    return { ...result.data.theme, id: result.data.id, sharedWith: result.data.sharedWith };
  }
  return null;
}

export async function updateFormStyle(
  name,
  id,
  muiTheme,
  customFonts,
  backgroundImage,
  logo,
  backgroundColour,
  sharedWith,
  isDefault,
) {
  const body = {
    id,
    theme: {
      name,
      muiTheme,
      customFonts,
      backgroundImage,
      backgroundColour,
      logo,
      isDefault,
    },
    sharedWith,
  };

  const result = await axios.put(`${api.middlewareAPIURL}${forms.formStyles}`, body, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function getAllFormStyles() {
  const result = await axios.get(`${api.middlewareAPIURL}${forms.formStyles}`, {
    headers: getCommonHeaders(),
  });
  if (result.status === 201 || result.status === 200) {
    return result.data.map((theme) => {
      return { ...theme.theme, id: theme.id, sharedWith: theme.sharedWith };
    });
  }
  return null;
}

export async function deleteFormStyle(id = '') {
  const result = await axios.delete(`${api.middlewareAPIURL}${forms.formStyles}/${id}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}

export async function addFile(body) {
  const result = await axios.post(`${api.middlewareAPIURL}/file`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}
