import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { nory },
} = config;

export async function getSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${nory.basePath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function submitToNory(applicantId, data = {}) {
  if (Object.keys(data).length) {
    try {
      const result = await axios.post(
        `${api.middlewareAPIURL}${nory.basePath}/${applicantId}`,
        { ...data },
        { headers: getCommonHeaders() },
      );

      if (result.status === 201 || result.status === 200) return result.data;
    } catch (exception) {
      return { ...exception.response.data, isError: true };
    }
  }

  return null;
}
