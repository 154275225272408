import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BuildDetails from './BuildDetails';
import PostalAddress from './PostalAddress';

export default function Communication({ data }) {
  if (!data || !Object.keys(data).length) return null;

  const { emailAddress, phone = {}, postalAddress = {} } = data;
  const { mobileTelephone } = phone;

  return (
    <Fragment>
      <BuildDetails title="Email">{emailAddress}</BuildDetails>
      <BuildDetails title="Mobile">{mobileTelephone}</BuildDetails>
      <PostalAddress data={postalAddress} />
    </Fragment>
  );
}

Communication.propTypes = {
  data: PropTypes.shape({
    emailAddress: PropTypes.string,
    phone: PropTypes.shape({
      mobileTelephone: PropTypes.string,
    }),
    postalAddress: PropTypes.shape(),
  }),
};

Communication.defaultProps = {
  data: {},
};
