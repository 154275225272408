import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup } from '../CommonComponents';

function FunnelInputGroup({
  id,
  label,
  required,
  placeholder,
  options,
  value,
  error,
  onChange,
}) {
  return (
    <FormGroup
      id={id}
      label={label}
      row
      labelProps={{ sm: '2' }}
      colProps={{ sm: '10' }}
      required={required}
      error={error}
    >
      <Select
        id={id}
        className="react-select"
        placeholder={placeholder}
        options={options}
        value={options.find(({ value: val }) => val === value)}
        onChange={({ value: val }) => onChange(val)}
      />
    </FormGroup>
  );
}

FunnelInputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

FunnelInputGroup.defaultProps = {
  required: false,
  placeholder: null,
  options: [],
  value: null,
  error: null,
  onChange: () => { },
};

export default FunnelInputGroup;
