/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import AccountSelector from './AccountSelector';
import Required from '../Forms/Custom/Required';
import classNames from 'classnames';

function AccountFormSelect({
  id,
  label,
  classNamePrefix,
  subText,
  onChange,
  className,
  selectClassName,
  labelClassName,
  currentAccounts,
  isMulti,
  ignoreActiveAccount,
  disableActiveAccount,
  currentActiveAccount,
  required,
  hideLabel,
  isDisabled,
}) {
  const [selected, setSelected] = useState();

  return (
    <AccountSelector isPassive ignoreActiveAccount={ignoreActiveAccount}>
      {({ accounts, activeAccount }) => {
        let selectedVal = selected;
        if (!isMulti && !selected) selectedVal = { value: activeAccount.id, label: activeAccount.name };

        if (currentAccounts && !selected) {
          selectedVal = currentAccounts.map((acc) => ({
            value: acc.id,
            label: acc.name,
            readOnly:
              acc.readOnly ||
              (disableActiveAccount && currentActiveAccount.id === acc.id && currentActiveAccount.name === acc.name),
          }));
        }

        const opts = accounts.map(({ accountId, accountName }) => {
          return {
            value: accountId,
            label: accountName,
            readOnly:
              disableActiveAccount &&
              currentActiveAccount.id === accountId &&
              currentActiveAccount.name === accountName,
          };
        });

        const styles = {
          multiValue: (base, state) => (state.data.readOnly ? { ...base, backgroundColor: 'gray' } : base),
          multiValueLabel: (base, state) =>
            state.data.readOnly
              ? {
                  ...base,
                  fontWeight: 'bold',
                  color: 'white',
                  paddingRight: 6,
                }
              : base,
          multiValueRemove: (base, state) => (state.data.readOnly ? { ...base, display: 'none' } : base),
        };

        return (
          <FormGroup className={className}>
            {!hideLabel && (
              <Label for={id} className={labelClassName}>
                {label}
                {required && <Required />}
              </Label>
            )}
            {subText && <p className="help-block mb-2">{subText}</p>}
            <Select
              styles={styles}
              classNamePrefix={classNamePrefix}
              className={classNames('react-select', selectClassName)}
              placeholder="Choose Account"
              value={selectedVal}
              options={isMulti ? [{ label: 'Select All', value: '*' }, ...opts] : opts}
              onChange={(val) => {
                let updatedVal = isMulti ? val || [] : val;

                if (isMulti && val.length > 0 && val[val.length - 1].value === '*') {
                  updatedVal = opts;
                }

                setSelected(updatedVal);
                onChange(updatedVal);
              }}
              isMulti={isMulti}
              isClearable={Array.isArray(selectedVal) && selectedVal.every((v) => !v.readOnly)}
              isDisabled={isDisabled}
            />
          </FormGroup>
        );
      }}
    </AccountSelector>
  );
}

AccountFormSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  subText: PropTypes.string,
  currentAccounts: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  ignoreActiveAccount: PropTypes.bool,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  disableActiveAccount: PropTypes.bool,
  currentActiveAccount: PropTypes.shape(),
  selectClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  classNamePrefix: PropTypes.string,
};

AccountFormSelect.defaultProps = {
  label: 'Account',
  subText: null,
  currentAccounts: [],
  onChange: () => {},
  className: null,
  isMulti: false,
  ignoreActiveAccount: false,
  required: false,
  hideLabel: false,
  disableActiveAccount: false,
  currentActiveAccount: { id: '', name: '' },
  selectClassName: '',
  labelClassName: '',
  classNamePrefix: '',
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: { activeAccountId, activeAccountName },
    },
  } = state;

  return { currentActiveAccount: { id: activeAccountId, name: activeAccountName } };
}

export default connect(mapStateToProps)(AccountFormSelect);
