import useInterval from 'use-interval';
import { useState } from 'react';
import config from '../../../config/config';

const { env, version, versionBaseUrl } = config;

function VersionChecker() {
  const [showNewVersionBanner, setShowNewVersionBanner] = useState(false);

  useInterval(() => {
    if (!showNewVersionBanner && env !== 'local') {
      fetch(`${versionBaseUrl}version-${env}.txt`, { mode: 'cors' }).then((r) => {
        r.text().then((remVer) => {
          const remoteVerNum = parseInt(remVer.trim(), 10);
          if (remoteVerNum > version) {
            setShowNewVersionBanner(true);
          }
        });
      });
    }
  }, 300000);

  if (!showNewVersionBanner) return null;
  return (
    <div
      className="Toastify__toast-container Toastify__toast--success text-center cursor-pointer"
      onClick={() => {
        setShowNewVersionBanner(false);
        window.location.reload();
      }}
      style={{ position: 'relative', width: '100%' }}
    >
      <p style={{ fontSize: '1.3em', padding: '5px' }}>
        There is a new version available!
        <br />
        Please save your work and click here to update now.
      </p>
    </div>
  );
}

VersionChecker.propTypes = {};

VersionChecker.defaultProps = {};

export default VersionChecker;
