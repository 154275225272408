import kebabcase from 'lodash.kebabcase';

export function setVarsObject(stylesObj = {}) {
  return Object.entries(stylesObj).reduce(
    (acc, [prop, style]) => ({ ...acc, [`--${kebabcase(prop).toUpperCase()}`]: style }),
    {},
  );
}

export function gradientCreator(primaryColour, secondaryColour) {
  const isCorpBrand = primaryColour.toLowerCase() === '#7c89e3' && secondaryColour.toLowerCase() === '#b6e786';

  return {
    primaryColour,
    secondaryColour,
    gradientColour2: isCorpBrand ? '#4fe1c2' : secondaryColour,
    gradientColour4: isCorpBrand ? '#1c60a8' : primaryColour,
  };
}

export const strokeCol = {
  A: 'rgba(244,139,148,0.63)',
  B: '#e2bb78',
  C: 'rgba(80,126,101,0.68)',
  D: 'rgba(108,91,123,0.82)',
  E: '#355c7d',
  F: '#f7a8a6',
  G: 'rgba(253,202,88,0.87)',
  H: '#addcc9',
  I: '#bf6c85',
  J: '#60698a',
  K: '#f8b196',
  L: '#ffd17c',
  M: '#cbe7df',
  N: '#b2bec3',
  O: '#7fc7db',
  P: '#fdd2b5',
  Q: '#fff4e8',
  R: '#dbebc2',
  S: '#e8e7e5',
  T: '#84e2ea',
  U: '#d4f0f0',
  V: '#8fcaca',
  W: '#ffd8be',
  X: '#a2e1db',
  Y: '#f6eac2',
  Z: '#abdee6',
};
