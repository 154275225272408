import React from 'react';
import PropTypes from 'prop-types';
import AccountSelector from './AccountSelector';
import { useLanguagePack } from '../hooks';

function AccountSelectorModal({ isOpen, onToggle }) {
  const langPack = useLanguagePack('account-selector');

  return (
    <AccountSelector
      isModal
      modalProps={{
        title: langPack.modalTitle || 'Switch Account',
        isOpen,
        onToggle,
        minHeight: '55px',
        allowOverflow: true,
        size: 'md',
      }}
    />
  );
}

AccountSelectorModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
};

AccountSelectorModal.defaultProps = {
  isOpen: false,
  onToggle: () => { },
};

export default AccountSelectorModal;
