import React, { useRef, useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { LocationSelect } from '../../../../Scheduler/components/inputs';
import { getLocations } from '../../../../Scheduler/utils/fetchUtils';
import { useMounted } from '../../../hooks';

function normalizeLocationObject(locationObj = {}) {
  if (!Object.keys(locationObj).length) return locationObj;
  // take geoLocation coords
  const { geoLocation: { coordinates = [] } = {}, ...rest } = locationObj;
  // output as lat / long props
  const [lat, long] = coordinates[0] || [];
  return { ...rest, lat, long };
}

function LocationSelector({
  // schema = {},
  formContext,
  idSchema = {},
  uiSchema = {},
  name,
  // required,
  onChange,
  formData,
}) {
  // const { title } = schema;
  const { $id } = idSchema;
  let { asyncCache } = formContext;

  const isMounted = useMounted();
  const asyncCacheRef = useRef({});
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();

  // if no asyncCache set in formContext use a local cache
  if (!asyncCache) asyncCache = asyncCacheRef.current;
  // if not set, set the props related to this specific instance
  if (!asyncCache[$id]) asyncCache[$id] = { fetched: false, opts: [] };

  const setOpts = useCallback(
    (opts) => {
      setOptions(opts);
      if (opts.length === 1) onChange(normalizeLocationObject(opts[0].location));
    },
    [onChange],
  );

  const fetchOptions = useCallback(async () => {
    const opts = await getLocations('VACANCY');
    // stop future runs
    asyncCache[$id] = { fetched: true, opts: [...opts] };
    setOpts(opts);
  }, [$id, asyncCache, setOpts]);

  useEffect(() => {
    if (isMounted()) {
      // only fetch if hasn't already run
      if (!asyncCache[$id].fetched) {
        fetchOptions();
      } else {
        setOpts(asyncCache[$id].opts);
      }
    }
  }, [$id, asyncCache, fetchOptions, isMounted, setOpts]);

  useEffect(() => {
    if (isMounted()) {
      const { lat, long } = formData;
      const selectedOpt = options.find(({ location }) => {
        const locObj = normalizeLocationObject(location);
        return locObj.lat === lat && locObj.long === long;
      });

      setSelected((selectedOpt || {}).value || '');
    }
  }, [formData, isMounted, options]);

  function handleChange(id, locObj = {}, isNewLoc) {
    // standard selection
    let value = options.find(({ value: val }) => val === id);

    // new location so not in options
    if (isNewLoc) {
      value = { ...locObj };
      // update the options
      const optsArr = [...options, value];
      setOpts(optsArr);
      // and the cached options as they won't be refetched until form remounts
      asyncCache[$id] = { ...asyncCache[$id], opts: optsArr };
    }

    onChange(normalizeLocationObject((value || {}).location));
  }

  return (
    <Fragment>
      <Label className="control-label" htmlFor={name}>
        Choose Location
        <span className="required">*</span>
      </Label>
      <LocationSelect
        locationType="VACANCY"
        selectProps={{
          value: selected || '',
          options,
          onChange: handleChange,
          isClearable: true,
        }}
        updateOptionsOnSave={false}
        // stops default options overriding selected options
        useDefaultLocation={false}
        disabled={uiSchema && uiSchema['ui:readonly']}
      />
    </Fragment>
  );
}

LocationSelector.propTypes = {
  formData: PropTypes.shape(),
  schema: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  idSchema: PropTypes.shape().isRequired,
  formContext: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  // required: PropTypes.bool,
  onChange: PropTypes.func,
};

LocationSelector.defaultProps = {
  formData: {},
  // required: false,
  onChange: () => {},
};

export default LocationSelector;
