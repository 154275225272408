import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../../../Base/Modal';
import { useMounted } from '../../../Base/hooks';

const defaultAlertState = {
  isOpen: false,
  content: null,
};

const ALERT_SHOW = 'ALERT_SHOW';
const ALERT_HIDE = 'ALERT_HIDE';

function alertReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ALERT_SHOW:
      return { isOpen: true, content: payload };
    case ALERT_HIDE:
      return { isOpen: false, content: null };
    default:
      return state;
  }
}

function InfoAlert({ content, onClose }) {
  const isMounted = useMounted();
  const [alertState, alertDispatch] = useReducer(alertReducer, defaultAlertState);

  useEffect(() => {
    if (isMounted() && content) {
      alertDispatch({
        type: ALERT_SHOW,
        payload: content,
      });
    }
  }, [content, isMounted]);

  function handleClose() {
    alertDispatch({ type: ALERT_HIDE });
    onClose();
  }

  return (
    <Alert
      isOpen={alertState.isOpen}
      content={alertState.content}
      onOkay={handleClose}
    />
  );
}

InfoAlert.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func,
};

InfoAlert.defaultProps = {
  content: null,
  onClose: () => { },
};

export default InfoAlert;
