/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormGroup, Label, Input, FormText } from 'reactstrap';
import { SelectInput } from '../../../Base/Forms/Custom/CommonComponents';
import { roundTimeDifference } from '../../utils/dateTimeUtils';

function getOpts(diffDays, inviteType) {
  let len = 10;
  if (diffDays <= 10 && inviteType === 'SPECIFIC') len = diffDays - 2;
  return Array.from({ length: len }, (v, i) => ({ value: i + 1, label: i + 1 }));
}

function ReminderInput({ id, value, label, disabled, onChange, inviteType, startDate }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value > 0);
  }, [inviteType, value]);

  let diffDays = 0;
  if (startDate) {
    const [num, unit] = roundTimeDifference(startDate);
    diffDays = /minute|hour/.test(unit) ? 0 : num;
  }

  const isDisabled = disabled || (diffDays <= 2 && inviteType === 'SPECIFIC');

  const opts = getOpts(diffDays, inviteType);
  let selectVal = value || 1;
  // if user chooses specific the value could be out of range - reset it
  if (inviteType === 'SPECIFIC' && value > opts.length) selectVal = 1;

  return (
    <Fragment>
      <FormGroup check inline>
        <Label check disabled={isDisabled}>
          <Input
            type="checkbox"
            className="standard-checkbox"
            disabled={isDisabled}
            checked={checked && !isDisabled}
            onChange={(e) => {
              const {
                target: { checked: isChecked },
              } = e;
              setChecked(isChecked);
              if (!isChecked) onChange(0);
            }}
          />
          <span className="bottom-0" style={{ color: isDisabled ? '#73818f' : '' }}>
            Send reminder if not booked in
          </span>
        </Label>
      </FormGroup>
      {isDisabled && (
        <FormText color="muted">Reminders cannot be sent when set up date is too close to specific slot date</FormText>
      )}
      {checked && !isDisabled && (
        <FormGroup>
          <Label htmlFor={id}>{label}</Label>
          <SelectInput value={selectVal} options={opts} onChange={onChange} disabled={disabled} />
        </FormGroup>
      )}
    </Fragment>
  );
}

ReminderInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  inviteType: PropTypes.string,
};

ReminderInput.defaultProps = {
  value: 0,
  disabled: false,
  label: 'Send reminder',
  onChange: () => {},
  startDate: null,
  inviteType: 'SELF_BOOK',
};

export default ReminderInput;
