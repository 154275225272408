import axios from 'axios';
import { getCommonHeaders } from './common-api-utils';
import config from '../config/config';

const { api, locations } = config;

export async function getLocation(opts = {}) {
  const { id, type } = opts;

  let endPointPath = id ? `/${id}` : '';
  if (type) endPointPath += `?type=${type.toUpperCase()}`;

  const result = await axios.get(
    `${api.middlewareAPIURL}${locations.locationPath}${endPointPath}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createLocation(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${locations.locationPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function updateLocation(data = {}) {
  if (Object.keys(data).length && data.id && data.id.length) {
    const result = await axios.put(
      `${api.middlewareAPIURL}${locations.locationPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function deleteLocation(id) {
  if (id) {
    const result = await axios.delete(
      `${api.middlewareAPIURL}${locations.locationPath}/${id}`,
      { headers: getCommonHeaders() },
    );

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}
