import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { retryableAPICall } from '../../../api/common-api-utils';
import { downloadFiles } from '../../../api/FileAPI/FileAPI';
import { ModalPopup } from '../../Base/Modal';
import { uploadUtils } from '../../Base/Forms/Custom/FileUpload';
import { checkPermissions } from '../../../js/auth/AuthUtils';
import IconSVG from '../../Base/SVG/IconSVG';
import { PDFDisplay } from '../../Base/PDF';

function getIconProps(file) {
  const { contentType = '' } = file;

  switch (contentType) {
    case 'application/pdf':
      return { type: 'pdf' };
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      return { type: 'image' };
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    default:
      return { type: 'word' };
  }
}

// Temp replace attachments with file api
function downloadAttachment(fileId) {
  retryableAPICall(() => downloadFiles([fileId]));
}

function AttachmentEntry({ attachment, onDelete, title }) {
  const [previewFile, setPreviewFile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [previewError, setPreviewError] = useState(undefined);

  const fileExt = uploadUtils.fileExtension(attachment.fileName);
  const isImage = uploadUtils.isImage(attachment.fileName);

  async function previewAttachment(fileId, image) {
    setIsFileLoading(true);
    setIsModalOpen(true);
    setPreviewError(undefined);
    let file = await retryableAPICall(() => downloadFiles([fileId], { forceDownload: false, base64: true }));
    const fileRegex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    if (!fileRegex.test(file) && !image) {
      setPreviewError('Sorry the preview could not be loaded at this time');
    } else {
      file = image ? file[0] : file;
      setPreviewFile(file);
      setIsFileLoading(false);
    }
  }

  return (
    <Fragment>
      <div className="attachment-entry-wrapper">
        <div className="attachment-entry-container">
          {title && <h5 className="attachment-entry-title">{title}</h5>}
          <div className="attachment-entry-file">
            {checkPermissions(['candidate:attachments:delete']) && (
              <a
                className="delete-icon"
                href="#attachments"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(attachment);
                }}
              >
                <IconSVG name="Bin" />
              </a>
            )}
            <a
              className="file-icon-trigger"
              href="#download"
              onClick={(e) => {
                e.preventDefault();
                if (isImage || fileExt === 'pdf') {
                  previewAttachment(attachment.id, isImage);
                } else {
                  downloadAttachment(attachment.id);
                }
              }}
            >
              <IconSVG name="File" {...getIconProps(attachment)} />
            </a>
          </div>
          <div className="attachment-entry-file-name" title={attachment.fileName}>
            <p>{uploadUtils.truncateFilename(attachment.fileName, 12)}</p>
          </div>
        </div>
      </div>
      <ModalPopup
        isOpen={isModalOpen}
        title={attachment.fileName}
        okayLabel="Download File"
        cancelLabel="Close Preview"
        closeOnOkay={false}
        centerButtons
        centerContent
        onOkay={() => downloadAttachment(attachment.id)}
        onToggle={() => {
          setIsModalOpen(false);
          setPreviewFile(undefined);
        }}
      >
        {previewError && (
          <div>
            <p className="text-danger">{previewError}</p>
          </div>
        )}
        {previewFile && !previewError ? (
          <Fragment>
            {isImage ? (
              <img alt="preview" src={`data:image/jpeg;base64,${previewFile}`} style={{ maxWidth: '100%' }} />
            ) : (
              <PDFDisplay file={previewFile} isBase64 />
            )}
          </Fragment>
        ) : (
          <div className="mt-4">{isFileLoading && !previewError ? <Spinner color="secondary" /> : null}</div>
        )}
      </ModalPopup>
    </Fragment>
  );
}

AttachmentEntry.propTypes = {
  attachment: PropTypes.shape(),
  onDelete: PropTypes.func,
  title: PropTypes.string,
};

AttachmentEntry.defaultProps = {
  attachment: {},
  onDelete: () => {},
  title: null,
};

export default AttachmentEntry;
