/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

function DateRadio({
  name,
  limitToFutureDates,
  limitToPastDates,
  onChange,
  disabled,
}) {
  return (
    <FormGroup check inline>
      <Label check>
        <Input
          disabled={disabled}
          type="radio"
          className="standard-checkbox small"
          name={name}
          checked={!limitToFutureDates && !limitToPastDates}
          onChange={() => onChange({ limitToFutureDates: false, limitToPastDates: false })}
        />
        <span>No date limit</span>
      </Label>
      <Label check>
        <Input
          disabled={disabled}
          type="radio"
          className="standard-checkbox small"
          name={name}
          checked={limitToFutureDates && !limitToPastDates}
          onChange={() => onChange({ limitToFutureDates: true, limitToPastDates: false })}
        />
        <span>Limit to future dates</span>
      </Label>
      <Label check>
        <Input
          disabled={disabled}
          type="radio"
          className="standard-checkbox small"
          name={name}
          checked={!limitToFutureDates && limitToPastDates}
          onChange={() => onChange({ limitToFutureDates: false, limitToPastDates: true })}
        />
        <span>Limit to past dates</span>
      </Label>
    </FormGroup>
  );
}

DateRadio.propTypes = {
  name: PropTypes.string,
  limitToFutureDates: PropTypes.bool,
  limitToPastDates: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DateRadio.defaultProps = {
  name: 'date-radio',
  limitToFutureDates: false,
  limitToPastDates: false,
  onChange: () => { },
  disabled: false,
};

export default DateRadio;
