import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

export const stateLookup = {
  active: {
    color: 'success',
    state: 'active',
  },
  pending: {
    color: 'warning',
    state: 'pending',
  },
  submitted: {
    color: 'warning',
    state: 'pending',
  },
  success: {
    color: 'warning',
    state: 'success',
  },
  closed: {
    color: 'danger',
    state: 'close',
  },
  deleted: {
    color: 'danger',
    state: 'closed',
  },
  failed: {
    color: 'danger',
    state: 'failed',
  },
  expiring: {
    color: 'warning',
    state: 'expiring',
  },
  scheduled: {
    color: 'info',
    state: 'scheduled',
  },
};

export default function BadgePill({ status, className, label }) {
  if (!status || !stateLookup[status.toLowerCase()]) return null;

  const { color } = stateLookup[status.toLowerCase()];

  return (
    <Badge
      data-filtername="status"
      data-filtervalue={status}
      color={color}
      className={className}
    >
      {label || status}
    </Badge>
  );
}

BadgePill.propTypes = {
  status: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

BadgePill.defaultProps = {
  status: null,
  label: null,
  className: null,
};
