/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import SelectOption, { optionDataProp } from './SelectOption';

export default function DistanceFrom({
  optionData,
  onPostcodeChange,
  onSelect,
  postcode,
  selectedValue,
}) {
  return (
    <Fragment>
      <FormGroup>
        <Input
          type="select"
          id="distance"
          name="distance"
          defaultValue={selectedValue}
          onChange={(e) => {
            const { target: { value } } = e;
            onSelect(value);
          }}
        >
          {optionData.map((opt) => {
            let optValue = opt;
            let optLabel = opt;

            if (typeof opt === 'object') {
              optValue = opt.value;
              optLabel = opt.label;
            }

            return <SelectOption key={optValue} value={optValue}>{optLabel}</SelectOption>;
          })}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="postcode">From</Label>
        <Input
          type="text"
          id="postcode"
          name="postcode"
          value={postcode}
          placeholder="Postcode"
          onChange={(e) => onPostcodeChange(e.target.value.toUpperCase())}
        />
      </FormGroup>
    </Fragment>
  );
}

DistanceFrom.propTypes = {
  optionData: optionDataProp,
  onPostcodeChange: PropTypes.func,
  onSelect: PropTypes.func,
  postcode: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DistanceFrom.defaultProps = {
  optionData: [],
  onPostcodeChange: () => { },
  onSelect: () => { },
  postcode: '',
  selectedValue: 15,
};
