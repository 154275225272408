import { ADD_TRANSCRIPTION_REQUEST } from '../constants/actionTypes';

const initialState = {
  requests: {},
};

const transcriptionRequestReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { requests } = state;

  switch (type) {
    case ADD_TRANSCRIPTION_REQUEST:

      if (!requests[payload.fileId]) {
        requests[payload.fileId] = {};
      }

      requests[payload.fileId] = { status: payload.status, transcription: payload.transcription };

      return {
        ...state,
        requests: { ...requests },
      };
    default:
      return state;
  }
};

export default transcriptionRequestReducer;
