import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import placeholderImage from "../../../assets/img/nodata/reference_not_requested_img_2.png";
import ActionToBePerformedNoDataState from "../Other/ActionToBePerformedNoDataState";

function DbsCheckNotPermittedContainer({languagePack}) {

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="onboarding-container-center">
            <Row className="mt-2">
              <Col>
                <ActionToBePerformedNoDataState
                  image={placeholderImage}
                  title="Unsupported Job Role"
                  description={languagePack.dbsNotPermitted}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

DbsCheckNotPermittedContainer.propTypes = {
  languagePack: PropTypes.shape(),
};

DbsCheckNotPermittedContainer.defaultProps = {
  languagePack: {},
};

export default DbsCheckNotPermittedContainer;
