// eslint-disable-next-line import/prefer-default-export
export const words = {
  en: {
    noCandidatesMessage: 'You currently have no new candidates',
    loadingError: 'There has been an error loading the latest candidates',
    overdueCandPrefix: 'Number of days in same stage: ',
    overdueCandSuffix: 'days (not rejected or hired)',
    liveVacanciesLabel: 'Live Vacancies',
    createNewVacancyLabel: 'Create New',
    errorCreatingFilter: 'We are unable to process your request at this time',
  },
  es: {},
};

export default words;
