import React from 'react';
import BaseSVG from '../BaseSVG';

function Edit(props) {
  return (
    <BaseSVG
      {...props}
      name="Edit"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M21.8582211,19.1683347 L23.3582536,17.6685544 C23.5926337,17.4342137 24.000455,17.5982522 24.000455,17.9357028 L24.000455,24.7503295 C24.000455,25.9923351 22.9926207,27 21.7504062,27 L5.25004875,27 C4.00783434,27 3,25.9923351 3,24.7503295 L3,8.25274618 C3,7.01074061 4.00783434,6.00307572 5.25004875,6.00307572 L18.070639,6.00307572 C18.4034587,6.00307572 18.5722124,6.40614168 18.3378323,6.64516916 L16.8377998,8.14494947 C16.7674858,8.21525167 16.6737338,8.25274618 16.5706065,8.25274618 L5.25004875,8.25274618 L5.25004875,24.7503295 L21.7504062,24.7503295 L21.7504062,19.4307963 C21.7504062,19.3323732 21.7879071,19.2386369 21.8582211,19.1683347 Z M29.1990051,9.71034516 L16.8893634,22.0179173 L12.6517716,22.4865986 C11.42362,22.6225162 10.3782849,21.5867305 10.5142253,20.3494117 L10.9829855,16.1125323 L23.2926272,3.80496021 C24.3660879,2.73167993 26.1005005,2.73167993 27.1692736,3.80496021 L29.1943175,5.82966362 C30.2677783,6.9029439 30.2677783,8.6417517 29.1990051,9.71034516 L29.1990051,9.71034516 Z M24.5676548,11.1585705 L21.8441583,8.4355319 L13.1345946,17.1483181 L12.7923997,20.2088073 L15.8534035,19.8666699 L24.5676548,11.1585705 Z M27.6052206,7.4231802 L25.5801767,5.39847679 C25.3879851,5.20631743 25.0739157,5.20631743 24.8864117,5.39847679 L23.4379428,6.84670214 L26.1614393,9.56974076 L27.6099082,8.1215154 C27.7974123,7.92466924 27.7974123,7.61533955 27.6052206,7.4231802 L27.6052206,7.4231802 Z"
    />
  );
}

export default Edit;
