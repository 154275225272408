import React, { Fragment } from 'react';
import BaseSVG from '../BaseSVG';

function Clock(props) {
  return (
    <Fragment>
      <BaseSVG
        {...props}
        name="Clock"
        viewBox="0 0 515.1 515.1"
      // eslint-disable-next-line max-len
        d="M512.55,99.45L395.25,0L362.1,38.25l117.3,99.45L512.55,99.45z M153,40.8L119.85,2.55L2.55,99.45L35.7,137.7L153,40.8z     M270.3,158.1h-38.25v153l119.85,73.951l20.4-30.602l-102-61.199V158.1z M257.55,56.1c-127.5,0-229.5,102-229.5,229.5    c0,127.5,102,229.5,229.5,229.5s229.5-102,229.5-229.5C487.05,158.1,385.05,56.1,257.55,56.1z M257.55,464.1    c-99.45,0-178.5-79.049-178.5-178.5c0-99.45,79.05-178.5,178.5-178.5s178.5,79.05,178.5,178.5    C436.05,385.051,357,464.1,257.55,464.1z"
      />
    </Fragment>
  );
}

export default Clock;
