import React from 'react';
import BaseSVG from '../BaseSVG';

function Ellipsis(props) {
  return (
    <BaseSVG
      {...props}
      name="Ellipsis"
      viewBox="0 0 512 512"
      // eslint-disable-next-line max-len
      d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
    />
  );
}

export default Ellipsis;
