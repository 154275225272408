import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { IconButton } from '../Buttons';
import { IconSVG } from '../SVG';

const sizes = {
  desktop: 950,
  mobile: 375,
  tablet: 650,
};

function ScreenSizeSelector({ onChange }) {
  return (
    <>
      <Row className="mb-1">
        <Col className="w-100">
          <IconButton
            action={() => onChange(sizes.mobile)}
            className="d-flex flex-column w-100"
            label={<span className="m-auto">Mobile</span>}
            SVGIcon={<IconSVG className="mx-auto" name="Mobile" width="1.5rem" />}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="w-100">
          <IconButton
            action={() => onChange(sizes.tablet)}
            className="d-flex flex-column w-100"
            label={<span className="m-auto">Tablet</span>}
            SVGIcon={<IconSVG className="mx-auto" name="Tablet" width="1.3rem" />}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="w-100">
          <IconButton
            action={() => onChange(sizes.desktop)}
            className="d-flex flex-column w-100"
            label={<span className="m-auto">Desktop</span>}
            SVGIcon={<IconSVG className="mx-auto" name="Desktop" width="1.3rem" />}
          />
        </Col>
      </Row>
    </>
  );
}

ScreenSizeSelector.propTypes = {
  onChange: PropTypes.func,
};

ScreenSizeSelector.defaultProps = {
  onChange: () => {},
};

export default ScreenSizeSelector;
