import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cx from 'classnames';
import IconSVG from '../SVG/IconSVG';

export default function ButtonComp({
  className,
  onClick,
  onFocus,
  children,
  size,
  type,
  id,
  disabled,
  isLoading,
  loadingIconColor,
  iconName,
  iconOnly,
  innerRef,
}) {
  return (
    <Button
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      size={size}
      id={id}
      type={type}
      disabled={disabled}
      innerRef={innerRef}
      color="default"
    >
      {isLoading ? (
        <IconSVG name="Talent Funnel" className={`pulse btn-loading-icon ${loadingIconColor}`} />
      ) : (
        iconName && (
          <span>
            <i className={cx('fa', { 'fa-btn': !iconOnly }, iconName)} />
          </span>
        )
      )}
      {iconName ? <span className={`button-text${iconOnly ? ' d-none' : ''}`}>{children}</span> : children}
    </Button>
  );
}

ButtonComp.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingIconColor: PropTypes.string,
  iconName: PropTypes.string,
  iconOnly: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
};

ButtonComp.defaultProps = {
  className: null,
  onClick: () => {},
  onFocus: () => {},
  size: null,
  type: 'button',
  id: null,
  disabled: false,
  isLoading: false,
  loadingIconColor: null,
  iconName: null,
  iconOnly: false,
  innerRef: null,
};
