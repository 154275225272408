/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export default {
  en: {
    CANDIDATE_REFEREES_RECEIVED_PREFIX: 'Received referee details from',
    APPLICATIONS_FOR_VACANCY_PREFIX: 'candidates have applied for',
    CANDIDATE_ONBOARDING_RECEIVED: 'has completed their onboarding information',
    SCHEDULING_BOOKED_IN: 'candidate has booked in for event',
    CANCELLED_SLOT_SUFFIX: 'has cancelled their interview slot booking',
    NO_AVAILABLE_SLOTS_REPORTED_SUFFIX: 'has reported that there are no available slots to book in to',
    CANDIDATE_REFERENCE_RECEIVED_TOTAL_PREFIX: 'Received all references for',
    CANDIDATE_REFERENCE_RECEIVED_PARTIAL_PREFIX: 'Received a reference for',
    CANDIDATE_REFERENCE_RECEIVED_PARTIAL_SUFFIX: 'received',
    APPLICATION_EXPORT_PREFIX: 'Your candidate application export of',
    APPLICATION_EXPORT_REQ_DATE: 'candidates requested on',
    APPLICATION_EXPORT_READY_TO_DOWNLOAD_SUFFIX: 'is ready for download',
    APPLICATION_EXPORT_FAILED_SUFFIX: 'failed to be created',
    APPLICATION_EXPORT_FULL_ERROR: 'Your request to export candidate applications has failed due to an error on our system',
    CANDIDATE_REFERENCE_RECEIVED_PARTIAL_OF: 'of',
    REFERENCE_RECEIVED_UPDATED_BY_PREFIX: 'Reference request completed by',
    REFERENCE_RECEIVED_UPDATED_BY_SUFFIX: 'updated by',
    noNotificationsLabel: 'You currently don\'t have any notifications to show',
    FOURTH_SUBMISSION_SUCCESS: 'has been successfully submitted to Fourth',
    FOURTH_SUBMISSION_ERROR: 'has not been submitted to Fourth',
    CANDIDATE_DOCUMENT_RECEIVED_TOTAL_PREFIX: 'Documents received for ',
    CANDIDATE_DOCUMENT_RECEIVED_DOCUMENT_NAME_PREFIX: 'Document received',
    CANDIDATE_DOCUMENT_RECEIVED_PARTIAL_PREFIX: 'for',
    POLARIS_SETUP_ERROR: 'Polaris has not been properly setup. Some accounts aren\'t accessible',
    RTW_CHECK_RECEIVED_FAILED: 'Right to check work rejected for ',
    RTW_CHECK_RECEIVED_SUCCESS: 'Right to check work approved for ',
    seeViewedLabel: 'See Viewed',
    seeNewLabel: 'See New',
    deleteAllLabel: 'Delete All (Double Click)',
    deleteLabel: 'Delete (Double Click)',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    markAllAsRead: 'Mark all as read',
    markAllAsUnread: 'Mark all as unread',
    EXTERNAL_PROVIDER_SUBMISSION_PREFIX: 'Submission to',
    EXTERNAL_PROVIDER_SUBMISSION_FOR: 'for',
    EXTERNAL_PROVIDER_SUBMISSION_FAILURE_SUFFIX: 'failed',
  },
  es: {
    CANDIDATE_REFEREES_RECEIVED_PREFIX: 'Recibió los detalles del árbitro de',
  },
};
