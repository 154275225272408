import React from 'react';
import PropTypes from 'prop-types';
import Import from './Import';

function SiteProxy({ children }) {
  return (
    <Import
      desktop={() => import('../containers/DefaultLayout/DefaultLayout')}
      touch={() => import('../touch/Layout/TouchLayout')}
    >
      {(Layout) => <Layout>{children}</Layout>}
    </Import>
  );
}

SiteProxy.propTypes = {
  children: PropTypes.element,
};

SiteProxy.defaultProps = {
  children: null,
};

export default SiteProxy;
