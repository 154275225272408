import { SET_STYLES } from '../constants/actionTypes';
import { gradientCreator } from '../utils/style-utils';

// eslint-disable-next-line import/prefer-default-export
export function setStyles(styleObj) {
  const { primaryColour, secondaryColour } = styleObj;

  return {
    type: SET_STYLES,
    payload: { ...styleObj, ...gradientCreator(primaryColour, secondaryColour) },
  };
}
