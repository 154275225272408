import { SET_CATEGORIES, SET_IS_LOADING } from '../constants/actionTypes';

export function setCategories(categories) {
  return {
    payload: categories,
    type: SET_CATEGORIES,
  };
}

export function setIsLoading() {
  return {
    type: SET_IS_LOADING,
  };
}
