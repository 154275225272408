export default {
  en: {
    fullAttachmentsTitle: 'Attachments',
    notesError: 'Sorry an error occurred when attempting to load the users attachments. Please try again later.',
    allowedFiles: 'You can upload jpg, png, pdf, doc, txt and xls files, up to 20MB each',
    dragAndDrop: 'Drag and drop your files here',
    or: 'or',
    of: 'of',
    releaseToDrop: 'Drop files here',
    browseFiles: 'Browse Files',
    uploadingFile: 'Uploading file',
    fileUploadSuccess: 'All attachments uploaded successfully',
    fileUploadError: 'Some or all of your files could not be uploaded',
    deleteFailed: 'could not be deleted',
    deleteSuccess: 'was successfully deleted',
  },
  es: {

  },
};
