/* eslint-disable no-nested-ternary */
import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { useMounted } from '../../../Base/hooks';
import { CreateButton } from '../../../Base/Buttons';
import { submitToSmart } from '../../../../api/Integrations/SmartAPI';

function SmartIntegration({ candidate, candidateProfileSummary }) {
  const isMounted = useMounted();

  const [isAbleToSendToSmart, setIsAbleToSendToSmart] = useState(true);
  const [smartStatus, setSmartStatus] = useState('NOT_SUBMITTED');

  useEffect(() => {
    // setIsAbleToSendToSmart(candidateProfileSummary?.onboarding?.status === 'RECEIVED' && candidateProfileSummary?.references?.status === 'RECEIVED');
  }, []);

  async function handleSubmit() {
    const result = await retryableAPICall(() => submitToSmart(candidate.id));

    if (typeof result === 'string') {
      toast.error('Could not submit to SMART'); // @todo we need to return relevant errors
    } else {
      setSmartStatus('SUBMITTED');
      toast.success('Candidate successfully submitted to smart');
    }
  }

  return (
    <>
      <hr />
      <Row>
        <Col>
          <Tippy
            content="Both onboarding and reference information must have been received before sending to Smart is enabled"
            disabled={isAbleToSendToSmart}
          >
            <span>
              <CreateButton action={handleSubmit} disabled={!isAbleToSendToSmart} label="Send to Smart" />
            </span>
          </Tippy>
        </Col>
      </Row>
    </>
  );
}

SmartIntegration.propTypes = {
  candidate: PropTypes.shape(),
  candidateProfileSummary: PropTypes.shape(),
};

SmartIntegration.defaultProps = {
  candidate: null,
  candidateProfileSummary: null,
};

export default connect(null, null)(SmartIntegration);
