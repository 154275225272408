import { useState, useEffect, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { retryableAPICall } from '../../../api/common-api-utils';
import { getTenantWorkflow } from '../../../api/WorkflowAPI';

export const WorkflowContext = createContext(null);

export function WorkflowProvider({ children }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await retryableAPICall(() => getTenantWorkflow());
        setData(response);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const value = useMemo(() => ({ data, isLoading }), [data, isLoading]);

  return <WorkflowContext.Provider value={value}>{children}</WorkflowContext.Provider>;
}

WorkflowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
