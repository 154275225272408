import browserStore from 'store2';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { setTalentMatchFavourite } from '../../../../api/JobSeekerAPI/JobSeekerAPI';
import { setLocalObject, getLocalObject } from '../../../../js/utils/storeObject';
import { localStorageNames } from '../../../../js/constants/storageNames';
import { isJSON } from '../../../../js/utils/general-utils';

const { DATA_TABLE } = localStorageNames;

export const TABLE_PAGE_BOUNDARIES = [5, 10, 20, 25, 50, 100, 250, 500, 1000, 5000];

export function getApplicantDetails(applicant) {
  const { userId, id, applicantProfile, funnelId, status } = applicant;
  let { formattedName } = applicant;
  let email;
  let mobile;

  if (applicantProfile) {
    const {
      personalDetails: { givenName, familyName },
      communication: { emailAddress, phone },
    } = applicantProfile;

    formattedName = `${givenName} ${familyName}`;
    email = emailAddress;
    mobile = phone?.mobilePhone;
  }

  return {
    applicantId: id || userId,
    applicantEmail: email,
    applicantPhone: mobile,
    applicantName: formattedName,
    applicantStatus: status,
    funnelId,
  };
}

export function getSelectedRows(data, idsArr, onSelectFn) {
  return data.reduce((acc, item) => {
    const { userId, id } = item;
    const selectedId = id || userId;

    if (idsArr.includes(selectedId)) {
      const selected = onSelectFn ? onSelectFn(item) : selectedId;
      acc.push(selected);
    }

    return acc;
  }, []);
}

export function allIds(data) {
  return data.map(({ userId, id }) => id || userId);
}

export function selectedIds(selectedIdArr, selectedId, isSelected, isRadio) {
  if (isRadio) return [selectedId];

  let cloneArr = [...selectedIdArr];

  if (!isSelected) {
    cloneArr = cloneArr.filter((id) => selectedId !== id);
  } else {
    cloneArr.push(selectedId);
  }

  return [...new Set(cloneArr)];
}

export async function updateShortlist(vacancyId, jobSeekerIds = [], isChecked, onError) {
  if (!jobSeekerIds.length) return;

  const result = await retryableAPICall(() => setTalentMatchFavourite(vacancyId, jobSeekerIds, isChecked));

  if (typeof result === 'string') onError();
}

export function getCurrentPage(id, vacancies) {
  return vacancies[id]?.currentApplicantPage ? vacancies[id].currentApplicantPage : 0;
}

export function insertColumns(insertCols, masterCols = []) {
  return insertCols.reduce(
    (acc, col) => {
      if (col.colPosIndex) {
        const delCount = col.replaceCol ? 1 : 0;
        acc.splice(col.position, delCount, col);
      } else {
        acc.push(col);
      }
      return acc;
    },
    [...masterCols],
  );
}

export function roundToPageOption(count) {
  const largerNums = TABLE_PAGE_BOUNDARIES.filter((boundary) => boundary > count);
  return Math.min(...largerNums);
}

export function sortColumns(a, b) {
  if (a.orderIndex < b.orderIndex) return -1;
  if (a.orderIndex < b.orderIndex) return 1;
  return 0;
}

export function removeColumnConfig(ns) {
  if (ns && ns.length) browserStore.remove(ns);
}

export function setColumnConfig(ns, columns = {}) {
  if (ns && ns.length) browserStore(ns, JSON.stringify(columns));
}

export function getColumnConfig(ns) {
  if (!ns) return {};
  const storedVal = browserStore(ns);
  if (isJSON(storedVal)) return JSON.parse(browserStore(ns)) || {};
  return {};
}

export function columnPresets(columns = [], ns) {
  const colConf = getColumnConfig(ns);

  return columns
    .map((column) => {
      const col = { ...column };
      const { sortable, visible, ...rest } = column;
      const { accessor, id } = rest;

      if (sortable && !accessor && id) col.accessor = id;

      let isVisible = visible === undefined || false;
      let orderIndex;
      if (colConf[id]) {
        isVisible = colConf[id].visible;
        orderIndex = colConf[id].orderIndex;
      }

      return {
        resizable: false,
        filterable: false,
        disableSortBy: !sortable,
        optional: false,
        visible: isVisible,
        ...(orderIndex !== undefined ? { orderIndex } : {}),
        ...rest,
      };
    })
    .sort(sortColumns);
}

export function storeTablePref(tableId, prop, value = '', keepExisting = false) {
  if (tableId && prop) setLocalObject(DATA_TABLE, tableId, prop, value, keepExisting);
}

export function getColWidth(cols, tableId, ogCols) {
  const colWidths = getLocalObject('dataTable');

  if (
    cols &&
    cols.length &&
    colWidths &&
    colWidths[tableId]?.colWidths &&
    Object.keys(colWidths[tableId]?.colWidths).length > 0
  ) {
    return cols.map((col) => {
      if (colWidths[tableId].colWidths[col.id]) {
        return {
          ...col,
          width: colWidths[tableId].colWidths[col.id],
        };
      }
      return col;
    });
  }
  return ogCols;
}
