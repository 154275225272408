import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

export default function PostalAddress({ data }) {
  if (!data || !Object.keys(data).length) return null;

  const {
    city,
    county,
    postcode,
    country,
  } = data;

  return (
    <Row className="large-content">
      <Col sm="3">
        <p className="detail-title">Address</p>
      </Col>
      <Col>
        <div className="detail-value">
          {city && <p>{city}</p>}
          {county && <p>{county}</p>}
          {postcode && <p>{postcode}</p>}
          {country && <p>{country}</p>}
        </div>
      </Col>
    </Row>
  );
}

PostalAddress.propTypes = {
  data: PropTypes.shape({
    city: PropTypes.string,
    county: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
  }),
};

PostalAddress.defaultProps = {
  data: {},
};
