import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Required from '../../../../Base/Forms/Custom/Required';
import { SelectInput, optionPropType } from '../../../../Base/Forms/Custom/CommonComponents';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { getDescription } from '../../../../../api/SchedulingAPI/DescriptionsAPI';
import { getTemplatesByType } from '../../../../../api/TemplateAPI';
import { getCreateVacancyTemplate } from '../../../../../api/VacancyAPI';
import { useMounted } from '../../../../Base/hooks';

async function getOptions(apiName) {
  if (apiName === 'descriptions') return getDescription();

  // eslint-disable-next-line max-len
  if (
    /EVENT_INVITE|EVENT_CONFIRMATION|INVITE_TO_INTERVIEW|EVENT_INVITE_REMINDER|EVENT_BOOKING_REMINDER|EVENT_PHONE_CONFIRMATION|EVENT_VIDEO_CONFIRMATION|EVENT_FACE_TO_FACE_CONFIRMATION|EVENT_UPDATED|EVENT_CANCELLED|EVENT_REMINDER_24/.test(
      apiName,
    )
  ) {
    return getTemplatesByType(apiName);
  }

  if (/VACANCY_TEMPLATE/.test(apiName)) {
    return getCreateVacancyTemplate('id,name');
  }

  return [];
}

function AsyncSelectInput({ id, label, apiName, value, required, hideOnEmpty, onChange, updatedOptions, children }) {
  const isMounted = useMounted();
  const [selectOpts, setSelectOpts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let opts = await retryableAPICall(() => getOptions(apiName));

      if (isMounted()) {
        if (Array.isArray(opts)) {
          opts = opts.map(({ id: optVal, name: optLabel }) => ({ value: optVal, label: optLabel }));
          setSelectOpts(opts);
        } else {
          toast.error('Error loading options, please try again.');
        }
      }
    };
    if (apiName) fetchData();
  }, [apiName, isMounted]);

  if (hideOnEmpty && selectOpts.length === 0) return null;

  return (
    <FormGroup className="disabled-margin-bottom pb-2">
      <Label htmlFor={id}>
        {label}
        {required && <Required />}
      </Label>
      <SelectInput value={value} onChange={onChange} options={selectOpts} updatedOptions={updatedOptions} />
      {children}
    </FormGroup>
  );
}

AsyncSelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  apiName: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  hideOnEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  updatedOptions: optionPropType,
};

AsyncSelectInput.defaultProps = {
  id: '',
  value: '',
  required: false,
  hideOnEmpty: false,
  onChange: () => {},
  children: null,
  updatedOptions: [],
};

export default AsyncSelectInput;
