/* eslint-disable max-len */
export default {
  en: {
    applyButton: 'Apply Tags',
    createButton: 'Create new Tag',
    promptTitle: 'New Tag Label',
    tagCreatedSuccess: 'Tag Created Successfully',
    manageLinkLabel: 'Manage Tags',
    deleteConfirmTitle: 'Are you sure you want to delete it',
    deleteConfirmContent: 'If you delete this tag, the tag will be removed from the tag manager but all the applicants that are tagged with it will keep it.',
    deleteTagSuccess: 'Tags deleted successfully',
    deleteTagError: 'Error deleting tags',
    updateTagSuccess: 'Tag updated successfully',
    updateTagError: 'Error updating tag, please try again',
    deleteSelectedBtnLabel: 'Delete selected Tags',
    newTagLabel: 'New Tag',
    createTagBtnLabel: 'Create New Tag',
    createTagSuccess: 'Tag created successfully',
    createTagError: 'Error creating tag, please try again',
    createTagErrorExists: 'Tag alredy exists, please try again',
    applyTagError: 'Error applying tags',
    noMatchesLabel: 'Press enter to create tag',
    addTagLabel: '+ Add Tag',
    tagFetchErrorLabel: 'Unable to get tags at this time',
  },
  es: {
    noMatchesLabel: 'Presione enter para crear la etiqueta',
    addTagLabel: 'Agregar etiquetas',
    tagFetchErrorLabel: 'No se pueden obtener etiquetas en este momento',
  },
};
