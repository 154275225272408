import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { Input } from 'reactstrap';
import { useOutsideClick } from '../hooks';

export function validateColors(inpId, colorValue) {
  return { [inpId]: !/^#[0-9A-F]{6}$/i.test(colorValue) ? 'Invalid colour' : '' };
}

function Picker({ color, displayPicker, onClick, onChange, style }) {
  return (
    <>
      <div className="color-picker-swatch" onClick={onClick} onKeyUp={onClick} role="button" tabIndex="-1">
        <div style={{ backgroundColor: color }} />
      </div>
      {displayPicker && (
        <div className="color-picker" style={style}>
          <ChromePicker color={color} disableAlpha onChange={(colVal) => onChange(colVal.hex)} />
        </div>
      )}
    </>
  );
}

Picker.propTypes = {
  color: PropTypes.string,
  displayPicker: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

Picker.defaultProps = {
  color: '#7c89e3',
  displayPicker: false,
  onClick: () => {},
  onChange: () => {},
};

function ColorPicker({ color, onChange, id, className, swatchClassName, isDisabled }) {
  const wrapperRef = useRef();
  const [displayPickerOpts, setDisplayPickerOpts] = useState({ display: false, x: null, y: null });

  useOutsideClick(wrapperRef, () => {
    setDisplayPickerOpts({ display: false, x: null, y: null });
  });

  function handlePickerToggle(e) {
    if (!isDisabled) {
      setDisplayPickerOpts({ display: !displayPickerOpts.display, x: undefined, y: undefined });
    }
  }

  return (
    <div ref={wrapperRef} className={`position-relative ${className} ${id ? 'input-group align-items-center' : ''}`}>
      {id && (
        <Input
          id={id}
          isDisabled={isDisabled}
          maxLength="7"
          onChange={(e) => onChange(e.target.value)}
          onFocus={handlePickerToggle}
          value={color.toUpperCase()}
        />
      )}
      {id ? (
        <div className={`color-picker-addon ${swatchClassName}`}>
          <Picker
            color={color}
            displayPicker={displayPickerOpts.display}
            onChange={onChange}
            onClick={(e) => {
              if (!isDisabled) {
                handlePickerToggle(e);
              }
            }}
            style={{ left: displayPickerOpts.x - 260, top: displayPickerOpts.y - 50 }}
          />
        </div>
      ) : (
        <Picker
          color={color}
          displayPicker={displayPickerOpts.display}
          onChange={onChange}
          onClick={(e) => {
            if (!isDisabled) {
              handlePickerToggle(e);
            }
          }}
          style={{ left: displayPickerOpts.x - 1260, top: displayPickerOpts.y - 450 }}
        />
      )}
    </div>
  );
}

ColorPicker.propTypes = {
  className: PropTypes.string,
  swatchClassName: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ColorPicker.defaultProps = {
  className: '',
  swatchClassName: '',
  color: '#7c89e3',
  onChange: () => {},
  id: null,
  isDisabled: false,
};

export default ColorPicker;
