import React from 'react';
import PropTypes from 'prop-types';

function ErrorMessage({
  className,
  styles,
  children,
  isInvalid,
}) {
  return (
    <div
      className={`invalid-feedback ${className || ''}`}
      style={{ ...styles, display: isInvalid ? 'block' : 'none' }}
    >
      {children}
    </div>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isInvalid: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  className: null,
  styles: {},
  children: null,
  isInvalid: false,
};

export default ErrorMessage;
