import React, { Component } from 'react';

import Select from 'react-select';
// import PropTypes from 'prop-types';
// import TagContainer from '../../../../Applications/Tags/tag-container';

class SingleDDSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOpt: undefined,
      data: this.props.schema.data ? this.props.schema.data : [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(val) {
    this.setState({ selectedOpt: val });
    let value;
    if (val) {
      if (Array.isArray(val)) {
        value = val.map((entry) => entry.value);
      } else if (val) {
        value = val.value;
      }
    }
    setTimeout(() => this.props.onChange(value), 50); // Have to do this for some odd reason
  }

  componentDidMount() {
    let selectedOpt;
    if (this.props.schema.isMultiple && this.props.formData) {
      selectedOpt = this.state.data.filter((item) => this.props.formData.find((data) => item.value === data));
    } else {
      let formData;
      if (!this.props.schema.isMultiple && Array.isArray(this.props.formData) && this.props.formData.length === 1) {
        formData = this.props.formData[0];
        setTimeout(() => this.props.onChange(formData), 50);
      } else {
        formData = this.props.formData;
      }
      selectedOpt =
        formData && Object.keys(formData.toString()).length > 0
          ? this.state.data.find((item) => item.value === formData)
          : undefined;
      if (!selectedOpt && !formData && this.state.data.length === 1) this.handleChange(this.state.data[0]);
      selectedOpt = [selectedOpt];
    }

    this.setState({ selectedOpt });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.schema && (
          <label className="control-label" htmlFor="root_referenceCode">
            {this.props.schema.title}
            {this.props.required && <span className="required">*</span>}
          </label>
        )}
        <span id={this.props.name}>
          <Select
            value={this.state.selectedOpt}
            onChange={this.handleChange}
            options={this.state.data}
            inputId={this.props.name}
            isMulti={this.props.schema.isMultiple ? this.props.schema.isMultiple : false}
            classNamePrefix="react-select"
            defaultValue={this.state.selectedOpt}
            isDisabled={this.props.readonly}
          />
        </span>
      </React.Fragment>
    );
  }
}

export default SingleDDSelect;
