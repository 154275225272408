import React from 'react';
import BaseSVG from '../BaseSVG';

function PlusCircle(props) {
  return (
    <BaseSVG
      {...props}
      name="Plus Circle"
      viewBox="0 0 32 32"
      // eslint-disable-next-line max-len
      d="M16,30 C8.279875,30 2,23.71925 2,16 C2,8.28075 8.279875,2 16,2 C23.71925,2 30,8.28075 30,16 C30,23.71925 23.71925,30 16,30 Z M16,4.760625 C9.802375,4.760625 4.75975,9.802375 4.75975,16 C4.75975,22.197625 9.802375,27.239375 16,27.239375 C22.19675,27.239375 27.239375,22.197625 27.239375,16 C27.239375,9.802375 22.19675,4.760625 16,4.760625 Z M21.142375,19.051125 C21.719875,19.628625 21.719875,20.56575 21.142375,21.14325 C20.564875,21.72075 19.628625,21.72075 19.052,21.14325 L15.999125,18.090375 L12.948,21.142375 C12.369625,21.72075 11.433375,21.721625 10.855875,21.14325 C10.278375,20.564875 10.278375,19.628625 10.855875,19.051125 L13.907875,16 L10.85675,12.948875 C10.280125,12.3705 10.280125,11.43425 10.85675,10.85675 C11.435125,10.27925 12.371375,10.27925 12.948,10.85675 L16,13.90875 L19.05025,10.85675 C19.628625,10.27925 20.564875,10.278375 21.142375,10.85675 C21.719875,11.435125 21.719875,12.371375 21.142375,12.948875 L18.090375,16 L21.142375,19.051125 Z"
    />
  );
}

export default PlusCircle;
