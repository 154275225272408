/* eslint-disable max-len */
import React from 'react';
import BaseSVG from '../BaseSVG';

function Briefcase(props) {
  const paths = [
    {
      strokeLinejoin: 'round',
      stroke: '#5A5A5A',
      strokeWidth: '2',
      d: 'M3 11C3 9.11438 3 8.17157 3.58579 7.58579C4.17157 7 5.11438 7 7 7H8H16H17C18.8856 7 19.8284 7 20.4142 7.58579C21 8.17157 21 9.11438 21 11V17C21 18.8856 21 19.8284 20.4142 20.4142C19.8284 21 18.8856 21 17 21H16H8H7C5.11438 21 4.17157 21 3.58579 20.4142C3 19.8284 3 18.8856 3 17V11Z',
    },
    {
      d: 'M8 21V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V21',
      strokeLinejoin: 'round',
      stroke: '#5A5A5A',
      strokeWidth: '2',
    },
  ];
  return <BaseSVG {...props} fill="none" viewBox="0 0 24 24" name="Briefcase" strokeLinejoin="round" paths={paths} />;
}

export default Briefcase;
