import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import DatePickerInput from './DatePickerInput';

export default function DatePicker({ schema, required, onChange, formData, uiSchema }) {
  const [date, setDate] = useState();

  const { title, defaultMonthsAhead } = schema;

  useEffect(() => {
    if (formData) {
      if (typeof formData === 'number' || typeof formData === 'string') {
        setDate(moment(formData, 'YYYY-MM-DD'));
      } else if (typeof formData === 'object' && 'date' in formData) {
        setDate(moment(formData.date, 'YYYY-MM-DD'));
      }
    } else if (defaultMonthsAhead !== undefined) {
      setDate(moment(new Date(), 'YYYY-MM-DD').add(defaultMonthsAhead, 'M'));
    }
  }, [defaultMonthsAhead, formData]);

  function handleDateChange(newDate) {
    setDate(newDate);
    onChange(newDate ? newDate.format('YYYY-MM-DD') : undefined);
  }

  return (
    <Fragment>
      {title && (
        <label className="control-label" htmlFor="root_referenceCode">
          {title}
          {required && <span className="required">*</span>}
        </label>
      )}
      <DatePickerInput
        id={uuidv4()}
        value={date}
        onChange={handleDateChange}
        readOnly
        showClearDate
        placeholder="Date"
        disabled={uiSchema && uiSchema['ui:readonly']}
      />
    </Fragment>
  );
}

DatePicker.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
    defaultMonthsAhead: PropTypes.number,
  }),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  formData: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      date: PropTypes.string,
    }),
  ]),
};

DatePicker.defaultProps = {
  schema: {
    title: null,
  },
  required: false,
  onChange: () => {},
  formData: null,
};
