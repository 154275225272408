import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { DEFAULT_RULE } from '../utils';

function RulesInputs({
  rule,
  onChange,
  items,
  maxSize,
  disabled,
}) {
  function handleChange(type, value) {
    const updated = { ...rule, [type]: value };
    onChange(updated);
  }

  return (
    <div className="rules-group">
      <select
        disabled={disabled}
        className="select"
        value={rule.rule}
        onChange={(e) => handleChange('rule', e.target.value)}
      >
        {items.map(({ value: optVal, label }, i) => (
          <option key={optVal} value={optVal} disabled={i === 0}>{label}</option>
        ))}
      </select>
      <Input
        disabled={disabled}
        className="small"
        type="number"
        min="1"
        max="100"
        value={rule.value || 1}
        onChange={(e) => {
          const { target: { value } } = e;
          let val = Number(value);
          if (val > maxSize) val = maxSize;
          handleChange('value', val);
        }}
      />
    </div>
  );
}

RulesInputs.propTypes = {
  rule: PropTypes.shape({
    rule: PropTypes.string,
    value: PropTypes.number,
  }),
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  maxSize: PropTypes.number,
  disabled: PropTypes.bool,
};

RulesInputs.defaultProps = {
  rule: DEFAULT_RULE,
  onChange: () => { },
  items: [],
  maxSize: 100,
  disabled: false,
};

export default RulesInputs;
