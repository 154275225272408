import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e0e0e0;
`;

const TitleIcon = styled.div`
  color: #4a90e2;
  margin-right: 0.75rem;
`;

const Title = styled.h4`
  color: #333;
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
`;

const Subtitle = styled.span`
  color: #666;
  font-size: 0.875rem;
  margin-left: auto;
`;

const EnhancedCardTitle = ({ title, subtitle, icon: Icon }) => (
  <TitleWrapper>
    {/*<TitleIcon>*/}
    {/*  <Icon size={24} />*/}
    {/*</TitleIcon>*/}
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </TitleWrapper>
);

EnhancedCardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType,
};

EnhancedCardTitle.defaultProps = {
  subtitle: '',
};

export default EnhancedCardTitle;
