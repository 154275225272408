import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import ResolveLogo from '../SVG/ResolveLogo';
import LoadingIcon from '../SVG/Icons/LoadingIcon';

function LoadingScreen({
  className,
  isPage,
  isModal,
  isEmbeded,
  styles,
  companyLogo,
  notFixed,
}) {
  return (
    <div
      className={cx(
        'loading-container',
        { 'loading-page': isPage },
        { 'loading-modal': isModal },
        { 'loading-embedded': isEmbeded },
        { 'loading-not-fixed loading-embedded': notFixed },
        className,
      )}
      style={{ ...styles }}
    >
      <LoadingIcon size="10em" />
      {/*<ResolveLogo imgSrc={companyLogo} imgWidth="60px" svgWidth="4em" className="pulse" />*/}
    </div>
  );
}

LoadingScreen.propTypes = {
  className: PropTypes.string,
  isPage: PropTypes.bool,
  isModal: PropTypes.bool,
  isEmbeded: PropTypes.bool,
  styles: PropTypes.shape(),
  companyLogo: PropTypes.string,
  notFixed: PropTypes.bool,
};

LoadingScreen.defaultProps = {
  className: null,
  isPage: false,
  isModal: false,
  isEmbeded: false,
  styles: {},
  companyLogo: null,
  notFixed: false,
};

function mapStateToProps(state) {
  const { companyData: { companyDetails: { companyLogo } }, styles } = state;
  const { logoUrl } = styles;
  return { companyLogo: logoUrl || companyLogo };
}

export default connect(mapStateToProps)(LoadingScreen);
