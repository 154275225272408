import {
  RESET_PUBLISH_STATE,
  ADD_PUBLISHING_DATA,
  GET_PUBLISHED_DATA,
} from '../constants/actionTypes';

const initialState = {
  publishedData: [],
};

const publishingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { publishedData } = state;

  if (type === ADD_PUBLISHING_DATA) {
    const tmpPublishedData = [...publishedData];
    // Check in the array to see in JB with this jb already exists
    const foundVac = tmpPublishedData.find((vacancy) => vacancy.vacancyId === payload.vacancyId);

    if (!foundVac) {
      tmpPublishedData.push({
        vacancyId: payload.vacancyId,
        publishData: payload.publishData,
      });
    } else {
      const alreadyExists = foundVac.publishData.find((data) => data.id === payload.publishData.id);
      if (!alreadyExists) foundVac.publishData.push(payload.publishData);
    }

    return {
      ...state,
      jbAds: tmpPublishedData,
    };
  }

  if (type === GET_PUBLISHED_DATA) return initialState.publishedData.find((vacancy) => vacancy.id === payload);

  if (type === RESET_PUBLISH_STATE) {
    return {
      ...state,
      publishedData: [],
    };
  }

  return state;
};

export default publishingReducer;
