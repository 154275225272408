import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import NotificationItem from './NotficationItem';
import { IconSVG } from '../Base/SVG';
import { deleteNotifications, markNotificationAsViewed } from '../../api/EventAPI/EventAPI';
import { deleteNotificationsFromView, toggleNotificationReadState } from '../../js/actions/eventNotificationActions';
import { useMounted, useLanguage } from '../Base/hooks';
import { eventNotificationMenu } from '../../js/language/pages';

function NotificationHeader({ notifications, error, toggleReadState, deleteNotifs }) {
  const isMounted = useMounted();
  const { langPack: languagePack } = useLanguage(eventNotificationMenu);
  const [isOpen, setIsOpen] = useState(false);
  const [notificationElements, setNotificationElements] = useState([]);
  // const [languagePack, setLanguagePack] = useState({});
  const [numNewNotifs, setNumNewNotifs] = useState(0);
  const [showViewed, setShowViewed] = useState(false);

  function replaceKeys(content) {
    if (content) {
      return content.replace(
        /\{(\w*)}/g,
        // eslint-disable-next-line no-prototype-builtins
        (m, key) => (languagePack.hasOwnProperty(key) ? languagePack[key] : key),
      );
    }
    return null;
  }

  useEffect(() => {
    if (!error && notifications && notifications.length && isMounted()) {
      const filteredNotifications = notifications.filter((notif) => notif.isViewed === showViewed);
      const mappedNotifs = filteredNotifications.map((notif) => ({
        ...notif,
        shortContent: replaceKeys(notif.shortContent),
        content: replaceKeys(notif.content),
      }));

      setNotificationElements(mappedNotifs);
      setNumNewNotifs(notifications.filter((notif) => !notif.isViewed).length);
    } else if (!error && notifications && !notifications.length && isMounted()) {
      setNotificationElements([]);
      setNumNewNotifs(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, notifications, showViewed, isMounted]);

  function doDelete() {
    notifications.forEach((notif) => {
      deleteNotifs(notif.ids);
    });

    deleteNotifications(notifications.map((notif) => notif.ids).flat());
  }

  function toggleViewed() {
    notifications.forEach((notif) => {
      toggleReadState(notif.ids, numNewNotifs !== 0);
    });

    markNotificationAsViewed(notifications.map((notif) => notif.ids).flat(), numNewNotifs !== 0);
  }

  return (
    <Fragment>
      {numNewNotifs !== 0 && (
        <div className={`new-count ${numNewNotifs > 99 ? 'new-count-max' : ''}`}>
          <div>{numNewNotifs > 99 ? '99+' : numNewNotifs}</div>
        </div>
      )}
      <a href="https://talentfunl.zendesk.com/hc/en-gb/" target="_blank" rel="noopener noreferrer">
        <IconSVG name="Help" height="24px" width="24px" stroke="#5A5A5A" fill="white" />
      </a>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle className="notification-dd" caret>
          <IconSVG
            name="Bell Solid"
            height="24px"
            width="24px"
            stroke="#5A5A5A"
            fill="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </DropdownToggle>
        <DropdownMenu
          className="notification-menu-body"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [70, 0],
              },
            },
          ]}
        >
          <DropdownItem onClick={() => false} header className="notification-dd-item">
            <p style={{ display: 'inline-block' }}> Notifications</p>
            {notifications.length && (
              <span style={{ float: 'right', marginTop: '5px' }}>
                <Tippy content={numNewNotifs ? languagePack.markAllAsRead : languagePack.markAllAsUnread} theme="ats">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleViewed();
                    }}
                  >
                    <IconSVG
                      name={numNewNotifs ? 'Eye' : 'Eye Slash'}
                      styles={{ color: 'rgb(88 85 85)' }}
                      className="notif-action-icon"
                    />
                  </a>
                </Tippy>
                {showViewed && (
                  <Tippy content={languagePack.deleteAllLabel} theme="ats">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onDoubleClick={(e) => {
                        e.preventDefault();
                        doDelete();
                      }}
                    >
                      <IconSVG name="Bin" styles={{ color: '#b10404' }} className="notif-action-icon" />
                    </a>
                  </Tippy>
                )}
              </span>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              style={{ float: 'right', marginTop: '7px' }}
              onClick={(e) => {
                e.preventDefault();
                setShowViewed(!showViewed);
              }}
            >
              {showViewed ? languagePack.seeNewLabel : languagePack.seeViewedLabel}
            </a>
          </DropdownItem>
          {notificationElements.length ? (
            notificationElements.map((notif, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`notif-${i}`} className="dropdown-item no-active-bg">
                <NotificationItem notification={notif} languagePack={languagePack} isViewedMode={showViewed} />
              </div>
            ))
          ) : (
            <Row>
              <Col>
                <div className="no-notification-container">
                  <IconSVG name="Bell Hollow" />
                  <p>{languagePack.noNotificationsLabel}</p>
                </div>
              </Col>
            </Row>
          )}
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
}

NotificationHeader.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.string,
  toggleReadState: PropTypes.func,
  deleteNotifs: PropTypes.func,
};

NotificationHeader.defaultProps = {
  notifications: [],
  error: null,
  toggleReadState: () => {},
  deleteNotifs: () => {},
};

function mapStateToProps(state) {
  const {
    eventNotificationReducer: { notifications },
  } = state;

  return { notifications };
}

function mapDispatchToProps(dispatch) {
  return {
    toggleReadState: (notificationIds, read) => {
      dispatch(toggleNotificationReadState(notificationIds, read));
    },
    deleteNotifs: (notificationIds) => {
      dispatch(deleteNotificationsFromView(notificationIds));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHeader);
