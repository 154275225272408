import React from 'react';
import PropTypes from 'prop-types';

function Loader({
  wrapperClass, 
  className,
  justify,
  size,
  color,
  style,
}) {
  return (
    <div className={`d-flex align-items-center justify-content-${justify} ${wrapperClass}`} style={{ ...style }}>
      <div
        className={`spinner-border spinner-border-${size} text-${color} ${className || ''}`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

Loader.propTypes = {
  wrapperClass: PropTypes.string,
  className: PropTypes.string,
  justify: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.shape(),
};

Loader.defaultProps = {
  className: null,
  wrapperClass:'',
  justify: 'center',
  size: 'md',
  color: 'secondary',
  style: {},
};

export default Loader;
