import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const { api, onboarding } = config

export async function handlePayrollSubmitTo(platform, applicantId) {
  const endpointKey = `submitTo${platform}`;
  return axios.post(`${api.middlewareAPIURL}${onboarding[endpointKey]}/${applicantId}`, null, {
    headers: getCommonHeaders(),
  });
}
