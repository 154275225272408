import React from 'react';
import PropTypes from 'prop-types';
import ModalPopup from './ModalPopup';

function Alert({
  isOpen,
  title,
  onOkay,
  className,
  size,
  content,
  buttonLabel,
}) {
  return (
    <ModalPopup
      isOpen={isOpen}
      title={title}
      minHeight="0"
      size={size}
      className={`alert-box ${className || ''}`}
      onOkay={onOkay}
      hideCloseButton
      hideCancelButton
      okayLabel={buttonLabel}
    >
      {content}
    </ModalPopup>
  );
}

Alert.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onOkay: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  buttonLabel: PropTypes.string,
};

Alert.defaultProps = {
  isOpen: false,
  title: null,
  onOkay: () => { },
  className: null,
  size: 'md',
  content: null,
  buttonLabel: 'Okay',
};

export default Alert;
