export default {
  en: {
    formName: 'Application Form',
    applicationForm: {
      title: 'Personal Details',
      properties: {
        givenName: {
          title: 'First Name',
        },
        familyName: {
          title: 'Surname',
        },
        emailAddressReg: {
          title: 'Email Address',
        },
        address: {
          title: 'Address',
        },
        mobilePhone: {
          title: 'Phone Number',
        },
        cvUpload: {
          title: 'Upload your CV',
        },
        coverLetter: {
          title: 'Your cover letter',
        },
        termsAndCond: {
          title: 'Do you agree with the terms and conditions?',
        },
      },
    },
    employmentHistory: {
      title: 'Employment History',
      items: {
        properties: {
          jobTitle: {
            title: 'Job Title',
          },
          employer: {
            title: 'Employer',
          },
          employerAddress: {
            title: 'Employer Address',
          },
          dates: {
            title: 'Dates',
            properties: {
              currentRole: {
                title: 'I currently work here',
              },
              dateFrom: {
                title: 'Date From',
              },
              dateTo: {
                title: 'Date To',
              },
            },
          },
          mainDuties: {
            title: 'Main duties and responsibilities',
          },
          reasonForLeaving: {
            title: 'Reason for leaving',
          },
        },
      },
    },
    reference1: {
      title: 'Provide a referee',
      properties: {
        firstNameR1: {
          title: 'First Name',
        },
        surnameR1: {
          title: 'Surname',
        },
        companyR1: {
          title: 'Company',
        },
        positionR1: {
          title: 'Position',
        },
        relationR1: {
          title: 'Relation to applicant',
        },
        emailR1: {
          title: 'Email Address',
        },
        phoneR1: {
          title: 'Phone Number',
        },
      },
    },
    reference2: {
      title: 'Provide another referee',
      properties: {
        firstNameR2: {
          title: 'First Name',
        },
        surnameR2: {
          title: 'Surname',
        },
        companyR2: {
          title: 'Company',
        },
        positionR2: {
          title: 'Position',
        },
        relationR2: {
          title: 'Relation to applicant',
        },
        emailR2: {
          title: 'Email Address',
        },
        phoneR2: {
          title: 'Phone Number',
        },
      },
    },
  },
  de: {
    formName: 'Anmeldeformular',
    applicationForm: {
      title: 'Persönliche Daten',
      properties: {
        givenName: {
          title: 'Vorname',
        },
        familyName: {
          title: 'Nachname',
        },
        emailAddressReg: {
          title: 'E-Mail-Addresse',
        },
        address: {
          title: 'Addresse',
        },
        phoneNumber: {
          title: 'Telefonnummer',
        },
        cvUpload: {
          title: 'Laden Sie Ihren Lebenslauf hoch',
        },
        coverLetter: {
          title: 'Ihr Anschreiben',
        },
        termsAndCond: {
          title: 'Stimmen Sie den Allgemeinen Geschäftsbedingungen zu?',
        },
      },
    },
    employmentHistory: {
      title: 'Beschäftigungsgeschichte',
      items: {
        properties: {
          jobTitle: {
            title: 'Berufsbezeichnung',
          },
          employer: {
            title: 'Arbeitgeber',
          },
          employerAddress: {
            title: 'Arbeitgeber Adresse',
          },
          dates: {
            title: 'Termine',
            properties: {
              currentRole: {
                title: 'Derzeit arbeite ich hier',
              },
              dateFrom: {
                title: 'Stammen aus',
              },
              dateTo: {
                title: 'Datum bis',
              },
            },
          },
          mainDuties: {
            title: 'Hauptpflichten und Verantwortungen',
          },
          reasonForLeaving: {
            title: 'Grund für die Abreise',
          },
        },
      },
    },
    reference1: {
      title: 'Stellen Sie einen Schiedsrichter zur Verfügung',
      properties: {
        firstNameR1: {
          title: 'Vorname',
        },
        surnameR1: {
          title: 'Nachname',
        },
        companyR1: {
          title: 'Unternehmen',
        },
        positionR1: {
          title: 'Position',
        },
        relationR1: {
          title: 'Beziehung zum Antragsteller',
        },
        emailR1: {
          title: 'E-Mail-Addresse',
        },
        phoneR1: {
          title: 'Telefonnummer',
        },
      },
    },
    reference2: {
      title: 'Stellen Sie einen anderen Schiedsrichter zur Verfügung',
      properties: {
        firstNameR2: {
          title: 'Vorname',
        },
        surnameR2: {
          title: 'Nachname',
        },
        companyR2: {
          title: 'Unternehmen',
        },
        positionR2: {
          title: 'Position',
        },
        relationR2: {
          title: 'Beziehung zum Antragsteller',
        },
        emailR2: {
          title: 'E-Mail-Addresse',
        },
        phoneR2: {
          title: 'Telefonnummer',
        },
      },
    },
  },
};
