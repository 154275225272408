import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';

const InnerTag = styled.div``;

export default function BuildDetails(props) {
  const { title, largeContent, children, childTag, vertical } = props;
  let { titleColumnSize, touchTitleColumnSize } = props;

  if (!title && !children) return null;

  if (vertical) {
    touchTitleColumnSize = null;
    titleColumnSize = null;
  }

  return (
    <Row className={cx({ 'large-content': largeContent }, { 'is-vertical': vertical })}>
      <Col>
        <Row>
          {title && children && (
            <Col sm={titleColumnSize} xs={touchTitleColumnSize || titleColumnSize}>
              <InnerTag as="p" className="detail-title">
                {title}
              </InnerTag>
              {vertical && (
                <InnerTag as={childTag} className="detail-value">
                  {children}
                </InnerTag>
              )}
            </Col>
          )}
          {children && !vertical && (
            <Col>
              <InnerTag as={childTag} className="detail-value">
                {children}
              </InnerTag>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

BuildDetails.propTypes = {
  childTag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  largeContent: PropTypes.bool,
  title: PropTypes.string,
  titleColumnSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  touchTitleColumnSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vertical: PropTypes.bool,
};

BuildDetails.defaultProps = {
  childTag: 'p',
  children: null,
  largeContent: false,
  title: null,
  titleColumnSize: '3',
  touchTitleColumnSize: null,
  vertical: false,
};
