import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Spinner,
  Row,
  Label,
  FormGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import browserStore from 'store2';
import { connect } from 'react-redux';
import queryString from 'query-string';
import withRouter from '../../../hoc/withRouter';
import { setAuthError } from '../../../js/actions/userActions';
import { requestStatuses } from '../../../js/constants/requestStatuses';
import { cookieNames, localStorageNames } from '../../../js/constants/storageNames';
import { doLogin, doRenewSession, goToLastUrl, isAuthenticated } from '../../../js/auth/AuthUtils';
import { Checkbox } from '../../Base/Tables/components';
import { ResolveLogo } from '../../Base/SVG';
import { startPasswordlessLogin, getZendeskSSO } from '../../../api/AuthAPI/AuthAPI';
import IDPLogin from './IDPLogin';
import config from '../../../config/baseConfig';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        username: undefined,
        password: undefined,
        // loginDenied: false,
        // loginError: false,
        // offlineError: false,
      },
      rememberMe: false,
      isRequesting: false,
      fieldMissing: false,
      passwordlessLoginMode: false,
      showPassword: false,
      idpLoginMode: false,
      requirePasswordChange: false,
      passwordlessStartError: false,
      completedPasswordlessStart: false,
      doingPasswordlessLogin: false,
      qs: queryString.parse(location.search),
    };
    this.cookies = new Cookies();

    this.state.isLoading = this.state.qs?.brand_id && this.state.qs?.return_to ? true : false;

    this.formRef = React.createRef();
    this.inputRef = React.createRef();
    this.inputReturnToRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRememberMeToggle = this.handleRememberMeToggle.bind(this);
  }

  async zendeskLogin() {
    const { qs } = this.state;
    const isAuthed = await isAuthenticated();

    if (isAuthed) {
      try {
        const jwt = await getZendeskSSO(qs.return_to);

        this.inputRef.current.value = jwt;
        this.inputReturnToRef.current.value = qs.return_to;
        this.formRef.current.submit();
      } catch (e) {
        toast.error('Error logging in. Please contact support.');
        console.log(e);
      } finally {
        this.setState({ isRequesting: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  }
  // Check if the user has a refresh token cookie, if so try to login with this
  // @todo fix this
  async componentDidMount() {
    const { qs } = this.state;
    const { navigate } = this.props;

    if (qs.t && qs.t !== '') {
      this.setState({ doingPasswordlessLogin: true });
      try {
        await doLogin(null, null, null, null, this.cookies.get(cookieNames.TWO_FACTOR_TRUST_ID), qs.t, null, navigate);
      } finally {
        setTimeout(() => this.setState({ doingPasswordlessLogin: false }), 1000);
      }
    }

    const { dispatchAuthError } = this.props;
    dispatchAuthError(undefined);

    const rememberMe = this.cookies.get(cookieNames.REMEMBER_ME);
    const username = browserStore.get(localStorageNames.USERNAME);

    if (qs.brand_id && qs.return_to) {
      this.zendeskLogin();
    }

    if (rememberMe) {
      const isNewSession = await doRenewSession(rememberMe);
      if (isNewSession) {
        goToLastUrl(navigate);
      } else {
        this.cookies.remove(cookieNames.REMEMBER_ME, { path: '/' });
      }
    }

    if (username) this.setState({ formData: { username } });
  }

  async handleSubmit(e) {
    e.preventDefault();

    const { dispatchAuthError, navigate } = this.props;
    const {
      formData: { username, password },
      rememberMe,
      qs,
    } = this.state;

    dispatchAuthError(undefined);

    if (username && password) {
      this.setState({
        // loginDenied: false,
        // loginError: false,
        // offlineError: false,
        fieldMissing: false,
        isRequesting: true,
      });

      await doLogin(
        username,
        password,
        rememberMe,
        () => {
          this.setState({ isRequesting: false });
        },
        this.cookies.get(cookieNames.TWO_FACTOR_TRUST_ID),
        null,
        null,
        navigate,
        qs?.return_to && qs?.brand_id ? this.zendeskLogin() : null,
      );

      if (qs?.return_to && qs?.brand_id) {
        await this.zendeskLogin();
      }
    } else {
      this.setState({ fieldMissing: true });
    }
  }

  handleChange(e) {
    const { formData: data } = this.state;
    const formData = { ...data };
    formData[e.target.name] = e.target.value.trim();
    this.setState({ formData });
  }

  handleRememberMeToggle() {
    const { rememberMe } = this.state;
    this.setState({ rememberMe: !rememberMe });
  }

  async sendMagicLink(e) {
    e.preventDefault();
    this.setState({ passwordlessStartError: false });
    const { formData } = this.state;

    const result = await startPasswordlessLogin(formData.username);

    if (result === 'string') {
      this.setState({ passwordlessStartError: true });
    } else {
      this.setState({ completedPasswordlessStart: true });
    }
  }

  render() {
    const { authError } = this.props;
    const {
      isRequesting,
      rememberMe,
      formData,
      fieldMissing,
      passwordlessLoginMode,
      passwordlessStartError,
      completedPasswordlessStart,
      doingPasswordlessLogin,
      showPassword,
      idpLoginMode,
      isLoading,
    } = this.state;

    let error;

    if (authError === requestStatuses.INVALID_CREDENTIALS) {
      error = "The details you've tried to login with are incorrect. Please check and try again.";
    } else if (authError === requestStatuses.GENERAL_ERROR) {
      error = 'There has been an error when logging in, if this continues please contact support';
    } else if (authError === requestStatuses.OFFLINE_ERROR) {
      // eslint-disable-next-line max-len
      error =
        'You appear to be offline, or the service may be experiencing an outage, please check your internet connection and try again.';
    } else if (authError === requestStatuses.ACCOUNT_LOCKED) {
      error =
        'Your account is currently locked due exceeding the allowed number of failed login attempts. ' +
        'Please try again in 5 minutes.';
    } else if (fieldMissing) {
      error = 'Please provide a Username and a Password';
    }

    if (idpLoginMode) {
      return <IDPLogin onBackToLogin={() => this.setState({ idpLoginMode: false })} props={this.props} />;
    }
    if (doingPasswordlessLogin) {
      return (
        <div className="app flex-row align-items-center auth-page login-background-image">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={7} sm={10} xl={5} xs={12}>
                <CardGroup>
                  <Card className="p-4 rounded">
                    <CardBody>
                      <div className="text-center mb-4">
                        <ResolveLogo loginPage />
                      </div>
                      <Row className="pb-2">
                        <Col className="text-center">
                          <p className="pb-2">
                            <strong>Logging you in...</strong>
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div className="app flex-row align-items-center auth-page login-background-image">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={7} sm={10} xl={5} xs={12}>
              <CardGroup>
                {isLoading ? (
                  <Card className="p-4 rounded">
                    <CardBody>
                      <div className="text-center mb-4">
                        <ResolveLogo loginPage />
                      </div>
                      <Row className="pb-2">
                        <Col className="text-center">
                          <Spinner color="secondary" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                ) : (
                  <Card className="p-4 rounded login-box-bg">
                    <CardBody>
                      <div className="text-center mb-4">
                        <ResolveLogo loginPage />
                      </div>
                      {completedPasswordlessStart && (
                        <Row className="pb-2">
                          <Col className="text-center">
                            <p className="pb-2">
                              A magic link has been sent to the email address you provided. Please check your email and
                              click the link to login.
                            </p>
                            <p>
                              <strong>You can now close this window.</strong>
                            </p>
                          </Col>
                        </Row>
                      )}
                      {!passwordlessLoginMode && !completedPasswordlessStart && (
                        <Row className="pb-2 mt-4">
                          <Col className="text-center">
                            <Button
                              className="px-3 me-auto btn-outline"
                              onClick={() => {
                                this.setState({ passwordlessLoginMode: !passwordlessLoginMode });
                                this.props.dispatchAuthError(undefined);
                              }}
                              type="submit"
                            >
                              <span>
                                <i className="fa fa-magic me-2" />
                                Login with magic link
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      )}
                      {!idpLoginMode && (
                        <>
                          <Row>
                            <Col className="text-center">
                              <Button
                                className="px-3 me-auto btn-outline"
                                onClick={() => {
                                  this.setState({ idpLoginMode: !idpLoginMode });
                                  this.props.dispatchAuthError(undefined);
                                }}
                                style={{ width: '200px' }}
                                type="submit"
                              >
                                <span>
                                  <i className="fa fa-sign-in me-2" />
                                  Login with SSO
                                </span>
                              </Button>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                      {passwordlessLoginMode && !completedPasswordlessStart && (
                        <>
                          <Row className="pb-3">
                            <Col className="text-center">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({ passwordlessLoginMode: !passwordlessLoginMode });
                                }}
                              >
                                {`< Back to login`}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p style={{ textAlign: 'center' }}>
                                {"Enter your email address below and you'll be sent a link to your inbox which " +
                                  'allows you to login to the system without your password'}
                              </p>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                      <Form>
                        {error && (
                          <Row className="pb-2">
                            <Col>
                              <p className="text-danger text-center">{error}</p>
                            </Col>
                          </Row>
                        )}
                        {passwordlessStartError && (
                          <Row className="pb-2">
                            <Col>
                              <p className="text-danger text-center">
                                Unable to request a magic link at this time. Please try again later.
                              </p>
                            </Col>
                          </Row>
                        )}
                        {!completedPasswordlessStart && (
                          <FormGroup className="pb-2" row>
                            <Col>
                              <InputGroup>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-user" />
                                  </span>
                                </div>
                                <Input
                                  autoComplete="username"
                                  defaultValue={formData.username}
                                  name="username"
                                  onChange={this.handleChange}
                                  placeholder="Username"
                                  type="email"
                                />
                              </InputGroup>
                            </Col>
                          </FormGroup>
                        )}
                        {!passwordlessLoginMode && !completedPasswordlessStart && (
                          <span>
                            <FormGroup className="pb-2" row>
                              <Col>
                                <InputGroup>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text smaller-text">
                                      <i className="icon-lock" />
                                    </span>
                                  </div>
                                  <Input
                                    className="mt-0"
                                    autoComplete="current-password"
                                    name="password"
                                    onChange={this.handleChange}
                                    placeholder="Password"
                                    type={showPassword ? 'text' : 'password'}
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text smaller-text"
                                      onClick={() => this.setState({ showPassword: !showPassword })}
                                    >
                                      <i className={!showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} />
                                    </span>
                                  </div>
                                </InputGroup>
                              </Col>
                            </FormGroup>

                            <FormGroup className="pb-2" row>
                              <Col>
                                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                <Label check>
                                  <Checkbox
                                    isChecked={rememberMe}
                                    name="Remember me"
                                    onChange={this.handleRememberMeToggle}
                                  />
                                  <span style={{ cursor: 'pointer', userSelect: 'none' }}>Remember Me</span>
                                </Label>
                              </Col>
                            </FormGroup>
                          </span>
                        )}
                        {!passwordlessLoginMode && !completedPasswordlessStart && (
                          <Row>
                            <Col>
                              <div className="d-flex align-items-center">
                                <Button
                                  className="px-4 me-auto"
                                  disabled={isRequesting}
                                  onClick={this.handleSubmit}
                                  type="submit"
                                >
                                  {!isRequesting ? 'Login' : 'Logging in...'}
                                </Button>
                                <a className="d-block" href="/password-recovery" id="forgotPassword">
                                  Forgot password?
                                </a>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {passwordlessLoginMode && !completedPasswordlessStart && (
                          <Row>
                            <Col className="text-center">
                              <Button
                                className="px-4 me-auto"
                                disabled={isRequesting}
                                onClick={(e) => {
                                  this.sendMagicLink(e);
                                }}
                                type="submit"
                              >
                                <i className="fa fa-magic me-2" />
                                Send magic link
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                )}
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <form ref={this.formRef} action={config.auth.zenDeskPath} method="post">
          <input ref={this.inputRef} type="hidden" name="jwt"></input>
          <input ref={this.inputReturnToRef} type="hidden" name="returnTo"></input>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  authError: PropTypes.string,
  dispatchAuthError: PropTypes.func,
  navigate: PropTypes.func,
};

Login.defaultProps = {
  authError: null,
  dispatchAuthError: () => {},
  navigate: () => {},
};

function mapStateToProps(state) {
  return { authError: state.userData.userAuth.authError, failedAuthCount: state.userData.failedAuthCount };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchAuthError: (error) => {
      dispatch(setAuthError(error));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
