import { useEffect } from 'react';

export function updateCssVars(varName, value, selector) {
  if (value) {
    if (selector) {
      [...document.querySelectorAll(selector)].forEach((elm) => {
        elm.style.setProperty(varName, value);
      });
    }
    else {
      document.documentElement.style.setProperty(varName, value);
    }
  }
}

export function batchUpdateCssVars(batch = {}, selector) {
  const batchArr = Object.entries(batch);
  if (!batchArr.length) return;
  batchArr.forEach(([cssVar, val]) => updateCssVars(cssVar, val, selector));
}

export default function useCssVars(varName, value, selector) {
  useEffect(() => {
    updateCssVars(varName, value, selector);
  }, [selector, value, varName]);
}
