import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

export default function SidebarCol({
  children,
  maxWidth,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
}) {
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ maxWidth }}
      className={className}
      id="side-bar"
    >
      {children}
    </Col>
  );
}

const sizePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

SidebarCol.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  maxWidth: PropTypes.string,
  xs: sizePropType,
  sm: sizePropType,
  md: sizePropType,
  lg: sizePropType,
  xl: sizePropType,
  className: PropTypes.string,
};

SidebarCol.defaultProps = {
  children: null,
  maxWidth: '300px',
  xs: null,
  sm: '2',
  md: null,
  lg: null,
  xl: null,
  className: null,
};
