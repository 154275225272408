import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

function InputDisplay({ inline, children }) {
  if (inline) return <Col>{children}</Col>;
  return children;
}

InputDisplay.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

InputDisplay.defaultProps = {
  inline: false,
};

export default InputDisplay;
