import React from 'react';
import PropTypes from 'prop-types';

function BasicSelect({
  className,
  value,
  onChange,
  options,
  disableFirstOption,
  ...rest
}) {
  return (
    <select
      className={`select ${className || ''}`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {options.map(({ value: optVal, label }, i) => (
        <option
          key={optVal}
          value={optVal}
          disabled={(disableFirstOption && i === 0)}
        >
          {label}
        </option>
      ))}
    </select>
  );
}

BasicSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string,
  })),
  disableFirstOption: PropTypes.bool,
};

BasicSelect.defaultProps = {
  className: null,
  value: '',
  onChange: () => { },
  options: [],
  disableFirstOption: false,
};

export default BasicSelect;
