// Contains a set of API calls to middleware services in order to get data around events
import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders } from '../common-api-utils';

const { api, publishing } = config;

export async function getMultiPosterAccount(multiPosterServiceType) {
  const result = await axios.get(
    `${api.middlewareAPIURL}${publishing.multiPosterAccountPath}/${multiPosterServiceType}`,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;

  return null;
}

export async function savePublishingSettings(data) {
  const result = await axios.put(`${api.middlewareAPIURL}${publishing.multiPosterAccountPath}`, data, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function getPublisherAccounts() {
  const result = await axios.get(`${api.middlewareAPIURL}${publishing.publisherAccountPath}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function updatePublisherBoardAccount(data) {
  const result = await axios.put(`${api.middlewareAPIURL}${publishing.publisherAccountPath}`, data, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function getIndeedSettings(tenantId) {
  const result = await axios.get(`${api.middlewareAPIURL}${publishing.indeedIntegrationPath}${tenantId}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function createUpdateIndeedSettings(tenantId, data) {
  const result = await axios.put(`${api.middlewareAPIURL}${publishing.indeedIntegrationPath}${tenantId}`, data, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function createPublisherBoardAccount(data) {
  const result = await axios.post(`${api.middlewareAPIURL}${publishing.publisherAccountPath}`, data, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}

export async function deletePublisherBoardAccount(id, type) {
  const result = await axios.delete(`${api.middlewareAPIURL}${publishing.publisherAccountPath}`, {
    data: { id, type },
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data;

  return null;
}
