import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React from 'react';
import cx from 'classnames';
import { TextButton } from './index';
import { useLanguagePack } from '../hooks';

function CopyToClipboardButton(props) {
  const { label, textToCopy, isTouch, disabled } = props;
  const languagePack = useLanguagePack('candidate-view');

  return (
    <TextButton
      action={() => {
        navigator.clipboard.writeText(textToCopy).then(
          () => {
            toast.success(`${label} ${languagePack.copiedToClipboard}`);
          },
          () => {
            toast.error(languagePack.copyToClipboardError);
          },
        );
      }}
      className={cx('btn-outline ', { 'me-2 mb-0': !isTouch })}
      label={`${languagePack.copy} ${label}`}
      disabled={disabled}
    />
  );
}

export default CopyToClipboardButton;

CopyToClipboardButton.propTypes = {
  label: PropTypes.string,
  textToCopy: PropTypes.string.isRequired,
  isTouch: PropTypes.bool,
  disabled: PropTypes.bool,
};

CopyToClipboardButton.defaultProps = {
  label: null,
  isTouch: false,
  disabled: false,
};
