import axios from 'axios';
import config from '../../config/config';
import {store} from '../../js/store/index';
import {getCommonHeaders, mapErrorResponse} from '../common-api-utils';

function commonHeaders() {
  const {getState} = store;
  const {
    userData: {
      userAuth: {token},
    },
  } = getState();
  const {
    auth: {tenantId},
  } = config;

  return {
    Authorization: `Bearer ${token}`,
    'X-FusionAuth-TenantId': tenantId,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
}
export async function getDbsUser() {
  return await axios
    .get(`${config.api.middlewareAPIURL}/dbs/user`, {
      headers: commonHeaders(),
    })
    .then((resp) => resp.data)
    .catch((error) => mapErrorResponse(error.response));
}

export async function checkIfRoleSupportsDbsCheck(vacancyId) {
  return await axios
    .get(`${config.api.middlewareAPIURL}/dbs/permitted-roles/${vacancyId}`
      , {
      headers: commonHeaders(),
    })
    .then((resp) => resp.data)
    .catch((error) => mapErrorResponse(error.response));
}

export async function getDbsCheckRequestStatus(candidateId) {
  return await axios
    .get(`${config.api.middlewareAPIURL}/dbs/requests/${candidateId}`, {
      headers: commonHeaders(),
    })
    .then((resp) => resp.data)
    .catch((error) => mapErrorResponse(error.response));
}

export async function requestDbsCheck(candidateId, vacancyId) {
  const result = await axios.post(
    `${config.api.middlewareAPIURL}/dbs/requests`,
    {
      candidateId: candidateId,
      vacancyId: vacancyId,
    },
    {headers: getCommonHeaders()},
  );

  if (result.status === 201 || result.status === 200) return result.data;
  return null;
}



