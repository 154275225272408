import PropTypes from 'prop-types';

function ConditionalWrapper({ condition, wrapper, children }) {
  return condition ? wrapper(children) : children;
}

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ConditionalWrapper.defaultProps = {
  condition: false,
  wrapper: () => { },
  children: null,
};

export default ConditionalWrapper;
