import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';

export default function PillRadios({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  disabled,
}) {
  function handleChange(e) {
    const { target: { value: val } } = e;
    onChange(val);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <Label
      check
      for={id}
      className="pill-radio"
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <Input
        id={id}
        type="radio"
        name={name}
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      <span>{label}</span>
    </Label>
  );
}

PillRadios.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

PillRadios.defaultProps = {
  checked: false,
  onChange: () => { },
  disabled: false,
};
