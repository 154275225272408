import axios from 'axios';
import config from '../config/config';
import { getCommonHeaders } from './common-api-utils';

// eslint-disable-next-line import/prefer-default-export
export async function updateAccountStatus(status, accountId, tenantId) {
  const body = [
    {
      accountId,
      tenantId,
      status,
    },
  ];

  const result = await axios.put(`${config.api.middlewareAPIURL}${config.admin.accountStatusPath}`, body, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200) return result.data.length ? result.data : status;

  return null;
}

export async function createAccount(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${config.api.middlewareAPIURL}${config.accountManagement.accountPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateAccount(data = {}) {
  if (Object.keys(data).length && data.id && data.id.length) {
    const result = await axios.put(
      `${config.api.middlewareAPIURL}${config.accountManagement.accountPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function deleteAccount(id) {
  if (id) {
    const result = await axios.delete(`${config.api.middlewareAPIURL}${config.accountManagement.accountPath}/${id}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function listAccounts() {
  const result = await axios.get(`${config.api.middlewareAPIURL}${config.accountManagement.accountPath}/list`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;
  return null;
}

export async function createUser(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${config.api.middlewareAPIURL}${config.accountManagement.userPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateUser(data = {}) {
  if (Object.keys(data).length && data.id && data.id.length) {
    const result = await axios.put(
      `${config.api.middlewareAPIURL}${config.accountManagement.userPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function getUserListForAccount(searchTerm, pending) {
  let url = `${config.api.middlewareAPIURL}${config.accountManagement.userPath}/list`;
  if (searchTerm) {
    url += `?searchTerm=${searchTerm}`;
  }
  if (pending && searchTerm) {
    url += '&pending=true';
  } else if (pending && !searchTerm) {
    url += '?pending=true';
  }

  const result = await axios.get(url, { headers: getCommonHeaders() });

  if (result.status === 200 || result.status === 201) return result.data;
  return null;
}

export async function deleteUser(id) {
  if (id) {
    const result = await axios.delete(`${config.api.middlewareAPIURL}${config.accountManagement.userPath}/${id}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function saveStyleSettings(styleObj = {}) {
  if (Object.keys(styleObj).length) {
    const result = await axios.post(
      `${config.api.middlewareAPIURL}${config.account.styleSettingsPath}`,
      { ...styleObj },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function getStyleSettings() {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.styleSettingsPath}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200 || result.status === 201) return result.data;
  } catch {
    return null;
  }
  return null;
}

export async function saveTalentHubStyleSettings(styleObj = {}) {
  if (Object.keys(styleObj).length) {
    const result = await axios.put(
      `${config.api.middlewareAPIURL}${config.account.talentHubStyleSettingsPath}`,
      { ...styleObj },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function getTalentHubStyleSettings() {
  try {
    const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.talentHubStyleSettingsPath}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 200 || result.status === 201) return result.data;
  } catch {
    return null;
  }
  return null;
}

export async function saveUserProfile(profileObj = {}) {
  if (Object.keys(profileObj).length) {
    const result = await axios.put(
      `${config.api.middlewareAPIURL}${config.account.userProfilePath}`,
      { ...profileObj },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function saveUserPassword(pwObj = {}) {
  if (Object.keys(pwObj).length) {
    const result = await axios.put(
      `${config.api.middlewareAPIURL}${config.account.resetPasswordTrustedPath}`,
      { ...pwObj },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function reSyncAccounts(email) {
  const result = await axios.get(`${config.api.middlewareAPIURL}${config.account.reSyncAccountsPath}?email=${email}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

export async function findUsersForAccount(searchTerm, accountId) {
  let url = `${config.api.middlewareAPIURL}${config.account.lookupUsersForAccountPath}/${accountId}`;
  if (searchTerm) {
    url += `?nameSearch=${searchTerm}`;
  }

  const result = await axios.get(url, {
    headers: getCommonHeaders(),
  });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}
