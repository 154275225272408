import React from 'react';
import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

const StyledCard = styled(Card)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledCardBody = styled(CardBody)`
  padding: 1.5rem;
`;

const EnhancedCard = ({ children, className, ...props }) => (
  <StyledCard className={className} {...props}>
    <StyledCardBody>{children}</StyledCardBody>
  </StyledCard>
);

export default EnhancedCard;
